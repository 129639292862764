import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export const i18n = new VueI18n({
    messages: {
        pt_clm: {
            pt_br: {
                documento: 'Contrato | Contratos',
                signatario: 'Signatário | Signatários'
            }
        },
        pt_sign: {
            pt_br: {
                documento: 'Documento | Documentos',
                signatario: 'Contato | Contatos'
            }
        }
    }
})

