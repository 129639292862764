import { render, staticRenderFns } from "./BotaoNovoMobile.vue?vue&type=template&id=26d1352f"
import script from "./BotaoNovoMobile.vue?vue&type=script&lang=js"
export * from "./BotaoNovoMobile.vue?vue&type=script&lang=js"
import style0 from "./BotaoNovoMobile.vue?vue&type=style&index=0&id=26d1352f&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports