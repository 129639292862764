<template>
    <div class="co-documento-signatario-novo">
        <span>Tipo de documento</span>
        <icon-info :descricao-campo="descricaoCampo"/>
        <v-select
            v-model="value.documentoId"
            v-validate="validarCampo()"
            class="required-field mt-0 pt-0"
            :error-messages="errors.collect('Documento')"
            :items="documentos"
            name="Documento"
            placeholder="Selecione"
            persistent-placeholder
            :rules="[(v) => !!v || 'Informe o campo obrigatório']"/>
    </div>
</template>

<script>
import IconInfo from '@/views/criar-documento/novo-documento-signatario/components/IconInfo'

export default {
    name: 'DocumentoSignatarioNovo',
    components: {IconInfo},
    props: {
        value: {
            required: true
        },
        campoRequerido: {
            type: Boolean,
            default: false
        },
        descricao: {
            type: Boolean
        },
        documentos: {
            required: true,
            type: Array,
            default: () => []
        }
    },
    data: () => ({
        documentosSelecao: []
    }),
    computed: {
        descricaoCampo() {
            return `Tipo de documento que o ${this.$tc("pt_br.signatario").toLowerCase()} informará no momento da assinatura`
        },
    },
    methods: {
        validarCampo() {
            return this.campoRequerido ? 'required' : ''
        }
    }
}
</script>

<style lang="stylus">
.co-documento-signatario-novo
    position relative
    width 100%
</style>
