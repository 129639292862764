var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"co-grupo-contatos-tabela"},[_c('v-data-table',{staticClass:"tabela-grupo-signatarios",attrs:{"hide-default-footer":"","no-data-text":_vm.noDataText,"headers":_vm.headers,"items-per-page":_vm.qtdLinhasTabela,"items":_vm.gruposSignatarios},scopedSlots:_vm._u([{key:"item.integrantes",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_vm._v(_vm._s(_vm.construirIntegrantes(item.integrantes)))])])]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{staticClass:"acoes-tabela"},[_c('v-tooltip',{directives:[{name:"az-auth",rawName:"v-az-auth",value:('GrupoSignatarios.Editar'),expression:"'GrupoSignatarios.Editar'"}],attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"botoes botoes-mobile",attrs:{"size":"15px"},on:{"click":function($event){return _vm.$emit('edit', item)}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{directives:[{name:"az-auth",rawName:"v-az-auth",value:('GrupoSignatarios.Excluir'),expression:"'GrupoSignatarios.Excluir'"}],attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"botoes",attrs:{"size":"15px"},on:{"click":function($event){return _vm.$emit('excluir', item)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Excluir")])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }