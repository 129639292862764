<template>
    <div class="co-email-signatario">
        <v-text-field
            v-model="value.email"
            v-validate="validarCampo()"
            :class="campoRequerido ? 'required-field' : ''"
            :disabled="desabilitarCampo"
            :error-messages="errors.collect('E-mail')"
            :label="placeholder"
            name="E-mail"
            :placeholder="placeholder"
            persistent-placeholder
        />

        <div v-if="descricao" class="hint-checkbox">
            {{ descricaoCampo }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'EmailSignatario',
    props: {
        value: {
            required: true
        },
        campoRequerido: {
            type: Boolean,
            default: false
        },
        desabilitarCampo: {
            type: Boolean
        },
        descricao: {
            type: Boolean
        },
    },
    data() {
        return {
            descricaoCampo: `Forma de comunicação entre as duas partes (Quem elaborou o documento e o ${this.$tc("pt_br.signatario").toLowerCase()})`
        }
    },
    computed: {
        placeholder() {
            return `E-mail do ${this.$tc("pt_br.signatario").toLowerCase()}`
        }
    },
    methods: {
        validarCampo() {
            return this.campoRequerido ? 'required|email' : ''
        }
    }
}
</script>