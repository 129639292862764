<template>
    <div class="aviso-limite-assinatura-expirado" v-if="modalOps">
        <div class="chevron"></div>
        <div class="content">
            <v-icon size="18" class="btn-fechar" @click="fecharOps"> mdi-window-close </v-icon>
            <img src="@/assets/ops/triste.gif">
            <div class="titulo">Ops! Preciso da sua atenção aqui!</div>
            <div class="subtitulo">A data limite para assinaturas expirou e ainda existem assinaturas pendentes. Para
                prosseguir é necessário que a data seja prorrogada.
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'aviso-limite-assinatura-expirado',
    data() {
        return {
            modalOps: true
        }
    },
    methods: {
        fecharOps() {
            this.modalOps = false
        }
    }
}
</script>
<style lang="stylus">
.aviso-limite-assinatura-expirado
    position absolute
    width 0
    height 0
    top 75px !important
    border-top 15px solid transparent
    border-bottom 15px solid transparent
    border-right 10px solid #ccc

    .chevron
        position absolute
        width 0
        height 0
        border-top 15px solid transparent
        border-bottom 15px solid transparent
        border-right 10px solid white
        z-index 1
        left 1px
        top -20px

    .content
        box-shadow 5px 5px 5px 0 rgba(0, 0, 0, 0.25)
        background-color white
        position absolute
        top -35px
        left 10px
        border 1px solid #ccc
        border-radius 5px
        width 300px
        padding 20px
        text-align center

        img
            width 120px

        .titulo
            font-size 16px
            color #777777
            font-weight bold
            margin 10px 0

        .subtitulo
            font-size 12px
            color #999

        .btn-fechar
            position absolute
            z-index 1
            right 10px
            top 10px
</style>