<template>
    <v-dialog value="true" persistent width="500">
        <v-card>
            <v-card-title class="headline primary white--text" primary-title>
                Adicionar anexo
            </v-card-title>
            <v-card-text class="co-novo-anexo">
                <v-layout wrap>
                    <v-flex class="mt-3">
                        <v-text-field
                            class="required-field"
                            label="Nome do anexo"
                            name="Nome do anexo"
                            placeholder="ex. Nota Fiscal"
                            persistent-placeholder
                            v-model="value.nome"
                            v-validate="'required'"
                            :error-messages="errors.collect('Nome do anexo')"/>
                    </v-flex>
                </v-layout>
                <div class="label-arquivo">Arquivo<span style="color: red"> *</span></div>
                <div class="upload-file-anexo">
                    <div v-if="possuiAnexo">
                        <div class="arquivo">
                            <v-icon size="60" class="icone"> mdi-clipboard-text </v-icon>
                            <span class="texto">{{ nomeArquivoSelecionado }}</span>
                        </div>
                        <div class="opcoes" @click="limparAnexo">
                            <v-icon size="70px" class="icone"> mdi-close </v-icon>
                            <span class="texto">Excluir</span>
                        </div>
                    </div>
                    <div v-else>
                        <clm-file-upload
                            ref="clmFileUploader"
                            height="150px"
                            v-show="!arquivoSelecionado"
                            @arquivoSelecionado="setArquivoSelecionado"
                            @error="tratarErroEnvioArquivo">
                            <v-icon size="50px" class="icon" @click.prevent="$refs.clmFileUploader.openFileSelector()">
                                mdi-cloud-upload
                            </v-icon>
                            <p class="descricao">Arraste seu arquivo aqui ou clique para fazer upload.</p>
                        </clm-file-upload>
                    </div>
                </div>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#ccc" text @click="$emit('cancelar')">
                    Cancelar
                </v-btn>
                <v-btn color="green" text @click="salvar">
                    Confirmar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapState} from 'vuex'
import ClmFileUpload from '@/views/contrato/commons/ClmFileUpload'

export default {
    name: 'novo-anexo',
    components: {ClmFileUpload},
    props: ['value'],
    data() {
        return {
            arquivoSelecionado: false,
            nomeArquivoSelecionado: ''
        }
    },
    computed: {
        ...mapState(['minutaAtual']),
        possuiAnexo() {
            return this.arquivoSelecionado
        }
    },
    methods: {
        atualizarAnexo() {
            this.value.nomeArqOriginal = this.nomeArquivoSelecionado
        },
        async salvar() {
            const dadosValidos = await this.validarDadosFormulario()
            if (!dadosValidos) {
                return
            }
            this.atualizarAnexo()
            this.$emit('inserir')
        },
        setArquivoSelecionado(arquivo) {
            this.arquivoSelecionado = true
            this.nomeArquivoSelecionado = arquivo.name
            this.$emit('upload', arquivo)
        },
        limparAnexo() {
            this.arquivoSelecionado = false
            this.$emit('clean')
        },
        async validarDadosFormulario() {
            const valido = await this.$validator._base.validateAll()
            const arquivoSelecionado = this.validarArquivoSelecionado()
            return valido && arquivoSelecionado
        },
        validarArquivoSelecionado() {
            if (!this.arquivoSelecionado) {
                this.mostrarNotificacaoErro('Arquivo não selecionado.')
                return false
            }
            return true
        }
    }
}
</script>

<style lang="stylus">
.co-novo-anexo
    .label-arquivo
        font-size 12px
        color rgba(0, 0, 0, .54)
        padding-bottom 5px

    .upload-file-anexo
        .arquivo
            display block
            text-align center
            margin: 10px 0
            height 100px
            border 2px dashed #ccc

            .icone
                color #777
                cursor pointer

            .texto
                text-align center
                display block
                color #777

        .opcoes
            display none
            text-align center
            margin: 10px 0
            height 100px
            border 2px dashed #ccc

            .icone
                color #777
                cursor pointer

            .texto
                text-align center
                display block
                color #777

        &:hover
            background-color rgba(0, 0, 0, .4)

            .arquivo
                display none

            .opcoes
                display block

            .icone
                color white

            .texto
                color white
</style>