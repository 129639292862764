export default class CurrencyFormatterUtils {
    static formatador() {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
        })
    }

    static formatarBrl(valor) {
       return this.formatador().format(valor)
    }
}