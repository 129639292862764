<template>
    <v-form class="co-formulario-opcional-assinatura-whatsapp">
        <nome-signatario
            v-model="novoSignatario"
            descricao
        />
    </v-form>
</template>

<script>
import NomeSignatario
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/NomeSignatario'

export default {
    name: 'FormularioOpcionalAssinaturaWhatsapp',
    components: {
        NomeSignatario
    },
    props: {
        novoSignatario: {
            type: Object,
            required: true
        }
    }
}
</script>