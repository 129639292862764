<template>
    <v-container class="co-finalizar-pagamento">
        <v-col cols="12" class="finalizar">
            <v-card-text class="mt-3 mb-5 finalizar" :style="getContentHeight">
                <v-row no-gutters class="justify-center my-4">
                    <v-img contain
                           max-width="60px"
                           :src="imagens.checked"/>
                </v-row>

                <v-row no-gutters class="text-sucesso justify-center mt-4 mb-2">
                    <div v-if="ehMetodoSelecionadoCartao">AGUARDANDO PAGAMENTO!</div>
                    <div v-else-if="ehMetodoSelecionadoBoleto">BOLETO GERADO COM SUCESSO!</div>
                    <div v-else-if="ehMetodoSelecionadoPIX">PIX GERADO COM SUCESSO!</div>
                </v-row>

                <v-row no-gutters v-if="ehMetodoSelecionadoCartao && !mostrarFallbackLinkPagamento" class="text-liberacao justify-center">
                    <div>Estamos quase lá! Será aberta automaticamente uma nova janela através da nossa plataforma de
                    pagamento ASAAS. Caso não abra, clique no botão abaixo ou acesse o link enviado no seu email.</div>
                </v-row>

                <v-row no-gutters v-else class="text-liberacao justify-center mb-3">
                    <div>
                        Estamos quase lá! Para sua segurança, a primeira cobrança é enviada diretamente no seu e-mail cadastrado
                        contendo um link de pagamento da nossa plataforma de pagamento ASAAS.
                        Caso não receba esse e-mail em até 2 horas, entre em contato com o nosso suporte.
                    </div>
                </v-row>

                <v-row no-gutters class="text-liberacao justify-center">
                    <div>Após o pagamento a liberação será realizada em até {{horasLiberacao}}</div>
                </v-row>

                <v-row no-gutters class="text-liberacao justify-center">
                    <div>Qualquer dificuldade nos chame no chat ou ligue no 0800 722 2701</div>
                </v-row>

                <v-row no-gutters class="justify-center my-3">
                    <v-img v-if="ehMetodoSelecionadoBoleto"
                           contain class="cod-barras d-flex"
                           :src="imagens.codBarras"/>
                </v-row>

                <v-row v-if="!mostrarFallbackLinkPagamento" no-gutters class="justify-center mt-2 mb-4">
                    <v-btn
                        color="green white--text"
                        depressed
                        @click="abrirPaginaPagamento">{{ textoBotao }}
                    </v-btn>
                </v-row>

                <v-row no-gutters class="justify-center mb-2">
                    <div class="certificado">Certificado de Compra Segura</div>
                </v-row>

                <v-row no-gutters class="justify-center mb-4">
                    <v-img
                        contain
                        height="40px"
                        max-width="100px"
                        :src="imagens.siteSeguro"
                    />
                </v-row>
            </v-card-text>

            <v-divider/>

            <v-card-actions>
                <v-row no-gutters class="justify-center px-2 py-3">
                    <v-btn class="ok"
                           depressed
                           @click="fechar">OK
                    </v-btn>
                </v-row>
            </v-card-actions>
        </v-col>
    </v-container>
</template>

<script>
import CodBarras from '@/assets/icons/cod-barras.svg'
import SiteSeguro from '@/assets/icons/site-seguro.png'
import Checked from '@/assets/icons/checked.svg'
import {metodoPagamentoEnum} from '@/commons/constants'

export default {
    name: 'FinalizarPagamento',
    props: {
        metodoSelecionado: {
            type: Object,
        },
        linkPagamento: {
            type: String
        }
    },
    data() {
        return {
            imagens: {
                codBarras: CodBarras,
                siteSeguro: SiteSeguro,
                checked: Checked
            },
            mostrarFallbackLinkPagamento: false
        }
    },
    mounted() {
        if (this.linkPagamento) {
            if (this.ehMetodoSelecionadoCartao) {
                this.abrirPaginaPagamento()
            }
        } else {
            this.mostrarFallbackLinkPagamento = true
        }
    },
    computed: {
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        },
        ehMetodoSelecionadoCartao() {
            return this.metodoSelecionado === metodoPagamentoEnum.CARTAO_CREDITO
        },
        ehMetodoSelecionadoBoleto() {
            return this.metodoSelecionado === metodoPagamentoEnum.BOLETO
        },
        ehMetodoSelecionadoPIX() {
            return this.metodoSelecionado === metodoPagamentoEnum.PIX
        },
        getContentHeight() {
            if (this.ehMobile) {
                const alt = window.innerHeight - 159
                return `height:${alt}px`
            } else {
                return ''
            }
        },
        horasLiberacao() {
            return this.ehMetodoSelecionadoBoleto ? "2 dias!" : "2 horas!"
        },
        textoBotao() {
            if (this.ehMetodoSelecionadoCartao) {
                return 'INFORMAR DADOS DE PAGAMENTO'
            } else if (this.ehMetodoSelecionadoPIX) {
                return 'VISUALIZAR PIX'
            } else {
                return 'VISUALIZAR BOLETO'
            }
        }
    },
    methods: {
        abrirPaginaPagamento() {
            window.open(this.linkPagamento, '_blank')
        },
        fechar() {
            this.$emit('fechar')
        }
    }
}
</script>

<style lang="stylus">
.co-finalizar-pagamento
    display flex
    min-height 400px !important

    .finalizar
        background-color white
        align-items stretch
        justify-content space-between
        padding-bottom 0
        min-height 299px

    .certificado
        color #C7C7C7

    .text-sucesso
        color green
        font-weight bold
        font-size 20px

    .text-liberacao
        text-align center
        color #787878

    .ok
        background: #eeeeee
        color #787878
        height 40px

    .titulo
        font-size 40px

    .cod-barras
        max-width 450px

@media (max-width: 600px)
    .co-finalizar-pagamento
        .text-sucesso
            font-size 18px

        .cod-barras
            max-width 100%

</style>
