<template>
    <div class="co-documento-lista-upload-novo">
        <div class="d-flex justify-space-between">
            <div class="mb-2">Documentos ({{ quantidadePdf }})</div>
            <div class="mb-2">{{ tamanhoTotalPdfs }} de 20MB</div>
        </div>

        <v-simple-table dense :class="listaUploadClass">
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left"><h3>Anexo</h3></th>

                    <th class="vertical-lines column-tamanho text-center"><h3>Tamanho</h3></th>

                    <th class="column-acoes text-center"><h3>Ações</h3></th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(pdf, i) in pdfs" :key="pdf.name + i">
                    <td class="text-left">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <span v-on="on">{{ nomePdf(pdf.name) }} </span>
                            </template>
                            <span>{{ pdf.name }}</span>
                        </v-tooltip>
                    </td>

                    <td class="vertical-lines text-right">{{ formatBytes(pdf.size) }}</td>

                    <td class="text-center pa-0">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon class="acoes-btn" v-on="on" @click="visualizarPdf(pdf)"> mdi-eye-outline </v-icon>
                            </template>
                            <span>Visualizar</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon class="acoes-btn" v-on="on" @click="abrirDialogConfirmacaoRemocao(pdf)">
                                    mdi-delete
                                </v-icon>
                            </template>
                            <span>Deletar</span>
                        </v-tooltip>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>

        <dialog-default-confirmar-acao
            v-if="dialogConfirmacaoRemocao"
            :dialog="dialogConfirmacaoRemocao"
            sentimento="assustado"
            titulo="Eita! Deixa eu confirmar uma coisa..."
            subtitulo="Você quer mesmo remover este pdf ?"
            label-botao-principal="Sim, eu quero remover!"
            @acao-principal="removerPdf"
            @fechar="fecharDialogConfirmacaoRemocao"
        />

        <dialog-visualizar-pdf
            :dialog="dialogVisualizarPdf"
            :pdf="pdfAcao"
            @fechar="fecharDialogVisualizarPdf"
        />
    </div>
</template>

<script>
import DialogDefaultConfirmarAcao from '@/commons/components/dialogs/DialogDefaultConfirmarAcao'
import DialogVisualizarPdf
    from '@/views/criar-documento/novo-documento/components/documento/campos-upload/listaUpload/dialog/DialogVisualizarPdf'

export default {
    name: 'ListaUpload',
    components: {
        DialogDefaultConfirmarAcao,
        DialogVisualizarPdf
    },
    props: {
        pdfs: {
            type: Array
        }
    },
    data() {
        return {
            dialogVisualizarPdf: false,
            dialogConfirmacaoRemocao: false,
            pdfAcao: {}
        }
    },
    computed: {
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        },
        listaUploadClass() {
            return this.ehMobile ? 'documento-lista-upload__mobile' : 'documento-lista-upload'
        },
        maxSize() {
            return this.$store.state.loki.file.maxSize.toUpperCase()
        },
        quantidadePdf() {
            return this.pdfs.length
        },
        tamanhoTotalPdfs() {
            const size = this.pdfs.reduce((acumulador, atual) => acumulador += atual.size, 0)
            return this.formatBytes(size)
        }
    },
    methods: {
        abrirDialogConfirmacaoRemocao(pdf) {
            this.pdfAcao = pdf
            this.dialogConfirmacaoRemocao = true
        },
        abrirDialogVisualizarPdf() {
            this.dialogVisualizarPdf = true
        },
        abrirListaPdf() {
            let listaPdf = document.getElementById("listaPdf")
            listaPdf.classList.add('lista-aberta')
            listaPdf.classList.remove('lista-fechada')
        },
        adicionarPdf(files) {
            this.$emit('adicionar-pdf', files)
        },
        exbirlistaPdf() {
            setTimeout(this.abrirListaPdf)
        },
        fecharDialogConfirmacaoRemocao() {
            this.dialogConfirmacaoRemocao = false
        },
        fecharDialogVisualizarPdf() {
            this.dialogVisualizarPdf = false
            this.pdfAcao = {}
        },
        formatBytes(bytes, decimals = 2) {
            if (bytes === 0) return '0 Bytes'

            const kb = 1024
            const dm = decimals < 0 ? 0 : decimals
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

            const i = Math.floor(Math.log(bytes) / Math.log(kb))
            return parseFloat((bytes / Math.pow(kb, i)).toFixed(dm)) + ' ' + sizes[i]
        },
        nomePdf(name) {
            if (this.$vuetify.breakpoint.xs && name.length > 20) {
                return name.slice(0, 12) + '...'
            } else if (this.$vuetify.breakpoint.sm && name.length > 45) {
                return name.slice(0, 24) + '...'
            } else if (this.$vuetify.breakpoint.md && name.length > 70) {
                return name.slice(0, 62) + '...'
            } else {
                return name
            }
        },
        removerPdf() {
            this.$emit('remover-pdf', this.pdfAcao)
            this.fecharDialogConfirmacaoRemocao()
            this.pdfAcao = {}
        },
        visualizarPdf(pdf) {
            this.pdfAcao = pdf
            this.abrirDialogVisualizarPdf()
        }
    }
}
</script>

<style lang="stylus">
.co-documento-lista-upload-novo
    margin 20px 30px
    align-content center
    align-items center
    border-color var(--divider-100) !important

    .v-data-table
        color unset

    .documento-lista-upload
        &__mobile
            margin-bottom 40px

        .column-acoes
            min-width 70px
            width 100px

        .column-tamanho
            min-width 70px
            width 100px

    .vertical-lines
        border-right 1px solid var(--divider-100)
        border-left 1px solid var(--divider-100)

    .acoes-btn
        cursor pointer
        margin 0 5px

@media (max-width: 600px)
    .co-documento-lista-upload-novo
        padding-bottom 100px

</style>