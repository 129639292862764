<template>
    <div class="co-tipo-assinatura-digital">
        <div class="tipo-assinatura">

            <div class="tipo-assinatura-click" @click="tipoAssinaturaProximo">
                <div :style="getStyleTitle" class="assinatura-digital-title">Digital (Certificado digital A1 ou A3)
                </div>
            </div>

            <v-spacer></v-spacer>
            <v-icon v-if="false" class="icon-info" size="14" @click="abrirDialogInfo()"> mdi-information </v-icon>
        </div>

        <div :style="getStyleHint" class="assinatura-digital-hint">
            {{ descricao }}
        </div>

        <contratar-servico v-if="!digitalHabilitado" @abrir-dialog-contratar-servico="abrirDialogContratarServico"/>

        <label-adquirir-saldo v-else/>

    </div>
</template>

<script>

import LabelAdquirirSaldo
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/LabelAdquirirSaldo'
import ContratarServico
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/ContratarServico'

export default {
    name: 'TipoAssinaturaWhatsapp',
    components: {ContratarServico, LabelAdquirirSaldo},
    props: ['digitalHabilitado', 'value'],
    data() {
        return {
            dialogContratarServico: false,
            descricao: 'Utilizará seu próprio certificado armazenado em um dispositivo pendrive, Smartcard'
        }
    },
    computed: {
        getStyleHint() {
            return this.digitalHabilitado ? 'color: #aaa;' : 'color: rgba(170,170,170,0.5);'
        },
        getStyleTitle() {
            return this.digitalHabilitado ? 'color: var(--v-primary-base)' : 'color: rgba(119,119,119,0.4)'
        },
    },
    methods: {
        abrirDialogContratarServico() {
            this.$emit('abrir-dialog-contratar-servico')
        },
        abrirDialogInfo() {
            this.$emit('abrir-dialog-info', 'Digital')
        },
        tipoAssinaturaProximo() {
            if (this.digitalHabilitado) {
                this.value.tipoAssinatura = 'DIGITAL'
                this.$emit('tipo-assinatura-proximo')
            } else {
                this.abrirDialogContratarServico()
            }
        }

    }
}
</script>

<style lang="stylus">
.co-tipo-assinatura-digital
    .tipo-assinatura
        display flex

        .tipo-assinatura-click
            display flex
            cursor pointer

            .assinatura-digital-title
                font-weight bold

            .assinatura-digital-title:hover
                text-decoration-line underline

        .icon-info
            color #ccc

    .assinatura-digital-hint
        font-size 12px
        text-align justify
        margin-bottom 8px

</style>