<template>
    <div>
        <v-dialog v-model="dialog" width="700" persistent>
            <v-card class="co-dialog-importar-signatarios">
                <v-toolbar flat dark color="primary">
                    <span class="titulo">Importar: Selecione os {{ $tc("pt_br.signatario", 2) }}</span>

                    <v-spacer></v-spacer>

                    <v-icon @click="fechar"> mdi-window-close </v-icon>
                </v-toolbar>

                <v-card-text class="mt-6">
                    <importar-signatarios-tabela
                        v-model="signatarios"
                        :papeis-signatario="papeisSignatario"
                        @novo="novoCadastroSignatario"
                        ref="ImportarSignatariosTabela"/>
                </v-card-text>

                <v-card-actions class="acoes justify-center">
                    <div class="cancelar">
                        <v-btn depressed @click="fechar">Cancelar</v-btn>
                    </div>

                    <v-spacer/>

                    <div class="importar">
                        <v-btn color="primary" @click="importar">Incluir</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <dialog-novo-contato
            v-if="dialogNovoContato"
            :dialog="dialogNovoContato"
            :cadastro-signatario="cadastroContato"
            :documentos-signatario="documentosSignatario"
            :papeis-signatario="papeisSignatario"
            :grupos-signatarios="gruposSignatarios"
            @fechar="fecharDialogNovoContato"
            @salvar="salvarFecharDialogNovoContato"
        />
    </div>
</template>

<script>
import ImportarSignatariosTabela from '../components/ImportarSignatariosTabela'
import {actionTypes} from '@/commons/constants'
import DialogNovoContato from '@/views/home/configuracoes/contatos/DialogNovoContato'

export default {
    name: 'DialogImportarSignatarios',
    components: {DialogNovoContato, ImportarSignatariosTabela},
    props: {
        dialog: {
            type: Boolean
        },
        documentosSignatario: {
            type: Array,
            required: true
        },
        papeisSignatario: {
            type: Array,
            required: true
        },
        salvarBackend: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            signatarios: {
                selecionados: []
            },
            dialogNovoContato: false,
            cadastroContato: {},
            gruposSignatarios: [],
        }
    },
    methods: {
        async carregarGruposSignatarios() {
            const grupos = await this.$store.dispatch(actionTypes.GRUPO_SIGNATARIOS.BUSCAR_MEUS_GRUPOS_SIGNATARIOS)
            for (const grupo of grupos) {
                this.gruposSignatarios.push({text: grupo.nome, value: grupo.id})
            }
        },
        possuiSignatarioWhatsApp(signatariosSelecionados) {
            return signatariosSelecionados.filter(signatario => signatario.tipoAssinatura === 'WHATSAPP')
        },
        possuiSignatarioSemEmail(signatariosSelecionados) {
            return signatariosSelecionados.filter(signatario => !signatario.email)
        },
        fechar() {
            this.$emit('fechar')
        },
        fecharDialogNovoContato() {
            this.dialogNovoContato = false
        },
        importar() {
            const signatariosWpp = this.possuiSignatarioWhatsApp(this.signatarios.selecionados)
            const possuiSignatarioSemEmail = this.possuiSignatarioSemEmail(this.signatarios.selecionados)

            if (signatariosWpp.length > 0) {
                if (signatariosWpp.length === 1) {
                    this.mostrarNotificacaoErro(`O ${this.$tc("pt_br.signatario").toLowerCase()} ${signatariosWpp[0].nome} está com o tipo de assinatura WhatsApp selecionados. A funcionalidade foi descontinuada, altere o tipo de assinatura para e-mail.`)
                    return
                } else {
                    this.mostrarNotificacaoErro(`Existem ${this.$tc("pt_br.signatario", 2).toLowerCase()} com o tipo de assinatura WhatsApp selecionados. A funcionalidade foi descontinuada, altere o tipo de assinatura para e-mail.`)
                    return
                }
            } else if (possuiSignatarioSemEmail.length > 0) {
                if (possuiSignatarioSemEmail.length === 1) {
                    this.mostrarNotificacaoErro(`O ${this.$tc("pt_br.signatario").toLowerCase()} ${possuiSignatarioSemEmail[0].nome} está sem e-mail. Adicione um e-mail e inclua o signatário novamente.`)
                    return
                } else {
                    this.mostrarNotificacaoErro(`Existem ${this.$tc("pt_br.signatario", 2).toLowerCase()} sem e-mail selecionados. Adicione um e-mail e inclua o signatário novamente.`)
                    return
                }
            }

            if (this.signatarios.selecionados.length > 0) {
                if (this.salvarBackend) {
                    this.importarESalvar()
                } else {
                    this.$emit('concluir-importar', this.signatarios.selecionados)
                }
            } else {
                this.mostrarNotificacaoErro(`Nenhum ${this.$tc("pt_br.signatario").toLowerCase()} selecionado!`)
            }
        },
        async importarESalvar() {
            let cadastrosSelecionados = this.signatarios.selecionados.map(cadastro => cadastro.id)
            let signatariosImportadosOutput = await this.$store.dispatch(actionTypes.CADASTRO_SIGNATARIOS.IMPORTAR_CADASTROS_SIGNATARIOS, {
                cadastrosSelecionados: cadastrosSelecionados
            })
            this.$emit('concluir-importar', signatariosImportadosOutput)
        },
        async novoCadastroSignatario() {
            this.cadastroContato = {
                id: null,
                tipoAssinatura: null,
                papelId: null,
                documentoId: null,
                skipFotoWpp: true,
                nome: '',
                email: '',
                ddi: '55',
                telefone: '',
                grupos: []
            }
            await this.carregarGruposSignatarios()
            this.dialogNovoContato = true
        },
        salvarFecharDialogNovoContato() {
            this.dialogNovoContato = false
            this.$refs.ImportarSignatariosTabela.buscarPagina()
        }
    }

}
</script>


<style lang="stylus">
.co-dialog-importar-signatarios
    text-align center
    display block
    padding-bottom 15px
    background-color white

    .titulo
        font-size 18px
        font-weight bold

    .cancelar
        margin-right 30px

    .table
        text-align start

    .busca
        justify-content end
        display flex
        align-items center

        .search-bar
            max-width 350px;

</style>