<template>
    <div>
        <v-dialog v-model="dialog" width="700" persistent>
            <v-card class="co-dialog-importar-signatarios">
                <v-toolbar flat dark color="primary">
                    <span class="titulo">Importar: Selecione os grupos de {{ $tc("pt_br.signatario", 2) }}</span>

                    <v-spacer></v-spacer>

                    <v-icon @click="fechar"> mdi-window-close </v-icon>
                </v-toolbar>

                <v-card-text class="mt-6">
                    <importar-grupos-signatarios-tabela
                        v-model="gruposSignatarios"
                        :papeis-signatario="papeisSignatario"
                        @novo="novoGrupoSignatarios"
                        ref="ImportarGruposTabela"/>
                </v-card-text>

                <v-card-actions class="acoes justify-center">
                    <div class="cancelar">
                        <v-btn depressed @click="fechar">Cancelar</v-btn>
                    </div>

                    <v-spacer/>

                    <div class="importar">
                        <v-btn color="primary" @click="importar">Incluir</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <dialog-novo-grupo-contatos
            v-if="dialogNovoGrupo"
            :dialog="dialogNovoGrupo"
            :grupo-signatarios="cadastroGrupo"
            :cadastros-signatarios="cadastrosSignatarios"
            @fechar="fecharDialogNovoGrupo"
            @salvar="salvarFecharDialogNovoGrupo"
        />
    </div>
</template>

<script>
import ImportarGruposSignatariosTabela from '../components/ImportarGruposSignatariosTabela'
import {actionTypes} from '@/commons/constants'
import DialogNovoGrupoContatos from '@/views/home/configuracoes/grupos-contatos/DialogNovoGrupoContatos'

export default {
    name: 'DialogImportarGrupos',
    components: {DialogNovoGrupoContatos, ImportarGruposSignatariosTabela},
    props: {
        dialog: {
            type: Boolean
        },
        papeisSignatario: {
            type: Array
        },
        salvarBackend: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            gruposSignatarios: {
                selecionados: []
            },
            dialogNovoGrupo: false,
            cadastroGrupo: {},
            cadastrosSignatarios: []
        }
    },
    methods: {
        async buscarSignatariosImportados(gruposSelecionados) {
            let signatariosImportados = await this.$store.dispatch(actionTypes.GRUPO_SIGNATARIOS.BUSCAR_SIGNATARIOS_IMPORTACAO, {
                gruposSelecionados: gruposSelecionados
            })

            const grupoPossuiSignatarioWpp = this.ehGrupoComSignatariosWhatsAppSelecionado(signatariosImportados)
            const grupoPossuiSignatarioSemEmail = this.ehGrupoComSignatariosSemEmail(signatariosImportados)

            if (grupoPossuiSignatarioWpp) {
                this.mostrarNotificacaoErro(`Existem ${this.$tc("pt_br.signatario", 2).toLowerCase()} com o tipo de assinatura WhatsApp em um grupo selecionado. A funcionalidade foi descontinuada, altere o tipo de assinatura para e-mail.`)
                return
            } else if (grupoPossuiSignatarioSemEmail) {
                this.mostrarNotificacaoErro(`Existem ${this.$tc("pt_br.signatario", 2).toLowerCase()} sem e-mail em um grupo selecionado. Adicione um e-mail e inclua o signatário novamente.`)
                return
            }

            this.$emit('concluir-importar', signatariosImportados)
        },
        async carregarCadastrosSignatarios() {
            const cadastros = await this.$store.dispatch(actionTypes.CADASTRO_SIGNATARIOS.BUSCAR_MEUS_CADASTROS_SIGNATARIOS)
            for (const cadastro of cadastros) {
                this.cadastrosSignatarios.push({
                    text: cadastro.nome + ' - ' + cadastro.contato,
                    value: cadastro.id,
                })
            }
        },
        ehGrupoComSignatariosWhatsAppSelecionado(signatarios) {
            return signatarios.some(signatario =>  signatario.tipoAssinatura === 'WHATSAPP')
        },
        ehGrupoComSignatariosSemEmail(signatarios) {
            return signatarios.some(signatario =>  !signatario.email)
        },
        fechar() {
            this.$emit('fechar')
        },
        fecharDialogNovoGrupo() {
            this.dialogNovoGrupo = false
        },
        importar() {
            let gruposSelecionados = this.gruposSignatarios.selecionados.map(grupo => grupo.id)
            if (gruposSelecionados.length > 0) {
                if (this.salvarBackend) {
                    this.importarESalvar(gruposSelecionados)
                } else {
                    this.buscarSignatariosImportados(gruposSelecionados)
                }
            } else {
                this.mostrarNotificacaoErro('Nenhum grupo selecionado!')
            }
        },
        async importarESalvar(gruposSelecionados) {
            let signatariosImportadosOutput = await this.$store.dispatch(actionTypes.GRUPO_SIGNATARIOS.IMPORTAR_CADASTROS_SIGNATARIOS_GRUPOS, {
                gruposSelecionados: gruposSelecionados
            })
            this.$emit('concluir-importar', signatariosImportadosOutput)
        },
        async novoGrupoSignatarios() {
            this.cadastroGrupo = {
                id: null,
                nome: '',
                signatarios: []
            }
            await this.carregarCadastrosSignatarios()
            this.dialogNovoGrupo = true
        },
        salvarFecharDialogNovoGrupo() {
            this.dialogNovoGrupo = false
            this.$refs.ImportarGruposTabela.buscarPagina()
        }
    }

}
</script>


<style lang="stylus">
.co-dialog-importar-signatarios
    text-align center
    display block
    padding-bottom 15px
    background-color white

    .titulo
        font-size 18px
        font-weight bold

    .cancelar
        margin-right 30px

    .table
        text-align start

    .busca
        justify-content end
        display flex
        align-items center

        .search-bar
            max-width 350px;

</style>