<template>
    <div class="co-importar-signatarios-tabela">
        <div class="busca mb-6">
            <div v-az-auth="'CadastroSignatario.Novo'">
                <v-btn color="primary" dark depressed large @click="novoGrupo">
                    <v-icon size="20px" class="mr-2"> mdi-plus-circle </v-icon>
                    Adicionar
                </v-btn>
            </div>

            <v-spacer/>

            <v-text-field
                class="search-bar"
                v-model="search"
                label="Buscar pelo nome"
                hide-details
                @keyup.enter="atualizarFiltro">
            </v-text-field>

            <v-btn icon outlined @click="atualizarFiltro">
                <v-icon dark> mdi-magnify</v-icon>
            </v-btn>
        </div>

        <v-data-table
            v-model="value.selecionados"
            class="tabela-signatarios"
            hide-default-footer
            show-select
            :no-data-text="noDataText"
            :headers="headers"
            :items="gruposSignatarios"
            :items-per-page="5"
            checkbox-color="primary"
        >
            <template v-slot:header.data-table-select="{ props, on }">
                <v-simple-checkbox
                    :value="props.value || props.indeterminate"
                    v-on="on"
                    :indeterminate="props.indeterminate"
                    color="primary"
                />
            </template>

            <template v-slot:item.integrantes="{ item }">
                <div>
                    <span>{{ construirIntegrantes(item.integrantes) }}</span>
                </div>
            </template>
        </v-data-table>

        <v-flex class="footer">
            <v-pagination
                dense
                class="paginas justify-center elevation-0"
                v-model="getPaginacaoAtual().page"
                :length="getPaginacaoAtual().numPages"
                :total-visible="7"
                @input="irParaPagina"/>
        </v-flex>
    </div>
</template>

<script>
import _ from 'lodash'
import {actionTypes, mutationTypes} from '@/commons/constants'
import {mapGetters, mapState} from 'vuex'

export default {
    name: 'ImportarGruposSignatariosTabela',
    props: {
        value: {
            type: Object
        },
        papeisSignatario: {
            type: Array
        }
    },
    data() {
        return {
            listaSignatarios: [],
            gruposSignatarios: [],
            search: '',
            todosSelecionados: false,
            headers: [
                {
                    text: 'Nome',
                    align: 'left',
                    sortable: false,
                    filterable: true,
                    value: 'nome',
                },
                {
                    text: 'Integrantes',
                    align: 'left',
                    sortable: false,
                    filterable: true,
                    value: 'integrantes',
                    width: '300px'
                }
            ]
        }
    },
    async mounted() {
        await this.resetarPaginacao()
        await this.resetarFiltros()
        await this.buscarPagina()
    },
    computed: {
        ...mapState(['gruposSignatariosBusca']),
        ...mapGetters(['ehEfcazCLM']),
        noDataText() {
            return `Nenhum ${this.$tc("pt_br.signatario").toLowerCase()} encontrado.`
        }
    },
    methods: {
        atualizarListagruposSignatarios(gruposSignatarios) {
            this.gruposSignatarios = gruposSignatarios
        },
        async atualizarFiltro() {
            await this.setFiltros({
                nome: {value: this.search, default: null},
            })
            await this.buscarPagina()
        },
        async buscarPagina() {
            const paginaGruposSignatarios = await this.$store.dispatch(actionTypes.GRUPO_SIGNATARIOS.BUSCAR_GRUPOS_SIGNATARIOS_FILTRADOS)
            this.setPaginacao({
                numPages: paginaGruposSignatarios.totalPages
            })
            this.atualizarListagruposSignatarios(paginaGruposSignatarios.content)
        },
        construirIntegrantes(cadastros) {
            let integrantes = ''
            cadastros.slice(0,2).forEach(cadastro => {
                integrantes += cadastro + ', '
            })
            return integrantes.slice(0, -2) + ((cadastros.length > 2) ? '...' : '')
        },
        converterPapel(papelId) {
            try {
                return this.papeisSignatario.find(papel => papel.value === papelId).text
            } catch (e) {
                return papelId
            }
        },
        getFiltrosAtuais() {
            return _.clone(this.gruposSignatariosBusca.filtros)
        },
        getPaginacaoAtual() {
            return _.clone(this.gruposSignatariosBusca.paginacao)
        },
        irParaPagina(pagina) {
            this.setPaginacao({
                page: pagina
            })
            this.buscarPagina()
        },
        montarContato(item) {
            if (item.tipoAssinatura === 'WHATSAPP') {
                let ddd = item.telefone.substring(0, 2);
                let telefone = item.telefone.substring(2);
                return '+' + item.ddi + " (" + ddd + ") " + telefone;
            } else {
                return item.email
            }
        },
        novoGrupo() {
            this.$emit('novo')
        },
        resetarPaginacao() {
            this.setPaginacao({
                page: 1,
                rowsPerPage: 5
            })
        },
        resetarFiltros() {
            this.setFiltros({
                nome: {value: null, default: null},
            })
        },
        setFiltros(filtros) {
            const filtrosAtuais = this.getFiltrosAtuais()
            this.$store.commit(mutationTypes.SET_FILTROS_GRUPOS_SIGNATARIOS, Object.assign({}, filtrosAtuais, filtros))
        },
        setPaginacao(paginacao) {
            const paginacaoAtual = this.getPaginacaoAtual()
            this.$store.commit(mutationTypes.SET_PAGINACAO_GRUPOS_SIGNATARIOS, Object.assign({}, paginacaoAtual, paginacao))
        }
    }
}
</script>

<style lang="stylus">
.co-importar-signatarios-tabela
    .busca
        justify-content end
        display flex
        align-items center

        .search-bar
            max-width 350px;

    .tabela-signatarios
        min-height 290px

    .v-data-table-header th
        background-color var(--divider-100) !important


</style>