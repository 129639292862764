<template>
    <div>
        <form enctype="multipart/form-data" novalidate>
            <div class="co-documento-upload-novo"
                 :style="getStyleBackground"
                 @click="$refs.inputPdf.openFileSelector()"
                 @drop.prevent="$refs.inputPdf.receberArquivo($event.dataTransfer.items)" @dragover.prevent="">

                <input-pdf-novo ref="inputPdf" @arquivo-selecionado="adicionarPdf" :lista-pdf-inserido="pdfs"/>

                <div class="upload-body">
                    <p class="upload-text"> Arraste seus arquivos aqui ou clique no botão para fazer upload </p>

                    <v-btn color="primary">
                        <v-icon left>
                            mdi-file-upload-outline
                        </v-icon>
                        Selecionar Arquivo
                    </v-btn>

                    <p class="ma-3">*Máximo de 5 arquivos com extensão .pdf</p>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import InputPdfNovo from './InputPdfNovo'

export default {
    name: "Upload",
    components: {InputPdfNovo},
    props: {
        pdfs: Array
    },
    computed: {
        getStyleBackground() {
            return this.$vuetify.breakpoint.xsOnly ? 'background: white' : 'background: var(--background-200)'
        }
    },
    methods: {
        adicionarPdf(files) {
            this.$emit('adicionar-pdf', files)
        }
    }
}
</script>

<style lang="stylus">
.co-documento-upload-novo
    height 200px
    border 2px dashed #ccc
    margin 20px 30px
    align-content center
    align-items center
    text-align center
    color var(--text-color-300)
    display flex
    position relative
    cursor pointer

    &:hover
        border-color var(--text-color-200)

    .upload-body
        width 100%

        .upload-text
            font-size 16px
            font-weight 500
            margin-bottom 20px
</style>