<template>
    <div class="co-item-confirmacao">
        <div class="titulo-confirmacao">
            {{ tituloProduto }} ({{ quantidadeProduto }})
        </div>
 
        <v-divider class="divider-confirmacao my-2"/>

        <v-row no-gutters class="descricao-confirmacao">
            <v-col class="valor-unitario">
                <div>{{ tipoValor }}</div>
                <div>{{ formatReal(valor) }}</div>
            </v-col>

            <v-col class="sub-total">
                <v-flex class="flex-confirmacao">
                    <div>Sub-Total</div>
                    <div>{{ subtotalValor }}</div>
                </v-flex>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'CardResumoItem',
    props: {
        produto: {
            type: Object
        }
    },
    computed: {
        tituloProduto() {
            if (this.produto.quantidadePacote > 1) {
                return `${this.produto.quantidadePacote} ${this.produto.titulo}`
            } else {
                return this.produto.titulo
            }
        },
        tipoValor() {
            let tipo = 'Valor mensal'

            if (this.produto.nome.includes('ANUAL')) {
                tipo = this.ehParcela() ? tipo += ` (${this.produto.parcelas}x)` : 'Valor unitário'
            } else if (this.produto.nome === 'DOCUMENTO' || this.produto.nome === 'COMBO_DOCUMENTO_WHATSAPP') {
                tipo = 'Valor unitário'
            }

            return tipo
        },
        quantidadeProduto() {
            return this.produto.quantidade > 0 ? this.produto.quantidade : 1
        },
        valor() {
          return this.ehParcela() ? this.produto.valorParcela : this.produto.valorPacote
        },
        subtotalValor() {
            let subtotalValor = this.valor

            if (this.ehParcela()) {
                subtotalValor = subtotalValor * this.produto.parcelas
            }

            return this.formatReal(subtotalValor * this.quantidadeProduto)
        }
    },
    methods: {
        ehParcela() {
            return this.produto.parcelas > 0
        },
        formatReal(valor) {
            return valor.toLocaleString('pt-br', {style: 'currency', currency: 'BRL', minimumFractionDigits: 2})
        }
    }
}
</script>

<style lang="stylus" scoped>
.co-item-confirmacao

    .titulo-confirmacao
        font-size 13px
        font-weight bold
        color #777777

    .flex-confirmacao
        flex-basis min-width

    .descricao-confirmacao
        font-size 12px
        color #777777

    .valor-unitario
        text-align initial

    .sub-total
        text-align end

</style>
