<template>
    <div>
        <v-dialog
            v-model="mostrarDialog"
            persistent width="600px">
            <v-card class="dialog-signatario-whatsapp-assinando">

                <v-card-title class="primary white--text">
                    <span>{{ $tc("pt_br.signatario", 2) }} assinando via WhatsApp!</span>
                    <v-spacer></v-spacer>
                    <v-icon @click="fecharDialog" color="white"> mdi-window-close </v-icon>
                </v-card-title>

                <v-card-text class="text-center mt-2">
                    <p>
                        Os {{ $tc("pt_br.signatario", 2).toLowerCase() }} listados abaixo encontram assinando um {{ $tc("pt_br.documento").toLowerCase() }} via <span class="font-weight-bold">WhatsApp</span>
                        <span v-if="priorizar"> em sua organização</span> no momento.
                    </p>

                    <signatarios-whatsapp-asinando-tabela :signatarios-impedidos="signatariosImpedidos"/>

                    <template v-if="priorizar">
                        <p>
                            Clique em <span class="font-weight-bold">PRIORIZAR ESTE {{ $tc("pt_br.documento").toUpperCase() }}</span>, para que o convite desse {{ $tc("pt_br.documento").toLowerCase() }} seja enviado e assinado pelo(s) {{ $tc("pt_br.signatario").toLowerCase() }}(s).
                            Caso opte por aguardar a assinatura do(s) {{ $tc("pt_br.documento").toLowerCase() }}(s) anterior(es) clique em <span class="font-weight-bold">Fechar</span>.
                        </p>

                        <p>Ao optar por priorizar esse {{ $tc("pt_br.documento").toLowerCase() }}, fique ciente que os demais {{ $tc("pt_br.documento", 2).toLowerCase() }} anteriores necessitarão do reenvio do convite de assinatura.</p>
                    </template>

                    <template v-else>
                        <p>Aguarde o termino da assinatura do {{ $tc("pt_br.documento").toLowerCase() }} anterior por seus {{ $tc("pt_br.signatario", 2).toLowerCase() }}.</p>
                    </template>
                </v-card-text>

                <v-card-actions class="acoes justify-center">
                    <div class="secundaria">
                        <v-btn depressed @click="fecharDialog">Fechar</v-btn>
                    </div>

                    <div class="principal ml-6" v-if="priorizar">
                        <v-btn color="primary" @click="priorizarAssinatura">Priorizar Esse {{ $tc("pt_br.documento") }}</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>

import SignatariosWhatsappAsinandoTabela from '../SignatarioWhatsappAssinando/SignatariosWhatsappAsinandoTabela'
import {actionTypes} from '@/commons/constants'

export default {
    name: 'DialogInfoSignatarioWhatsappAssinando',
    components: {
        SignatariosWhatsappAsinandoTabela,
    },
    props: {
        mostrarDialog: {
            type: Boolean,
            required: true
        },
        signatariosImpedidos: {
            type: Array,
            required: true
        },
        mostrarOutros: {
            type: Boolean,
            default: false
        },
        priorizar: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dialogConfirmarPrioridade: false,
        }
    },
    computed: {
        possuiImpedidosDaOrganizacao() {
            return this.signatariosImpedidos.daOrganizacao.length > 0
        },
        possuiImpedidosOutros() {
            return this.signatariosImpedidos.outros.length > 0
        },
        subtituloConfirmarPrioridade() {
            return `Você quer mesmo priorizar a assinatura deste ${this.$tc("pt_br.documento")}?`
        },
    },
    methods: {
        alterarPriorizar() {
            this.$emit('alterar-priorizar')
        },
        fecharDialog() {
            this.$emit('fechar')
        },
        async priorizarAssinatura() {
            this.setMensagemLoading(`Priorizando ${this.$tc("pt_br.signatario")}...`)
            this.habilitarLoadingGlobal()
            try {
                await this.$store.dispatch(actionTypes.SIGNATARIO.PRIORIZAR_SIGANTARIOS_WHATSAPP, {
                    signatariosImpedidos: this.signatariosImpedidos
                })
                this.mostrarNotificacaoSucesso('Prioridades definidas')
                this.alterarPriorizar()
                if(!this.mostrarOutros) {
                    this.fecharDialog()
                }
            } catch (e) {
                this.mostrarNotificacaoErro('Não foi possível redefinir as prioridades!')
            } finally {
                this.desabilitarLoadingGlobal()
            }
        },
        removerTelefoneImpedido(telefone) {
            const index = this.signatariosImpedidos.daOrganizacao.indexOf(telefone);
            if (index !== -1) {
                this.signatariosImpedidos.daOrganizacao.splice(index, 1);
            }
        }
    }
}
</script>