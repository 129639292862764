import axios from 'axios'

export default class PlanoAssinaturaUtils {
    static async limiteContratosAtingido(organizacaoId) {
        try {
            await axios.get(`api/configuracoes/organizacoes/${organizacaoId}/plano/validar-documento`)
            return false
        } catch (e) {
            return true
        }
    }

    static async limiteUsuariosAtingido(organizacaoId) {
        try {
            await axios.get(`api/configuracoes/organizacoes/${organizacaoId}/plano/validar-usuario`)
            return false
        } catch (e) {
            return true
        }
    }
}
