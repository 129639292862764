import axios from 'axios'
import {actionTypes, mutationTypes} from '@/commons/constants'
import {AzSearchUrlBuilder} from '@azinformatica/loki'

export default {
    async [actionTypes.USUARIO.BUSCAR_POR_ORGANIZACAO](context, {organizacaoId, filtros, paginacao}) {
        const url = AzSearchUrlBuilder.build(`api/usuarios/organizacoes/${organizacaoId}`, filtros, paginacao)
        const {data} = await axios.get(url)
        return data
    },

    async [actionTypes.USUARIO.ATUALIZAR_PREFERENCIAS_USUARIO]({commit, state}, preferencias) {
        const usuarioId = state.loki.user.id
        const url = AzSearchUrlBuilder.build(`api/usuarios/${usuarioId}/preferencias`)
        const {data} = await axios.post(url, preferencias)
        return data
    },

    async [actionTypes.USUARIO.BUSCAR_PREFERENCIAS_USUARIO]({commit, state}) {
        const usuarioId = state.loki.user.id
        const url = AzSearchUrlBuilder.build(`api/usuarios/${usuarioId}/preferencias`)
        const {data} = await axios.get(url)

        const paginacaoRecuperada = Object.assign({}, state.paginacaoContratos, data.ordenacao)
        commit(mutationTypes.PREFERENCIAS_USUARIO.SET_PREFERENCIAS, data)
        commit(mutationTypes.SET_PAGINACAO_BUSCA_CONTRATOS, paginacaoRecuperada)
    },

    async [actionTypes.USUARIO.INSERIR_USUARIO_ORGANIZACAO]({commit, state}, novoUsuario) {
        const {data} = await axios.post(`api/usuarios/`, novoUsuario)
        return data
    },
}
