<template>
    <v-dialog value="true" persistent fullscreen hide-overlay scrollable>
        <v-card>
            <v-card-title class="pa-0">
                <toobar @voltar="voltarParaMeusDocumentos" :titulo="getTitulo"/>
            </v-card-title>

            <v-card-text class="pa-0">
                <v-container fluid class="co-documento-novo">
                    <v-row no-gutters :style="getHeight">
                        <v-col class="formulario-documento pa-0" cols="12" sm="3">
                            <formulario-documento
                                    ref="formularioDocumentoNovo"
                                    class="ma-3 formulario"
                                    :documento="documento"
                            />

                            <div v-if="!ehMobile" class="d-flex justify-end pa-4 btn-continuar">
                                <v-btn color="primary"
                                       @click="continuarOnce"
                                       :disabled="disableContinuar">
                                    Continuar
                                    <v-icon right> mdi-arrow-right </v-icon>
                                </v-btn>
                            </div>
                        </v-col>

                        <v-col :style="getStyleBackground" cols="12" sm="9">
                            <div>
                                <upload :pdfs="pdfs" @adicionar-pdf="adicionarPdfs"/>
                                <lista-upload class="mb-6" :pdfs="pdfs" @remover-pdf="removerPdf"/>
                                <v-btn v-if="ehMobile"
                                       color="primary"
                                       class="btn-continuar btn-mobile"
                                       @click="continuarOnce"
                                       :disabled="disableContinuar">
                                    Continuar
                                    <v-icon right> mdi-arrow-right </v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {actionTypes} from '@/commons/constants'
import Toobar from '@/views/criar-documento/components/toobar/Toobar'
import Upload from '@/views/criar-documento/novo-documento/components/documento/campos-upload/Upload'
import ListaUpload from './components/documento/campos-upload/listaUpload/ListaUpload'
import FormularioDocumento from './components/documento/campos-formulario/FormularioDocumento'
import DateTimeUtils from '@/commons/utils/DateTimeUtils'
import moment from 'moment-timezone'
import Utils from '@/commons/utils/Utils'
import RouterUtils from '@/commons/utils/RouterUtils'
import _ from 'lodash'

export default {
    name: "NovoDocumento",
    components: {FormularioDocumento, ListaUpload, Upload, Toobar},
    data() {
        return {
            documento: {
                nome: '',
                nomeDocOriginal: '',
                inicioVigencia: '',
                fimVigencia: '',
                inicioAssinatura: '',
                fimAssinatura: '',
                tags: [],
                pastaId: null
            },
            disableContinuar: false,
            pdfs: []
        }
    },
    computed: {
        ...mapState(['pastaAtual']),
        ...mapGetters(['podeCriarContratoRaiz', 'ehAdministradorOrganizacao']),
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        },
        getHeight() {
            return this.ehMobile ? ' height: auto' : ' height: 100%'
        },
        getStyleBackground() {
            return this.ehMobile ? 'background: var(--background-200)' : 'background: white'
        },
        getTitulo() {
            return `Novo ${this.$tc("pt_br.documento")}`
        }
    },
    watch: {
        pdfs() {
            if (!this.documento.nome) {
                this.documento.nome = this.pdfs[0].name.slice(0, -4)
            }
        }
    },
    created() {
        this.validarPastaAtual()
    },
    mounted() {
        this.inicializarModeloDocumento()
    },
    methods: {
        ...mapActions([actionTypes.CRIAR_CONTRATO]),
        adicionarPdfs(files) {
            this.pdfs.push(...files)
        },
        atualizarDatasISO(documento) {
            documento.inicioVigencia = DateTimeUtils.setZeroHorasISO(documento.inicioVigencia)
            documento.fimVigencia = DateTimeUtils.set23HorasISO(documento.fimVigencia)
            documento.inicioAssinatura = DateTimeUtils.setZeroHorasISO(documento.inicioAssinatura)
            documento.fimAssinatura = DateTimeUtils.set23HorasISO(documento.fimAssinatura)
        },
        construirRequisicao() {
            const documento = _.clone(this.documento)
            this.atualizarDatasISO(documento)
            documento.nomeDocOriginal = this.pdfs[0].name
            const arquivo = new FormData()
            this.pdfs.forEach(a => {
                arquivo.append('arquivo', a)
            })
            arquivo.append('contrato', JSON.stringify(documento))
            return arquivo
        },
        async continuarOnce() {
            this.disableContinuar = true
            setTimeout(() => {
                this.criarNovoDocumento()
            },500)
        },
        async criarNovoDocumento() {
            const dadosValidos = await this.validarDadosFormulario()
            if (!dadosValidos) {
                this.disableContinuar = false
                return
            }
            try {
                this.setMensagemLoading('Salvando documento...')
                const {data} = await this.$store.dispatch(actionTypes.CRIAR_CONTRATO, this.construirRequisicao())
                this.mostrarNotificacaoSucessoDefault()
                let tipoDefault = this.$route.params.tipoDefault
                await this.$router.push({name: 'novoDocumentoSignatario', params: {id: data.contratoId, tipoDefault:tipoDefault}})
            } catch (e) {
                this.disableContinuar = false
                const exceptionType = e.response.data.exception
                if (exceptionType && exceptionType.search('LimiteDocumentosAtingidoException') !== -1) {
                    this.mostrarLimitePlanoAtingido = true
                }
                await this.resetDatas()
            }
        },
        fecharDialogLimitePlanoAtingido() {
            this.mostrarLimitePlanoAtingido = false
        },
        inicializarModeloDocumento() {
            let criacao = moment().format('YYYY-MM-DD')
            let vencimento = moment().add(7, 'days').format('YYYY-MM-DD')
            this.documento.inicioVigencia = criacao
            this.documento.inicioAssinatura = criacao
            this.documento.fimAssinatura = vencimento

            if (this.pastaAtual.pastaId) {
                this.documento.pastaId = this.pastaAtual.pastaId
            }
        },
        removerPdf(pdf) {
            const index = this.pdfs.indexOf(pdf)
            if (index > -1) {
                this.pdfs.splice(index, 1)
            }
        },
        resetDatas() {
            this.documento.inicioVigencia = moment(this.documento.inicioVigencia).format('YYYY-MM-DD')
            this.documento.fimVigencia = moment(this.documento.fimVigencia).format('YYYY-MM-DD')
            this.documento.inicioAssinatura = moment(this.documento.inicioAssinatura).format('YYYY-MM-DD')
            this.documento.fimAssinatura = moment(this.documento.fimAssinatura).format('YYYY-MM-DD')
        },
        validarArquivoSelecionado() {
            if (!this.pdfs || this.pdfs.length <= 0) {
                this.mostrarNotificacaoErro('Arquivo(s) não selecionado(s).')
                return false
            }
            return true
        },
        async validarDadosFormulario() {
            const validado = await this.$refs.formularioDocumentoNovo.validarFormulario()
            const datasValidas = this.validarDatasInicioFimVigencia()
            const arquivoSelecionado = this.validarArquivoSelecionado()
            const validarDocumentoRaiz = await this.validarCriarDocumentoRaiz()
            if (!datasValidas || !validarDocumentoRaiz) {
                this.$refs.formularioDocumentoNovo.abrirOpcoesAvancadas()
            }
            return validado && datasValidas && arquivoSelecionado && validarDocumentoRaiz
        },
        validarDatasInicioFimVigencia() {
            if (!this.documento.inicioVigencia) {
                return false
            }
            if (this.documento.fimVigencia) {
                const fimVigencia = moment(this.documento.fimVigencia)
                const inicioVigencia = moment(this.documento.inicioVigencia)
                if (inicioVigencia.isAfter(fimVigencia)) {
                    this.mostrarNotificacaoErro('A data de término da vigência não pode ser anterior ao início da vigência.')
                    return false
                }
            }
            return true
        },
        async validarCriarDocumentoRaiz() {
            if (!this.podeCriarContratoRaiz && (!Utils.possuiValor(this.documento.pastaId))) {
                if (this.ehAdministradorOrganizacao) {
                    this.mostrarNotificacaoErro(`Não é permitido criar ${this.$tc('pt_br.documento').toLowerCase()}s na RAIZ, selecione uma pasta ou mude a configuração.`)
                } else {
                    this.mostrarNotificacaoErro(`Não é permitido criar  ${this.$tc('pt_br.documento').toLowerCase()}s na RAIZ, selecione uma pasta ou contate o Administrador da sua Organização.`)
                }
                return false
            }
            return true
        },
        voltarParaMeusDocumentos() {
            RouterUtils.voltarParaMeusDocumentos(this.meusContratosBusca.filtros.formalizacao.value)
        }
    }
}
</script>

<style lang="stylus">
.co-documento-novo
    background var(--background-200) !important

    .col-12, .col-sm-3
        padding 0

    .btn-continuar
        width 100%
        bottom 0
        z-index 999
        background var(--background-200) !important

    .btn-mobile
        position absolute
        height 60px !important

    .formulario-documento
        position relative
        height auto
        background-color var(--background-200)

        .formulario
            overflow auto
            height calc(100vh - 118px)

@media (max-width: 600px)
    .co-documento-novo
        .formulario-documento
            .formulario
                height auto
</style>