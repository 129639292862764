var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"co-compras-tabela"},[_c('v-data-table',{staticClass:"tabela-compras",attrs:{"hide-default-footer":"","no-data-text":'Nenhuma compra encontrada.',"headers":_vm.headers,"items-per-page":_vm.itemsPerPage,"items":_vm.itens},scopedSlots:_vm._u([{key:"item.itens",fn:function(ref){
var item = ref.item;
return [_c('ul',{staticClass:"listProdutos"},_vm._l((item.adicionais),function(item){return _c('li',{key:item.id},[_c('span',{staticClass:"itensComprados"},[_vm._v(" "+_vm._s(item.tipoAdicionalCompra.replaceAll('_', ' '))+" "+_vm._s(_vm.tipoSelecionado === "Cobranças de Assinaturas" ? '' : ("(" + (item.quantidade) + ")"))+" ")])])}),0)]}},{key:"item.data",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatHora(item.data))+" ")]}},{key:"item.valorTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatReal(item.valorTotal))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{staticClass:"acoes-tabela"},[_c('span',{staticClass:"status d-flex align-center",style:(_vm.getStyleConteudo(item.status))},[_vm._v(_vm._s(item.status))]),(_vm.ehCompraPendente(item.status) || _vm.ehCobrancaExpirada(item.status))?_c('v-btn',{staticClass:"ml-2",attrs:{"color":"green white--text","depressed":"","small":""},on:{"click":function($event){return _vm.abrirLink(item.linkPagamento)}}},[_vm._v(" PAGAR "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }