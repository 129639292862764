<template>
    <div>
        <dialog-selecionar-pasta
            v-if="dialogSelecionar"
            :dialog="dialogSelecionar"
            :pasta-selecionada="pastaSelecionada"
            :verificarPastaSelecionada="verificarPastaSelecionada"
            :eh-sub-pasta-liberada="ehSubPastaLiberada"
            @fechar="fecharDialogs"
            @selecionar="selecionarPasta"
        />

        <dialog-default-confirmar-acao
            v-if="dialogConfirmar"
            :dialog="dialogConfirmar"
            sentimento="atencao"
            titulo="Eita! Deixa eu confirmar uma coisa..."
            :subtitulo="mensagemConfirmar"
            label-botao-principal="MOVER"
            :loading="loadingAcao"
            @acao-principal="mover"
            @fechar="fecharDialogs"
        />

    </div>
</template>

<script>
import {actionTypes, mensagensMover, mutationTypes} from '@/commons/constants'
import ClmDialog from '@/commons/components/ClmDialog'
import ClmOps from '@/commons/components/ClmOps'
import {mapGetters, mapState} from 'vuex'
import Utils from '@/commons/utils/Utils'
import DialogDefaultConfirmarAcao from '@/commons/components/dialogs/DialogDefaultConfirmarAcao'
import DialogSelecionarPasta from '@/views/common/dialogs/DialogSelecionarPasta'

export default {
    name: 'DialogMoverElemento',
    components: {DialogSelecionarPasta, DialogDefaultConfirmarAcao, ClmDialog, ClmOps},
    props: {
        dialog: {
            type: Boolean,
            default: false
        },
        elemento: {
            type: Object,
            required: true
        },
        destino: {
            type: Object
        }
    },
    data() {
        return {
            caseSensitive: false,
            dialogSelecionar: false,
            dialogConfirmar: false,
            mensagemConfirmar: '',
            search: null,
            loadingAcao: false,
            pastaSelecionada: {}
        }
    },
    async created() {
        await this.validarPastaAtual()
    },
    mounted() {
        if (!!this.destino && this.verificarPastaSelecionada(this.destino)) {
            this.abrirDialogConfirmar()
        } else {
            this.atualizarPastaAtual()
            this.abrirDialogSelecionarPasta()
        }
    },
    computed: {
        ...mapState(['contrato', 'login', 'pastaAtual', 'meusContratos']),
        ...mapGetters(['podeCriarContratoRaiz', 'ehAdministradorOrganizacao']),
        ehContrato() {
            return Utils.possuiValor(this.elemento.contratoId)
        }
    },
    methods: {
        abrirDialogConfirmar() {
            this.setMensagemConfirmar()
            this.dialogConfirmar = true
        },
        abrirDialogSelecionarPasta() {
            this.dialogSelecionar = true
        },
        atualizarContagemPasta(pasta) {
            pasta.quantidadeContratos += 1
            if(!!this.pastaAtual){
                this.pastaAtual.quantidadeContratos -= 1
            }
        },
        atualizarPastaAtual() {
            if (this.ehContrato) {
                this.pastaSelecionada.pastaId = this.elemento.pastaId
                this.pastaSelecionada.pastaNome = this.elemento.pastaNome
            } else {
                this.pastaSelecionada.pastaId = this.pastaAtual.pastaId
                this.pastaSelecionada.pastaNome = this.pastaAtual.pastaNome
            }
        },
        ehElementoNaMesmaPasta(pasta) {
            return this.elemento.pastaId === pasta.pastaId
        },
        ehMesmaPasta(pasta) {
            return this.elemento.pastaId === pasta.pastaId
        },
        ehMoverDaRaizParaPasta(destino, atual) {
            return atual === null && destino.pastaId !== undefined
        },
        ehMoverDePastaParaPasta(destino, atual) {
            return atual !== null && destino.pastaId === undefined
        },
        ehSubPastaLiberada(pasta) {
            return this.ehContrato || !this.ehMesmaPasta(pasta)
        },
        fecharDialogs() {
            this.dialogConfirmar = false
            this.dialogSelecionar = false
            this.$emit('fechar')
        },
        async mover() {
            this.loadingAcao = true
            try {
                if (this.ehContrato) {
                    await this.moverContrato();
                } else {
                    await this.moverPasta();
                }
                this.fecharDialogs()
            } finally {
                this.loadingAcao = false
            }
        },
        async moverContrato() {
            const contratoId = this.elemento.contratoId
            const pastaId = !!this.destino ? this.destino.pastaId : this.pastaSelecionada.pastaId
            let contratoAtualizado = await this.$store.dispatch(actionTypes.MOVER_CONTRATO_PASTA, {
                contratoId,
                contratoPasta: {
                    pastaId,
                    contratoId
                }
            })
            this.$store.commit(mutationTypes.SET_CONTRATO, contratoAtualizado)
            if (!!this.destino) {
                this.atualizarContagemPasta(this.destino)
            }
            this.removerContratoPasta(contratoId)
            this.mostrarNotificacaoSucessoDefault()
        },
        async moverPasta() {
            const pastaId = this.elemento.pastaId
            const pastaDestinoId = !!this.destino ? this.destino.pastaId : this.pastaSelecionada.pastaId
            await this.$store.dispatch(actionTypes.PASTAS.MOVER_PASTA, {
                pastaId,
                pastaPaiId: pastaDestinoId
            })
            if (!!this.pastaAtual) {
                if (!!this.destino) {
                    await this.moverEntrePastas(this.pastaAtual, this.destino, pastaId);
                } else {
                    await this.moverEntrePastasSemPastaPai(this.pastaAtual, pastaId, pastaDestinoId)
                }
            }
            this.mostrarNotificacaoSucessoDefault()
        },
        removerContratoPasta(contratoId) {
            const meusContratos = this.meusContratos.filter(contrato => contrato.contratoId !== contratoId)
            this.$store.commit(mutationTypes.SET_MEUS_CONTRATOS, meusContratos)
        },
        selecionarPasta() {
            this.abrirDialogConfirmar()
        },
        setMensagemConfirmar() {
            const pastaDestino = !!this.destino ? this.destino : this.pastaSelecionada
            const pastaAtualId = this.elemento.pastaId

            if (this.ehMoverDaRaizParaPasta(pastaDestino, pastaAtualId)) {
                this.mensagemConfirmar = this.ehContrato ? mensagensMover.MENSAGEM_MOVER_CONTRATO_DA_RAIZ_PARA_PASTA : mensagensMover.MENSAGEM_MOVER_PASTA_DA_RAIZ_PARA_PASTA
            }
            else if (this.ehMoverDePastaParaPasta(pastaDestino, pastaAtualId)) {
                this.mensagemConfirmar = this.ehContrato ? mensagensMover.MENSAGEM_MOVER_CONTRATO_PASTA_PARA_RAIZ : mensagensMover.MENSAGEM_MOVER_PASTA_DA_PASTA_PARA_RAIZ
            }
            else {
                this.mensagemConfirmar = this.ehContrato ? mensagensMover.MENSAGEM_MOVER_CONTRATO_ENTRE_PASTAS : mensagensMover.MENSAGEM_MOVER_PASTA_ENTRE_PASTAS
            }
        },
        verificarPastaSelecionada(pasta) {
            if (! !!pasta) {
                return false
            }
            if (this.ehElementoNaMesmaPasta(pasta)) {
                return false
            }
            if (this.ehContrato) {
                if (!this.verificarMoverParaRaiz(pasta.pastaId)) {
                    return false
                }
            } else {
                if (this.ehMesmaPasta(pasta)) {
                    return false
                }
            }

            return true
        },
        verificarMoverParaRaiz(pastaId) {
            if (!this.podeCriarContratoRaiz && !Utils.possuiValor(pastaId)) {
                if (this.ehAdministradorOrganizacao) {
                    this.mostrarNotificacaoErro(`Não é permitido mover ${this.$tc('pt_br.documento').toLowerCase()}s para a RAIZ, selecione uma pasta ou mude a configuração.`)
                } else {
                    this.mostrarNotificacaoErro(`Não é permitido mover ${this.$tc('pt_br.documento').toLowerCase()}s para a RAIZ, selecione uma pasta ou contate o Administrador da sua Organização.`)
                }
                return false
            }
            return true
        },
    }
}
</script>
