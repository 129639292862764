<template>
    <div v-if="ehPaginaComPastas" class="co-home-pastas ml-4 mt-4">
        <v-col cols="12" class="titulo">
            Pastas
        </v-col>

        <v-row no-gutters>
            <v-col v-for="(pasta, index) in subPastasAtual" :key="index" cols="12"
                   :sm="modoGrade ? '6' : '12'"
                   :md="modoGrade ? '4' : '12'"
                   :xl="modoGrade ? '3' : '12'"
                   class="pr-3 mt-3 align-center">
                <pasta :pasta="pasta"
                       @editar-pasta="editarPasta"
                       @excluir-pasta="abrirDialogExcluirPasta"
                       @mover-pasta="abrirDialogMoverPasta"
                       @mover-drop="abrirDialogConfirmarMoverPasta"
                       @compartilhar-pasta="abrirDialogCompartilharPasta"
                       @entrar-pasta="entrarPasta"/>
            </v-col>
        </v-row>

        <dialog-pasta
            v-if="dialogPasta"
            :dialog="dialogPasta"
            :pasta-editar="pastaAcao"
            :pasta-pai="pastaAtual"
            @fechar="fecharDialogPasta"
            editar
        />

        <dialog-default-confirmar-acao
            v-if="dialogExcluirPasta"
            :dialog="dialogExcluirPasta"
            sentimento="atencao"
            titulo="Eita! Deixa eu confirmar uma coisa..."
            subtitulo="Você quer mesmo remover esta pasta ?"
            label-botao-principal="Sim, eu quero remover!"
            :loading="loadingAcao"
            @acao-principal="acaoExcluirPasta"
            @fechar="fecharDialogExcluirPasta"
        />

        <dialog-pasta-compartilhamento
            :dialog="dialogCompartilharPasta"
            v-if="dialogCompartilharPasta"
            :pasta="pastaAcao"
            @fechar="fecharDialogCompartilharPasta"
        />

        <dialog-mover-elemento
            v-if="dialogMover.abrirDialog"
            :dialog="dialogMover.abrirDialog"
            :elemento="dialogMover.elemento"
            :destino="dialogMover.destino"
            @fechar="fecharDialoglMover"
        />
    </div>
</template>

<script>
import DialogDefaultConfirmarAcao from '@/commons/components/dialogs/DialogDefaultConfirmarAcao'
import DialogPastaCompartilhamento from './components/dialogs/DialogPastaCompartilhamento'
import DialogMoverElemento from '../common/DialogMoverElemento'
import DialogPasta from '@/views/common/dialogs/DialogPasta'
import Pasta from './components/pasta/Pasta'
import {actionTypes} from '@/commons/constants'
import {mapState} from 'vuex'


export default {
    name: 'Pastas',
    components: {
        DialogMoverElemento,
        DialogPastaCompartilhamento,
        Pasta,
        DialogDefaultConfirmarAcao,
        DialogPasta
    },
    props: {
        modoGrade: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            dialogExcluirPasta: false,
            dialogCompartilharPasta: false,
            dialogPasta: false,
            pastaAcao: {},
            loadingAcao: false,
            dialogMover: {
                abrirDialog: false,
                elemento: null,
                destino: null
            }
        }
    },
    computed: {
        ...mapState(['login', 'pastaAtual', 'subPastasAtual']),
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        },
        ehPaginaComPastas() {
            return this.subPastasAtual && this.subPastasAtual.length > 0
        },
    },
    methods: {
        abrirDialogCompartilharPasta(pasta) {
            this.dialogCompartilharPasta = true
            this.pastaAcao = {
                pastaId: pasta.pastaId
            }
        },
        abrirDialogExcluirPasta(pasta) {
            this.dialogExcluirPasta = true
            this.pastaAcao = pasta
        },
        abrirDialogMoverPasta(elemento) {
            this.dialogMover.abrirDialog = true
            this.dialogMover.elemento = elemento
        },
        abrirDialogConfirmarMoverPasta({pastaDestino, elemento}) {
            this.dialogMover.abrirDialog = true
            this.dialogMover.elemento = elemento
            this.dialogMover.destino = pastaDestino
        },
        abrirDialogPasta() {
            this.dialogPasta = true
        },
        async acaoExcluirPasta() {
            this.habilitarLoadingGlobal()
            this.loadingAcao = true
            try {
                this.setMensagemLoading('Excluindo pasta...')
                await this.$store.dispatch(actionTypes.PASTAS.REMOVER_PASTA, this.pastaAcao)
                this.excluirPasta(this.pastaAtual, this.pastaAcao.pastaId)
                this.mostrarNotificacaoSucessoDefault()
                this.fecharDialogExcluirPasta()
            } catch (e) {
                if (e.response.data.exception.includes('PastaComContratosNaoPodeSerRemovidaException') ||
                    e.response.data.exception.includes('PastaComSubPastasNaoPodeSerRemovidaException')) {
                    this.mostrarNotificacaoErro("Não é possível excluir pasta com conteúdo!")
                } else {
                    throw e
                }
                this.fecharDialogExcluirPasta()
            } finally {
                this.loadingAcao = false
                this.desabilitarLoadingGlobal()
            }
        },
        editarPasta(pasta) {
            this.pastaAcao = pasta
            this.abrirDialogPasta()
        },
        async entrarPasta(pasta) {
            await this.entrarNaPasta(pasta)
        },
        fecharDialogExcluirPasta() {
            this.dialogExcluirPasta = false
        },
        fecharDialogCompartilharPasta() {
            this.dialogCompartilharPasta = false
        },
        fecharDialoglMover() {
            this.dialogMover.abrirDialog = false
            this.dialogMover.elemento = null
            this.dialogMover.destino = null
        },
        fecharDialogPasta() {
            this.dialogPasta = false
        }
    }
}
</script>

<style lang="stylus">
.co-home-pastas
    .titulo
        color var(--text-color-300)

</style>