<template>
    <div class="co-novo-documento-signatario-chave-seguranca">
        <span>Chave de segurança</span>

        <icon-info :descricao-campo="descricaoCampo"/>
        
        <v-text-field
            v-model="value.chaveSeguranca"
            :error-messages="errors.collect('Chave de seguranca')"
            name="chave"
            class="pt-0 mt-0"
            placeholder="Informe a chave de segurança"
            persistent-placeholder
            :disabled="disabled"
        />
    </div>
</template>

<script>

import IconInfo from '@/views/criar-documento/novo-documento-signatario/components/IconInfo'

export default {
    name: 'ChaveSegurancaNovo',
    components: {IconInfo},
    props: {
        value: {
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            descricaoCampo: 'Aqui você pode definir uma chave de segurança que só você e o '+ 
                this.$tc("pt_br.signatario").toLowerCase() + ' saberão. Isso é uma medida para dar mais segurança ao processo de' +
                ' assinatura, garantindo que outra pessoa não conseguirá assinar no lugar deste ' + this.$tc("pt_br.signatario").toLowerCase() + '.'
        }
    }
}
</script>

<style lang="stylus">
.co-novo-documento-signatario-chave-seguranca
    position relative
    width 100%

    .chave-seguranca-field
        padding-top 0
        margin-top 0

    .label
        color var(--text-color-300)

</style>