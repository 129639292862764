<template>
     <span class="co-novo-documento-signatario-icon-info">
          <v-tooltip bottom max-width="500px">
              <template v-slot:activator="{ on }">
                  <v-icon v-on="on" class="icon-info"> mdi-information </v-icon>
              </template>
              <span>{{ descricaoCampo }}</span>
          </v-tooltip>
    </span>
</template>

<script>
export default {
    name: 'IconInfo',
    props: {
        descricaoCampo: {
            type: String
        }
    }
}
</script>

<style lang="stylus">
.co-novo-documento-signatario-icon-info
    .icon-info
        font-size 13px
        padding-bottom 10px
</style>