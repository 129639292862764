<template>
    <div class="co-signaraios-whatsapp-assinando-tabela">
        <v-data-table
            class="tabela-signatarios-assinando"
            hide-default-footer
            no-data-text="Erro ao obter resposta."
            :headers="headers"
            :items="signatariosImpedidos"
        >
            <template v-slot:item.telefone="{ item }">
                {{ aplicarMascaraTelefoneBrasil(item.telefone) }}
            </template>

        </v-data-table>
    </div>
</template>

<script>
export default {
    name: 'SignaraiosWhatsappAssinandoTabela',
    props: {
        signatariosImpedidos: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            headers: [
                {
                    text: `Telefone do ${this.$tc("pt_br.signatario")}`,
                    value: 'telefone',
                    align: 'center',
                    sortable: false,
                }
            ]
        }
    },
    methods: {
        aplicarMascaraTelefoneBrasil(telefone) {
            if (telefone.slice(0, 2) === "55") {
                let pattern = '+## (##) # ####-####'
                let i = 0;
                let v = telefone.toString();
                v = v.replace(/\D/g, '');
                return pattern.replace(/#/g, () => v[i++] || '');
            } else {
                return '+' + telefone;
            }
        }
    }
}
</script>

<style lang="stylus">
.co-signaraios-whatsapp-assinando-tabela

    .v-input--selection-controls.v-input .v-label
        font-size 16px
        color #555

    .tabela-signatarios-assinando
        color unset
        .v-table thead tr
            height: 40px

        .v-table tbody td
            background-color white !important
            height 40px
            border-top 1px solid #ccc
            text-align center

        .v-table thead th
            background-color var(--v-primary-lighten1) !important
            color white !important
            font-weight normal !important

</style>