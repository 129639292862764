<template>
    <div v-if="exibir" class="co-label-adquirir-saldo">
        <span :class="getStyle">Saldo: <span>{{ saldo }}</span> disparos restantes</span>

        <v-spacer></v-spacer>

        <span class="comprar-mais" @click="acao">COMPRAR MAIS</span>
    </div>
</template>

<script>
export default {
    name: 'LabelAdquirirSaldo',
    props: {
        exibir: {
            type: Boolean
        },
        saldo: {
            type: Number,
            default: 0
        }
    },
    computed: {
        getStyle() {
            return this.saldo === 0 ? 'saldo-zerado' : 'saldo'
        }
    },
    methods: {
        acao() {
            this.$emit('adquirir-saldo')
        }
    }
}
</script>

<style lang="stylus">
.co-label-adquirir-saldo
    display flex
    margin-bottom 8px

    .saldo-zerado
        color var(--v-error-base)
        font-weight bold
        font-size 10px

    .saldo
        color #777
        font-weight bold
        font-size 10px

    .comprar-mais
        color green
        font-weight bold
        font-size 10px
        transition all 500ms
        cursor pointer

    .comprar-mais:hover
        padding 0 7px
        background rgba(240, 240, 240, .8)
        font-size 10px
        color lighten(green, 20%)

</style>