import { render, staticRenderFns } from "./MaisOpcoesPastas.vue?vue&type=template&id=6e2f1094"
import script from "./MaisOpcoesPastas.vue?vue&type=script&lang=js"
export * from "./MaisOpcoesPastas.vue?vue&type=script&lang=js"
import style0 from "./MaisOpcoesPastas.vue?vue&type=style&index=0&id=6e2f1094&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports