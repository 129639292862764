<template>
    <v-dialog v-model="naoPossuiSMS" width="500" persistent>
        <v-card class="clm-ops-acabou-sms py-2">
            <v-icon class="btn-fechar" small @click="fecharClmOpsAcabouSMS"> mdi-window-close </v-icon>
            <v-flex class="text-xs-center">
                <div style="font-size: 24px; color: #666" class="my-2 mx-2">O saldo de SMS acabou</div>
                <img src="../../../../../assets/ops/assustado.gif">
                <div class="texto">Opa, vejo aqui que gostaria de enviar uma SMS porém sinto em lhe
                    informar que o saldo de SMS chegou ao fim.
                </div>
                <div class="texto">Que tal resolvermos esse problema, solicite mais créditos clicando
                    <a @click.prevent="abrirChatSuporte" style="color: orange">AQUI</a>.
                </div>
            </v-flex>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ClmOpsAcabouSMS',
    props: {
        naoPossuiSMS: {
            type: Boolean,
            required: true
        },
    },
    methods: {
        fecharClmOpsAcabouSMS() {
            this.$emit('fecharClmOpsAcabouSMS')
        },
        abrirChatSuporte() {
            this.$chat.toggle()
        },
    }
}
</script>

<style lang="stylus">
.clm-ops-acabou-sms
    .texto
        color: #aaaaaa
        margin 10px 40px

    .btn-fechar
        position absolute
        z-index 1
        right 10px
        top 10px

</style>
