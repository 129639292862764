var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"co-contatos-tabela"},[_c('v-data-table',{staticClass:"tabela-signatarios",attrs:{"hide-default-footer":"","no-data-text":_vm.noDataText,"items-per-page":_vm.qtdLinhasTabela,"headers":_vm.headers,"items":_vm.cadastrosSignatarios},scopedSlots:_vm._u([{key:"item.contato",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.montarContato(item))+" ")]}},{key:"item.papel",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.converterPapel(item.papelId))+" ")]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{staticClass:"acoes-tabela"},[_c('v-tooltip',{directives:[{name:"az-auth",rawName:"v-az-auth",value:('CadastroSignatario.Editar'),expression:"'CadastroSignatario.Editar'"}],attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"botoes botoes-mobile",attrs:{"size":"15px"},on:{"click":function($event){return _vm.$emit('edit', item)}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{directives:[{name:"az-auth",rawName:"v-az-auth",value:('CadastroSignatario.Excluir'),expression:"'CadastroSignatario.Excluir'"}],attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"botoes",attrs:{"size":"15px"},on:{"click":function($event){return _vm.$emit('excluir', item)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Excluir")])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }