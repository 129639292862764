import axios from 'axios'
import {actionTypes} from '@/commons/constants'

export default {
    async [actionTypes.RELATORIO.GERAR]({commit, state}, {path}) {
        const organizacaoId = state.login.organizacaoPlano.organizacaoId

        const response = await axios.get(`api/relatorios/${path}/${organizacaoId}`, {responseType: 'blob'})
            .catch(async function (error){
                return JSON.parse(await error.response.data.text())
            })

        if (response.message) {
            return response
        }

        const urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;

        if (urlCreator) {
            return urlCreator.createObjectURL(response.data)
        }
    },
}