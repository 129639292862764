<template>
    <v-dialog :value="dialog" width="700" persistent :fullscreen="ehMobile">
        <v-card class="co-dialog-pasta-compartilhamento">
            <v-card-title class="title text-color-300--text">
                Gerenciar compartilhamento
                <v-spacer/>
                <v-icon color="text-color-300" @click="fechar"> mdi-window-close </v-icon>
            </v-card-title>

            <v-card-text class="conteudo">
                <pasta-compartilhamento-usuarios
                    :usuarios="usuariosComAcesso"
                    @remover="removerAcessoUsuario"
                    @fechar="fechar"
                />
            </v-card-text>

            <v-card-actions class="card-acoes">
                <pasta-compartilhamento-acoes
                    :usuariosSemAcesso="usuariosSemAcesso"
                    @atribuirAcesso="atribuirAcesso"
                />
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import _ from 'lodash'
import PastaCompartilhamentoUsuarios from './components/PastaCompartilhamentoUsuarios'
import {actionTypes} from '@/commons/constants'
import {mapState} from 'vuex'
import PastaCompartilhamentoAcoes from './components/PastaCompartilhamentoAcoes'

export default {
    name: 'DialogPastaCompartilhamento',
    components: {PastaCompartilhamentoAcoes, PastaCompartilhamentoUsuarios},
    props: {
        dialog: {
            type: Boolean,
            default: false
        },
        pasta: {
            required: true
        }
    },
    data() {
        return {
            usuariosComAcesso: [],
            usuariosSemAcesso: []
        }
    },
    async mounted() {
        await this.buscarUsuariosComAcesso()
        await this.buscarUsuariosSemAcesso()
    },
    computed: {
        ...mapState(['login']),
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        }
    },
    methods: {
        async atribuirAcesso(usuariosSemAcesso) {
            const usuarios = {
                usuariosId: usuariosSemAcesso
            }
            await this.$store.dispatch(actionTypes.PASTAS.ATRIBUIR_ACESSO_USUARIOS_PASTA, {
                pastaId: this.pasta.pastaId,
                usuarios
            })
            this.mostrarNotificacaoSucessoDefault()
            await this.buscarUsuariosComAcesso()
            await this.buscarUsuariosSemAcesso()
        },
        async buscarUsuariosComAcesso() {
            const pastaId = this.pasta.pastaId
            this.usuariosComAcesso = await this.$store.dispatch(actionTypes.PASTAS.BUSCAR_USUARIOS_COM_ACESSO_PASTA, pastaId)
        },
        async buscarUsuariosSemAcesso() {
            const organizacaoId = this.login.organizacaoPlano.organizacaoId

            const usuariosOrganizacao = await this.$store.dispatch(actionTypes.BUSCAR_USUARIOS_POR_ORGANIZACAO, {
                organizacaoId,
                filtros: {
                    situacao: {
                        value: 'ATIVO',
                        default: null
                    }
                }
            })

            this.usuariosSemAcesso = this.filtrarUsuariosSemAcesso(usuariosOrganizacao)
        },
        fechar() {
            this.$emit('fechar')
        },
        filtrarUsuariosSemAcesso(usuariosOrganizacao) {
            this.usuariosComAcesso.forEach(usuarioComAcessoId => {
                _.remove(usuariosOrganizacao, usuario => usuario.usuarioId === usuarioComAcessoId.id)
            })
            return usuariosOrganizacao
        },
        async removerAcessoUsuario(usuario) {
            const pastaId = this.pasta.pastaId
            const usuarioId = usuario.id
            await this.$store.dispatch(actionTypes.PASTAS.REMOVER_ACESSO_USUARIO_PASTA, {pastaId, usuarioId})
            await this.buscarUsuariosComAcesso(pastaId)
            await this.buscarUsuariosSemAcesso()
        }
    }
}
</script>

<style lang="stylus">
.co-dialog-pasta-compartilhamento
    .conteudo
        height 400px
        overflow auto

    .card-acoes
        width 100%

@media (max-width: 720px)
    .co-dialog-pasta-compartilhamento
        .card-acoes
            width 100%
            position absolute !important
            bottom 0 !important
</style>