<template>
    <v-flex class="co-home-contratos-lista-header d-flex align-center px-2">
        <v-col cols="11" class="dados">
            <v-col cols="4" class="dados">
                <div class="dados ml-2 mr-5">Título</div>
            </v-col>

            <v-col cols="2">
                <div class="dados">Tipo</div>
            </v-col>

            <v-col cols="3">
                <div class="dados">Status</div>
            </v-col>

            <v-col cols="3">
                <div class="dados">Tags</div>
            </v-col>
        </v-col>

        <v-col cols="1">
            <div class="dados justify-end">Ações</div>
        </v-col>
    </v-flex>
</template>

<script>
export default {
    name: "ContratoListaHeader"
}
</script>

<style lang="stylus">
.co-home-contratos-lista-header
    border 1px solid var(--background-300)
    background-color var(--background-200)
    border-radius 5px
    height 46px
    position relative

    .dados
        display flex
        height 45px
        width 100%
        position relative
        align-items center
        text-align left
        color var(--text-color-300)

</style>