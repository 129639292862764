<template>
    <div :class="classe">
        <az-pdf-document-viewer
            progress-bar
            ref="azPdfDocumentViewer"
            css-class="documentViwer"
            :height="alturaDocumentViewer"
            :src="pathDocumento"
        />

        <botao-scroll-documento :paginacao="paginacao"/>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import BotaoScrollDocumento from '../../commons/BotaoScrollDocumento.vue';

export default {
    name: 'Documento',
    props: ['pathDocumento'],
    components: {BotaoScrollDocumento},
    data() {
        return {
            classe: '',
            alturaDocumentViewer: '',
            ajusteTamanho: 11,
            paginacao: {
                paginaAtual: 0,
                totalPaginas: 0,
                ehUltimaPagina: false
            }
        }
    },
    mounted() {
        const pdfViewer = document.querySelector('#az-pdf-viewer')

        this.calcularAlturaDocumentViewer()

        pdfViewer?.addEventListener('scroll', this.lidarComPaginacaoAtual)
    },
    beforeDestroy() {
        const pdfViewer = document.querySelector('#az-pdf-viewer')

        pdfViewer?.removeEventListener('scroll', this.lidarComPaginacaoAtual);
    },
    computed: {
        ...mapState(['minutaAtual'])
    },
    watch: {
        minutaAtual() {
            const situacao = this.minutaAtual.situacaoFormalizacao
            if (situacao !== 'RASCUNHO' && situacao !== 'EM_FORMALIZACAO') {
                this.classe = 'co-documento mobile-only'
            } else {
                this.classe = 'co-documento mobile-only mostrar-formalizar'
            }
        }
    },
    methods: {
        calcularAlturaDocumentViewer() {
            window.setTimeout(() => {
                const element = document.getElementsByClassName('conteudo-aba')[0]
                this.alturaDocumentViewer = element.clientHeight + this.ajusteTamanho + 'px'
            }, 100)
        },
        lidarComPaginacaoAtual() {
            const paginaAtual = this.$refs.azPdfDocumentViewer.pagination.current
            const ultimaPagina = this.$refs.azPdfDocumentViewer.pagination.total

            this.paginacao.paginaAtual = paginaAtual
            this.paginacao.totalPaginas = ultimaPagina

            this.paginacao.ehUltimaPagina = paginaAtual === ultimaPagina
        },
    }
}
</script>

<style lang="stylus">
.documentViwer
    .az-pdf-toolbar
        height 49px !important

.co-documento
    height 100%
    overflow hidden

</style>