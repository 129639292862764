<template>
    <div class="co-novo-documento-signatario-email-signatario">
        <v-combobox
            ref="emailSignatarioNovo"
            v-model="value.email"
            v-validate="'required|email'"
            :class="campoRequerido ? 'required-field email-signatario' : ''"
            :error-messages="errors.collect('E-mail')"
            :items="signatariosSugestoes"
            :no-data-text="noDataText"
            name="E-mail"
            label="E-mail"
            hide-details
            :placeholder="placeholder"
            outlined
            dense
            persistent-placeholder
            @blur="blur"
        >
            <template v-slot:prepend-inner>
                <div class="email-left">Email</div>
            </template>
        </v-combobox>
    </div>
</template>

<script>
export default {
    name: 'EmailSignatarioNovo',
    props: {
        value: {
            required: true
        },
        campoRequerido: {
            type: Boolean,
            default: false
        },
        descricao: {
            type: Boolean
        },
        signatariosSugestoes: {
            type: Array,
        }
    },
    computed: {
        noDataText() {
            return `Não há ${this.$tc("pt_br.signatario", 2).toLowerCase()} cadastrados.`
        },
        placeholder() {
            return `E-mail do ${this.$tc("pt_br.signatario").toLowerCase()}`
        }
    },
    methods: {
        validarCampo() {
            return this.campoRequerido ? 'required' : ''
        },
        blur() {
           this.$refs.emailSignatarioNovo.blur();
        }
    }
}
</script>

<style lang="stylus">
.co-novo-documento-signatario-email-signatario

    .email-left
        color var(--primary)
        padding-top 5px

    .v-text-field__slot
        input
            padding-left 28px
</style>