import { render, staticRenderFns } from "./GruposContatos.vue?vue&type=template&id=3682065f"
import script from "./GruposContatos.vue?vue&type=script&lang=js"
export * from "./GruposContatos.vue?vue&type=script&lang=js"
import style0 from "./GruposContatos.vue?vue&type=style&index=0&id=3682065f&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports