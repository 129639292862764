import moment from 'moment'

class ControleAcoes {
    adicionarRemoverSignatarios(minuta) {
        const dataLimiteExpirada = this._dataLimiteAssinaturaExpirada(minuta)
        const formalizacaoNaoCompleta = this._minutaNaoFormalizadaCancelada(minuta.situacaoFormalizacao)
        const contratoArquivado = minuta.contratoArquivado
        return !dataLimiteExpirada && formalizacaoNaoCompleta && !contratoArquivado
    }

    arquivarContrato(contrato) {
        return !contrato.arquivado
    }

    baixarAssinado(totalAssinaturas) {
        return totalAssinaturas > 0
    }

    cancelarMinuta(minuta) {
        const contratoArquivado = this._contratoArquivado(minuta.contratoArquivado)
        const minutaCancelada = this._minutaCancelada(minuta.situacaoMinuta)
        const contratoExtinto = this._contratoExtinto(minuta.contratoExtinto)
        return !contratoArquivado && !minutaCancelada && !contratoExtinto
    }

    _contratoArquivado(contratoArquivado) {
        return contratoArquivado === true
    }

    _contratoExtinto(contratoExtinto) {
        return contratoExtinto === true
    }

    _dataLimiteAssinaturaExpirada(minuta) {
        const dataLimiteAssinatura = moment(minuta.fimAssinatura).endOf('day')

        return moment().isAfter(dataLimiteAssinatura)
    }

    excluirContrato(objetoExcluirContrato) {
        const minutaRascunho = this._minutaAtualEmRascunho(objetoExcluirContrato.situacaoMinuta)
        const minutaSemAssinaturas = this._minutaSemAssinaturas(objetoExcluirContrato.totalAssinaturas)
        return minutaRascunho && minutaSemAssinaturas
    }

    extinguirContrato(minutaAtual, contrato) {
        return minutaAtual.situacaoFormalizacao === 'FORMALIZADO' && !contrato.extinto
    }

    extinguirContratoPeloCard(contrato) {
        return contrato.minutaSituacaoFormalizacao === 'FORMALIZADO' && !contrato.extinto
    }

    _minutaAtualEmRascunho(situacaoMinuta) {
        return situacaoMinuta === 'RASCUNHO'
    }

    _minutaNaoFormalizadaCancelada(situacaoMinuta) {
        return situacaoMinuta !== 'FORMALIZADO' && situacaoMinuta !== 'CANCELADO'
    }

    _minutaCancelada(situacaoMinuta) {
        return situacaoMinuta === 'CANCELADO'
    }

    _minutaSemAssinaturas(quantidadeAssinatura) {
        return quantidadeAssinatura === 0
    }

    restaurarContrato(contrato) {
        return contrato.arquivado
    }

    _versaoInicial(versao) {
        return versao === 1
    }
}

const controleAcoes = new ControleAcoes()
export default controleAcoes
