<template>
    <v-container grid-list px-10 pt-10 pb-4 class="co-configuracoes-seguranca">
        <configuracoes-exclusivas :eh-sem-funcionalidade="ehPlanoSemPermissaoConfiguracaoGeral"/>

        <configuracao-titulo :titulo="this.$tc('pt_br.documento', 2)"/>

        <v-row no-gutters class="mx-3">
            <v-switch
                color="primary"
                v-model="configuracoes.documento.documentosNaRaiz"
                :label="`Permitir criação de ${this.$tc('pt_br.documento', 2).toLowerCase()} na raiz da plataforma.`"
                :disabled="ehPlanoSemEdicaoConfiguracaoGeral"
            />
        </v-row>

        <v-row no-gutters class="mx-3">
            <v-switch
                color="primary"
                v-model="configuracoes.documento.formalizarDocumentosAuto"
                :label="`Formalizar automaticamente os ${this.$tc('pt_br.documento', 2).toLowerCase()} após a finalização de todas as assinaturas`"
                :disabled="ehPlanoSemEdicaoConfiguracaoGeral"
            />
        </v-row>

        <v-row no-gutters class="mx-3">
            <v-switch
                color="primary"
                v-model="configuracoes.documento.signatariosRepetidos"
                label="Permitir que um mesmo signatário assine o documento repetidas vezes"
                :disabled="ehPlanoSemEdicaoConfiguracaoGeral"
            />
        </v-row>

        <v-row no-gutters class="mt-5">
            <div class="title">{{ $tc("pt_br.signatario", 2) }}</div>
        </v-row>

        <v-divider/>

        <v-row no-gutters class="mx-3">
            <v-switch
                color="primary"
                v-model="configuracoes.signatario.tokenAssinatura"
                :label="`Exigir o token de verificação na assinatura do ${this.$tc('pt_br.documento').toLowerCase()}.`"
                :disabled="ehPlanoSemPermissaoConfiguracaoGeral"
            />
        </v-row>

        <v-row no-gutters class="mt-5 mx-3">
            <v-col cols="12">
                <mensagem-email-signatario
                    informativo
                    v-model="configuracoes.signatario"
                    :disabled="ehPlanoSemEdicaoConfiguracaoGeral"
                />
            </v-col>
        </v-row>

        <v-row no-gutters class="mx-3">
            <v-col cols="12">
                <chave-seguranca
                    v-model="configuracoes.signatario"
                    informativo
                    :disabled="ehPlanoSemEdicaoConfiguracaoGeral"
                />
            </v-col>
        </v-row>

        <configuracao-salvar :disabled="ehPlanoSemPermissaoConfiguracaoGeral" @salvar="salvar" :loading="loading"/>
    </v-container>
</template>

<script>
import ConfiguracoesExclusivas from '../common/ConfiguracoesExclusivas'
import ChaveSeguranca
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/ChaveSeguranca'
import MensagemEmailSignatario
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/MensagemEmailSignatario'
import {mapGetters} from 'vuex'
import {actionTypes} from '@/commons/constants'
import {hasPermissions} from '@azinformatica/loki'
import _ from 'lodash'
import store from '@/commons/store'
import ConfiguracaoTitulo from '../common/ConfiguracaoTitulo'
import ConfiguracaoSalvar from '../common/ConfiguracaoSalvar'

export default {
    name: 'ConfiguracoesSeguranca',
    components: {
        ConfiguracaoSalvar,
        ConfiguracaoTitulo,
        ConfiguracoesExclusivas,
        ChaveSeguranca,
        MensagemEmailSignatario
    },
    data() {
        return {
            configuracoes: {
                documento: {
                    documentosNaRaiz: true
                },
                signatario: {
                    tokenAssinatura: true,
                    chaveSeguranca: '',
                    mensagem: ''
                },
            },
            loading: false
        }
    },
    async mounted() {
        await this.buscarConfiguracoes()
    },
    computed: {
        ...mapGetters(['ehEfcazCLM', 'ehPlanoFree']),
        ehPlanoSemEdicaoConfiguracaoGeral() {
            const userPermissions = this.$store.state.loki.user.authorities
            return !this.ehEfcazCLM && this.ehPlanoFree || !hasPermissions(userPermissions, ['Painel.Geral.Editar'])
        },
        ehPlanoSemPermissaoConfiguracaoGeral() {
            const userPermissions = this.$store.state.loki.user.authorities
            return !hasPermissions(userPermissions, ['Painel.Geral.Editar'])
        }
    },
    methods: {
        async buscarConfiguracoes() {
            this.configuracoes = _.cloneDeep(store.state.login.organizacaoConfiguracao.geral)
        },
        async salvar() {
            try {
                this.loading = true
                await this.$store.dispatch(actionTypes.CONFIGURACAO.ATUALIZAR_ORGANIZACAO_CONFIGURACOES_GERAIS, this.configuracoes)
                this.mostrarNotificacaoSucessoDefault()
            } catch (e) {
                this.mostrarNotificacaoErro("Não foi possível atualizar as configurações da organização!")
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="stylus">
.co-configuracoes-seguranca
    background-color var(--background-100) !important
    width 100%

    .title
        color var(--primary)

</style>
