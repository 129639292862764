var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"az-auth",rawName:"v-az-auth",value:('Contrato.Publicar'),expression:"'Contrato.Publicar'"}],staticClass:"acao-formalizar"},[(_vm.existeAssinaturaRejeitada)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticStyle:{"width":"100%"}},on),[_c('v-btn',{directives:[{name:"az-auth",rawName:"v-az-auth",value:('Contrato.Publicar'),expression:"'Contrato.Publicar'"}],staticStyle:{"margin":"0 !important","height":"60px"},attrs:{"block":"","disabled":"","large":""}},[_vm._v(" FORMALIZAR "+_vm._s(_vm.$tc("pt_br.documento").toUpperCase())+" ")])],1)]}}],null,false,3816100782)},[_c('span',[_vm._v("É necessário que todos "+_vm._s(_vm.$tc("pt_br.signatario", 2).toLowerCase())+" assinem o contrato para formalizar")])]):(_vm.ehSignatarioPagante && _vm.possuiSignatarios && _vm.possuiSignatariosAguardadoEnvio)?_c('v-tooltip',{attrs:{"disabled":_vm.possuiRepresentanteLegal,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticStyle:{"width":"100%"}},on),[_c('v-btn',{directives:[{name:"az-auth",rawName:"v-az-auth",value:('Contrato.Signatario.Convidar'),expression:"'Contrato.Signatario.Convidar'"}],staticStyle:{"margin":"0 !important","height":"60px"},attrs:{"dark":_vm.possuiRepresentanteLegal && _vm.habilitarBotaoConvidar,"disabled":!(_vm.possuiRepresentanteLegal && _vm.habilitarBotaoConvidar),"block":"","color":"green lighten-2","depressed":"","large":"","loading":_vm.loading},on:{"click":_vm.enviarConvites}},[_vm._v(" ENVIAR CONVITES ")])],1)]}}])},[_c('span',[_vm._v("Para enviar os convites é necessário possuir 1 representante legal")])]):(_vm.possuiSignatarios && _vm.possuiSignatariosAguardadoEnvio)?_c('v-btn',{directives:[{name:"az-auth",rawName:"v-az-auth",value:('Contrato.Signatario.Convidar'),expression:"'Contrato.Signatario.Convidar'"}],staticStyle:{"margin":"0 !important","height":"60px"},attrs:{"dark":_vm.habilitarBotaoConvidar,"disabled":!_vm.habilitarBotaoConvidar,"block":"","color":"green lighten-2","depressed":"","large":"","loading":_vm.loading},on:{"click":_vm.enviarConvites}},[_vm._v(" ENVIAR CONVITES ")]):(_vm.existeSignatarioAguardandoAssinatura)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticStyle:{"width":"100%"}},on),[_c('v-btn',{directives:[{name:"az-auth",rawName:"v-az-auth",value:('Contrato.Publicar'),expression:"'Contrato.Publicar'"}],staticStyle:{"margin":"0 !important","height":"60px"},attrs:{"block":"","depressed":"","disabled":"","large":""}},[_vm._v(" FORMALIZAR "+_vm._s(_vm.$tc("pt_br.documento").toUpperCase())+" ")])],1)]}}])},[_c('span',[_vm._v("É necessário que todos "+_vm._s(_vm.$tc("pt_br.signatario", 2).toLowerCase())+" assinem o contrato para formalizar")])]):(_vm.ehSignatarioPagante && !_vm.possuiRepresentanteLegal)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticStyle:{"width":"100%"}},on),[_c('v-btn',{directives:[{name:"az-auth",rawName:"v-az-auth",value:('Contrato.Publicar'),expression:"'Contrato.Publicar'"}],staticStyle:{"margin":"0 !important","height":"60px"},attrs:{"block":"","depressed":"","disabled":"","large":"","loading":_vm.loading},on:{"click":_vm.formalizarDocumento}},[_vm._v(" FORMALIZAR "+_vm._s(_vm.$tc("pt_br.documento").toUpperCase())+" ")])],1)]}}])},[_c('span',[_vm._v("Para formalizar este contrato é necessário possuir 1 representante legal")])]):_c('v-btn',{directives:[{name:"az-auth",rawName:"v-az-auth",value:('Contrato.Publicar'),expression:"'Contrato.Publicar'"}],staticStyle:{"margin":"0 !important","height":"60px"},attrs:{"block":"","color":"green lighten-2","dark":"","depressed":"","large":"","loading":_vm.loading},on:{"click":_vm.formalizarDocumento}},[_vm._v(" FORMALIZAR "+_vm._s(_vm.$tc("pt_br.documento").toUpperCase())+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }