<template>
    <div class="co-meus-contratos-lista-vazia">
        <clm-ops v-if="selecionouFiltros" sentimento="triste">
            <div slot="titulo">Ops, não encontrei nada por aqui...</div>
            <div slot="subtitulo">Aqui está vazio, sua busca não retornou nenhum
                {{ $tc("pt_br.documento").toLowerCase() }}.
            </div>
        </clm-ops>

        <clm-ops v-else sentimento="feliz">
            <div slot="titulo">Seja muito bem-vindo!</div>
            <div slot="subtitulo">
                <span>Clique agora em <strong>Criar {{ $tc('pt_br.documento')[0].toUpperCase() + $tc('pt_br.documento').slice(1) }}</strong> para começar.</span><br/>
                <span>Aqui temos assinatura por WhatsApp e Email!</span><br/>
                <botoes-criar botao-quadrado ocultar-criar-pasta class="mt-2"/>
            </div>
        </clm-ops>

    </div>
</template>

<script>
import ClmOps from '@/commons/components/ClmOps'
import BotoesCriar from './botoes-criar/BotoesCriar'
import {mapState} from 'vuex'

export default {
    name: 'ListaVazia',
    components: {
        BotoesCriar,
        ClmOps
    },
    data() {
        return {
            reveal: false,
        }
    },
    computed: {
        ...mapState(['meusContratosBusca', 'pastaAtual']),
        selecionouFiltros() {
            const {filtros} = this.meusContratosBusca
            let selecionou = false
            for (const i of Object.keys(filtros)) {
                const filtro = filtros[i]
                if (filtro.value && i !== 'formalizacao') {
                    selecionou = true
                    break
                }
            }
            let filtrou = this.pastaAtual.buscaFiltrada

            return selecionou || filtrou
        },
    }
}
</script>

<style lang="stylus">
.co-meus-contratos-lista-vazia
    color unset

</style>