<template>
    <div class="co-limite-assinatura">
        <div class="dados">
            <div class="titulo-data-limite">Limite p/ Assinaturas:</div>

            <v-dialog v-if="naoFormalizado && temPermissaoParaEditar()"
                      ref="modalData"
                      width="290px"
                      v-model="modalDataLimiteAssinaturas">
                <template v-slot:activator="{ on }">
                    <div v-on="on" class="data-editavel titulo-data-picker">{{ valor | azDate }}</div>
                </template>

                <div class="titulo-seletor-data">Data Limite Assinaturas</div>

                <v-date-picker
                    show-current
                    locale="pt-br"
                    v-model="limiteAssinaturas"
                    scrollable
                    @change="salvar"
                    close-on-content-click=true
                    class="az-date">
                </v-date-picker>
            </v-dialog>

            <v-icon size="14" v-if="naoFormalizado && temPermissaoParaEditar()" color="#ccc" style="margin-left: 2px">
                mdi-pencil
            </v-icon>

            <div class="data" style="margin-left: 5px" v-else>{{ valor | azDate }}</div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {hasPermissions} from '@azinformatica/loki'
import moment from 'moment'
import DateTimeUtils from '@/commons/utils/DateTimeUtils'

export default {
    name: 'LimiteAssinatura',
    props: ['valor'],
    data() {
        return {
            limiteAssinaturas: null,
            modalDataLimiteAssinaturas: false,
        }
    },
    computed: {
        ...mapState(['minutaAtual', 'loki']),
        naoFormalizado() {
            return this.minutaAtual.situacaoFormalizacao === 'RASCUNHO' ||
                this.minutaAtual.situacaoFormalizacao === 'EM_FORMALIZACAO'
        }
    },
    watch: {
        valor() {
            this.formatarDataLimiteAssinaturas()
        }
    },
    mounted() {
        if (this.valor) this.limiteAssinaturas = moment(this.valor).format('YYYY-MM-DD')
    },
    methods: {
        formatarDataLimiteAssinaturas() {
            this.limiteAssinaturas = moment(this.valor).format('YYYY-MM-DD')
        },
        salvar() {
            if (this.validarDataLimiteAssinaturas()) {
                this.$emit('salvar', DateTimeUtils.set23HorasISO(this.limiteAssinaturas))
            }
            this.modalDataLimiteAssinaturas = false
        },
        temPermissaoParaEditar() {
            return hasPermissions(this.loki.user.authorities, ['Contrato.Editar'])
        },
        validarDataLimiteAssinaturas() {
            if (moment().isAfter(this.limiteAssinaturas)) {
                this.mostrarNotificacaoErro('Data limite assinatura deve ser posterior a data atual.')
                return false
            }
            return true
        }
    }
}
</script>

<style lang="stylus">
.co-limite-assinatura
    .dados
        font-size 12px
        color #777
        text-align left
        display flex

        .titulo-data-limite
            font-size 12px
            text-transform none
            color #aaa

        .titulo-data-picker
            font-size 12px
            text-transform capitalize
            color #aaa

            &:hover
                color white

    .data
        margin-left 5px
        color #bbb

    .v-small-dialog
        display inline-block
        width fit-content
        height fit-content

    .v-menu__activator
        display block !important
        text-align center !important
</style>