<template>
    <div v-if="ehSemFuncionalidade" class="co-configuracoes-exclusivas mx-4 mt-5">
        <v-row class="plano d-flex" no-gutters>
            <span class="plano">Mude para um Plano Pago e tenha acesso</span>
            <span class="plano">a esta Funcionalidade e muito mais!!</span>
            <img :src="icons.crownVector" alt="coroa" class="coroa" height="20" width="20"/>
        </v-row>

        <v-row v-if="!ehEfcazCLM" class="plano sub-titulo mt-2" no-gutters>
            <p>Conheça nossos planos
                <a class="destaque" @click.prevent.stop="abrirLoja">
                    CLICANDO AQUI
                </a>
            </p>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import crownVector from '../../../../assets/icons/crown_vector.svg'


export default {
    name: 'FuncionalidadeExclusiva',
    props: {
        ehSemFuncionalidade: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            icons: {
                crownVector
            }
        }
    },
    computed: {
        ...mapGetters(['ehEfcazCLM']),
    },
    methods: {
        abrirLoja() {
            this.$router.push({name: 'planos'})
        }
    }
}
</script>

<style lang="stylus">
.co-configuracoes-exclusivas
    .titulo
        color #777

    .plano
        font-size 18px
        text-align center
        color var(--v-primary-base)

    .sub-titulo
        font-size 14px

    .coroa
        margin-left 5px

    .destaque
        font-weight bold
        color green
        transition all 500ms
        cursor pointer

    .destaque:hover
        background rgba(240, 240, 240, .8)
        color lighten(green, 20%)


</style>
