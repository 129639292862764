<template>
    <div class="co-white-label-pub-colors">
        <v-row no-gutters class="align-center my-4">
            <configuracao-titulo
                titulo="Página de Assinatura"
                divider-off
            />
        </v-row>

        <v-row no-gutters class="mx-3">
            <v-col cols="12" md="6" class="pr-3">
                <item-selecao-cor
                    titulo="Cor principal"
                    v-model="colors.primary"
                    :cor-default="coresDefault.primary"
                    :disabled="disabled"/>

                <item-selecao-cor
                    titulo="Cor secundária"
                    v-model="colors.secondary"
                    :cor-default="coresDefault.secondary"
                    :disabled="disabled"/>
            </v-col>

            <v-col cols="12" md="6">
                <pub-design :colors="colors" :default-colors="coresDefault" :disabled="disabled" :logo="logo"/>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import ConfiguracaoTitulo from '../../../common/ConfiguracaoTitulo'
import ItemSelecaoCor from '../ItemSelecaoCor'
import {mapState} from 'vuex'
import PubDesign from './PubDesign'

export default {
    name: 'WhiteLabelPubColors',
    components: {PubDesign, ItemSelecaoCor, ConfiguracaoTitulo},
    props: {
        colors: {
            type: Object,
            required: true
        },
        logo: {
            type: String,
            required: true
        },
        coresDefault: {
            type: Object
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState(['loki']),
        getLogo() {
            if (!!this.logo) {
                return this.logo
            } else {
                return this.loki.product.mainLogo
            }
        },
    }
}
</script>

<style lang="stylus">
.co-white-label-pub-colors
    .title
        color var(--primary)

    .subtitle-2
        color var(--text-color-200)

</style>
