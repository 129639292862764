import axios from 'axios'
import {actionTypes, mutationTypes} from '@/commons/constants'
import {AzSearchUrlBuilder} from '@azinformatica/loki'
import _ from "lodash";

export default {
    async [actionTypes.WEBHOOK.BUSCAR_ORGANIZACAO_CONFIGURACOES_WEBHOOKS]({commit}) {
        const {data} = await axios.get('api/webhooks/configuracoes')
        commit(mutationTypes.LOGIN.SET_ORGANIZACAO_CONFIGURACAO_WEBHOOK, data)
    },

    async [actionTypes.WEBHOOK.ATUALIZAR_ORGANIZACAO_CONFIGURACOES_WEBHOOKS]({state, commit}, configuracoes) {
        const {data} = await axios.put('api/webhooks/configuracoes', configuracoes)
        commit(mutationTypes.LOGIN.SET_ORGANIZACAO_CONFIGURACAO_WEBHOOK, _.merge(state.login.organizacaoConfiguracao.webhook, configuracoes))
    },

    async [actionTypes.WEBHOOK.BUSCAR_WEBHOOKS_PENDENTES_ENVIO]({state}) {
        const {filtros, paginacao} = state.webhookPendentesBusca
        const organizacaoId = state.login.organizacaoPlano.organizacaoId
        const url = AzSearchUrlBuilder.build(`api/webhooks/organizacoes/${organizacaoId}/pendentes`, filtros, paginacao)
        const {data} = await axios.get(url)
        return data
    },

    async [actionTypes.WEBHOOK.REENVIAR_WEBHOOKS_PENDENTES]({state}) {
        const organizacaoId = state.login.organizacaoPlano.organizacaoId
        const {data} = await axios.put(`api/webhooks/organizacoes/${organizacaoId}/pendentes/reenviar`)
        return data
    }
}
