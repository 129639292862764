<template>
    <v-form ref="signatarioForm" class="co-formulario-selecao-multiplos-signatarios">
        <papel-signatario
            v-model="novoSignatario"
            :papeis="papeisSiganatario"
            campo-requerido
        />
        <email-signatario-lote
            v-model="novoSignatario"
            :desabilitar-campo="false"
            :signatarios-sugestao="signatariosSugestoes"
            campo-requerido
        />
        <documento-signatario
            v-model="novoSignatario"
            :documentos="documentosSignatario"
            campo-requerido
        />
    </v-form>
</template>
<script>
import DocumentoSignatario
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/DocumentoSignatario'
import EmailSignatarioLote
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/EmailSignatarioLote'
import PapelSignatario
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/PapelSignatario'

export default {
    name: "FormularioSelecaoMultiplosSignatarios",
    inject: ['$validator'],
    components: {
        DocumentoSignatario, EmailSignatarioLote, PapelSignatario
    },
    props: {
        documentosSignatario: {
            type: Array,
            required: true
        },
        novoSignatario: {
            type: Object,
            required: true,
        },
        papeisSiganatario: {
            type: Array,
            required: true
        },
        signatariosSugestoes:
            {
                type: Array,
                required: true
            }
    },
    methods: {
        validarFormulario() {
            return this.$refs.signatarioForm.validate() && this.novoSignatario.email.length > 0
        }
    }
}
</script>