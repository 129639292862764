<template>
    <v-dialog v-model="dialog" persistent width="700">
        <v-card class="co-dialog-token-api">
            <v-card-title class="primary title lighten-1 white--text">
                Token API
                <v-spacer/>
                <v-icon color="white" @click="fechar"> mdi-window-close </v-icon>
            </v-card-title>
            <v-card-text>
                <v-flex style="display: flex" xs12 sm12 class="ml-3 mr-3 mt-2">
                    <v-text-field
                        id="input-token-api"
                        label="Token API"
                        name="Token API"
                        disabled
                        v-model="token"
                    />
                    <div class="copiar-token" ref="content_copy">
                        <v-icon style="margin-left: 8px; cursor: pointer" @click="copiarToken(token)"> mdi-content-copy </v-icon>
                    </div>
                </v-flex>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn color="primary" text @click="fechar">
                    OK
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'DialogTokenApi',
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
        token: {
            type: String
        }
    },
    methods: {
        async copiarToken(token) {
            let sucess = await this.$copyText(token, this.$refs.content_copy)

            if(sucess) {
                this.mostrarNotificacaoSucesso('Token copiado com sucesso.')
            }
        },
        fechar() {
            this.$emit('fechar')
        }
    }
}
</script>

<style lang="stylus">
.co-dialog-token-api

    .copiar-token
        display flex
        justify-content center

</style>