<template>
    <div class="co-tipo-assinatura-formulario">
        <v-select
            v-show="exibirSignatarioCompleto"
            :class="campoRequerido ? 'required-field' : ''"
            :error-messages="errors.collect('Tipo Assinatura')"
            name="Tipo Assinatura"
            v-validate="validarCampo()"
            item-text="nome"
            item-value="id"
            label="Tipo Assinatura"
            placeholder="Selecione"
            v-on:change="validaTipoWhatsapp"
            :items="tiposAssinatura"
            v-model="value.tipoAssinatura"
            persistent-placeholder>
            <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-on="on" :disabled="item.disabled">
                    <div class="d-flex justify-space-between align-center co-tipo-assinatura-novo" style="width: 100%">
                        <span>{{ item.nome }}</span>
                        <span v-if="possuiValor(item.saldo)" :class="item.disabled? 'saldo-disabled' : 'saldo'"> Saldo: {{ item.saldo }}</span>
                    </div>
                </v-list-item>
            </template>
        </v-select>
    </div>
</template>

<script>

export default {
    name: 'TipoAssinaturaFormulario',
    props: {
        exibirSignatarioCompleto: {
            type: Boolean,
            default: true
        },
        campoRequerido: {
            type: Boolean,
            default: false
        },
        value: {
            required: true,
            type: Object
        },
        tiposAssinatura: {
            type: Array
        }
    },
    methods: {
        possuiValor(valor) {
            return valor !== null && valor !== undefined && valor !== ''
        },
        validaTipoWhatsapp() {
            this.$emit('validaTipoWhatsapp')
        },
        validarCampo() {
            return this.campoRequerido ? 'required' : ''
        },
    }
}
</script>

<style lang="stylus">
.co-tipo-assinatura-formulario
    .saldo
        font-size 12px !important

    .saldo-disabled
        font-size 12px !important
        color var(--error-200)

</style>