<template>
    <v-flex class="co-home-contrato-lista d-flex align-center">
        <contrato-drag :contrato="contrato">
            <div slot="contratoItem" class="contratos-lista px-2 d-flex align-center">
                <v-col cols="11" class="dados" @click="visualizarContrato">
                    <v-col cols="4" class="dados">
                        <v-icon color="secondary-200" size="22"> mdi-file-document </v-icon>

                        <div class="ellipsis ml-2">
                            {{ contrato.nome }}
                            <div v-if="contrato.pasta" class="pasta-nome"> <span style="font-weight: bold">Pasta:</span> {{ contrato.pasta }}</div>
                        </div>
                    </v-col>

                    <v-col cols="2">
                        <div>{{ contratoSituacao }}</div>
                    </v-col>

                    <v-col cols="3">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <div v-on="ehContratoComSignatarios ? on : null">{{ contratoStatus }}</div>
                            </template>

                            <div v-for="(signatario, index) in contrato.signatarios"
                                 :key="index" class="mr-2">
                                {{ getSignatarioEmailTelefone(signatario) }} ({{ getSignatarioStatus(signatario) }}),
                            </div>
                        </v-tooltip>
                    </v-col>

                    <v-col cols="3">
                        <card-contrato-tags-novo
                            :value="contrato.tags"
                            :qtd-caracteres-max="8"
                            :quantidade-tags="quantidadeTags"/>
                    </v-col>
                </v-col>
            </div>
        </contrato-drag>

        <div class="acoes">
            <contrato-info :contrato="contrato"/>
            <notificacao :contrato="contrato" ref="refNotificao"/>
            <mais-opcoes-contratos :contrato="contrato" @notificacao-contrato="notificacaoContrato"/>
        </div>
    </v-flex>

</template>

<script>
import CardContratoTagsNovo from '../common/ContratoTagsNovo'
import ContratoDrag from '../common/ContratoDrag'
import ContratoInfo from '../common/contrato-info/ContratoInfo'
import MaisOpcoesContratos from '../common/MaisOpcoesContratos'
import Notificacao from '@/views/home/meus-contratos/common/Notificacao'

export default {
    name: "ContratoLista",
    components: {
        ContratoInfo,
        CardContratoTagsNovo,
        ContratoDrag,
        MaisOpcoesContratos,
        Notificacao
    },
    props: {
        contrato: {
            type: Object,
            required: true
        },
        contratoStatus: {
            type: String,
            default: ''
        },
        contratoSituacao: {
            type: String,
            default: ''
        }
    },
    computed: {
        ehContratoComSignatarios() {
            return this.contrato.signatarios && this.contrato.signatarios.length > 0
        }
    },
    methods: {
        getSignatarioEmailTelefone(signatario) {
            if (signatario.email && signatario.email !== '') {
                return signatario.email
            } else {
                return signatario.telefone
            }
        },
        getSignatarioStatus(signatario) {
            return signatario.status.replace('_', ' ')
        },
        notificacaoContrato() {
            this.$refs.refNotificao.verificarPerfilUsuarioContrato()
        },
        quantidadeTags() {
            if (this.$vuetify.breakpoint.xl) {
                return 5
            } else if (this.$vuetify.breakpoint.lg) {
                return 2
            } else {
                return 1
            }
        },
        visualizarContrato() {
            this.$router.push({name: 'detalheContrato', params: {id: this.contrato.contratoId}})
        }
    }
}
</script>

<style lang="stylus">
.co-home-contrato-lista
    border 1px solid var(--background-300)
    border-radius 5px
    cursor pointer
    height 46px
    position relative

    .contratos-lista
        width 100%

    .dados
        display flex
        height 45px
        width 100%
        position relative
        align-items center
        text-align left
        color var(--text-color-300)
        margin 0
        padding 0

        .ellipsis
            display block
            align-items center
            text-overflow ellipsis
            white-space nowrap
            overflow hidden
            width 100%

        .pasta-nome
            font-size 10px

    .acoes
        position absolute
        margin-top 5px
        right 5px
        display flex

</style>