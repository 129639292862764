<template>
    <v-dialog v-model="naoPossuiHistorico" width="500" persistent>
        <v-card class="clm-ops-nao-possui-historico py-2">
            <v-icon class="btn-fechar" small @click="fecharClmOpsNaoPossuiHistorico"> mdi-window-close </v-icon>
            <v-flex class="text-xs-center">
                <div style="font-size: 24px; color: #666" class="my-2 mx-2">Histórico indisponível</div>
                <img src="../../../../../assets/ops/assustado.gif">
                <div class="texto">Opa, vejo aqui que gostaria de visualizar o histórico desse {{ $tc("pt_br.signatario").toLowerCase() }},
                    porém essa ação foi implementada apenas para {{ $tc("pt_br.signatario", 2).toLowerCase() }} adicionados após <span style="color: orange">18/05/2020</span>.
                </div>
            </v-flex>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ClmOpsNaoPossuiHistorico',
    props: {
        naoPossuiHistorico: {
            type: Boolean,
            required: true
        },
    },
    methods: {
        fecharClmOpsNaoPossuiHistorico() {
            this.$emit('fecharClmOpsNaoPossuiHistorico')
        }
    }
}
</script>

<style lang="stylus">
.clm-ops-nao-possui-historico
    .texto
        color: #aaaaaa
        margin 10px 40px

    .btn-fechar
        position absolute
        z-index 1
        right 10px
        top 10px

</style>
