import axios from 'axios'
import {actionTypes} from '@/commons/constants'

export default {
    async [actionTypes.SIGNATARIO.ATUALIZAR_SALDO_SMS]({state, getters}) {
        const organizacaoId = state.login.organizacaoPlano.organizacaoId
        const saldoSMS = getters.saldoSms
        await axios.put(`api/configuracoes/organizacoes/${organizacaoId}/saldo/sms`, {saldoSMS})
    },

    async [actionTypes.SIGNATARIO.BUSCAR_SIGNATARIOS_SUGESTOES]({state}) {
        const organizacaoId = state.login.organizacaoPlano.organizacaoId
        const {data} = await axios.get(`api/signatario/organizacao/${organizacaoId}/signatario-sugestoes`)
        return data
    },

    async [actionTypes.SIGNATARIO.INSERIR_SIGNATARIO](context, {minutaId, signatario}) {
        return await axios.post(`api/signatario/minuta/${minutaId}/inserir/novo`, signatario)
    },

    async [actionTypes.SIGNATARIO.INSERIR_SIGNATARIO_LOTE](context, {contratoId, minutaId, signatario}) {
        await axios.post(`api/contratos/${contratoId}/minutas/${minutaId}/signatarios/lote`, signatario)
    },

    async [actionTypes.SIGNATARIO.INSERIR_SIGNATARIOS_NOVO](context, {contratoId, minutaId, signatario, ordem}) {
        return await axios.post(`api/signatario/contratos/${contratoId}/minutas/${minutaId}/lote/novo?ordem=${ordem}`, signatario)
    },

    async[actionTypes.SIGNATARIO.PRIORIZAR_SIGANTARIOS_WHATSAPP] ({state}, signatariosImpedidos) {
        const organizacaoId = state.login.organizacaoPlano.organizacaoId
        const minutaId = state.minutaAtual.id
        await axios.post(`api/signatario/organizacao/${organizacaoId}/minuta/${minutaId}/whatsapp/priorizar`, signatariosImpedidos);
    },
}