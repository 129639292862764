<template>
    <v-toolbar flat dark color="primary" height="50">
        <v-spacer/>
        <v-btn icon dark color="text-color-2" @click="mostrarSignatarios">
            <v-icon> mdi-gesture </v-icon>
        </v-btn>
        <v-btn icon dark color="text-color-2" @click="mostrarDocumento">
            <v-icon> mdi-paperclip </v-icon>
        </v-btn>
        <v-spacer/>
    </v-toolbar>
</template>

<script>
export default {
    name: 'ToolbarSignatarios',
    props: {
        mostrarTela: {}
    },
    methods: {
        mostrarSignatarios() {
            this.mostrarTela.signatarios = true
        },
        mostrarDocumento() {
            this.mostrarTela.signatarios = false
        },
    }
}
</script>

<style scoped>

</style>