import axios from 'axios'
import {actionTypes} from '@/commons/constants'
import {AzSearchUrlBuilder} from '@azinformatica/loki'

export default {
    async [actionTypes.GRUPO_SIGNATARIOS.BUSCAR_GRUPO_SIGNATARIOS](context, {grupoSignatariosId}) {
        const {data} = await axios.get(`api/grupos-signatarios/${grupoSignatariosId}`)
        return data
    },

    async [actionTypes.GRUPO_SIGNATARIOS.BUSCAR_GRUPOS_SIGNATARIOS_FILTRADOS]({state}) {
        const {filtros, paginacao} = state.gruposSignatariosBusca
        const url = AzSearchUrlBuilder.build(`api/grupos-signatarios/paginada`, filtros, paginacao)
        const {data} = await axios.get(url)
        return data
    },

    async [actionTypes.GRUPO_SIGNATARIOS.BUSCAR_MEUS_GRUPOS_SIGNATARIOS]() {
        const url = AzSearchUrlBuilder.build(`api/grupos-signatarios`)
        const {data} = await axios.get(url)
        return data
    },

    async [actionTypes.GRUPO_SIGNATARIOS.INSERIR_GRUPO_SIGNATARIOS](context, {novoGrupoSignatarios}) {
        const {data} = await axios.post(`api/grupos-signatarios`, novoGrupoSignatarios)
        return data
    },

    async [actionTypes.GRUPO_SIGNATARIOS.ATUALIZAR_GRUPO_SIGNATARIOS](context, {atualizarGrupoSignatarios}) {
        const {data} = await axios.put(`api/grupos-signatarios`, atualizarGrupoSignatarios)
        return data
    },

    async [actionTypes.GRUPO_SIGNATARIOS.REMOVER_GRUPO_SIGNATARIOS](context, {grupoSignatariosId}) {
        const {data} = await axios.delete(`api/grupos-signatarios/${grupoSignatariosId}`)
        return data
    },

    async [actionTypes.GRUPO_SIGNATARIOS.IMPORTAR_CADASTROS_SIGNATARIOS_GRUPOS]({state}, {gruposSelecionados}) {
        const minutaId = state.minutaAtual.id
        const {data} = await axios.post(`api/grupos-signatarios/importar/minuta/${minutaId}`, {gruposSelecionados})
        return data
    },

    async [actionTypes.GRUPO_SIGNATARIOS.BUSCAR_SIGNATARIOS_IMPORTACAO]({state}, {gruposSelecionados}) {
        const {data} = await axios.post(`api/grupos-signatarios/importar/filtrar-signatarios`, {gruposSelecionados})
        return data
    },
}