<template>
    <div class="co-configuracao-salvar">
        <v-spacer class="footer"/>

        <v-row no-gutters class="justify-center py-3 acoes">
            <v-btn
                class="cancelar botao"
                v-if="mostrarCancelar"
                depressed
                outlined
                :disabled="disabled"
                @click="cancelar"
            >
                Cancelar
            </v-btn>

            <v-btn
                class="botao white--text"
                color="green"
                depressed
                :disabled="disabled"
                :loading="loading"
                @click="salvar"
            >
                Salvar
            </v-btn>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'ConfiguracaoSalvar',
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        mostrarCancelar: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        cancelar() {
            this.$emit('cancelar')
        },
        salvar() {
            this.$emit('salvar')
        }
    }
}
</script>

<style lang="stylus">
.co-configuracao-salvar
    .acoes
        position absolute
        bottom 36px
        left 0
        width 100%
        background-color rgba(249, 249, 249, 0.8)
        backdrop-filter blur(3px)

        .botao
            min-width 117px

        .cancelar
            color var(--text-color-200)
            margin-right 20px

    .footer
        height 80px
@media (max-width: 720px)
    .co-configuracao-salvar
        .acoes
            bottom 0

            .cancelar
                margin-left 0
                margin-bottom 10px

            .botao
                width 80%
</style>