<template>
    <div class="co-disponibilidade-assinatura">
        <v-select
            v-model="value.disponibilidadeAssinatura"
            v-validate="'required'"
            :class="campoRequerido ? 'required-field' : ''"
            :error-messages="errors.collect('Disponibilidade assinatura')"
            :items="disponibilidadeAssinatura"
            label="Como o contato irá assinar"
            name="Disponibilidade assinatura"
            placeholder="Selecione"
            persistent-placeholder>
        </v-select>

        <div class="hint-checkbox">
            {{ getHintDisponibilidadeAssinatura }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'DisponibilidadeAssinatura',
    props: {
        value: {
            required: true
        },
        campoRequerido: {
            type: Boolean,
            default: false
        },
        desabilitarCampo: {
            type: Boolean
        }
    },
    data() {
        return {
            disponibilidadeAssinatura: [
                {text: 'Remotamente em outro dispositivo', value: 'REMOTA'},
                {text: 'Presencialmente', value: 'PRESENCIAL'}
            ],
        }
    },
    computed: {
        ehSignatarioConvidado() {
            return this.value.statusSignatario === 'CONVIDADO'
        },
        getHintDisponibilidadeAssinatura() {
            if (this.value.disponibilidadeAssinatura === undefined) {
                return `Meio pelo qual o ${this.$tc("pt_br.signatario").toLowerCase()} irá assinar o documento`
            }
            return this.value.disponibilidadeAssinatura === 'REMOTA' ? `Para este tipo de assinatura o ${this.$tc("pt_br.signatario").toLowerCase()} deverá acessar seu e-mail em um dispositivo de sua preferência, e fará o processo de assinatura de onde estiver` : `O ${this.$tc("pt_br.signatario").toLowerCase()} se encontra presente no momento da assinatura`
        },
    }
}
</script>
