<template>
    <v-container fluid class="co-planos">
        <v-row class="banner-plano-wrapper justify-center text-center" no-gutters>
            <strong v-if="!ehEfcazCLM" class="banner-plano py-3 ma-0 black-friday">Black November, aproveite!</strong>
            <strong v-else class="banner-plano py-3 ma-0">
                Escolha o melhor plano para <span class="enfase">Você</span>
            </strong>
        </v-row>

        <v-row no-gutters class="justify-center">
            <v-tabs
                v-model="tabSelect"
                :color="getPrimaryColor"
                :slider-color="getPrimaryColor"
                centered
            >
                <v-tab v-for="(tab, index) in abas"
                       v-if="!ehOcultarAba(tab)"
                       :key="`aba-planos-${index}`">
                    <div class="tabs">
                        {{ tab }}
                    </div>
                </v-tab>
            </v-tabs>
        </v-row>

        <v-row no-gutters class="justify-center">
            <v-tabs-items v-if="!ehPlanosCarregando" v-model="tabSelect" class="w-100">
                <v-tab-item>
                    <anuais
                        key="anuais"
                        @abrir-pagamentos="abrirDialogPagamentos"
                        :planos="planos"
                    />
                </v-tab-item>

                <v-tab-item>
                    <anuais-api
                        key="anuais-api"
                        :planos="planos"
                        @abrir-pagamentos="abrirDialogPagamentos"
                    />
                </v-tab-item>

                <v-tab-item>
                    <mensais
                        key="mensais"
                        @abrir-pagamentos="abrirDialogPagamentos"
                        :planos="planos"
                    />
                </v-tab-item>

                <v-tab-item>
                    <mensais-api
                        key="planos-api"
                        :planos="planos"
                        @abrir-pagamentos="abrirDialogPagamentos"
                    />
                </v-tab-item>

                <v-tab-item>
                    <personalizado
                        key="plano-personalizado"
                        @abrir-pagamentos="abrirDialogPagamentos"
                    />
                </v-tab-item>

                <v-tab-item v-if="!documentosIlimitados">
                    <sem-recorrencia
                        key="sem-recorrencia"
                        @abrir-pagamentos="abrirDialogPagamentos"
                        :itens-adicionais="itensAdicionais"
                    />
                </v-tab-item>
            </v-tabs-items>
        </v-row>

        <dialog-pagamento
            v-if="dialogPagamentos"
            key="dialog-pagamento"
            :itensAdicionais="itensAdicionais"
            :dialogPagamentos="dialogPagamentos"
            :planoSelecionado="planoSelecionado"
            @fechar-pagamentos="fecharDialogPagamentos"
        />
    </v-container>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import {actionTypes} from '@/commons/constants'
import SemRecorrencia from './components/sem-recorrencia/SemRecorrencia'
import Anuais from './components/anual/Anual'
import AnuaisApi from './components/anual/AnualApi'
import DialogPagamento from './components/dialog/dialog-pagamento/DialogPagamento'
import Mensais from './components/mensal/Mensal.vue'
import MensaisApi from './components/mensal/MensalApi'
import Personalizado from './components/personalizado/Personalizado'
import _ from 'lodash'

export default {
    name: 'Planos',
    components: {
        SemRecorrencia,
        Mensais,
        MensaisApi,
        Anuais,
        AnuaisApi,
        DialogPagamento,
        Personalizado
    },
    data() {
        return {
            abas: ['Plano Mensal'],
            tabSelect: null,
            temPlanoPromocao: false,
            planos: {
                light: {
                    titulo: '',
                    valorPacote: null,
                    descricao: '',
                    beneficios: [
                        {beneficio: 'Todas as funcionalidades do plano free +', destaque: false},
                        {beneficio: '20 Documentos por E-mail renovável todo o mês'},
                        {beneficio: 'Personalização de E-mail'},
                        {beneficio: 'Envio de documentos em lote'},
                        {beneficio: 'Personalização da Marca, E-mails e Páginas de Assinatura'},
                    ]
                },
                lightAnual: {
                    titulo: '',
                    valorPacote: null,
                    valorParcela: null,
                    descricao: '',
                    beneficios: [
                        {beneficio: 'Todas as funcionalidades do plano free +', destaque: false},
                        {beneficio: '240 Documentos por E-mail *'},
                        {beneficio: 'Personalização de E-mail'},
                        {beneficio: 'Envio de documentos em lote'},
                        {beneficio: 'Personalização da Marca, E-mails e Páginas de Assinatura'},
                    ],
                    validade: '* Válido por 12 meses'
                },
                // starterWhatsApp: {
                //     titulo: '',
                //     valorPacote: null,
                //     descricao: '',
                //     beneficios: [
                //         {beneficio: 'Todas as funcionalidades do plano free +', destaque: false},
                //         {beneficio: '50 Documentos por E-mail renovável todo o mês'},
                //         {beneficio: 'Personalização de E-mail'},
                //         {beneficio: 'Envio de documentos em lote'},
                //         {beneficio: 'Personalização da Marca, E-mails e Páginas de Assinatura'},
                //     ]
                // },
                // starterWhatsAppAnual: {
                //     titulo: '',
                //     valorPacote: null,
                //     valorParcela: null,
                //     descricao: '',
                //     beneficios: [
                //         {beneficio: 'Todas as funcionalidades do plano free +', destaque: false},
                //         {beneficio: '600 Documentos por E-mail *'},
                //         {beneficio: 'Personalização de E-mail'},
                //         {beneficio: 'Envio de documentos em lote'},
                //         {beneficio: 'Personalização da Marca, E-mails e Páginas de Assinatura'},
                //     ],
                //     validade: '* Válido por 12 meses'
                // },
                starter: {
                    titulo: '',
                    valorPacote: null,
                    descricao: '',
                    beneficios: [
                        {beneficio: 'Todas as funcionalidades do plano free +', destaque: false},
                        {beneficio: '50 Documentos por E-mail renovável todo o mês'},
                        {beneficio: 'Personalização de E-mail'},
                        {beneficio: 'Envio de documentos em lote'},
                        {beneficio: 'Personalização da Marca, E-mails e Páginas de Assinatura'},
                    ]
                },
                starterAnual: {
                    titulo: '',
                    valorPacote: null,
                    valorParcela: null,
                    descricao: '',
                    beneficios: [
                        {beneficio: 'Todas as funcionalidades do plano free +', destaque: false},
                        {beneficio: '600 Documentos por E-mail *'},
                        {beneficio: 'Personalização de E-mail'},
                        {beneficio: 'Envio de documentos em lote'},
                        {beneficio: 'Personalização da Marca, E-mails e Páginas de Assinatura'},
                    ],
                    validade: '* Válido por 12 meses'
                },
                // proWhatsApp: {
                //     titulo: '',
                //     valorPacote: null,
                //     descricao: '',
                //     beneficios: [
                //         {beneficio: 'Todas as funcionalidades do plano free +', destaque: false},
                //         {beneficio: 'Documentos E-mail Ilimitados'},
                //         {beneficio: 'Personalização de E-mail'},
                //         {beneficio: 'Envio de documentos em lote'},
                //         {beneficio: 'Personalização da Marca, E-mails e Páginas de Assinatura'},
                //     ]
                // },
                // proWhatsAppAnual: {
                //     titulo: '',
                //     valorPacote: null,
                //     valorParcela: null,
                //     descricao: '',
                //     beneficios: [
                //         {beneficio: 'Todas as funcionalidades do plano free +', destaque: false},
                //         {beneficio: 'Documentos E-mail Ilimitados *'},
                //         {beneficio: 'Personalização de E-mail'},
                //         {beneficio: 'Envio de documentos em lote'},
                //         {beneficio: 'Personalização da Marca, E-mails e Páginas de Assinatura'},
                //     ],
                //     validade: '* Válido por 12 meses'
                // },
                pro: {
                    titulo: '',
                    valorPacote: null,
                    descricao: '',
                    beneficios: [
                        {beneficio: 'Todas as funcionalidades do plano free +', destaque: false},
                        {beneficio: 'Documentos E-mail Ilimitados'},
                        {beneficio: 'Personalização de E-mail'},
                        {beneficio: 'Envio de documentos em lote'},
                        {beneficio: 'Personalização da Marca, E-mails e Páginas de Assinatura'},
                    ]
                },
                proAnual: {
                    titulo: '',
                    valorPacote: null,
                    valorParcela: null,
                    descricao: '',
                    beneficios: [
                        {beneficio: 'Todas as funcionalidades do plano free +', destaque: false},
                        {beneficio: 'Documentos E-mail Ilimitados *'},
                        {beneficio: 'Personalização de E-mail'},
                        {beneficio: 'Envio de documentos em lote'},
                        {beneficio: 'Personalização da Marca, E-mails e Páginas de Assinatura'},
                    ],
                    validade: '* Válido por 12 meses'
                },
                starterApi: {
                    titulo: '',
                    valorPacote: null,
                    descricao: '',
                    beneficios: [
                    {   beneficio: 'Todas as funcionalidades do plano free +', destaque: false},
                        {beneficio: 'Requisições API ilimitadas'},
                        {beneficio: '100 documentos renovável todo o mês'},
                        {beneficio: 'Personalização da Marca, E-mails e Páginas de Assinatura'},
                    ]
                },
                starterApiAnual: {
                    titulo: '',
                    valorPacote: null,
                    valorParcela: null,
                    descricao: '',
                    beneficios: [
                    {   beneficio: 'Todas as funcionalidades do plano free +', destaque: false},
                        {beneficio: 'Requisições API ilimitadas *'},
                        {beneficio: '1200 documentos *'},
                        {beneficio: 'Personalização da Marca, E-mails e Páginas de Assinatura'},
                    ],
                    validade: '* Válido por 12 meses'
                },
                lightApi: {
                    titulo: '',
                    valorPacote: null,
                    descricao: '',
                    beneficios: [
                        {beneficio: 'Todas as funcionalidades do plano free +', destaque: false},
                        {beneficio: 'Requisições API ilimitadas'},
                        {beneficio: '60 documentos renovável todo o mês'},
                        {beneficio: 'Personalização da Marca, E-mails e Páginas de Assinatura'},
                    ]
                },
                lightApiAnual: {
                    titulo: '',
                    valorPacote: null,
                    valorParcela: null,
                    descricao: '',
                    beneficios: [
                        {beneficio: 'Todas as funcionalidades do plano free +', destaque: false},
                        {beneficio: 'Requisições API ilimitadas *'},
                        {beneficio: '720 documentos *'},
                        {beneficio: 'Personalização da Marca, E-mails e Páginas de Assinatura'},
                    ],
                    validade: '* Válido por 12 meses'
                },
                proApi: {
                    titulo: '',
                    valorPacote: null,
                    descricao: '',
                    beneficios: [
                        {beneficio: 'Todas as funcionalidades do plano free +', destaque: false},
                        {beneficio: 'Requisições API ilimitadas'},
                        {beneficio: '200 documentos renovável todo o mês'},
                        {beneficio: 'Personalização da Marca, E-mails e Páginas de Assinatura'},
                    ]
                },
                proApiAnual: {
                    titulo: '',
                    valorPacote: null,
                    valorParcela: null,
                    descricao: '',
                    beneficios: [
                        {beneficio: 'Todas as funcionalidades do plano free +', destaque: false},
                        {beneficio: 'Requisições API ilimitadas *'},
                        {beneficio: '2400 documentos *'},
                        {beneficio: 'Personalização da Marca, E-mails e Páginas de Assinatura'},
                    ],
                    validade: '* Válido por 12 meses'
                }
            },
            itensAdicionais: {
                documento: {
                    nome: 'DOCUMENTO',
                    titulo: 'Documentos (E-mail)',
                    valorPacote: null,
                    descricao: '',
                    quantidade: 0,
                    quantidadePacote: null,
                    parcelas: 0,
                    beneficios: [
                        {beneficio: '5 Documentos únicos por e-mail'},
                        {beneficio: 'Usuários ilimitados'},
                        {beneficio: `${this.$tc("pt_br.signatario", 2)} ilimitados`},
                        {beneficio: 'Painel de Gestão das Assinaturas dos Documentos'},
                        {beneficio: 'Possibilidade de Organização do Documentos em Pastas'},
                        {beneficio: 'Definir ordem de assinaturas'},
                        {beneficio: 'Posicionamento da Assinatura no documento'},
                        {beneficio: 'Personalização de e-mail'},
                        {beneficio: 'Envio de documentos em lote'},
                    ]
                },
                // comboDocumentoWhatsapp: {
                //     nome: 'COMBO_DOCUMENTO_WHATSAPP',
                //     titulo: 'Documentos + WhatsApp',
                //     valorPacote: null,
                //     descricao: '',
                //     quantidade: 0,
                //     quantidadePacote: null,
                //     parcelas: 0,
                //     beneficios: [
                //         {beneficio: 'Todas as funcionalidades do Plano free +', destaque: false},
                //         {beneficio: '10 documentos únicos por e-mail'},
                //         {beneficio: '10 assinaturas únicas por WhatsApp'}
                //     ]
                // }
            },
            dialogPagamentos: false,
            planoSelecionado: {},
            ehPlanosCarregando: true
        }
    },
    async created() {
        this.$store.state.loki.page.title = 'Planos e Valores'
        await this.atualizarPlanos()
        this.atualizarValoresAdcionais()

        if (!this.ehEfcazCLM) {
            if (this.ehMobile) {
                this.abas = [
                    'Plano anual',
                    'Plano API Anual',
                    'Plano Mensal',
                    'Plano API',
                    'Plano Personalizado'
                ]
            } else {
                this.abas = [
                    'Plano anual [BLACK NOVEMBER]',
                    'Plano API Anual [BLACK NOVEMBER]',
                    'Plano Mensal',
                    'Plano API',
                    'Plano Personalizado'
                ]
            }
        }

        if (!this.documentosIlimitados) {
            this.abas.push('Sem recorrência')
        }
    },
    watch: {
        'tabSelect'() {
            this.resetarAdicionais()
            this.planoSelecionado = {}
        }
    },
    computed: {
        ...mapGetters(['ehEfcazCLM', 'ehPlanoFree']),
        ...mapState(['consumoOrganizacao']),
        documentosIlimitados() {
            return this.consumoOrganizacao.documentosIlimitados
        },
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        },
        getPrimaryColor() {
            return this.temPlanoPromocao ? 'black' : 'primary'
        }
    },
    methods: {
        abrirDialogPagamentos(planoSelecionado) {
            this.planoSelecionado = _.cloneDeep(planoSelecionado)

            this.dialogPagamentos = true
        },
        async atualizarValoresAdcionais() {
            let compras = await this.$store.dispatch(actionTypes.COMPRAS.BUSCAR_PLANO_ASSINATURA_ADICIONAL)
            _.merge(this.itensAdicionais, compras, this.itensAdicionais)

            //let qtd_combo = this.itensAdicionais.comboDocumentoWhatsapp.quantidadePacote

            //this.itensAdicionais.comboDocumentoWhatsapp.descricao = `${qtd_combo} Documentos; + ${qtd_combo} WhatsApp`
            this.itensAdicionais.documento.descricao = `${this.itensAdicionais.documento.quantidadePacote} Documentos`

        },
        async atualizarPlanos() {
            let todosPlanos = await this.$store.dispatch(actionTypes.PLANOS.BUSCAR_TODOS_PLANOS_ORDENADOS)

            todosPlanos.forEach(plano => {
                let titulo = this.tratarTituloPlano(plano.nome)

                switch (plano.nome) {
                    case 'LIGHT':
                        this.planos.light.titulo = titulo
                        this.planos.light.quantidade = 0
                        this.planos.light.parcelas = 0
                        this.planos.light = _.merge(this.planos.light, plano)
                        break
                    case 'LIGHT_ANUAL':
                        this.planos.lightAnual.titulo = titulo
                        this.planos.lightAnual.quantidade = 0
                        this.planos.lightAnual.parcelas = 0
                        this.planos.lightAnual = _.merge(this.planos.lightAnual, plano)
                        break
                    // case 'STARTER_WHATSAPP':
                    //     this.planos.starterWhatsApp.titulo = titulo
                    //     this.planos.starterWhatsApp.quantidade = 0
                    //     this.planos.starterWhatsApp.parcelas = 0
                    //     this.planos.starterWhatsApp = _.merge(this.planos.starterWhatsApp, plano)
                    //     break
                    // case 'STARTER_WHATSAPP_ANUAL':
                    //     this.planos.starterWhatsAppAnual.titulo = titulo
                    //     this.planos.starterWhatsAppAnual.quantidade = 0
                    //     this.planos.starterWhatsAppAnual.parcelas = 0
                    //     this.planos.starterWhatsAppAnual = _.merge(this.planos.starterWhatsAppAnual, plano)
                    //     break
                    case 'STARTER':
                        this.planos.starter.titulo = titulo
                        this.planos.starter.quantidade = 0
                        this.planos.starter.parcelas = 0
                        this.planos.starter = _.merge(this.planos.starter, plano)
                        break
                    case 'STARTER_ANUAL':
                        this.planos.starterAnual.titulo = titulo
                        this.planos.starterAnual.quantidade = 0
                        this.planos.starterAnual.parcelas = 0
                        this.planos.starterAnual = _.merge(this.planos.starterAnual, plano)
                        break
                    // case 'PRO_WHATSAPP':
                    //     this.planos.proWhatsApp.titulo = titulo
                    //     this.planos.proWhatsApp.quantidade = 0
                    //     this.planos.proWhatsApp.parcelas = 0
                    //     this.planos.proWhatsApp = _.merge(this.planos.proWhatsApp, plano)
                    //     break
                    // case 'PRO_WHATSAPP_ANUAL':
                    //     this.planos.proWhatsAppAnual.titulo = titulo
                    //     this.planos.proWhatsAppAnual.quantidade = 0
                    //     this.planos.proWhatsAppAnual.parcelas = 0
                    //     this.planos.proWhatsAppAnual = _.merge(this.planos.proWhatsAppAnual, plano)
                    //     break
                    case 'PRO':
                        this.planos.pro.titulo = titulo
                        this.planos.pro.quantidade = 0
                        this.planos.pro.parcelas = 0
                        this.planos.pro = _.merge(this.planos.pro, plano)
                        break
                    case 'PRO_ANUAL':
                        this.planos.proAnual.titulo = titulo
                        this.planos.proAnual.quantidade = 0
                        this.planos.proAnual.parcelas = 0
                        this.planos.proAnual = _.merge(this.planos.proAnual, plano)
                        break
                    case 'API_STARTER':
                        this.planos.starterApi.titulo = titulo
                        this.planos.starterApi.quantidade = 0
                        this.planos.starterApi.parcelas = 0
                        this.planos.starterApi = _.merge(this.planos.starterApi, plano)
                        break
                    case 'API_STARTER_ANUAL':
                        this.planos.starterApiAnual.titulo = titulo
                        this.planos.starterApiAnual.quantidade = 0
                        this.planos.starterApiAnual.parcelas = 0
                        this.planos.starterApiAnual = _.merge(this.planos.starterApiAnual, plano)
                        break
                    case 'API_LIGHT':
                        this.planos.lightApi.titulo = titulo
                        this.planos.lightApi.quantidade = 0
                        this.planos.lightApi.parcelas = 0
                        this.planos.lightApi = _.merge(this.planos.lightApi, plano)
                        break
                    case 'API_LIGHT_ANUAL':
                        this.planos.lightApiAnual.titulo = titulo
                        this.planos.lightApiAnual.quantidade = 0
                        this.planos.lightApiAnual.parcelas = 0
                        this.planos.lightApiAnual = _.merge(this.planos.lightApiAnual, plano)
                        break
                    case 'API_PRO':
                        this.planos.proApi.titulo = titulo
                        this.planos.proApi.quantidade = 0
                        this.planos.proApi.parcelas = 0
                        this.planos.proApi = _.merge(this.planos.proApi, plano)
                        break
                    case 'API_PRO_ANUAL':
                        this.planos.proApiAnual.titulo = titulo
                        this.planos.proApiAnual.quantidade = 0
                        this.planos.proApiAnual.parcelas = 0
                        this.planos.proApiAnual = _.merge(this.planos.proApiAnual, plano)
                        break
                }
            })

            this.verificarPlanoPromocao()

            this.ehPlanosCarregando = false
        },
        ehOcultarAba(tab) {
            return this.documentosIlimitados && tab === 'Sem Recorrência'
        },
        fecharDialogPagamentos() {
            this.dialogPagamentos = false
        },
        resetarAdicionais() {
            Object.keys(this.itensAdicionais).forEach(adicional => {
                this.itensAdicionais[adicional].quantidade = 0
            })
        },
        verificarPlanoPromocao() {
            let lightPromo = this.planos.light.promocao ||
                this.planos.lightAnual.promocao ||
                this.planos.lightApi.promocao ||
                this.planos.lightApiAnual.promocao

            let starterPromo = this.planos.starter.promocao ||
                this.planos.starterAnual.promocao ||
                // this.planos.starterWhatsApp.promocao ||
                // this.planos.starterWhatsAppAnual.promocao ||
                this.planos.starterApi.promocao ||
                this.planos.starterApiAnual.promocao

            let proPromo = this.planos.pro.promocao ||
                this.planos.proAnual.promocao ||
                // this.planos.proWhatsApp.promocao ||
                // this.planos.proWhatsAppAnual.promocao ||
                this.planos.proApi.promocao ||
                this.planos.proApiAnual.promocao

            this.temPlanoPromocao = lightPromo || starterPromo || proPromo
        },
        tratarTituloPlano(titulo) {
            let tituloAux = titulo.toLowerCase().replaceAll('_', ' ')
            return tituloAux.replace(/(^\w{1})|(\s+\w{1})/g, letra => letra.toUpperCase());
        }
    }
}
</script>

<style lang="stylus">
.co-planos
    width 100%
    background-color var(--background-100) !important
    height calc(100vh - 96px) !important
    overflow auto

    .v-slide-group__prev--disabled
      display none !important

    .banner-plano-wrapper
        .banner-plano
            background-color var(--background-150) !important
            color var(--text-color-300)
            font-size 30px
            width 100%

        .black-friday
            background-color var(--text-color-500) !important;
            color white

    .enfase
        color var(--primary)

@media (max-width: 720px)
    .co-planos
        height calc(100vh - 60px) !important;

@media (max-height: 700px)
    .co-planos
        .banner-plano-wrapper
            display none

</style>
