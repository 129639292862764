<template>
    <div>
        <div v-if="ehPasta">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-icon
                        size="20"
                        v-on="on"
                        :color="podeNotificarPasta ? 'secondary-200' : 'secondary-100'"
                        @click="verificarPerfilUsuarioPasta">
                        {{ podeNotificarPasta ? 'mdi-bell' : 'mdi-bell-off' }}
                    </v-icon>
                </template>
                <span>Silenciar/Ativar notificações</span>
            </v-tooltip>
        </div>

        <div v-else>
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-icon
                        size="20"
                        v-on="on"
                        :color="podeNotificarContrato ? 'secondary-200' : 'secondary-100'"
                        v-if="ehContratoNotificavel"
                        @click="verificarPerfilUsuarioContrato">
                        {{ podeNotificarContrato ? 'mdi-bell' : 'mdi-bell-off' }}
                    </v-icon>
                    <v-icon
                        size="20"
                        v-on="on"
                        color="secondary-100"
                        v-else-if="!ehContratoNotificavel">
                        mdi-bell-off
                    </v-icon>
                </template>
                <span>Silenciar/Ativar notificações</span>
            </v-tooltip>
        </div>

        <dialog-notificacao
            v-if="dialogNotificacao"
            :dialog="dialogNotificacao"
            :eh-pasta="ehPasta"
            :usuarios="usuariosNotificacao"
            @ativarNotificacao="ativarNotificacao"
            @desativarNotificacao="desativarNotificacao"
            @desativarTodasNotificacoes="desativarTodasNotificacoes"
            @fecharModalNotificacao="fecharDialogNotificacao"
        />

        <dialog-default-confirmar-acao
            v-if="dialoglDesativarNotificacao"
            :dialog="dialoglDesativarNotificacao"
            sentimento="assustado"
            titulo="Eita! Deixa eu confirmar uma coisa..."
            :subtitulo="getLabelModalDesativarNotificacao"
            label-botao-principal="Sim, eu quero desativar!"
            @acao-principal="fecharDialogDesativarNotificacao"
            @fechar="fecharDialogDesativarNotificacao"
        />
    </div>
</template>

<script>
import DialogDefaultConfirmarAcao from '@/commons/components/dialogs/DialogDefaultConfirmarAcao'
import _ from 'lodash'
import {actionTypes} from '@/commons/constants'
import DialogNotificacao from '@/views/home/meus-contratos/dialogs/DialogNotificacao'

const mensagensModal = {
    MENSAGEM_DESATIVAR_PASTA: 'Você quer mesmo desativar as notificações desta pasta? Ao confirmar\n' +
        '                    não será notificado sobre todos os documentos existentes na pasta.',
    MENSAGEM_DESATIVAR_CONTRATO: 'Você quer mesmo desativar as notificações deste documento? Ao confirmar\n' +
        '                    não será notificado sobre as ações do documento.'
}

export default {
    name: 'Notificacao',
    components: {DialogNotificacao, DialogDefaultConfirmarAcao},
    data() {
        return {
            usuariosNotificacao: [],
            dialoglDesativarNotificacao: false,
            dialogNotificacao: false
        }
    },
    props: {
        contrato: {
            type: Object,
            required: false
        },
        pasta: {
            type: Object,
            required: false
        }
    },
    computed: {
        ehContratoCancelado() {
            return this.contrato.minutaSituacaoFormalizacao === 'CANCELADO'
        },
        ehContratoArquivado() {
            return this.contrato.minutaSituacaoFormalizacao === 'ARQUIVADO'
        },
        ehContratoExtinto() {
            return this.contrato.minutaSituacaoFormalizacao === 'EXTINTO'
        },
        ehPasta() {
            return this.pasta != null
        },
        ehContratoNotificavel() {
            return !this.ehContratoCancelado && !this.ehContratoArquivado && !this.ehContratoExtinto
        },
        getLabelModalDesativarNotificacao() {
            return this.ehPasta ? mensagensModal.MENSAGEM_DESATIVAR_PASTA : mensagensModal.MENSAGEM_DESATIVAR_CONTRATO
        },
        podeNotificarContrato() {
            return this.contrato.notificar
        },
        podeNotificarPasta() {
            return this.pasta.notificar
        }
    },
    methods: {
        abrirDialogDesativarNotificacao() {
            this.dialoglDesativarNotificacao = true
        },
        abrirDialogNotificacao() {
            this.dialogNotificacao = true
        },
        async ativarNotificacao(usuario) {
            if (this.ehPasta) {
                await this.ativarNotificacaoPasta([usuario.usuarioId])
                await this.buscarUsuariosNotificacaoPasta()
                this.atualizarNotificacaoPastaState([usuario], true)
            } else {
                await this.ativarNotificacaoContrato([usuario.usuarioId])
                await this.buscarUsuariosNotificacaoContrato()
                this.atualizarNotificacaoContratoState([usuario], true)
            }
            this.mostrarNotificacaoSucessoDefault()
        },
        async ativarNotificacaoContrato(usuariosId) {
            await this.$store.dispatch(actionTypes.ATIVAR_NOTIFICACAO_CONTRATO, {
                organizacaoId: this.contrato.organizacaoId,
                contratoId: this.contrato.contratoId,
                notificacaoContrato: {
                    contratoId: this.contrato.contratoId,
                    usuariosId: usuariosId
                }
            })
        },
        async ativarNotificacaoPasta(usuarioId) {
            await this.$store.dispatch(actionTypes.ATIVAR_NOTIFICACAO_PASTA, {
                organizacaoId: this.$store.state.login.organizacaoPlano.organizacaoId,
                pastaId: this.pasta.pastaId,
                pastaUsuarioAcesso: {
                    pastaId: this.pasta.pastaId,
                    usuariosId: usuarioId
                }
            })
        },
        atualizarNotificacaoContratoState(usuariosNotificacao, notificar) {
            const usuarioLogadoId = this.$store.state.loki.user.id
            if (_.find(usuariosNotificacao, (usuario) => usuario.usuarioId === usuarioLogadoId)) {
                this.contrato.notificar = notificar
            }
        },
        atualizarNotificacaoPastaState(usuariosNotificacao, notificar) {
            const usuarioLogadoId = this.$store.state.loki.user.id
            if (_.find(usuariosNotificacao, (usuario) => usuario.usuarioId === usuarioLogadoId)) {
                this.pasta.notificar = notificar
            }
        },
        async buscarUsuariosNotificacaoContrato() {
            this.usuariosNotificacao = await this.$store.dispatch(actionTypes.BUSCAR_USUARIOS_NOTIFICACAO_CONTRATO, {
                organizacaoId: this.$store.state.login.organizacaoPlano.organizacaoId,
                contratoId: this.contrato.contratoId
            })
        },
        async buscarUsuariosNotificacaoPasta() {
            this.usuariosNotificacao = await this.$store.dispatch(actionTypes.BUSCAR_USUARIOS_NOTIFICACAO_PASTA, {
                organizacaoId: this.$store.state.login.organizacaoPlano.organizacaoId,
                pastaId: this.pasta.pastaId
            })
        },
        async desativarNotificacao(usuario) {
            if (this.ehPasta) {
                await this.desativarNotificacaoPasta([usuario.usuarioId])
                await this.buscarUsuariosNotificacaoPasta()
                this.atualizarNotificacaoPastaState([usuario], false)
            } else {
                await this.desativarNotificacaoContrato([usuario.usuarioId])
                await this.buscarUsuariosNotificacaoContrato()
                this.atualizarNotificacaoContratoState([usuario], false)
            }
            this.fecharDialogDesativarNotificacao()
            this.mostrarNotificacaoSucessoDefault()
        },
        async desativarNotificacaoContrato(usuariosId) {
            await this.$store.dispatch(actionTypes.DESATIVAR_NOTIFICACAO_CONTRATO, {
                organizacaoId: this.contrato.organizacaoId,
                contratoId: this.contrato.contratoId,
                notificacaoContrato: {
                    contratoId: this.contrato.contratoId,
                    usuariosId: usuariosId
                }
            })
        },
        async desativarNotificacaoPasta(usuarioId) {
            await this.$store.dispatch(actionTypes.DESATIVAR_NOTIFICACAO_PASTA, {
                organizacaoId: this.$store.state.login.organizacaoPlano.organizacaoId,
                pastaId: this.pasta.pastaId,
                pastaUsuarioAcesso: {
                    pastaId: this.pasta.pastaId,
                    usuariosId: usuarioId
                }
            })
        },
        async desativarTodasNotificacoes(usuarios) {
            const usuariosId = this.getUsuariosId(usuarios)
            if (this.ehPasta) {
                await this.desativarNotificacaoPasta(usuariosId)
                await this.buscarUsuariosNotificacaoPasta()
                this.atualizarNotificacaoPastaState(usuarios, false)
            } else {
                await this.desativarNotificacaoContrato(usuariosId)
                await this.buscarUsuariosNotificacaoContrato()
                this.atualizarNotificacaoContratoState(usuarios, false)
            }
            this.mostrarNotificacaoSucessoDefault()
        },
        ehAdministradorOrganizacao() {
            const usuarioLogadoId = this.$store.state.loki.user.id
            const administradoresId = this.$store.state.login.organizacaoPlano.administradoresId
            return _.find(administradoresId, (administradorId) => administradorId === usuarioLogadoId) !== undefined
        },
        fecharDialogDesativarNotificacao() {
            this.dialoglDesativarNotificacao = false
        },
        fecharDialogNotificacao() {
            this.dialogNotificacao = false
        },
        getUsuariosId(usuarios) {
            let usuariosId = []
            usuarios.forEach(usuario => {
                if (usuario.ativo) {
                    usuariosId.push(usuario.usuarioId)
                }
            })
            return usuariosId
        },
        async verificarPerfilUsuarioContrato() {
            if (this.ehAdministradorOrganizacao()) {
                await this.buscarUsuariosNotificacaoContrato()
                return this.abrirDialogNotificacao()
            }

            if (this.contrato.notificar && !this.ehContratoCancelado) {
                return this.abrirDialogDesativarNotificacao()
            }
            return this.ativarNotificacao(this.$store.state.login.usuarioOrganizacao)
        },
        async verificarPerfilUsuarioPasta() {
            if (this.ehAdministradorOrganizacao()) {
                await this.buscarUsuariosNotificacaoPasta()
                return this.abrirDialogNotificacao()
            }

            if (this.pasta.notificar) {
                return this.abrirDialogDesativarNotificacao()
            }
            return this.ativarNotificacao(this.$store.state.login.usuarioOrganizacao)
        },
    }
}
</script>