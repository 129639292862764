<template>
    <div>
        <v-app-bar flat dark color="primary" class="co-detalhe-contrato-toolbar">
            <v-btn dark icon @click="voltarParaMeusDocumentos">
                <v-icon> mdi-chevron-left </v-icon>
            </v-btn>
            <div>
                <div>
                    <detalhe-contrato-nome
                        :nome="contrato.nome"
                        :minutaAtual="minutaAtual"
                        :tags="contratoTags"
                        :contrato="contrato"
                        @salvar="atualizarDadosContrato"
                    />
                </div>

                <v-tooltip bottom v-if="possuiTags">
                    <template v-slot:activator="{ on }">
                        <div class="toolbar-tag ml-1" v-on="on">
                            <div style="width: 200px; text-overflow: ellipsis; overflow: hidden;">
                                <span>Tags: </span>
                                <span v-for="(tag, idx) in tags">
                                    {{ tag | azClipText(15) }}{{ idx >= tags.length - 1 ? "" : ", " }} 
                                </span>
                            </div>

                            <span v-if="ehItemMinuta && valorTotalItem"> Valor total: {{ valorTotalItem }} </span>
                        </div>
                    </template>

                    <div style="max-width: 400px; text-overflow: ellipsis; overflow: hidden;">
                        <span v-for="(tag, idx) in tags" class=" mr-1">
                            {{ tag }}{{ idx >= tags.length - 1 ? "" : ", " }}  
                        </span>
                    </div>
                </v-tooltip>
            </div>

            <v-spacer></v-spacer>
            <div class="situacao no-mobile">
                <span v-if="contrato.arquivado">Arquivado</span>
                <visualizar-extincao v-else-if="contrato.extinto" :contrato="contrato"/>
                <span v-else>{{ minutaAtual.situacaoFormalizacao | az-enum(situacaoFormalizacao) }}</span>
                <div class="data-formalizacao"
                     v-if="minutaAtual.situacaoFormalizacao === 'FORMALIZADO' && !contrato.extinto">
                    Formalizado em: {{ minutaAtual.dataFormalizacao | azDate }}
                </div>
            </div>
            <div class="acoes">
                <detalhe-contrato-acoes @buscarDocumento="buscarDocumento"/>
            </div>
        </v-app-bar>

    </div>
</template>

<script>
import {mapState} from 'vuex'
import {situacaoFormalizacao} from '@/commons/constants'
import DetalheContratoAcoes from './DetalheContratoAcoes'
import DetalheContratoNome from './DetalheContratoNome'
import VisualizarExtincao from './extincao/VisualizarExtincao'
import RouterUtils from '@/commons/utils/RouterUtils'

export default {
    name: 'DetalheContratoToolbar',
    components: {DetalheContratoAcoes, DetalheContratoNome, VisualizarExtincao},
    data() {
        return {
            situacaoFormalizacao,
            notificarUsuario: true,
            podeNotificar: false,
        }
    },
    computed: {
        az() {
            return az
        },
        ...mapState(['contrato', 'minutaAtual', 'login', "meusContratosBusca"]),
        ehItemMinuta() {
            return this.login.funcionalidadesOrganizacao.itemMinuta
        },
        contratoTags(){
            return this.contrato.contratoTags ? this.contrato.contratoTags : []
        },
        possuiTags(){
            if (!this.contrato.contratoTags || this.contrato.contratoTags.length === 0) {
                return false
            }
            return true
        },
        tags() {
            let tags = []
            if (this.possuiTags) {
                this.contrato.contratoTags.forEach(tag => {
                    tags.push(tag.nome)
                })
            }
            return tags
        },
        valorTotalItem() {
            return this.minutaAtual.valorTotalItemMinuta ?
                Number(this.minutaAtual.valorTotalItemMinuta).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                })
                : ''
        }
    },
    watch: {
        'contrato'() {
            this.verificarAcaoNotificarContrato()
        }
    },
    methods: {
        buscarDocumento() {
            this.$emit('buscarDocumento')
        },
        atualizarDadosContrato(novoNome, tags) {
            this.$emit('atualizarDadosContrato', novoNome, tags)
        },
        getUsuariosId(usuarios) {
            let usuariosId = []
            usuarios.forEach(usuario => {
                if (usuario.ativo) {
                    usuariosId.push(usuario.usuarioId)
                }
            })
            return usuariosId
        },
        verificarAcaoNotificarContrato() {
            this.podeNotificar = this.$store.state.contrato.notificar
        },
        voltarParaMeusDocumentos() {
            RouterUtils.voltarParaMeusDocumentos(this.meusContratosBusca.filtros.formalizacao.value)
        },
        verificarPerfilUsuario(usuarios) {
            if (this.ehAdministradorOrganizacao()) {
                return this.abrirModalNotificacao()
            }
            if (this.contrato.notificar && !this.ehMinutaCancelada()) {
                return this.abrirModalDesativarNotificacao()
            }
            return this.ativarNotificacao(usuarios)
        },
    }
}
</script>

<style lang="stylus">
.co-detalhe-contrato-toolbar
    .v-toolbar__title
        font-size 18px !important
        margin-left 0 !important

    .toolbar-tag
        font-size 12px
        color #eeeeee
        white-space nowrap

    .situacao
        font-size 16px
        font-weight bold
        margin-right 20px
        text-align right

    .data-formalizacao
        font-size 12px
        color rgba(255, 255, 255, 0.5)
</style>