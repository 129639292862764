import {mutationTypes} from '@/commons/constants'
import Vue from 'vue'

export default {

    [mutationTypes.PREFERENCIAS_USUARIO.SET_ASIDE_CLOSE](state, asideClosed) {
        const preferencias = state.preferenciasUsuario

        preferencias.asideClosed = asideClosed

        Vue.set(state, 'preferenciasUsuario', preferencias)
    },

    [mutationTypes.PREFERENCIAS_USUARIO.SET_LAYOUT_LISTA](state, layout) {
        const preferencias = state.preferenciasUsuario

        preferencias.layoutLista = layout

        Vue.set(state, 'preferenciasUsuario', preferencias)
    },

    [mutationTypes.PREFERENCIAS_USUARIO.SET_ORDENACAO_BUSCA](state, sort) {
        const ordenacao = state.preferenciasUsuario.ordenacao

        ordenacao.sortBy = sort.sortBy
        ordenacao.descending = sort.descending

        Vue.set(state.preferenciasUsuario, 'ordenacao', ordenacao)
    },

    [mutationTypes.PREFERENCIAS_USUARIO.SET_PREFERENCIAS](state, preferencias) {
        Vue.set(state, 'preferenciasUsuario', preferencias)
    }
}