<template>
    <div class="co-lista-pdf">
        <div>Documentos ({{ quantidadePdf }})</div>

        <div id="listaPdf" class="lista-fechada pa-2">
            <upload-lista-pdf :pdfs="pdfs" @adicionar-pdf="adicionarPdf"/>

            <div style="display: flex; justify-content: flex-end; margin: 0 20px 20px 0">
                {{ tamanhoTotalPdfs }} de {{ maxSize }}
            </div>

            <header-list-pdf></header-list-pdf>

            <v-divider class="mb-1"/>
            <div v-for="(item, index) in pdfs" :key="index">
                <item-pdf
                    :pdf="item"
                    @excluir="abrirDialogConfirmacaoRemocao"
                    @visualizar="visualizarPdf"
                />
            </div>
        </div>

        <dialog-visualizar-pdf
            :dialog="dialogVisualizarPdf"
            :pdf="pdfAcao"
            @fechar="fecharDialogVisualizarPdf"
        />

        <dialog-default-confirmar-acao
            v-if="dialogConfirmacaoRemocao"
            :dialog="dialogConfirmacaoRemocao"
            sentimento="assustado"
            titulo="Eita! Deixa eu confirmar uma coisa..."
            subtitulo="Você quer mesmo remover este pdf ?"
            label-botao-principal="Sim, eu quero remover!"
            @acao-principal="removerPdf"
            @fechar="fecharDialogConfirmacaoRemocao"
        />
    </div>
</template>

<script>
import ItemPdf from '@/views/contrato/novo/upload-envelope/components/ListaPdf/components/ItemPdf'
import UploadListaPdf from '@/views/contrato/novo/upload-envelope/components/ListaPdf/components/UploadListaPdf'
import DialogVisualizarPdf from '@/views/contrato/novo/upload-envelope/components/ListaPdf/dialog/DialogVisualizarPdf'
import DialogDefaultConfirmarAcao from '@/commons/components/dialogs/DialogDefaultConfirmarAcao'
import HeaderListPdf from '@/views/contrato/novo/upload-envelope/components/ListaPdf/components/headerListPdf'

export default {
    name: 'ListaPdf',
    components: {HeaderListPdf, DialogDefaultConfirmarAcao, DialogVisualizarPdf, UploadListaPdf, ItemPdf},
    props: {
        pdfs: {
            type: Array
        }
    },
    data() {
        return {
            dialogVisualizarPdf: false,
            dialogConfirmacaoRemocao: false,
            pdfAcao: {}
        }
    },
    mounted() {
        this.exbirlistaPdf()
    },
    computed: {
        maxSize() {
            return this.$store.state.loki.file.maxSize.toUpperCase()
        },
        quantidadePdf() {
            return this.pdfs.length
        },
        tamanhoTotalPdfs() {
            const size = this.pdfs.reduce((acumalador, atual) => acumalador += atual.size, 0)
            return this.formatBytes(size)
        }
    },
    methods: {
        abrirDialogConfirmacaoRemocao(pdf) {
            this.pdfAcao = pdf
            this.dialogConfirmacaoRemocao = true
        },
        abrirDialogVisualizarPdf() {
            this.dialogVisualizarPdf = true
        },
        abrirListaPdf() {
            let listaPdf = document.getElementById("listaPdf")
            listaPdf.classList.add('lista-aberta')
            listaPdf.classList.remove('lista-fechada')
        },
        adicionarPdf(files) {
            this.$emit('adicionar-pdf', files)
        },
        exbirlistaPdf() {
            setTimeout(this.abrirListaPdf)
        },
        fecharDialogConfirmacaoRemocao() {
            this.dialogConfirmacaoRemocao = false
        },
        fecharDialogVisualizarPdf() {
            this.dialogVisualizarPdf = false
            this.pdfAcao = {}
        },
        formatBytes(bytes, decimals = 2) {
            if (bytes === 0) return '0 Bytes'

            const kb = 1024
            const dm = decimals < 0 ? 0 : decimals
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

            const i = Math.floor(Math.log(bytes) / Math.log(kb))
            return parseFloat((bytes / Math.pow(kb, i)).toFixed(dm)) + ' ' + sizes[i]
        },
        removerPdf() {
            this.$emit('remover-pdf', this.pdfAcao)
            this.fecharDialogConfirmacaoRemocao()
            this.pdfAcao = {}
        },
        visualizarPdf(pdf) {
            this.pdfAcao = pdf
            this.abrirDialogVisualizarPdf()
        }
    }
}
</script>

<style lang="stylus">
.co-lista-pdf
    color #777777

    .lista-fechada
        height 10px
        opacity 0.1

    .lista-aberta
        height 450px
        overflow-y auto
        opacity 1
        -webkit-transition opacity 1s, height 2s 1s
        -moz-transition opacity 1s, height 2s 1s
        -ms-transition opacity 1s, height 2s 1s
        -o-transition opacity 1s, height 2s 1s
        transition opacity 1s, height 1s


</style>