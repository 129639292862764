<template>
    <v-col class="px-2 py-0 co-campo-pesquisa">
        <v-text-field
            class="pa-0 input-busca"
            v-model="value.nome.value"
            name="BuscarPor"
            :placeholder="placeHolderPesquisalabel"
            persistent-placeholder
            hide-details
            v-on:blur="filtrar"
            v-on:keyup.enter="filtrar"
        >
            <template #append>
                <v-btn icon @click="filtrar">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on">
                                mdi-magnify
                            </v-icon>
                        </template>
                        <span>Pesquisar</span>
                    </v-tooltip>
                </v-btn>

                <v-btn icon @click="limpar">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on">
                                mdi-close
                            </v-icon>
                        </template>
                        <span>Limpar filtros</span>
                    </v-tooltip>
                </v-btn>
            </template>
        </v-text-field>
    </v-col>
</template>

<script>
import {mutationTypes} from '@/commons/constants'
import {mapGetters} from 'vuex'

export default {
    name: "CampoPesquisa",
    props: {
        value: {
            required: true
        },
    },
    data() {
        return {
            tiposBusca: [
                {
                    descricao: 'Documento',
                    valor: 'contratos',
                },
                {
                    descricao: 'Pasta',
                    valor: 'pastas',
                }
            ]
        }
    },
    computed: {
        ...mapGetters(['ehEfcazCLM']),
        placeHolderPesquisalabel() {
            return `Buscar por ${this.$tc("pt_br.documento").toLowerCase()} ou pastas`
        }
    },
    methods: {
        filtrar() {
            const filtros = this.value
            this.$store.commit(mutationTypes.FILTROS.SET_FILTROS_BUSCA, filtros)
            this.$store.commit(mutationTypes.ACOES.SET_ACOES_FILTRAR)
        },
        limpar() {
            this.$store.commit(mutationTypes.FILTROS.LIMPAR_FILTROS_BUSCA)
            this.$store.commit(mutationTypes.ACOES.SET_ACOES_FILTRAR)
        }
    }
}
</script>

<style lang="stylus">
.co-campo-pesquisa
    padding 0
    width 100%
    max-width 500px

    .input-busca
        input
            text-overflow ellipsis

    .v-select__selections
        width 100px

    .v-input__slot
        margin-bottom 0

    .append-pesquisa
        display inline-flex

</style>