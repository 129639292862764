<template>
    <div>
        <v-row class="justify-center" no-gutters>
            <card-plano-anual
                v-for="(planosInfos, index) in planosInformacoes" :key="planosInfos.titulo"
                :index="index"
                :eh-plano-atual="nomePlano === planosInfos.nome"
                :eh-alteracao-plano="!ehPlanoFree"
                :plano="planosInfos"
                :eh-melhor-opcao="planosInfos.nome === 'API_PRO_ANUAL'"
                @alterar-plano="abrirDialogAlterarPlano"
                @cancelar-plano="abrirDialogCancelarPlano"
                @abrir-pagamentos="abrirPagamentos"
                class="my-3"
            />
            
            <dialog-default-confirmar-acao
                v-if="dialogDefault"
                apenas-acao-principal
                :dialog="dialogDefault"
                :sentimento="dialog.sentimento"
                :titulo="dialog.titulo"
                :subtitulo="dialog.subtitulo"
                :label-botao-principal="dialog.labelBotaoPrincipal"
                @acao-principal="fecharDialogDefault"
                @fechar="fecharDialogDefault"
            />

            <dialog-cancelar-plano
                v-if="dialogCancelarPlano"
                apenas-acao-principal
                :dialog="dialogCancelarPlano"
                @fechar="fecharDialogCancelarPlano"
                @solicitacao-realizada="concluirSolicitacaoCancelamento"
            />
        </v-row>
    </div>
</template>

<script>

import {mapGetters} from 'vuex'
import DialogDefaultConfirmarAcao from '@/commons/components/dialogs/DialogDefaultConfirmarAcao'
import CardPlanoAnual from './components/CardPlanoAnual'
import DialogCancelarPlano from '../dialog/DialogCancelarPlano'

export default {
    name: 'AnuaisApi',
    components: {DialogCancelarPlano, DialogDefaultConfirmarAcao, CardPlanoAnual},
    props: {
        planos: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            planosInformacoes: [],
            dialogCancelarPlano: false,
            dialogDefault: false,
            dialog: {
                titulo: '',
                subtitulo: '',
                labelBotaoPrincipal: '',
                sentimento: ''
            }
        }
    },
    created() {        
        this.planosInformacoes = [
            this.planos.lightApiAnual, 
            this.planos.starterApiAnual,
            this.planos.proApiAnual,
        ]
    },
    computed: {
        ...mapGetters(['nomePlano', 'ehPlanoFree']),
    },
    methods: {
        abrirPagamentos(planoSelecionado) {
            this.$emit('abrir-pagamentos', planoSelecionado)
        },
        abrirDialogCancelarPlano() {
            this.dialogCancelarPlano = true
        },
        abrirDialogAlterarPlano() {
            let dialogDowgrade = {
                sentimento: 'assustado',
                titulo: 'Ops, não é possível realizar a alteração do plano',
                subtitulo: 'Para prosseguir com a troca de plano realize o cancelamento do seu plano atual, e faça a contratação de um novo. Se possuir dúvidas entre em contato com um de nossos atendentes através do chat, ligue no telefone 0800 722 2701 ou envie um e-mail a suporte@besign.com.br',
                labelBotaoPrincipal: 'Ok'
            }

            this.dialog = _.merge(this.dialog, dialogDowgrade)
            this.dialogDefault = true
        },
        concluirSolicitacaoCancelamento() {
            this.dialogCancelarPlano = false
            let dialogSolicitarCancelamentoConcluido = {
                sentimento: 'feliz',
                titulo: 'Solicitação de cancelamento enviada',
                subtitulo: 'A solicitação de cancelamento enviada ao nosso time. Fique atento ao seu email e ao seu telefone, o status do seu cancelamento será informado por lá. Qualquer duvida nos chame no chat ou ligue no 0800 722 2701',
                labelBotaoPrincipal: 'Ok'
            }
            this.dialog = _.merge(this.dialog, dialogSolicitarCancelamentoConcluido)
            this.dialogDefault = true
        },
        fecharDialogCancelarPlano() {
            this.dialogCancelarPlano = false
            this.$router.push({name: 'meus'})
        },
        fecharDialogDefault() {
            this.dialogDefault = false
        },
    }
}
</script>
