<template>
    <div class="co-tipo-assinatura-novo">
        <v-select
            v-show="exibirSignatarioCompleto"
            class="mb-4"
            item-text="nome"
            item-value="id"
            label="Tipo Assinatura"
            placeholder="Selecione"
            v-on:change="validaTipoWhatsapp"
            :items="tiposAssinatura"
            v-model="signatario.tipoAssinatura"
            persistent-placeholder
            outlined
            hide-details>
            <template v-slot:item="{ item, attrs, on }">
                <v-list-item :disabled="item.disabled" v-on="on">
                    <div class="d-flex justify-space-between align-center co-tipo-assinatura-novo" style="width: 100%">
                        <span>{{ item.nome }}</span>
                        <span v-if="possuiValor(item.saldo)" :class="item.disabled? 'saldo-disabled' : 'saldo'"> Saldo: {{ item.saldo }}</span>
                    </div>
                </v-list-item>
            </template>
        </v-select>
    </div>
</template>

<script>

export default {
    name: 'TipoAssinatura',
    props: {
        exibirSignatarioCompleto: {
            type: Boolean,
            default: true
        },
        signatario: {
            required: true,
            type: Object
        },
        tiposAssinatura: {
            type: Array
        }
    },
    methods: {
        possuiValor(valor) {
            return valor !== null && valor !== undefined && valor !== ''
        },
        validaTipoWhatsapp() {
            this.$emit('validaTipoWhatsapp')
        }
    }
}
</script>

<style lang="stylus">
.co-tipo-assinatura-novo
    .saldo
        font-size 12px !important

    .saldo-disabled
        font-size 12px !important
        color var(--error-200)

</style>
