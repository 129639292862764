import axios from 'axios'
import {actionTypes} from '@/commons/constants'

export default {
    async [actionTypes.ITEM.ATUALIZAR_ITEM_MINUTA]({state}, item) {
        const contratoId = state.contrato.id
        const minutaId = state.minutaAtual.id
        const {data} = await axios.put(`api/contratos/${contratoId}/minutas/${minutaId}/item`, item)
        return data
    },

    async [actionTypes.ITEM.BUSCAR_ITENS_MINUTA]({state}) {
        const contratoId = state.contrato.id
        const minutaId = state.minutaAtual.id
        const {data} = await axios.get(`api/contratos/${contratoId}/minutas/${minutaId}/item`)
        return data
    },

    async [actionTypes.ITEM.INSERIR_ITEM_MINUTA]({state}, item) {
        const contratoId = state.contrato.id
        const minutaId = state.minutaAtual.id
        const {data} = await axios.post(`api/contratos/${contratoId}/minutas/${minutaId}/item`, item)
        return data
    },

    async [actionTypes.ITEM.IMPORTAR_ITENS_MINUTA]({state}, antigaMinutaId) {
        const contratoId = state.contrato.id
        const minutaId = state.minutaAtual.id
        const {data} = await axios.post(`api/contratos/${contratoId}/minutas/${minutaId}/item/importar/${antigaMinutaId}`)
        return data
    },

    async [actionTypes.ITEM.REMOVER_ITEM_MINUTA]({state}, itemId) {
        const contratoId = state.contrato.id
        const minutaId = state.minutaAtual.id
        await axios.delete(`api/contratos/${contratoId}/minutas/${minutaId}/item/${itemId}`)
    },
}