export default {
    methods: {
        base64ToArrayBuffer(base64) {
            const binaryString = window.atob(base64)
            const binaryLen = binaryString.length
            const bytes = new Uint8Array(binaryLen)
            for (let i = 0; i < binaryLen; i++) {
                bytes[i] = binaryString.charCodeAt(i)
            }
            return bytes
        },
        criarLinkDownload(uri, thumbnail) {
            return `${this.$store.state.loki.file.api}?uri=${uri}&thumbnail=${thumbnail === true}`
        },
        criarLinkDownloadTemporario(uri, thumbnail) {
            return `${this.$store.state.loki.file.api}/temporario?uri=${uri}&thumbnail=${thumbnail === true}`
        },
        extractFilename(uri) {
            const beginIndex = uri.lastIndexOf('/') + 1
            return uri.substr(beginIndex, uri.length)
        },
        salvarArquivo(arquivoNome, base64, mimetype = 'application/pdf') {
            let nomeFormatado = arquivoNome.replace(/[^a-z0-9]/gi, '').toLowerCase()
            if (mimetype === 'application/pdf') {
                nomeFormatado = nomeFormatado + '.pdf'
            } else if (mimetype === 'application/zip') {
                nomeFormatado = nomeFormatado + '.zip'
            }
            const bytes = this.base64ToArrayBuffer(base64)
            if (window.Android) {
                window.Android.saveFile(nomeFormatado, bytes)
            } else {
                const blob = new Blob([bytes], {type: mimetype})
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = nomeFormatado
                link.click()
            }
        },
        resetUploadedFiles() {
            this.$store.commit('SET_UPLOADED_FILES', [])
        },
        tratarErroEnvioArquivo() {
            this.mostrarNotificacaoErro(`O arquivo selecionado deve ter no máximo ${this.$store.state.loki.file.maxSize}.`)
        }
    }
}
