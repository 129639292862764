<template>
    <v-list class="co-informacoes-pasta-novo">
        <div class="text-xs-center">
            <div class="pa-3 informacao">
                <template>
                    <div class="titulo">Nome</div>
                    <div class="valor ellipsis">{{ pasta.pastaNome }}</div>
                </template>

                <template v-if="ehItemMinuta">
                    <div class="titulo">Valor total</div>
                    <div class="valor ellipsis">{{ format }}</div>
                </template>
            </div>
        </div>
    </v-list>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'InformacaoPastaNovo',
    props: {
        pasta: {
            required: true
        }
    },
    computed: {
        ...mapState(['login']),
        ehItemMinuta() {
            return this.login.funcionalidadesOrganizacao.itemMinuta
        },
        format() {
            return Number(this.pasta.valorTotalItem).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
            })
        }
    }
}
</script>

<style lang="stylus">
.co-informacoes-pasta-novo
    .informacao
        width 100%

        .ellipsis
            display block
            align-items center
            text-overflow ellipsis
            white-space nowrap
            overflow hidden
            width 100%

    .titulo
        color var(--text-color-400)

    .valor
        color var(--text-color-200)

</style>