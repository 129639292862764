<template>
    <v-col class="co-relatorios">
        <barra-saldo ref="refBarraSaldo" v-if="!ehOrganizacaoFreeSemAssinatura"/>
        <v-row no-gutters style="margin: 20px">
            <v-select
                v-model="tipoRelatorio"
                v-validate="'required'"
                class="required-field"
                :error-messages="errors.collect('Tipo Relatório')"
                :items="tiposRelatorio"
                label="Selecione o tipo de relatório"
                name="Tipo de relatório"
                placeholder="Selecione"
                persistent-placeholder>
            </v-select>
        </v-row>
        <v-row no-gutters>
            <v-spacer/>
            <v-btn class="mt-4 mr-12" 
                    dark 
                    color="primary"
                    :loading="carregandoRelatorio"
                    @click="gerar">
                Gerar
            </v-btn>
        </v-row>

        <dialog-default-confirmar-acao
            v-if="dialogdefaultConfirmaAcao"
            :dialog="dialogdefaultConfirmaAcao"
            sentimento="assustado"
            titulo="Poxa, você não tem acesso a esse tipo de funcionalidade"
            subtitulo="Faça um upgrade para um plano recorrente para poder utilizar essa funcionalidade. Clique em “Sim, eu quero” para conhecer nossas opções 🚀"
            label-botao-principal="Sim, eu quero!"
            @acao-principal="acaoDialogDefaultConfirmaAcao"
            @fechar="fecharDialogDefaultConfirmarAcao"
        />
    </v-col>
</template>

<script>
import BarraSaldo from '@/views/home/meus-contratos/components/BarraSaldo'
import DialogDefaultConfirmarAcao from '@/commons/components/dialogs/DialogDefaultConfirmarAcao'
import {actionTypes} from '@/commons/constants'
import {mapGetters} from 'vuex'
import moment from 'moment'

export default {
    name: "Relatorios",
    components: {
        BarraSaldo,
        DialogDefaultConfirmarAcao
    },
    data() {
        return {
            tipoRelatorio: {},
            tiposRelatorio: [
                {text: `${this.$tc("pt_br.signatario", 2)} com pendência de assinatura`, value: 'CSV'},
                {text: 'Gerar Dashboard', value: 'DASHBOARD'}
            ],
            carregandoRelatorio: false,
            dialogdefaultConfirmaAcao: false
        }
    },
    mounted() {
        this.$store.state.loki.page.title = 'Relatórios'
    },
    computed: {
        ...mapGetters(['ehEfcazCLM', 'ehPlanoFree', 'ehOrganizacaoSemAssinatura']),
        ehOrganizacaoFreeSemAssinatura() {
            return !this.ehEfcazCLM && this.ehPlanoFree && this.ehOrganizacaoSemAssinatura
        },
        ehFreeGerandoRelatorioPendentes() {
            return this.tipoRelatorio === 'CSV' && this.ehPlanoFree
        }
    },
    methods:{
        acaoDialogDefaultConfirmaAcao() {
            this.$router.push({name: 'planos'})
        },
        async gerar() {
            this.carregandoRelatorio = true

            if (this.ehFreeGerandoRelatorioPendentes) {
                this.abriDialogDefault()
                this.carregandoRelatorio = false
                return
            }
            
            const url = await this.$store.dispatch(actionTypes.RELATORIO.GERAR, {path: this.tipoRelatorio.toLowerCase() })
            
            if (url.message) {
                this.mostrarNotificacaoErro(url.message.substring(2, url.message.length - 2))
                this.carregandoRelatorio = false
                return
            }

            const a = document.createElement('a')

            const dataRelatorio = moment(new Date()).format('DD/MM/YYYY HH:mm:ss')
            const dataRelatorioArquivo = dataRelatorio.toString().replaceAll(":", "-").replaceAll("/", "-").replaceAll(" ", "-")

            document.body.appendChild(a)
            a.href = url
            a.download = `relatorio-${this.$tc("pt_br.signatario", 2).toLowerCase()}-pendencia-assinatura-${dataRelatorioArquivo}.xls`
            a.click()
            window.URL.revokeObjectURL(url)

            this.carregandoRelatorio = false
        },
        abriDialogDefault() {
            this.dialogdefaultConfirmaAcao = true
        },
        fecharDialogDefaultConfirmarAcao() {
            this.dialogdefaultConfirmaAcao = false
        }
    }
}
</script>

<style lang="stylus">
.co-relatorios
    background-color var(--background-100)
    padding 0px

</style>