<template>
    <div class="co-contatos-tabela">
        <v-data-table
            class="tabela-signatarios"
            hide-default-footer
            :no-data-text="noDataText"
            :items-per-page="qtdLinhasTabela"
            :headers="headers"
            :items="cadastrosSignatarios">
            <template v-slot:item.contato="{ item }">
                {{ montarContato(item) }}
            </template>

            <template v-slot:item.papel="{ item }">
                {{ converterPapel(item.papelId) }}
            </template>

            <template v-slot:item.acoes="{ item }">
                <v-layout class="acoes-tabela">
                    <v-tooltip top color="black" v-az-auth="'CadastroSignatario.Editar'">
                        <template v-slot:activator="{ on }">
                            <v-icon class="botoes botoes-mobile" v-on="on" size="15px" @click="$emit('edit', item)"> mdi-pencil </v-icon>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>

                    <v-tooltip top color="black" v-az-auth="'CadastroSignatario.Excluir'">
                        <template v-slot:activator="{ on }">
                            <v-icon class="botoes" v-on="on" size="15px" @click="$emit('excluir', item)"> mdi-delete </v-icon>
                        </template>
                        <span>Excluir</span>
                    </v-tooltip>
                </v-layout>
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    name: 'ContatosTabela',
    props: {
        cadastrosSignatarios: {
            type: Array,
            required: true
        },
        papeisSignatario: {
            type: Array
        },
        qtdLinhasTabela: {
            type: Number,
            default: 10
        }
    },
    data() {
        return {
            headers: [
                {
                    text: 'Nome',
                    align: 'left',
                    sortable: false,
                    filterable: true,
                    value: 'nome',
                    divider: true
                },
                {
                    text: 'E-mail/Telefone',
                    align: 'left',
                    sortable: false,
                    filterable: true,
                    value: 'contato',
                    width: '30%',
                    divider: true
                },
                {
                    text: 'Papel',
                    align: 'left',
                    sortable: false,
                    filterable: true,
                    value: 'papel',
                    width: '15%',
                    divider: true
                },
                {
                    text: 'Ações',
                    align: 'center',
                    value: 'acoes',
                    sortable: false,
                    filterable: false,
                    width: '70px',
                    divider: true
                }
            ]
        }
    },
    computed: {
        noDataText() {
            return 'Nenhum ' + this.$tc("pt_br.signatario").toLowerCase() + ' encontrado.'
        }
    },
    methods: {
        ehEmailVazio(email) {
            return email === undefined || email === null || email === ''
        },
        formatarTelefone(ddi, telefone) {
            return `+${ddi} (${telefone.substring(0, 2)}) ${telefone.substring(2)}`
        },
        converterPapel(papelId) {
            try {
                return this.papeisSignatario.find(papel => papel.value === papelId).text
            } catch (e) {
                return papelId
            }
        },
        montarContato(item) {
            if (item.tipoAssinatura === 'WHATSAPP') {
                let ddd = item.telefone.substring(0, 2)
                let telefone = item.telefone.substring(2)
                return '+' + item.ddi + " (" + ddd + ") " + telefone
            } else {
                return item.email
            }
        },
    }
}
</script>

<style lang="stylus">
.co-contatos-tabela
    width 100%

    .v-input--selection-controls.v-input .v-label
        font-size 16px

    .tabela-signatarios
        color var(--text-color-300)

        .acoes-tabela
            justify-content space-around

            .botoes
                color var(--text-color-200)

        thead th
            border-bottom thin solid rgba(0,0,0,.12)

        tbody td
            height 40px !important
            font-size 13px !important
            border-bottom thin solid rgba(0,0,0,.12)

@media (max-width: 720px)
    .co-contatos-tabela
        .tabela-signatarios
            .acoes-tabela
                justify-content right

                .botoes-mobile
                    margin-right 15px

            thead
                display none !important

            tbody tr
                border-bottom 1px solid var(--v-primary-lighten1) !important

                td
                    display block !important
                    text-align left !important
                    padding 5px 10px !important
                    max-height unset !important
                    height unset !important
                    justify-items left
</style>