<template>
    <v-dialog v-model="dialog" width="500" persistent>
        <v-card>
            <v-card-title class="title text-color-300--text">
                {{ this.editar ? 'Editar Pasta' : 'Nova Pasta' }}
                <v-spacer/>
                <v-icon @click="fechar"> mdi-window-close </v-icon>
            </v-card-title>

            <v-card-text>
                <v-text-field
                    placeholder="Nome da pasta"
                    name="Nome da pasta"
                    autocomplete="off"
                    v-validate="'required'"
                    v-model="pastaNome"
                    persistent-placeholder
                    @keydown.enter="salvar"
                    :error-messages="errors.collect('Nome da pasta')"
                    autofocus
                />
            </v-card-text>

            <v-card-actions class="pb-5">
                <v-btn color="background-200" depressed @click="fechar">Cancelar</v-btn>
                <v-spacer/>
                <v-btn color="primary" @click="salvar" :loading="loading">Salvar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: 'DialogPasta',
    props: {
        dialog: {
            type: Boolean,
            default: false
        },
        pastaEditar: {
            type: Object
        },
        pastaPai: {
            type: Object
        },
        pastaPaiId: {
            type: Number,
        },
        editar: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            pastaNome: '',
            loading: false
        }
    },
    mounted() {
        this.pastaNome = this.pastaEditar ? this.pastaEditar.pastaNome : ''
    },
    computed: {
        mountPastaInput() {
            return {
                pastaId: this.pastaEditar ? this.pastaEditar.pastaId : '',
                pastaNome: this.pastaNome,
                pastaPaiId: this.pastaPai ? this.pastaPai.pastaId :  this.pastaPaiId
            }
        },
    },
    methods: {
        fechar() {
            this.$emit('fechar')
        },
        async inserir() {
            let pastaNova = null
            if (!!this.pastaPai){
                pastaNova = await this.adicionarPastaNova(this.mountPastaInput, this.pastaPai)
            } else {
                pastaNova = await this.adicionarPastaNovaSemPastaPai(this.mountPastaInput, this.pastaPaiId)
            }
            this.$emit('pasta-nova', pastaNova)
        },
        async renomear() {
            await this.atualizarPasta(this.mountPastaInput, this.pastaEditar, this.pastaPai)
        },
        async salvar() {
            const valido = await this.$validator._base.validateAll()
            if (!valido) {
                return
            }
            try {
                this.loading = true
                this.habilitarLoadingGlobal()
                if (this.editar) {
                    await this.renomear()
                } else {
                    await this.inserir()
                }
                this.mostrarNotificacaoSucessoDefault()
                this.fechar()
            } catch (e) {
                if (e.response.data.exception.includes('PastaComNomeDuplicadoException')) {
                    this.mostrarNotificacaoErro('Já existe uma pasta ou sub-pasta com o nome informado. Escolha outro nome ou renomeie a já existente.')
                } else {
                    throw e
                }
            } finally {
                this.loading = false
                this.desabilitarLoadingGlobal()
            }
        }
    }
}
</script>