<template>
    <div class="co-lista-grupos-selecionaveis">
        <v-autocomplete
            flat
            chips deletable-chips
            multiple small-chips
            persistent-placeholder
            placeholder="Selecione"
            v-model="value.grupos"
            :items="grupos"
            label="Grupos"
            :menu-props="{offsetY: false, maxWidth:436}">

            <template v-slot:prepend-item>
                <v-list>
                    <v-list-item>
                        <v-list-item-title @mousedown.prevent @click="selecionarTodosClick" class="co-lista-grupos-selecionaveis__selecionar-todos">
                            <v-list-item-action>
                                <v-icon :color="value.grupos.length > 0 ? 'primary' : ''">
                                    {{ icon }}
                                </v-icon>
                            </v-list-item-action>
                            Selecionar Todos
                        </v-list-item-title>
                    </v-list-item>
                </v-list>

                <v-divider class="mt-2"></v-divider>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
export default {
    name: 'ListaGruposSelecionaveis',
    props: {
        value: {
            required: true
        },
        campoRequerido: {
            type: Boolean,
            default: false
        },
        grupos: {
            type: Array
        }
    },
    computed: {
        algunsSelecionados() {
            return this.value.grupos.length > 0 && !this.todosSelecionados
        },
        icon() {
            if (this.todosSelecionados) {
                return 'mdi-checkbox-marked'
            } else if (this.algunsSelecionados) {
                return 'mdi-minus-box'
            } else {
                return 'mdi-checkbox-blank-outline'
            }
        },
        todosSelecionados() {
            return this.value.grupos.length === this.grupos.length
        },
    },
    methods: {
        validarCampo() {
            return this.campoRequerido ? 'required' : ''
        },
        selecionarTodosClick() {
            this.$nextTick(() => {
                if (this.todosSelecionados) {
                    this.value.grupos = []
                } else {
                    this.value.grupos = []
                    this.grupos.forEach(grupo => {
                        this.value.grupos.push(grupo.value)
                    })
                }
            })
        }
    }
}
</script>

<style lang="stylus">
.co-lista-grupos-selecionaveis
    .hint-checkbox
        font-size 12px
        color #aaa
        margin-bottom 10px

    .v-chip--small .v-icon
        margin-top -3px;

    .v-select__selections
        max-height 150px
        overflow-y auto

    &__selecionar-todos
        .v-list__tile__action
            max-width 56px

</style>