import router from '@/router'

export default class RouterUtils {
    static voltarParaMeusDocumentos(formalizacao) {
        switch (formalizacao) {
            case 'ASSINADOS':
                router.push({name: 'assinados'})
                break
            case 'PARCIAL_ASSINADOS':
                router.push({name: 'parcialmenteAssinados'})
                break
            case 'FORMALIZADO':
                router.push({name: 'formalizados'})
                break
            case 'NAO_FORMALIZADO':
                router.push({name: 'naoFormalizados'})
                break
            case 'CANCELADO':
                router.push({name: 'cancelados'})
                break
            case 'ARQUIVADOS':
                router.push({name: 'arquivados'})
                break
            case 'EXTINTOS':
                router.push({name: 'extintos'})
                break
            case 'MEUS':
            default:
                router.push({name: 'meus'})
        }
    }
}