<template>
    <div class="co-sugestoes">
        <iframe src="https://azcronos.com.br/sugestoes/?categoria=9" loading="eager" class="iframe" id="iframe"/>
        <basic-loading v-if="loading"/>
    </div>
</template>

<script>
import BasicLoading from '@/commons/components/BasicLoading'

export default {
    name: 'Sugestoes',
    components: {BasicLoading},
    data() {
        return {
            loading: true
        }
    },
    mounted() {
        this.$store.state.loki.page.title = 'Sugestões'
        const iframeEle = document.getElementById('iframe');

        iframeEle.addEventListener('load', this.pararLoading);
    },
    methods: {
        pararLoading() {
            this.loading = false
        }
    }
}
</script>

<style lang="stylus">
.co-sugestoes
    background-color var(--background-100) !important
    padding 0
    width 100%
    height calc(100% - 36px)

    .iframe
        border none
        width 100%
        height 100%
        padding 0
        margin 0
</style>