<template>
    <div class="co-itens-aba">
        <funcionalidade-exclusiva :eh-sem-funcionalidade="ehSemFuncionalidade"/>

        <v-divider/>

        <nenhum-item-encontrado
            v-if="!possuiItens && !cadastrandoItens"
            :eh-sem-funcionalidade="ehSemFuncionalidade"
            @cadastrar-item="abrirCadastrarItem"
        />

        <cabecalho-itens
            v-if="possuiItens || cadastrandoItens"
            :cadastrando-itens="cadastrandoItens"
            :quantidade="contarQuantidadeItens"
            :valor-total="valorTotal"
            :eh-sem-funcionalidade="ehSemFuncionalidade"
            @cadastrar=abrirCadastrarItem
        />

        <formulario-item
            v-if="cadastrandoItens"
            :item="item"
            @cancelar="cancelar"
            @inserir="salvarItem"
        />

        <lista-itens
            v-if="possuiItens && !cadastrandoItens"
            :itens="itens"
            :eh-sem-funcionalidade="ehSemFuncionalidade"
            @editar="editarItem"
            @excluir="abrirDialogExclusao"
        />

        <dialog-default-confirmar-acao
            v-if="dialogConfirmacaoExclusao"
            :dialog="dialogConfirmacaoExclusao"
            sentimento="assustado"
            titulo="Eita! Deixa eu confirmar uma coisa..."
            subtitulo="Você quer mesmo excluir o item ?"
            label-botao-principal="Sim, eu quero excluir!"
            @acao-principal="removerItem"
            @fechar="fecharDialogExclusao"
        />
    </div>
</template>

<script>
import NenhumItemEncontrado from './components/NenhumItemEncontrado'
import ListaItens from './components/lista-itens/ListaItens'
import FormularioItem from './components/formulario-itens/FormularioItem'
import CabecalhoItens from './components/CabecalhoItens'
import {actionTypes} from '../../../../commons/constants'
import {mapState} from 'vuex'
import DialogDefaultConfirmarAcao from '@/commons/components/dialogs/DialogDefaultConfirmarAcao'
import FuncionalidadeExclusiva from '../components/FuncionanlidadeExclusiva'

export default {
    name: 'Itens',
    components: {
        FuncionalidadeExclusiva,
        DialogDefaultConfirmarAcao,
        CabecalhoItens,
        FormularioItem,
        ListaItens,
        NenhumItemEncontrado
    },
    data() {
        return {
            cadastrandoItens: false,
            dialogConfirmacaoExclusao: false,
            itens: [],
            item: {},
            itemIndex: null,
            itemRemocao: {},
            modoEdicao: false
        }
    },
    async mounted() {
        await this.buscarItens()
    },
    watch: {
        'minutaAtual': function versaoMinutaAlterada() {
            this.buscarItens()
        }
    },
    computed: {
        ...mapState(['login', 'minutaAtual']),
        contarQuantidadeItens() {
            return this.itens.length
        },
        ehSemFuncionalidade() {
            return !this.login.funcionalidadesOrganizacao.itemMinuta
        },
        possuiDescricaoDuplicada() {
            const descricaoDuplicada = this.itens.filter(item => item.descricao === this.item.descricao && item.id !== this.item.id)
            return descricaoDuplicada.length > 0
        },
        possuiItens() {
            return this.itens.length > 0
        },
        valorTotal() {
            return this.itens.reduce((acumalador, atual) => acumalador += atual.valorTotal, 0).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
            })
        }
    },
    methods: {
        async atualizaItem(item) {
            try {
                const itemTratado = this.tratarItem(item)
                const itemAtualizado = await this.$store.dispatch(actionTypes.ITEM.ATUALIZAR_ITEM_MINUTA, itemTratado)
                this.itens.splice(this.itemIndex, 1, itemAtualizado)
                this.minutaAtual.valorTotalItemMinuta = this.valorTotalItens()
                this.mostrarNotificacaoSucessoDefault()
                this.cancelar()
            } catch (e) {
                return e
            }
        },
        abrirCadastrarItem() {
            this.cadastrandoItens = true
        },
        abrirDialogExclusao(item) {
            this.itemRemocao = item
            this.dialogConfirmacaoExclusao = true
        },
        async buscarItens() {
            this.itens = await this.$store.dispatch(actionTypes.ITEM.BUSCAR_ITENS_MINUTA)
        },
        cancelar() {
            this.fecharCadastrarItem()
            this.modoEdicao = false
            this.resetarValorItem()
        },
        editarItem(item) {
            this.modoEdicao = true
            this.cadastrandoItens = true
            this.item = Object.assign({}, item);
            this.itemIndex = this.itens.indexOf(item)
        },
        fecharCadastrarItem() {
            this.cadastrandoItens = false
        },
        fecharDialogExclusao() {
            this.dialogConfirmacaoExclusao = false
        },
        async inserirItem(item) {
            const itemTratado = this.tratarItem(item)
            const novoItem = await this.$store.dispatch(actionTypes.ITEM.INSERIR_ITEM_MINUTA, itemTratado)
            this.itens.push(novoItem)
            this.minutaAtual.valorTotalItemMinuta = this.valorTotalItens()
            this.mostrarNotificacaoSucessoDefault()
            this.cancelar()
        },
        async removerItem() {
            try {
                await this.$store.dispatch(actionTypes.ITEM.REMOVER_ITEM_MINUTA, this.itemRemocao.id)
                this.itens.splice(this.itens.indexOf(this.itemRemocao), 1)
                this.minutaAtual.valorTotalItemMinuta = this.valorTotalItens()
                this.itemRemocao = {}
                this.fecharDialogExclusao()
                this.mostrarNotificacaoSucessoDefault()
            } catch (e) {
                return e
            }
        },
        resetarValorItem() {
            this.item = {
                descricao: '',
                quantidade: '',
                valorUnitario: '',
                valorTotal: ''
            }
        },
        async salvarItem(item) {
            let formularioObrigatorioValido = false
            formularioObrigatorioValido = await this.$validator._base.validateAll()

            if (!formularioObrigatorioValido) {
                return
            }

            if (!Number.isInteger(Number(item.quantidade)) || Number(item.quantidade) <= 0) {
                this.mostrarNotificacaoErro('Quantidade deve ser um número inteiro positivo.')
                return
            }

            if (Number(item.valor) <= 0) {
                this.mostrarNotificacaoErro('Valor deve ser um número positivo.')
                return
            }

            if (this.possuiDescricaoDuplicada) {
                this.mostrarNotificacaoErro('Já existe um item com esse nome informado.')
                return
            }

            if (item.id === undefined) {
                await this.inserirItem(item)
            } else {
                await this.atualizaItem(item)
            }
        },
        tratarItem(item) {
            let itemTratado = Object.assign({}, item)
            itemTratado.valor = item.valor.toString().replace(/,/g, '.')
            return itemTratado
        },
        valorTotalItens() {
            return this.itens.reduce((acumalador, atual) => acumalador += atual.valorTotal, 0)
        }
    }
}
</script>

<style lang="stylus">
.co-itens-aba
    display block
    text-align center
    overflow-x hidden
    overflow-y auto
    height: 100%

    &__acoes
        background-color green
        color white !important
        border-radius 20px
        font-size 15px

        i
            margin-right 5px
</style>