<template>
    <div class="co-representante-legal">

        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <div v-on="on">
                    <v-switch
                        :disabled="desabilitarCampo"
                        class="elevation-0 pl-1"
                        color="#153c5c"
                        hide-details
                        label="Representante Legal"
                        v-model="value.representanteLegal"
                    />
                </div>
            </template>
            <span>Representante legal</span>
        </v-tooltip>

        <div class="hint-checkbox">
            {{ descricaoCampo }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'RepresentanteLegal',
    props: {
        value: {
            required: true
        },
        campoRequerido: {
            type: Boolean,
            default: false
        },
        desabilitarCampo: {
            type: Boolean
        }
    },
    data() {
        return {
            descricaoCampo: 'Este signatario será considerado o representate legal, por sua vez, pode outorgar poderes para um terceiro atuar em nome da empresa, por meio de uma procuração.'
        }
    },
    methods: {
        validarCampo() {
            return this.campoRequerido ? 'required' : ''
        }
    }
}
</script>

<style lang="stylus">
.co-representante-legal
    padding-bottom 8px

    .v-input--selection-controls
        margin-top 0 !important
        padding-top 0 !important
</style>