import {alertasDefault, mutationTypes} from '@/commons/constants'

export default {
    methods: {
        mostrarNotificacaoErro(message) {
            this.$store.commit(mutationTypes.LOKI.SHOW_ALERT, {
                message,
                type: 'error'
            })
        },
        mostrarNotificacaoErroDefault() {
            this.$store.commit(mutationTypes.LOKI.SHOW_ALERT, {
                message: alertasDefault.ERRO_DEFAULT,
                type: 'error',
                styleButtomClose: 'position: absolute; right: 0; top:7px',
            })
        },
        mostrarNotificacaoSucessoDefault() {
            this.$store.commit(mutationTypes.LOKI.SHOW_ALERT, {
                message: alertasDefault.SUCESSO_DEFAULT,
                type: 'success',
                styleButtomClose: 'position: absolute; right: 0; top:7px'
            })
        },
        mostrarNotificacaoSucesso(text) {
            this.$store.commit(mutationTypes.LOKI.SHOW_ALERT, {
                message: text,
                type: 'success'
            })
        }
    }
}
