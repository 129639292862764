<template>
    <div>
        <v-row no-gutters>
            <v-col cols="6" sm="8" class="pt-1">
                <div>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-on="on" style="font-size: 16px; color: #777777">{{ nomePdf }}</span>
                        </template>
                        <span>{{ pdf.name }}</span>
                    </v-tooltip>
                </div>
            </v-col>

            <v-col cols="3" sm="2" class="grey--text pt-1">
                <span>{{ sizePdf }}</span>
            </v-col>

            <v-col cols="3" sm="2" class="grey--text pt-1">
                <div>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon style="cursor: pointer; margin: 0px 5px" v-on="on" @click="visualizar"> mdi-eye-outline </v-icon>
                        </template>
                        <span>Visualizar</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon style="cursor: pointer; margin: 0px 5px" v-on="on" @click="excluir"> mdi-delete </v-icon>
                        </template>
                        <span>Deletar</span>
                    </v-tooltip>
                </div>
            </v-col>

        </v-row>
    </div>

</template>

<script>
export default {
    name: 'ItemPdf',
    props: ['pdf'],
    computed: {
        nomePdf() {
            if (this.$vuetify.breakpoint.xs && this.pdf.name.length > 20) {
                return this.pdf.name.slice(0, 20) + '...'
            } else if (this.$vuetify.breakpoint.sm && this.pdf.name.length > 45) {
                return this.pdf.name.slice(0, 45) + '...'
            } else if (this.$vuetify.breakpoint.md && this.pdf.name.length > 70) {
                return this.pdf.name.slice(0, 70) + '...'
            } else {
                return this.pdf.name
            }

        },
        sizePdf() {
            return this.formatBytes(this.pdf.size)
        }
    },
    methods: {
        visualizar() {
            this.$emit("visualizar", this.pdf)
        },
        excluir() {
            this.$emit("excluir", this.pdf)
        },
        formatBytes(bytes, decimals = 2) {
            if (bytes === 0) return '0 Bytes'

            const kb = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

            const i = Math.floor(Math.log(bytes) / Math.log(kb))
            return parseFloat((bytes / Math.pow(kb, i)).toFixed(dm)) + ' ' + sizes[i]
        }
    }
}
</script>