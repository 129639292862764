<template>
    <div class="co-configuracao-titulo">
        <v-row no-gutters class="mt-5 justify-space-between title-line">
            <div class="title">
                {{ titulo }}
            </div>

            <slot name="actions"/>
        </v-row>

        <v-divider v-if="!dividerOff"/>

        <v-row no-gutters v-if="ehSubtituloPreenchido">
            <div class="subtitle-2">
                <span> {{ subtitulo }} </span>
                <br v-if="ehSubtitulo2Preenchido"/>
                <span> {{ subtitulo2 }} </span>
            </div>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'ConfiguracaoTitulo',
    props: {
        titulo: {
            type: String,
            required: true
        },
        subtitulo: {
            type: String,
            default: ''
        },
        subtitulo2: {
            type: String,
            default: ''
        },
        dividerOff: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ehSubtituloPreenchido() {
            return !!this.subtitulo && this.subtitulo !== ''
        },
        ehSubtitulo2Preenchido() {
            return !!this.subtitulo2 && this.subtitulo2 !== ''
        }
    }
}
</script>

<style lang="stylus">
.co-configuracao-titulo
    .title-line
        height 32px

    .title
        color var(--primary)

    .subtitle-2
        color var(--text-color-200)
</style>