<template>
    <v-card class="co-novo-documento-signatario-card-posicionamento-assinatura mt-5 pa-5">
        <v-card-text class="pa-0">
            <v-row no-gutters>
                <v-icon color="secondary-200" size="20"> mdi-email </v-icon>
                <span class="pl-2">{{ ehEmail() ? signatario.email : signatario.telefone }}</span>
            </v-row>

            <v-row no-gutters>
                <span class="py-1 px-2 my-2 signatario-papel">{{ converterPapelId }}</span>
            </v-row>

            <v-divider />
        </v-card-text>

        <v-card-actions class="pa-0">
            <v-row no-gutters>
                <div v-if="ehEditavel(opcaoPosicionamentoDado.value)" v-for="(opcaoPosicionamentoDado, index) in opcoesPosicionamentoDados" :key="index" class="mr-2">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" outlined small class="mt-3" @click="criarPosicionamentoAssinatura(opcaoPosicionamentoDado)" v-bind="attrs" v-on="on">
                                <v-icon left color="primary" >{{ opcaoPosicionamentoDado.icon }}</v-icon>
                                {{ opcaoPosicionamentoDado.tipo }}
                            </v-btn>
                        </template>
                        <span> Para posicionar, clique em {{ opcaoPosicionamentoDado.tipo }} e depois no documento.
                        <br/>
                        Caso deseje alterar o posicionamento segure {{ opcaoPosicionamentoDado.tipo }} para arrastar até o ponto desejado.
                    </span>
                    </v-tooltip>
                </div>
            </v-row>

        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'CardPosicionamentoAssinatura',
    props: {
        papeisSignatario: {
            type: Array,
            required: true
        },
        signatario: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            opcoesPosicionamentoDados: [
                {
                    tipo: 'Assinatura', value: 'RUBRICA', icon: 'mdi-gesture'
                },
                {
                    tipo: 'Rubrica', value: 'DADO_EXTRA', icon: 'mdi-pencil'
                },
                {
                    tipo: 'Documento', value: 'CPF', icon: 'mdi-badge-account-horizontal'
                },
                {
                    tipo: 'Email', value: 'EMAIL', icon: 'mdi-email'
                },
                {
                    tipo: 'Nome', value: 'NOME_SIGNATARIO', icon: 'mdi-account'
                },
                {
                    tipo: 'Data Nascimento', value: 'DATA_NASCIMENTO', icon: 'mdi-calendar-month'
                }
            ]
        }
    },
    computed: {
        converterPapelId() {
            return this.papeisSignatario.find(papel => papel.value === this.signatario.papelId).text
        }
    },
    methods: {
        criarPosicionamentoAssinatura(opcaoPosicionamentoDado) {
            this.$emit('criar-posicionamento-assinatura', {...this.signatario,  tipoPosicionamento: opcaoPosicionamentoDado})
        },
        ehEditavel(opcaoPosicionamentoDadoValue) {
            return this.ehEletronica() || (this.ehWhatsapp() && this.ehOpcoesWhats(opcaoPosicionamentoDadoValue)) || (this.ehDigital() && this.ehOpcoesDigital(opcaoPosicionamentoDadoValue))
        },
        ehEmail() {
            return !this.ehWhatsapp()
        },
        ehEletronica() {
            return  this.signatario.tipoAssinatura === 'ELETRONICA'
        },
        ehDigital() {
            return this.signatario.tipoAssinatura === 'DIGITAL';
        },
        ehWhatsapp() {
            return this.signatario.tipoAssinatura === 'WHATSAPP'
        },
        ehOpcoesWhats(opcaoPosicionamentoDadoValue) {
            return opcaoPosicionamentoDadoValue === 'DATA_NASCIMENTO' || opcaoPosicionamentoDadoValue === 'CPF' || opcaoPosicionamentoDadoValue === 'NOME_SIGNATARIO'
        },
        ehOpcoesDigital(opcaoPosicionamentoDadoValue) {
            return !(opcaoPosicionamentoDadoValue === 'DATA_NASCIMENTO')
        },
    }
}
</script>

<style lang="stylus">
.co-novo-documento-signatario-card-posicionamento-assinatura
    .signatario-papel
        background var(--background-300)
        font-size 11px
        border-radius 5px

</style>