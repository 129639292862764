<template>
    <div class="co-botoes-signatario">
        <v-btn color="white lighten-2 grey--text" depressed @click.once="cancelar">
            Cancelar
        </v-btn>
        <v-btn color="green lighten-2 white--text" depressed @click="salvar" :disabled="!habilitarSalvar">
            Salvar
        </v-btn>
    </div>
</template>

<script>
export default {
    name: 'BotoesSignatario',
    methods: {
        cancelar() {
            this.$emit('cancelar')
        },
        salvar() {
            setTimeout(() => {
                this.$emit('salvar')
            },)
        }
    },
    props: {
        habilitarSalvar: {
            type: Boolean
        }
    }
}
</script>

<style lang="stylus">
.co-botoes-signatario
    display flex
    justify-content center
    padding-top 10px
    margin-top 5px
    margin-bottom 20px
</style>