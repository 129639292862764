import axios from 'axios'
import {actionTypes, mutationTypes} from '@/commons/constants'

export default {
    async [actionTypes.LOGIN.BUSCAR_PARAMETROS_SISTEMA]({commit}) {
        const parametrosCLM = await axios.get('api/public/parametrosSistema')
        commit(mutationTypes.SET_PARAMETROS_SISTEMA, parametrosCLM.data)
    },

    async [actionTypes.LOGIN.BUSCAR_VINCULO_USUARIO_ORGANIZACAO]({commit, state}) {
        const organizacaoId = state.login.organizacaoPlano.organizacaoId
        const usuarioId = state.loki.user.id
        const {data} = await axios.get(`api/organizacoes/${organizacaoId}/usuarios/${usuarioId}`)
        commit(mutationTypes.LOGIN.SET_VINCULO_USUARIO_ORGANIZACAO, data)
    },

    async [actionTypes.LOGIN.BUSCAR_USUARIO_LOGADO]({commit, state}) {
        const produtoId = state.loki.product.id
        const {data} = await axios.get(`hal/usuario/sessao?produtoId=${produtoId}`)
        commit(mutationTypes.LOGIN.SET_USUARIO_LOGADO, data)
    },

    async [actionTypes.LOGIN.BUSCAR_ORGANIZACAO_PLANO]({commit}) {
        const {data} = await axios.get('api/configuracoes/organizacoes/organizacao-plano')
        commit(mutationTypes.LOGIN.SET_ORGANIZACAO_PLANO, data)
    },

    async [actionTypes.LOGIN.BUSCAR_PRODUTO_POR_NOME]({commit}, packageJson) {
        const {data} = await axios.get(`hal/public/produtos?produtoNome=efcaz-clm`)
        commit(mutationTypes.SET_PRODUTO, {data, packageJson})
    },

    async [actionTypes.LOGIN.BUSCAR_FUNCIONALIDADES_ORGANIZACAO]({commit, state}) {
        const organizacaoId = state.login.organizacaoPlano.organizacaoId
        const {data} = await axios.get(`api/configuracoes/organizacoes/${organizacaoId}/funcionalidades`)
        commit(mutationTypes.LOGIN.SET_FUNCIONALIDADES_ORGANIZACAO, data)
    },
}