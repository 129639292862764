<template>
    <div class="co-filtro-filtro-avancado">
        <v-btn
            :color="ehfiltrosSelecionados ? 'green' : 'primary'"
            dark
            @click.stop="abrirFiltroAvancado">
            <v-icon> mdi-chevron-left </v-icon>
        </v-btn>

        <v-navigation-drawer
            class="menu-filtro"
            v-model="filtroAvancado"
            app
            right
            v-bind:fixed="ehMobile"
            :width="ehMobile ? '100%' : '375px'"
            temporary>

            <v-toolbar
                class="titulo"
                dark
                color="primary">
                <v-toolbar-title>Filtros de {{ $tc('pt_br.documento').toLowerCase() }}</v-toolbar-title>
                <v-spacer/>
                <v-toolbar-items>
                    <v-btn
                        dark
                        text
                        @click="fecharFiltroAvancado">
                        <v-icon> mdi-window-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <filtros-components v-model="value"/>

        </v-navigation-drawer>
    </div>
</template>

<script>
import FiltrosComponents from './filtro-components/FiltrosComponents'

export default {
    name: "FiltroAvancado",
    components: {FiltrosComponents},
    props: {
        value: {
            required: true
        },
    },
    data() {
        return {
            filtroAvancado: false,
        }
    },
    computed: {
        ehfiltrosSelecionados() {
            const cor = this.value.cor.value !== ''
            const tags = this.value.tags.value !== ''
            const dataCriacaoInicio = this.value.dataCriacaoInicio.value !== ''
            const dataCriacaoFim = this.value.dataCriacaoFim.value !== ''
            return cor || tags || dataCriacaoInicio || dataCriacaoFim
        },
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        }
    },
    methods: {
        abrirFiltroAvancado() {
            this.filtroAvancado = true
        },
        fecharFiltroAvancado() {
            this.filtroAvancado = false
        }
    }
}
</script>

<style lang="stylus">
.co-filtro-filtro-avancado
    flex none
    margin 0
    align-self end

    .titulo
        .v-toolbar__content
            padding 4px 0 4px 16px

</style>