<template>
    <v-dialog v-model="dialog" width="600" persistent>
        <v-card class="co-banner-promocao text-center">
            <v-card-title class="titulo justify-center">
                <span>BLACK NOVEMBER - 30% OFF EM PLANOS ANUAIS</span>
            </v-card-title>

            <v-divider class="mx-5"/>

            <v-card-text class="mt-2">
                <p class="text-left">
                    Chegou a oportunidade que faltava para tornar mais eficiente o seu processo de assinatura com um custo que você dificilmente vai ver novamente.
                </p>

                <p class="text-left">
                    Até o dia 24/11 todos os PLANOS ANUAIS da plataforma estão com um super desconto, aproveite!
                </p>

                <p class="text-left">
                    Receba 30% em pagamento único e 25% de desconto em pagamentos parcelados (4x).
                </p>

                <p class="text-left">
                    Clique agora  “Quero o desconto” ou vá até o menu “Planos” e não perca a oportunidade de economizar.
                </p>

                <v-alert v-if="personalizado" dense text type="info" color="primary lighten-1" class="mt-12 mb-0">
                    <v-row align="center" no-gutters>
                        <v-col class="grow" style="font-size: 12px">
                            Quero um plano personalizado
                        </v-col>

                        <v-col class="shrink">
                            <v-btn color="green" outlined x-small @click="chamarWhats">
                                <v-icon small class="mr-2">mdi-whatsapp</v-icon>

                                FALAR COM VENDAS
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-text>

            <v-card-actions class="acoes justify-space-between">
                <div class="secundaria">
                    <v-btn  class="botao" depressed @click="fechar">SAIR</v-btn>
                </div>

                <div class="principal">
                    <v-btn class="botao" color="black" dark @click="abrirLoja">QUERO DESCONTO</v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import {mapState} from 'vuex'
import CookiesUtils from '@/commons/utils/CookiesUtils'

export default {
    name: 'BannerPromocao',
    props: {
        dialog: {
            type: Boolean
        },
        personalizado: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState(['login', 'cookies', 'contatoComercial']),
    },
    methods: {
        abrirLoja() {
            this.fechar()
            this.$router.push({name: 'planos'})
        },
        async chamarWhats() {
            let emailcadastradosistema = this.login.usuarioOrganizacao.usuarioEmail
            let nomeUsuario = this.login.usuarioOrganizacao.usuarioNome.replace(' ', '%20%20')
            window.open(`https://api.whatsapp.com/send?phone=${this.contatoComercial.telefone}&text=Ol%C3%A1,%20sou%20${nomeUsuario},%20%20${emailcadastradosistema}%20e%20acessei%20a%20Besign%20e%20vi%20a%20promoção%20da%20black%20november.%20Gostaria%20de%20um%20plano%20personalizado`, '_blank')
        },
        fechar() {
            this.login.usuarioOrganizacao.ultimoAcesso = new Date();
            this.salvarNosCookies()
            this.$emit('fechar')
        },
        salvarNosCookies() {
            this.cookies.promoCloseCount += 1
            CookiesUtils.atualizarCookies(this.cookies)
        },
    }
}
</script>

<style lang="stylus">
@media (max-width: 768px)
    .co-banner-promocao
        .acoes
            flex-direction column
            gap 8px
            width 100%

            .principal
            .secundaria
                width 100%

                .botao
                    width 100%
</style>
