<template>
    <div class="co-loading d-flex align-center justify-center">
        <v-progress-circular
            indeterminate
            color="blue-grey"
            :size="200"
            :width="10"
        />
    </div>
</template>

<script>

export default {
    name: 'BasicLoading',
    components: {},
}
</script>

<style lang="stylus">
.co-loading
    background-color rgba(0,0,0,0.2)
    opacity 0.2
    position absolute
    top 0
    left 0
    height 100%
    width 100%


</style>