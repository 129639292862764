<template>
    <v-container grid-list px-10 pt-10 pb-4 class="co-compras">

        <div class="d-flex justify-space-between align-center wrapper-topo">
            <div class="pendentes-tooltip">
                <configuracao-titulo :titulo="tipoSelecionado" divider-off/>

                <v-tooltip v-if="tipoSelecionado === 'Compras'" right>
                    <template v-slot:activator="{ on }">
                        <span v-on="on">
                            <v-icon color="primary" size="16px"> mdi-information </v-icon>
                        </span>
                    </template>
                    <span>{{ quantidadePendentes }} pagamentos pendentes</span>
                </v-tooltip>
            </div>

            <div class="botoes-wrapper d-flex">
                <div class="d-flex status-btns">
                    <button v-for="(tipos) in tiposDisponiveis"
                    :class="ehComprasFiltro(tipos) ? 'active' : ''"
                    @click="selecionarTipo(tipos)"
                    :key="tipos">
                        {{ tipos }}
                    </button>
                </div>
    
                <div class="d-flex status-btns">
                    <button v-for="(status) in statusDisponiveis"
                    :class="ehTodosFiltro(status) || filtros.status.value === status ? 'active' : ''"
                    @click="selecionarStatus(status)"
                    :disabled="ehTodosFiltro(status) || status === filtros.status.value"
                    :key="status">
                        {{ status }}
                    </button>
                </div>
            </div>

        </div>

        <v-row no-gutters class="justify-space-between mx-3 mt-5">
            <compras-tabela
                :items-per-page="paginacao.rowsPerPage"
                :itens="historico"
                :tipoSelecionado="tipoSelecionado"
            />
        </v-row>

        <v-row no-gutters class="justify-space-between mt-3 mx-3">
            <v-flex class="footer">
                <v-pagination
                    dense
                    class="paginas justify-center elevation-0"
                    v-model="paginacao.page"
                    :length="paginacao.numPages"
                    :total-visible="7"
                    @input="mudarPagina"/>

                <v-spacer v-if="!ehMobile" />

                <v-select
                    v-if="!ehMobile"
                    class="linhas-por-pagina"
                    v-model="paginacao.rowsPerPage"
                    :items="quantidadeLinhas"
                    label="Itens por pagina"
                    hide-details
                    @change="mudarQuantidadePorPagina"/>
            </v-flex>
        </v-row>
    </v-container>
</template>

<script>
import {actionTypes} from '@/commons/constants'
import ComprasTabela from './ComprasTabela'
import ConfiguracaoTitulo from '../common/ConfiguracaoTitulo'

export default {
    name: 'Compras',
    components: {
        ConfiguracaoTitulo,
        ComprasTabela
    },
    data() {
        return {
            quantidadePendentes: 0,
            quantidadeLinhas: [5, 10, 15, 25, 50],
            ultimaPaginaBuscada: 1,
            historico: [],
            paginacao: {
                page: 1,
                numPages: 0,
                rowsPerPage: 10,
                descending: true,
                sortBy: 'dataCadastro'
            },
            tipoSelecionado: 'Compras',
            filtros: {
                status: {value: null, default: null}
            },
            tiposDisponiveis: ['Compras', 'Cobranças de Assinaturas'],
            statusDisponiveis: ['Todos', 'Pago', 'Pendente', 'Cancelado', 'Expirado']
        }
    },
    async created() {
       await this.buscarHistoricoCompras()
    },
    computed: {
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        }
    },
    methods: {
        async buscarHistoricoCompras() {
            const compraPaginado = await this.$store.dispatch(actionTypes.COMPRAS.HISTORICO_ADICIONAIS_ORGANIZACAO, {filtros: this.filtros, paginacao: this.paginacao})

            this.historico = compraPaginado.itens.content

            this.paginacao.numPages = compraPaginado.itens.totalPages
            this.ultimaPaginaBuscada = this.paginacao.page

            this.quantidadePendentes = compraPaginado.quantidadePendente
        },
        async buscarHistoricoCobrancas() {
            const cobrancasPaginado = await this.$store.dispatch(actionTypes.COBRANCAS.HISTORICO_ORGANIZACAO, {filtros: this.filtros, paginacao: this.paginacao})

            this.historico = cobrancasPaginado.itens.content

            this.paginacao.numPages = cobrancasPaginado.itens.totalPages
            this.ultimaPaginaBuscada = this.paginacao.page
        },
        ehTodosFiltro(status) {
            return this.filtros.status.value === null && status === 'Todos'
        },
        ehComprasFiltro(tipo) {
            return this.tipoSelecionado === tipo
        },
        async mudarPagina() {
            if (this.ultimaPaginaBuscada === this.paginacao.page) return

            await this.buscarHistoricos()
        },
        async mudarQuantidadePorPagina() {
            this.paginacao.page = 1

            await this.buscarHistoricos()
        },
        async selecionarTipo(tipo) {
            this.tipoSelecionado = tipo

            if (tipo !== 'Compras') {
               this.statusDisponiveis = ['Todos', 'Pago', 'Pendente', 'Expirado']
            } else {
                this.statusDisponiveis = ['Todos', 'Pago', 'Pendente', 'Cancelado', 'Expirado']
            }

            await this.selecionarStatus('Todos')
            await this.buscarHistoricos()
        },
        async selecionarStatus(status) {
            this.filtros.status.value = status === 'Todos' ? null : status
            this.paginacao.page = 1
            this.paginacao.rowsPerPage = 10

            await this.buscarHistoricos()
        },
        async buscarHistoricos() {
            if (this.tipoSelecionado === "Compras") {
                await this.buscarHistoricoCompras()
            } else {
                await this.buscarHistoricoCobrancas()
            }
        }
    }
}
</script>

<style lang="stylus">
.co-compras
    background-color var(--background-100) !important
    width 100%

    .botoes-wrapper
        flex-direction column
        gap 10px

    .titulo
        height 44px

    .title
        color var(--primary)

    .pendentes-tooltip
        display flex
        align-items center
        gap 10px

        .co-configuracao-titulo div
            margin-top 0 !important

    .status-btns
        gap 10px
        button
            font-size 13px
            color var(--text-color-200)
            &.active
                font-weight 600
                color var(--primary)

    .footer
        display flex
        position relative
        align-content space-between

    .paginas
        width 100%
        align-content center

    .linhas-por-pagina
        position absolute
        right 0
        width 100px

@media (max-width: 720px)
    .co-compras
        .wrapper-topo
            width 100%
            margin-right 12px
            margin-left 12px
            flex-direction column
            gap 20px

        .botoes-wrapper
            gap 25px

        .footer
            .linhas-por-pagina
                position unset
</style>