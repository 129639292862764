<template>
    <div class="co-white-label-email-colors">
        <v-row no-gutters class="align-center my-4">
            <configuracao-titulo
                titulo="Email"
                divider-off
            />
        </v-row>

        <v-row class="mt-1 mx-3" no-gutters>
            <v-col class="pr-3" cols="12" md="6">
                <item-selecao-cor
                    v-model="colors.primary"
                    :cor-default="coresDefault.primary"
                    :disabled="disabled"
                    titulo="Cor principal"/>

                <item-selecao-cor
                    v-model="colors.secondary"
                    :cor-default="coresDefault.secondary"
                    :disabled="disabled"
                    titulo="Cor secundária"/>
            </v-col>

            <v-col class="d-flex justify-space-between" cols="12" md="6">
                <email-design :colors="colors" :default-colors="coresDefault" :disabled="disabled" :logo="logo"/>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ConfiguracaoTitulo from '../../../common/ConfiguracaoTitulo'
import ItemSelecaoCor from '../ItemSelecaoCor'
import EmailDesign from './EmailDesign'

export default {
    name: 'WhiteLabelEmailColors',
    components: {EmailDesign, ItemSelecaoCor, ConfiguracaoTitulo},
    props: {
        colors: {
            type: Object,
            required: true
        },
        logo: {
            type: String,
            required: true
        },
        coresDefault: {
            type: Object
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="stylus">
.co-white-label-email-colors

    .title
        color var(--primary)

    .subtitle-2
        color var(--text-color-200)


</style>
