import axios from 'axios'
import {actionTypes, mutationTypes} from '@/commons/constants'
import {AzSearchUrlBuilder} from '@azinformatica/loki'

export default {
    async [actionTypes.PASTAS.ATUALIZAR_PASTA]({state, commit}, pasta) {
        const organizacaoId = state.login.organizacaoPlano.organizacaoId
        const {data} = await axios.put(`api/pastas/${pasta.pastaId}/organizacoes/${organizacaoId}`, pasta)
        return data;
    },

    async [actionTypes.PASTAS.BUSCAR_PASTAS_ACESSO_USUARIO]({state}, pastaId) {
        const organizacaoId = state.login.organizacaoPlano.organizacaoId
        const {data} = await axios.get(`api/pastas/${pastaId}/organizacoes/${organizacaoId}/usuario`)
        return data
    },

    async [actionTypes.PASTAS.BUSCAR_PASTAS_PAGINADAS]({state, commit}, paginacao) {
        const {filtros} = state.meusContratosBusca
        const organizacaoId = state.login.organizacaoPlano.organizacaoId

        filtros.pastaId.value = state.pastaAtual && state.pastaAtual.pastaId ? state.pastaAtual.pastaId : null

        const url = AzSearchUrlBuilder.build(`api/pastas/organizacoes/${organizacaoId}`, filtros, paginacao)
        const {data} = await axios.get(url)
        await commit(mutationTypes.PASTAS.SET_PASTA_ATUAL_TOTAL_ELEMENTS, data.totalElements)
        await commit(mutationTypes.PASTAS.SET_PASTA_ATUAL_FLAG_FILTRAGEM, false)
        return data
    },

    async [actionTypes.PASTAS.BUSCAR_PASTAS_FILTRADAS]({state, commit}, paginacao) {
        const {filtros} = state.meusContratosBusca
        const organizacaoId = state.login.organizacaoPlano.organizacaoId

        filtros.pastaId.value = state.pastaAtual && state.pastaAtual.pastaId ? state.pastaAtual.pastaId : null

        const url = AzSearchUrlBuilder.build(`api/pastas/organizacoes/${organizacaoId}/filtros`, filtros, paginacao)
        const {data} = await axios.get(url)
        await commit(mutationTypes.PASTAS.SET_PASTA_ATUAL_TOTAL_ELEMENTS, data.totalElements)
        await commit(mutationTypes.PASTAS.SET_PASTA_ATUAL_FLAG_FILTRAGEM, true)
        return data;
    },

    async [actionTypes.PASTAS.INSERIR_PASTA]({state}, pasta) {
        const organizacaoId = state.login.organizacaoPlano.organizacaoId
        const {data} = await axios.post(`api/pastas/organizacoes/${organizacaoId}`, pasta)
        return data
    },

    async [actionTypes.PASTAS.MOVER_PASTA]({state}, pasta) {
        const organizacaoId = state.login.organizacaoPlano.organizacaoId
        const {data} = await axios.put(`api/pastas/${pasta.pastaId}/organizacoes/${organizacaoId}`, pasta)
        return data
    },

    async [actionTypes.PASTAS.REMOVER_PASTA]({state}, pasta) {
        const organizacaoId = state.login.organizacaoPlano.organizacaoId
        await axios.delete(`api/pastas/${pasta.pastaId}/organizacoes/${organizacaoId}`)
    },

    async [actionTypes.PASTAS.REMOVER_ACESSO_USUARIO_PASTA]({state}, {pastaId, usuarioId}) {
        const organizacaoId = state.login.organizacaoPlano.organizacaoId
        await axios.delete(`api/pastas/${pastaId}/organizacoes/${organizacaoId}/acesso/usuarios/${usuarioId}`)
    },

    async [actionTypes.PASTAS.ATRIBUIR_ACESSO_USUARIOS_PASTA]({state}, {pastaId, usuarios}) {
        const organizacaoId = state.login.organizacaoPlano.organizacaoId
        const {data} = await axios.post(`api/pastas/${pastaId}/organizacoes/${organizacaoId}/acesso/usuarios`, usuarios)
        return data
    },

    async [actionTypes.PASTAS.BUSCAR_USUARIOS_COM_ACESSO_PASTA]({state}, pastaId) {
        const organizacaoId = state.login.organizacaoPlano.organizacaoId
        const {data} = await axios.get(`api/pastas/${pastaId}/organizacoes/${organizacaoId}/acesso/usuarios`)
        return data
    },
}