<template>
    <div class="co-item-selecao-cor d-flex justify-space-between align-center mb-3">
        <div class="titulo">
            {{ titulo }}
        </div>

        <v-menu offset-y :close-on-content-click="false" content-class="co-item-selecao-cor">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" elevation="0" small class="color-picker" :color="value.color" :disabled="disabled">
                    <span :class="getButtonTextClass()">
                        {{ value.color }}
                    </span>
                </v-btn>
            </template>

            <div class="menu pb-3">
                <v-color-picker
                    v-model="value.color"
                    dot-size="25"
                    hide-mode-switch
                    mode="hexa"
                    swatches-max-height="200"/>

                <div class="d-flex justify-center align-center">
                    <v-btn v-if="ehPossivelResetar" color="primary" @click="reset" small>
                        RESET
                    </v-btn>
                </div>
            </div>
        </v-menu>
    </div>
</template>

<script>
import Utils from '@/commons/utils/Utils'

export default {
    name: 'ItemSelecaoCor',
    props: {
        value: {
            type: Object,
            required: true
        },
        titulo: {
            type: String,
            required: true
        },
        corDefault: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ehPossivelResetar() {
            return Utils.possuiValor(this.corDefault)
        }
    },
    methods: {
        getButtonTextClass() {
            if (this.disabled) {
                return 'black--text'
            }
            let hexColor = this.value.color.replace('#', '')

            let r = parseInt(hexColor.substring(0, 2), 16)
            let g = parseInt(hexColor.substring(2, 4), 16)
            let b = parseInt(hexColor.substring(4, 6), 16)

            let luminosidade = (0.299 * r + 0.587 * g + 0.114 * b) / 255

            return luminosidade < 0.5 ? 'white--text' : 'black--text'
        },
        reset() {
            this.value.color = this.corDefault
        }
    }
}
</script>

<style lang="stylus">
.co-item-selecao-cor

    .menu
        background-color var(--background-100) !important

    .titulo
        color var(--text-color-200)

    .color-picker
        width 120px
        outline 1px solid black
</style>
