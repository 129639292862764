<template>
    <div class="co-contratar-servico" @click="abrirDialogContratarServico()">
        <v-spacer></v-spacer>
        <span class="contratar-servico-texto">Contratar serviço</span>
        <v-icon class="contratar-servico-icon" size="14"> mdi-cart-plus </v-icon>
    </div>
</template>

<script>
export default {
    name: "ContratarServico",
    methods: {
        abrirDialogContratarServico() {
            this.$emit('abrir-dialog-contratar-servico')
        }
    }
}
</script>

<style lang="stylus">
.co-contratar-servico
    display flex
    margin-bottom 8px
    cursor pointer

    .contratar-servico-texto
        color green
        font-size 10px

    .contratar-servico-icon
        color green
</style>