<template>
    <div class="co-contrato-mais-opcoes">
        <v-menu open-on-hover top left offset-y>
            <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="secondary-200"> mdi-dots-vertical </v-icon>
            </template>

            <v-list>
                <v-list-item v-show="podeEditar" v-az-auth="'Contrato.Editar'"
                             @click="abrirDialogEdicao">
                    <v-list-item-title>
                        <v-icon class="mr-2" size="18px"> mdi-pencil </v-icon>
                        Editar {{ $tc("pt_br.documento") }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-show="podeCancelar" v-az-auth="'Contrato.Cancelar'"
                             @click="abrirDialogMotivoCancelamento">
                    <v-list-item-title>
                        <v-icon class="mr-2" size="18px"> mdi-close </v-icon>
                        Cancelar Versão
                    </v-list-item-title>
                </v-list-item>

                <v-list-item
                    v-show="podeRemover"
                    v-az-auth="'Contrato.Excluir'"
                    @click="abrirDialogConfirmarRemoverContrato">
                    <v-list-item-title>
                        <v-icon class="mr-2" size="18px"> mdi-delete </v-icon>
                        Excluir Versão
                    </v-list-item-title>
                </v-list-item>

                <v-divider/>

                <v-list-item v-az-auth="'Contrato.Renovar'" @click="renovarContrato">
                    <v-list-item-title>
                        <v-icon class="mr-2" size="18px"> mdi-autorenew </v-icon>
                        {{ getLabelBotaoGerarNovaVersaoOuRenovar }}
                    </v-list-item-title>
                </v-list-item>

                <v-list-item
                    v-show="podeArquivar"
                    v-az-auth="'Contrato.Arquivar'"
                    @click="abrirDialogConfirmarArquivarContrato">
                    <v-list-item-title>
                        <v-icon class="mr-2" size="18px"> mdi-package-down </v-icon>
                        Arquivar {{ $tc("pt_br.documento") }}
                    </v-list-item-title>
                </v-list-item>

                <v-list-item v-show="podeRestaurar" v-az-auth="'Contrato.Restaurar'"
                             @click="abrirDialogConfirmarRestaurarContrato">
                    <v-list-item-title>
                        <v-icon class="mr-2" size="18px"> mdi-package-up</v-icon>
                        Restaurar {{ $tc("pt_br.documento") }}
                    </v-list-item-title>
                </v-list-item>

                <v-list-item v-show="podeExtinguir" v-az-auth="'Contrato.Extinguir'" @click="abrirDialogExtincao">
                    <v-list-item-title>
                        <v-icon class="mr-2" size="18px"> mdi-timer-sand-empty </v-icon>
                        Extinguir {{ $tc("pt_br.documento") }}
                    </v-list-item-title>
                </v-list-item>

                <v-list-item v-az-auth="'Contrato.Mover'" @click="abrirDialogMoverContrato">
                    <v-list-item-title>
                        <v-icon class="mr-2" size="18px"> mdi-folder </v-icon>
                        Mover para Pasta
                    </v-list-item-title>
                </v-list-item>

                <v-divider/>

                <v-list-item @click="downloadContrato">
                    <v-list-item-title>
                        <v-icon class="ml-0 mr-2" size="18px" style="margin-left: 5px"> mdi-download </v-icon>
                        Baixar {{ $tc("pt_br.documento") }}
                    </v-list-item-title>
                </v-list-item>

                <v-list-item v-if="ehMobile" @click="notificacaoContrato">
                    <v-list-item-title>
                        <v-icon size="18" class="ml-0 mr-2"> mdi-bell</v-icon>
                        Notificação
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>


        <dialog-motivo-cancelamento
            v-if="dialogMotivoCancelamento"
            :dialog="dialogMotivoCancelamento"
            @confirmarCancelamento="confirmarCancelamento"
            @fecharModalMotivoCancelamento="fecharDialogMotivoCancelamento"
        />

        <dialog-nova-extincao
            v-if="dialogExtincao"
            :dialog="dialogExtincao"
            v-model="extincao"
            @cancelar="fecharDialogExtincao"
            @clean="limparArquivoExtincao"
            @extinguir="extinguirContrato"
            @upload="preencherReferenciaArquivo"
        />

        <dialog-mover-elemento
            v-if="dialogMoverContrato"
            :dialog="dialogMoverContrato"
            :elemento="contrato"
            @fechar="fecharDialoglMover"
        />

        <dialog-default-confirmar-acao
            v-if="dialogMostrarConfirmacao"
            :dialog="dialogMostrarConfirmacao"
            sentimento="atencao"
            titulo="Eita! Deixa eu confirmar uma coisa..."
            :subtitulo="confirmacao.subtitulo"
            :label-botao-principal="confirmacao.labelBotao"
            @acao-principal="confirmacao.acaoPrincipal()"
            @fechar="fecharDialogMostrarConfirmacao"
        />

        <dialog-default-confirmar-acao
            v-if="dialogLimitePlanoAtingido"
            :dialog="dialogLimitePlanoAtingido"
            sentimento="assustado"
            titulo="Eita! Você atingiu seu limite de uso"
            :subtitulo=mensagemLimiteDocumentos
            label-botao-principal="Sim, eu quero!"
            @acao-principal="acaoDialogLimitePlanoAtingido"
            @fechar="fecharDialogLimitePlanoAtingido"
        />

        <dialog-renomear-contratos-novo
            v-if="modalEditar"
            v-model="contrato"
            :nome="contrato.nome"
            :tags="retornaListaTag(contrato.tags)"
            @fechar="fecharDialogRenomearContratos"
            @salvar="salvar"
        />
    </div>

</template>

<script>
import {mapGetters, mapState} from 'vuex'
import controleAcoes from '@/views/contrato/controle-acoes'
import DialogDefaultConfirmarAcao from '@/commons/components/dialogs/DialogDefaultConfirmarAcao'
import {actionTypes, mutationTypes} from '@/commons/constants'
import DialogMotivoCancelamento from '@/views/home/meus-contratos/components/contratos/dialogs/DialogMotivoCancelamento'
import PlanoAssinaturaUtils from '@/commons/utils/PlanoAssinaturaUtils'
import DialogNovaExtincao from '@/views/home/meus-contratos/components/contratos/dialogs/DialogNovaExtincao'
import DialogMoverElemento from '@/views/home/meus-contratos/components/common/DialogMoverElemento'
import DialogRenomearContratosNovo from './DialogRenomearContratosNovo'

export default {
    name: 'MaisOpcoesContratos',
    components: {
        DialogMoverElemento,
        DialogNovaExtincao,
        DialogMotivoCancelamento,
        DialogDefaultConfirmarAcao,
        DialogRenomearContratosNovo
    },
    props: {
        contrato: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            confirmacao: {
                subtitulo: '',
                acaoPrincipal: () => {
                    // acao default
                },
                labelBotao: '',
            },
            motivoCancelamento: '',
            dialogExtincao: false,
            dialogMotivoCancelamento: false,
            dialogMostrarConfirmacao: false,
            dialogLimitePlanoAtingido: false,
            arquivoExtincao: {},
            extincao: {},
            dialogMoverContrato: false,
            mensagemModalMoverNotificacao: '',
            modalEditar: false
        }
    },
    computed: {
        ...mapState(['login', 'organizacao', 'meusContratos', 'meusContratosBusca', 'minutaAtual', 'signatarios', 'pastaAtual']),
        ...mapGetters(['nomePlanoContratado', 'ehEfcazCLM', 'ehPlanoFree']),
        mensagemLimiteDocumentos() {
            const textClm = 'Adquira o Plano PRO e continue alavancando seu negócio.'
            let textoBesign = ''

            if (this.ehPlanoFree) {
                textoBesign = 'A quantidade de documentos disponíveis acabou! Adquira um plano recorrente ou um pacote individual para continuar a enviar contratos para assinatura. Clique em “Sim, eu quero” para conhecer nossas opções.'
            } else {
                textoBesign = 'A quantidade de documentos disponíveis em seu plano acabou! Faça um upgrade para um plano com mais documentos ou adquira um pacote individual para continuar a enviar contratos para assinatura. Clique em “Sim, eu quero” para conhecer nossas opções.'
            }

            return `${this.ehEfcazCLM ? textClm : textoBesign} <span style="font-size:20px">&#128640;</span>`
        },
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        },
        getLabelBotaoGerarNovaVersaoOuRenovar() {
            return controleAcoes._minutaCancelada(this.contrato.minutaSituacaoFormalizacao) ? 'Gerar Nova Versão' : 'Renovar/Aditivar'
        },
        podeCancelar() {
            const minutaCancelamento = {
                situacaoMinuta: this.contrato.minutaSituacaoFormalizacao,
                totalAssinaturas: this.contrato.totalAssinaturas,
                contratoArquivado: this.contrato.arquivado,
                contratoExtinto: this.contrato.extinto
            }
            return controleAcoes.cancelarMinuta(minutaCancelamento)
        },
        podeExtinguir() {
            return controleAcoes.extinguirContratoPeloCard(this.contrato)
        },
        podeRemover() {
            const contratoExclusao = {
                situacaoMinuta: this.contrato.minutaSituacaoFormalizacao,
                totalAssinaturas: this.contrato.totalAssinaturas
            }
            return controleAcoes.excluirContrato(contratoExclusao)
        },
        podeRestaurar() {
            return controleAcoes.restaurarContrato(this.contrato)
        },
        podeArquivar() {
            return !this.contrato.arquivado
        },
        podeEditar() {
            return !this.contrato.arquivado && !this.contrato.extinto
        },
    },
    methods: {
        abrirDialogExtincao() {
            this.dialogExtincao = true
            this.arquivoExtincao = new File([''], '')
            this.contratoDTO = {}
        },
        abrirDialogConfirmarArquivarContrato() {
            this.confirmacao = {
                subtitulo: `Você quer mesmo arquivar o ${this.$tc('pt_br.documento').toLowerCase()}?`,
                labelBotao: 'Sim, eu quero arquivar!',
                acaoPrincipal: this.arquivar
            }
            this.dialogMostrarConfirmacao = true
        },
        abrirDialogConfirmarRestaurarContrato() {
            this.confirmacao = {
                subtitulo: `Você quer mesmo restaurar o ${this.$tc("pt_br.documento").toLowerCase()}?`,
                labelBotao: 'Sim, eu quero restaurar!',
                acaoPrincipal: this.restaurarContrato
            }
            this.dialogMostrarConfirmacao = true
        },
        abrirDialogConfirmarRemoverContrato() {
            this.confirmacao = {
                subtitulo: `Você quer mesmo excluir a versão do ${this.$tc('pt_br.documento').toLowerCase()}?`,
                labelBotao: 'Sim, eu quero excluir!',
                acaoPrincipal: this.removerContrato
            }
            this.dialogMostrarConfirmacao = true
        },
        abrirDialogEdicao() {
            this.modalEditar = true
        },
        abrirDialogLimitePlanoAtingido() {
            this.dialogLimitePlanoAtingido = true
        },
        abrirDialogMostrarConfirmacao() {
            this.dialogMostrarConfirmacao = true
        },
        abrirDialogMotivoCancelamento() {
            this.dialogMotivoCancelamento = true
        },
        abrirDialogMoverContrato() {
            this.dialogMoverContrato = true
        },
        async acaoDialogLimitePlanoAtingido() {
            window.open("https://www.asaas.com/c/416172638803", '_blank')
            await this.$store.dispatch(actionTypes.EMAIL.ENVIAR_EMAIL_COMERCIAL, {
                'mensagem': `O usuário ${this.login.usuarioOrganizacao.usuarioNome}` +
                    `de e-mail: ${this.login.usuarioOrganizacao.usuarioEmail}` +
                    `e telefone: ${this.login.usuarioOrganizacao.usuarioTelefone}` +
                    `da organização: ${this.organizacao.nome}` +
                    `atingiu o limite do Plano: ${this.nomePlanoContratado}`
            })
            this.fecharDialogLimitePlanoAtingido()
            this.$router.push({name: 'planos'})
        },
        async arquivar() {
            this.setMensagemLoading(`Arquivando ${this.$tc('pt_br.documento').toLowerCase()}...`)
            this.fecharDialogMostrarConfirmacao()
            await this.$store.dispatch(actionTypes.ARQUIVAR_CONTRATO, this.contrato.contratoId)
            await this.buscarPastas()
            await this.atualizarListagemContratos()
            this.mostrarNotificacaoSucessoDefault()
        },
        atualizarContratoState() {
            this.$store.state.contrato = this.contrato
        },
        async atualizarListagemContratos() {
            this.setMensagemLoading(`Atualizando listagem de ${this.$tc("pt_br.documento").toLowerCase()}...`)
            this.removerDaListagemMeusContratos()
        },
        async cancelar() {
            try {
                this.setMensagemLoading(`Cancelando versão do ${this.$tc('pt_br.documento').toLowerCase()}...`)
                this.fecharDialogMostrarConfirmacao()
                await this.$store.dispatch(actionTypes.CANCELAR_MINUTA, {
                    contratoId: this.contrato.contratoId,
                    minutaId: this.contrato.minutaId,
                    motivoCancelamento: this.motivoCancelamento
                })
                await this.sincronizarDadosContrato()
                this.mostrarNotificacaoSucessoDefault()
            } catch (e) {
                this.fecharDialogMostrarConfirmacao()
            }
        },
        confirmarCancelamento(value) {
            this.motivoCancelamento = value.motivoCancelamento
            this.fecharDialogMotivoCancelamento()
            this.confirmacao = {
                subtitulo: `Você quer mesmo cancelar a versão do ${this.$tc('pt_br.documento').toLowerCase()}?`,
                labelBotao: 'Sim, eu quero cancelar!',
                acaoPrincipal: this.cancelar
            }
            this.abrirDialogMostrarConfirmacao()
        },
        construirRequisicaoExtincao() {
            this.extincao.contratoId = this.contrato.contratoId
            const extincao = this.extincao
            const formData = new FormData()
            formData.append('arquivo', this.arquivoExtincao)
            formData.append('extincao', JSON.stringify(extincao))
            return {contratoId: this.contrato.contratoId, extincao: formData}
        },
        criarPayloadTags(tags) {
            const contratoTags = []
            tags.forEach(tag => {
                contratoTags.push({
                    contratoId: this.contrato.contratoId,
                    tagId: tag.tagId,
                    nome: tag.nome
                })
            })
            return contratoTags
        },
        async downloadContrato() {
            const arquivoBase64 = await this.$store.dispatch(actionTypes.DOWNLOAD_MINUTA_ZIP, {
                contratoId: this.contrato.contratoId,
                minutaId: this.contrato.minutaId
            })
            this.salvarArquivo(this.contrato.nome, arquivoBase64, 'application/zip')
        },
        async extinguirContrato() {
            try {
                this.setMensagemLoading(`Extinguindo ${this.$tc("pt_br.documento").toLowerCase()}...`)
                await this.$store.dispatch(actionTypes.EXTINGUIR_CONTRATO, this.construirRequisicaoExtincao())
                await this.$store.dispatch(actionTypes.BUSCAR_CONTRATO, this.contrato.contratoId)
                this.fecharDialogExtincao()
                this.$store.state.contrato = {}
                await this.buscarPastas()
                await this.atualizarListagemContratos()
                this.mostrarNotificacaoSucessoDefault()
            } catch (e) {
                this.fecharDialogExtincao()
            }
        },
        fecharDialogLimitePlanoAtingido() {
            this.dialogLimitePlanoAtingido = false
        },
        fecharDialogMotivoCancelamento() {
            this.dialogMotivoCancelamento = false
        },
        fecharDialogMostrarConfirmacao() {
            this.dialogMostrarConfirmacao = false
        },
        fecharDialogExtincao() {
            this.dialogExtincao = false
        },
        fecharDialoglMover() {
            this.dialogMoverContrato = false
        },
        fecharDialogRenomearContratos() {
            this.modalEditar = false
        },
        limparArquivoExtincao() {
            this.arquivoExtincao = new File([""], "");
        },
        notificacaoContrato() {
            this.$emit('notificacao-contrato')
        },
        podeRenovar() {
            return this.contrato.minutaSituacaoFormalizacao === 'CANCELADO' || this.contrato.minutaSituacaoFormalizacao === 'FORMALIZADO'
        },
        preencherReferenciaArquivo(arquivo) {
            this.arquivoExtincao = arquivo
        },
        async removerContrato() {
            try {
                this.setMensagemLoading(`Removendo ${this.$tc("pt_br.documento").toLowerCase()}...`)
                this.fecharDialogMostrarConfirmacao()
                await this.$store.dispatch(actionTypes.REMOVER_CONTRATO, this.contrato.contratoId)
                if (controleAcoes._versaoInicial(this.contrato.ultimaVersao)) {
                    this.removerDaListagemMeusContratos()
                } else {
                    await this.sincronizarDadosContrato()
                }
                await this.buscarPastas()
                await this.$store.dispatch(actionTypes.COMUN.BUSCAR_CONSUMO_ORGANIZACAO)
                this.mostrarNotificacaoSucessoDefault()
            } catch (e) {
                this.fecharDialogMostrarConfirmacao()
            }
        },
        async removerContratoDaListagemArquivados() {
            await this.atualizarContratoState()
            this.removerDaListagemMeusContratos()
        },
        removerDaListagemMeusContratos() {
            const contratos = this.meusContratos
            const totalContratos = this.meusContratosBusca.totalContratosListagem
            const index = contratos.findIndex(contrato => contrato.contratoId === this.contrato.contratoId)
            contratos.splice(index, 1)
            this.resetMeusContratos()
            this.$store.commit(mutationTypes.SET_MEUS_CONTRATOS, contratos)
            this.$store.commit(mutationTypes.SET_TOTAL_CONTRATOS_LISTAGEM, totalContratos - 1)
        },
        async renovarContrato() {
            const podeRenovar = this.podeRenovar()
            const organizacaoId = this.$store.state.login.organizacaoPlano.organizacaoId
            if (await PlanoAssinaturaUtils.limiteContratosAtingido(organizacaoId)) {
                this.abrirDialogLimitePlanoAtingido()
                return
            }
            if (!podeRenovar) {
                this.mostrarNotificacaoErro(`${this.$tc("pt_br.documento")} deve estar formalizado ou cancelado para ser renovado/aditivado.`)
                return
            }
            await this.$router.push({name: 'renovarDocumento', params: {contratoId: this.contrato.contratoId}})
        },
        retornaListaTag(tags) {
            return JSON.parse(tags[0]);
        },
        resetMeusContratos() {
            this.$store.commit(mutationTypes.SET_MEUS_CONTRATOS, [])
        },
        async restaurarContrato() {
            this.setMensagemLoading('Restaurando contrato...')
            await this.$store.dispatch(actionTypes.RESTAURAR_CONTRATO_ARQUIVADO, this.contrato.contratoId)
            await this.buscarPastas()
            await this.removerContratoDaListagemArquivados()
            this.mostrarNotificacaoSucessoDefault()
        },
        async salvar(nome, tags) {
            this.setMensagemLoading('Atualizando dados do contrato...')
            await this.$store.dispatch(actionTypes.ATUALIZAR_CONTRATO, {
                contratoId: this.contrato.contratoId,
                contrato: {nome: nome}
            })
            await this.$store.dispatch(actionTypes.ATUALIZAR_TAGS_CONTRATO, {
                contratoId: this.contrato.contratoId,
                contrato: {contratoTags: this.criarPayloadTags(tags)}
            })
            await this.buscarContratos()
            this.mostrarNotificacaoSucessoDefault()
        },
        async sincronizarDadosContrato() {
            this.setMensagemLoading(`Carregando dados do ${this.$tc("pt_br.documento").toLowerCase()}...`)
            await this.$store.dispatch(actionTypes.BUSCAR_MINUTA_ATUAL, this.contrato.contratoId)
            this.contrato.minutaSituacaoFormalizacao = this.minutaAtual.situacaoFormalizacao
            this.contrato.ultimaVersao = this.minutaAtual.versao
        },
    }
}
</script>
