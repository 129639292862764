<template>
    <v-container grid-list px-10 pt-10 pb-4 class="co-usuarios">

        <configuracao-titulo titulo="Usuários" divider-off>
            <div slot="actions" class="d-flex flex-column actions-wrapper">
                <div class="d-flex align-center top-actions">
                    <v-slide-x-reverse-transition v-if="!ehMobile">
                        <v-text-field
                            transition="slide-x-reverse-transition"
                            v-if="mostrarSearch"
                            class="search-bar"
                            v-model="valorSearch"
                            label="Buscar pelo nome ou e-mail"
                            hide-details
                            @blur="limparFiltrosNome"
                            @keyup.enter="realizarFiltroNomeEmail"/>
                    </v-slide-x-reverse-transition>
    
                    <v-fade-transition>
                        <v-btn
                            v-if="!mostrarSearch || !ehMobile"
                            class="mr-4"
                            height="32px"
                            width="32px"
                            icon outlined
                            @click="realizarFiltroNomeEmail"
                            color="primary"
                        >
                            <v-icon> mdi-magnify</v-icon>
                        </v-btn>
                    </v-fade-transition>
    
                    <div v-az-auth="'Usuario.Novo'">
                        <v-tooltip transition="slide-y-reverse-transition" top :disabled="!ehConfiguracaoNotificacaoUsuariosDesabilitada">
                            <template v-slot:activator="{on}">
                                <div v-on="on">
                                    <v-btn v-if="!ehMobile" 
                                    :disabled="ehConfiguracaoNotificacaoUsuariosDesabilitada" 
                                    color="primary"  
                                    depressed
                                    @click="novoCadastroUsuario">
                                        <v-icon size="20px" class="mr-2"> mdi-plus-circle</v-icon>
                                        Adicionar
                                    </v-btn>
        
                                    <v-btn
                                        v-else icon
                                        color="primary"
                                        height="32px"
                                        width="32px"
                                        dark
                                        depressed
                                        :disabled="ehConfiguracaoNotificacaoUsuariosDesabilitada"
                                        @click="novoCadastroUsuario"
                                    >
                                        <v-icon size="41px"> mdi-plus-circle</v-icon>
                                    </v-btn>
                                </div>
                            </template>
    
                            <span>Não é possível adicionar novos usuários na organização<br> pois o envio das credenciais por e-mail foi desativado.</span>
                        </v-tooltip>    
                    </div>
                </div>

                <div class="pr-2 filters-active-inative">
                    <a :class="getClasseFiltro('ATIVO')" @click="filtrarStatus('ATIVO')">Ativos</a>
                    <a :class="getClasseFiltro('INATIVO')" @click="filtrarStatus('INATIVO')">Inativos</a>
                    <a :class="getClasseFiltro('TODOS')" @click="filtrarStatus('TODOS')">Todos</a>
                </div>
            </div>
        </configuracao-titulo>

        <v-row no-gutters v-if="ehMobile" class="justify-space-between align-center my-4">
            <v-slide-x-reverse-transition>
                <v-text-field
                    transition="slide-x-reverse-transition"
                    v-if="mostrarSearch"
                    class="search-bar"
                    v-model="valorSearch"
                    label="Buscar pelo nome ou e-mail"
                    hide-details
                    @blur="limparFiltrosNome"
                    @keyup.enter="realizarFiltroNomeEmail">
                </v-text-field>
            </v-slide-x-reverse-transition>

            <v-fade-transition>
                <v-btn
                    v-if="mostrarSearch"
                    class="ml-2"
                    height="32px"
                    width="32px"
                    icon outlined
                    @click="realizarFiltroNomeEmail"
                    color="primary">
                    <v-icon dark> mdi-magnify</v-icon>
                </v-btn>
            </v-fade-transition>
        </v-row>
        
        <v-row no-gutters class="justify-space-between mx-3 mt-5">
            <usuarios-tabela
                :usuarios="usuarios.content"
                :qtd-linhas-tabela="qtdLinhasSelecionada"
                @editar="abrirEditarUsuario"
                @inativar="abrirModalInativarUsuario"
                @ativar="abrirModalAtivarUsuario"
                @token="abrirModalTokenApi"
            />
        </v-row>

        <v-row no-gutters class="justify-space-between mt-3 mx-3">
            <v-flex class="footer">
                <v-pagination
                    dense
                    class="paginas justify-center elevation-0"
                    v-model="paginacao.page"
                    @input="mudarPaginacao"
                    :length="usuarios.totalPages"
                    :total-visible="7"
                />

                <v-spacer v-if="!ehMobile"/>

                <v-select
                    v-if="!ehMobile"
                    class="linhas-por-pagina"
                    v-model="qtdLinhasSelecionada"
                    :items="quatidades"
                    label="Itens por pagina"
                    hide-details
                    @change="atualizarQtdLinhasPorPagina"
                />
            </v-flex>
        </v-row>

        <dialog-usuario-form
            v-if="abrirModalNovoUsuario"
            :dialog="abrirModalNovoUsuario"
            :cadastro-usuario="cadastroUsuario"
            :editando="editando"
            @fechar="fecharDialogNovoUsuario"
            @salvar="enviarConvite"
            @fecharEdicao="fecharDialogEditarUsuario"
            @editar="editarUsuario"
        />

        <dialog-token-api
            :dialog="dialogTokenApi"
            :token="cadastroUsuario.tokenApi"
            @fechar="fecharDialogTokenApi"
        />

        <dialog-default-confirmar-acao
            v-if="mostrarConfirmacao"
            :dialog="mostrarConfirmacao"
            sentimento="assustado"
            :titulo="confirmacao.titulo"
            :subtitulo="confirmacao.subtitulo"
            label-botao-principal="Sim, Claro!"
            @acao-principal="confirmacao.acaoPrincipal"
            @fechar="fecharConfirmacao"
        />
    </v-container>
</template>

<script>
import {mapState} from 'vuex'
import {actionTypes} from '@/commons/constants'
import UsuariosTabela from './UsuariosTabela'
import DialogUsuarioForm from './DialogUsuarioForm'
import DialogTokenApi from './DialogTokenApi'
import DialogDefaultConfirmarAcao from '@/commons/components/dialogs/DialogDefaultConfirmarAcao'
import ConfiguracaoTitulo from '../common/ConfiguracaoTitulo'
import PlanoAssinaturaUtils from '@/commons/utils/PlanoAssinaturaUtils'

export default {
    name: 'Usuarios',
    components: {
        ConfiguracaoTitulo,
        DialogDefaultConfirmarAcao,
        DialogUsuarioForm,
        UsuariosTabela,
        DialogTokenApi
    },
    data() {
        return {            
            cadastroUsuario: {},
            abrirModalNovoUsuario: false,
            editando: false,
            usuarios: [],
            mostrarConfirmacao: false,
            dialogTokenApi: false,
            filtroStatusSelecionado: 'ATIVO',
            paginacao: {
                page: 1, rowsPerPage: 10, sortBy: '', descending: true
            },
            quatidades: [5, 10, 15, 25, 50],
            qtdLinhasSelecionada: 10,
            confirmacao: {
                titulo: '',
                subtitulo: '',
                acaoPrincipal: () => {}
            },
            mostrarSearch: false,
            valorSearch: '',
            filtros: {situacao: {value: 'ATIVO'}, nome: {value: ''}},
            filtrado: false,
        }
    },
    computed: {
        ...mapState(['loki', 'organizacao', 'ehEfcazClm']),
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        },
        ehConfiguracaoNotificacaoUsuariosDesabilitada() {
            return !this.$store.state.login.organizacaoConfiguracao.notificacao.usuario.emailUsuarioAdicional
        }
    },
    async mounted() {        
        await this.resetarPaginacao()
        await this.resetarFiltros()
        await this.calcularNumeroLinhas()
        await this.buscarUsuarios()
    },
    methods: {
        async abrirEditarUsuario(usuarioSelecionado) {
            this.cadastroUsuario = {
                id: usuarioSelecionado.usuarioId,
                perfilId: usuarioSelecionado.perfil.id,
                nome: usuarioSelecionado.usuarioNome,
                documento: usuarioSelecionado.usuarioDocChave,
                email: usuarioSelecionado.usuarioEmail,
            }
            
            this.editando = true
            this.abrirModalNovoUsuario = true
        },
        async abrirModalTokenApi(usuario) {
            this.cadastroUsuario = { ...usuario }

            const usuarioJaPossuiTokenApi = this.cadastroUsuario.tokenApi

            if (!usuarioJaPossuiTokenApi) {
                var token = await this.$store.dispatch(actionTypes.CONFIGURACAO.CRIAR_TOKEN_API_V2, {
                    usuarioId: this.cadastroUsuario.usuarioId
                })

                await this.buscarUsuarios()

                this.cadastroUsuario = {...this.cadastroUsuario, tokenApi: token.tokenApi}
            } else {
                this.cadastroUsuario = {...this.cadastroUsuario}
            }

            this.dialogTokenApi = true
        },
        async atribuirDomionioPerfilParaUsuario(usuarioId) {
            this.setMensagemLoading('Atribuindo permissões...')
            await this.$store.dispatch(actionTypes.ATRIBUIR_DOMINIO_PERFIL_NOVO_USUARIO, this.criarPayloadDominioPerfil(usuarioId))
        },
        async abrirModalAtivarUsuario(usuarioParaAtivar) {
            const planoAtingiuLimiteUsuarios = await PlanoAssinaturaUtils.limiteUsuariosAtingido(this.$store.state.login.organizacaoPlano.organizacaoId)

            if (planoAtingiuLimiteUsuarios) {
                this.abrirDialogLimiteAtingido()
                
                return
            }

            this.confirmacao = {
                titulo: 'Eita! Deixa eu confirmar uma coisa...',
                subtitulo: `Ao ativar o usuário, ele terá acesso aos ${this.$tc("pt_br.documento", 2).toLowerCase()} de sua organização. Tem certeza que deseja ativar o usuário?`,
                acaoPrincipal: this.ativarUsuario
            }

            this.mostrarConfirmacao = true
            this.cadastroUsuario = usuarioParaAtivar
        },
        async atualizarQtdLinhasPorPagina() {
            this.paginacao.page = 1
            this.paginacao.rowsPerPage = this.qtdLinhasSelecionada

            await this.buscarUsuarios()
        },
        async ativarUsuario() {
            try {
                this.fecharConfirmacao()
                this.setMensagemLoading('Ativando usuário...')

                await this.$store.dispatch(actionTypes.ATIVAR_USUARIO_ORGANIZACAO, {
                    organizacaoId: this.$store.state.organizacao.organizacaoId,
                    usuarioId: this.cadastroUsuario.usuarioId
                })

                this.buscarUsuarios()
                this.mostrarNotificacaoSucessoDefault()
            } catch (e) {
                const exceptionType = e.response.data.exception

                if (exceptionType.search('LimiteUsuariosAtingidoException') !== -1) {
                    this.mostrarLimitePlanoAtingido = true
                }
            }
        },
        abrirModalInativarUsuario(usuarioParaInativar) {
            this.confirmacao = {
                titulo: 'Eita! Deixa eu confirmar uma coisa...',
                subtitulo: `Ao inativar o usuário, ele não poderá gerenciar os ${this.$tc("pt_br.documento", 2).toLowerCase()} de sua organização. Tem certeza que deseja inativar o usuário?`,
                acaoPrincipal: this.inativarUsuario
            }

            this.mostrarConfirmacao = true
            this.cadastroUsuario = usuarioParaInativar
        },
        abrirDialogLimiteAtingido() {
            const textoClm = 'Adquira o Plano PRO e continue alavancando seu negócio'
            let textoBesign = ''

            if (this.ehPlanoFree) {
                textoBesign = 'Considere atualizar para um plano! Adquira um plano recorrente ou um pacote individual para adicionar mais usuários. Clique em “Sim, eu quero” para conhecer nossas opções.'
            } else {
                textoBesign = 'Considere atualizar para um plano! Faça um upgrade para um plano com mais limite para continuar adicionando usuários. Clique em “Sim, eu quero” para conhecer nossas opções.'
            }

            this.confirmacao = {
                titulo: 'Eita! O limite de usuários foi atingido...',
                subtitulo: `${this.ehEfcazCLM ? textoClm : textoBesign} <span style="font-size:20px">&#128640;</span>`,
                acaoPrincipal: this.redirecionarParaPlanos
            }

            this.mostrarConfirmacao = true
        },
        async buscarUsuarios() {
            this.setMensagemLoading('Carregando usuários...')

            const organizacaoId = this.$store.state.organizacao.organizacaoId

            this.usuarios = await this.$store.dispatch(actionTypes.USUARIO.BUSCAR_POR_ORGANIZACAO,
                {organizacaoId, filtros: this.filtros, paginacao: this.paginacao})
        },
        async calcularNumeroLinhas() {
            if (!this.ehMobile) {
                let qtdLinhasIdeal = Math.floor((window.innerHeight - 316) / 40)

                this.qtdLinhasSelecionada = qtdLinhasIdeal
                
                this.quatidades.push(qtdLinhasIdeal)

                this.quatidades.sort((a, b) => a - b)
            }
        },
        criarPayloadDominioPerfil(usuarioId) {
            return {
                idUsuario: usuarioId,
                idProduto: this.$store.state.loki.product.id,
                perfis: [this.cadastroUsuario.perfil.id],
                unidadesOrganizacionais: [0]
            }
        },
        criarPayloadNovoUsuario() {
            return {
                email: this.cadastroUsuario.email,
                nome: this.cadastroUsuario.nome,
                docChave: this.cadastroUsuario.documento.replaceAll(/-|\./g, ''),
                perfil: this.cadastroUsuario.perfil.nome
            }
        },
        async enviarConvite() {
            if (await PlanoAssinaturaUtils.limiteUsuariosAtingido(this.organizacao.organizacaoId)) {
                this.fecharDialogNovoUsuario()

                this.mostrarLimitePlanoAtingido = true
                return
            }

            this.fecharDialogNovoUsuario()

            try {
                await this.inserirUsuario()
                await this.buscarUsuarios()

                this.mostrarNotificacaoSucessoDefault()
            } catch (e) {
                const mensagem = 'Ocorreu um erro ao enviar os dados de acesso do usuário por e-mail. Por favor, entre em contato com o suporte para solucionar este problema.'
                
                this.mostrarNotificacaoErro(mensagem)
            }
        },
        async inserirUsuario() {
            return await this.$store.dispatch(actionTypes.USUARIO.INSERIR_USUARIO_ORGANIZACAO, this.criarPayloadNovoUsuario())
        },
        async editarUsuario(){
            this.setMensagemLoading('Salvando alteração...')

            await this.atribuirDomionioPerfilParaUsuario(this.cadastroUsuario.id)
            await this.buscarUsuarios()

            this.fecharDialogEditarUsuario()
            this.mostrarNotificacaoSucessoDefault()
        },
        fecharDialogNovoUsuario() {
            this.abrirModalNovoUsuario = false
        },
        fecharDialogEditarUsuario() {
            this.editando = false
            this.abrirModalNovoUsuario = false
        },
        fecharConfirmacao() {
            this.confirmacao = {
                subtitulo: '',
                acaoPrincipal: () => {}
            }

            this.mostrarConfirmacao = false
        },
        fecharDialogTokenApi() {
            this.dialogTokenApi = false
        },
        async filtrarStatus(filtro) {
            this.filtroStatusSelecionado = filtro

            if (this.filtroStatusSelecionado === 'TODOS') {
                this.filtros = {...this.filtros, situacao: {value: null}}
            } else {
                this.filtros = {...this.filtros, situacao: {value: filtro}}
            }

            await this.buscarUsuarios()
        },
        getClasseFiltro(filtro) {
            return this.filtroStatusSelecionado === filtro ? 'ativo' : 'inativo'
        },
        async inativarUsuario() {
            this.fecharConfirmacao()
            this.setMensagemLoading('Inativando usuário...')

            await this.$store.dispatch(actionTypes.INATIVAR_USUARIO_ORGANIZACAO, {
                organizacaoId: this.$store.state.organizacao.organizacaoId,
                usuarioId: this.cadastroUsuario.usuarioId
            })

            this.buscarUsuarios()
            this.mostrarNotificacaoSucessoDefault()
        },
        async limparFiltrosNome() {
            if(this.valorSearch === "" && this.filtrado) {
                this.filtros = {...this.filtros, nome: {value: null}}

                this.filtrado = false
                
                await this.buscarUsuarios()
            }
        },
        async mudarPaginacao(pagina) {
            this.paginacao.page = pagina

            await this.buscarUsuarios()
        },
        async novoCadastroUsuario() {
            this.cadastroUsuario = {
                id: null,
                perfil: null,
                nome: '',
                documento: '',
                email: '',
            }

            this.editando = false
            this.abrirModalNovoUsuario = true
        },
        async realizarFiltroNomeEmail() {
            if (!this.mostrarSearch) {
                this.mostrarSearch = true

                return
            }

            if (this.valorSearch === '') {
                this.mostrarSearch = false

                if (!this.filtrado) return

                this.filtrado = false
            } else {
                this.filtrado = true
            }

            this.setFiltros({
                ...this.filtros,
                nome: {value: this.valorSearch}
            })

            this.resetarPaginacao()
            
            await this.buscarUsuarios()
        },
        redirecionarParaPlanos(){
            this.$router.push({name: 'planos'})
            this.mostrarConfirmacao = false
        },
        resetarPaginacao() {
            this.paginacao = {
                page: 1, rowsPerPage: 10, sortBy: '', descending: true
            }
        },
        resetarFiltros() {
            this.setFiltros({
                situacao: {value: 'ATIVO'},
                nome: {value: ''},
            })
        },
        setFiltros(filtros) {
            this.filtros = filtros
        },
    }
}
</script>

<style lang="stylus">
.co-usuarios
    background-color var(--background-100) !important
    width 100%

    .actions-wrapper
        gap 10px
    
        .ativo
            padding-left 20px
            color var(--primary)
            font-size 12px

        .inativo
            padding-left 20px
            color #bbb
            font-size 12px
        
        .filters-active-inative
            align-self end
        
        .top-actions {
            align-self end
        }

    .title-line
        height 60px

    .titulo
        height 44px

    .title
        color var(--primary)

    .search-bar
        padding-top 0
        margin-top 0
        max-width 350px
        min-width 220px

    .footer
        display flex
        position relative
        align-content space-between

        .paginas
            width 100%
            align-content center

        .linhas-por-pagina
            position absolute
            right 0
            width 100px

@media (max-width: 720px)
    .co-usuarios
        .footer
            .linhas-por-pagina
                position unset
</style>

