<template>
    <v-flex class="co-home-contrato-grade mb-3">
        <elemento-drag :elemento="contrato" :nome="contrato.nome" icon="mdi-file-document">
            <div slot="item" @click="visualizarContrato" class="co-home-contrato-grade contrato d-flex">
                <v-col class="pa-2">

                    <div class="nome-contrato">
                        <span class="ellipsis"> {{ contrato.nome }} </span>
                    </div>

                    <div v-if="contrato.pasta" class="pasta-nome"> <span class="font-weight-bold">Pasta:</span> {{ contrato.pasta }}</div>

                    <div class="situacao mt-2 mb-3">{{ contratoSituacao }}</div>

                    <contrato-tags-novo
                        :value="contrato.tags"
                        :qtd-caracteres-max="10"
                        :quantidade-tags="quantidadeTags"/>
                </v-col>

                <div class="barra-inferior-assinaturas py-1" :style="styleContratoStatus">{{ contratoStatus }}</div>
            </div>
        </elemento-drag>

        <div class="acoes">
            <contrato-info :contrato="contrato"/>
            <notificacao v-show="!ehMobile" :contrato="contrato" ref="refNotificao"/>
            <mais-opcoes-contratos :contrato="contrato" @notificacao-contrato="notificacaoContrato"/>
        </div>
    </v-flex>
</template>

<script>
import MaisOpcoesContratos from './common/MaisOpcoesContratos'
import ContratoTagsNovo from './common/ContratoTagsNovo'
import ContratoInfo from './common/contrato-info/ContratoInfo'
import Notificacao from '@/views/home/meus-contratos/common/Notificacao'
import ElementoDrag from '@/views/home/meus-contratos/components/common/ElementoDrag'

export default {
    name: 'ContratoGrade',
    components: {ElementoDrag, ContratoInfo, ContratoTagsNovo, MaisOpcoesContratos, Notificacao},
    props: {
        contrato: {
            type: Object,
            required: true
        },
        contratoStatus: {
            type: String,
            default: ''
        },
        contratoSituacao: {
            type: String,
            default: ''
        },
        styleContratoStatus: {
            type: String,
            defaul: ''
        }
    },
    computed: {
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        }
    },
    methods: {
        notificacaoContrato() {
            this.$refs.refNotificao.verificarPerfilUsuarioContrato()
        },
        visualizarContrato() {
            this.$router.push({name: 'detalheContrato', params: {id: this.contrato.contratoId}})
        },
        quantidadeTags() {
            if (this.$vuetify.breakpoint.xl) {
                return 3
            } else if (this.$vuetify.breakpoint.lg) {
                return 2
            } else if (this.$vuetify.breakpoint.md) {
                return 1
            } else if (this.$vuetify.breakpoint.sm) {
                return 2
            } else {
                return 1
            }
        }
    }
}
</script>

<style lang="stylus">
.co-home-contrato-grade
    cursor pointer
    height 135px
    width 100%
    display flex
    flex-direction column
    position relative

    .contrato
        border 1px solid var(--background-300)
        color var(--text-color-300)
        border-radius 5px

    .contratos-lista
        width 100%

    .nome-contrato
        color var(--text-color-300) !important
        display flex
        align-items center
        max-width calc(100% - 70px)

        .ellipsis
            display block
            align-items center
            text-overflow ellipsis
            white-space nowrap
            overflow hidden
            width 100%

    .pasta-nome
        font-size 10px


    .situacao
        font-size 12px
        font-weight 100

    .barra-inferior-assinaturas
        display flex
        justify-content center
        font-size 12px
        border-radius 0 0 5px 5px

    .acoes
        position absolute
        margin-top 8px
        right 5px
        display flex
</style>