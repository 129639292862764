<template>
    <div class="co-grupo-contatos-tabela">
        <v-data-table
            class="tabela-grupo-signatarios"
            hide-default-footer
            :no-data-text="noDataText"
            :headers="headers"
            :items-per-page="qtdLinhasTabela"
            :items="gruposSignatarios">
            <template v-slot:item.integrantes="{ item }">
                <div>
                    <span>{{ construirIntegrantes(item.integrantes) }}</span>
                </div>
            </template>
            <template v-slot:item.acoes="{ item }">
                <v-layout class="acoes-tabela">
                    <v-tooltip top color="black" v-az-auth="'GrupoSignatarios.Editar'">
                        <template v-slot:activator="{ on }">
                            <v-icon class="botoes botoes-mobile" v-on="on" size="15px" @click="$emit('edit', item)"> mdi-pencil </v-icon>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>

                    <v-tooltip top color="black" v-az-auth="'GrupoSignatarios.Excluir'">
                        <template v-slot:activator="{ on }">
                            <v-icon class="botoes" v-on="on" size="15px" @click="$emit('excluir', item)"> mdi-delete </v-icon>
                        </template>
                        <span>Excluir</span>
                    </v-tooltip>
                </v-layout>
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    name: 'GruposContatosTabela',
    props: {
        gruposSignatarios: {
            type: Array,
            required: true
        },
        qtdLinhasTabela: {
            type: Number,
            default: 10
        }
    },
    data() {
        return {
            headers: [
                {
                    text: 'Nome',
                    align: 'left',
                    sortable: false,
                    filterable: true,
                    value: 'nome',
                    divider: true
                },
                {
                    text: 'Qtd',
                    align: 'right',
                    sortable: false,
                    filterable: false,
                    value: 'integrantes.length',
                    width: '70px',
                    divider: true
                },
                {
                    text: 'Integrantes',
                    align: 'left',
                    sortable: false,
                    filterable: false,
                    value: 'integrantes',
                    width: '40%',
                    divider: true
                },
                {
                    text: 'Ações',
                    align: 'center',
                    value: 'acoes',
                    sortable: false,
                    filterable: false,
                    width: '70px',
                    divider: true
                }
            ]
        }
    },
    computed: {
        noDataText() {
            return 'Nenhum grupo de ' + this.$tc("pt_br.signatario", 2).toLowerCase() + ' encontrado.'
        }
    },
    methods: {
        construirIntegrantes(cadastros) {
            let integrantes = ''
            cadastros.slice(0,2).forEach(cadastro => {
                integrantes += cadastro + ', '
            })
            return integrantes.slice(0, -2) + ((cadastros.length > 2) ? '...' : '')
        }
    }
}
</script>

<style lang="stylus">
.co-grupo-contatos-tabela
    width 100%

    .v-input--selection-controls.v-input .v-label
        font-size 16px

    .tabela-grupo-signatarios
        color var(--text-color-300)

        .acoes-tabela
            justify-content space-around

            .botoes
                color var(--text-color-200)

        thead th
            border-bottom thin solid rgba(0,0,0,.12)

        tbody td
            height 40px !important
            font-size 13px !important
            border-bottom thin solid rgba(0,0,0,.12)

@media (max-width: 720px)
    .co-grupo-contatos-tabela
        .tabela-grupo-signatarios
            .acoes-tabela
                justify-content right

                .botoes-mobile
                    margin-right 15px

            thead
                display none !important

            tbody tr
                border-bottom 1px solid var(--v-primary-lighten1) !important

                td
                    display block !important
                    text-align left !important
                    padding 5px 10px !important
                    max-height unset !important
                    height unset !important
                    justify-items left

</style>