<template>
    <v-menu content-class="az-menu-lista" offset-y open-on-hover>
        <template v-slot:activator="{ on }">
            <p v-on="on">{{ labelVersaoSelecionada }}
                <v-icon size="14px"> mdi-chevron-down </v-icon>
            </p>
        </template>
        <v-list v-for="versao in historicoContrato" :key="versao.versao">
            <v-list-item @click="$emit('mudarVersao', versao)">
                <v-list-item-title>Versão {{ versao.versao }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'HistoricoContrato',
    computed: {
        ...mapState(['historicoContrato', 'minutaAtual']),
        labelVersaoSelecionada() {
            if (this.minutaAtual.versao === this.numeroVersaoAtual) {
                return `Versão ${this.minutaAtual.versao} (atual)`
            }
            return `Versão ${this.minutaAtual.versao}`
        },
        numeroVersaoAtual() {
            let maiorVersao = 0
            for (const minuta of this.historicoContrato) {
                if (minuta.versao > maiorVersao) {
                    maiorVersao = minuta.versao
                }
            }
            return maiorVersao
        }
    }
}
</script>