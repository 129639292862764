<template>
    <v-container grid-list px-10 pt-10 pb-4 class="co-configuracoes-white-label">

        <template>
            <configuracao-titulo
                titulo="Logotipo"
                subtitulo="Logo utilizado nos emails, página de assinatura e documento."
            />

            <v-row no-gutters class="align-center my-4 mx-3">
                <div class="d-flex botao justify-space-between">
                    <v-btn color="primary" class="selecione" dark @click.prevent="$refs.azFileUploadOrg.openFileSelector()">
                        <v-icon class="mr-2"> mdi-file-upload-outline </v-icon>
                        Selecionar Logotipo
                    </v-btn>

                    <v-btn icon v-if="ehOrganizacaoComLogo" color="text-color-2" class="remover" @click="limparLogo">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on">
                                    mdi-delete
                                </v-icon>
                            </template>
                            <span>Remover Logotipo</span>
                        </v-tooltip>
                    </v-btn>
                </div>


                <v-alert dense text type="info" color="primary lighten-1" class="alerta mb-0 justify-space-between">
                    <span style="font-size: 12px">
                        A imagem recomendada é de 400 x 100 pixels no formato .png
                    </span>
                </v-alert>
            </v-row>

            <v-row no-gutters class="align-center my-4 mx-3">
                <v-switch
                    class="mt-0 mr-3 pt-2"
                    v-model="whiteLabel.logoEmail"
                    :disabled="!ehOrganizacaoComLogo">
                    <template v-slot:label>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <span v-on="on">Desejo utilizar a logo da organização nos emails.</span>
                            </template>
                            <span>Esta opção estará ativa após a organização possuir uma logo.</span>
                        </v-tooltip>
                    </template>
                </v-switch>
            </v-row>
        </template>

        <configuracoes-exclusivas :eh-sem-funcionalidade="!ehWhiteLabelAtivo"/>

        <template>
            <configuracao-titulo
                titulo="White Label"
                subtitulo="Personalize o envio de documentos com sua marca e cores."
            />

            <div class="mx-3">
                <v-switch class="mt-0 mr-3 pt-2" v-model="whiteLabel.ativo" label="Ativar" :disabled="!ehWhiteLabelAtivo"/>
            </div>

            <div v-if="browser()" class="ml-3">
                <az-file-upload
                    v-show="false"
                    useCustomBehavior
                    :repository="repository"
                    :multiple="false"
                    accept="image/png,image/jpeg,image/jpg"
                    ref="azFileUploadOrg"
                    @upload-files="uploadFiles"/>
            </div>


            <white-label-pub-colors
                class="mx-3"
                :colors="whiteLabel.pub"
                :logo="logo.base64"
                :cores-default="whiteLabelCoresDefaulf"
                :disabled="!whiteLabel.ativo"/>

            <white-label-email-colors
                class="mx-3"
                :colors="whiteLabel.email"
                :logo="logo.base64"
                :cores-default="whiteLabelCoresDefaulf"
                :disabled="!whiteLabel.ativo"/>
        </template>

        <configuracao-salvar
            :disabled="!ehConfiguracoesDiferentes()"
            mostrar-cancelar
            @salvar="salvar"
            @cancelar="cancelarAlteracao"/>
    </v-container>
</template>

<script>

import {mapGetters, mapState} from 'vuex'
import {actionTypes} from '@/commons/constants'
import {hasPermissions} from '@azinformatica/loki'
import ConfiguracaoTitulo from '@/views/home/configuracoes/common/ConfiguracaoTitulo'
import ConfiguracaoSalvar from '@/views/home/configuracoes/common/ConfiguracaoSalvar'
import ConfiguracoesExclusivas from '@/views/home/configuracoes/common/ConfiguracoesExclusivas'
import WhiteLabelPubColors from './components/white-label-pub/WhiteLabelPubColors'
import WhiteLabelEmailColors from './components/white-label-email/WhiteLabelEmailColors'
import _ from 'lodash'

export default {
    name: 'ConfiguracoesWhiteLabel',
    components: {
        ConfiguracoesExclusivas,
        ConfiguracaoTitulo,
        ConfiguracaoSalvar,
        WhiteLabelEmailColors,
        WhiteLabelPubColors
    },
    data() {
        return {
            logo: {
                base64: '',
                base64Salva: '',
                file: new File([],'')
            },
            whiteLabelCoresDefaulf: {
                primary: this.ehEfcazCLM ? '#153C5C' : '#604A99',
                secondary: '#ffffff'
            },
            whiteLabelSalvo: {},
            whiteLabel: {
                ativo: null,
                logoEmail: null,
                pub: {
                    primary: {color: ''},
                    secondary: {color: ''},
                },
                email: {
                    primary: {color: ''},
                    secondary: {color: ''},
                }
            },
            repository: `repo1:efcaz/clm/organizacoes`
        }
    },
    async mounted() {
        await this.buscarConfiguracoesWhiteLabel()
    },
    computed: {
        ...mapGetters(['ehEfcazCLM']),
        ...mapState(['login', 'loki']),
        ehOrganizacaoComLogo() {
            return !!this.logo.base64
        },
        ehPlanoSemPermissaoWhiteLabel() {
            const userPermissions = this.loki.user.authorities
            return !hasPermissions(userPermissions, ['WhiteLabel.Editar'])
        },
        ehWhiteLabelAtivo() {
            return this.login.funcionalidadesOrganizacao.whiteLabel
        },
    },
    methods: {
        async buscarConfiguracoesWhiteLabel() {
            const configuracoes = await this.$store.dispatch(actionTypes.CONFIGURACAO.WHITE_LABEL.BUSCAR_ORGANIZACAO_CONFIGURACOES_WHITE_LABEL)

            this.whiteLabel = configuracoes.whiteLabel
            this.whiteLabel.alterouLogo = false
            if(configuracoes.logo === '') {
                this.mostrarNotificacaoErro('Erro ao carregar logo')
            }
            if (configuracoes.logo) {
                this.logo.base64 = 'data:image/png;base64,' + configuracoes.logo
            }

            this.inicializarCores(this.whiteLabel.pub)
            this.inicializarCores(this.whiteLabel.email)

            this.whiteLabelSalvo = JSON.parse(JSON.stringify(this.whiteLabel))
            this.logo.base64Salva = _.cloneDeep(this.logo.base64)
        },
        cancelarAlteracao() {
            this.whiteLabel = JSON.parse(JSON.stringify(this.whiteLabelSalvo))
        },
        construirRequisicao() {
            const arquivo = new FormData()
            arquivo.append('arquivo', this.logo.file)
            arquivo.append('input', JSON.stringify(this.whiteLabel))
            return arquivo
        },
        convertJPEGtoPNG(jpgFile) {
            return new Promise((resolve, reject) => {
                const img = new Image()
                img.onload = () => {
                    const canvas = document.createElement('canvas')
                    canvas.width = img.width
                    canvas.height = img.height
                    const ctx = canvas.getContext('2d')
                    ctx.drawImage(img, 0, 0)
                    canvas.toBlob((blob) => {
                        const pngFile = new File([blob], jpgFile.name.replace(/\.[^/.]+$/, ".png"), { type: "image/png" })
                        resolve(pngFile)
                    }, 'image/png')
                }
                img.onerror = (error) => { reject(error) }
                img.src = URL.createObjectURL(jpgFile)
            })
        },
        diferencaLuminosidade(cor1, cor2) {
            let color1RGB = this.hexToRgb(cor1)
            let color2RGB = this.hexToRgb(cor2)

            let brightness1 = this.nivelLuminosidade(color1RGB)
            let brightness2 = this.nivelLuminosidade(color2RGB)

            return Math.abs(brightness1 - brightness2)
        },
        ehConfiguracoesDiferentes() {
            const keys = ['ativo', 'logoEmail', 'pub', 'email']
            const keyProperties = ['primary', 'secondary']

            const configs = keys.some(key => {
                if (key === 'ativo' || key === 'logoEmail') {
                    return this.whiteLabel[key] !== this.whiteLabelSalvo[key]
                } else {
                    return keyProperties.some(prop => {
                        return this.whiteLabel[key][prop].color !== this.whiteLabelSalvo[key][prop].color
                    })
                }
            })

            const logo = this.logo.base64 !== this.logo.base64Salva

            return configs || logo
        },
        fileToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => resolve(reader.result.split(',')[1])
                reader.onerror = error => reject(error)
            })
        },
        hexToRgb(hex) {
            let hexColor = hex.replace('#', '')

            let r = parseInt(hexColor.substring(0, 2), 16)
            let g = parseInt(hexColor.substring(2, 4), 16)
            let b = parseInt(hexColor.substring(4, 6), 16)
            return [r, g, b]
        },
        inicializarCores(elemento) {
            if (!elemento.primary.color) {
                elemento.primary.color = this.whiteLabelCoresDefaulf.primary
            }
            if (!elemento.secondary.color) {
                elemento.secondary.color = this.whiteLabelCoresDefaulf.secondary
            }
        },
        limparLogo() {
            this.logo.file = new File([],'')
            this.logo.base64 = ''
            this.whiteLabel.alterouLogo = true
            this.whiteLabel.logoEmail = false
        },
        nivelLuminosidade(rgb) {
            return (0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2]) / 255
        },
        async salvar() {
            if (!this.validarCores()){
                return
            }
            try {
                await this.$store.dispatch(actionTypes.CONFIGURACAO.WHITE_LABEL.ATUALIZAR_ORGANIZACAO_CONFIGURACOES_WHITE_LABEL, this.construirRequisicao())
                this.whiteLabelSalvo = JSON.parse(JSON.stringify(this.whiteLabel))
                this.logo.base64Salva = _.cloneDeep(this.logo.base64)
                this.mostrarNotificacaoSucessoDefault()
            } catch (e) {
                this.mostrarNotificacaoErro("Não foi possível atualizar as configurações da organização!")
            }
        },
        async uploadFiles(files) {
            this.whiteLabel.alterouLogo = true
            let file = files[0]

            if (file.type !== 'image/png') {
                await this.convertJPEGtoPNG(file)
                    .then((png) => {
                        file = png
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            }
            this.logo.file = file
            this.fileToBase64(file).then(base64String => {
                this.logo.base64 = 'data:image/png;base64,' + base64String
            })
        },
        validarCores() {
            if (this.whiteLabel.pub.primary.color === this.whiteLabel.pub.secondary.color) {
                this.mostrarNotificacaoErro("Cor primária e secundária da página de assinatura devem ser distintas")
                return false
            }

            let luminosidade = this.diferencaLuminosidade(this.whiteLabel.pub.primary.color, '#FFFFFF')
            if (luminosidade < 0.1) {
                this.mostrarNotificacaoErro("Cor primária deve ser mais escura")
                return false
            }

            luminosidade = this.diferencaLuminosidade(this.whiteLabel.pub.primary.color, this.whiteLabel.pub.secondary.color)
            if (luminosidade < 0.1) {
                this.mostrarNotificacaoErro("Cor primária e secundária da página de assinatura devem possuir um contraste maior")
                return false
            }

            return true
        }
    }
}
</script>

<style lang="stylus">
.co-configuracoes-white-label
    background-color var(--background-100) !important
    width 100%

    .title
        color var(--primary)

    .subtitle-2
        color var(--text-color-200)

    .remover
    .alerta
        margin-left 16px


@media (max-width: 720px)
    .co-configuracoes-white-label
        .selecione
            width calc(100% - 40px)

        .remover
            margin-left 0

        .alerta
            margin-top 4px
            margin-left 0
            width 100%

        .botao
            width 100%

</style>
