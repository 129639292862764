<template>
    <div class="co-posicionamento-dados">
        <div class="abas">
            <div class="titulo">Posicionar Assinaturas</div>
        </div>

        <v-container class="dados">
            <v-col class="px-3 pb-0 mb-n5">
                <dica-posicionamento-assinatura />
            </v-col>

            <v-col>
                <v-row class="itens" no-gutters v-for="signatario in signatarios" :key="signatario.index">
                    <card-posicionamento-dados
                        :dadosSignatario="signatario.dadosSignatario"
                        @criar-posicionamento-assinatura="criarPosicionamentoAssinatura"
                    />
                </v-row>
            </v-col>
        </v-container>

        <div class="mt-2 acoes">
            <v-btn color="green lighten-2" dark depressed block
                   @click="salvar"
                   class="px-5 mt-3 btn-salvar">
                Salvar
            </v-btn>
        </div>
    </div>
</template>

<script>

import DicaPosicionamentoAssinatura from '../../../../common/DicaPosicionamentoAssinatura';
import CardPosicionamentoDados from './components/CardPosicionamentoDados'
import {actionTypes} from '@/commons/constants'

export default {
    name: 'PosicionamentoDados',
    components: {CardPosicionamentoDados, DicaPosicionamentoAssinatura},
    props: {
        draggables: {
            type: Array,
            require: true
        }
    },
    data() {
        return {
            signatarios: [],
        }
    },
    mounted() {
        this.limparDraggables()
        this.buscarPosicionamentoDadoSignatariosPorMinuta()
    },
    methods: {
        salvar() {
            this.$emit('salvar-draggables')
        },
        voltar() {
            this.$emit('voltar')
        },
        async buscarPosicionamentoDadoSignatariosPorMinuta() {
            this.signatarios = await this.$store.dispatch(actionTypes.POSICIONAMENTO_DADO.BUSCAR_POR_MINUTA)
            this.signatarios.forEach(signatario => {
                signatario.posicionamentos.forEach(dadosPosicao => {
                    dadosPosicao.content = {
                        text: signatario.dadosSignatario.contato,
                        type: dadosPosicao.type,
                        typeName: dadosPosicao.typeName
                    }
                    dadosPosicao.id = dadosPosicao.id.toString()
                    this.draggables.push(dadosPosicao)
                })
            })
        },
        criarPosicionamentoAssinatura(signatario) {
            this.$emit('criar-posicionamento-assinatura', signatario)
        },
        limparDraggables() {
            this.$emit('limpar-draggables')
        }
    }
}
</script>

<style lang="stylus">
.co-posicionamento-dados
    display block
    text-align center
    overflow-x hidden
    overflow-y auto
    height: 100%

    .dados
        background var(--background-100) !important
        width 100%
        overflow-x hidden
        overflow-y auto
        height calc(100% - 123px)

    .abas
        display flex
        justify-content space-between
        height 50px
        padding 0 20px
        vertical-align center
        border-bottom 1px solid #ccc

    .titulo
        display flex
        align-items center
        color #777
        font-size 14px
        text-transform uppercase

    .acoes
        width 100%
        height 60px

    .btn-salvar
        width 100%
        height 100% !important


@media (max-width: 600px)
    .co-posicionamento-dados
        .acoes
            justify-content center
</style>
