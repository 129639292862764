<template>
    <v-dialog v-model="dialog" width="600" persistent>
        <v-card class="co-clm-ops-imagem">
            <v-card-title class="titulo justify-center">
                <span :style="tituloColor">{{ titulo }}</span>
            </v-card-title>

            <v-card-text>
                <clm-ops-imagem :sentimento="sentimento"/>

                <div id="subtitulo" class="subtitulo"></div>
            </v-card-text>

            <v-card-actions class="acoes justify-center">
                <div class="secundaria" v-if="!apenasAcaoPrincipal">
                    <v-btn depressed @click="fechar">Não, obrigado</v-btn>
                </div>

                <div class="principal">
                    <v-btn color="primary" @click="acaoPrincipal" :loading="loading">{{ labelBotaoPrincipal }}</v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ClmOpsImagem from '@/commons/components/ClmOpsImagem'
import {mapGetters} from 'vuex'

export default {
    name: 'DialogDefaultConfirmarAcao',
    components: {ClmOpsImagem},
    props: {
        dialog: {
            type: Boolean
        },
        labelBotaoPrincipal: {
            type: String
        },
        sentimento: {
            type: String,
            validator: val => ['feliz', 'triste', 'assustado', 'valido', 'atencao', 'erro'].includes(val),
            required: true
        },
        subtitulo: {
            type: String
        },
        titulo: {
            type: String
        },
        apenasAcaoPrincipal: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters(['ehEfcazCLM']),
        tituloColor() {
            if(this.ehEfcazCLM) {
                return 'color: var(--primary)';
            } else {
                switch(this.sentimento) {
                    case "valido":
                        return 'color: var(--success-500)';
                    case "erro":
                        return 'color: var(--error-500)';
                    case "atencao":
                        return 'color: var(--warning-500)';
                    default:
                        return 'color: var(--primary)';
                }
            }
        }
    },
    mounted() {
        this.adicionarSubtitulo()
    },
    methods: {
        acaoPrincipal() {
            this.$emit('acao-principal')
        },
        adicionarSubtitulo() {
            const div = document.createElement('div');
            div.innerHTML = this.subtitulo
            document.getElementById('subtitulo').appendChild(div)
        },
        fechar() {
            this.$emit('fechar')
        }
    }
}
</script>


<style lang="stylus">
.co-clm-ops-imagem
    text-align center
    display block
    padding-bottom 15px
    background-color white

    .titulo
        font-size 18px
        font-weight bold
        color #777

    .subtitulo
        font-size 14px
        color #777
        margin-right 10%
        margin-left 10%

        .destaque
            color orange
            font-weight bold
            text-decoration none

    .secundaria
        margin-right 30px

@media (max-width: 600px)
    .co-clm-ops-imagem
        .acoes
            flex-direction column-reverse
        .secundaria
            margin 20px 0 0 0
</style>