<template>
    <div v-if="false" class="co-enviar-token-sms">
        <div class="checkbox-enviar-token" :style="getStyleCheckbox" @click="alterarValue">

            <input v-model="value.enviarTokenSMS"
                   :disabled="desabilitar"
                   :style="getStyleCheckbox"
                   name="enviarTokenSMS"
                   type="checkbox"/>

            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <span v-on="on" class="checkbox-texto">Enviar token por SMS</span>
                </template>
                <span v-if="!smsHabilitado">É necessário contratar o serviço de SMS, entre <br/> em contato com o suporte pelo chat</span>
                <span v-else>Restam: {{ saldoSms }}</span>
            </v-tooltip>
        </div>

        <div class="hint-checkbox">
            {{ descricaoCampo }}
        </div>

        <label-adquirir-saldo v-if="smsHabilitado" :saldo="saldoSms" exibir @adquirir-saldo="abrirLoja"/>

        <contratar-servico v-else @abrir-dialog-contratar-servico="abrirDialogContratarServico"/>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import LabelAdquirirSaldo
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/LabelAdquirirSaldo'
import ContratarServico
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/ContratarServico'

export default {
    name: 'ConvidarPorSms',
    components: {
        ContratarServico,
        LabelAdquirirSaldo
    },
    props: {
        value: {
            required: true
        },
        desabilitarCampo: {
            type: Boolean
        }
    },
    watch: {
        'value.enviarTokenSMS': function alterandoValorStateSms() {
            if (this.value.enviarTokenSMS) {
                this.consumoOrganizacao.smsUtilizados += 1
            } else {
                this.consumoOrganizacao.smsUtilizados -= 1
            }
        }
    },
    computed: {
        ...mapState(['login', 'consumoOrganizacao']),
        ...mapGetters(['saldoSms']),
        desabilitar() {
            return (!this.possuiSMS && !this.checkBoxMarcado) || (this.desabilitarCampo && this.ehSignatarioConvidado)
        },
        descricaoCampo() {
            return `Para concluir o processo de assinatura do tipo eletrônica o sistema emite um Token, que selecionando esta opção poderá enviar ao telefone cadastrado do ${this.$tc("pt_br.signatario").toLowerCase()}`
        },
        ehSignatarioConvidado() {
            return this.value.statusSignatario === 'CONVIDADO'
        },
        possuiSMS() {
            return this.smsHabilitado && this.saldoDisponivel
        },
        smsHabilitado() {
            return this.login.funcionalidadesOrganizacao.enviarTokenAssinaturaSMS
        },
        checkBoxMarcado() {
            return this.value.enviarTokenSMS
        },
        saldoDisponivel() {
            return this.saldoSms > 0
        },
        getStyleCheckbox() {
            return !this.desabilitar ? 'cursor: pointer' : 'cursor: not-allowed;'
        }
    },
    methods: {
        abrirLoja() {
            this.$router.push({name: 'planos'})
        },
        abrirDialogContratarServico() {
            this.$emit('abrir-dialog-contratar-servico')
        },
        alterarValue() {
            if (!this.desabilitar)
                this.value.enviarTokenSMS = !this.value.enviarTokenSMS
        }
    }
}
</script>

<style lang="stylus">
.co-enviar-token-sms
    .checkbox-enviar-token
        display flex
        align-items center

        .checkbox-texto
            font-size 14px
            display flex
            margin-left 10px
            color #777777
            text-align right
</style>