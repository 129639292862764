<template>
    <form class="co-formulario-itens-contrato">
        <descricao v-model="item"/>
        <quantidade v-model="item"/>
        <valor-unitario v-model="item"/>
        <valor-total v-model="valorTotal"/>
        <botoes-itens @adicionar="adicionar" @cancelar="cancelar"/>
    </form>
</template>

<script>
import Descricao from '../campos-formulario/Descricao'
import Quantidade from '../campos-formulario/Quantidade'
import ValorUnitario from '../campos-formulario/ValorUnitario'
import ValorTotal from '../campos-formulario/ValorTotal'
import BotoesItens from './BotoesItens'

export default {
    name: 'FormularioItem',
    components: {BotoesItens, ValorTotal, ValorUnitario, Quantidade, Descricao},
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        valorTotal() {
            return this.item.valor === undefined || this.item.quantidade === undefined ? 0.00.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                }) :
                (Number(this.item.valor) * Number(this.item.quantidade)).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                })
        }
    },
    methods: {
        adicionar() {
            this.$emit('inserir', this.item)
        },
        cancelar() {
            this.$emit('cancelar')
        }
    }
}
</script>

<style lang="stylus">
.co-formulario-itens-contrato
    padding 20px

    .acoes
        display: flex
        justify-content: space-between
        margin-top: 20px
</style>