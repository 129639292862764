<template>
    <div class="co-compras-tabela">
        <v-data-table
            class="tabela-compras"
            hide-default-footer
            :no-data-text="'Nenhuma compra encontrada.'"
            :headers="headers"
            :items-per-page="itemsPerPage"
            :items="itens">
            <template v-slot:item.itens="{ item }">
                <ul class="listProdutos">
                    <li v-for="item in item.adicionais" :key="item.id">
                        <span class="itensComprados"> 
                            {{ item.tipoAdicionalCompra.replaceAll('_', ' ') }} {{ tipoSelecionado === "Cobranças de Assinaturas" ? '' : `(${item.quantidade})` }}
                        </span>
                    </li>
                </ul>
            </template>

            <template v-slot:item.data="{ item }">
                {{ formatHora(item.data) }}
            </template>

            <template v-slot:item.valorTotal="{ item }">
                {{ formatReal(item.valorTotal) }}
            </template>

            <template v-slot:item.status="{ item }">
                <v-layout class="acoes-tabela">
                    <span class="status d-flex align-center" :style="getStyleConteudo(item.status)">{{ item.status }}</span>

                    <v-btn v-if="ehCompraPendente(item.status) || ehCobrancaExpirada(item.status)"
                           class="ml-2"
                           color="green white--text"
                           depressed small
                           @click="abrirLink(item.linkPagamento)">
                        PAGAR <v-icon small class="ml-2">mdi-open-in-new</v-icon>
                    </v-btn>
                </v-layout>
            </template>

        </v-data-table>
    </div>
</template>

<script>

import DateTimeUtils from '@/commons/utils/DateTimeUtils';

export default {
    name: 'ComprasTabela',
    props: {
        itens: {
            type: Array | undefined
        },
        itemsPerPage: {
            type: Number
        },
        tipoSelecionado: {
            type: String
        }
    },
    data() {
        return {
            headers: [
                {
                    text: 'Data',
                    align: 'left',
                    sortable: false,
                    filterable: false,
                    value: 'data',
                    divider: true
                },
                {
                    text: 'Descrição',
                    align: 'left',
                    sortable: false,
                    filterable: false,
                    value: 'itens',
                    divider: true
                },
                {
                    text: 'Forma de Pagamento',
                    align: 'left',
                    sortable: false,
                    filterable: false,
                    value: 'formaPagamento',
                    width: '15%',
                    divider: true
                },
                {
                    text: 'Total',
                    align: 'left',
                    sortable: false,
                    filterable: false,
                    value: 'valorTotal',
                    width: '15%',
                    divider: true
                },
                {
                    text: 'Situação',
                    align: 'left',
                    value: 'status',
                    sortable: false,
                    filterable: false,
                    width: '20%',
                    divider: true
                }
            ]
        }
    },
    methods: {
        abrirLink(link) {
            window.open(link, '_blank')
        },
        ehCompraPendente(statusCompra) {
            return statusCompra === 'PENDENTE'
        },
        ehCobrancaExpirada(statusCobranca) {
            return statusCobranca === "EXPIRADO"
        },
        formatReal(valor) {
            return valor.toLocaleString('pt-br', {style: 'currency', currency: 'BRL', minimumFractionDigits: 2})
        },
        formatHora(valor) {
            if (!valor) return ''

            return DateTimeUtils.converterDataHoraLocal(new Date(valor)).replaceAll(',', '');
        },
        getStyleConteudo(status) {
            if (this.ehCompraPendente(status)) {
                return 'color: red'
            } else if (status === 'PAGO'){
                return 'color: #4CAF50'
            } else if (this.ehCobrancaExpirada(status)) {
                return 'color: #b1b144'
            }
        },
    }
}
</script>

<style lang="stylus">
.co-compras-tabela
    width 100%

    .v-input--selection-controls.v-input .v-label
        font-size 16px

    .tabela-compras
        color var(--text-color-200)

        .status
            width 65px

        .listProdutos
            list-style-type none
            padding 0

        thead th
            border-bottom thin solid rgba(0,0,0,.12);

        tbody td
            height 40px !important
            font-size 13px !important
            border-bottom thin solid rgba(0,0,0,.12);

@media (max-width: 720px)
    .co-compras-tabela
        .tabela-compras
            .acoes-tabela
                justify-content right

            thead
                display none !important

            tbody tr
                border-bottom 1px solid var(--v-primary-lighten1) !important

                td
                    display block !important
                    text-align left !important
                    padding 5px 10px !important
                    max-height unset !important
                    height unset !important
                    justify-items left
</style>