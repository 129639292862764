<template>
    <div class="co-ops" v-if="ehEfcazCLM">
        <img v-if="sentimento === 'feliz' || sentimento === 'valido'" src="@/assets/ops/feliz.gif">
        <img v-if="sentimento === 'triste' || sentimento === 'atencao'" src="@/assets/ops/triste.gif">
        <img v-if="sentimento === 'assustado' || sentimento === 'erro'" src="@/assets/ops/assustado.gif">
    </div>

    <div class="co-ops" v-else>
        <img v-if="sentimento === 'feliz'" src="@/assets/ops-besign/feliz.svg">
        <img v-if="sentimento === 'triste'" src="@/assets/ops-besign/triste.svg">
        <img v-if="sentimento === 'assustado'" src="@/assets/ops-besign/assustado.svg">
        <img v-if="sentimento === 'valido'" src="@/assets/ops-besign/valido.svg">
        <img v-if="sentimento === 'atencao'" src="@/assets/ops-besign/atencao.svg">
        <img v-if="sentimento === 'erro'" src="@/assets/ops-besign/erro.svg">
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'ClmOpsImagem',
    props: {
        sentimento: {
            type: String,
            validator: val => ['feliz', 'triste', 'assustado', 'valido', 'atencao', 'erro'].includes(val)
        }
    },
    computed: {
        ...mapGetters(['ehEfcazCLM']),
    }
}
</script>
