<template>
    <v-dialog
        v-model="mostrarDialog"
        persistent width="500px">
        <v-card class="co-dialog-falha-signatario-whatsapp">

            <v-card-title class="primary white--text">
                <span>Falha ao enviar assinatura!</span>
                <v-spacer></v-spacer>
                <v-icon @click="fechar" color="white"> mdi-window-close </v-icon>
            </v-card-title>

            <v-card-text>
                <p>Ocorreu um problema ao tentar enviar o documento ao {{ $tc("pt_br.signatario").toLowerCase() }} via Whatsapp. Verifique as informações na próxima tela e tente novamente</p>
            </v-card-text>

            <v-card-actions class="actions">
                <v-btn class="mr-2 elevation-0" color="green" dark @click="fechar">OK</v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: 'dialog-falha-signatario-whatsapp',
    props: {
        mostrarDialog: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        fechar() {
            this.$emit('fechar')
        }
    }
}
</script>

<style lang="stylus">
.co-dialog-falha-signatario-whatsapp
    .bold
        font-weight bold

    .actions
        justify-content center

</style>