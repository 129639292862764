<template>
    <v-form class="co-formulario-obrigatorio-assinatura-whatsapp">
        <representante-legal
            :desabilitar-campo="naoPodeAtualizar"
            v-model="novoSignatario"
            v-if="signatarioPaganate"
        />
        <telefone-signatario
            v-model="novoSignatario"
            :desabilitar-campo="naoPodeAtualizar"
            campo-requerido
            descricao
        />
        <documento-signatario
            v-model="novoSignatario"
            :documentos="documentosSignatario"
            campo-requerido
            descricao
        />
        <papel-signatario
            v-model="novoSignatario"
            :papeis="papeisSiganatario"
            campo-requerido
            descricao
        />
    </v-form>
</template>

<script>
import DocumentoSignatario from '../campos-formulario/DocumentoSignatario'
import PapelSignatario from '../campos-formulario/PapelSignatario'
import TelefoneSignatario from '@/commons/components/text-field/TelefoneSignatario'
import RepresentanteLegal
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/RepresentanteLegal'

export default {
    name: 'FormularioObrigatorioAssinaturaWhatsapp',
    components: {RepresentanteLegal, DocumentoSignatario, PapelSignatario, TelefoneSignatario},
    props: {
        documentosSignatario: {
            type: Array,
            required: true
        },
        naoPodeAtualizar: {
            type: Boolean
        },
        novoSignatario: {
            type: Object,
            required: true,
        },
        papeisSiganatario: {
            type: Array,
            required: true
        },
        signatarioPaganate: {
            type: Boolean
        }
    }
}
</script>