import _ from 'lodash'

export default {
    caminhoVisualizarMinuta(state) {
        if (state.minutaAtual.situacaoFormalizacao === 'RASCUNHO' || state.minutaAtual.situacaoFormalizacao === 'FORMALIZADO' && state.minutaAtual.contratoAssinado) {
            return `api/contratos/${state.contrato.id}/minutas/${state.minutaAtual.id}/original`
        } else {
            return `api/contratos/${state.contrato.id}/minutas/${state.minutaAtual.id}/impressao`
        }
    },
    carregando(state) {
        return state.loki.isLoading
    },
    ehAdministradorOrganizacao(state) {
        const usuarioLogadoId = state.loki.user.id
        const administradoresId = state.login.organizacaoPlano.administradoresId
        return _.find(administradoresId, (administradorId) => administradorId === usuarioLogadoId) !== undefined
    },
    ehEfcazCLM(state) {
        return state.loki.product.efcazCLM
    },
    ehOrganizacaoSemAssinatura(state) {
        return state.consumoOrganizacao.organizacaoSemAssinatura
    },
    ehPastaRaiz(state) {
        return state.pastaAtual == null || state.pastaAtual.pastaId == null
    },
    ehPlanoDocIlimitado(state) {
        return state.consumoOrganizacao.documentosIlimitados ? state.consumoOrganizacao.documentosIlimitados : false
    },
    ehPlanoFree(state) {
        return state.login.organizacaoPlano.nomePlanoContratado === 'FREE'
    },
    ehPlanoApi(state) {
        return state.login.organizacaoPlano.nomePlanoContratado.includes('API_')
    },
    ehPlanoAnual(state) {
        return state.login.organizacaoPlano.nomePlanoContratado.endsWith('ANUAL') || state.login.organizacaoPlano.recorrenciaDoPlano === 'ANUAL'
    },
    ehPlanoWhatsApp(state) {
        return state.login.organizacaoPlano.nomePlanoContratado.includes('WHATSAPP')
    },
    ehPlanoLight(state) {
        return state.login.organizacaoPlano.nomePlanoContratado.includes('LIGHT')
    },
    ehPlanoPersonalizado(state) {
        return state.login.organizacaoPlano.nomePlanoContratado === 'PERSONALIZADO'
    },
    ehPlanoPro(state) {
        return state.login.organizacaoPlano.nomePlanoContratado.includes('PRO')
    },
    ehPlanoStarter(state) {
        return state.login.organizacaoPlano.nomePlanoContratado.includes('STARTER')
    },
    ehPlanoBasic(state) {
        return state.login.organizacaoPlano.nomePlanoContratado === 'BASIC'
    },
    ehPlanoEmCancelamento(state) {
        return state.login.organizacaoPlano.statusAssinatura === "CANCELADA" && state.consumoOrganizacao.dataExpiracaoPlano !== null
    },
    ehPlanoExpirado(state) {
        return state.login.organizacaoPlano.statusAssinatura === "EXPIRADA" && state.consumoOrganizacao.dataExpiracaoPlano !== null
    },
    ehSignatarioPagante(state) {
        return state.login.funcionalidadesOrganizacao.signatarioPagante
    },
    existeContratos(state) {
        return !!state.meusContratos && state.meusContratos.length > 0
    },
    existePastas(state) {
        return !!state.subPastasAtual && state.subPastasAtual.length > 0
    },
    mobile(state) {
        return state.mobile
    },
    nomeCookieUsuario(state) {
        return `efcazclm-${state.loki.user.id}`
    },
    nomePlano(state) {
        return state.login.organizacaoPlano.nomePlano
    },
    nomePlanoContratado(state) {
        return state.login.organizacaoPlano.nomePlanoContratado
    },
    podeFormalizarContratoAuto(state) {
        return state.login.organizacaoConfiguracao.geral.documento.formalizarDocumentosAuto
    },
    podeCriarContratoRaiz(state) {
        return state.login.organizacaoConfiguracao.geral.documento.documentosNaRaiz
    },
    podeRepetirSignatarios(state) {
        return state.login.organizacaoConfiguracao.geral.documento.signatariosRepetidos
    },
    possuiRepresentanteLegal(state) {
        let signatario = state.signatarios.filter(signatario => signatario.representanteLegal === true)
        return signatario.length > 0
    },
    quantidadeParcelasMaximas(state) {
        return state.parametrosSistema['az.efcaz.clm.plano.quantidade-parcelas-default']
    },
    saldoSms(state) {
        return state.consumoOrganizacao.totalSMS - state.consumoOrganizacao.smsUtilizados
    },
    saldoWhatsapp(state) {
        return state.consumoOrganizacao.totalWhatsapp - state.consumoOrganizacao.whatsappUtilizados
    }
}
