var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"co-usuarios-tabela"},[_c('v-data-table',{staticClass:"tabela-usuarios",attrs:{"hide-default-footer":"","no-data-text":_vm.noDataText,"items-per-page":_vm.qtdLinhasTabela,"headers":_vm.headers,"items":_vm.usuarios},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("azTitleCase")(item.usuarioSituacao))+" ")]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{staticClass:"acoes-tabela"},[(!_vm.ehUsuarioLogado(item))?[(_vm.ehUsuarioAtivo(item.usuarioSituacao))?[_c('v-tooltip',{directives:[{name:"az-auth",rawName:"v-az-auth",value:('Usuario.Editar'),expression:"'Usuario.Editar'"}],attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"15px"},on:{"click":function($event){return _vm.$emit('editar', item)}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar")])])]:_vm._e(),(_vm.ehUsuarioAtivo(item.usuarioSituacao))?[_c('v-tooltip',{directives:[{name:"az-auth",rawName:"v-az-auth",value:('Usuario.AtivarInativar'),expression:"'Usuario.AtivarInativar'"}],attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"15px"},on:{"click":function($event){return _vm.$emit('inativar', item)}}},on),[_vm._v(" mdi-cancel ")])]}}],null,true)},[_c('span',[_vm._v("Inativar")])])]:[_c('v-tooltip',{directives:[{name:"az-auth",rawName:"v-az-auth",value:('Usuario.AtivarInativar'),expression:"'Usuario.AtivarInativar'"}],attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"15px"},on:{"click":function($event){return _vm.$emit('ativar', item)}}},on),[_vm._v(" mdi-check ")])]}}],null,true)},[_c('span',[_vm._v("Ativar")])])]]:_vm._e(),(_vm.ehUsuarioAtivo(item.usuarioSituacao))?[_c('v-tooltip',{directives:[{name:"az-auth",rawName:"v-az-auth",value:('Usuario.Editar'),expression:"'Usuario.Editar'"}],attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"15px"},on:{"click":function($event){return _vm.$emit('token', item)}}},on),[_vm._v(" mdi-key ")])]}}],null,true)},[_c('span',[_vm._v("Token Api")])])]:_vm._e()],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }