import actions from './actions'
import cadastroSignatariosActions from './cadastroSignatariosActions'
import comprasActions from './comprasActions'
import cobrancasActions from './cobrancasActions'
import configuracaoActions from './configuracaoActions'
import contratoActions from './contratoActions'
import emailActions from './emailActions'
import feedbackActions from './feedbackActions'
import grupoSignatariosActions from './grupoSignatariosActions'
import webhookActions from './webhookActions'
import itemActions from './itemActions'
import loginActions from './loginActions'
import organizacaoActions from './organizacaoActions'
import pastasActions from './pastasActions'
import planosActions from './planosActions'
import posicionamentoDadoActions from './posicionamentoDadoActions'
import relatorioActions from './relatorioActions'
import signatarioActions from './signatarioActions'
import usuarioActions from './usuarioActions'
import whiteLabelActions from './whiteLabelActions'

export default {
    ...actions,
    ...cadastroSignatariosActions,
    ...comprasActions,
    ...cobrancasActions,
    ...configuracaoActions,
    ...contratoActions,
    ...emailActions,
    ...feedbackActions,
    ...grupoSignatariosActions,
    ...itemActions,
    ...loginActions,
    ...organizacaoActions,
    ...pastasActions,
    ...planosActions,
    ...posicionamentoDadoActions,
    ...relatorioActions,
    ...signatarioActions,
    ...usuarioActions,
    ...webhookActions,
    ...whiteLabelActions
}
