<template>
    <v-dialog
        fullscreen
        hide-overlay
        persistent
        scrollable
        transition="dialog-bottom-transition"
        value="true">
        <v-card color="rgb(238, 238, 238)" tile class="co-primeiro-acesso">
            <v-toolbar color="primary" dark fixed flat class="bar">
                <div v-show="ehPrimeiroAcesso()" :class="celular ? '' : 'bar-item'">
                    <div class="bem-vindo">{{ mensagemBemVindo }}</div>
                </div>

                <v-toolbar-title :class="celular ? '' : 'bar-item'">
                    <img :src="loki.product.mainLogo">
                </v-toolbar-title>

                <div :class="celular ? '' : 'bar-item sair'">
                    <v-btn icon @click="logout">
                        <v-icon> mdi-exit-to-app </v-icon>
                    </v-btn>
                </div>

            </v-toolbar>

            <div class="dados">
                <dados-organizacao
                    :organizacaoValue="organizacao"
                    :disabled="botaoNovoDesabilitado"
                    @salvar="cadastrarOrganizacao"
                />
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import {actionTypes} from '@/commons/constants'
import DadosOrganizacao from '../../common/DadosOrganizacao'

export default {
    name: 'nova-organizacao',
    components: {DadosOrganizacao},
    data() {
        return {
            botaoNovoDesabilitado: false,
            organizacao: {
                nome: '',
                tipoPessoa: 'FISICA',
                cnpjCPF: '',
                email: '',
                telefone: '',
                cep: null,
                endereco: null,
                estadoId: null,
                municipioId: null,
                recebeEmails: true,
                emailMarketing: true
            }
        }
    },
    computed: {
        ...mapState(['loki']),
        ...mapGetters(['ehEfcazCLM']),
        celular() {
            return this.$vuetify.breakpoint.xs
        },
        mensagemBemVindo() {
            if (this.$vuetify.breakpoint.xs) {
                return ""
            }
            if (this.ehEfcazCLM) {
                return "Bem vindo ao Efcaz!"
            } else {
                return "Bem vindo ao BeSign!"
            }
        }
    },
    async mounted() {
        if (this.ehPrimeiroAcesso()) {
            await this.buscarDadosOrganizacaoByUsuarioLogado();
        }
    },
    methods: {
        async buscarDadosOrganizacaoByUsuarioLogado() {
            const dadosOrganizacao = await this.$store.dispatch(actionTypes.ORGANIZACAO.BUSCAR_DADOS_ORGANIZACAO_PRIMEIRO_ACESSO)
            this.organizacao.nome = dadosOrganizacao.nome
            this.organizacao.cnpjCPF = dadosOrganizacao.cpf
            this.organizacao.email = dadosOrganizacao.email
            this.organizacao.telefone = dadosOrganizacao.telefone
        },
        async cadastrarOrganizacao() {
            try {
                this.botaoNovoDesabilitado = true
                await this.$store.dispatch(actionTypes.INSERIR_ORGANIZACAO, this.organizacao)
                await this.$store.dispatch(actionTypes.LOGIN.BUSCAR_ORGANIZACAO_PLANO)
                await this.$store.dispatch(actionTypes.CONFIGURACAO.BUSCAR_ORGANIZACAO_CONFIGURACOES)
                await this.$router.push({name: 'meus'})
            } catch (e) {
                this.botaoNovoDesabilitado = false
            }
        },
        logout() {
            window.location.assign('/efcaz-clm/hal/logout')
        },
        ehPrimeiroAcesso() {
            return !this.$store.state.login.organizacaoPlano.organizacaoExistente
        },
    }
}
</script>

<style lang="stylus">
.co-primeiro-acesso

    .v-toolbar__content
        display flex
        justify-content space-between

    .bar-item
        width 250px

    .sair
        display flex !important
        justify-content end !important


    .bem-vindo
        font-size 20px

    .dados
        height 100% !important
        overflow-y auto !important
        padding-top 20px

</style>