<template>
    <div class="acoes-listagem-itens">
        <v-btn dark depressed color="white" class="grey--text" @click="cancelar">CANCELAR</v-btn>
        <v-btn dark depressed color="green lighten-3" @click="adicionar">ADICIONAR</v-btn>
    </div>
</template>

<script>
export default {
    name: 'BotoesItens',
    methods: {
        adicionar() {
            this.$emit('adicionar')
        },
        cancelar() {
            this.$emit('cancelar')
        }
    }
}
</script>

<style lang="stylus">
.acoes-listagem-itens
    display flex
    justify-content center

    i
        font-size 24px
</style>