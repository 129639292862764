import Vue from 'vue'
import Vuetify from 'vuetify'
import pt from 'vuetify/lib/locale/pt'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    lang: {
        locales: {pt},
        current: 'pt'
    },
    theme: {
        themes: {
            light: {
                primary: '#153c5c',
                secondary: '#ed8732',
                accent: '#604a99',
                'secondary-100': '#CACED7',
                'secondary-200': '#959CAE',
                'secondary-300': '#888E9E',
                'secondary-400': '#6A6F7C',
                'secondary-500': '#525660',
                'text-color-100': '#BBBBBB',
                'text-color-200': '#9A9A9A',
                'text-color-300': '#6C6C6C',
                'text-color-400': '#313131',
                'text-color-500': '#232323',
                'error-100': '#FAB0B0',
                'error-200': '#F88A8A',
                'error-300': '#F45454',
                'error-400': '#F23333',
                'error-500': '#EF0000',
                'warning-100': '#FFCCB0',
                'warning-200': '#FF9254',
                'warning-300': '#FF9254',
                'warning-400': '#FF7D33',
                'warning-500': '#FF5C00',
                'success-100': '#CAEBB0',
                'success-200': '#B1E28A',
                'success-300': '#8DD554',
                'success-400': '#77CD33',
                'success-500': '#55C000',
                'background-100': '#FFFFFF',
                'background-200': '#F5F6FA',
                'background-300': '#EFF0F5',
                'background-400': '#F1F4F7',
                'divider-100' : '#EFF0F5',
                'botao-background': '#153C5C',
                'tabs-100': '#F0F0F0'
            },
            dark: {
                primary: '#153c5c',
                secondary: '#ed8732'
            }
        },
        options: {
            customProperties: true
        }
    },
})

