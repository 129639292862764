<template>
    <div class="co-sancao">
        <funcionalidade-exclusiva :eh-sem-funcionalidade="ehSemFuncionalidade"/>

        <v-divider/>

        <div class="abas">
            <div class="titulo">Sanções</div>

            <a v-if="!contrato.extinto && !ehSemFuncionalidade" v-az-auth="'Contrato.Sancao.Novo'" class="abas-novo"
               @click="abrirModalNovaSancao">
                <v-icon color="green" size="20px"> mdi-plus-circle</v-icon>
            </a>
        </div>

        <sancao
            v-for="sancao in sancoes"
            :sancao="sancao"
            :key="sancao.sancaoId"
            :eh-sem-funcionalidade="ehSemFuncionalidade"
            @remover="confirmarRemocaoSancao"
            @visualizar="abrirVisualizacao"
        />

        <nova-sancao
            v-if="abrirModalSancao"
            v-model="sancao"
            :visualizandoSancao="visualizando"
            @cancelar="fecharModalInsercao"
            @inserir="inserirSancao"
            @upload="preencherReferenciaArquivo"
            @clean="limparArquivo"
        />

        <clm-dialog v-model="dialogRemoverSancao">
            <clm-ops sentimento="assustado">
                <div slot="titulo">Eita! Deixa eu confirmar uma coisa...</div>
                <div slot="subtitulo">Você quer mesmo excluir a sanção?</div>
                <div slot="acoes">
                    <div class="principal">
                        <a @click="remover">Sim, eu quero excluir!</a>
                    </div>
                    <div class="secundaria">
                        <a @click="fecharDialogRemocao">Não, obrigado</a>
                    </div>
                </div>
            </clm-ops>
        </clm-dialog>

    </div>
</template>

<script>
import {mapState} from 'vuex'
import {actionTypes} from '@/commons/constants'
import ClmDialog from '@/commons/components/ClmDialog'
import ClmOps from '@/commons/components/ClmOps'
import Sancao from './Sancao'
import NovaSancao from './NovaSancao'
import FuncionalidadeExclusiva from '../../../../visualizar-contrato/barra-lateral/components/FuncionanlidadeExclusiva'

export default {
    components: {FuncionalidadeExclusiva, ClmDialog, ClmOps, Sancao, NovaSancao},
    name: 'ListaSancao',
    data() {
        return {
            arquivoSancao: {},
            sancao: {},
            sancoes: [],
            abrirModalSancao: false,
            sancaoRemocao: {},
            dialogRemoverSancao: false,
            visualizando: false
        }
    },
    computed: {
        ...mapState(['login', 'contrato', 'minutaAtual']),
        ehSemFuncionalidade() {
            return !this.login.funcionalidadesOrganizacao.sancao
        }
    },
    watch: {
        async 'minutaAtual'() {
            if (this.minutaAtual.situacaoProcessamento !== 'EM_PROCESSAMENTO') {
                await this.atualizarListagemSancoes()
            }
        }
    },
    async mounted() {
        if (this.minutaAtual && this.minutaAtual.id) {
            await this.atualizarListagemSancoes()
        }
    },
    methods: {
        abrirModalNovaSancao() {
            this.sancao = {
                tipo: '',
                descricao: '',
                minutaId: this.$store.state.minutaAtual.id
            }
            this.arquivoSancao = new File([''], '')
            this.abrirModalSancao = true
            this.visualizando = false
        },
        abrirVisualizacao(sancao) {
            this.abrirModalSancao = true
            this.visualizando = true
            this.sancao = sancao
        },
        async atualizarListagemSancoes() {
            this.setMensagemLoading('Carregando sanções...')
            this.sancoes = await this.$store.dispatch(actionTypes.BUSCAR_SANCOES, {
                contratoId: this.contrato.id,
                minutaId: this.minutaAtual.id
            })
        },
        fecharModalInsercao() {
            this.abrirModalSancao = false
        },
        construirRequisicao() {
            const sancao = this.sancao
            const formData = new FormData()
            formData.append('arquivo', this.arquivoSancao)
            formData.append('sancao', JSON.stringify(sancao))
            return {contratoId: this.contrato.id, minutaId: this.minutaAtual.id, sancao: formData}
        },
        confirmarRemocaoSancao(sancao) {
            this.dialogRemoverSancao = true
            this.sancaoRemocao = sancao
        },
        fecharDialogRemocao() {
            this.dialogRemoverSancao = false
        },
        async inserirSancao() {
            try {
                this.setMensagemLoading('Salvando sanção...')
                await this.$store.dispatch(actionTypes.INSERIR_SANCAO, this.construirRequisicao())
                this.fecharModalInsercao()
                await this.atualizarListagemSancoes()
                this.mostrarNotificacaoSucessoDefault()
            } catch (e) {
                await this.atualizarListagemSancoes()
            }
        },
        preencherReferenciaArquivo(arquivo) {
            this.arquivoSancao = arquivo
        },
        async remover() {
            try {
                this.setMensagemLoading('Removendo sanção...')
                await this.$store.dispatch(actionTypes.REMOVER_SANCAO, {
                    contratoId: this.contrato.id,
                    minutaId: this.minutaAtual.id,
                    sancaoId: this.sancaoRemocao.sancaoId
                })
                this.fecharDialogRemocao()
                await this.atualizarListagemSancoes()
                this.mostrarNotificacaoSucessoDefault()
            } catch (e) {
                await this.atualizarListagemSancoes()
            }
        },
        limparArquivo() {
            this.arquivoSancao = new File([''], '')
        },
    }
}
</script>

<style lang="stylus">
.co-sancao
    display block
    text-align center
    overflow-x hidden
    overflow-y auto
    height: 100%

    &__acoes
        background-color green
        color white !important
        border-radius 20px
        font-size 15px

        i
            margin-right 5px

    .abas
        display flex
        justify-content space-between
        height 50px
        padding 0 20px
        vertical-align center
        border-bottom 1px solid #ccc

        .abas-novo
            display flex !important
            align-items center !important
            color green
            font-size 12px
            font-weight bold

        .titulo
            display flex
            align-items center
            color #777
            font-size 14px
            text-transform uppercase

@media (max-width: 720px)
    .co-sancao
        height calc(100% - 90px)
</style>