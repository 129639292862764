<template>
    <v-row no-gutters class="pa-3">
        <v-col class="co-card-cartao-credito align-self-center py-3" cols="6">
            <v-radio
                :label="metodo.nome"
                :value="metodo"
                class="radio pb-0"
                color="primary lighten-1"/>

            <cartao-credito-input
                v-if="ehMetodoCartaoSelecionado"
                :dados-cartao="dadosCartao"
                class="mr-2"
            />
        </v-col>

        <cartao-credito-miniatura
            v-if="ehMetodoCartaoSelecionado"
            :dados-cartao="dadosCartao"
        />
    </v-row>
</template>

<script>
import CartaoCreditoInput from './CartaoCreditoInput'
import CartaoCreditoMiniatura from './CartaoCreditoFisico'
import {metodoPagamentoEnum} from '@/commons/constants'

export default {
    name: 'CardCartaoCredito',
    components: {CartaoCreditoInput, CartaoCreditoMiniatura},
    props: {
        dadosCartao: {
            type: Object,
        },
        metodo: {
            type: Object,
        },
        metodoSelecionado: {
            type: Object,
        }
    },
    computed: {
        ehMetodoCartaoSelecionado() {
            return this.metodoSelecionado === metodoPagamentoEnum.CARTAO_CREDITO
        }
    }
}
</script>

<style lang="stylus">
.co-card-cartao-credito
    .radio
        color #c7c7c7
        size 10px

    .titulo
        font-weight bold
        font-size 20px
</style>
