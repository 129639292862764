<template>
    <v-col class="co-documento-opcoes-avancadas">
        <v-expansion-panels flat v-model="opcoesAvancadas">
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Opções avançadas
                    <template v-slot:actions>
                        <v-icon color="primary"> mdi-chevron-down </v-icon>
                    </template>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                    <v-row no-gutters>
                        <contrato-tag-novo class="documento-tag" :value="value.tags"/>
                    </v-row>

                    <v-row no-gutters>
                        <v-combobox
                            :class="pastaRaizClasses"
                            v-model="pastaSelecionada.pastaNome"
                            item-text="pastaNome"
                            label="Pasta"
                            placeholder="Selecione"
                            :rules="pastaRaizRules()"
                            persistent-placeholder
                            clearable
                            hide-details
                            @click="abrirDialogSelecioanrPasta"
                            @click:clear="limparPastaSelecioanda"
                            :disabled="renovacao"
                        />
                    </v-row>

                    <v-row no-gutters>
                        <v-text-field
                            name-date="Limite p/ assinaturas"
                            label="Limite p/ assinaturas"
                            class="required-field mt-8"
                            v-model="value.fimAssinatura"
                            type="date"
                            :rules="[(v) => !!v || 'Informe o campo obrigatório']"
                            hide-details
                        />
                    </v-row>

                    <v-row no-gutters>
                        <v-text-field
                            name-date="Início da vigência"
                            label="Início da vigência"
                            class="required-field mt-8"
                            v-model="value.inicioVigencia"
                            type="date"
                            :rules="[(v) => !!v || 'Informe o campo obrigatório']"
                            hide-details
                            persistent-placeholder
                        />
                    </v-row>

                    <v-row no-gutters>
                        <v-text-field
                            name-date="Término da vigência"
                            label="Término da vigência"
                            :class="fimVigenciaClasses"
                            v-model="value.fimVigencia"
                            type="date"
                            :rules="terminoVigenciaRules()"
                            :disabled="desabilitarDataFim"
                            hide-details
                            persistent-placeholder
                        />
                    </v-row>

                    <v-row no-gutters>
                        <v-switch
                            class="miniSize"
                            color="primary"
                            label="Sem data de término"
                            @change="limparDataTerminoVigencia()"
                            v-model="desabilitarDataFim"
                            hide-details
                            dense inset
                        ></v-switch>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <dialog-selecionar-pasta
            v-if="dialogSelecionar"
            :dialog="dialogSelecionar"
            :pasta-selecionada="pastaSelecionada"
            @fechar="fecharDialogSelecionarPasta"
            @selecionar="selecionarPasta"
            pode-criar
        />
    </v-col>
</template>

<script>
import ContratoTagNovo from '@/views/common/ContratoTagNovo'
import {mapGetters, mapState} from 'vuex'
import moment from 'moment-timezone'
import DialogSelecionarPasta from '@/views/common/dialogs/DialogSelecionarPasta'
import Utils from '@/commons/utils/Utils'

export default {
    name: 'OpcoesAvancadas',
    components: {DialogSelecionarPasta, ContratoTagNovo},
    props: {
        value: {
            required: true
        },
        renovacao: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            dialogSelecionar: false,
            desabilitarDataFim: true,
            opcoesAvancadas: null,
            pastaSelecionada: {}
        }
    },
    computed: {
        ...mapState(['contrato', 'pastaAtual', 'login']),
        ...mapGetters(['podeCriarContratoRaiz']),
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        },
        fimVigenciaClasses() {
            let classComum = 'mt-8 date-com-check'
            return this.desabilitarDataFim ? classComum : classComum + ' required-field'
        },
        ehContrato() {
            return Utils.possuiValor(this.value.contratoId)
        },
        pastaRaizClasses() {
            return this.podeCriarContratoRaiz ? 'mt-8' : 'required-field mt-8'
        },
    },
    async mounted() {
        this.atualizarPastaAtual()
        if (!this.ehMobile) {
            this.abrirOpcoesAvancadas()
        }
    },
    watch: {
        'value.pastaId'() {
            if (this.renovacao) {
                this.atualizarPastaAtual()
            }
        }
    },
    methods: {
        abrirDialogSelecioanrPasta() {
            this.dialogSelecionar = true
        },
        abrirOpcoesAvancadas() {
            this.opcoesAvancadas = 0
        },
        atualizarPastaAtual() {
            if (this.renovacao) {
                this.pastaSelecionada.pastaId = this.contrato.pastaId
                this.pastaSelecionada.pastaNome = this.contrato.pastaNome
            } else {
                this.pastaSelecionada.pastaId = this.pastaAtual.pastaId
                this.pastaSelecionada.pastaNome = this.pastaAtual.pastaNome
            }
        },
        ehElementoNaMesmaPasta(pasta) {
            return this.pastaAtual.pastaId === pasta.pastaId
        },
        fecharDialogSelecionarPasta() {
            this.dialogSelecionar = false
        },
        limparDataTerminoVigencia() {
            if (this.desabilitarDataFim) {
                this.value.fimVigencia = null
            } else {
                this.value.fimVigencia = moment(this.value.inicioVigencia).add(7, 'days').format('YYYY-MM-DD')
            }
        },
        limparPastaSelecioanda() {
            this.pastaSelecionada = {
                pastaId: null
            }
        },
        pastaRaizRules() {
            if (this.podeCriarContratoRaiz || this.renovacao) {
                return []
            }
            return [(v) => !!v || 'Informe uma Pasta']
        },
        selecionarPasta() {
            this.value.pastaId = this.pastaSelecionada.pastaId
            if (!this.pastaSelecionada.pastaId) {
                this.limparPastaSelecioanda()
            }
            this.fecharDialogSelecionarPasta()
        },
        terminoVigenciaRules() {
            if (this.desabilitarDataFim) {
                return []
            }
            return [(v) => !!v || 'Informe o Término da Vigência']
        }
    }
}
</script>

<style lang="stylus">
.co-documento-opcoes-avancadas
    .v-expansion-panel
        background-color unset !important

        .documento-tag
            width: 100%

        .v-expansion-panel-header
            color var(--primary)
            padding 0

            .v-icon
                color var(--primary) !important

        .v-expansion-panel-content__wrap
            padding 0

    .lSize
        font-family "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif"
        font-size 13px
        cursor pointer
        user-select none

    .miniSize
        margin-top 2px

        .v-label
            font-size 12px !important

        .v-input--selection-controls
            &__input
                margin-top 6px !important

            &__input
                width 32px !important

            &__ripple
                height 24px !important
                width 24px !important

        .v-input--switch__thumb
            height 12px !important
            width 12px !important

        .v-input--switch__track
            height 16px !important
            width 38px !important
</style>