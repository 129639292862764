<template>
    <div :class="classeMenu">
        <div v-for="menuItem in itens"
             v-az-auth="menuItem.authorities"
             :key="menuItem.id"
             :class="getClasseMenuItem(menuItem)">
            <v-tooltip right color="black" class="mr-1 ml-1">
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="selecionarMenu(menuItem)">
                        {{ menuItem.icone }}
                    </v-icon>
                </template>
                <span>{{ menuItem.label }}</span>
            </v-tooltip>
            <div class="arrow-right"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MenuLateral',
    props: {
        itens: {
            type: Array,
            required: true
        },
        visivel: {
            type: Boolean,
            default: true
        },
        menuDefault: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            menuSelecionado: {}
        }
    },
    computed: {
        classeMenu() {
            return {
                'menu-lateral': true,
                'mostrar_menu': this.visivel,
                'esconder_menu': !this.visivel
            }
        }
    },
    mounted() {
        this.selecionarMenu(this.menuDefault)
    },
    methods: {
        getClasseMenuItem(menuItem) {
            return {
                'ml-2 mr-0 mt-6 mb-4': true,
                'mobile-only': menuItem.mobile === true,
                'icone-ativo': this.menuSelecionado.id === menuItem.id,
                'icone-inativo': this.menuSelecionado.id !== menuItem.id
            }
        },
        selecionarMenu(menuItem) {
            this.menuSelecionado = menuItem
            this.$emit('selecionar', this.menuSelecionado)
        }
    }
}
</script>

<style lang="stylus">
.menu-lateral
    box-shadow inset -15px 0 10px -10px var(--v-primary-darken1)
    background-color var(--primary)
    height 100%
    display block

    .icone-ativo
        display flex

        .arrow-right
            transition 0.5s
            margin-top 5px
            width 0
            height 0
            border-top 8px solid transparent
            border-bottom 8px solid transparent
            border-right 8px solid #fff

        button
            transition 0.5s
            color orange

    .icone-inativo
        display flex

        .arrow-right
            transition 0.5s
            margin-top 5px
            width 0
            height 0
            border-top 8px solid transparent
            border-bottom 8px solid transparent
            border-right 8px solid transparent

        button
            transition 0.5s
            color white
            opacity 0.3
</style>