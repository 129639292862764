<template>
    <div class="area-sem-assinaturas">
        <img height="140px" src="@/assets/ops/feliz.gif" class="mt-3">

        <div>Olá, vejo que você ainda não adicionou</div>

        <div>nenhum {{ $tc("pt_br.signatario").toLowerCase() }} ao seu {{ $tc("pt_br.documento").toLowerCase() }}.</div>

        <div v-az-auth="'Contrato.Signatario.Novo'">
            <v-btn class="mt-3" color="green lighten-3" depressed @click="adicionarSignatario">
                <span style="color: white">Adicionar {{ $tc("pt_br.signatario") }}</span>
            </v-btn>

            <div class="py-3" v-if="!ehMobile">OU</div>

            <importar-signatarios-menu
                v-if="!ehMobile"
                :abrir-dialogs="abrirDialogs">
                <template v-slot:botao-importar="{ menu: menu, tooltip: tooltip }">
                    <v-btn
                        color="green lighten-3"
                        depressed
                        v-on="{ ...tooltip, ...menu }">
                        <span style="color: white">Importar</span>
                    </v-btn>
                </template>
            </importar-signatarios-menu>

            <div class="py-3">OU</div>

            <v-btn outlined color="grey lighten-3" depressed @click="formalizar">
                <span class="formalizar-contrato" style="color: #ccc">FORMALIZAR {{ $tc("pt_br.documento").toUpperCase() }}</span>
            </v-btn>

            <div>para apenas controlar a vigência</div>
        </div>
    </div>
</template>

<script>
import ImportarSignatariosMenu
    from '@/views/criar-documento/novo-documento-signatario/components/dados-principais/components/importarSignatarios/ImportarSignatariosMenu'

export default {
    name: 'AreaSemAssinaturas',
    components: {ImportarSignatariosMenu},
    props: {
        abrirDialogs: {
            type: Object,
            require: true
        }
    },
    computed:{
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        }
    },
    methods: {
        formalizar() {
            this.$emit('formalizar')
        },
        adicionarSignatario() {
            this.$emit('adicionarSignatario')
        }
    }
}
</script>

<style lang="stylus">
.area-sem-assinaturas
    height calc(100% - 159px)
    overflow-y auto
    display block
    text-align center
    color #777

    a
        color #66BB6A

</style>
