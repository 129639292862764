<template>
    <div class="item">
        <div class="detalhes">
            <div class="nome">
                {{ descricao }}
            </div>
            <div class="dados">
                Quantidade: {{ quantidade }}
            </div>
            <div class="dados">
                Valor unitário: {{ valorUnitario | conversaoDinheiro }}
            </div>
            <div class="dados">
                Valor total: {{ valorTotal | conversaoDinheiro }}
            </div>
        </div>
        <div class="acoes">
            <acoes-item
                :acoes-itens="acoesItens"
                @editar="editar"
                @remover="remover"
            />
        </div>
    </div>
</template>

<script>
import AcoesItem from '@/views/visualizar-contrato/barra-lateral/itens/components/lista-itens/AcoesItem'

export default {
    name: 'Item',
    components: {AcoesItem},
    props: {
        descricao: {
            type: String,
            required: true
        },
        ehSemFuncionalidade: {
            type: Boolean,
            default: false
        },
        quantidade: {
            required: true
        },
        valorUnitario: {
            required: true,
        },
        valorTotal: {
            required: true,
        }
    },
    computed: {
        acoesItens() {
            let acoes = [
                {
                    nome: 'Editar',
                    acao: 'editar',
                    authorities: 'Contrato.Item.Editar'
                }
            ]
            if (!this.ehSemFuncionalidade) {
                acoes.push({
                    nome: 'Excluir',
                    acao: 'remover',
                    authorities: 'Contrato.Item.Excluir'
                })
            }

            return acoes
        }
    },
    filters: {
        conversaoDinheiro(value) {
            return value.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
        }
    },
    methods: {
        editar() {
            this.$emit('editar')
        },
        remover() {
            this.$emit('excluir')
        }

    }
}
</script>

<style lang="stylus">
.item
    display: flex
    justify-content space-between
    width 100%
    align-items center

    .detalhes
        display block
        text-align left

        .nome
            color #777
            font-size 13px
            width 250px

        .dados
            color: #bbb
            font-size: 11px

</style>