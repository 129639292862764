<template>
    <v-text-field
        label="Valor Total"
        v-model="value"
        disabled
        placeholder="0,00"
    />
</template>

<script>
export default {
    name: 'ValorTotal',
    props: ['value']
}
</script>