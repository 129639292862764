<template>
    <v-dialog v-model="ehAbrirFeedback" persistent width="700">
        <v-card class="co-feedback">
            <v-card-title class="title pt-6">
                <span class="titulo texto">Queremos a sua opinião</span>
            </v-card-title>

            <v-card-text>
                <span class="subtitle-1 titulo texto2 text-center">Nos conte como está sua experiencia com a BeSign:</span>

                <sentimento-feedback :nota="nota" @atualizar-nota="atualizarNota" :desabilitar="desabilitar" ref="notaFeedback"/>

                <span class="title titulo destaque">Sua opinião vale muito!</span>

                <div class="texto2 text-center">
                    Escreva o que você achou ou sentiu falta e receba <br/>
                    <strong class="destaque">+3 {{ ehPlanoDocIlimitado ? 'WhatsApp' : this.$tc('pt_br.documento', 2) }}</strong> adicionais na sua conta como recompensa.
                </div>

                <div class="d-flex justify-center mx-4">
                    <v-textarea
                        class="mx-4 area-texto"
                        rows="2"
                        :disabled="desabilitar"
                        placeholder="Digite aqui sua opinião"
                        counter="250"
                        v-model="detalhes"
                        v-validate="'max:250'"
                        name="detalhesFeedback"
                        type="text"
                    />
                </div>

                <v-row no-gutters class="actions">
                    <v-btn class="botao" :disabled="desabilitar" color="secondary" rounded depressed @click="enviar">
                        ENVIAR
                    </v-btn>
                </v-row>

                <v-row no-gutters class="actions">
                    <v-btn class="cancelar botao" text :disabled="desabilitar" @click="recusar">
                        Não, obrigado
                    </v-btn>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import SentimentoFeedback from '@/commons/components/dialogs/dialogFeedback/components/SentimentosFeedback'
import {actionTypes} from '@/commons/constants'

export default {
    name: 'DialogFeedback',
    components: {SentimentoFeedback},
    data() {
        return {
            dialogFeedback: true,
            nota: -1,
            detalhes: '',
            desabilitar: false
        }
    },
    computed: {
        ...mapGetters(['ehEfcazCLM', 'ehPlanoDocIlimitado']),
        ...mapState(['pesquisaFeedback']),
        ehAbrirFeedback() {
            return this.dialogFeedback
        },
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        },
    },
    methods: {
        atualizarNota(novaNota) {
            this.nota = novaNota
        },
        fechar() {
            this.dialogFeedback = false
        },
        async enviar() {
            if (!this.$refs.notaFeedback.validarNota()) {
                return
            }
            try {
                this.desabilitar = true
                await this.$store.dispatch(actionTypes.FEEDBACK.INSERIR_FEEDBACK, {
                    nota: this.nota,
                    detalhes: this.detalhes,
                    respondidoEmail: false
                })
                this.pesquisaFeedback.respondido = true
                this.mostrarNotificacaoSucesso('Obrigado pelo Feedback')
                await this.$store.dispatch(actionTypes.COMUN.BUSCAR_CONSUMO_ORGANIZACAO)
                this.fechar()
            } catch (e) {
                this.mostrarNotificacaoErro('Erro ao enviar resposta')
                this.desabilitar = false
            }
        },
        async recusar() {
            try {
                this.desabilitar = true
                await this.$store.dispatch(actionTypes.FEEDBACK.RECUSAR_FEEDBACK)
                this.pesquisaFeedback.dataIgnorado = new Date()
                this.mostrarNotificacaoSucessoDefault()
                this.fechar()
            } catch (e) {
                this.desabilitar = false
            }
        }
    }
}
</script>

<style lang="stylus">
.co-feedback
    .titulo
        display flex
        justify-content center
        width 100%

    .texto
        color var(--text-color-300)

    .area-texto
        width 80%

    .texto2
        color var(--text-color-200)

    .destaque
        color var(--primary)

    .actions
        display flex
        justify-content center
        width 100%

        .botao
            width 160px

        .secundaria
            margin-right 30px

@media (max-width: 600px)
    .co-clm-ops-imagem
        .acoes
            flex-direction column-reverse
            .secundaria
                margin 20px 0 0 0
</style>