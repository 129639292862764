<template>
    <div style="width: 100%; position: relative">
        <div :class="definirClasseBarraStatus()"/>
        <div v-if="mostrarOrdem" class="ordem-signatarios">
            <v-icon v-if="aguardandoEnvio && ehMinutaEmFormalizacaoOuRascunho || (ehAssinaturaPresencial && aguardandoAssinaturas)"
                    class="icone-ordem">
                mdi-unfold-more-horizontal
            </v-icon>
            <span class="ordem-envio">{{ signatario.ordemEnvio + 1 }}</span>
        </div>

        <div class="signatario">
            <div style="display: block !important;">
                <div :style=" mostrarOrdem ? 'margin-left: 26px' : ''" class="cabecalho">
                    <div v-if="ehNome" :style="widthSignatario" class="linha-signatario ellipsis">
                        <v-icon style="color: #aaa !important"> mdi-account </v-icon>

                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <span v-on="on"> {{ signatario.nome }}</span>
                            </template>
                            <span>{{ signatario.nome }}</span>
                        </v-tooltip>
                    </div>

                    <div :style="widthSignatario" class="linha-signatario ellipsis">
                        <v-icon v-if="this.signatario.tipoAssinatura === 'WHATSAPP'" style="color: #aaa !important">
                            mdi-whatsapp
                        </v-icon>

                        <template>
                            <v-tooltip top v-if="aguardandoAberturaEmail">
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" color="#aaa"> mdi-email </v-icon>
                                </template>
                                <span>Aguardando abertura</span>
                            </v-tooltip>

                            <v-tooltip top v-else-if="emailAberto">
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" color="#92d892"> mdi-email-open </v-icon>
                                </template>
                                <span>Aberto em {{ formatarDataAberturaEmail }} </span>
                            </v-tooltip>
                        </template>

                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <span v-on="on"> {{ nomeNaLista }}</span>
                            </template>
                            <span>{{ nomeNaLista }}</span>
                        </v-tooltip>
                    </div>

                    <div v-if="aguardandoEnvio" class="linha-signatario">
                        <v-icon style="color: #ccc"> mdi-clock-outline </v-icon>

                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <span v-on="on"> Aguardando envio</span>
                            </template>
                            <span>Desde {{ signatario.dataCadastro | azDate('DD/MM/YYYY HH:mm:ss') }}</span>
                        </v-tooltip>
                    </div>

                    <template v-if="assinaturaPendente">
                        <div
                            v-if="ehOrganizacaoComValidacaoAberturaEmail && !ehEmailAberto && !ehAssinaturaPresencial && !ehAssinaturaWhatsapp"
                            class="linha-signatario">
                            <v-icon style="color: #ccc"> mdi-clock-outline </v-icon>

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on"> Aguardando abertura</span>
                                </template>
                                <span>Desde {{ signatario.dataEnvioEmail | azDate('DD/MM/YYYY HH:mm:ss') }}</span>
                            </v-tooltip>
                        </div>

                        <div v-else class="linha-signatario" style="color: #aaa">
                            <v-icon style="color: #ffbe48"> mdi-clock-outline </v-icon>

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on"> Aguardando assinatura</span>
                                </template>
                                <span>Desde {{ ((ehAssinaturaPresencial || ehAssinaturaWhatsapp) ? signatario.dataCadastro :  signatario.dataEnvioEmail ) | azDate('DD/MM/YYYY HH:mm:ss') }}</span>
                            </v-tooltip>
                        </div>
                    </template>

                    <div v-if="assinaturaEfetuada" class="linha-signatario"
                         style="color: #aaa">
                        <v-icon style="color: #72cb72"> mdi-check-circle </v-icon>

                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <span v-on="on"> Assinado</span>
                            </template>
                            <span>Em {{ signatario.dataAssinatura | azDate('DD/MM/YYYY HH:mm:ss') }}</span>
                        </v-tooltip>
                    </div>

                    <div v-if="assinaturaRejeitada" class="linha-signatario"
                         style="color: #aaa">
                        <v-icon color="#f78393"> mdi-cancel </v-icon>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <span v-on="on"> Rejeitado</span>
                            </template>
                            <p style="width: 350px">Em {{ signatario.dataRejeicao | azDate('DD/MM/YYYY HH:mm:ss') }}
                                <br/>Motivo: {{ signatario.motivoRejeicao }}
                            </p>
                        </v-tooltip>
                    </div>
                </div>
            </div>

            <div style="display: flex; align-items: center;">
                <div class="acoes" style="margin-right: 17px !important;">
                    <v-tooltip top>
                        <template v-slot:activator="{on}">
                            <v-icon v-if="ehAssinaturaPresencial" v-on="on" :color="definirCorIconGesture()"
                                    :disabled="!habilitarBotaoAssinaturaPresencial"
                                    size="24px"
                                    @click="convidarPresencial">
                                mdi-gesture
                            </v-icon>
                        </template>
                        <p style="text-align: justify; margin-bottom: 0px">{{ labelAssinaturaPresencial }}</p>
                    </v-tooltip>

                    <div v-if="aguardandoAssinaturas" class="acoes">
                        <div v-if="!ehAssinaturaPresencial && !ehAssinaturaWhatsapp && !aguardandoEnvio"
                             style="margin-top: 2px; margin-right: 3px" ref="content_copy">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" color="primary" size="24px"
                                            @click="copiarLinkSignatario()"> mdi-content-copy </v-icon>
                                </template>
                                <span>Copiar link de assinatura</span>
                            </v-tooltip>
                        </div>

                        <div v-if="podeRemoverReenviarConvite && !ehAssinaturaPresencial && !ehAssinaturaWhatsapp && !aguardandoEnvio"
                             style="margin-top: 2px; margin-right: 3px">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" color="primary" size="24px" @click="reenviarConviteEmail()"> mdi-email-arrow-right-outline </v-icon>
                                </template>
                                <span>Reenviar e-mail de convite</span>
                            </v-tooltip>
                        </div>

                        <div v-if="podeRemoverReenviarConvite && !ehAssinaturaPresencial && !ehAssinaturaWhatsapp && !aguardandoEnvio && !ehEfcazCLM"
                             style="margin-top: 2px; margin-right: 3px">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" color="primary" size="24px" @click="encaminharLinkAssinaturaWhatsApp()"> mdi-whatsapp </v-icon>
                                </template>
                                <span>Encaminhar link de assinatura pelo WhatsApp</span>
                            </v-tooltip>
                        </div>

                        <v-menu v-if="podeRemoverReenviarConvite && aguardandoAssinaturas"
                                :close-on-content-click="expandirMenu" bottom right transition="scale-transition"
                                content-class="az-menu-lista">
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" color="primary" size="24px"> mdi-dots-vertical </v-icon>
                            </template>

                            <v-list class="menu-acoes-signatario">
                                <v-list-group no-action
                                              v-if="(convidarPorOrdemAssinatura || convidarSignatarios) && !ehAssinaturaWhatsapp"
                                              v-on:click="expandirMenu = false">

                                    <template v-slot:activator>
                                        <v-list-item-content>
                                            <template v-if="aguardandoEnvio">
                                                <v-list-item-title v-az-auth="'Contrato.Signatario.Convidar'">Enviar
                                                    Convite
                                                </v-list-item-title>
                                            </template>

                                            <template v-else>
                                                <v-list-item-title v-az-auth="'Contrato.Signatario.ReenviarConvite'">
                                                    Reenviar Convite
                                                </v-list-item-title>
                                            </template>
                                        </v-list-item-content>
                                    </template>

                                    <template v-if="podeEnviarSMS && aguardandoEnvio && !ehAssinaturaWhatsapp">
                                        <v-list-item class="pl-6"
                                                     v-for="(item, i) in acoesNaoConvidado" :key="i"
                                                     v-az-auth="item.authorities"
                                                     :disabled="habilitarEnviarEmail"
                                                     @click="abrirClmOpsReenvioConvite(item.evento.acao, item.evento.mensagem)">
                                            <v-list-item-title> {{ item.nome }}</v-list-item-title>
                                        </v-list-item>
                                        <v-divider/>
                                    </template>

                                    <template v-else-if="podeEnviarSMS && assinaturaPendente && !ehAssinaturaWhatsapp">
                                        <template v-az-auth="'Contrato.Signatario.ReenviarConvite'">
                                            <v-list-item class="pl-6" v-for="(item, i) in acoesReenviarConvite" :key="i"
                                                         @click="abrirClmOpsReenvioConvite(item.evento.acao, item.evento.mensagem)">
                                                <v-list-item-title> {{ item.nome }}</v-list-item-title>
                                                <v-list-item-avatar v-if="ehSMS(item.nome)">
                                                    <span class="quantidade-saldo">{{ saldoSms }}</span>
                                                </v-list-item-avatar>
                                            </v-list-item>
                                            <v-divider/>
                                        </template>
                                    </template>

                                    <template v-else-if="!podeEnviarSMS && !ehAssinaturaWhatsapp">
                                        <v-list-item class="pl-6"
                                                     v-show="aguardandoEnvio" v-az-auth="'Contrato.Signatario.Convidar'"
                                                     @click="abrirClmOpsReenvioConvite('enviarConviteEmail','Convidar por e-mail' )">
                                            <v-list-item-title>E-mail</v-list-item-title>
                                        </v-list-item>

                                        <v-list-item
                                            v-if="assinaturaPendente"
                                            @click="abrirClmOpsReenvioConvite('reenviarEmail','Convidar por e-mail' )">
                                            <v-list-item-title>E-mail</v-list-item-title>
                                        </v-list-item>
                                        <v-divider/>
                                    </template>
                                </v-list-group>

                                <v-list-group no-action
                                              v-show="!ehAssinaturaPresencial && !ehAssinaturaWhatsapp"
                                              v-on:click="expandirMenu = false"
                                              v-az-auth="'Contrato.Signatario.Editar'"
                                >

                                    <template v-slot:activator>
                                        <v-list-item-content>
                                            <v-list-item-title>Chave Segurança</v-list-item-title>
                                        </v-list-item-content>
                                    </template>

                                    <v-list-item class="pl-6" @click="abrirModalChaveSeguranca"
                                                 v-on:click="expandirMenu = true">
                                        <v-list-item-title>{{ verificarChaveSeguranca() }}</v-list-item-title>
                                    </v-list-item>
                                    <v-divider/>
                                </v-list-group>

                                <v-list-item v-if="ehAssinaturaPresencial && presencialPodeAssinar"
                                             @click="convidarPresencial()"
                                             v-on:click="expandirMenu = true">
                                    <v-list-item-title>Assinar</v-list-item-title>
                                </v-list-item>

                                <v-list-item v-for="(acao, index) in acoesSignatarioConvidado"
                                             :key="index" v-az-auth="acao.authorities"
                                             @click="$emit(acao.evento, signatario)"
                                             v-on:click="expandirMenu = true">
                                    <v-list-item-title>{{ acao.nome }}</v-list-item-title>
                                </v-list-item>

                                <v-list-item
                                    v-if="assinaturaPendente && !ehAssinaturaPresencial && !ehAssinaturaWhatsapp"
                                    v-az-auth="'Contrato.Signatario.Historico'"
                                    @click="$emit('historico', signatario)"
                                    v-on:click="expandirMenu = true"
                                >
                                    <v-list-item-title>Histórico</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>

                    <v-menu v-if="assinaturaEfetuada" bottom right
                            transition="scale-transition"
                            content-class="az-menu-lista">
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="primary" size="24px"> mdi-dots-vertical </v-icon>
                        </template>
                        <v-list>
                            <v-list-item v-for="(acao, index) in acoesAssinaturaEfetuada" :key="index"
                                         v-az-auth="acao.authorities"
                                         @click="$emit(acao.evento, signatario)">
                                <v-list-item-title>{{ acao.nome }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-menu v-if="assinaturaRejeitada" bottom right
                            transition="scale-transition"
                            content-class="az-menu-lista">
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="primary" size="24px"> mdi-dots-vertical </v-icon>
                        </template>
                        <v-list>
                            <v-list-item v-for="(acao, index) in acoesAssinaturaRejeitada" :key="index"
                                         v-az-auth="acao.authorities"
                                         @click="$emit(acao.evento, signatario)">
                                <v-list-item-title>{{ acao.nome }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <clm-ops-reenvio-convite
                        :clm-ops-reenvio-convite="clmOpsReenvioConvite"
                        :enviar-convite-email="enviarConviteEmail"
                        :mensagem="mensagem"
                        :reenviar-email="reenviarEmail"
                        :reenviar-sms="reenviarSms"
                        :reenviar-whats-app="reenviarWhatsApp"
                        :signatario="signatario"
                        @atualizarTelefoneSignatario="atualizarTelefoneSignatario"
                        @convidarPorEmail="convidarPorEmail"
                        @convidarPorSMS="convidarPorSMS"
                        @convidarPorWhatsApp="convidarPorWhatsApp"
                        @fecharClmOpsReenvioConvite="fecharClmOpsReenvioConvite"
                        @reenviarConviteEmail="reenviarConviteEmail"

                    />
                    <ClmOpsEnviarSMS
                        :clmOpsEnviarSMS="clmOpsEnviarSMS"
                        :signatario="signatario"
                        @atualizarChaveSeguranca="atualizarChaveSeguranca"
                        @atualizarTelefoneSignatario="atualizarTelefoneSignatario"
                        @enviarChaveSegurancaPorSMS="enviarChaveSegurancaPorSMS"
                        @fecharClmOpsEnviarSMS="fecharClmOpsEnviarSMS"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import controleAcoes from '../../../controle-acoes'
import moment from 'moment-timezone'
import {mapGetters, mapState} from 'vuex'
import ClmOpsReenvioConvite from './ClmOpsReenvioConvite'
import ClmOpsEnviarSMS from './ClmOpsEnviarSMS'
import store from '@/commons/store'
import {actionTypes} from '@/commons/constants'

export default {
    name: 'Signatario',
    components: {
        ClmOpsReenvioConvite,
        ClmOpsEnviarSMS
    },
    props: {
        signatario: {
            required: true
        },
        presencialPodeAssinar: {
            required: true
        }
    },
    data() {
        return {
            acoesAssinaturaEfetuada: [
                {
                    nome: `Dados ${this.$tc("pt_br.signatario")}`,
                    evento: 'dadosSignatario',
                    authorities: 'Contrato.Signatario.Dados'
                },
                {
                    nome: 'Histórico',
                    evento: 'historico',
                    authorities: 'Contrato.Signatario.Historico'
                }
            ],
            acoesAssinaturaRejeitada: [
                {
                    nome: 'Histórico',
                    evento: 'historico',
                    authorities: 'Contrato.Signatario.Historico'
                },
                {
                    nome: 'Excluir',
                    evento: 'remover',
                    authorities: 'Contrato.Signatario.Excluir'
                }
            ],
            acoesSignatarioConvidado: [
                {
                    nome: `Editar ${this.$tc("pt_br.signatario")}`,
                    evento: 'editar',
                    authorities: 'Contrato.Signatario.Editar'
                },
                {
                    nome: 'Excluir',
                    evento: 'remover',
                    authorities: 'Contrato.Signatario.Excluir'
                }
            ],
            acoesReenviarConvite: [
                {
                    nome: 'Email',
                    evento: {
                        acao: 'reenviarEmail',
                        mensagem: 'Convidar por e-mail'
                    },
                    authorities: 'Contrato.Signatario.Convidar'
                },
                // {
                //     nome: 'SMS',
                //     evento: {
                //         acao: 'reenviarSms',
                //         mensagem: 'Convidar por SMS'
                //     },
                //     authorities: 'Contrato.Signatario.Convidar'
                // },
                // {
                //     nome: 'WhatsApp',
                //     evento: {
                //         acao: 'reenviarWhatsapp',
                //         mensagem: 'Convidar por WhatsApp'
                //     },
                //     authorities: 'Contrato.Signatario.Convidar'
                // }
            ],
            acoesNaoConvidado: [
                {
                    nome: 'Email',
                    evento: {
                        acao: 'enviarConviteEmail',
                        mensagem: 'Convidar por e-mail'
                    },
                    authorities: 'Contrato.Signatario.Convidar'
                }
            ],
            adicionarSignatario: false,
            conviteSignatario: '',
            clmOpsReenvioConvite: false,
            clmOpsEnviarSMS: false,
            dadosSignatario: false,
            dialogModalMensagens: false,
            enviarConviteEmail: false,
            expandirMenu: false,
            mensagem: '',
            reenviarEmail: false,
            reenviarSms: false,
            reenviarWhatsApp: false
        }
    },
    computed: {
        ...mapState(['minutaAtual', 'contrato', 'login']),
        ...mapGetters(['saldoSms', 'ehSignatarioPagante', 'possuiRepresentanteLegal', 'ehEfcazCLM']),
        aguardandoAberturaEmail() {
            return !this.ehEmailAberto && this.ehOrganizacaoComValidacaoAberturaEmail && !this.aguardandoEnvio
        },
        aguardandoAssinaturas() {
            return !this.assinaturaEfetuada && !this.assinaturaRejeitada
        },
        aguardandoEnvio() {
            return this.signatario.statusSignatario === 'NAO_CONVIDADO' && !this.ehAssinaturaPresencial
        },
        assinaturaEfetuada() {
            return this.signatario.dataAssinatura && this.signatario.statusSignatario === 'ASSINADO'
        },
        assinaturaPendente() {
            return !this.signatario.dataAssinatura && !this.signatario.dataRejeicao && (this.signatario.statusSignatario === 'CONVIDADO' || this.ehAssinaturaPresencial)
        },
        assinaturaRejeitada() {
            return this.signatario.dataRejeicao && this.signatario.statusSignatario === 'REJEITADO'
        },
        contratoCancelado() {
            return this.minutaAtual.situacaoFormalizacao === 'CANCELADO'
        },
        contratoId() {
            return this.contrato.id
        },
        convidarPorOrdemAssinatura() {
            return !this.ehAssinaturaPresencial && this.signatario.statusSignatario === 'CONVIDADO' && this.minutaAtual.ordemAssinaturas
        },
        convidarSignatarios() {
            return !this.ehAssinaturaPresencial && !this.minutaAtual.ordemAssinaturas
        },
        emailAberto() {
            return this.ehOrganizacaoComValidacaoAberturaEmail && !this.aguardandoEnvio
        },
        ehAssinaturaPresencial() {
            return this.signatario.disponibilidadeAssinatura === 'PRESENCIAL'
        },
        ehEmailAberto() {
            return this.signatario.dataAberturaEmail
        },
        ehMinutaEmFormalizacaoOuRascunho() {
            return this.minutaAtual.situacaoFormalizacao === 'EM_FORMALIZACAO' || this.minutaAtual.situacaoFormalizacao === 'RASCUNHO'
        },
        ehOrganizacaoComValidacaoAberturaEmail() {
            return this.login.funcionalidadesOrganizacao.validarAberturaEmail
        },
        ehAssinaturaWhatsapp() {
            return this.signatario.tipoAssinatura === 'WHATSAPP'
        },
        ehNome() {
            return this.signatario.nome && this.signatario.nome.length > 0
        },
        habilitarEnviarEmail() {
            return this.ehSignatarioPagante ? !this.possuiRepresentanteLegal : false
        },
        labelAssinaturaPresencial() {
            return this.presencialPodeAssinar ? 'Assinatura presencial' : 'Você só poderá assinar depois que o anterior definido pela ordem de assinatura tenha assinado.'
        },
        mostrarOrdem() {
            return this.$store.state.minutaAtual.ordemAssinaturas
        },
        nomeNaLista() {
            return this.signatario.tipoAssinatura === 'WHATSAPP' ? this.aplicarMascaraTelefoneBrasil(this.signatario.ddi + this.signatario.telefone) : this.signatario.email
        },
        formatarDataAberturaEmail() {
            return `${moment(this.signatario.dataAberturaEmail).format('DD/MM/YYYY HH:mm:ss')} (UTC/GMT -3)`
        },
        habilitarBotaoAssinaturaPresencial() {
            return this.minutaAtual.envioConvitesIniciados || (!this.minutaAtual.ordemAssinaturas)
        },
        minutaId() {
            return this.minutaAtual.id
        },
        podeEnviarSMS() {
            return this.login.funcionalidadesOrganizacao.notificarConviteSMS
        },
        podeRemoverReenviarConvite() {
            const dataAssinaturaNaoExpirada = controleAcoes.adicionarRemoverSignatarios(this.minutaAtual)
            const podeRemover = this.aguardandoEnvio || this.assinaturaPendente
            return dataAssinaturaNaoExpirada && !this.assinaturaRejeitada && podeRemover
        },
        possuiSMS() {
            return this.saldoSms !== 0
        },
        widthSignatario() {
            let w = 270
            w -= (this.mostrarOrdem ? 26 : 0)
            w -= (this.ehAssinaturaPresencial ? 20 : 0)
            w -= (this.signatario.chaveSeguranca ? 20 : 0)
            w -= ((this.aguardandoAberturaEmail && !this.ehAssinaturaPresencial && !this.ehAssinaturaWhatsapp) ? 26 : 0)
            return `width:${w}px;`
        },
    },
    methods: {
        abrirClmOpsEnviarSMS() {
            this.clmOpsEnviarSMS = true
        },
        abrirClmOpsReenvioConvite(evento, mensagem) {
            if (this.ehSMS(evento) && !this.possuiSMS) {
                this.expandirMenu = true
                this.$router.push({name: 'planos'})
            } else {
                this.definirModalContratitos(evento)
                this.mensagem = mensagem
                this.clmOpsReenvioConvite = true
                this.expandirMenu = true
            }
        },
        abrirContratitoEnviarEmail() {
            this.enviarConviteEmail = true
            this.reenviarEmail = false
            this.reenviarSms = false
            this.reenviarWhatsApp = false
        },
        abrirContratitoEnviarSMS() {
            this.reenviarSms = true
            this.reenviarEmail = false
            this.reenviarWhatsApp = false
            this.enviarConviteEmail = false
        },
        abrirContratitoEnviarWhatsapp() {
            this.reenviarWhatsApp = true
            this.reenviarSms = false
            this.reenviarEmail = false
            this.enviarConviteEmail = false
        },
        abrirContratitoReenviarEmail() {
            this.reenviarEmail = true
            this.enviarConviteEmail = false
            this.reenviarSms = false
            this.reenviarWhatsApp = false
        },
        abrirModalChaveSeguranca() {
            this.$emit('adicionarChaveSeguranca', this.signatario)
        },
        aplicarMascaraTelefoneBrasil(telefone) {
            if (telefone.slice(0, 2) === "55") {
                let pattern = '+## (##) # ####-####'
                let i = 0;
                let v = telefone.toString();
                v = v.replace(/\D/g, '');
                return pattern.replace(/#/g, () => v[i++] || '');
            } else {
                return '+' + telefone;
            }
        },
        atualizarTelefoneSignatario() {
            this.$emit('atualizarTelefoneSignatario', this.signatario)
        },
        atualizarChaveSeguranca(signatario) {
            this.$emit('atualizarChaveSeguranca', signatario)
        },
        cancelar() {
            this.adicionarSignatario = false
        },
        async copiarLinkSignatario() {
            try {
                let convitePresencial = await this.pegarLinkAssinatura()

                let sucess = await this.$copyText(convitePresencial, this.$refs.content_copy);
                if(sucess) {
                    this.mostrarNotificacaoSucesso('Link copiado para sua área de transferência')
                }
            } catch (e) {
                this.mostrarNotificacaoErro('Erro ao copiar link!')
            }
        },
        convidarPresencial() {
            this.$emit('convidarPresencial', this.signatario.id)
        },
        convidarPorSMS() {
            this.$emit('convidarPorSMS', this.signatario)
            this.fecharClmOpsReenvioConvite()
        },
        convidarPorEmail() {
            this.$emit('convidarPorEmail', this.signatario)
            this.fecharClmOpsReenvioConvite()
        },
        convidarPorWhatsApp() {
            this.$emit('convidarPorWhatsApp', this.signatario)
            this.fecharClmOpsReenvioConvite()
        },
        definirClasseBarraStatus() {
            if (this.aguardandoEnvio) {
                return 'barra-status-aguardando-envio'
            }
            if (this.assinaturaPendente && this.ehOrganizacaoComValidacaoAberturaEmail && !this.ehEmailAberto && !this.ehAssinaturaPresencial && !this.ehAssinaturaWhatsapp) {
                return 'barra-status-aguardando-abertura'
            }
            if (this.assinaturaPendente) {
                return 'barra-status-aguardando-assinatura'
            }
            if (this.assinaturaEfetuada) {
                return 'barra-status-assinado'
            }
            if (this.assinaturaRejeitada) {
                return 'barra-status-rejeitado'
            }
        },
        definirModalContratitos(evento) {
            if (evento === 'reenviarEmail') {
                this.abrirContratitoReenviarEmail()
            } else if (evento === 'reenviarSms') {
                this.abrirContratitoEnviarSMS()
            } else if (evento === 'reenviarWhatsapp') {
                this.abrirContratitoEnviarWhatsapp()
            } else if (evento === 'enviarConviteEmail') {
                this.abrirContratitoEnviarEmail()
            }
        },
        definirCorIconGesture() {
            if (this.assinaturaEfetuada) {
                return '#72cb72'
            } else if (this.assinaturaRejeitada) {
                return '#f78393'
            } else if (this.assinaturaPendente) {
                return 'primary'
            }
        },
        ehSMS(item) {
            return item.toUpperCase().includes('SMS')
        },
        enviarChaveSegurancaPorSMS(signatario) {
            this.$emit('enviarChaveSegurancaPorSMS', signatario)
            this.fecharClmOpsEnviarSMS()
        },
        fecharClmOpsEnviarSMS() {
            this.clmOpsEnviarSMS = false
        },
        fecharClmOpsReenvioConvite() {
            this.limparEventosEnvioConvite()
            this.mensagem = ''
            this.clmOpsReenvioConvite = false
            this.expandirMenu = false
        },
        inserirSignatario(novoSignatario) {
            this.$emit('salvar', novoSignatario)
        },
        limparEventosEnvioConvite() {
            this.reenviarEmail = false
            this.reenviarSms = false
        },
        async pegarLinkAssinatura() {
            return await store.dispatch(actionTypes.CONVIDAR_SIGNATARIO_PRESENCIAL, {
                contratoId: this.contratoId,
                minutaId: this.minutaId,
                signatarioId: this.signatario.id
            })
        },
        async encaminharLinkAssinaturaWhatsApp() {
            const linkAssinatura = await this.pegarLinkAssinatura()

            window.open(`https://api.whatsapp.com/send?text=Ol%C3%A1%2C%20segue%20o%20link%20do%20documento%20para%20assinatura%20eletr%C3%B4nica.%20Qualquer%20d%C3%BAvida%20estou%20%C3%A0%20disposi%C3%A7%C3%A3o.%0A%0A${encodeURIComponent(linkAssinatura)}`, '_blank')
        },
        reenviarConviteEmail() {
            this.$emit('reenviarConviteEmail', this.signatario)
            this.fecharClmOpsReenvioConvite()
        },
        verificarChaveSeguranca() {
            return this.signatario.chaveSeguranca === null ? 'Adicionar Chave Segurança' : 'Editar Chave Segurança'
        }
    }
}
</script>
<style lang="stylus">
.ordem-signatarios
    .icone-ordem
        color #999
        font-size 15px !important
        margin-left 18px
        margin-top 11px
        position absolute

    .ordem-envio
        color #999
        font-size 12px !important
        margin-left 33px
        margin-top 10px
        position absolute

.co-dados-assinante
    .dados
        padding 10px

        .nome
            font-size 12px !important
            color #999

        .valor
            max-width 400px
            font-size 14px
            color #555
            margin-bottom 10px

.aviso-limite-assinatura-expirado
    position absolute
    top 105px
    right -10px

.barra-status
    &-assinado
        background-color: #72cb72
        width: 8px
        height: 100%
        position: absolute
        left: 0

    &-aguardando-assinatura
        background-color: #ffbe48
        width: 8px
        height: 100%
        position: absolute
        left: 0

    &-rejeitado
        background-color: #f78393
        width: 8px
        height: 100%
        position: absolute
        left: 0

    &-aguardando-abertura
        background-color: #ccc
        width: 8px
        height: 100%
        position: absolute
        left: 0

    &-aguardando-envio
        background-color: white
        width: 8px
        height: 100%
        position: absolute
        left: 0
        border-bottom 1px solid #ccc

.linha-signatario
    font-size 13px
    color #aaa
    font-weight normal !important
    padding-left 7px

    i
        font-size 16px !important
        margin-right 5px
        font-weight normal !important

.signatario
    border-bottom: 1px solid #ddd !important;
    display flex
    padding: 10px 0 10px 15px
    justify-content space-between
    border-bottom 1px solid #ccc

    .acoes
        display flex
        align-items center

        button
            margin 0 !important

    .ellipsis
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap

    .v-expansion-panel__body
        padding: 0 0 0 12px !important

    .v-expansion-panel__header
        display flex !important
        position relative
        padding 0 !important
        min-height unset !important
        height 48px

        &__icon
            position: absolute
            width: 100%
            top: 0

            i
                position: absolute
                top: 10px
                right: 30px
                color #ccc !important

        .barra-status
            width 8px
            height 48px

        .cabecalho
            display block
            width: 100%;
            padding 5px 12px 0 12px
            justify-content flex-end
            padding-right 10px
            margin-top 5px

        a
            font-size 13px
            padding 5px 10px


.menu-acoes-signatario
    .quantidade-saldo
        font-weight bold
        font-size 13px
        border-radius 50%
        padding 0 5px
        background-color #aaa
        color white
</style>