import Vue from 'vue'
import VeeValidate, {Validator} from 'vee-validate'
import ptBR from 'vee-validate/dist/locale/pt_BR'
import {isCep, isCnpj, isCpf} from 'validator-brazil'
import moment from 'moment'

Validator.extend('unique', {
    validate(value, {currentValues}) {
        const found = currentValues.filter(current => current === value)
        return found.length === 0
    }
}, {
    paramNames: ['currentValues']
})

Validator.extend('cpf', {
    validate(value) {
        return isCpf(value)
    }
})

Validator.extend('cnpj', {
    validate(value) {
        return isCnpj(value)
    }
})

Validator.extend('cep', {
    validate(value) {
        return isCep(value)
    }
})

Validator.extend('check_dif_datas', {
    validate(value, {dataInicio}) {
        if (!dataInicio || !value) {
            return false
        }

        let inicio = moment.utc(dataInicio)
        let fim = moment.utc(value)

        return !inicio.isAfter(fim, 'day') && fim.diff(inicio, 'days') <= 180
    }
}, {
    paramNames: ['dataInicio']
})

Validator.extend('check_data_inicial', {
    validate(value) {
        if (!value) {
            return false
        }

        let inicio = moment.utc(value)
        let hj = moment.utc()

        return !inicio.isAfter(hj, 'day')
    }
})


Vue.use(VeeValidate)
Validator.localize('pt_BR', ptBR)
Validator.localize({
    'pt_BR': {
        messages: {
            unique: field => `${field} duplicado`,
            cpf: field => `${field} inválido`,
            cnpj: field => `${field} inválido`
        }
    }
})