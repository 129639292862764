<template>
    <v-form class="co-formulario-opcional-assinatura-digital">
        <rejeicao-documento
            :value="novoSignatario"
        />
        <nome-signatario
            v-model="novoSignatario"
            descricao
        />
        <convidar-por-sms
            :value="novoSignatario"
            :desabilitar-campo="modoEdicao"
            @abrir-dialog-contratar-servico="abrirDialogContratarServico"
        />
        <telefone-signatario
            v-model="novoSignatario"
            :campo-requerido="telefoneRequired"
            descricao
        />
        <chave-seguranca
            v-model="novoSignatario"
        />

        <mensagem-email-signatario
            v-model="novoSignatario"
        />

        <dialog-contratar-servico
            :dialog="dialogContratarServico"
            @fechar="fecharDialogContratarServico"
        />

    </v-form>
</template>

<script>
import ConvidarPorSms from '../campos-formulario/ConvidarPorSms'
import ChaveSeguranca from '../campos-formulario/ChaveSeguranca'
import TelefoneSignatario from '@/commons/components/text-field/TelefoneSignatario'
import DialogContratarServico from '@/commons/components/DialogContratarServico'
import MensagemEmailSignatario
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/MensagemEmailSignatario'
import RejeicaoDocumento
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/RejeicaoDocumento'
import NomeSignatario
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/NomeSignatario'

export default {
    name: 'FormularioOpcionalAssinaturaDigital',
    components: {
        RejeicaoDocumento,
        ConvidarPorSms,
        ChaveSeguranca,
        DialogContratarServico,
        MensagemEmailSignatario,
        TelefoneSignatario,
        NomeSignatario
    },
    props: {
        novoSignatario: {
            type: Object,
            required: true
        },
        modoEdicao: {
            type: Boolean
        }
    },
    data() {
        return {
            dialogContratarServico: false
        }
    },
    computed: {
        telefoneRequired() {
            return this.novoSignatario.enviarConviteSMS
        }
    },
    methods: {
        abrirDialogContratarServico() {
            this.dialogContratarServico = true
        },
        fecharDialogContratarServico() {
            this.dialogContratarServico = false
        }
    }
}
</script>