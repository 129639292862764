<template>
    <v-form class="co-formulario-obrigatorio-assinatura-digital">
        <representante-legal
            :desabilitar-campo="naoPodeAtualizar"
            v-model="novoSignatario"
            v-if="signatarioPaganate"
        />
        <email-signatario
            v-if="modoEdicao"
            v-model="novoSignatario"
            :desabilitar-campo="naoPodeAtualizar"
            campo-requerido
            descricao
        />
        <email-signatario-lote
            v-else
            v-model="novoSignatario"
            :desabilitar-campo="naoPodeAtualizar"
            :signatarios-sugestao="signatariosSugestoes"
            campo-requerido
        />
        <documento-signatario
            v-model="novoSignatario"
            :documentos="documentosSignatario"
            campo-requerido
            descricao
        />
        <papel-signatario
            v-model="novoSignatario"
            :papeis="papeisSiganatario"
            campo-requerido
            descricao
        />
    </v-form>
</template>

<script>
import DocumentoSignatario from '../campos-formulario/DocumentoSignatario'
import EmailSignatarioLote from '../campos-formulario/EmailSignatarioLote'
import PapelSignatario from '../campos-formulario/PapelSignatario'
import RepresentanteLegal
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/RepresentanteLegal'
import EmailSignatario
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/EmailSignatario'

export default {
    name: 'FormularioObrigatorioAssinaturaDigital',
    components: {EmailSignatario, RepresentanteLegal, DocumentoSignatario, EmailSignatarioLote, PapelSignatario},
    props: {
        documentosSignatario: {
            type: Array,
            required: true
        },
        modoEdicao: {
            type: Boolean
        },
        naoPodeAtualizar: {
            type: Boolean
        },
        novoSignatario: {
            type: Object,
            required: true,
        },
        papeisSiganatario: {
            type: Array,
            required: true
        },
        signatariosSugestoes: {
            type: Array
        },
        signatarioPaganate: {
            type: Boolean
        }
    }
}
</script>