<template>
    <div>
        <v-row class="justify-center" no-gutters>
            <card-plano-mensal
                v-for="(planosInfos, index) in planosInformacoes" :key="planosInfos.titulo"
                :eh-plano-atual="nomePlano === planosInfos.nome"
                :eh-alteracao-plano="!ehPlanoFree"
                :plano="planosInfos"
                :eh-melhor-opcao="planosInfos.nome === 'API_PRO'"
                @alterar-plano="abrirDialogAlterarPlano"
                @cancelar-plano="abrirDialogCancelarPlano"
                @abrir-pagamentos="abrirPagamentos"
                class="my-3"
            />
            
            <dialog-default-confirmar-acao
                v-if="dialogDefault"
                apenas-acao-principal
                :dialog="dialogDefault"
                :sentimento="dialog.sentimento"
                :titulo="dialog.titulo"
                :subtitulo="dialog.subtitulo"
                :label-botao-principal="dialog.labelBotaoPrincipal"
                @acao-principal="dialog.acaoPrincipal"
                @fechar="dialog.acaoPrincipal"
            />

            <dialog-cancelar-plano
                v-if="dialogCancelarPlano"
                apenas-acao-principal
                :dialog="dialogCancelarPlano"
                @fechar="fecharDialogCancelarPlano"
                @solicitacao-realizada="concluirSolicitacaoCancelamento"
            />
        </v-row>
    </div>
</template>

<script>

import {mapGetters} from 'vuex'
import DialogDefaultConfirmarAcao from '@/commons/components/dialogs/DialogDefaultConfirmarAcao'
import CardPlanoMensal from './components/CardPlanoMensal'
import DialogCancelarPlano from '../dialog/DialogCancelarPlano'
import actionTypes from '@/commons/constants/action-types'
import _ from 'lodash'

export default {
    name: 'MensaisApi',
    components: {DialogCancelarPlano, DialogDefaultConfirmarAcao, CardPlanoMensal},
    props: {
        planos: {
            type: Array | undefined,
            required: true
        }
    },
    data() {
        return {
            planosInformacoes: [],
            dialogCancelarPlano: false,
            dialogDefault: false,
            dialog: {
                titulo: '',
                subtitulo: '',
                labelBotaoPrincipal: '',
                sentimento: '',
                acaoPrincipal: null
            }
        }
    },
    created() {        
        this.planosInformacoes = [
            this.planos.lightApi, 
            this.planos.starterApi, 
            this.planos.proApi, 
            ...this.planosInformacoes
        ]
    },
    computed: {
        ...mapGetters(['nomePlano', 'ehPlanoFree']),
    },
    methods: {
        abrirPagamentos(planoSelecionado) {
            this.$emit('abrir-pagamentos', planoSelecionado)
        },
        abrirDialogCancelarPlano() {
            this.dialogCancelarPlano = true
        },
        abrirDialogAlterarPlano() {
            let dialogDowgrade = {
                sentimento: 'assustado',
                titulo: 'Ops, não é possível realizar a alteração do plano',
                subtitulo: 'Para prosseguir com a troca de plano realize o cancelamento do seu plano atual, e faça a contratação de um novo. Se possuir dúvidas entre em contato com um de nossos atendentes através do chat, ligue no telefone 0800 722 2701 ou envie um e-mail a suporte@besign.com.br',
                labelBotaoPrincipal: 'Ok',
                acaoPrincipal: this.fecharDialogDefault
            }

            this.dialog = _.merge(this.dialog, dialogDowgrade)
            this.dialogDefault = true
        },
        concluirSolicitacaoCancelamento(cancelamentoOutput) {
            this.dialogCancelarPlano = false

            const mensagemParaPlanosSemAssinaturaExterno = 'Solicitação de cancelamento realizada.<br/><br/> Ficamos tristes pela sua solicitação de cancelamento com a nossa plataforma, mas estamos gratos pela sua confiança na BeSign até o momento. Em breve você receberá um e-mail com as orientações e confirmação do seu cancelamento. Seu acesso continuará disponível dentro da plataforma após o processamento do seu cancelamento.<br/><br/> Caso deseje continuar enviando documentos, poderá recontratar um plano ou adquirir documentos sem recorrência.'
            let mensagemPlanoNaoExpiradoNaSolicitacao = ''
            
            if (!cancelamentoOutput.planoFoiCanceladoNaSolicitacao) {
                mensagemPlanoNaoExpiradoNaSolicitacao = ` Você poderá realizar envio de documentos até o dia <strong>${cancelamentoOutput.dataVigenciaPlano}</strong>, após essa data seu plano passará a ser FREE e seu acesso será mantido.`
            }
            
            const mensagemParaPlanosComAssinaturaExterno = `Cancelamento concluído.<br/><br/> Ficamos tristes pela sua solicitação de cancelamento com a nossa plataforma, mas estamos gratos pela sua confiança na BeSign até o momento.${mensagemPlanoNaoExpiradoNaSolicitacao} <br/><br/>Caso deseje continuar enviando documentos, poderá recontratar um plano ou adquirir documentos sem recorrência.`

            let dialogSolicitarCancelamentoConcluido = {
                sentimento: 'triste',
                titulo: 'Solicitação de cancelamento realizada',
                subtitulo: cancelamentoOutput.ehPlanoComAssinaturaExterna ? mensagemParaPlanosComAssinaturaExterno : mensagemParaPlanosSemAssinaturaExterno,
                labelBotaoPrincipal: 'Ok',
                acaoPrincipal: this.fecharDialogAtualizandoPlano
            }

            this.dialog = _.merge(this.dialog, dialogSolicitarCancelamentoConcluido)
            this.dialogDefault = true
        },
        fecharDialogCancelarPlano() {
            this.dialogCancelarPlano = false
            this.$router.push({name: 'meus'})
        },
        async fecharDialogAtualizandoPlano() {
            await this.$store.dispatch(actionTypes.LOGIN.BUSCAR_ORGANIZACAO_PLANO)
            await this.$store.dispatch(actionTypes.LOGIN.BUSCAR_FUNCIONALIDADES_ORGANIZACAO)
            await this.$store.dispatch(actionTypes.COMUN.BUSCAR_CONSUMO_ORGANIZACAO)

            this.fecharDialogDefault()
        },
        fecharDialogDefault() {
            this.dialogDefault = false
        },
    }
}
</script>
