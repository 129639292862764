import Vue from 'vue'
import azEnum from './enum-filter'
import enumFilterCapitalize from '@/commons/filters/enum-filter-capitalize'

Vue.filter('az-enum', azEnum)
Vue.filter('enumCapitalize', enumFilterCapitalize)
export {
    azEnum,
    enumFilterCapitalize,
}
