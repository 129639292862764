export default {
    methods: {
        browser() {
            return !window.Android
        },
        webview() {
            return window.Android
        }
    }
}
