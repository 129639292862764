<template>
    <div class="co-mensagem-email-signatario">
        <span v-if="informativo" class="label">
            Mensagem para o {{ $tc("pt_br.signatario").toLowerCase() }}
            <v-tooltip top max-width="500px">
                <template v-slot:activator="{ on }">
                    <v-icon v-if="informativo" v-on="on" size="20" color="#999"> mdi-information </v-icon>
                </template>
                <span>{{ descricaoCampo }}</span>
            </v-tooltip>
        </span>
        <v-textarea
            v-model="value.mensagem"
            :label=" informativo ? '' : label"
            :name="label"
            placeholder="Informe uma mensagem"
            persistent-placeholder
            maxlength="250"
            type="text"
            :class="informativo ? 'mensagem-email-field' : ''"
            :disabled="disabled"
            auto-grow
            rows="4"
        >
        </v-textarea>

        <div style="display: flex; justify-content: flex-end; font-size: 11px; color: #aaaaaa">
            {{ value.mensagem !== null ? value.mensagem.length : '0' }}/250
        </div>

        <div v-if="!informativo" class="hint-checkbox">
            {{ descricaoCampo }}
        </div>
    </div>
</template>

<script>

export default {
    name: 'MensagemEmailSignatario',
    props: {
        value: {
            required: true
        },
        informativo: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            tamanhoTexto: [v => v.length <= 250 || 'Quantidade Máxima 250 caracteres']
        }
    },
    computed: {
        descricaoCampo() {
            return `Aqui você pode definir uma mensagem para o ${this.$tc("pt_br.signatario").toLowerCase()}, a mensagem será enviada no email de convite de assinatura.`
        },
        label() {
            return `Mensagem para o ${this.$tc("pt_br.signatario").toLowerCase()}`
        }
    }
}
</script>

<style lang="stylus">
.co-mensagem-email-signatario
    .mensagem-email-field
        padding-top 0
        margin-top 0

    .label
        color #777777
</style>