<template>
    <v-dialog hide-overlay v-model="clmOpsDadosSignatario" width="0" persistent>
        <div class="clm-ops-reenvio-convite">
            <div class="text-xs-center">
                <div class="content co-dados-assinante pa-3">
                    <v-icon size="18" class="btn-fechar" @click="fechar"> mdi-window-close </v-icon>

                    <div align="start" class="mb-2" v-if="signatario.representanteLegal">
                        <div class="nome">Representante Legal</div>
                    </div>
                    <div align="start" class="mb-2">
                        <div style="font-size:12px; color:#ccc">Nome</div>
                        <div class="nome">{{ signatario.nome }}</div>
                    </div>
                    <div align="start" class="mb-2">
                        <div style="font-size:12px; color:#ccc">Papel</div>
                        <div class="papel">{{ signatario.papel }}</div>
                    </div>
                    <div align="start" class="mb-2">
                        <div style="font-size:12px; color:#ccc">{{ tipoDocumento }}</div>
                        <div class="cpf">{{ signatario.cpf }}</div>
                    </div>
                    <div align="start" class="mb-2">
                        <div style="font-size:12px; color:#ccc">Data de Nascimento</div>
                        <div class="data-nascimento">{{ signatario.dataNascimento | azDate }}</div>
                    </div>
                    <div align="start" class="mb-2" v-if="signatario.chaveSeguranca">
                        <div style="font-size:12px; color:#ccc">Chave de Segurança</div>
                        <div class="chave-seguranca">{{ signatario.chaveSeguranca }}</div>
                    </div>
                    <div align="start" class="mb-2" v-if="possuiAssinaturaManuscrita">
                        <div class="assinatura-manuscrita">Assinatura Manuscrita</div>
                        <div style="margin: 5px 0">
                            <v-img :src="assinaturaManuscritaBase64"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
export default {
    name: 'ClmOpsDadosSignatario',
    props: {
        assinaturaManuscritaBase64: {
            required: false
        },
        clmOpsDadosSignatario: {
            type: Boolean,
            required: true
        },
        signatario: {
            type: Object,
            required: false
        }
    },
    computed: {
        possuiAssinaturaManuscrita() {
            return this.assinaturaManuscritaBase64 !== ''
        },
        tipoDocumento() {
            return this.signatario.documento === 'Outros' ? 'Documento' : this.signatario.documento
        }
    },
    methods: {
        fechar() {
            this.$emit('fecharClmOpsDadosSignatario')
        }
    }
}
</script>

<style lang="stylus">
.clm-ops-reenvio-convite
    .co-dados-assinante
        .nome
            font-size 13px
            color #2F5071
            font-weight bold

        .papel
            font-size 13px
            color #999

        .cpf
            font-size 13px
            color #999

        .data-nascimento
            font-size 13px
            color #999

        .chave-seguranca
            font-size 13px
            color #999

        .assinatura-manuscrita
            font-size 13px
            color #999
</style>