<template>
    <v-col class="pb-5">
        <v-row no-gutters>
            <v-text-field
                v-model="value.nome"
                name="Titulo Documento"
                label="Título documento"
                placeholder="Informe o nome do documento"
                :rules="[(v) => !!v || 'Informe o campo obrigatório']"
                :error-messages="errors.collect('Titulo Documento')"
                persistent-placeholder
                hide-details
                maxlength="255"
                :disabled="renovacao"
            />
        </v-row>
    </v-col>
</template>

<script>
export default {
    name: 'NomeDocumento',
    props: {
        value: {
            type: Object,
            required: true
        },
        renovacao: {
            type: Boolean,
            default: false
        }
    }
}
</script>