<template>
    <div class="co-nome-grupo">
        <v-text-field
            v-model="value.nome"
            v-validate="validarCampo()"
            :class="campoRequerido ? 'required-field' : ''"
            :error-messages="errors.collect('Nome do grupo')"
            label="Nome do grupo"
            name="Nome do grupo"
            placeholder="Informe o nome do grupo"
            @input="toTitleCase"
        />
    </div>

</template>

<script>
export default {
    name: 'NomeGrupo',
    props: {
        value: {
            required: true
        },
        campoRequerido: {
            type: Boolean,
            default: false
        },
        titleCase:{
            type: Boolean,
            default: false
        }
    },
    methods: {
        validarCampo() {
            return this.campoRequerido ? 'required' : ''
        },
        toTitleCase() {
            if (this.titleCase) {
                this.value.nome = this.value.nome.replace(/(^|\s)\S/g, function (t) {
                    return t.toUpperCase()
                });
            }
        }
    }
}
</script>