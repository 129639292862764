<template>
    <div v-if="ehPaginaComContratos" class="co-home-contratos ml-4 mt-4 mb-12">
        <v-col cols="12" class="titulo">
            {{ $tc('pt_br.documento')[0].toUpperCase() + $tc('pt_br.documento').slice(1) }}s
        </v-col>

        <v-row no-gutters>
            <template v-if="ehModoGrade">
                <v-col v-for="(contrato, index) in contratos" :key="index"
                       no-gutters sm="6" md="4" xl="3"
                       :cols="modoGrade? 6 : 12" class="pr-3">
                    <contrato-grade :contrato="contrato"
                                    :contrato-status="getStatus(contrato)"
                                    :style-contrato-status="getStyleContratoStatus(contrato)"
                                    :contrato-situacao="getSituacao(contrato)"/>
                </v-col>
            </template>

            <template v-else>
                <v-flex class="pr-3 mt-3">
                    <contrato-lista-header/>
                </v-flex>

                <v-flex v-for="(contrato, index) in contratos" :key="index"
                        class="contratos-lista pr-3 mt-3">
                    <contrato-lista
                        :contrato="contrato"
                        :contrato-status="getStatus(contrato)"
                        :contrato-situacao="getSituacao(contrato)"/>
                </v-flex>
            </template>
        </v-row>
    </div>
</template>

<script>

import ContratoGrade from '@/views/home/meus-contratos/components/contratos/components/ContratoGrade'
import ContratoLista from '@/views/home/meus-contratos/components/contratos/components/contrato-lista/ContratoLista'
import ContratoListaHeader
    from '@/views/home/meus-contratos/components/contratos/components/contrato-lista/components/ContratoListaHeader'

export default {
    name: "Contratos",
    components: {
        ContratoGrade,
        ContratoLista,
        ContratoListaHeader
    },
    props: {
        contratos: {
            type: Array,
            required: true
        },
        modoGrade: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        ehModoGrade() {
            return this.modoGrade || this.ehMobile()
        },
        ehPaginaComContratos() {
            return this.contratos && this.contratos.length > 0
        }
    },
    methods: {
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        },
        ehContratoCancelado(contrato) {
            return contrato.minutaSituacaoFormalizacao === 'CANCELADO'
        },
        ehContratoRascunho(contrato) {
            return contrato.minutaSituacaoFormalizacao === 'RASCUNHO'
        },
        ehContratoEmFormalizacao(contrato) {
            return contrato.minutaSituacaoFormalizacao === 'EM_FORMALIZACAO'
        },
        ehContratoFormalizado(contrato) {
            return contrato.minutaSituacaoFormalizacao === 'FORMALIZADO'
        },
        getStyleContratoStatus (contrato) {
            if (contrato.totalRejeicoes > 0) {
                return 'background-color: var(--error-300); color: var(--background-100)'
            } else if (contrato.totalAssinaturas === 0) {
                return `background-color: var(--secondary-100); color: var(--text-color-300)`
            } else if (contrato.totalSignatarios === contrato.totalAssinaturas) {
                return `background-color: var(--primary); color: var(--background-100)`
            } else {
                return `background-color: var(--secondary-100); color: var(--text-color-300)`
            }
        },
        getStatus(contrato) {
            if (contrato.totalRejeicoes > 0) {
                return 'Rejeitado'
            } else if (contrato.totalAssinaturas === 0) {
                return `Não Assinado 0/${contrato.totalSignatarios}`
            } else if (contrato.totalSignatarios === contrato.totalAssinaturas) {
                return `Assinado ${contrato.totalAssinaturas}/${contrato.totalSignatarios}`
            } else {
                return `Parcialmente Assinando ${contrato.totalAssinaturas}/${contrato.totalSignatarios}`
            }
        },
        getSituacao(contrato) {
            if (this.ehContratoRascunho(contrato) || this.ehContratoEmFormalizacao(contrato)) {
                return 'Não Formalizado'
            } else if (this.ehContratoFormalizado(contrato)) {
                return 'Formalizado'
            } else if (this.ehContratoCancelado(contrato)) {
                return 'Cancelado'
            } else {
                return ''
            }
        }
    }
}
</script>

<style lang="stylus">
.co-home-contratos
    .titulo
        color var(--text-color-300)

    .contratos-lista
        width 100%

</style>