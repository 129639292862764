<template>
    <div>
        <detalhe-contrato-caminho
            :caminhoContrato="caminhoContrato"
            :nome="nome"
            :minutaAtual="minutaAtual"
            @abrirModalEditar="abrirModalEditar"
            @abrirModalParaMoverContrato="abrirDialogMoverParaPasta"
            @desativarNotificacao="desativarNotificacao"
        />
        <dialog-renomear-contratos
            v-if="modalEditar"
            v-model="contrato"
            :nome="contrato.nome"
            :tags="contrato.contratoTags"
            @fechar="fecharDialogRenomearContratos"
            @salvar="salvar"
        />

        <dialog-mover-elemento
            v-if="dialogMoverParaPasta"
            :dialog="dialogMoverParaPasta"
            :elemento="contrato"
            @fechar="fecharDialogMoverParaPasta"
        />

    </div>
</template>
<script>
import {mapState} from 'vuex'
import DetalheContratoCaminho from './DetalheContratoCaminho'
import DialogRenomearContratos from '@/views/contrato/commons/DialogRenomearContratos'
import DialogMoverElemento from '@/views/home/meus-contratos/components/common/DialogMoverElemento'

export default {
    name: 'DetalheContratoNome',
    components: {DialogMoverElemento, DetalheContratoCaminho, DialogRenomearContratos},
    props: ['nome', 'tags', 'minutaAtual'],
    data() {
        return {
            mensagemModalMoverNotificacao: '',
            mostrarModalMoverNotificacao: false,
            modalEditar: false,
            dialogMoverParaPasta: false,
        }
    },
    computed: {
        ...mapState(['loki', 'contrato', 'login']),
        caminhoContrato() {
            if (this.possuiPasta()) {
                return this.contrato.pastaNome
            }
            return this.login.organizacaoPlano.organizacaoNome
        }
    },
    methods: {
        abrirDialogMoverParaPasta() {
            this.contrato.contratoId = this.contrato.id
            this.dialogMoverParaPasta = true
        },
        abrirModalMoverNotificacao() {
            this.mostrarModalMoverNotificacao = true
        },
        async abrirModalEditar() {
            this.modalEditar = true
        },
        atualizarCaminho(pasta) {
            this.contrato.pastaId = pasta.pastaId
            this.contrato.pastaNome = pasta.name
        },
        ehMoverContratoRaizParaPasta(pasta, pastaSelecionada) {
            return pastaSelecionada === null && pasta.pastaId !== undefined
        },
        ehMoverContratoPastaParaPasta(pasta, pastaSelecionada) {
            return pastaSelecionada !== null && pasta.pastaId === undefined
        },
        fecharDialogMoverParaPasta() {
            this.dialogMoverParaPasta = false
        },
        fecharDialogRenomearContratos(){
            this.modalEditar = false
        },
        fecharModalMoverNotificacao() {
            this.mostrarModalMoverNotificacao = false
        },
        possuiPasta() {
            return this.contrato.pastaId
        },
        salvar(nome, tags) {
            this.$emit('salvar', nome, tags)
        },
        fecharModalDesativarNotificacao() {
            this.$emit('fecharModalDesativarNotificacao')
        },
        desativarNotificacao(usuario) {
            this.$emit('desativarNotificacao', usuario)
        },
    }
}
</script>

<style lang="stylus">
.co-detalhe-contrato-caminho
.v-sheet .theme--dark .v-toolbar .v-toolbar--flat .v-app-bar .primary
    height fit-content !important
</style>
