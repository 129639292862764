import axios from 'axios'
import {actionTypes} from '@/commons/constants'

export default {
    async [actionTypes.PLANOS.BUSCAR_TODOS_PLANOS_ORDENADOS](context) {
        const {data} = await axios.get(`api/planos-assinaturas`)
        return data
    },

    async[actionTypes.PLANOS.SOLICITAR_CANCELAMENTO_PLANO] ({state}, solicitacao) {
        const organizacaoId = state.login.organizacaoPlano.organizacaoId
        const {data} = await axios.post(`api/cancelar-plano/organizacao/${organizacaoId}`, solicitacao)
        return data
    },
}