<template>
    <div class="co-adicionar-signatario">
        <v-stepper v-model="stepper" class="elevation-0" editable vertical>
            <v-stepper-step :complete="stepper > 1" :editable="desabilitarSelecaoTipoAssinatura()" step="1">
                <span :style="getColorStep(1)">{{ tituloTipoAssinatura }}</span>
                <small v-if="stepper !== 1">{{ labelTipoAssinatura }}</small>
            </v-stepper-step>

            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-card class="mb-2" elevation="0">
                        <formulario-tipos-assinaturas
                            :mostrar-assinatura-digital="mostrarAssinaturaDigital"
                            :novo-signatario="novoSignatario"
                            @tipo-assinatura-proximo="tipoAssinaturaProximo"
                        />
                    </v-card>
                </v-stepper-content>

                <v-stepper-step step="2" :complete="stepper > 2" :editable="temTipoAssinatura">
                    <span :style="getColorStep(2)">Dados do {{ $tc("pt_br.signatario").toLowerCase() }}</span>
                    <small v-for="(item, index) in dadosSignatarioExibir" :key="index">
                        <div v-if="stepper !== 2">
                        <span v-if="item !== null && item === 'REMOTA' || item === 'PRESENCIAL'">
                          {{ item | enumCapitalize }}
                        </span>

                            <span v-else-if="ehEmail(item)"
                                  style="text-overflow: ellipsis; overflow: hidden;display: inline-block; width: 200px">
                            {{ item.toString() }}
                        </span>

                            <span v-else>{{ item }}</span>
                        </div>
                    </small>
                </v-stepper-step>

                <v-stepper-content step="2">
                    <v-card class="mb-12" elevation="0">

                        <formulario-obrigatorio-assinatura-digital
                            v-if="tipoAssinaturaDigital"
                            :documentos-signatario="documentosSignatario"
                            :modo-edicao="modoEdicao"
                            :nao-pode-atualizar="naoPodeAtualizar"
                            :novo-signatario="novoSignatario"
                            :papeis-siganatario="papeisSiganatario"
                            :signatarios-sugestoes="signatariosSugestoes"
                            :signatario-paganate="ehSignatarioPagante"
                        />

                        <formulario-obrigatorio-assinatura-eletronica
                            v-else-if="tipoAssinaturaEletronica"
                            :documentos-signatario="documentosSignatario"
                            :modo-edicao="modoEdicao"
                            :nao-pode-atualizar="naoPodeAtualizar"
                            :novo-signatario="novoSignatario"
                            :papeis-siganatario="papeisSiganatario"
                            :signatarios-sugestoes="signatariosSugestoes"
                            :signatario-paganate="ehSignatarioPagante"
                        />

                        <formulario-obrigatorio-assinatura-whatsapp
                            v-else
                            :documentos-signatario="documentosSignatario"
                            :nao-pode-atualizar="naoPodeAtualizar"
                            :novo-signatario="novoSignatario"
                            :papeis-siganatario="papeisSiganatario"
                            :signatario-paganate="ehSignatarioPagante"
                        />

                    </v-card>
                </v-stepper-content>

                <v-stepper-step step="3" :complete="stepper > 3" :editable="temTipoAssinatura">
                    <span :style="getColorStep(3)">Dados complementares</span>
                </v-stepper-step>

                <v-stepper-content step="3">
                    <v-card class="mb-12" elevation="0">

                        <formulario-opcional-varios-email
                            v-if="ehVariosEmail"
                            :novo-signatario="novoSignatario"
                        />

                        <formulario-opcional-assinatura-digital
                            v-else-if="tipoAssinaturaDigital"
                            :modo-edicao="modoEdicao"
                            :novo-signatario="novoSignatario"
                        />

                        <formulario-opcional-assinatura-eletronica
                            v-else-if="tipoAssinaturaEletronica"
                            :modo-edicao="modoEdicao"
                            :novo-signatario="novoSignatario"
                        />

                        <formulario-opcional-assinatura-whatsapp
                            v-else-if="tipoAssinaturaWhatsapp"
                            :modo-edicao="modoEdicao"
                            :novo-signatario="novoSignatario"
                        />

                    </v-card>
                </v-stepper-content>
            </v-stepper-items>

            <botoes-adicionar-signatario
                @cancelar="cancelar"
                @salvar="salvarOnce"
                :habilitar-salvar="habilitarSalvar"
            />

            
        </v-stepper>
    </div>

</template>

<script>
import {mapGetters, mapState} from 'vuex'
import {actionTypes} from '@/commons/constants'
import BotoesAdicionarSignatario from './components/BotoesSignatario'
import FormularioTiposAssinaturas from './components/formulario-tipos-assinaturas/FormularioTiposAssinaturas'
import FormularioObrigatorioAssinaturaDigital
    from './components/formulario-obrigatorio-assinatura-digital/FormularioObrigatorioAssinaturaDigital'
import FormularioObrigatorioAssinaturaEletronica
    from './components/formulario-obrigatorio-assinatura-eletronica/FormularioObrigatorioAssinaturaEletronica'
import FormularioObrigatorioAssinaturaWhatsapp
    from './components/formulario-obrigatorio-assinatura-whatsapp/FormularioObrigatorioAssinaturaWhatsapp'
import FormularioOpcionalAssinaturaDigital
    from './components/formulario-opcional-assinatura-digital/FormularioOpcionalAssinaturaDigital'
import FormularioOpcionalAssinaturaEletronica
    from './components/formulario-opcional-assinatura-eletronica/FormularioOpcionalAssinaturaEletronica'
import _ from 'lodash'
import FormularioOpcionalVariosEmail
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/formulario-opcional-varios-email/FormularioOpcionalVariosEmail'
import FormularioOpcionalAssinaturaWhatsapp
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/formulario-opcional-assinatura-whatsapp/FormularioOpcionalAssinaturaWhatsapp'
import store from '@/commons/store'

export default {
    name: 'AdicionarSignatario',
    components: {
        FormularioOpcionalVariosEmail,
        BotoesAdicionarSignatario,
        FormularioTiposAssinaturas,
        FormularioObrigatorioAssinaturaDigital,
        FormularioObrigatorioAssinaturaEletronica,
        FormularioObrigatorioAssinaturaWhatsapp,
        FormularioOpcionalAssinaturaDigital,
        FormularioOpcionalAssinaturaEletronica,
        FormularioOpcionalAssinaturaWhatsapp
    },
    props: {
        documentosSignatario: {
            type: Array,
            required: true
        },
        minutaAtual: {
            type: Object
        },
        modoEdicao: {
            type: Boolean,
            required: true
        },
        novoSignatario: {
            type: Object,
            required: true,
        },
        papeisSiganatario: {
            type: Array,
            required: true
        },
        signatariosSugestoes: {
            type: Array
        }
    },
    data() {
        return {
            cloneSignatario: {},
            dadosSignatarioExibir: {},
            habilitarSalvar: true,
            naoPodeAtualizar: false,
            stepper: 1,
            tipoAssinaturaSelecionada: ''
        }
    },
    async created() {
        if (this.modoEdicao) {
            this.cloneSignatario = _.clone(this.novoSignatario)
            this.verificarSePodeAtualizarFormaComunicacao()
            this.tipoAssinaturaSelecionada = this.novoSignatario.tipoAssinatura
            this.stepper = 2
        } else if (this.ehEfcazCLM || !this.ehPlanoFree) {
            this.buscarConfiguracoesSignatarios()
        }
        await this.buscarConsumoOrganizacao()
    },
    computed: {
        ...mapState(['loki', 'signatarios', 'consumoOrganizacao', 'login']),
        ...mapGetters(['ehEfcazCLM', 'ehPlanoFree', 'podeRepetirSignatarios']),
        disponibilidadeAssinaturaPresencial() {
            return this.novoSignatario.disponibilidadeAssinatura === 'PRESENCIAL'
        },
        ehSignatarioPagante() {
            return this.login.funcionalidadesOrganizacao.signatarioPagante
        },
        ehVariosEmail() {
            return this.ehEmailArray && this.novoSignatario.email.length > 1
        },
        ehEmailArray() {
            return this.novoSignatario.email instanceof Array
        },
        labelTipoAssinatura() {
            if (this.novoSignatario.tipoAssinatura === 'DIGITAL') {
                return 'Digital (Certificado digital A1 ou A3)'
            } else if (this.novoSignatario.tipoAssinatura === 'ELETRONICA') {
                return 'Eletrônica (E-mail)'
            } else if (this.novoSignatario.tipoAssinatura === 'WHATSAPP') {
                return 'Eletrônica (Whatsapp)'
            } else {
                return ''
            }
        },
        mostrarAssinaturaDigital() {
            return this.login.funcionalidadesOrganizacao.assinaturaDigital
        },
        possuiEmailDuplicado() {
            const emailDuplicado = this.signatarios.filter(signatario => signatario.email.toLowerCase() === this.novoSignatario.email.toLowerCase() && signatario.tipoAssinatura !== 'WHATSAPP' && signatario.id !== this.novoSignatario.signatarioId)
            return emailDuplicado.length > 0
        },
        possuiEmailDuplicadoLote() {
            const signatariosEmail = this.novoSignatario.email.map(email => email.toLowerCase())
            const emailDuplicado = this.signatarios.filter(signatario => signatariosEmail.includes(signatario.email.toLowerCase()) && signatario.tipoAssinatura !== 'WHATSAPP' && signatario.id !== this.novoSignatario.signatarioId)
            return emailDuplicado.length > 0
        },
        possuiEmailDuplicadoInserindo() {
            const signatariosEmail = this.novoSignatario.email.map(email => email.toLowerCase()).sort()
            for (let i = 0; i < signatariosEmail.length; i++) {
                if (signatariosEmail[i + 1] === signatariosEmail[i]) {
                    return true
                }
            }
            return false
        },
        possuiTelefoneDuplicado() {
            const telefoneNovoSignatario = this.novoSignatario.telefone === null ? '' : this.novoSignatario.telefone.replace(/[^a-zA-Z0-9 ]/g, "").replace(/\s/g, "")
            const signatarioId = this.novoSignatario.signatarioId
            const telefoneDuplicado = this.signatarios.filter(signatario => signatario.telefone === telefoneNovoSignatario && signatario.tipoAssinatura === 'WHATSAPP'
                && signatario.id != signatarioId)
            return telefoneDuplicado.length > 0
        },
        temTipoAssinatura() {
            return !!this.tipoAssinaturaSelecionada
        },
        tipoAssinaturaDigital() {
            return this.novoSignatario.tipoAssinatura === 'DIGITAL'
        },
        tipoAssinaturaEletronica() {
            return this.novoSignatario.tipoAssinatura === 'ELETRONICA'
        },
        tipoAssinaturaWhatsapp() {
            return this.novoSignatario.tipoAssinatura === 'WHATSAPP'
        },
        tituloTipoAssinatura() {
            return this.novoSignatario.tipoAssinatura === '' ? 'Selecione o tipo de assinatura' : 'Tipo assinatura'
        }
    },
    watch: {
        'novoSignatario.papelId': function irParaUltimaEtapa() {
            this.stepper = 3
        },
        'novoSignatario.disponibilidadeAssinatura': async function resetarOpcaoEnviarConviteSms() {
            if (this.novoSignatario.enviarTokenSMS)
                this.consumoOrganizacao.smsUtilizados -= 1

            if (this.novoSignatario.enviarConviteSMS)
                this.consumoOrganizacao.smsUtilizados -= 1

            await this.atualizarSaldoSms()
            this.novoSignatario.enviarTokenSMS = false
            this.novoSignatario.enviarConviteSMS = false
            await this.buscarConsumoOrganizacao()
        },
        'novoSignatario.tipoAssinatura': function setPrimeiroTipoAssinatura() {
            if (this.tipoAssinaturaSelecionada === '') {
                this.tipoAssinaturaSelecionada = this.novoSignatario.tipoAssinatura
            }
        },
        'stepper': function verificarDadosSignatariosExibidos() {
            this.construirDadosSignatario()
        }
    },
    methods: {
        async atualizarSaldoSms() {
            await this.$store.dispatch(actionTypes.SIGNATARIO.ATUALIZAR_SALDO_SMS)
        },
        buscarConfiguracoesSignatarios() {
            this.novoSignatario.chaveSeguranca = store.state.login.organizacaoConfiguracao.geral.signatario.chaveSeguranca
            this.novoSignatario.mensagem = store.state.login.organizacaoConfiguracao.geral.signatario.mensagem
        },
        async buscarConsumoOrganizacao() {
            await this.$store.dispatch(actionTypes.COMUN.BUSCAR_CONSUMO_ORGANIZACAO)
        },
        cancelar() {
            this.$emit('cancelar')
        },
        construirDadosSignatario() {
            if (this.tipoAssinaturaEletronica) {
                this.dadosSignatarioExibir.telefone = null
                this.dadosSignatarioExibir.email = this.novoSignatario.email
                this.dadosSignatarioExibir.disponibilidadeAssinatura = this.novoSignatario.disponibilidadeAssinatura === undefined ? '' : this.novoSignatario.disponibilidadeAssinatura
            } else if (this.tipoAssinaturaWhatsapp) {
                this.dadosSignatarioExibir.telefone = this.novoSignatario.telefone
                this.dadosSignatarioExibir.email = null
                this.dadosSignatarioExibir.disponibilidadeAssinatura = null
            } else {
                this.dadosSignatarioExibir.telefone = null
                this.dadosSignatarioExibir.email = this.novoSignatario.email
                this.dadosSignatarioExibir.disponibilidadeAssinatura = null
            }
            this.dadosSignatarioExibir.papel = this.novoSignatario.papelId === '' ? '' : this.papeisSiganatario.find((papel) => papel.value === this.novoSignatario.papelId).text
            this.dadosSignatarioExibir.documento = this.novoSignatario.documentoId === '' ? '' : this.documentosSignatario[this.novoSignatario.documentoId - 1].text
        },
        ehEmail(item) {
            return item !== null && Array.isArray(item)
        },
        formatarChaveSeguranca() {
            this.novoSignatario.chaveSeguranca = (this.novoSignatario.chaveSeguranca === null || this.novoSignatario.chaveSeguranca.length === 0) ? null : this.novoSignatario.chaveSeguranca
        },
        getColorStep(step) {
            return this.stepper === step ? 'color: var(--v-primary-base)' : ''
        },
        limparDadosSignatarioDiferente() {
            if ((this.tipoAssinaturaSelecionada === 'ELETRONICA' || this.tipoAssinaturaSelecionada === 'DIGITAL') && this.tipoAssinaturaWhatsapp) {
                this.novoSignatario.chaveSeguranca = ''
                this.novoSignatario.email = []
                this.novoSignatario.enviarConviteSMS = false
                this.novoSignatario.enviarTokenSMS = false
                this.novoSignatario.assinaturaManuscrita = false
                this.novoSignatario.disponibilidadeAssinatura = ''
                this.novoSignatario.mensagem = ''
            } else if (this.tipoAssinaturaSelecionada === 'ELETRONICA' && this.tipoAssinaturaDigital) {
                this.novoSignatario.disponibilidadeAssinatura = ''
                this.novoSignatario.enviarTokenSMS = false
            }
        },
        desabilitarSelecaoTipoAssinatura() {
            return !this.modoEdicao
        },
        async salvar() {
            let formularioObrigatorioValido = false

            formularioObrigatorioValido = await this.$validator._base.validateAll()

            if (!formularioObrigatorioValido) {
                const camposInvalidos = await this.$validator._base.errors
                if (camposInvalidos.items[0].field === 'Telefone' && !this.tipoAssinaturaWhatsapp) {
                    this.stepper = 3
                } else {
                    this.stepper = 2
                }
                return
            }
                        
            const mensagemRepeticaoHabilitada = 'Defina uma ordem de assinatura para o documento com a opção para repetir signatários habilitada, então inclua o signatário que precisa repetir.'
            
            const mensagemEmailRepetido = `Já existe um ${this.$tc("pt_br.signatario").toLowerCase()} com esse email informado. ${mensagemRepeticaoHabilitada}`
            const mensagemTelefoneRepetido = `Já existe um ${this.$tc("pt_br.signatario").toLowerCase()} com esse telefone informado. ${mensagemRepeticaoHabilitada}`
            
            if (!this.podeRepetirSignatarios || !this.minutaAtual.ordemAssinaturas) {

                if (!this.ehEmailArray && this.possuiEmailDuplicado) {
                    this.mostrarNotificacaoErro(mensagemEmailRepetido)
                    return
                }

                if (this.ehEmailArray && (this.possuiEmailDuplicadoInserindo || this.possuiEmailDuplicadoLote)) {
                    this.mostrarNotificacaoErro(mensagemEmailRepetido)
                    return
                }

                if (this.possuiTelefoneDuplicado && this.tipoAssinaturaWhatsapp) {
                    this.mostrarNotificacaoErro(mensagemTelefoneRepetido)
                    return
                }
            }

            this.validarDisponibilidadeAssinatura()
            this.validarTipoAssinatura()
            this.formatarChaveSeguranca()

            let signatario = Object.assign({}, this.novoSignatario);

            if (this.modoEdicao) {
                await this.atualizarSaldoSms()
                signatario.email = signatario.email.toString()
                this.$emit('atualizar', signatario)
            } else {
                this.$emit('salvar', signatario)
            }
        },
        async salvarOnce() {
            this.habilitarSalvar = false
            await this.salvar()
            await setTimeout(() => this.habilitarSalvar = true, 2000);
        },
        tipoAssinaturaProximo() {
            this.limparDadosSignatarioDiferente()
            this.stepper = 2
        },
        validarTipoAssinatura() {
            if (this.tipoAssinaturaDigital) {
                this.novoSignatario.disponibilidadeAssinatura = 'REMOTA'
                this.novoSignatario.enviarTokenSMS = false
            }
            if (this.tipoAssinaturaWhatsapp) {
                this.novoSignatario.disponibilidadeAssinatura = 'REMOTA'
                this.novoSignatario.mensagem = ''
                this.novoSignatario.chaveSeguranca = ''
            }
        },
        validarDisponibilidadeAssinatura() {
            if (this.disponibilidadeAssinaturaPresencial) {
                this.novoSignatario.mensagem = ''
                this.novoSignatario.chaveSeguranca = ''
            }
        },
        verificarSePodeAtualizarFormaComunicacao() {
            if (this.novoSignatario.statusSignatario === 'CONVIDADO') {
                this.naoPodeAtualizar = true
            } else if (this.novoSignatario.statusSignatario === 'NAO_CONVIDADO') {
                this.naoPodeAtualizar = false
            }
        }
    }
}
</script>

<style lang="stylus">
.v-stepper__label, .assinatura-digital-title, .assinatura-eletronica-title, .assinatura-whatsapp-title
    font-size 14px

.co-adicionar-signatario .v-text-field .v-label
    font-size 14px !important

.co-adicionar-signatario .co-telefone-signatario
    font-size 14px !important

.v-stepper__label small
    font-size 12px
    padding 2px

.co-adicionar-signatario
    .v-text-field
        font-size 14px
        border-bottom 0 solid #ddd

        .v-label
            font-size 19px

    .hint-checkbox
        font-size 12px
        color #aaa
        margin-bottom 10px

    .v-text-field__details
        display none
</style>
