<template>
    <div>
        <v-dialog value="true" width="500" persistent>
            <v-card class="co-novo-sacao">
                <v-card-title class="headline primary white--text" primary-title>
                    {{ titulo }}
                </v-card-title>

                <v-card-text>
                    <v-layout wrap>
                        <v-flex xs12 sm12 md12 class="mt-3">
                            <az-text-view
                                v-if="visualizandoSancao"
                                label="Tipo da Sanção"
                                class="titulo-sancao"
                                :text="value.tipo"
                            />
                            <v-text-field
                                v-else
                                :disabled="visualizandoSancao"
                                label="Tipo da Sanção"
                                placeholder="Sanção"
                                persistent-placeholder
                                name="Tipo da Sanção"
                                class="required-field"
                                v-model="value.tipo"
                                v-validate="'required|max:255'"
                                maxlength="255"
                                :error-messages="errors.collect('Tipo da Sanção')"
                            />
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                            <v-textarea
                                v-if="visualizandoSancao"
                                disabled
                                label="Texto"
                                v-model="value.descricao"
                            />
                            <v-textarea
                                v-else
                                label="Texto"
                                placeholder="Texto"
                                persistent-placeholder
                                name="Texto"
                                class="required-field"
                                v-model="value.descricao"
                                v-validate="'required'"
                                :error-messages="errors.collect('Texto')"
                            />
                        </v-flex>
                    </v-layout>
                    <div v-if="visualizandoSancao && arquivoSelecionado" class="label-arquivo">
                        Arquivo
                    </div>
                    <div v-else-if="!visualizandoSancao" class="label-arquivo">Arquivo</div>
                    <a @click="download" v-if="visualizandoSancao">{{ value.nomeArqOriginal }}</a>
                    <div class="upload-file-sancao" v-if="!visualizandoSancao">
                        <div v-if="arquivoSelecionado">
                            <div class="arquivo">
                                <v-icon size="60" class="icone"> mdi-clipboard-text </v-icon>
                                <span class="texto">{{ nomeArquivoSelecionado }}</span>
                            </div>
                            <div class="opcoes" @click="limparSancao">
                                <v-icon size="70px" class="icone"> mdi-window-close </v-icon>
                                <span class="texto">Excluir</span>
                            </div>
                        </div>
                        <div v-else>
                            <clm-file-upload
                                ref="clmFileUploader"
                                height="120px"
                                v-show="!arquivoSelecionado"
                                @arquivoSelecionado="setArquivoSelecionado"
                                @error="tratarErroEnvioArquivo">
                                <v-icon size="50px" class="icon" @click.prevent="$refs.clmFileUploader.openFileSelector()">
                                    mdi-cloud-upload
                                </v-icon>
                                <p class="descricao">Arraste seu arquivo aqui ou clique para fazer upload.</p>
                            </clm-file-upload>
                        </div>
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#ccc" text @click="$emit('cancelar')">
                        {{ btnCancelarFechar }}
                    </v-btn>
                    <v-btn color="green" text @click="salvar" v-if="!visualizandoSancao">
                        Confirmar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {actionTypes} from '@/commons/constants'
import ClmFileUpload from '@/views/contrato/commons/ClmFileUpload'

export default {
    name: 'novo-sancao',
    components: {ClmFileUpload},
    props: ['value', 'visualizandoSancao'],
    data() {
        return {
            arquivoSelecionado: false,
            nomeArquivoSelecionado: ''
        }
    },
    computed: {
        ...mapState(['minutaAtual']),
        btnCancelarFechar() {
            return this.visualizandoSancao ? 'Fechar' : 'Cancelar'
        },
        titulo() {
            return this.visualizandoSancao ? 'Visualizar sanção' : 'Adicionar sanção'
        }
    },
    methods: {
        async download() {
            const arquivoBase64 = await this.$store.dispatch(actionTypes.DOWNLOAD_ARQUIVO_SANCAO, {
                contratoId: this.$store.state.contrato.id,
                minutaId: this.minutaAtual.id,
                sancaoId: this.value.sancaoId
            })
            this.salvarArquivo(this.value.nomeArqOriginal, arquivoBase64, this.value.tipoArquivo)
        },
        limparSancao() {
            this.arquivoSelecionado = false
            this.$emit('clean')
        },
        async salvar() {
            const valido = await this.$validator._base.validateAll()
            if (!valido) {
                return
            }
            this.$emit('inserir')
        },
        setArquivoSelecionado(arquivo) {
            this.arquivoSelecionado = true
            this.nomeArquivoSelecionado = arquivo.name
            this.$emit('upload', arquivo)
        }
    }
}
</script>

<style lang="stylus">
.co-novo-sacao
    .titulo-sancao p
        overflow hidden
        text-overflow ellipsis
        white-space nowrap

    .descricao
        margin-top 0 !important

    .label-arquivo
        font-size 12px
        color rgba(0, 0, 0, .54)
        padding-bottom 5px

    .upload-file-sancao
        .az-drop-file
            padding 20px 0 !important

        .input-file
            width 0 !important
            height 0 !important

        .arquivo
            display block
            text-align center
            margin: 10px 0
            height 103px
            border 2px dashed #ccc
            margin-top: 7px

            .icone
                color #777
                cursor pointer
                margin-top 7px

            .texto
                text-align center
                display block
                color #777

        .opcoes
            display none
            text-align center
            margin: 10px 0
            height 100px
            border 2px dashed #ccc

            .icone
                color #777
                cursor pointer

            .texto
                text-align center
                display block
                color #777

        &:hover
            background-color rgba(0, 0, 0, .4)

            .arquivo
                display none

            .opcoes
                display block

            .icone
                color white

            .texto
                color white
</style>