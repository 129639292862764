<template>
    <div class="co-pasta-compartilhamento-usuarios">
        <div class="titulo-lista">Usuários com acesso</div>

        <div class="item-lista mt-2" v-for="usuario in usuarios" :key="usuario.id">
            <div class="dados-usuario">
                <div class="nome">{{ usuario.nome }}</div>
                <div class="email">{{ usuario.email }}</div>
            </div>

            <div v-if="ehProprietario(usuario)" class="papel text-color-300--text">
                Proprietário
            </div>

            <div class="acoes">
                <span v-if="ehAdministrador(usuario)" class="papel text-color-300--text">
                    Administrador
                </span>

                <v-icon v-else size="20" color="secondary-200" @click="removerAcessoUsuario(usuario)">
                    mdi-delete
                </v-icon>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PastaCompartilhamentoUsuarios',
    props: {
        usuarios: {
            required: true
        }
    },
    methods: {
        ehAdministrador(usuario) {
            return usuario.administrador
        },
        ehProprietario(usuario) {
            return usuario.proprietario
        },
        fechar() {
            this.$emit('fechar')
        },
        removerAcessoUsuario(usuario) {
            this.$emit('remover', usuario)
        }
    }
}
</script>

<style lang="stylus">
.co-pasta-compartilhamento-usuarios
    padding 10px 20px

    .titulo-lista
        font-size 12px
        color var(--text-color-300)
        padding-bottom 0
        display flex
        justify-content space-between
        padding-top 10px

    .item-lista
        padding 5px 0
        display flex
        justify-content space-between

        .dados-usuario
            display block

            .nome
                font-size: 14px
                color var(--text-color-300)

            .email
                font-size 12px
                color var(--text-color-200)

        .papel
            display flex
            align-items center
            justify-content end

        .acoes
            display flex
            justify-content end
</style>