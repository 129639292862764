import CookiesUtils from '@/commons/utils/CookiesUtils'
import {mutationTypes} from '@/commons/constants'
import Vue from 'vue'

export default {
    [mutationTypes.FILTROS.LIMPAR_FILTROS_BUSCA](state) {
        const filtros = state.meusContratosBusca.filtros
        filtros.nome.value =  filtros.nome.default
        filtros.tags.value =  filtros.tags.default
        filtros.cor.value =  filtros.cor.default
        filtros.dataCriacaoInicio.value =  filtros.dataCriacaoInicio.default
        filtros.dataCriacaoFim.value =  filtros.dataCriacaoFim.default
        Vue.set(state.meusContratosBusca, 'filtros', filtros)

        const cookies = state.cookies
        cookies.filtros.cor = filtros.cor.default
        cookies.filtros.dataCriacaoInicio =  filtros.dataCriacaoInicio.default
        cookies.filtros.dataCriacaoFim =  filtros.dataCriacaoFim.default
        cookies.filtros.nome = filtros.nome.default
        cookies.filtros.tags = filtros.tags.default
        Vue.set(state, 'cookies', cookies)
        CookiesUtils.atualizarCookies(cookies)
    },

    [mutationTypes.FILTROS.SET_FILTROS_BUSCA](state, filtros) {
        Vue.set(state.meusContratosBusca, 'filtros', filtros)

        const cookies = state.cookies
        cookies.filtros.cor = filtros.cor.value
        cookies.filtros.dataCriacaoInicio =  filtros.dataCriacaoInicio.value
        cookies.filtros.dataCriacaoFim =  filtros.dataCriacaoFim.value
        cookies.filtros.formalizacao = filtros.formalizacao.value
        cookies.filtros.nome = filtros.nome.value
        cookies.filtros.tags = filtros.tags.value
        Vue.set(state, 'cookies', cookies)
        CookiesUtils.atualizarCookies(cookies)
    },

    [mutationTypes.FILTROS.RECUPERAR_FILTROS_BUSCA_COOKIES](state, cookies) {
        const filtros = state.meusContratosBusca.filtros
        filtros.cor.value = cookies.filtros.cor
        filtros.dataCriacaoInicio.value = cookies.filtros.dataCriacaoInicio
        filtros.dataCriacaoFim.value = cookies.filtros.dataCriacaoFim
        filtros.formalizacao.value = cookies.filtros.formalizacao
        filtros.nome.value = cookies.filtros.nome
        filtros.tags.value = cookies.filtros.tags
        Vue.set(state.meusContratosBusca, 'filtros', filtros)
    },
}