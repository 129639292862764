<template>
    <div class="co-importar-signatarios-dialogs">
        <dialog-importar-signatarios
            v-if="abrirDialogs.dialogImportarSignatarios"
            :dialog="abrirDialogs.dialogImportarSignatarios"
            :papeis-signatario="papeisSignatario"
            :documentos-signatario="documentosSignatario"
            :salvar-backend="salvarBackend"
            @fechar="fecharDialogImportarSignatarios"
            @concluir-importar="concluirImportar"/>

        <dialog-importar-grupos
            v-if="abrirDialogs.dialogImportarGrupos"
            :dialog="abrirDialogs.dialogImportarGrupos"
            :papeis-signatario="papeisSignatario"
            :salvar-backend="salvarBackend"
            @fechar="fecharDialogImportarGrupos"
            @concluir-importar="concluirImportar"/>
    </div>
</template>

<script>

import DialogImportarSignatarios from './dialogs/DialogImportarSignatarios'
import DialogImportarGrupos from './dialogs/DialogImportarGrupos'
import {mapGetters} from 'vuex'

export default {
    name: 'ImportarSignatariosDialogs',
    components: {
        DialogImportarGrupos,
        DialogImportarSignatarios,
    },
    props: {
        documentosSignatario: {
            type: Array,
            required: true
        },
        papeisSignatario: {
            type: Array,
            required: true
        },
        salvarBackend: {
            type: Boolean,
            default: false
        },
        abrirDialogs: {
            type: Object,
            default() {
                return {
                    dialogImportarSignatarios: false,
                    dialogImportarGrupos: false
                }
            }
        }
    },
    computed: {
        ...mapGetters(['podeRepetirSignatarios']),
    },
    methods: {
        fecharDialogImportarGrupos() {
            this.abrirDialogs.dialogImportarGrupos = false
        },
        fecharDialogImportarSignatarios() {
            this.abrirDialogs.dialogImportarSignatarios = false
        },
        concluirImportarSalvarBackend(signatariosImportadosOutput) {
            if (signatariosImportadosOutput.sucesso.length > 0) {
                this.$emit('adicionar-signatarios', signatariosImportadosOutput.sucesso)
                this.mostrarNotificacaoSucesso(`${this.$tc("pt_br.signatario")}(s) adicionado(s) com sucesso!`)
            }

            if (signatariosImportadosOutput.falha.length > 0) {
                const errosSignatarioRepetidos = signatariosImportadosOutput.falha.filter(signatarioFalha => signatarioFalha.mensagem === 'Já adicionado')
                
                if (errosSignatarioRepetidos.length > 0) {
                    const ehRepetirSignatariosAtivo = this.podeRepetirSignatarios ? '' : 'ative a opção nas configurações e'

                    if (errosSignatarioRepetidos.length == 1) {
                        this.mostrarNotificacaoErro(`O ${this.$tc("pt_br.signatario").toLowerCase()} ${errosSignatarioRepetidos[0].nome} já está presente no ${this.$tc("pt_br.documento").toLowerCase()}. Para incluir um mesmo ${this.$tc("pt_br.signatario").toLowerCase()} ${ehRepetirSignatariosAtivo} defina uma ordem de assinaturas para o ${this.$tc("pt_br.documento").toLowerCase()}, então inclua o ${this.$tc("pt_br.signatario").toLowerCase()} que precisa repetir.`)
                    } else {
                        this.mostrarNotificacaoErro(`Alguns ${this.$tc("pt_br.signatario", 2).toLowerCase()} já estão presentes no ${this.$tc("pt_br.documento").toLowerCase()}. Para incluir um mesmo ${this.$tc("pt_br.signatario").toLowerCase()} ${ehRepetirSignatariosAtivo} defina uma ordem de assinaturas para o ${this.$tc("pt_br.documento").toLowerCase()}, então inclua o ${this.$tc("pt_br.signatario").toLowerCase()} que precisa repetir.`)
                    }
                }
            }

            const errosSignatarioWpp = signatariosImportadosOutput.falha.filter(signatarioFalha => signatarioFalha.mensagem === 'Assinatura Whats App')

            if (errosSignatarioWpp.length > 0) {
                if (errosSignatarioWpp.length === 1) {
                    this.mostrarNotificacaoErro(`O ${this.$tc("pt_br.signatario").toLowerCase()} ${errosSignatarioWpp[0].nome} está com o tipo de assinatura WhatsApp em um grupo selecionado. A funcionalidade foi descontinuada, altere o tipo de assinatura para e-mail.`)
                } else {
                    this.mostrarNotificacaoErro(`Alguns ${this.$tc("pt_br.signatario", 2).toLowerCase()} estão com o tipo de assinatura WhatsApp em um grupo selecionado. A funcionalidade foi descontinuada, altere o tipo de assinatura para e-mail.`)
                }
            }
        },
        concluirImportar(signatariosImportados) {
            if (this.salvarBackend) {
                this.concluirImportarSalvarBackend(signatariosImportados)
            } else {
                this.$emit('adicionar-signatarios', signatariosImportados)
            }

            this.fecharDialogImportarSignatarios()
            this.fecharDialogImportarGrupos()
        }
    }
}
</script>