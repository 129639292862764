<template>
    <div class="co-nome-contato">
        <v-text-field
            v-model="value.nome"
            v-validate="validarCampo()"
            :class="campoRequerido ? 'required-field' : ''"
            :error-messages="errors.collect(label)"
            :label="label"
            :name="label"
            :placeholder="placeholder"
            persistent-placeholder
            :disabled="desabilitarCampo"
            @input="toTitleCase"
        />

        <div v-if="descricao" class="hint-checkbox">
            {{ descricaoCampo }}
        </div>
    </div>

</template>

<script>
export default {
    name: 'NomeContato',
    props: {
        value: {
            required: true
        },
        campoRequerido: {
            type: Boolean,
            default: false
        },
        desabilitarCampo: {
            type: Boolean,
            default: false
        },
        descricao: {
            type: Boolean
        },
        titleCase: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        descricaoCampo() {
            return `Aqui você pode definir um nome o qual usuário deseja ser chamado.`
        },
        label() {
            return `Nome do usuário`
        },
        placeholder() {
            return `Informe o nome do usuário`
        }
    },
    methods: {
        validarCampo() {
            return this.campoRequerido ? 'required' : ''
        },
        toTitleCase() {
            if (this.titleCase) {
                this.value.nome = this.value.nome.replace(/(^|\s)\S/g, function (t) {
                    return t.toUpperCase()
                });
            }
        }
    }
}
</script>