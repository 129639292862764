<template>
    <div class="co-telefone-signatario">
        <div class="label-telefone">
            Telefone <span v-if="campoRequerido" style="color: #ff5252">*</span>
        </div>

        <div style="display: flex;align-items: center;">
            <v-flex class="box-selecionar-ddi" @click="abrirDialogPais">
                <span class="texto-ddi" :style="getStyleDisabled">
                    {{ isoCode }} {{ ddiSelecionado }}
                    <v-icon size="20" :style="getStyleDisabled"> mdi-chevron-down </v-icon>
                </span>
            </v-flex>

            <v-flex>
                <v-text-field
                    v-model="value.telefone"
                    v-mask="mascara"
                    v-validate="validarCampo()"
                    :class="campoRequerido ? 'required-field' : ''"
                    :disabled="desabilitarCampo"
                    :error-messages="errors.collect('Telefone')"
                    autocomplete="off"
                    dense
                    label=""
                    name="Telefone"
                    placeholder="(DDD) 000000000"
                    style="margin: 0 5px 0 10px"
                />
            </v-flex>
        </div>

        <v-switch
            v-if="campoRequerido"
            v-model="value.skipFotoWpp"
            label="Solicitar autenticação por Selfie"
            :true-value="false"
            :false-value="true"/>

        <dialog-pais
            :dialog="dialogPais"
            @fechar="fecharDialogPais"
            @adicionar-ddi="adicionarDdi"
        />

        <div v-if="descricao" class="hint-checkbox">
            {{ descricaoCampo }}
        </div>
    </div>
</template>

<script>
import DialogPais from '@/commons/components/dialogs/DialogPais'

export default {
    name: 'TelefoneSignatario',
    components: {DialogPais},
    props: {
        value: {
            required: true
        },
        campoRequerido: {
            type: Boolean,
            default: false
        },
        descricao: {
            type: Boolean
        },
        desabilitarCampo: {
            type: Boolean
        }
    },
    data: () => ({
        dialogPais: false,
        isoCode: 'BR +',
        ddiDefault: '55'
    }),
    computed: {
        descricaoCampo() {
            return `Número de telefone que o ${this.$tc("pt_br.signatario").toLowerCase()} irá usar para receber as notificações`
        },
        ddiSelecionado() {
            return this.value.ddi == null ? this.ddiDefault : this.value.ddi
        },
        getStyleDisabled() {
            return !this.desabilitarCampo ? 'color: #777777' : 'color: #cccccc'
        },
        mascara() {
            return this.ddiSelecionado == '55' ? '(##) # ####-####' : '############'
        }
    },
    methods: {
        abrirDialogPais() {
            if (!this.desabilitarCampo) {
                this.dialogPais = true
            }
        },
        adicionarDdi(item) {
            this.ddiDefault = item.fone
            this.isoCode = item.iso
            this.value.ddi = item.fone
        },
        fecharDialogPais() {
            this.dialogPais = false
        },
        validarCampo() {
            return this.campoRequerido ? 'required' : ''
        }
    }
}
</script>

<style lang="stylus">
.co-telefone-signatario
    .label-telefone
        color #777777

    .box-selecionar-ddi
        width 100px
        border-right 1px solid #cccccc
        cursor pointer

        .texto-ddi
            font-weight bold
</style>