<template>
    <div class="co-nenhum-item-encontrado">
        <div class="abas">
            <div class="titulo">Itens</div>
        </div>

        <img height="140px" src="@/assets/ops/feliz.gif" class="mt-3">

        <div>Olá, vejo que você ainda não adicionou</div>

        <div class="px-2">
            <p class="ma-0">nenhum ítem ao seu {{ $tc("pt_br.documento").toLowerCase() }}.</p>

            <p class="ma-0">
                Controle o valor total do {{ $tc("pt_br.documento").toLowerCase() }} adicionando itens.
            </p>
            
            <p class="ma-0">
                Os valores inseridos serão visualizados por usuários gestores e administradores da plataforma.
            </p>
        </div>

        <v-btn :disabled="ehSemFuncionalidade" class="mt-3" color="green lighten-3" depressed @click="cadastrarItem">
            <span style="color: white">ADICIONAR ÍTEM</span>
        </v-btn>
    </div>
</template>

<script>
export default {
    name: 'NenhumItemEncontrado',
    props: {
        ehSemFuncionalidade: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        cadastrarItem() {
            this.$emit('cadastrar-item')
        }
    }
}
</script>

<style lang="stylus">
.co-nenhum-item-encontrado
    height calc(100% - 159px)
    overflow-y auto
    display block
    text-align center
    color #777

    a
        color #66BB6A

    .abas
        display flex
        justify-content space-between
        height 50px
        padding 0 20px
        vertical-align center
        border-bottom 1px solid #ccc

        .titulo
            display flex
            align-items center
            color #777
            font-size 14px
            text-transform uppercase
</style>