import Vue from 'vue'
import file from './file'
import loading from './loading'
import alert from './alert'
import pastas from './pastas'
import plataforma from './plataforma'
import contratos from './contratos'
import organizacao from './organizacao'

Vue.mixin(file)
Vue.mixin(loading)
Vue.mixin(alert)
Vue.mixin(pastas)
Vue.mixin(plataforma)
Vue.mixin(contratos)
Vue.mixin(organizacao)

