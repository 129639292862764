<template>
    <div class="co-animacao-confirmacao" :style="getHeight">

        <div v-if="mostrarAnimacaoSucesso"
             class="divAnimacao">
            <img v-if="mostrarImagemConfirmacaoRealizada"
                 class="img-confirmacao-realizada"
                 src="@/assets/confirmacao-realizada.gif">
        </div>

    </div>
</template>

<script>
export default {
    name: 'AnimacaoConfirmacao',
    props: ['mostrarAnimacaoSucesso'],
    data: () => ({
        alturaBotao: '0px',
        mostrarImagemConfirmacaoRealizada: false
    }),
    watch: {
        'mostrarAnimacaoSucesso'() {
            this.alturaBotao = '100vh'
            this.mostrarImagemConfirmacaoRealizada = true
        }
    },
    computed: {
        getHeight() {
            return 'height: ' + this.alturaBotao
        }
    }
}
</script>

<style lang="stylus">
.co-animacao-confirmacao
    position fixed
    width 100vw
    bottom 0
    transition height 0.6s
    z-index 2000

    .divAnimacao
        height 100%
        background-color #4CAF50
        text-align center

    .img-confirmacao-realizada
        height 200px
        margin 0
        position relative
        top 50%
        transform translate(0, -50%)

</style>