<template>
    <div>
        <v-row class="justify-center" no-gutters>
            <card-plano-personalizado
                v-for="(planosInfos) in planosInformacoes" :key="planosInfos.titulo"
                :plano="planosInfos"
                @cancelar-plano="abrirDialogCancelarPlano"
                class="my-3"
            />
            
            <dialog-default-confirmar-acao
                v-if="dialogDefault"
                apenas-acao-principal
                :dialog="dialogDefault"
                :sentimento="dialog.sentimento"
                :titulo="dialog.titulo"
                :subtitulo="dialog.subtitulo"
                :label-botao-principal="dialog.labelBotaoPrincipal"
                @acao-principal="dialog.acaoPrincipal"
                @fechar="dialog.acaoPrincipal"
            />

            <dialog-cancelar-plano
                v-if="dialogCancelarPlano"
                apenas-acao-principal
                :dialog="dialogCancelarPlano"
                @fechar="fecharDialogCancelarPlano"
                @solicitacao-realizada="concluirSolicitacaoCancelamento"
            />
        </v-row>
    </div>
</template>

<script>

import {mapGetters} from 'vuex'
import DialogDefaultConfirmarAcao from '@/commons/components/dialogs/DialogDefaultConfirmarAcao'
import CardPlanoPersonalizado from './components/CardPlanoPersonalizado'
import DialogCancelarPlano from '../dialog/DialogCancelarPlano'
import actionTypes from '@/commons/constants/action-types'
import _ from 'lodash'

export default {
    name: 'PlanoPersonalizado',
    components: {DialogCancelarPlano, DialogDefaultConfirmarAcao, CardPlanoPersonalizado},
    data() {
        return {
            planosInformacoes: [
                {
                    titulo: 'SOB MEDIDA',
                    descricao: 'Fale com o nosso comercial para receber um plano de acordo com as necessidades da sua empresa.',
                    beneficios: [],
                }
            ],
            dialogCancelarPlano: false,
            dialogDefault: false,
            dialog: {
                titulo: '',
                subtitulo: '',
                labelBotaoPrincipal: '',
                sentimento: '',
                acaoPrincipal: null
            }
        }
    },
    computed: {
        ...mapGetters(['nomePlano'])
    },
    methods: {
        abrirDialogCancelarPlano() {
            this.dialogCancelarPlano = true
        },
        concluirSolicitacaoCancelamento(cancelamentoOutput) {
            this.dialogCancelarPlano = false

            const mensagemParaPlanosSemAssinaturaExterno = 'Solicitação de cancelamento realizada.<br/><br/> Ficamos tristes pela sua solicitação de cancelamento com a nossa plataforma, mas estamos gratos pela sua confiança na BeSign até o momento. Em breve você receberá um e-mail com as orientações e confirmação do seu cancelamento. Seu acesso continuará disponível dentro da plataforma após o processamento do seu cancelamento.<br/><br/> Caso deseje continuar enviando documentos, poderá recontratar um plano ou adquirir documentos sem recorrência.'
            let mensagemPlanoNaoExpiradoNaSolicitacao = ''
            
            if (!cancelamentoOutput.planoFoiCanceladoNaSolicitacao) {
                mensagemPlanoNaoExpiradoNaSolicitacao = ` Você poderá realizar envio de documentos até o dia <strong>${cancelamentoOutput.dataVigenciaPlano}</strong>, após essa data seu plano passará a ser FREE e seu acesso será mantido.`
            }
            
            const mensagemParaPlanosComAssinaturaExterno = `Cancelamento concluído.<br/><br/> Ficamos tristes pela sua solicitação de cancelamento com a nossa plataforma, mas estamos gratos pela sua confiança na BeSign até o momento.${mensagemPlanoNaoExpiradoNaSolicitacao} <br/><br/>Caso deseje continuar enviando documentos, poderá recontratar um plano ou adquirir documentos sem recorrência.`

            let dialogSolicitarCancelamentoConcluido = {
                sentimento: 'triste',
                titulo: 'Solicitação de cancelamento realizada',
                subtitulo: cancelamentoOutput.ehPlanoComAssinaturaExterna ? mensagemParaPlanosComAssinaturaExterno : mensagemParaPlanosSemAssinaturaExterno,
                labelBotaoPrincipal: 'Ok',
                acaoPrincipal: this.fecharDialogAtualizandoPlano
            }

            this.dialog = _.merge(this.dialog, dialogSolicitarCancelamentoConcluido)
            this.dialogDefault = true
        },
        fecharDialogCancelarPlano() {
            this.dialogCancelarPlano = false
            this.$router.push({name: 'meus'})
        },
        async fecharDialogAtualizandoPlano() {
            await this.$store.dispatch(actionTypes.LOGIN.BUSCAR_ORGANIZACAO_PLANO)
            await this.$store.dispatch(actionTypes.LOGIN.BUSCAR_FUNCIONALIDADES_ORGANIZACAO)
            await this.$store.dispatch(actionTypes.COMUN.BUSCAR_CONSUMO_ORGANIZACAO)

            this.fecharDialogDefault()
        },
        fecharDialogDefault() {
            this.dialogDefault = false
        },
    }
}
</script>