export default {
    'br.com.azi.hal.usuario.exception.CpfJaExisteException': {mensagem: 'O CPF informado já está sendo utilizado por outro usuário.'},
    'br.com.azi.hal.usuario.exception.EmailJaExisteException': {mensagem: 'O e-mail informado já está sendo utilizado por outro usuário.'},
    'br.com.azi.efcaz.clm.configuracao.spec.plano.organizacao.consumo.exception.LimiteUsuariosAtingidoException': {
        ignorarNotificacaoDefault: true
    },
    'br.com.azi.efcaz.clm.configuracao.spec.plano.organizacao.consumo.exception.LimiteDocumentosAtingidoException': {
        ignorarNotificacaoDefault: true
    },
    'br.com.azi.efcaz.clm.configuracao.spec.exception.OrganizacaoJaExisteException': {
        mensagem: 'Organização já cadastrada.',
        ignorarNotificacaoDefault: false
    },
    'br.com.azi.efcaz.clm.configuracao.spec.exception.OrganizacaoNaoExisteException': {mensagem: 'Não foi possível localizar a organização.'},
    'br.com.azi.efcaz.clm.contrato.spec.contrato.contrato.exception.ContratoComNomeDuplicadoException': {mensagem: 'Já existe um contrato com o mesmo nome.'},
    'br.com.azi.efcaz.clm.contrato.spec.contrato.contrato.exception.ContratoNaoExisteException': {mensagem: 'Não foi possível localizar o contrato'},
    'br.com.azi.efcaz.clm.contrato.spec.minuta.exception.DocumentoJaAssinadoException': {mensagem: 'Não é possível excluir documentos com assinaturas.'},
    'br.com.azi.efcaz.clm.contrato.spec.minuta.exception.MinutaNaoExisteException': {mensagem: 'Não foi possível localizar a minuta.'},
    'br.com.azi.efcaz.clm.contrato.spec.minuta.exception.RenovacaoSemPublicarMinutaException': {mensagem: 'Não é possível renovar contrato não formalizado.'},
    'br.com.azi.efcaz.clm.contrato.spec.minuta.exception.AssinaturasPendentesException': {mensagem: 'Não é possível formalizar o contrato pois existem assinaturas pendentes.'},
    'br.com.azi.efcaz.clm.contrato.spec.pasta.exception.UsuarioNaoPossuiPemissaoNaPastaException': {mensagem: 'Não é possível mover contratos para pastas em que o usuário não possui acesso.'},
    'br.com.azi.efcaz.clm.pasta.spec.exception.PastaComNomeDuplicadoException': {mensagem: 'Já existe uma pasta com o nome informado'},
    'br.com.azi.efcaz.clm.pasta.spec.exception.UsuarioNaoPossuiPemissaoParaRemoverPastaException': {mensagem: 'Usuário não possui permissão para excluir a pasta'},
    'br.com.azi.efcaz.clm.pasta.spec.exception.PastaComContratosNaoPodeSerRemovidaException': {mensagem: 'Não é possível excluir uma pasta com contratos'},
    'br.com.azi.efcaz.clm.contrato.spec.signatario.exception.SignatarioJaEstaAssinandoPorWhatsappException': {mensagem: 'Signatário esta assinando pelo WhatsApp no momento.\nAguarde concluir a assinatura para adicionar novamente.'},
}
