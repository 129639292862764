<template>
    <v-row id="meus-contratos-barra-saldo" class="co-meus-contratos-barra-saldo d-flex align-center" no-gutters>
        <v-row no-gutters cols="11" sm="9" :class="ehMobile ? 'd-flex justify-space-around mt-3' : 'd-flex justify-space-around ml-2'">
            <v-col>
                <v-row no-gutters>
                    Plano
                </v-row>
                <v-row no-gutters class="font-weight-bold plano">
                    {{ tratarTituloPlano(nomePlano) }}

                    <v-tooltip bottom color="primary lighten-1" top v-if="!ehPlanoFree && !ehEfcazCLM">
                        <template v-slot:activator="{ on }">
                            <v-icon class="ml-1" color="white" size="15" v-on="on">
                                mdi-information
                            </v-icon>
                        </template>
                        <div class="tooltip">
                            <span>Nome do Plano: {{ tratarTituloPlano(nomePlano) }}</span> <br/>
                            <span>Status da assinatura: {{ definirStatusAssinatura }}</span> <br/>
                            <span>Valor do plano: {{ formatarReal(login.organizacaoPlano.valorMensalidadePlano) }}</span>
                            <span v-if="ehPlanoAnual"><br/>Plano ativo até: {{ formatarData(formatarDataPlanoAnual) }}</span>
                            <span v-if="ehAssinaturaComRecorrencia"><br/>Data da próxima cobrança: {{ formatarData(login.organizacaoPlano.dataVencimentoPlano) }}</span>
                            <span v-if="ehPlanoEmCancelamento || ehPlanoExpirado"><br/>Plano vigente até: {{ formatarData(consumoOrganizacao.dataExpiracaoPlano) }}</span>
                        </div>
                    </v-tooltip>
                </v-row>
            </v-col>
            <v-col>
                <v-row no-gutters>
                    Documento
                </v-row>
                <v-row no-gutters class="font-weight-bold">
                    {{ quantidadeDocumentos }}

                    <v-tooltip bottom color="primary lighten-1" top>
                        <template v-slot:activator="{ on }">
                            <v-icon v-if="consumoOrganizacao.qtdDocumentosRcorrente > 0" class="ml-1" color="white" size="15" v-on="on">
                                mdi-information
                            </v-icon>
                        </template>
                        <div class="tooltip">
                            <span>Saldo de {{ consumoOrganizacao.qtdDocumentosRcorrente }} documentos mensais</span> <br/>
                            <span v-if="ehPlanoComDiaDeRenovacao">Renova em {{ consumoOrganizacao.diasRenovacaoDocumentos }} dias</span>
                        </div>
                    </v-tooltip>
                </v-row>
            </v-col>
            <v-col>
                <v-row no-gutters>
                    Usuários
                </v-row>
                <v-row no-gutters class="font-weight-bold">
                    {{ quantidadeUsuarios }}
                </v-row>
            </v-col>
            <!-- <v-col>
                <v-row no-gutters>
                    WhatsApp
                </v-row>
                <v-row no-gutters class="font-weight-bold">
                    {{ quantidadeWhatsapp }}
                </v-row>
            </v-col> -->
        </v-row>

        <v-spacer v-if="!ehMobile"/>
        <v-col v-if="!ehEfcazCLM" cols="12" sm="3" :class="ehMobile ? 'd-flex justify-center my-3' : 'd-flex justify-end mr-7'">
            <v-btn outlined color="primary"
                   class="adquirir"
                   @click="abrirLoja">
                {{ botaoAdquirirlabel }}
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import DateTimeUtils from '@/commons/utils/DateTimeUtils';
import CurrencyFormatterUtils from '@/commons/utils/CurrencyFormatterUtils';

export default {
    name: 'BarraSaldo',
    computed: {
        ...mapState(['login', 'consumoOrganizacao']),
        ...mapGetters(['ehEfcazCLM', 'ehPlanoFree', 'ehPlanoPro', 'nomePlano', 'ehPlanoEmCancelamento', 'ehPlanoExpirado', 'ehPlanoAnual']),
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        },
        ehAssinaturaComRecorrencia() {
            return this.login.organizacaoPlano.dataVencimentoPlano !== null && this.ehAssinaturaComIdExterno
        },
        ehAssinaturaComIdExterno() {
            return this.login.organizacaoPlano.ehAssinaturaComIdExterno
        },
        ehPlanoComDiaDeRenovacao() {
            return !this.ehPlanoEmCancelamento && !this.ehPlanoExpirado && this.consumoOrganizacao.diasRenovacaoDocumentos !== null
        },
        quantidadeDocumentos() {
            return this.consumoOrganizacao.documentosIlimitados ? 'Ilimitados' : this.consumoOrganizacao.totalDocumentos - this.consumoOrganizacao.documentosUtilizados
        },
        quantidadeUsuarios() {
            return this.consumoOrganizacao.usuariosIlimitados ? 'Ilimitados' : `${this.consumoOrganizacao.usuariosUtilizados}/${this.consumoOrganizacao.totalUsuarios}`
        },
        quantidadeWhatsapp() {
            return this.consumoOrganizacao.totalWhatsapp - this.consumoOrganizacao.whatsappUtilizados
        },
        definirStatusAssinatura() {
            return this.ehPlanoEmCancelamento ? 'Cancelado' : this.ehPlanoExpirado ? 'Expirado' : 'Ativo'
        },
        botaoAdquirirlabel() {
            if (this.ehPlanoFree) {
                return 'Quero Contratar'
            } else if (this.ehPlanoPro) {
                return 'Adquirir Saldos'
            } else {
                return 'Adquirir Planos e Saldos'
            }
        },
        formatarDataPlanoAnual() {
            const dataAdesao = new Date(this.login.organizacaoPlano.adesaoPlano);
            const ano = dataAdesao.getFullYear();
            const mes = dataAdesao.getMonth();
            const dia = dataAdesao.getDate();
            const dataFinal = new Date(ano + 1, mes, dia);

            return dataFinal
        },
    },
    methods: {
        abrirLoja() {
            this.$router.push({name: 'planos'})
        },
        formatarData(valor) {
            if (!valor) return ''
            return DateTimeUtils.converterDataLocal(new Date(valor));
        },
        formatarReal(valor) {
            return CurrencyFormatterUtils.formatarBrl(valor)
        },
        tratarTituloPlano(titulo) {
            let tituloAux = titulo.toLowerCase().replaceAll('_', ' ')
            return tituloAux.replace(/(^\w{1})|(\s+\w{1})/g, letra => letra.toUpperCase());
        },
    }
}
</script>

<style lang="stylus">
.co-meus-contratos-barra-saldo
    background-color var(--primary)
    color white
    height 60px

    .v-tooltip .v-tooltip__content
        opacity 1 !important

    .plano
        white-space nowrap
        width max-content

    .col
        flex-grow 0

    .adquirir
        background-color var(--background-100)

@media (max-width: 720px)
    .co-meus-contratos-barra-saldo
        height auto
</style>