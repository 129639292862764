<template>
    <v-col class="co-dados-principais">
        <v-row no-gutters class="d-flex align-center">
            <h3 class="primary--text">{{ $tc("pt_br.signatario", 2) }} ({{ signatarios.length }})</h3>

            <v-btn icon @click="exibirSignatarioCompleto = !exibirSignatarioCompleto">
                <v-icon color="primary">{{ exibirSignatarioCompleto ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
            </v-btn>
        </v-row>

        <v-row no-gutters class="d-flex align-center">
            <v-card class="full-size">
                <v-card-text class="d-flex px-5 justify-space-between">
                    <span>Incluir-me como {{ $tc("pt_br.signatario").toLowerCase() }}</span>
                    <v-switch
                        class="ma-0 pa-0"
                        v-model="incluirMe"
                        color="primary"
                        dense
                        @change="addOrRemoveMe()"
                        hide-details
                    />
                </v-card-text>
            </v-card>
        </v-row>

        <v-row no-gutters class="d-flex align-center mt-5">
            <importar-signatarios-menu :abrir-dialogs="abrirDialogs" />

            <v-spacer/>

            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon @click="adicionar">
                        <v-icon color="primary"> mdi-plus-circle </v-icon>
                    </v-btn>
                </template>

                <span>Adicionar {{ $tc("pt_br.signatario", 2) }}</span>
            </v-tooltip>
        </v-row>

        <v-row no-gutters class="d-flex align-center mt-5">
            <draggable
                tag="ul"
                :list="signatarios"
                v-bind="opcoesDraggable"
                class="draggable-wrapper d-flex pa-0"
                :class="podeMoverSignatario ? 'pode-arrastar': ''"
                :disabled="!podeMoverSignatario"
            >
                <transition-group tag="li" class="transition-wrapper" type="transition">
                    <div v-for="(signatario, index) in signatarios" 
                        :key="index" 
                        class="d-flex align-center card-wrapper"
                    >
                        <div class="d-flex align-center dragging-icon-wrapper" :class="exibirIndicador">
                            <v-icon class="icone-ordem">
                                mdi-unfold-more-horizontal
                            </v-icon>
                            <span>{{ index + 1 }}</span>
                        </div>
                        <card-dados-principais
                            :signatario="signatario"
                            class="full-size"
                            :pode-excluir-signatario="podeExcluirSignatario"
                            :papeis-signatario="papeisSignatario"
                            :tipos-assinatura="tiposAssinatura"
                            :exibir-signatario-completo="exibirSignatarioCompleto"
                            :signatarios-sugestoes="signatariosSugestoes"
                            @remover="remover"
                            @validaTipoAssinatura="validaTipoAssinatura"
                        />
                    </div>
                </transition-group>
            </draggable>
        </v-row>

        <importar-signatarios-dialogs
            :papeis-signatario="papeisSignatario"
            :documentos-signatario="documentosSignatario"
            :abrir-dialogs="abrirDialogs"
            @adicionar-signatarios="adicionarSignatariosImportados"
        />
    </v-col>
</template>

<script>
import CardDadosPrincipais from './components/CardDadosPrincipais'
import {actionTypes} from '@/commons/constants'
import ImportarSignatariosDialogs from './components/importarSignatarios/ImportarSignatariosDialogs'
import ImportarSignatariosMenu from './components/importarSignatarios/ImportarSignatariosMenu'
import {mapState} from 'vuex'

export default {
    name: 'DadosPrincipais',
    components: {ImportarSignatariosMenu, ImportarSignatariosDialogs, CardDadosPrincipais},
    props: {
        signatarios: {
            type: Array
        },
        documentosSignatario: {
            type: Array,
            required: true
        },
        papeisSignatario: {
            type: Array,
            required: true
        },
        tiposAssinatura: {
            type: Array
        },
        possuiOrdemAtiva: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            abrirDialogs: {
                dialogImportarSignatarios: false,
                dialogImportarGrupos: false
            },
            incluirMe: false,
            exibirSignatarioCompleto: true,
            signatariosSugestoes: [],
        }
    },
    computed: {
        ...mapState(['login']),
        opcoesDraggable() {
            return {
                animation: 330,
                group: "novo-documento",
                disabled: false,
                ghostClass: "sombra-arrastando"
            }
        },
        podeMoverSignatario() {
            return this.possuiOrdemAtiva
        },
        exibirIndicador() {
            return this.possuiOrdemAtiva ? 'indicador-ativo' : ''
        },
        podeExcluirSignatario() {
            return this.signatarios.length === 1
        },
    },
    mounted() {
        this.buscarSignatariosSugestoes()
    },
    methods: {
        addOrRemoveMe() {
            if (this.incluirMe) {
                this.signatarios.unshift({
                    incluirMe: true,
                    nome: this.login.usuarioOrganizacao.usuarioNome,
                    telefone:'',
                    ddi: '55',
                    tipoAssinatura: 'ELETRONICA',
                    email: this.login.usuarioOrganizacao.usuarioEmail,
                    papelId: 16,
                    enviarConviteSMS: false,
                    enviarTokenSMS: false,
                    representanteLegal: true
                })
                this.mostrarNotificacaoSucesso(`${this.$tc("pt_br.signatario", 2)} adicionado com sucesso!`)
            } else {
                let indexRemove = this.signatarios.findIndex(signatario => signatario.incluirMe)
                this.signatarios.splice(indexRemove, 1)
            }
        },
        adicionar() {
            this.$emit('adicionar-signatario')
        },
        adicionarSignatariosImportados(signatariosImportados) {
            signatariosImportados.forEach(signatario => {
                this.signatarios.unshift({
                    nome: signatario.nome,
                    telefone: signatario.telefone,
                    ddi: signatario.ddi,
                    skipFotoWpp: signatario.skipFotoWpp,
                    tipoAssinatura: signatario.tipoAssinatura,
                    email: signatario.email,
                    papelId: signatario.papelId,
                    enviarConviteSMS: false,
                    enviarTokenSMS: false,
                    representanteLegal: true
                })
            })
            this.removerUltimoEmBranco()
            this.mostrarNotificacaoSucesso(`${this.$tc("pt_br.signatario")}(s) adicionado(s) com sucesso!`)
        },
        async buscarSignatariosSugestoes() {
            let signatarios = await this.$store.dispatch(actionTypes.SIGNATARIO.BUSCAR_SIGNATARIOS_SUGESTOES)
            if (signatarios !== undefined) {
                this.signatariosSugestoes = signatarios.map(signatario => {
                    return signatario.email
                })
            }
        },
        ehSignatarioVazio(signatario) {
            let emailVazio = signatario.email === '' || signatario.email === undefined
            let telefoneVazio = signatario.telefone === '' || signatario.telefone === undefined

            return emailVazio && telefoneVazio
        },
        removerUltimoEmBranco() {
            if (this.signatarios.length > 1) {
                let ultimoSignatario = this.signatarios.at(-1)
                if (this.ehSignatarioVazio(ultimoSignatario)) {
                    this.signatarios.pop()
                }
            }
        },
        remover(signatario) {
            this.$emit('remover-signatario', signatario)
        },
        validaTipoAssinatura() {
            this.$emit('validaTipoAssinatura')
        }
    }
}
</script>

<style lang="stylus">
.co-dados-principais
    .draggable-wrapper
        width 100%
        flex-direction column
        gap 20px

        .transition-wrapper
            display flex
            flex-direction column
            gap 20px

            .card-wrapper
                .dragging-icon-wrapper
                    color #999
                    font-size 18px
                    transition all 0.3s ease-in-out
                    width 0px
                    opacity 0
                    visibility hidden
                    margin-right 0

                    .icone-ordem
                        font-size 20px

                    &.indicador-ativo
                        width 33px
                        opacity 1
                        visibility visible
                        margin-right 11px

    .pode-arrastar
        &:hover
            cursor move

    .full-size
        width 100%

.sombra-arrastando
  opacity 0.5

@media (max-width: 768px)
    .co-dados-principais
        .co-importar-signatarios-menu
            display none

</style>