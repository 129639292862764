<template>
    <v-dialog hide-overlay v-model="clmOpsReenvioConvite" width="0" persistent>
        <div class="clm-ops-reenvio-convite">
            <div class="text-xs-center">
                <div class="content">
                    <v-card-text>
                        <v-icon size="18" class="btn-fechar" @click="fechar"> mdi-window-close </v-icon>

                        <div v-if="enviarConviteEmail || reenviarEmail">
                            <img src="@/assets/ops/feliz.gif">
                            <div class="titulo">Dados do {{ $tc("pt_br.signatario").toLowerCase() }}:</div>
                            <div class="subtitulo">E-mail: <span>{{ signatario.email }}</span></div>
                        </div>

                        <div v-if="reenviarSms || reenviarWhatsApp">
                            <div v-if="!possuiTelefone">
                                <img src="@/assets/ops/assustado.gif">
                                <div class="subtitulo">Este {{ $tc("pt_br.signatario").toLowerCase() }} não possui telefone cadastrado.</div>
                                <br>
                                <div class="subtitulo">Vamos cadastrar um número para ele?</div>
                                <br>
                                <v-flex style="text-align: left">

                                    <telefone-signatario
                                        :value="telefoneSignatario"
                                    />

                                </v-flex>
                            </div>
                            <div v-else>
                                <img src="@/assets/ops/feliz.gif">
                                <div class="titulo">Dados do {{ $tc("pt_br.signatario").toLowerCase() }}:</div>
                                <div class="subtitulo">Telefone: <span>+{{ signatario.ddi }}{{signatario.telefone }}</span></div>
                            </div>
                        </div>
                    </v-card-text>

                    <v-divider/>

                    <v-card-actions style="padding: 8px 20px">
                        <a style="color: #ccc" @click="fechar">Cancelar</a>
                        <v-spacer/>
                        <a v-if="campoTelefoneVazio" style="color: #ccc; pointer-events: none">Salvar</a>
                        <a v-else-if="campoTelefonePreenchido" style="color: #499132" @click="atualizarTelefoneSignatario">Salvar</a>
                        <a v-else style="color: #499132" @click="verificarEnvioConvite"> {{ mensagem }} </a>
                    </v-card-actions>
                </div>
            </div>
        </div>

        <clm-ops-acabou-s-m-s :nao-possui-s-m-s="acabouSMS" @fecharClmOpsAcabouSMS="fecharClmOpsAcabouSMS"/>
    </v-dialog>
</template>

<script>
import ClmOpsAcabouSMS from '@/views/contrato/detalhe/area-trabalho/assinaturas/ClmOpsAcabouSMS'
import {mapGetters} from 'vuex'
import TelefoneSignatario from '@/commons/components/text-field/TelefoneSignatario'

export default {
    name: 'ClmOpsReenvioConvite',
    components: {TelefoneSignatario, ClmOpsAcabouSMS},
    props: {
        clmOpsReenvioConvite: {
            type: Boolean,
            required: true
        },
        mensagem: {
            type: String,
            required: false
        },
        enviarConviteEmail: {
            type: Boolean,
            required: false
        },
        reenviarEmail: {
            type: Boolean,
            required: false
        },
        reenviarSms: {
            type: Boolean,
            required: false
        },
        reenviarWhatsApp: {
            type: Boolean,
            required: false
        },
        signatario: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            telefoneSignatario: {
                telefone: null,
                ddi: '55'
            },
            acabouSMS: false
        }
    },
    computed: {
        ...mapGetters(['saldoSms']),
        campoTelefoneVazio() {
            return !this.telefoneSignatario.telefone && !this.possuiTelefone && (this.reenviarSms || this.reenviarWhatsApp)
        },
        campoTelefonePreenchido() {
            return this.telefoneSignatario.telefone && !this.possuiTelefone && (this.reenviarSms || this.reenviarWhatsApp)
        },
        possuiTelefone() {
            return this.signatario.telefone
        }
    },
    methods: {
        atualizarTelefoneSignatario() {
            this.preencherTelefoneSignatario()
            this.$emit('atualizarTelefoneSignatario')
        },
        convidarPorEmail() {
            this.$emit('convidarPorEmail')
        },
        convidarPorSMS() {
            this.$emit('convidarPorSMS')
        },
        convidarPorWhatsApp() {
            this.$emit('convidarPorWhatsApp')
        },
        fechar() {
            this.limparCampoTelefone()
            this.$emit('fecharClmOpsReenvioConvite')
        },
        fecharClmOpsAcabouSMS() {
            this.acabouSMS = false
        },
        limparCampoTelefone() {
            this.telefoneSignatario.telefone = null
        },
        preencherTelefoneSignatario() {
            this.signatario.ddi = this.telefoneSignatario.ddi
            this.signatario.telefone = this.telefoneSignatario.telefone.length === 0 ? null : this.telefoneSignatario.telefone.replace(/[^a-zA-Z0-9 ]/g, "").replace(/\s/g, "")
        },
        reconvidarPorEmail() {
            this.$emit('reenviarConviteEmail')
        },
        verificarEnvioConvite() {
            if (this.enviarConviteEmail) {
                this.convidarPorEmail()
            } else if (this.reenviarSms) {
                this.verificarSeSMSRestante(this.convidarPorSMS)
            } else if (this.reenviarWhatsApp) {
                this.convidarPorWhatsApp()
            } else if (this.reenviarEmail) {
                this.reconvidarPorEmail()
            }
        },
        verificarSeSMSRestante(funcao) {
            if (this.saldoSms === 0) {
                this.acabouSMS = true
            } else {
                funcao()
            }
        }
    }
}
</script>

<style lang="stylus">
.clm-ops-reenvio-convite
    text-align center
    position absolute
    width 0
    height 0
    top 150px
    left 405px

    .chevron
        position absolute
        width 0
        height 0
        border-top 15px solid transparent
        border-bottom 15px solid transparent
        border-right 10px solid white
        z-index 1
        left 1px
        top -15px

    .content
        box-shadow 5px 5px 5px 0 rgba(0, 0, 0, 0.25)
        background-color white
        position absolute
        top -50px
        left 10px
        border 1px solid #ccc
        border-radius 5px
        width 300px

        img
            width 120px

        .titulo
            font-size 16px
            color #777777
            font-weight bold
            margin 10px 0

        .subtitulo
            font-size 12px
            color #999

            span
                font-weight 500
                color var(--v-secondary-base)
                word-wrap break-word

        .trecho-copiar
            padding-top 20px

            .texto-copiar
                padding-left: 5px
                font-weight bold
                color var(--v-secondary-base)
                word-wrap break-word
                font-size 12px

            .btn-copiar
                color var(--v-secondary-base) !important

        .btn-fechar
            position absolute
            z-index 1
            right 10px
            top 10px

        .v-text-field
            label
                font-size 12px !important

        .v-input
            font-size 12px !important

        .v-input__slot

            border: 1px solid #ddd !important
            min-height 32px !important

            &:hover
                border: 1px solid #ccc !important

@media (max-width: 720px)
    .clm-ops-reenvio-convite
        position absolute
        top 275px
        left 200px

@media (max-width: 480px)
    .clm-ops-reenvio-convite
        position absolute
        left 40px

        .content
            width 250px

</style>