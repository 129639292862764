<template>
    <div class="co-detahe-contrato">
        <v-dialog value="true" persistent fullscreen hide-overlay v-resize="calcularAlturaDocumentViewer">
            <v-card tile color="#eee">
                <detalhe-contrato-toolbar
                    @atualizarDadosContrato="atualizarDadosContrato"
                    @buscarDocumento="buscarDocumento"
                />
                <div class="corpo">
                    <div id="documento" class="documento">
                        <az-pdf-document-viewer
                            ref="azPdfDocumentViewer"
                            progress-bar
                            default-scale-type="auto"
                            css-class="documentViwer"
                            v-if="!mobile"
                            :height="alturaDocumentViewer"
                            :src="pathDocumento"
                            :draggables="draggables"
                            :initial-draggable-width="210"
                            :initial-draggable-height="70"
                            draggable-link-tooltip="Repetir posicionamento para outras páginas"
                            draggable-unlink-tooltip="Desvincular este posicionamento do restante repetido"
                            @create:draggable="criarDraggable"
                            @update:draggable="atualizarDraggable"
                            @delete:draggable="deletarDraggable"
                            @link:draggable="vincularDraggable"
                            @unlink:draggable="desvincularDraggable"
                        >
                            <template v-slot:draggable-content="{ draggable }">
                                <v-container
                                    fill-height
                                    align-center
                                    justify-center
                                    text-xs-center
                                >
                                    <div class="d-flex slot-posicionamento-assinatura">
                                        <div>
                                            <v-icon left color="primary">{{draggable ? getTipoPosicionamentoIcon(draggable) : '' }}</v-icon>
                                            {{ draggable.content.typeName }}
                                        </div>

                                        <div>
                                            <span class="posicionamento-assinatura-email">
                                                {{ draggable.content.text }}
                                            </span>
                                        </div>
                                    </div>

                                </v-container>
                            </template>
                        </az-pdf-document-viewer>

                        <botao-scroll-documento :paginacao="paginacao" />
                    </div>
                    <area-trabalho
                        ref="refAreaTrabalho"
                        :path-documento="pathDocumento"
                        :draggables="draggables"
                        @buscarDocumento="buscarDocumento"
                        @atualizarDocumento="atualizarDocumento"
                        @criar-posicionamento-assinatura="criarPosicionamentoAssinatura"
                        @limpar-draggables="limparDraggables"
                        @salvar-draggables="salvarDraggables"
                    />
                </div>
            </v-card>
        </v-dialog>

        <dialog-repetir-posicionamento
            :dialog="dialogRepetirPosicionamento"
            @fechar-dialog="fecharDialogRepetirPosicionamento"
            @repetir-posicionamento="vincularPosicionamentoOutrasPaginas"
        />
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import {actionTypes, mutationTypes} from '@/commons/constants'
import AreaTrabalho from './area-trabalho/AreaTrabalho'
import DetalheContratoToolbar from './DetalheContratoToolbar'
import DialogRepetirPosicionamento from '@/commons/components/dialogs/DialogRepetirPosicionamento'
import BotaoScrollDocumento from '../commons/BotaoScrollDocumento.vue'

export default {
    name: 'DetalheContrato',
    components: {DialogRepetirPosicionamento, AreaTrabalho, DetalheContratoToolbar, BotaoScrollDocumento},
    data() {
        return {
            alturaDocumentViewer: '0px',
            alturaAreaTrabalho: {height: '0px'},
            contratoId: null,
            dialogRepetirPosicionamento: false,
            pathDocumento: '',
            signatarioPosicionamentoAssinaturaAtual: {},
            draggables: [],
            draggablesDeletados: [],
            paginacao: {
                paginaAtual: 0,
                totalPaginas: 0,
                ehUltimaPagina: false
            }
        }
    },
    computed: {
        ...mapState(['contrato', 'minutaAtual', 'pesquisaFeedback']),
        ...mapGetters(['caminhoVisualizarMinuta', 'mobile'])
    },
    beforeRouteLeave(to, from, next) {
        this.setContrato({})
        this.setMinutaAtual({})
        this.setSignatarios([])
        next()
    },
    async mounted() {
        this.calcularAlturaDocumentViewer()
        this.setContratoId(this.$route.params.id)
        try {
            this.setCarregandoDadosContrato(true)
            await this.buscarContratoPorId()
            await this.buscarVersaoMaisRecenteMinuta()
            await this.buscarHistoricoContrato(this.contratoId)
            this.setCarregandoDadosContrato(false)
            this.buscarDocumento()
            this.pesquisaFeedback.exigirResposta = true
        } catch (e) {
            this.setCarregandoDadosContrato(false)
            this.$router.push({name: 'meus'})
        }

        const pdfViewer = document.querySelector('#az-pdf-viewer')

        pdfViewer?.addEventListener('scroll', this.lidarComPaginacaoAtual)
    },
    beforeDestroy() {
        const pdfViewer = document.querySelector('#az-pdf-viewer')

        pdfViewer?.removeEventListener('scroll', this.lidarComPaginacaoAtual);
    },
    methods: {
        ...mapActions([
            actionTypes.ATUALIZAR_CONTRATO,
            actionTypes.ATUALIZAR_TAGS_CONTRATO,
            actionTypes.BUSCAR_CONTRATO,
            actionTypes.BUSCAR_MINUTA_ATUAL,
            actionTypes.BUSCAR_HISTORICO_CONTRATO,
            actionTypes.BUSCAR_SIGNATARIOS_POR_MINUTA_VERSAO
        ]),
        ...mapMutations([
            mutationTypes.SET_CARREGANDO_DADOS_CONTRATO,
            mutationTypes.SET_MINUTA_ATUAL,
            mutationTypes.SET_CONTRATO,
            mutationTypes.SET_SIGNATARIOS
        ]),
        abrirDialogRepetirPosicionamento() {
            this.dialogRepetirPosicionamento = true
        },

        async atualizarDadosContrato(novoNome, tags) {
            this.setMensagemLoading('Atualizando dados do contrato...')
            await this.atualizarContrato({
                contratoId: this.contrato.id,
                contrato: {nome: novoNome}
            })
            await this.atualizarTagsContrato({
                contratoId: this.contrato.id,
                contrato: {contratoTags: this.criarPayloadTags(tags)}
            })
            await this.buscarContratoPorId()
            this.mostrarNotificacaoSucessoDefault()
        },
        atualizarDraggable({draggable, draggableIndex}) {
            Object.assign(this.draggables[draggableIndex], draggable)
        },
        atualizarDocumento() {
            this.pathDocumento = ''
            setTimeout(this.buscarDocumento, 200)
        },
        async buscarContratoPorId() {
            this.setMensagemLoading('Carregando dados do contrato')
            await this.buscarContrato(this.contratoId)
        },
        buscarDocumento() {
            this.pathDocumento = this.caminhoVisualizarMinuta
        },
        buscarPosicionamentoDadoSignatariosPorMinuta() {
            this.$refs.refAreaTrabalho.buscarPosicionamentoDadoSignatariosPorMinuta()
        },
        async buscarSignatarios() {
            return await this.buscarSignatariosPorMinutaVersao({
                contratoId: this.contrato.id, versao: this.minutaAtual.versao
            })
        },
        async buscarVersaoMaisRecenteMinuta() {
            this.setMensagemLoading('Carregando dados da minuta')
            await this.buscarMinutaAtual(this.contratoId)
        },
        calcularAlturaDocumentViewer() {
            const element = document.getElementsByClassName('documento')[0]
            this.alturaDocumentViewer = element.clientHeight + 'px'

            window.setTimeout(() => {
                const viewer = document.getElementById('az-pdf-viewer')
                viewer.setAttribute('style', `height: ${element.clientHeight - 51}px !important`)
            }, 100)
        },
        criarDraggable({draggable}) {
            draggable.content = {
                text: this.signatarioPosicionamentoAssinaturaAtual.contato,
                type: this.signatarioPosicionamentoAssinaturaAtual.type,
                typeName: this.signatarioPosicionamentoAssinaturaAtual.typeName
            }
            this.draggables.push(draggable)
        },
        criarPosicionamentoAssinatura(signatario) {
            this.signatarioPosicionamentoAssinaturaAtual.contato = signatario.contato
            this.signatarioPosicionamentoAssinaturaAtual.type = signatario.tipoPosicionamento.value
            this.signatarioPosicionamentoAssinaturaAtual.typeName = signatario.tipoPosicionamento.tipo
            this.$refs.azPdfDocumentViewer.startCreateDraggable()
        },
        criarPayloadTags(tags) {
            const contratoTags = []
            tags.forEach(tag => {
                contratoTags.push({
                    contratoId: this.contrato.id,
                    tagId: tag.tagId,
                    nome: tag.nome
                })
            })
            return contratoTags
        },
        converterDadosPosicionamento(draggables) {
            const regex = /^\d+$/g;

            return draggables.map(draggable => {
                let xInteiro = draggable.percentX * 100
                let yInteiro = draggable.percentY * 100

                const x = xInteiro > 0.15 ? (xInteiro - 0.01) : (xInteiro + 0.01)
                const y = yInteiro > 0 ? (yInteiro - 0.01) : (yInteiro + 0.01)

                return {
                    ...draggable,
                    id: draggable.id.match(regex) ? parseInt(draggable.id) : null,
                    percentX: x / 100,
                    percentY: y / 100
                }
            })
        },
        deletarDraggable({draggable, draggableIndex}) {
            const regex = /^\d+$/g;
            if(draggable.id.match(regex)) {
                this.draggablesDeletados.push(parseInt(draggable.id))
            }
            this.draggables.splice(draggableIndex, 1)

        },

        desvincularDraggable({ draggable, draggableIndex }) {
            this.draggables[draggableIndex].groupId = null
        },

        ehDocumentoOriginal () {
            return this.minutaAtual.situacaoFormalizacao === 'RASCUNHO' || this.minutaAtual.situacaoFormalizacao === 'FORMALIZADO' && this.minutaAtual.contratoAssinado
        },

        fecharDialogRepetirPosicionamento() {
            this.dialogRepetirPosicionamento = false
        },

        getTipoPosicionamento(draggable) {
            switch (draggable.content.type) {
                case 'RUBRICA':
                    return 'Assinatura'

                case 'CPF':
                    return 'Documento'

                case 'NOME_SIGNATARIO':
                    return 'Nome'

                case 'EMAIL':
                    return 'Email'

                case 'DATA_NASCIMENTO':
                    return 'Data Nascimento'

                default:
                    return 'ASSINATURA'
            }
        },

        getTipoPosicionamentoIcon(draggable) {
            switch (draggable.content.typeName) {
                case 'Assinatura':
                    return 'mdi-gesture'

                case 'Rubrica':
                    return 'mdi-pencil'

                case 'Documento':
                    return 'mdi-badge-account-horizontal'

                case 'Email':
                    return 'mdi-email'

                case 'Nome':
                    return 'mdi-account'

                case 'Data Nascimento':
                    return 'mdi-calendar-month'

                default:
                    return 'mdi-gesture'
            }
        },
        lidarComPaginacaoAtual() {
            const paginaAtual = this.$refs.azPdfDocumentViewer.pagination.current
            const ultimaPagina = this.$refs.azPdfDocumentViewer.pagination.total

            this.paginacao.paginaAtual = paginaAtual
            this.paginacao.totalPaginas = ultimaPagina

            this.paginacao.ehUltimaPagina = paginaAtual === ultimaPagina
        },
        limparDraggables() {
            this.draggablesDeletados = []
            this.draggables = []
        },
        async salvarDraggables() {
            try {
                if(this.draggables.length > 0 || this.draggablesDeletados.length > 0) {
                    const posicionamento = {
                        posicionamentoDados: this.converterDadosPosicionamento(this.draggables),
                        posicionamentoDadosDeletadosId: this.draggablesDeletados
                    }
                    await this.$store.dispatch(actionTypes.POSICIONAMENTO_DADO.ATUALIZAR, posicionamento)
                }
                this.mostrarNotificacaoSucessoDefault()
                this.limparDraggables()
                this.buscarPosicionamentoDadoSignatariosPorMinuta()
            }catch (e) {
                this.mostrarNotificacaoErro('Ocorreu um erro ao atualizar posicionamento')
            }
        },
        setContratoId(contratoId) {
            this.contratoId = contratoId
        },
        vincularDraggable({draggable, draggableIndex}) {
            this.draggableToLink = this.draggables[draggableIndex]
            this.signatarioPosicionamentoAssinaturaAtual.contato = this.draggableToLink.content.text
            this.signatarioPosicionamentoAssinaturaAtual.type = this.draggableToLink.content.type
            this.signatarioPosicionamentoAssinaturaAtual.typeName = this.draggableToLink.content.typeName
            this.abrirDialogRepetirPosicionamento()
        },

        vincularPosicionamentoOutrasPaginas(repetirPosicionamento) {
            this.fecharDialogRepetirPosicionamento()
            switch (repetirPosicionamento) {
                case 'TODAS_AS_PAGINAS':
                    return this.vincularEmTodasAsPaginas()
                case 'PROXIMA_PAGINA':
                    return this.vincularNaProximaPagina()
                case 'ULTIMA_PAGINA':
                    return this.vincularNaUltimaPagina()
            }
        },

        vincularEmTodasAsPaginas() {
            this.$refs.azPdfDocumentViewer.linkDraggablesByPageInterval(this.draggableToLink, (pagination) => ({
                startPage: 1,
                endPage: this.ehDocumentoOriginal() ? pagination.total : pagination.total - 2
            }))
        },

        vincularNaProximaPagina() {
            this.$refs.azPdfDocumentViewer.linkDraggablesByPageInterval(this.draggableToLink, (pagination) => ({
                startPage: this.draggableToLink.pageNumber + 1,
                endPage: this.draggableToLink.pageNumber + 1
            }))
        },

        vincularNaUltimaPagina() {
            this.$refs.azPdfDocumentViewer.linkDraggablesByPageInterval(this.draggableToLink, (pagination) => ({
                startPage:  this.ehDocumentoOriginal() ? pagination.total : pagination.total - 2,
                endPage:  this.ehDocumentoOriginal() ? pagination.total : pagination.total - 2
            }))
        },

    }
}
</script>

<style lang="stylus">
.documentViwer
    .az-pdf-toolbar
        height 49px !important

::-webkit-scrollbar
    width: 8px
    height: 8px

::-webkit-scrollbar-track
    background: #ccc

::-webkit-scrollbar-thumb
    background: #666 !important

::-webkit-scrollbar-thumb:hover
    background: #555

.az-dv-container
    box-shadow inset 0 8px 10px -4px #ccc !important

.documento
    width calc(100% - 450px)
    height calc(100% - 64px)
    position: absolute
    right 0

    .document-draggable-item
        &__background
            background-color rgba(255, 255, 255, 0.7) !important

        button
            i
                color white

    .az-pdf-document-viewer
        background #dddddd

    .slot-posicionamento-assinatura
        color var(--primary)
        flex-direction column

        .posicionamento-assinatura-email
            color var(--text-color-200)

</style>