<template>
    <div class="co-usuarios-tabela">
        <v-data-table
            class="tabela-usuarios"
            hide-default-footer
            :no-data-text="noDataText"
            :items-per-page="qtdLinhasTabela"
            :headers="headers"
            :items="usuarios"
        >
            <template v-slot:item.status="{ item }">
                {{ item.usuarioSituacao | azTitleCase }}
            </template>

            <template v-slot:item.acoes="{ item }">
                <v-layout class="acoes-tabela">
                    <template v-if="!ehUsuarioLogado(item)">
                        <template v-if="ehUsuarioAtivo(item.usuarioSituacao)">
                            <v-tooltip top color="black" v-az-auth="'Usuario.Editar'">
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" size="15px" @click="$emit('editar', item)"> mdi-pencil </v-icon>
                                </template>
                                <span>Editar</span>
                            </v-tooltip>
                        </template>
                        <template v-if="ehUsuarioAtivo(item.usuarioSituacao)">
                            <v-tooltip top color="black" v-az-auth="'Usuario.AtivarInativar'">
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" size="15px" @click="$emit('inativar', item)"> mdi-cancel </v-icon>
                                </template>
                                <span>Inativar</span>
                            </v-tooltip>
                        </template>
                        <template v-else>
                            <v-tooltip top color="black" v-az-auth="'Usuario.AtivarInativar'">
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" size="15px" @click="$emit('ativar', item)"> mdi-check </v-icon>
                                </template>
                                <span>Ativar</span>
                            </v-tooltip>
                        </template>
                    </template>
                    <template v-if="ehUsuarioAtivo(item.usuarioSituacao)">
                        <v-tooltip top color="black" v-az-auth="'Usuario.Editar'">
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" size="15px" @click="$emit('token', item)"> mdi-key </v-icon>
                            </template>
                            <span>Token Api</span>
                        </v-tooltip>
                    </template>
                </v-layout> 
            </template>    
        </v-data-table>
    </div>
</template>

<script>

export default {
    name: 'UsuariosTabela',
    props: {
        usuarios: {
            type: Array | undefined,
            required: true
        },
        qtdLinhasTabela: {
            type: Number,
            default: 10
        }
    },
    data() {
        return {
            headers: [
                {
                    text: 'Nome',
                    align: 'left',
                    sortable: false,
                    value: 'usuarioNome',
                    width: '200px',
                    divider: true
                },
                {
                    text: 'E-mail',
                    align: 'left',
                    value: 'usuarioEmail',
                    sortable: false,
                    width: '200px',
                    divider: true
                },
                {
                    text: 'Função',
                    align: 'left',
                    value: 'perfil',
                    sortable: false,
                    width: '200px',
                    divider: true
                },
                {
                    text: 'Status',
                    align: 'left',
                    value: 'status',
                    sortable: false,
                    width: '20px',
                    divider: true
                },
                {
                    text: 'Ações',
                    align: 'left',
                    value: 'acoes',
                    sortable: false,
                    width: '50px',
                    divider: true
                }
            ]
        }
    },
    computed: {
        noDataText() {
            return 'Nenhum usuário encontrado.'
        }
    },
    methods: {
        ehUsuarioAtivo(usuarioSituacao) {
            return usuarioSituacao === 'ATIVO'
        },
        ehUsuarioLogado(usuario) {
            return usuario.usuarioId === this.$store.state.loki.user.id
        }
    }
}
</script>

<style lang="stylus">
.co-usuarios-tabela
    width 100%

    .v-input--selection-controls.v-input .v-label
        font-size 16px

    .tabela-usuarios
        color var(--text-color-300)

        .acoes-tabela
            justify-content space-around

            .botoes
                color var(--text-color-200)

        thead th
            border-bottom thin solid rgba(0,0,0,.12)

        tbody td
            height 40px !important
            font-size 13px !important
            border-bottom thin solid rgba(0,0,0,.12)

@media (max-width: 720px)
    .co-usuarios-tabela
        .tabela-usuarios
            .acoes-tabela
                justify-content right

                .botoes-mobile
                    margin-right 15px

            thead
                display none !important

            tbody tr
                border-bottom 1px solid var(--v-primary-lighten1) !important

                td
                    display block !important
                    text-align left !important
                    padding 5px 10px !important
                    max-height unset !important
                    height unset !important
                    justify-items left
</style>
