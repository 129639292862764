import axios from 'axios'
import {actionTypes} from '@/commons/constants'

export default {
    async [actionTypes.ORGANIZACAO.BUSCAR_DADOS_ORGANIZACAO_PRIMEIRO_ACESSO]() {
        const {data} = await axios.get('api/organizacoes/primeiro-acesso')
        return data
    },

    async [actionTypes.ORGANIZACAO.BUSCAR_DADOS_ORGANIZACAO]({state}) {
        const organizacaoId = state.login.organizacaoPlano.organizacaoId
        const {data} = await axios.get(`api/organizacoes/${organizacaoId}`)
        return data
    },

    async [actionTypes.ORGANIZACAO.BUSCAR_CEP](context, cep) {
        const {data} = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
        return data
    },

    async [actionTypes.ORGANIZACAO.ATUALIZAR](context, {organizacaoId, organizacaoDTO}) {
        return await axios.put(`api/organizacoes/${organizacaoId}`, organizacaoDTO)
    },
}