<template>
    <v-list class="co-informacoes-contrato-novo">
        <div class="text-xs-center">
            <div class="pa-3 informacao">
                <template>
                    <div class="titulo">Título</div>
                    <div class="valor ellipsis">{{ contrato.nome }}</div>
                </template>

                <template v-if="ehContratoComTags">
                    <div class="titulo">Tags</div>
                    <div class="valor ellipsis">
                        {{ retornaListaTag }}
                    </div>
                </template>

                <template v-if="ehItemMinuta">
                    <div class="titulo">Valor total</div>
                    <div class="valor ellipsis">{{ valorTotalItem }}</div>
                </template>

                <template>
                    <div class="titulo">Vigência</div>
                    <div class="valor ellipsis">{{ vigencia }}</div>
                </template>

                <template>
                    <div class="titulo">Data de criação do {{ $tc("pt_br.documento").toLowerCase() }}</div>
                    <div class="valor ellipsis">{{ dataCriacao }}</div>
                </template>

                <template>
                    <div class="titulo">Data limite de assinaturas</div>
                    <div class="valor ellipsis">{{ fimAssinatura }}</div>
                </template>
            </div>
        </div>
    </v-list>
</template>

<script>
import moment from 'moment'
import {mapState} from 'vuex'

export default {
    name: 'InformacaoContratNovo',
    props: {
        contrato: {
            required: true
        }
    },
    data() {
        return {
            format: 'DD/MM/YYYY'
        }
    },
    computed: {
        ...mapState(['login']),
        ehItemMinuta() {
            return this.login.funcionalidadesOrganizacao.itemMinuta
        },
        ehContratoComTags() {
            return this.contrato.tags[0] !== '[]'
        },
        dataCriacao() {
            if(this.contrato.dataCriacao)
                return moment(this.contrato.dataCriacao).tz('America/Sao_Paulo').format(this.format)
            return ''
        },
        fimVigencia() {
            if(this.contrato.fimVigencia)
                return moment(this.contrato.fimVigencia).tz('America/Sao_Paulo').format(this.format)
            return ''

        },
        fimAssinatura() {
            if(this.contrato.fimAssinatura)
                return moment(this.contrato.fimAssinatura).tz('America/Sao_Paulo').format(this.format)
            return ''
        },
        inicioVigencia() {
            if(this.contrato.inicioVigencia)
                return moment(this.contrato.inicioVigencia).tz('America/Sao_Paulo').format(this.format)
            return ''
        },
        valorTotalItem() {
            if(this.contrato.valorTotalItem)
                return this.contrato.valorTotalItem.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
            return ''
        },
        vigencia() {
            if(this.contrato.diasRestantesVigencia)
                return `${this.inicioVigencia} a ${this.fimVigencia}`
            return 'Prazo indeterminado'
        },
        retornaListaTag(){
            const obj = JSON.parse(this.contrato.tags[0]);
            const list = obj.map(t => t.nome);
            return list.toString()
        }

    }
}
</script>

<style lang="stylus">
.co-informacoes-contrato-novo
    .informacao
        width 100%

        .ellipsis
            display block
            align-items center
            text-overflow ellipsis
            white-space nowrap
            overflow hidden
            width 100%

    .titulo
        color var(--text-color-400)

    .valor
        color var(--text-color-200)

</style>