<template>
    <div class="filtro-components-filtro-data">
        <span> Vencimento </span>

        <v-select
            v-model="value.cor.value"
            :error-messages="errors.collect('Filtrar Por')"
            name="data"
            class="pt-0 mt-0"
            placeholder="Buscar"
            persistent-placeholder
            item-text="descricao"
            item-value="chave"
            :items="opcoesFiltrar"
            :disabled="disabled"
        />

        <span> Data de criação</span>

        <div class="d-flex mt-2">
            <v-text-field
                v-model="value.dataCriacaoInicio.value"
                v-validate="validarCampoDe()"
                :error-messages="errors.collect('De')"
                :rules="[validarDataInicio()]"
                class="dates"
                dense
                hide-details
                label="De"
                name="De"
                outlined
                type="date"
            />

            <v-text-field
                v-model="value.dataCriacaoFim.value"
                v-validate="validarCampoAte()"
                :error-messages="errors.collect('Ate')"
                :rules="[validarDataFim()]"
                class="ml-3 dates"
                dense
                hide-details
                label="Até"
                name="Ate"
                outlined
                type="date"
            />
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'FiltroData',
    props: {
        value: {
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            opcoesFiltrar: [
                {
                    descricao: 'Vencimento menor ou igual a 31 dias',
                    chave: 'VERMELHO',
                },
                {
                    descricao: 'Vencimento entre 32 e 45 dias',
                    chave: 'LARANJA',
                },
                {
                    descricao: 'Vencimento maior a 45 dias',
                    chave: 'VERDE',
                }
            ]
        }
    },
    methods: {
        validarCampoDe() {
            return (this.value.dataCriacaoInicio.value || this.value.dataCriacaoFim.value) ? 'required|check_data_inicial' : ''
        },
        validarCampoAte() {
            let dataInicio = this.value.dataCriacaoInicio.value
            return dataInicio ? `required|check_dif_datas:${dataInicio}` : ''
        },
        getDate(date) {
            return moment.utc(date)
        },
        validarDataInicio() {
            if (this.value.dataCriacaoInicio.value) {
                let inicio = this.getDate(this.value.dataCriacaoInicio.value)
                let hj = moment().utc()

                if (inicio.isAfter(hj, 'day')) {
                    this.mostrarNotificacaoErro("Data final deve ser posterior ou igual a data atual!")
                    return false
                }
            }
            return true
        },
        validarDataFim() {
            if (this.value.dataCriacaoInicio.value && this.value.dataCriacaoFim.value) {
                let inicio = this.getDate(this.value.dataCriacaoInicio.value)
                let fim = this.getDate(this.value.dataCriacaoFim.value)

                if (inicio.isAfter(fim, 'day')) {
                    this.mostrarNotificacaoErro("Data de inicio deve ser anterior a data de fim do intervalo!")
                    return false
                }

                if (fim.diff(inicio, 'day') > 180) {
                    this.mostrarNotificacaoErro("Não é permitido aplicar um filtro maior que 180 dias. Reduza a faixa e datas para menos dias")
                    return false
                }
            }
            return true
        }
    }
}
</script>

<style lang="stylus">
.filtro-components-filtro-data
    width 100%
    position relative

    .dates
        width 170px

</style>