<template>
    <v-row class="co-filtro-filtro-components pa-3" no-gutters>
        <filtro-tags v-model="value"/>

        <filtro-data v-model="value"/>

        <botoes-acoes @filtrar="filtrar"/>
    </v-row>
</template>

<script>
import FiltroTags from './components/FiltroTags.vue'
import FiltroData from './components/FiltroData'
import BotoesAcoes from './components/BotoesAcoes'
import {mutationTypes} from '@/commons/constants'
import {mapState} from 'vuex'

export default {
    name: 'FiltrosComponents',
    components: {FiltroTags, FiltroData, BotoesAcoes},
    props: {
        value: {
            required: true
        },
    },
    computed: {
        ...mapState(['cookies']),
    },
    methods: {
        async filtrar() {
            const valido = await this.$validator._base.validateAll()
            if (!valido) {
                return
            }
            const filtros = this.value
            this.$store.commit(mutationTypes.FILTROS.SET_FILTROS_BUSCA, filtros)
            this.$store.commit(mutationTypes.ACOES.SET_ACOES_FILTRAR)
        }
    }
}
</script>

<style lang="stylus">
.co-filtro-filtro-components
    width 100%
    margin 15px
    padding-right 30px

</style>