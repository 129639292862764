<template>
    <v-dialog hide-overlay v-model="clmOpsChaveSeguranca" width="0" persistent>
        <div class="clm-ops-reenvio-convite">
            <div class="text-xs-center">
                <div class="content">
                    <v-card-text>
                        <v-icon size="18" class="btn-fechar" @click="fechar"> mdi-window-close </v-icon>
                        <img src="@/assets/ops/feliz.gif">
                        <div v-if="!signatario.chaveSeguranca">
                            <div class="subtitulo">Este {{ $tc("pt_br.signatario").toLowerCase() }} não possui chave de segurança cadastrada.</div>
                            <br>
                            <div class="subtitulo">Vamos cadastrar uma chave para ele?</div>
                            <br>
                            <v-text-field
                                v-model="chaveSeguranca"
                                label="Informe a chave"
                                solo
                                dense
                                hide-details
                            />
                        </div>
                        <div v-else>
                            <div class="subtitulo">Este {{ $tc("pt_br.signatario").toLowerCase() }} já possui chave de segurança cadastrada.
                            </div>
                            <br>
                            <div class="subtitulo">Deseja editar a chave?</div>
                            <br>
                            <div style="font-size: 20px; cursor: pointer" v-if="!editando" @click="habilitarEdicao()">
                                <a class="dado-editavel">{{ signatario.chaveSeguranca }}</a>
                                <v-icon size="23" color="#ccc" style="margin-left: 2px"> mdi-pencil </v-icon>
                            </div>
                            <v-text-field
                                v-if="editando"
                                v-model="chaveSeguranca"
                                label="Informe a chave"
                                solo
                                dense
                                hide-details
                            />
                        </div>
                    </v-card-text>
                    <v-divider/>
                    <v-card-actions style="padding: 8px 20px">
                        <a style="color: #ccc" @click="fechar">Cancelar</a>
                        <v-spacer/>
                        <a v-if="!signatario.chaveSeguranca" style="color: #499132"
                           @click="salvarChaveSeguranca(signatario, chaveSeguranca)">Salvar</a>
                        <div v-else>
                            <a v-if="!chaveSeguranca && !editando" style="color: #499132"
                               @click="salvarChaveSeguranca(signatario, signatario.chaveSeguranca)">Salvar</a>
                            <a v-else style="color: #499132"
                               @click="salvarChaveSeguranca(signatario, chaveSeguranca)">Salvar</a>
                        </div>
                    </v-card-actions>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
export default {
    name: 'ClmOpsChaveSeguranca',
    props: {
        clmOpsChaveSeguranca: {
            type: Boolean,
            required: true
        },
        signatario: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            chaveSeguranca: '',
            editando: false
        }
    },
    methods: {
        fechar() {
            this.chaveSeguranca = ''
            this.editando = false
            this.$emit('fecharClmOpsChaveSeguranca')
        },
        habilitarEdicao() {
            this.chaveSeguranca = this.signatario.chaveSeguranca
            this.editando = true
        },
        preencherChaveSeguranca(signatario, chaveSeguranca) {
            signatario.chaveSeguranca = chaveSeguranca.length === 0 ? null : chaveSeguranca
        },
        salvarChaveSeguranca(signatario, chaveSeguranca) {
            this.preencherChaveSeguranca(signatario, chaveSeguranca)
            this.editando = false
            this.$emit('atualizarChaveSeguranca', signatario)
        }
    }
}
</script>

<style lang="stylus">
.clm-ops-reenvio-convite
    .dado-editavel
        cursor pointer
        padding 0 5px
        border-radius 2px
        color var(--v-secondary-base)

        i
            font-size 12px !important
            padding-bottom 2px
            margin-left 2px
            color #ccc !important
            transition 0 !important
</style>