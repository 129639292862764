<template>
    <v-dialog :value="dialog" width="650" persistent>
        <v-card class="co-dialog-notificacao">
            <v-card-title class="primary title lighten-1 white--text">
                <div class="titulo">Bem Vindo!!</div>
            </v-card-title>

            <v-card-text class="mt-3">
                <p>
                    Olá {{ organizacao.nome }}, você está a um passo de iniciar o envio dos seus documentos para assinatura,
                    basta aceitar os termos e clicar em CONFIRMAR.
                    Se desejar alterar as configurações acesse o menu "Organização" no canto superior direito da página.
                </p>

                <v-divider/>

                <v-switch
                    v-model="organizacao.emailMarketing"
                    label="Aceito receber notícias e promoções da Besign."
                ></v-switch>

                <v-switch v-model="aceiteTermosUso">
                    <template v-slot:label>
                        <a href="https://www.efcaz.com.br/termos-de-uso-e-adesao" @click.stop class="pl-1" target="_blank">
                            Termos de Uso.
                        </a>
                    </template>
                </v-switch>

            </v-card-text>

            <v-card-actions class="mx-2 pb-6">
                <v-btn class="pa-4" color="background-200" elevation="0" @click="deslogar">Cancelar</v-btn>
                <v-spacer/>
                <v-btn class="pa-4 white--text" color="var(--primary)" elevation="0" :disabled="ehConfirmarDesabilitado"
                       @click="cadastrarOrganizacao">Confirmar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {actionTypes} from '@/commons/constants'

export default {
    name: 'DialogNovaOrganizacao',
    props: {
        dialog: {
            type: Boolean,
            default: false
        },
        ehPasta: {
            type: Boolean,
            default: false
        },
        usuarios: {
            type: Array
        }
    },
    data() {
        return {
            aceiteTermosUso: false,
            botaoNovoDesabilitado: false,
            organizacao: {
                nome: '',
                tipoPessoa: 'FISICA',
                cnpjCPF: '',
                email: '',
                telefone: '',
                recebeEmails: true,
                emailMarketing: true
            }
        }
    },
    computed: {
        ehConfirmarDesabilitado() {
            return !this.aceiteTermosUso || this.botaoNovoDesabilitado
        }
    },
    async created() {
        await this.buscarDadosOrganizacaoByUsuarioLogado()
    },
    methods: {
        async atualizarDependencias() {
            await this.$store.dispatch(actionTypes.LOGIN.BUSCAR_ORGANIZACAO_PLANO)
            await this.$store.dispatch(actionTypes.LOGIN.BUSCAR_VINCULO_USUARIO_ORGANIZACAO)
            await this.$store.dispatch(actionTypes.CONFIGURACAO.BUSCAR_ORGANIZACAO_CONFIGURACOES)
            await this.$store.dispatch(actionTypes.LOGIN.BUSCAR_FUNCIONALIDADES_ORGANIZACAO)
        },
        async buscarDadosOrganizacaoByUsuarioLogado() {
            const dadosOrganizacao = this.$store.state.organizacao
            this.organizacao.nome = dadosOrganizacao.nome
            this.organizacao.cnpjCPF = dadosOrganizacao.cpf
            this.organizacao.email = dadosOrganizacao.email
            this.organizacao.telefone = dadosOrganizacao.telefone
        },
        async cadastrarOrganizacao() {
            try {
                this.habilitarLoadingGlobal()
                this.botaoNovoDesabilitado = true

                await this.$store.dispatch(actionTypes.INSERIR_ORGANIZACAO, this.organizacao)
                await this.atualizarDependencias()

                await this.$router.push({name: 'meus'})

                this.desabilitarLoadingGlobal()
            } catch (e) {
                this.desabilitarLoadingGlobal()
                this.botaoNovoDesabilitado = false
            }
        },
        deslogar() {
            window.location.assign('/efcaz-clm/hal/logout')
        },
    }
}
</script>

<style lang="stylus">
.co-dialog-notificacao
    .titulo
        width 80%

    .co-lista-acesso
        width 350px
        display block
        color #777

        .subtitulo-lista
            padding 0 5px 5px 5px
            color #bbb
            font-size 11px
            border-bottom 1px solid #ddd

        .titulo-lista
            padding 5px 5px 0 5px
            font-weight bold

        .item-lista
            padding 5px
            display flex
            justify-content space-between

    .co-lista-acesso-admin
        display block
        color #777
        padding 10px 20px

        .subtitulo-lista
            padding 0 0 5px 0
            color #bbb
            font-size 11px
            border-bottom 1px solid #ddd

        .titulo-lista
            padding 5px 0 0 0
            font-weight bold

        .item-lista
            padding 5px 0
            display flex
            justify-content space-between
</style>