var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"co-notificacoes-webhook-pendentes-tabela"},[_c('v-data-table',{staticClass:"notificacoes-webhook-pendentes-tabela",attrs:{"hide-default-footer":"","no-data-text":"Nenhuma notificação pendente","headers":_vm.headers,"items-per-page":_vm.qtdLinhasTabela,"items":_vm.historico,"loading":_vm.loading,"loading-text":"Buscando notificações pendentes..."},scopedSlots:_vm._u([{key:"item.data",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatHora(item.data))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStatus(item.status))+" ")]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{staticClass:"acoes-tabela"},[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary","size":"15px"},on:{"click":function($event){return _vm.copiarNotificacao(item.conteudo)}}},on),[_vm._v(" mdi-content-copy ")])]}}],null,true)},[_c('span',[_vm._v("Copiar")])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }