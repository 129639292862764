<template>
    <v-dialog hide-overlay v-model="clmOpsEnviarSMS" width="0" persistent>
        <div class="clm-ops-reenvio-convite">
            <div class="text-xs-center">
                <div class="content">
                    <v-card-text>
                        <v-icon size="18" class="btn-fechar" @click="fechar"> mdi-window-close </v-icon>
                        <div v-if="!enviarSMS">
                            <img src="@/assets/ops/assustado.gif">
                            <div class="subtitulo">Este {{ $tc("pt_br.signatario").toLowerCase() }} não possui telefone cadastrado.
                            </div>
                            <br>
                            <div class="subtitulo">Vamos cadastrar um número para ele?</div>
                            <br>
                            <v-text-field
                                v-model="telefoneSignatario"
                                v-mask="mask"
                                label="55 (DDD) 000000000"
                                solo
                                dense
                                hide-details
                            />
                        </div>
                        <div v-else>
                            <img src="@/assets/ops/feliz.gif">
                            <div>
                            <span class="titulo" style="color: #2F5071">
                                    Esqueceu a chave de <br/>
                                    segurança do signatario?
                            </span>
                            </div>
                            <br>
                            <div style="font-size: 20px; cursor: pointer" v-if="!editando" @click="habilitarEdicao">
                                <span class="dado-editavel">{{ signatario.chaveSeguranca }}</span>
                                <v-icon size="23" color="#ccc" style="margin-left: 2px"> mdi-pencil </v-icon>
                            </div>
                            <v-text-field
                                v-if="editando"
                                v-model="chaveSeguranca"
                                label="55 (DDD) 00000-0000"
                                solo
                                dense
                                hide-details
                            />
                        </div>
                    </v-card-text>
                    <v-divider/>
                    <v-card-actions style="padding: 8px 20px">
                        <a style="color: #ccc" @click="fechar">Cancelar</a>
                        <v-spacer/>
                        <a v-if="campoTelefoneVazio && !enviarSMS" style="color: #ccc; pointer-events: none">
                            Salvar
                        </a>
                        <a v-else-if="campoTelefonePreenchido" style="color: #499132"
                           @click="atualizarTelefoneSignatario">
                            Salvar
                        </a>
                        <!--SMS desabilitado-->
                        <a style="color: #499132" v-else-if="false && !editando && possuiChaveSeguranca"
                           @click="enviarChaveSegurancaPorSMS">
                            Enviar SMS
                        </a>
                        <a style="color: #499132" v-else-if="chaveSeguranca && editando" @click="salvarChaveSeguranca">
                            Salvar
                        </a>
                        <a style="color: #ccc" v-else-if="!chaveSeguranca && editando">
                            Salvar
                        </a>
                    </v-card-actions>
                </div>
            </div>
        </div>
        <clm-ops-acabou-s-m-s
            :naoPossuiSMS="naoPossuiSMS"
            @fecharClmOpsAcabouSMS="fecharClmOpsAcabouSMS"
        />
    </v-dialog>
</template>

<script>
import {mapState} from 'vuex'
import ClmOpsAcabouSMS from '@/views/contrato/detalhe/area-trabalho/assinaturas/ClmOpsAcabouSMS'

export default {
    name: "ClmOpsEnviarSMS",
    components: {ClmOpsAcabouSMS},
    props: {
        clmOpsEnviarSMS: {
            type: Boolean,
            required: true
        },
        signatario: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            chaveSeguranca: '',
            editando: false,
            enviarSMS: true,
            mask: '+## (##) #########',
            naoPossuiSMS: false,
            telefoneSignatario: null
        }
    },
    computed: {
        ...mapState(['organizacao']),
        campoTelefoneVazio() {
            return !this.telefoneSignatario && !this.possuiTelefone && this.clmOpsEnviarSMS
        },
        campoTelefonePreenchido() {
            return this.telefoneSignatario && !this.possuiTelefone && this.clmOpsEnviarSMS
        },
        possuiTelefone() {
            return this.signatario.telefone
        },
        possuiChaveSeguranca() {
            return this.signatario.chaveSeguranca
        }
    },
    methods: {
        atualizarTelefoneSignatario() {
            this.enviarSMS = true
            this.preencherTelefoneSignatario()
            this.$emit('atualizarTelefoneSignatario')
        },
        enviarChaveSegurancaPorSMS() {
            if (!this.possuiTelefone) {
                return this.enviarSMS = false
            }
            if (this.organizacao.smsRestante === 0) {
                this.naoPossuiSMS = true
            } else {
                this.$emit('enviarChaveSegurancaPorSMS', this.signatario)
            }
        },
        fecharClmOpsAcabouSMS() {
            this.naoPossuiSMS = false
        },
        fechar() {
            this.enviarSMS = true
            this.chaveSeguranca = ''
            this.editando = false
            this.$emit('fecharClmOpsEnviarSMS')
        },
        habilitarEdicao() {
            this.chaveSeguranca = this.signatario.chaveSeguranca
            this.editando = true
        },
        preencherTelefoneSignatario() {
            this.signatario.telefone = this.telefoneSignatario.length === 0 ? null : this.telefoneSignatario.replace(/[^a-zA-Z0-9 ]/g, "").replace(/\s/g, "")
        },
        salvarChaveSeguranca() {
            this.signatario.chaveSeguranca = this.chaveSeguranca
            this.editando = false
            this.$emit('atualizarChaveSeguranca', this.signatario)
        }
    }
}
</script>