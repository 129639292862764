<template>
    <!--TODO: Liberar pagar com cartão pelo front => remover false-->
    <card-cartao-credito
        v-if="ehCartao && false"
        class="co-card-metodo-pagamento"
        :dados-cartao="dadosCartao"
        :metodo-selecionado="metodoSelecionado"
        :metodo="metodo"
    />

    <v-row no-gutters v-else class="co-card-metodo-pagamento">
        <v-radio
            color="primary lighten-1"
            class="radio px-3 py-6"
            :label="metodo.nome"
            :value="metodo"
        />
    </v-row>
</template>

<script>
import CardCartaoCredito from './cartao-credito/CardCartaoCredito'
import {metodoPagamentoEnum} from '@/commons/constants'

export default {
    name: 'CardMetodoPagamento',
    components: {CardCartaoCredito},
    props: {
        dadosCartao: {
            type: Object,
        },
        metodoSelecionado: {
            type: Object,
        },
        metodo: {
            type: Object,
        }
    },
    computed: {
        ehCartao() {
            return this.metodo === metodoPagamentoEnum.CARTAO_CREDITO
        }
    }
}
</script>

<style lang="stylus">
.co-card-metodo-pagamento
    border 1px solid #c7c7c7
    border-radius 10px
    font-size 11px
    width 100%


</style>
