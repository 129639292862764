const LINK = 'https://web.whatsapp.com/send?phone=NUMERO&text=MENSAGEM'
const MENSAGEM = 'Olá!%0aA empresa: *[Organização]* enviou um convite de assinatura no documento: *[DOCUMENTO]* para o e-mail: [EMAIL DO SIGNATÁRIO]%0a%0aAtenciosamente,%0aEquipe Efcaz'

class ConvidarSignatarioPorWhatsApp {

    enviarWhatsApp(signatario, contratoNome, organizacaoNome) {
        let enviarWhats = LINK
        let convite = MENSAGEM
        enviarWhats = enviarWhats.replace('NUMERO', signatario.telefone)
        convite = convite.replace('[Organização]', organizacaoNome)
        convite = convite.replace('[DOCUMENTO]', contratoNome)
        convite = convite.replace('[EMAIL DO SIGNATÁRIO]', signatario.email)
        convite = convite.replace(/ /g, '%20')
        convite = convite.replace('&', '%0D')
        enviarWhats = enviarWhats.replace('MENSAGEM', convite)
        window.open(enviarWhats, '_blank')
    }
}

const convidarSignatarioPorWhatsApp = new ConvidarSignatarioPorWhatsApp()
export default convidarSignatarioPorWhatsApp