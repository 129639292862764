<template>
    <v-dialog :value="dialog" max-width="500px" persistent scrollable>
        <v-card class="co-novo-usuario" :disabled="loading">
            <v-card-title class="title primary--text mb-6">
                <span v-if="editando">Editar dados do usuário</span>
                <span v-else>Novo Usuário</span>

                <v-spacer/>

                <v-icon color="primary" @click="fechar"> mdi-window-close </v-icon>
            </v-card-title>

            <v-card-text clas="campos mx-3">

                <v-radio-group
                    row
                    name="Perfis"
                    class="ml-3 mr-3 mt-0"
                    v-model="cadastroUsuario.perfil"
                    v-validate="'required'"
                    v-for="perfil in perfis"
                    :key="perfil.id"
                    :error-messages="errors.collect('Perfis')">
                    <v-radio
                        name="Nome"
                        class="mb-0 card-radio"
                        color="primary"
                        height="5px"
                        :label="perfil.nome"
                        :value="perfil"/>
                    <v-card-text class="grey--text pb-0 pt-0 ml-3 pb-0 mb-0 caption">
                        {{ perfil.descricao }}
                    </v-card-text>
                </v-radio-group>
                
                <nome-contato
                    v-model="cadastroUsuario"
                    title-case
                    campo-requerido
                    :desabilitarCampo="editando"
                />

                <email-contato
                    v-model="cadastroUsuario"
                    :desabilitarCampo="editando"
                    :campo-requerido="!ehAssinaturaWhatsapp"
                />

                <v-text-field
                    v-validate="validarCampo()"
                    :class="campoRequerido ? 'required-field' : ''"
                    label="CPF do usuário"
                    name="CPF"
                    :disabled="editando"
                    placeholder="000.000.000-00"
                    v-mask="['###.###.###-##']"
                    v-model="cadastroUsuario.documento"
                    :error-messages="errors.collect('CPF')"
                    persistent-placeholder />
            </v-card-text>

            <v-divider/>
            <v-card-actions class="mx-3 my-2">
                <v-btn class="cancelar" depressed outlined @click="fechar">
                    CANCELAR
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn v-if="editando" color="green white--text" depressed @click="realizarAcao" :loading="loading">
                    SALVAR
                </v-btn>
                <v-btn v-else color="green white--text" depressed @click="realizarAcao" :loading="loading">
                    ENVIAR CONVITE
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {actionTypes} from '@/commons/constants'
import NomeContato
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/NomeContato'
import EmailContato
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/EmailContato'

export default {
    name: 'DialogUsuarioForm',
    components: {
        NomeContato,
        EmailContato,
    },
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
        cadastroUsuario: {
            type: Object,
        },
        editando: {
            type: Boolean
        }
    },
    data() {
        return {
            perfis: [],
            loading: false,
        }
    },
    async mounted() {
        await this.buscarPerfil()
    },
    computed: {
        campoRequerido() {
            return false
        },
        ehAssinaturaWhatsapp() {
            return this.cadastroUsuario.tipoAssinatura === 'WHATSAPP'
        }
    },
    methods: {
        validarCampo() {
            return this.campoRequerido ? 'required|cpf' : ''
        },
        async buscarPerfil() {
            this.setMensagemLoading('Carregando perfis...')

            let todosPerfis = await this.$store.dispatch(actionTypes.BUSCAR_PERFIS)

            todosPerfis.forEach(perfil => {
                if (!this.perfilEscondido(perfil)) {
                    this.perfis.push(perfil)
                }
            })
        },
        perfilEscondido(perfil) {
            switch (perfil.nome) {
                case 'Visualizador':
                case 'Integração API':
                    return true
                default:
                    return false
            }
        },
        fechar() {
            if (this.editando) {
                this.$emit('fecharEdicao')
            } else {
                this.$emit('fechar')
            }
        },
        async realizarAcao() {
            const valido = await this.$validator._base.validateAll()
            
            if (!valido) {
                return
            }

            if (this.editando) {
                this.$emit('editar')
            } else {
                this.$emit('salvar')
            }
        },
        tratarMensagemErro(mensagem) {
            return mensagem.slice(2, -2)
        }
    }
}
</script>

<style lang="stylus">
.co-novo-usuario
    .campos
        overflow-y auto

    .cancelar
        color var(--text-color-200)

</style>
