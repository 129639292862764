<template>
    <div class="co-descricao-envelope">
        <p class="label-documentos">Documentos <span class="required-field">*</span></p>
    </div>
</template>

<script>
export default {
    name: 'DescricaoEnvelope'
}
</script>

<style lang="stylus">
.co-descricao-envelope
    margin-top 15px

    .label-documentos
        color #777777

    .required-field
        color var(--v-error-base)
</style>