import axios from 'axios'
import {actionTypes, mutationTypes} from '@/commons/constants'

export default {
    async[actionTypes.FEEDBACK.BUSCAR_FEEDBACK] ({commit, state}) {
        const {data} = await  axios.get('api/feedback')
        commit(mutationTypes.FEEDBACK.SET_PESQUISA_FEEDBACK, data)
    },

    async[actionTypes.FEEDBACK.INSERIR_FEEDBACK] ({commit, state}, feedback) {
        const {data} = await  axios.post('api/feedback', feedback)
        commit(mutationTypes.FEEDBACK.SET_PESQUISA_FEEDBACK, data)
        return data
    },

    async[actionTypes.FEEDBACK.RECUSAR_FEEDBACK] ({commit, state}, feedback) {
        const {data} = await  axios.post('api/feedback/recusar')
        commit(mutationTypes.FEEDBACK.SET_PESQUISA_FEEDBACK, data)
        return data
    },
}