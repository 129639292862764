<template>
    <v-col class="co-card-plano-mensal justify-center" cols="10" lg="2" md="3" sm="4" xs="1">
        <div v-if="!(ehMelhorOpcao || plano.promocao)" class="d-flex justify-center melhor-opcao"/>

        <v-card class="card-principal text-center mx-2">
            <div v-if="plano.promocao" class="melhor-opcao-black-friday">
                <div class="py-2"><strong>BLACK NOVEMBER</strong></div>
                <v-divider/>
            </div>

            <div v-else-if="ehMelhorOpcao" class="melhor-opcao">
                <div class="py-2"><strong>MELHOR OPÇÃO</strong></div>
                <v-divider/>
            </div>

            <v-card-text class="card-principal-conteudo text-color-default">
                <div class="justify-center titulo mb-5">{{ plano.titulo.toUpperCase() }}</div>

                <div v-if="plano.descricao" class="subtitulo mb-3 descricao">
                    <div v-for="descricao in getDescricao" :key="descricao">
                        {{ descricao }}
                    </div>
                </div>

                <div class="justify-center subtitulo preco mb-3">
                    <span v-if="plano.valorPacote > 0.00">
                        <span class="mb-3 headline font-weight-bold"> {{ formatarReal(plano.valorPacote) }} </span>
                        /mês
                    </span>
                </div>

                <div class="text-left beneficios">
                    <div v-for="beneficios in plano.beneficios" :key="beneficios.beneficio">
                        <span>
                            <v-icon v-if="beneficios.destaque === undefined || beneficios.destaque" color="green" size="15px">
                                mdi-check-circle-outline
                            </v-icon>

                            {{ beneficios.beneficio }}
                        </span>
                    </div>
                </div>
            </v-card-text>

            <v-card-actions>
                <div class="adquirir">
                    <v-btn v-if="ehPlanoAtual" class="botao meu-plano" block disabled>
                        MEU PLANO
                        <v-icon size="18" right> mdi-check </v-icon>
                    </v-btn>

                    <v-btn v-else-if="ehAlteracaoPlano" class="botao" :disabled="!ehAdministradorOrganizacao" block @click="fazerAlteracaoPlano">
                        Assinar
                    </v-btn>

                    <v-btn v-else class="botao" :color="getPrimaryColor" :disabled="!ehAdministradorOrganizacao" block dark @click="contratarPlano">
                        Assinar
                    </v-btn>
                </div>
            </v-card-actions>
        </v-card>

        <div v-if="!ehPlanoEmCancelamento && ehAdministradorOrganizacao" class="d-flex justify-center cancelar-plano">
            <span v-if="ehPlanoAtual" class="my-3 text-color-default btn-cancelar" @click="cancelarPlano">
                CANCELAR PLANO
            </span>
        </div>
    </v-col>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import CurrencyFormatterUtils from '@/commons/utils/CurrencyFormatterUtils'
import {actionTypes} from '@/commons/constants'

export default {
    name: 'CardPlanoMensal',
    props: {
        plano: {
            type: Object,
            required: true
        },
        ehPlanoAtual: {
            type: Boolean,
            default: false
        },
        ehMelhorOpcao: {
            type: Boolean,
            default: false
        },
        ehAlteracaoPlano: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState(['contatoComercial']),
        ...mapGetters(['ehPlanoEmCancelamento', 'ehAdministradorOrganizacao']),
        getDescricao() {
            return this.plano.descricao.split(';')
        },
        getPrimaryColor() {
            return this.plano.promocao ? 'black' : 'primary'
        },
    },
    methods: {
        cancelarPlano() {
            this.$emit('cancelar-plano')
        },
        async contratarPlano() {
            this.plano.parcelas = 0
            this.$emit('abrir-pagamentos', this.plano)

            await this.enviarEmailComercial()
        },
        async enviarEmailComercial() {
            await this.$store.dispatch(actionTypes.EMAIL.ENVIAR_EMAIL_COMERCIAL, {
                'mensagem': `Lead ${this.$store.state.loki.user.fullName}, do email: ${this.$store.state.login.organizacaoPlano.organizacaoEmail}, do telefone: ${this.$store.state.login.organizacaoPlano.organizacaoTelefone ?? 'N/A'}, da organização: ${this.$store.state.login.organizacaoPlano.organizacaoNome}, atualmente no plano '${this.$store.getters.nomePlano}' clicou em 'Assinar' no plano '${this.plano.titulo}'`,
                'assunto': `Lead ${this.$store.state.loki.user.fullName} clicou em Assinar - Plano ${this.plano.titulo}`,
                'tituloEmail': `Lead ${this.$store.state.loki.user.fullName} clicou em Assinar - Plano ${this.plano.titulo}`
            })
        },
        async fazerAlteracaoPlano() {
            this.$emit('alterar-plano')

            await this.enviarEmailComercial()
        },
        formatarReal(valor) {
            return CurrencyFormatterUtils.formatarBrl(Number(valor))
        },
    }
}
</script>

<style lang="stylus" scoped>
.co-card-plano-mensal
    min-width 290px

    .text-color-default
        color var(--text-color-200)

    .melhor-opcao
        height 37px

    .card-principal
        display flex
        flex-direction column
        justify-content space-between
        border-radius 5px

        .melhor-opcao
            background-color var(--v-primary-base)
            color white

        .melhor-opcao-black-friday
            background-color black
            color white

        .card-principal-conteudo

            .titulo
                font-weight bold
                font-size 17px
                color var(--primary)

            .subtitulo
                font-size 15px

            .descricao
                min-height 88px
                margin-bottom 12px

            .preco
                min-height 32px

            .beneficios
                font-size 13px
                height 160px

            .meu-plano
                color var(--primary) !important

    .adquirir
        width 100%

    .cancelar-plano
        min-height 45px
        
        .btn-cancelar
            cursor pointer

@media (min-width: 1840px)
    .co-card-plano-mensal
        .card-principal
            .card-principal-conteudo
                .beneficios
                    height 220px

@media (max-height: 600px)
    .co-card-plano-mensal
        .card-principal
            .card-principal-conteudo
                .beneficios
                    display none
</style>
