<template>
    <div class="co-tipo-assinatura-whatsapp">
        <div class="tipo-assinatura">

            <div class="tipo-assinatura-click" @click="tipoAssinaturaProximo">
                <div :style="getStyleTitle" class="assinatura-whatsapp-title">Eletrônica (Whatsapp)</div>
            </div>

            <v-spacer></v-spacer>
            <v-icon v-if="false" class="icon-info" size="14" @click="abrirDialogInfo"> mdi-information </v-icon>
        </div>

        <div :style="getStyleHint" class="assinatura-whatsapp-hint">
            {{ descricao }}
        </div>

        <label-adquirir-saldo v-if="whatsappHabilitado" :saldo="saldoWhatsapp" exibir
                              @adquirir-saldo="abrirLoja"/>

        <contratar-servico v-else @abrir-dialog-contratar-servico="abrirDialogContratarServico"/>

    </div>
</template>

<script>
import LabelAdquirirSaldo
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/LabelAdquirirSaldo'
import {mapGetters, mapState} from 'vuex'
import ContratarServico
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/ContratarServico'

export default {
    name: 'TipoAssinaturaWhatsapp',
    components: {
        ContratarServico,
        LabelAdquirirSaldo
    },
    props: ['value', 'whatsappHabilitado'],
    data() {
        return {
            dialogContratarServico: false
        }
    },
    computed: {
        ...mapState(['consumoOrganizacao']),
        ...mapGetters(['saldoWhatsapp']),
        descricao() {
            return `O ${this.$tc("pt_br.signatario").toLowerCase()} será convidado pelo WhatsApp, e todo o processo de assinatura será feito através dessa ferramenta`
        },
        getStyleHint() {
            return this.whatsappHabilitado && this.saldoDisponivel ? 'color: #aaa;' : 'color: rgba(170,170,170,0.5);'
        },
        getStyleTitle() {
            return this.whatsappHabilitado && this.saldoDisponivel ? 'color: var(--v-primary-base)' : 'color: rgba(119,119,119,0.4)'
        },
        saldoDisponivel() {
            return (this.consumoOrganizacao.totalWhatsapp - this.consumoOrganizacao.whatsappUtilizados) !== 0
        }
    },
    methods: {
        abrirLoja() {
            this.$router.push({name: 'planos'})
        },
        abrirDialogContratarServico() {
            this.$emit('abrir-dialog-contratar-servico')
        },
        abrirDialogInfo() {
            this.$emit('abrir-dialog-info', 'WhatsApp')
        },
        tipoAssinaturaProximo() {
            if (!this.whatsappHabilitado) {
                this.abrirDialogContratarServico()
            } else if (this.saldoDisponivel) {
                this.value.tipoAssinatura = 'WHATSAPP'
                this.$emit('tipo-assinatura-proximo')
            } else {
                this.abrirLoja()
            }
        }
    }
}
</script>

<style lang="stylus">
.co-tipo-assinatura-whatsapp
    .tipo-assinatura
        display flex

        .tipo-assinatura-click
            display flex
            cursor pointer

            .assinatura-whatsapp-title
                font-weight bold

            .assinatura-whatsapp-title:hover
                text-decoration-line underline

        .icon-info
            color #ccc

    .contratar-servico
        display flex
        margin-bottom 8px
        cursor pointer

        .contratar-servico-saldo
            color var(--v-error-base)
            font-size 10px

        .contratar-servico-comprar-mais
            color green
            font-weight bold
            font-size 10px
            transition all 500ms

        .contratar-servico-comprar-mais:hover
            padding 0 7px
            background rgba(240, 240, 240, .8)
            font-size 11px


.contratar-servico-icon
    color green

.assinatura-whatsapp-hint
    font-size 12px
    text-align justify
    margin-bottom 8px
</style>