<template>
    <options-picker
        :selected="value"
        :options="tags"
        label="Tags"
        display-field="nome"
        @add="adicionarTag"
        @remove="removerTag"
    />
</template>
<script>
import OptionsPicker from '@/commons/components/OptionsPicker'
import {mapState} from 'vuex'
import {actionTypes} from '@/commons/constants'
import _ from 'lodash'

export default {
    name: 'ContratoTag',
    components: {OptionsPicker},
    props: {
        value: {
            required: true,
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            tags: [],
            tagsOriginal: []
        }
    },
    async mounted() {
        await this.buscarTags()
    },
    computed: {
        ...mapState(['organizacao'])
    },
    watch: {
        'value'() {
            this.resetarTags()
            this.removerTagsSelecionadasDasOpcoesDisponiveis()
        }
    },
    methods: {
        adicionarTagNasOpcoesDisponiveis(tag) {
            this.tags.push(tag)
        },
        async buscarTags() {
            this.setMensagemLoading('Buscando Tags...')
            const tags = await this.$store.dispatch(actionTypes.BUSCAR_TAGS_POR_ORGANIZACAO, this.$store.state.login.organizacaoPlano.organizacaoId)
            this.tags = _.sortBy(tags, (tag) => tag.nome)
            this.tagsOriginal = _.clone(this.tags)
        },
        getIndiceTag(tag) {
            return _.findIndex(this.value, t => t.nome === tag.nome)
        },
        ehNovaTag(tag) {
            return typeof tag === 'string' || !tag.tagId
        },
        criarTag(tag) {
            if (this.ehNovaTag(tag)) {
                return {nome: tag}
            }
            return tag
        },
        adicionarTag(tag) {
            const novaTag = this.criarTag(tag)
            this.value.push(novaTag)
            if (!this.ehNovaTag(novaTag)) {
                this.removerTagDasOpcoesDisponiveis(novaTag)
            }
        },
        removerTag(tag) {
            const indice = this.getIndiceTag(tag)
            if (!this.ehNovaTag(this.value[indice])) {
                this.adicionarTagNasOpcoesDisponiveis(this.value[indice])
            }
            this.value.splice(indice, 1)
        },
        removerTagDasOpcoesDisponiveis(tag) {
            const indice = _.findIndex(this.tags, t => t.nome === tag.nome)
            this.tags.splice(indice, 1)
        },
        removerTagsSelecionadasDasOpcoesDisponiveis() {
            this.value.forEach(tag => this.removerTagDasOpcoesDisponiveis(tag))
        },
        resetarTags() {
            this.tags = _.clone(this.tagsOriginal)
        }
    }
}
</script>