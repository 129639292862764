<template>
    <v-text-field
        label="Valor Unitário"
        v-model="value.valor"
        v-validate="'required'"
        :error-messages="errors.collect('valor')"
        class="required-field item-minuta-valor"
        name="valor"
        placeholder="0,00"
        prefix="R$"
        type="number"
    />
</template>

<script>
export default {
    name: 'ValorUnitario',
    props: ['value']
}
</script>

<style lang="stylus">
.item-minuta-valor
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button
        -webkit-appearance none
        margin 0

    .v-text-field__prefix
        color #aaaaaa
</style>