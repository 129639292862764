<template>
    <v-dialog :value="dialog" width="700" persistent>
        <v-card>
            <v-card-title class="title text-color-300--text">
                Confirmar cancelamento do {{ $tc("pt_br.documento").toLowerCase() }}?
                <v-spacer/>
                <v-icon size="18" @click="fecharModalMotivoCancelamento"> mdi-window-close </v-icon>
            </v-card-title>
            <v-form v-model="form" ref="form" class="mx-4">
                <v-textarea
                    label="Informe o motivo do cancelamento"
                    class="ma-4"
                    counter="240"
                    v-model="cancelarContrato.motivoCancelamento"
                    :rules="[rules.required]"
                    v-validate="'max:240'"
                    data-vv-as="motivoCancelamento"
                    name="motivoCancelamento"
                    type="text"
                    :error-messages="errors.collect('Motivo do cancelamento')"
                />
                <v-divider/>
                <v-card-actions>
                    <v-btn color="background-200" @click="fecharModalMotivoCancelamento">
                        CANCELAR
                    </v-btn>
                    <v-spacer/>
                    <v-btn color="primary" :disabled="!form" @click="confirmarCancelamento">
                        CONFIRMAR
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'DialogMotivoCancelamento',
    props: {
        dialog: {
            type: Boolean
        }
    },
    data() {
        return {
            rules: {
                required: v => !!v || 'Campo Motivo do cancelamento é obrigatório',
            },
            form: false,
            cancelarContrato: {
                motivoCancelamento: ''
            }
        }
    },
    methods: {
        async confirmarCancelamento() {
            const valido = await this.$validator._base.validateAll()
            if (valido) {
                this.$emit('confirmarCancelamento', this.cancelarContrato)
            }
        },
        fecharModalMotivoCancelamento() {
            this.$emit('fecharModalMotivoCancelamento')
        }
    }
}
</script>