<template>
    <v-col class="co-novo-documento-signatario-opcoes-avancadas px-3">
        <v-expansion-panels flat v-model="opcoesAvancadas">
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Opções avançadas
                    <template v-slot:actions>
                        <v-icon color="primary"> mdi-chevron-down </v-icon>
                    </template>
                </v-expansion-panel-header>

                <v-expansion-panel-content class="px-2">
                    <v-row no-gutters class="full-size">
                        <rejeicao-documento-novo
                            :value="signatarioOpcao"
                            class="altura-inputs mt-2"
                        />
                    </v-row>

                    <v-row no-gutters class="full-size">
                        <documento-signatario-novo
                            class="mt-8"
                            v-model="signatarioOpcao"
                            :documentos="documentosSignatario"
                            campo-requerido
                        />
                    </v-row>

                    <v-row no-gutters class="full-size">
                        <mensagem-email-signatario-novo
                            class="mt-3"
                            :value="signatarioOpcao"
                        />
                    </v-row>

                    <v-row no-gutters class="full-size">
                        <chave-seguranca-novo
                            class="mt-3"
                            :value="signatarioOpcao"
                        />
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-col>
</template>

<script>
import ChaveSegurancaNovo
    from '@/views/criar-documento/novo-documento-signatario/components/opcoes-avancadas/components/ChaveSegurancaNovo'
import MensagemEmailSignatarioNovo
    from '@/views/criar-documento/novo-documento-signatario/components/opcoes-avancadas/components/MensagemEmailSignatarioNovo'
import DocumentoSignatarioNovo
    from '@/views/criar-documento/novo-documento-signatario/components/opcoes-avancadas/components/DocumentoSignatarioNovo'
import RejeicaoDocumentoNovo
    from '@/views/criar-documento/novo-documento-signatario/components/opcoes-avancadas/components/RejeicaoDocumentoNovo'

export default {
    name: 'OpcoesAvancadas',
    components: {
        ChaveSegurancaNovo,
        MensagemEmailSignatarioNovo,
        DocumentoSignatarioNovo,
        RejeicaoDocumentoNovo
    },
    props: {
        signatarioOpcao: {
            type: Object,
            required: true,
        },
        papeisSiganatario: {
            type: Array,
            required: true
        },
        documentosSignatario: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            opcoesAvancadas: false
        }
    }
}
</script>

<style lang="stylus">
.co-novo-documento-signatario-opcoes-avancadas

    .v-expansion-panel
        background-color unset !important

        .documento-tag
            width: 100%

        .v-expansion-panel-header
            color var(--primary)
            padding 0

            .v-icon
                color var(--primary) !important

        .v-expansion-panel-content__wrap
            padding 0

    .v-input__slot
        flex-direction row-reverse

    .altura-inputs
        height 40px
</style>