<template>
    <div>
        <div v-if="pasta">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-icon size="20"
                            class="icone-visualizar"
                            v-on="on"
                            v-if="podeNotificar"
                            :color="definirColorIconNotifications()"
                            @click="verificarPerfilUsuario">
                        mdi-bell
                    </v-icon>
                    <v-icon size="20"
                            class="icone-visualizar"
                            v-on="on"
                            v-else
                            :color="definirColorIconNotifications()"
                            @click="verificarPerfilUsuario">
                        mdi-bell-off
                    </v-icon>
                </template>
                <span>Silenciar/Ativar notificações</span>
            </v-tooltip>
        </div>
        <div v-if="contrato">
            <div v-if="!ehDetalheDoContrato">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attr }">
                        <v-icon size="20"
                                color="#ccc"
                                class="icone-visualizar"
                                v-on="on"
                                v-if="podeNotificar && !ehContratoCancelado"
                                @click="verificarPerfilUsuario">
                            mdi-bell
                        </v-icon>
                        <v-icon size="20"
                                color="#ccc"
                                class="icone-visualizar"
                                v-on="on"
                                v-else-if="ehContratoCancelado">
                            mdi-bell-off
                        </v-icon>
                        <v-icon color="#e5e5e5"
                                size="20"
                                class="icone-visualizar"
                                v-on="on"
                                v-else-if="!podeNotificar && !ehContratoCancelado"
                                @click="verificarPerfilUsuario">
                            mdi-bell-off
                        </v-icon>
                    </template>
                    <span>Silenciar/Ativar notificações</span>
                </v-tooltip>
            </div>
            <div v-if="ehDetalheDoContrato && !ehAdministradorOrganizacao && this.ehContratoNotificavel">
                <v-tooltip bottom color="white">
                    <template v-slot:activator="{ on }">
                        <v-icon size="20"
                                class="ml-2"
                                v-on="on"
                                v-if="podeNotificar && !ehMinutaCancelada"
                                @click="verificarPerfilUsuario">
                            mdi-bell
                        </v-icon>
                        <v-icon color="rgba(255,255,255,0.5)"
                                size="20"
                                class="ml-2"
                                v-on="on"
                                v-if="!podeNotificar || ehMinutaCancelada"
                                @click="verificarPerfilUsuario">
                            mdi-bell-off
                        </v-icon>
                    </template>
                    <div class="co-lista-acesso">
                        <div class="titulo-lista">
                            LISTA DE USUÁRIOS NOTIFICADOS
                        </div>
                        <div class="subtitulo-lista">
                            Estes usuários serão notificados por e-mail quando ocorrerem alterações no documento.
                        </div>
                        <div class="item-lista" v-for="usuario in usuariosNotificacaoContrato" :key="usuario.id">
                            <div>{{ usuario.usuarioNome }}</div>
                            <v-icon size="16" color="#999" v-if="usuario.notificar"> mdi-bell </v-icon>
                            <v-icon size="16" color="#ccc" v-else> mdi-bell-off </v-icon>
                        </div>
                    </div>
                </v-tooltip>
            </div>
            <div
                v-if="ehDetalheDoContrato && podeNotificar && ehAdministradorOrganizacao && this.ehContratoNotificavel">
                <v-icon class="ml-2" size="20" slot="activator" @click="verificarPerfilUsuario"> mdi-bell </v-icon>
            </div>
            <div
                v-if="ehDetalheDoContrato && !podeNotificar && ehAdministradorOrganizacao && this.ehContratoNotificavel">
                <v-icon class="ml-2" color="rgba(255,255,255,0.5)" size="20" slot="activator"
                        @click="verificarPerfilUsuario">
                    mdi-bell-off
                </v-icon>
            </div>
            <div v-if="ehDetalheDoContrato && ehMinutaCancelada || ehContratoArquivado || ehContratoExtinto">
                <v-icon class="ml-2" color="rgba(255,255,255,0.5)" size="20" slot="activator"> mdi-bell-off </v-icon>
            </div>
        </div>
        <clm-dialog v-model="mostrarModalDesativarNotificacao" v-if="mostrarModalDesativarNotificacao">
            <clm-ops sentimento="assustado">
                <div slot="titulo">Eita! Deixa eu confirmar uma coisa...</div>
                <div slot="subtitulo">
                    {{ getLabelModalDesativarNotificacao() }}
                </div>
                <div slot="acoes">
                    <div class="principal">
                        <a @click="desativarNotificacao">Sim</a>
                    </div>
                    <div class="secundaria">
                        <a @click="fecharModalDesativarNotificacao">Não, obrigado</a>
                    </div>
                </div>
            </clm-ops>
        </clm-dialog>
    </div>
</template>

<script>
import ClmDialog from '@/commons/components/ClmDialog'
import ClmOps from '@/commons/components/ClmOps'

const mensagensModal = {
    MENSAGEM_DESATIVAR_PASTA: 'Você quer mesmo desativar as notificações desta pasta? Ao confirmar\n' +
        '                    não será notificado sobre todos os documentos existentes na pasta.',
    MENSAGEM_DESATIVAR_CONTRATO: 'Você quer mesmo desativar as notificações deste documento? Ao confirmar\n' +
        '                    não será notificado sobre as ações do documento.'
}

export default {
    name: 'notificacao',
    components: {ClmDialog, ClmOps},
    data() {
        return {
            usuario: this.$store.state.login.usuarioOrganizacao
        }
    },
    props: {
        contrato: {
            required: false,
            default: false
        },
        ehAdministradorOrganizacao: {
            required: false,
            default: false
        },
        ehContratoArquivado: {
            required: false,
            default: false
        },
        ehContratoCancelado: {
            required: false,
            default: false
        },
        ehContratoExtinto: {
            required: false,
            default: false
        },
        ehDetalheDoContrato: {
            required: false,
            default: false
        },
        ehMinutaCancelada: {
            required: false,
            default: false
        },
        mostrarModalDesativarNotificacao: {
            default: false
        },
        pasta: {
            required: false,
            default: false
        },
        podeNotificar: {
            required: false
        },
        pastaSelecionada: {
            required: false,
            default: false
        },
        usuariosNotificacaoContrato: {
            required: false
        }
    },
    computed: {
        ehContratoNotificavel() {
            return !this.ehMinutaCancelada && !this.ehContratoArquivado && !this.ehContratoExtinto
        }
    },
    methods: {
        definirColorIconNotifications() {
            if (this.pastaSelecionada) {
                if (this.podeNotificar) {
                    return '#aaa'
                }
                return '#ccc'
            }
            if (this.podeNotificar) {
                return '#ccc'
            }
            return '#e5e5e5'
        },
        desativarNotificacao() {
            this.$emit('desativarNotificacao', this.usuario)
        },
        fecharModalDesativarNotificacao() {
            this.$emit('fecharModalDesativarNotificacao')
        },
        getLabelModalDesativarNotificacao() {
            return this.pasta ? mensagensModal.MENSAGEM_DESATIVAR_PASTA : mensagensModal.MENSAGEM_DESATIVAR_CONTRATO
        },
        verificarPerfilUsuario() {
            this.$emit('verificarPerfilUsuario', this.usuario)
        }
    }
}
</script>

<style lang="stylus">
.co-lista-acesso
    width 350px
    display block
    color #777

    .subtitulo-lista
        padding 0 5px 5px 5px
        color #bbb
        font-size 11px
        border-bottom 1px solid #ddd

    .titulo-lista
        padding 5px 5px 0 5px
        font-weight bold

    .item-lista
        padding 5px
        display flex
        justify-content space-between
</style>