<template>
    <v-card>
        <v-card-text class="pa-0">
            <v-row no-gutters>
                <p class="pt-4 mx-4 text-left">
                    Para posicionar, clique no campo abaixo que deseja incluir no documento e depois no documento.
                    Caso deseje alterar o posicionamento, segure o campo e arraste até o local desejado.
                </p>
            </v-row>
        </v-card-text>
    </v-card> 
</template>

<script>
    export default {
        name: 'DicaPosicionamentoAssinatura'
    }
</script>