export default {
    COMUN: {
        BUSCAR_CONSUMO_ORGANIZACAO: 'buscarConsumoOrganizacao',
    },
    CONFIGURACAO: {
        ACEITAR_TERMO_USO_SIGNATARIOS_PAGANTES: 'aceitarTermoUsoSignatariosPagantes',
        BUSCAR_ORGANIZACAO_CONFIGURACOES: 'buscarOrganizacaoConfiguracoes',
        ATUALIZAR_ORGANIZACAO_CONFIGURACOES_GERAIS: 'atualizarOrganizacaoConfiguracoesGerais',
        ATUALIZAR_ORGANIZACAO_CONFIGURACOES_NOTIFICACOES: 'atualizarOrganizacaoConfiguracoesNotificacoes',
        CRIAR_TOKEN_API_V2: 'criarTokenApiV2',
        WHITE_LABEL: {
            ATUALIZAR_ORGANIZACAO_CONFIGURACOES_WHITE_LABEL: 'atualizarOrganizacaoConfiguracoesWhiteLabel',
            BUSCAR_ORGANIZACAO_CONFIGURACOES_WHITE_LABEL: 'buscarOrganizacaoConfiguracoesWhiteLabel'
        },
    },
    COMPRAS:{
        INSERIR: 'inserir',
        HISTORICO_ADICIONAIS_ORGANIZACAO: 'historicoAdicionaisOrganizacao',
        PLANOS: 'planos',
        BUSCAR_PLANO_ASSINATURA_ADICIONAL: 'buscarPlanoAssinaturaAdicional'
    },
    COBRANCAS: {
        HISTORICO_ORGANIZACAO: 'historicoOrganizacao'
    },
    CONTRATO:{
        BUSCAR_TODOS_CONTRATOS: 'buscarTodosContratos',
        BUSCAR_CONTRATOS_FILTRADOS: 'buscarContratosFiltrados'
    },
    EMAIL: {
        ENVIAR_EMAIL_COMERCIAL: 'enviarEmailComercial'
    },
    ITEM: {
        ATUALIZAR_ITEM_MINUTA: 'atualizarItemMinuta',
        BUSCAR_ITENS_MINUTA: 'buscarItensMinuta',
        REMOVER_ITEM_MINUTA: 'removerItemMinuta',
        IMPORTAR_ITENS_MINUTA: 'importarItensMinuta',
        INSERIR_ITEM_MINUTA: 'inserirItemMinuta'
    },
    LOGIN: {
        BUSCAR_PRODUTO_POR_NOME: 'buscarProdutoNome',
        BUSCAR_USUARIO_LOGADO: 'buscarUsuarioLogado',
        BUSCAR_ORGANIZACAO_PLANO: 'buscarOrganizacaoPlano',
        BUSCAR_VINCULO_USUARIO_ORGANIZACAO: 'buscarVinculoUsuarioOrganizacao',
        BUSCAR_PARAMETROS_SISTEMA: 'buscarParametrosSistema',
        BUSCAR_FUNCIONALIDADES_ORGANIZACAO: 'buscarFuncionalidadesOrganizacao'
    },
    POSICIONAMENTO_DADO: {
        ATUALIZAR: 'atualizarPosicionamentoDado',
        INSERIR: 'inserirPosicionamentoDado',
        BUSCAR_POR_MINUTA: 'buscarPosicionamentoDadoSignatariosPorMinuta'
    },
    SIGNATARIO: {
        ATUALIZAR_SALDO_SMS: 'atualizarSaldoSms',
        INSERIR_SIGNATARIO_LOTE: 'inserirSignatarioLote',
        BUSCAR_SIGNATARIOS_SUGESTOES: 'buscarSignatariosSugestoes',
        INSERIR_SIGNATARIOS_NOVO: 'inserirSignatariosNovo',
        PRIORIZAR_SIGANTARIOS_WHATSAPP: 'priorizarSignatariosWhatsapp',
        INSERIR_SIGNATARIO: 'inserirSignatario'
    },
    ORGANIZACAO: {
        BUSCAR_DADOS_ORGANIZACAO_PRIMEIRO_ACESSO: 'buscarDadosOrganizacaoPrimeiroAcesso',
        BUSCAR_DADOS_ORGANIZACAO: 'buscarDadosOrganizacao',
        BUSCAR_CEP: 'buscarCEP',
        ATUALIZAR: 'atualizarOrganizacao',
    },
    USUARIO:{
        BUSCAR_POR_ORGANIZACAO: 'usuariosBuscarPorOrganizacao',
        BUSCAR_PREFERENCIAS_USUARIO: 'buscarPreferenciasUsuario',
        ATUALIZAR_PREFERENCIAS_USUARIO: 'atualizarPreferenciasUsuario',
        INSERIR_USUARIO_ORGANIZACAO: 'inserirUsuarioOrganizacao'
    },
    CADASTRO_SIGNATARIOS: {
        BUSCAR_CADASTRO_SIGNATARIO: 'buscarCadastroSignatario',
        BUSCAR_CADASTROS_SIGNATARIOS_FILTRADOS: 'buscarCadastrosSignatariosFiltrados',
        BUSCAR_MEUS_CADASTROS_SIGNATARIOS: 'buscarMeusCadastrosSignatarios',
        INSERIR_CADASTRO_SIGNATARIO: 'inserirCadastroSignatario',
        ATUALIZAR_CADASTRO_SIGNATARIO: 'atualizarCadastroSignatario',
        REMOVER_CADASTRO_SIGNATARIO: 'removerCadastroSignatario',
        IMPORTAR_CADASTROS_SIGNATARIOS: 'importarCadastrosSignatarios'
    },
    GRUPO_SIGNATARIOS: {
        BUSCAR_GRUPO_SIGNATARIOS: 'buscarGrupoSignatarios',
        BUSCAR_GRUPOS_SIGNATARIOS_FILTRADOS: 'buscarGruposSignatariosFiltrados',
        BUSCAR_MEUS_GRUPOS_SIGNATARIOS: 'buscarMeusGruposSignatarios',
        INSERIR_GRUPO_SIGNATARIOS: 'inserirGrupoSignatarios',
        ATUALIZAR_GRUPO_SIGNATARIOS: 'atualizarGrupoSignatarios',
        REMOVER_GRUPO_SIGNATARIOS: 'removerGrupoSignatarios',
        BUSCAR_SIGNATARIOS_IMPORTACAO: 'buscarSignatariosImportacao',
        IMPORTAR_CADASTROS_SIGNATARIOS_GRUPOS: 'importarCadastrosSignatariosGrupos'
    },
    WEBHOOK: {
        ATUALIZAR_ORGANIZACAO_CONFIGURACOES_WEBHOOKS: 'atualizarOrganizacaoConfiguracoesWebhooks',
        BUSCAR_ORGANIZACAO_CONFIGURACOES_WEBHOOKS: 'buscarOrganizacaoConfiguracoesWebhooks',
        BUSCAR_WEBHOOKS_PENDENTES_ENVIO: 'buscarWebhooksPendentesEnvio',
        REENVIAR_WEBHOOKS_PENDENTES: 'reenviarWebhooksPendentes'
    },
    RELATORIO:{
        GERAR: 'gerarRelatorio'
    },
    PLANOS: {
        BUSCAR_TODOS_PLANOS_ORDENADOS: 'buscarTodosPlanosOrdenados',
        SOLICITAR_CANCELAMENTO_PLANO: 'solicitarCancelamentoPlano'
    },
    FEEDBACK: {
        BUSCAR_FEEDBACK: 'buscarFeedback',
        INSERIR_FEEDBACK: 'inserirFeedback',
        RECUSAR_FEEDBACK: 'recusarFeedback'
    },
    PASTAS: {
        ATRIBUIR_ACESSO_USUARIOS_PASTA: 'atribuirAcessoUsuariosPasta',
        BUSCAR_USUARIOS_COM_ACESSO_PASTA: 'buscarUsuariosComAcessoPasta',
        REMOVER_ACESSO_USUARIO_PASTA: 'removerAcessoUsuarioPasta',

        BUSCAR_PASTAS_PAGINADAS: 'buscarPastasPaginadas',
        BUSCAR_PASTAS_FILTRADAS: 'buscarPastasFiltradas',
        BUSCAR_PASTAS_ACESSO_USUARIO: 'buscarPastasAcessoUsuario',

        ATUALIZAR_PASTA: 'atualizarPasta',
        INSERIR_PASTA: 'inserirPasta',
        MOVER_PASTA: 'moverPasta',
        REMOVER_PASTA: 'removerPasta',
    },
    ARQUIVAR_CONTRATO: 'arquivarContrato',
    ATIVAR_NOTIFICACAO_CONTRATO: 'ativarNotificacaoContrato',
    ATIVAR_NOTIFICACAO_PASTA: 'ativarNotificacaoPasta',
    ATRIBUIR_DOMINIO_PERFIL_NOVO_USUARIO: 'atribuirDominioPerfilNovoUsuario',
    ATUALIZAR_CONTRATO: 'atualizarContrato',
    ATUALIZAR_MINUTA: 'atualizarMinuta',
    ATUALIZAR_TAGS_CONTRATO: 'atualizarTagsContrato',
    ATIVAR_USUARIO_ORGANIZACAO: 'ativarUsuarioOrganizacao',
    BUSCAR_ANEXOS: 'buscarAnexos',
    BUSCAR_CONTRATO: 'buscarContrato',
    BUSCAR_CIDADES: 'buscarCidades',
    BUSCAR_DADOS_USUARIO: 'buscarDadosUsuario',
    BUSCAR_ESTADOS: 'buscarEstados',
    BUSCAR_HISTORICO_CONTRATO: 'buscarHistoricoContrato',
    BUSCAR_HISTORICO_MENSAGENS: 'buscarHistoricoMensagens',
    BUSCAR_LINK_EDITAR_USUARIO: 'buscarLinkEditarUsuario',
    BUSCAR_MEUS_CONTRATOS: 'buscarMeusContratos',
    BUSCAR_MINUTA: 'buscarMinuta',
    BUSCAR_MINUTA_ATUAL: 'buscarMinutaAtual',
    BUSCAR_NOTIFICACOES: 'buscarNotificacoes',
    BUSCAR_DOCUMENTOS_SIGNATARIO: 'buscarDocumentosSignatario',
    BUSCAR_PAPEIS_SIGNATARIOS: 'buscarPapeisSignatarios',
    BUSCAR_PERFIS: 'buscarPerfis',
    BUSCAR_PLANOS: 'buscarPlanos',
    BUSCAR_ORGANIZACAO: 'buscarOrganizacao',
    BUSCAR_SANCOES: 'buscarSancoes',
    BUSCAR_SIGNATARIOS: 'buscarSignatarios',
    BUSCAR_USUARIOS_NOTIFICACAO_PASTA: 'buscarUsuariosNotificacaoPasta',
    BUSCAR_TAGS_POR_ORGANIZACAO: 'buscarTagsPorOrganizacao',
    BUSCAR_USUARIO_POR_ID: 'buscarUsuarioPorId',
    BUSCAR_USUARIOS_NOTIFICACAO_CONTRATO: 'buscarUsuarioNotificacaoContrato',
    BUSCAR_SIGNATARIOS_POR_MINUTA_VERSAO: 'buscarSignatariosPorMinutaVersao',
    BUSCAR_USUARIOS_POR_ORGANIZACAO: 'buscarUsuariosPorOrganizacao',
    CRIAR_CONTRATO: 'criarContrato',
    CRIAR_CONTRATO_LOTE: 'criarContratoLote',
    CANCELAR_MINUTA: 'cancelarMinuta',
    CONVIDAR_SIGNATARIOS: 'convidarSignatarios',
    CONVIDAR_SIGNATARIO_POR_EMAIL: 'convidarSignatarioPorEmail',
    CONVIDAR_SIGNATARIO_POR_EMAIL_SMS: 'convidarSignatarioPorEmailSMS',
    CONVIDAR_SIGNATARIO_POR_SMS: 'convidarSignatarioPorSMS',
    CONVIDAR_SIGNATARIO_PRESENCIAL: 'convidarSignatarioPresencial',
    CONVIDAR_USUARIO_ORGANIZACAO: 'convidarUsuarioOrganizacao',
    DEFINIR_ORDEM_ASSINATURA: 'DefinirOrdemaAssinatura',
    DESATIVAR_NOTIFICACAO_CONTRATO: 'desativarNotificacaoContrato',
    DESATIVAR_NOTIFICACAO_PASTA: 'desativarNotificacaoPasta',
    DOWNLOAD_ARQUIVO_ANEXO: 'downloadArquivoAnexo',
    DOWNLOAD_ARQUIVO_SANCAO: 'downloadArquivoSancao',
    DOWNLOAD_ARQUIVO_EXTINCAO: 'downloadArquivoExtincao',
    DOWNLOAD_MINUTA_ASSINADA: 'downloadMinutaAssinada',
    DOWNLOAD_MINUTA_IMPRESSAO: 'downloadMinutaImpressao',
    DOWNLOAD_MINUTA_ZIP: 'downloadMinutaZip',
    DOWNLOAD_MINUTA_ORIGINAL: 'downloadMinutaOriginal',
    ENVIAR_DADOS_ACESSO_USUARIO: 'enviarDadosAcessoUsuario',
    ENVIAR_CHAVE_SEGURANCA_POR_SMS: 'enviarChaveSegurancaPorSMS',
    FORMALIZAR_CONTRATO: 'formalizarContrato',
    INATIVAR_USUARIO_ORGANIZACAO: 'inativarUsuarioOrganizacao',
    INSERIR_ANEXO: 'inserirAnexo',
    ATUALIZAR_SIGNATARIO: 'atualizarSignatario',
    EXTINGUIR_CONTRATO: 'inserirExtincao',
    INSERIR_ORGANIZACAO: 'inserirOrganizacoes',
    INSERIR_SANCAO: 'inserirSancao',
    LER_NOTIFICACOES: 'lerNotificacoes',
    MOVER_CONTRATO_PASTA: 'moverContratoPasta',
    REENVIAR_CONVITE_SIGNATARIO: 'reenviarConviteSignatario',
    REMOVER_CONTRATO: 'removerContrato',
    REMOVER_NOTIFICACAO: 'removerNotificacao',
    REMOVER_SIGNATARIO: 'removerSignatario',
    REMOVER_ANEXO: 'removerAnexo',
    REMOVER_SANCAO: 'removerSancao',
    REMOVER_ORDEM_ASSINATURA: 'removerOrdemAssinatura',
    RENOVAR_CONTRATO: 'renovarContrato',
    REDEFINIR_ORDEM_SIGNATARIOS: 'redefinirOrdemSignatarios',
    RESTAURAR_CONTRATO_ARQUIVADO: 'restaurarContratoArquivado',
    VINCULAR_USUARIO_COM_ORGANIZACAO: 'vincularUsuarioComOrganizacao',
    VISUALIZAR_ASSINATURA_MANUSCRITA: 'visualizarAssinaturaManuscrita',
}
