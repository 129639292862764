<template>
    <v-col class="mt-5 co-posicionamento-assinatura">
        <v-row class="d-flex align-center pl-2">
            <h3 class="primary--text">
                <v-icon color="primary" @click="voltar" size="20"> mdi-arrow-left </v-icon>
                Posicionar Assinatura
            </h3>
        </v-row>
        <v-col class="mt-5 px-3 pb-0 mb-n5">
            <dica-posicionamento-assinatura />
        </v-col>
        <v-col>
            <div v-for="signatario in signatarios" :key="signatario.index">
                <card-posicionamento-assinatura
                    v-if="ehSignatarioEmail"
                    :signatario="signatario"
                    :papeis-signatario="papeisSignatario"
                    @criar-posicionamento-assinatura="criarPosicionamentoAssinatura"
                />
            </div>
        </v-col>
        <v-col class="mt-2 acoes">
            <v-row class="justify-center">
                <v-btn color="primary" large
                        :disabled="loadingSalvar"
                        @click="enviarParaAssinatura(false)"
                        class="px-5 mt-3">
                    Enviar para Assinatura
                </v-btn>
            </v-row>

            <v-row class="d-flex justify-center my-3">
                <v-btn text large plain
                        :disabled="loadingSalvar"
                        color="primary"
                        @click="pularEtapa"
                        class="btn-pular px-5 mt-4">
                    Pular Etapa
                </v-btn>
            </v-row>
        </v-col>
    </v-col>
</template>

<script>
import CardPosicionamentoAssinatura from './components/CardPosicionamentoAssinatura'
import DicaPosicionamentoAssinatura from '../../../../common/DicaPosicionamentoAssinatura'

export default {
    name: 'PosicionamentoAssinatura',
    components: {CardPosicionamentoAssinatura, DicaPosicionamentoAssinatura},
    props: {
        papeisSignatario: {
            type: Array,
            required: true
        },
        signatarios: {
            type: Array,
            required: true
        },
        loadingSalvar: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ehSignatarioEmail(signatario) {
            return signatario.tipoAssinatura !== 'WHATSAPP'
        }
    },
    methods: {
        criarPosicionamentoAssinatura(signatario) {
            this.$emit('criar-posicionamento-assinatura', signatario)
        },
        enviarParaAssinatura(pularEtapa = false) {
            this.$emit('salvar-once', pularEtapa)
        },
        pularEtapa(pularEtapa = true) {
            this.$emit('salvar-once', pularEtapa)
        },
        voltar() {
            this.$emit('voltar')
        }
    }
}
</script>

<style lang="stylus">
@media (max-width: 600px)
    .co-posicionamento-assinatura
        .acoes
            justify-content center
</style>