<template>
    <v-dialog :value="dialog" width="700" persistent>
        <v-card class="co-dialog-notificacao">
            <v-card-title class="primary title lighten-1 white--text">
                <div class="titulo">{{ getLabelNotificacaoContrato() }}</div>
                <v-spacer/>
                <v-icon color="white" @click="fecharModalNotificacao"> mdi-window-close </v-icon>
            </v-card-title>
            <div class="co-lista-acesso-admin">
                <div class="item-lista" style="font-weight: bold; border-bottom: 1px solid #ddd">
                    Usuários
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon size="16" color="#ccc" v-on="on" @click="desativarTodasNotificacao()"> mdi-bell-off</v-icon>
                        </template>
                        Silenciar todos os usuários
                    </v-tooltip>
                </div>
                <div class="item-lista" v-for="usuario in usuarios" :key="usuario.id" v-show="usuario.ativo">
                    {{ usuario.usuarioNome }}
                    <v-icon size="16" color="#999" v-if="usuario.notificar" @click="desativarNotificacao(usuario)">
                        mdi-bell
                    </v-icon>
                    <v-icon size="16" color="#ccc" v-else @click="ativarNotificacao(usuario)">
                        mdi-bell-off
                    </v-icon>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'DialogNotificacao',
    props: {
        dialog: {
            type: Boolean,
            default: false
        },
        ehPasta: {
            type: Boolean,
            default: false
        },
        usuarios: {
            type: Array
        }
    },
    methods: {
        ativarNotificacao(usuario) {
            this.$emit('ativarNotificacao', usuario)
        },
        desativarNotificacao(usuario) {
            this.$emit('desativarNotificacao', usuario)
        },
        desativarTodasNotificacao() {
            this.$emit('desativarTodasNotificacoes', this.usuarios)
        },
        getLabelNotificacaoContrato() {
            if (this.ehPasta) {
                return 'Gerenciar usuários notificados na pasta'
            }
            return 'Gerenciar usuários notificados no ' + this.$tc("pt_br.documento").toLowerCase()
        },
        fecharModalNotificacao() {
            this.$emit('fecharModalNotificacao')
        }
    }
}
</script>

<style lang="stylus">
.co-dialog-notificacao
    .titulo
        width 80%

    .co-lista-acesso
        width 350px
        display block
        color #777

        .subtitulo-lista
            padding 0 5px 5px 5px
            color #bbb
            font-size 11px
            border-bottom 1px solid #ddd

        .titulo-lista
            padding 5px 5px 0 5px
            font-weight bold

        .item-lista
            padding 5px
            display flex
            justify-content space-between

    .co-lista-acesso-admin
        display block
        color #777
        padding 10px 20px

        .subtitulo-lista
            padding 0 0 5px 0
            color #bbb
            font-size 11px
            border-bottom 1px solid #ddd

        .titulo-lista
            padding 5px 0 0 0
            font-weight bold

        .item-lista
            padding 5px 0
            display flex
            justify-content space-between
</style>