<template>
    <v-menu open-on-hover offset-y top left>
        <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="secondary-200" size="22"> mdi-dots-vertical </v-icon>
        </template>
        <v-list class="co-home-pasta-mais-opcoes">
            <v-list-item @click="editarPasta(pasta)" v-az-auth="'Pasta.Editar'">
                <v-list-item-title class="opcao-menu">
                    <v-icon size="18" class="mr-2"> mdi-pencil </v-icon>
                    Renomear
                </v-list-item-title>
            </v-list-item>

            <v-list-item @click="excluirPasta(pasta)" v-az-auth="'Pasta.Excluir'">
                <v-list-item-title class="opcao-menu">
                    <v-icon size="18" class="mr-2"> mdi-delete </v-icon>
                    Excluir
                </v-list-item-title>
            </v-list-item>

            <v-list-item @click="moverPasta(pasta)" v-az-auth="'Pasta.Editar'">
                <v-list-item-title class="opcao-menu">
                    <v-icon class="mr-2" size="18px"> mdi-folder </v-icon>
                    Mover para Pasta
                </v-list-item-title>
            </v-list-item>

            <v-list-item v-if="ehAdministradorOrganizacao" @click="compartilharPasta(pasta)"
                         v-az-auth="'Pasta.Compartilhar'">
                <v-list-item-title class="opcao-menu">
                    <v-icon size="18" class="mr-2"> mdi-account-multiple </v-icon>
                    Compartilhar
                </v-list-item-title>
            </v-list-item>

            <v-list-item v-if="ehMobile" @click="notificacaoPasta">
                <v-list-item-title class="opcao-menu">
                    <v-icon size="18" class="mr-2"> mdi-bell </v-icon>
                    Notificação
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'MaisOpcoes',
    props: {
        pasta: {
            type: Object
        }
    },
    computed: {
        ...mapGetters(['ehAdministradorOrganizacao']),
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        },
    },
    methods: {
        editarPasta(pasta) {
            this.$emit('editar-pasta', pasta)
        },
        excluirPasta(pasta) {
            this.$emit('excluir-pasta', pasta)
        },
        compartilharPasta(pasta) {
            this.$emit('compartilhar-pasta', pasta)
        },
        moverPasta(pasta) {
            this.$emit('mover-pasta', pasta)
        },
        notificacaoPasta() {
            this.$emit('notificacao-pasta')
        }
    }
}
</script>

<style lang="stylus">
.co-home-pasta-mais-opcoes
    .opcao-menu
        font-size 14px !important
        color var(--text-color-300) !important
</style>