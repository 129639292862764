<template>
    <v-form ref="documentoForm" class="co-documento-formulario-novo my-0 mx-0 pa-3">
        <h3 class="titulo mt-6 mb-2 mx-3">Dados {{ this.$tc("pt_br.documento") }}</h3>

        <nome-documento v-model="documento" :renovacao="renovacao"/>

        <opcoes-avancadas ref="opcoesAvancadas" v-model="documento" :renovacao="renovacao"/>
    </v-form>
</template>

<script>
import NomeDocumento
    from '@/views/criar-documento/novo-documento/components/documento/campos-formulario/components/NomeDocumento'
import OpcoesAvancadas
    from '@/views/criar-documento/novo-documento/components/documento/campos-formulario/components/opcoes-avancadas/OpcoesAvancadas'

export default {
    name: 'FormularioDocumento',
    components: {NomeDocumento, OpcoesAvancadas},
    props: {
        documento: {
            type: Object,
            required: true
        },
        renovacao: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        abrirOpcoesAvancadas() {
            this.$refs.opcoesAvancadas.abrirOpcoesAvancadas()
        },
        async validarFormulario() {
            if (this.documento.nome.search("[#%\\[{}\\]|\\\\]") !== -1) {
                this.mostrarNotificacaoErro('Nome do documento possui caracteres inválidos #%[{}]|\\')
                return false
            }
            return await this.$refs.documentoForm.validate()
        }
    }
}
</script>

<style lang="stylus">
.co-documento-formulario-novo
    .titulo
        color var(--primary)
</style>