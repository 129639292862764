<template>
    <div class="co-meus-contratos">
        <barra-saldo ref="refBarraSaldo" />

        <v-row id="meus-contratos-botoes" no-gutters>
            <botoes-criar/>
        </v-row>

        <v-row id="meus-contratos-acoes" no-gutters class="acoes-meus-contratos">
            <barra-busca :filtros="filtros"/>
        </v-row>

        <v-container class="meus-contratos overflow-y-auto" fluid id="scroll-target" :style="getHeight()"
                     v-scroll:#scroll-target="onScroll">
            <header-dentro-pasta v-if="!ehPastaRaiz"/>
            <lista-vazia id='lista-vazia' v-if="ehListaVazia"/>

            <div class="mb-10">
                <pastas :modo-grade="ehModoGrade"/>

                <contratos :modo-grade="ehModoGrade" :contratos="meusContratos"/>
            </div>
        </v-container>

        <dialog-nova-organizacao v-if="ehPrimeiroAcesso" :dialog="ehPrimeiroAcesso"/>
    </div>
</template>

<script>
import BarraSaldo from './components/BarraSaldo'
import Contratos from './components/contratos/Contratos'
import {mapGetters, mapState} from 'vuex'
import _ from 'lodash'
import {actionTypes, mutationTypes} from '@/commons/constants'
import ListaVazia from './components/ListaVazia'
import BarraBusca from './components/BarraBusca'
import Pastas from './components/pastas/Pastas'
import HeaderDentroPasta from './components/pastas/HeaderDentroPasta'
import DialogNovaOrganizacao from './dialogs/DialogNovaOrganizacao'
import BotoesCriar from './components/botoes-criar/BotoesCriar'
import DialogDefaultConfirmarAcao from '@/commons/components/dialogs/DialogDefaultConfirmarAcao'

export default {
    name: 'MeusContratos',
    components: {
        DialogDefaultConfirmarAcao,
        BotoesCriar,
        DialogNovaOrganizacao,
        HeaderDentroPasta,
        Pastas,
        ListaVazia,
        BarraSaldo,
        Contratos,
        BarraBusca
    },
    data() {
        return {
            buscando: false,
            filtros: {},
            loadingAcao: false,
            dialogPromocao: false,
            isDomReady: false
        }
    },
    watch: {
        async 'acaoFiltrar'() {
            if (this.acaoFiltrar) {
                await this.recarregarConteudo()
                this.$store.commit(mutationTypes.ACOES.SET_ACOES_FILTRAR)
            }
        },
        async 'acaoOrdenar'() {
            if (this.acaoOrdenar) {
                await this.recarregarContratos()
                this.$store.commit(mutationTypes.ACOES.SET_ACOES_ORDENAR)
            }
        },
        async 'meusContratosBusca.filtros.formalizacao.value'() {
            await this.recarregarConteudo()
        },
        async 'pastaAtual'() {
            if (!this.isDomReady) {
                return
            }
            let pastaId = this.pastaAtual.pastaId
            if (this.pastasParaAtualizar.includes(pastaId)) {
                await this.$store.commit(mutationTypes.PASTAS.REMOVE_PASTAS_PARA_ATUALIZAR, pastaId)
                await this.recarregarConteudo()
            }
            else if (this.pastaAtual.buscaFiltrada) {
                await this.recarregarConteudo()
            }
            else {
                await this.carregarConteudo()
            }
        }
    },
    computed: {
        ...mapState([
            'meusContratos',
            'meusContratosBusca',
            'preferenciasUsuario',
            'pastaAtual',
            'pastasParaAtualizar',
            'acaoFiltrar',
            'acaoOrdenar',
            'cookies',
            'login',
            'loki'
        ]),
        ...mapGetters([
            'existeContratos',
            'existePastas',
            'carregando',
            'ehEfcazCLM',
            'ehPlanoFree',
            'ehPastaRaiz',
            'ehOrganizacaoSemAssinatura',
        ]),
        ehListaVazia() {
            return !this.existePastas && !this.existeContratos
        },
        ehModoGrade() {
            return this.layout() === 'CARD'
        },
        ehOrganizacaoFreeSemAssinatura() {
            return !this.ehEfcazCLM && this.ehPlanoFree && this.ehOrganizacaoSemAssinatura
        },
        ehPrimeiroAcesso() {
            return !this.login.organizacaoPlano.organizacaoExistente
        }
    },
    async created() {
        this.filtros = _.clone(this.meusContratosBusca.filtros)
        await this.validarPastaAtual()
        if (!this.ehPrimeiroAcesso) {
            await this.buscarConsumoOrganizacao()
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.getHeight);
    },
    async mounted() {
        this.loki.page.title = 'Documentos'
        this.$nextTick(() => {
            window.addEventListener('resize', this.getHeight);
        })
        await this.carregarConteudo()

        this.isDomReady = true
    },
    methods: {
        async buscar() {
            if (this.ehBuscarContratos() || this.ehBuscarPastas()) {
                if (this.ehBuscarPastas()) {
                    await this.buscarPastas()
                    if (this.pastaAtual.ultimaPaginaBusca) {
                        await this.buscarPaginaContratos();
                    }
                } else if (this.ehBuscarContratos()) {
                    await this.buscarPaginaContratos();
                }
                await this.verificaScrollPaginacao()
            }
        },
        async buscarConsumoOrganizacao() {
            await this.$store.dispatch(actionTypes.COMUN.BUSCAR_CONSUMO_ORGANIZACAO)
        },
        async carregarConteudo() {
            this.resetarContratos()
            await this.iniciarBusca()
        },
        ehBuscarContratos() {
            return (this.meusContratosBusca.totalContratosListagem === null || this.meusContratosBusca.totalContratosListagem > this.meusContratos.length) && !this.meusContratosBusca.ultimaPaginaBusca
        },
        ehBuscarPastas() {
            return (this.pastaAtual.totalPastas === null || this.pastaAtual.totalPastas > this.pastaAtual.pastas.length) && !this.pastaAtual.ultimaPaginaBusca
        },
        getHeight() {
            let height = window.innerHeight

            let toolbar = document.getElementsByClassName('toolbar')
            if (toolbar.length !== 0) {
                height -= toolbar[0].clientHeight
            }

            let saldoOptions = document.getElementById('meus-contratos-barra-saldo')
            if (saldoOptions) {
                height -= saldoOptions.clientHeight
            }

            let botoesOptions = document.getElementById('meus-contratos-botoes')
            if (botoesOptions) {
                height -= botoesOptions.clientHeight
            }

            let filterOptions = document.getElementById('meus-contratos-acoes')
            if (filterOptions) {
                height -= filterOptions.clientHeight
            }

            let footer = document.getElementsByClassName('az-about')
            if (footer.length !== 0) {
                height -= footer[0].clientHeight
            }

            return `max-height:${height}px`
        },
        async iniciarBusca() {
            if (!this.buscando) {
                this.buscando = true
                this.habilitarLoadingGlobal()
                await this.buscar()
                this.desabilitarLoadingGlobal();
                this.buscando = false
            }
        },
        layout() {
            return this.preferenciasUsuario.layoutLista
        },
        limparFiltros() {
            this.$store.commit(mutationTypes.FILTROS.LIMPAR_FILTROS_BUSCA)
        },
        async onScroll(e) {
            if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
                await this.iniciarBusca()
            }
        },
        async recarregarContratos() {
            await this.resetarContratos()
            await this.iniciarBusca()
        },
        async recarregarConteudo() {
            await this.resetarContratos()
            await this.resetarPasta(this.pastaAtual)
            await this.iniciarBusca()
        },
        async verificaScrollPaginacao() {
            const paginacao = document.getElementById('scroll-target')
            const listaVazia = document.getElementById('lista-vazia')

            if (listaVazia !== null || paginacao.clientHeight >= paginacao.scrollHeight) {
                await this.buscar()
            }
        },
    }
}
</script>

<style lang="stylus">
.co-meus-contratos
    height calc(100% - 36px)
    background-color var(--background-100)

    .acoes-meus-contratos
        justify-content space-between
        align-items center

    .meus-contratos
        background var(--background-100)

@media (max-width: 720px)
    .co-meus-contratos
        height 100%

        .acoes-meus-contratos
            padding 0
</style>