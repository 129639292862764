<template>
    <div>
        <div class="justify-center subtitulo mb-3">
            <span>
                <span class="mb-3 headline font-weight-bold"> {{ formatarReal(valorComparacaoMensal) }} </span>
                /mês
            </span>
        </div>
    
        <div class="justify-center pagamento-unico-texto">
            <span v-if="valorTotal > 0.00">
                {{ definirValoresTexto.valorEQtd }}
                <span class="mb-3"> {{ formatarReal(valorTotal) }} </span>
                {{ definirValoresTexto.recorrencia }}
            </span>

            <strong v-else>R$ A definir</strong>
        </div>
    </div>
</template>

<script>
import CurrencyFormatterUtils from '../../../../../commons/utils/CurrencyFormatterUtils';
import {mapGetters} from 'vuex'

export default {
    name: 'PlanoPrecos',
    props: {
        ehParcela: {
            type: Boolean,
            required: true
        },
        valorTotal: {
            type: Number,
            default: 0
        },
        valorComparacaoMensal: {
            type: Number,
            default: 0
        }
    },
    computed: {
        ...mapGetters(['quantidadeParcelasMaximas']),
        definirValoresTexto() {
            const valoresTexto = {
                valorEQtd: this.ehParcela ? `Pagamento em ${this.quantidadeParcelasMaximas}x de ` : 'Pagamento único de ',
                recorrencia: this.ehParcela ? ' /mês' : ' /ano'
            }

            return valoresTexto
        }
    },
    methods: {
        formatarReal(valor) {
            return CurrencyFormatterUtils.formatarBrl(Number(valor))
        },
    }
}
</script>

<style lang="stylus" scoped>
.subtitulo
    font-size 15px
    color var(--text-color-200)

.pagamento-unico-texto
    min-height 44px
    color var(--text-color-200)
    margin-bottom 12px

</style>
