<template>
    <v-dialog value="true" persistent fullscreen hide-overlay>
        <v-card color="#eeeeee" tile class="co-lote-contrato">
            <v-toolbar flat dark color="primary">
                <v-btn icon dark @click="voltarParaMeusContratos">
                    <v-icon> mdi-chevron-left </v-icon>
                </v-btn>
                <v-toolbar-title>Preencha os dados do Lote de {{ $tc('pt_br.documento') }}s</v-toolbar-title>
            </v-toolbar>

            <v-stepper class="elevation-0 co-lote-contrato" editable v-model="stepper">
                <v-stepper-header>
                    <v-spacer/>
                    <v-stepper-step :complete="stepper > 1" step="1">
                        <span>Geral</span>
                    </v-stepper-step>
                    <v-divider/>
                    <v-stepper-step :complete="stepper > 2" step="2">
                        <span>{{ $tc("pt_br.signatario", 2) }}</span>
                    </v-stepper-step>
                    <v-divider/>
                    <v-stepper-step :complete="stepper > 3" step="3">
                        <span>Dados Complementares</span>
                        <small>Opcional</small>
                    </v-stepper-step>
                    <v-spacer/>
                </v-stepper-header>

                <v-stepper-content step="1">
                    <v-card-text class="co-steper-botton">
                        <v-container>
                            <v-row no-gutters wrap justify="center">
                                <novo-contrato-form
                                    ref="novoFormularioContrato"
                                    v-model="contrato"
                                />
                            </v-row>

                            <v-row no-gutters justify="center">
                                <upload-envelope @pdfs="pdfs"/>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <v-card-text class="co-steper-botton container-lote signatarios-lote">
                        <v-spacer/>
                        <v-flex xs12 sm4>
                            <h2>1. {{ $tc("pt_br.signatario", 2) }} recorrentes nos {{ $tc('pt_br.documento').toLowerCase() }}s</h2>
                            <h4>Informe aqui os {{ $tc("pt_br.signatario", 2).toLowerCase() }} que assinarão em todos os {{ $tc('pt_br.documento').toLowerCase() }}s</h4>
                            <br/>
                            <formulario-selecao-multiplos-signatarios
                                v-if="passo2"
                                ref="novoFormularioSignatario"
                                :novo-signatario="signatarioRecorrente"
                                :documentos-signatario="documentosSignatario"
                                :signatarios-sugestoes="signatariosSugestoes"
                                :papeis-siganatario="papeisSignatario"
                            />
                        </v-flex>
                        <v-spacer/>
                        <v-flex>
                            <v-divider vertical/>
                        </v-flex>
                        <v-flex xs12 sm4>
                            <h2>2. {{ $tc("pt_br.signatario", 2) }} individuais</h2>
                            <h4>Informe aqui os {{ $tc("pt_br.signatario", 2).toLowerCase() }} que assinarão individualmente</h4>
                            <h5>(Será gerado 1 {{ $tc('pt_br.documento').toLowerCase() }} para cada {{ $tc("pt_br.signatario").toLowerCase() }} informado abaixo)</h5>
                            <br/>
                            <formulario-selecao-multiplos-signatarios
                                v-if="passo2"
                                ref="novoFormularioSignatarioIndividual"
                                :novo-signatario="signatarioIndividual"
                                :documentos-signatario="documentosSignatario"
                                :signatarios-sugestoes="signatariosSugestoes"
                                :papeis-siganatario="papeisSignatario"
                            />
                        </v-flex>
                        <v-spacer/>
                    </v-card-text>
                </v-stepper-content>

                <v-stepper-content step="3">
                    <v-card-text class="container-lote">
                        <formulario-opcional-varios-email
                            class="formulario-opcional"
                            :novo-signatario="dadosComplementares"
                        />
                    </v-card-text>
                </v-stepper-content>
            </v-stepper>
        </v-card>

        <div class="co-lote-contrato-footer" v-az-auth="'Contrato.Lote'">
            <v-btn class="acoes voltar" rounded depressed @click="voltarEtapa()" v-if="stepper > 1">
                VOLTAR
            </v-btn>
            <v-btn class="acoes success" rounded depressed @click="validarEtapa()" v-if="stepper < 3">
                CONTINUAR
            </v-btn>
            <v-btn class="acoes secondary" rounded depressed @click="gerarLoteOnce()" :disabled="disableSalvar" v-if="stepper === 3">
                GERAR
            </v-btn>
        </div>

        <az-dialog v-if="mostrarLimitePlanoAtingido" @ok="fecharDialogLimitePlanoAtingido">
            <div class="topo-modal-inativar">Limite de {{ $tc('pt_br.documento').toLowerCase() }}s ultrapassa a quantidade disponível</div>
            <div class="subtitulo-modal-inativar">
                Para adquirir {{ $tc('pt_br.documento').toLowerCase() }}s adicionais, entre em contato com o suporte
                <a href="https://tawk.to/chat/5c1b926682491369ba9eec03/default" target="_blank">clicando aqui</a>.
            </div>
        </az-dialog>
        <animacao-confirmacao
            :mostrar-animacao-sucesso="mostrarAnimacaoSucesso"
        />
    </v-dialog>
</template>

<script>
import moment from 'moment-timezone'
import {mapActions, mapGetters, mapState} from 'vuex'
import {actionTypes} from '@/commons/constants'
import NovoContratoForm from '../NovoContratoForm'
import UploadEnvelope from '@/views/contrato/novo/upload-envelope/UploadEnvelope'
import FormularioSelecaoMultiplosSignatarios
    from './components/formulario-selecao-multiplos-signatarios/FormularioSelecaoMultiplosSignatarios'
import FormularioOpcionalVariosEmail
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/formulario-opcional-varios-email/FormularioOpcionalVariosEmail'
import _ from 'lodash'
import AnimacaoConfirmacao from '@/commons/components/dialogs/AnimacaoConfirmacao'
import Utils from '@/commons/utils/Utils'
import store from '@/commons/store'
import DateTimeUtils from '@/commons/utils/DateTimeUtils'
import RouterUtils from '@/commons/utils/RouterUtils'

export default {
    name: 'LoteDocumentos',
    components: {
        AnimacaoConfirmacao,
        UploadEnvelope, NovoContratoForm, FormularioSelecaoMultiplosSignatarios, FormularioOpcionalVariosEmail
    },
    inject: ['$validator'],
    data() {
        return {
            arquivo: null,
            contrato: {
                nome: '',
                nomeDocOriginal: '',
                inicioVigencia: '',
                fimVigencia: '',
                inicioAssinatura: '',
                fimAssinatura: '',
                tags: [],
                pastaId: null
            },
            dadosComplementares: {
                chaveSeguranca: null,
                assinaturaManuscrita: true,
                mensagem: null,
                disponibilidadeAssinatura: "REMOTA"
            },
            disableSalvar: false,
            documentosSignatario: [],
            mostrarAnimacaoSucesso: false,
            mostrarLimitePlanoAtingido: false,
            papeisSignatario: [],
            passo2: false,
            signatarioRecorrente: {
                email: [],
                papelId: '',
                documentoId: 1
            },
            signatarioIndividual: {
                email: [],
                papelId: '',
                documentoId: 1
            },
            signatariosSugestoes: [],
            stepper: 1,
        }
    },
    async mounted() {
        await this.buscarSignatariosSugestoes()
        await this.carregarDocumentosSignatario()
        await this.carregarPapeisSignatarios()
        await this.buscarConfiguracoesSignatarios()
        this.inicializarModelContrato()
    },
    computed: {
        ...mapState(['pastaAtual']),
        ...mapGetters(['ehEfcazCLM', 'ehPlanoFree', 'podeCriarContratoRaiz', 'ehAdministradorOrganizacao']),
    },
    methods: {
        ...mapActions([actionTypes.CRIAR_CONTRATO]),
        atualizarDatasISO() {
            this.contrato.inicioVigencia = DateTimeUtils.setZeroHorasISO(this.contrato.inicioVigencia)
            this.contrato.fimVigencia = DateTimeUtils.set23HorasISO(this.contrato.fimVigencia)
            this.contrato.inicioAssinatura = DateTimeUtils.setZeroHorasISO(this.contrato.inicioAssinatura)
            this.contrato.fimAssinatura = DateTimeUtils.set23HorasISO(this.contrato.fimAssinatura)
        },
        async buscarConfiguracoesSignatarios() {
            if (this.ehEfcazCLM || !this.ehPlanoFree) {
                this.dadosComplementares.chaveSeguranca = store.state.login.organizacaoConfiguracao.geral.signatario.chaveSeguranca
                this.dadosComplementares.mensagem = store.state.login.organizacaoConfiguracao.geral.signatario.mensagem
            }
        },
        async buscarSignatariosSugestoes() {
            this.signatariosSugestoes = await this.$store.dispatch(actionTypes.SIGNATARIO.BUSCAR_SIGNATARIOS_SUGESTOES)
        },
        async carregarDocumentosSignatario() {
            const documentos = await this.$store.dispatch(actionTypes.BUSCAR_DOCUMENTOS_SIGNATARIO)
            for (const documento of documentos) {
                this.documentosSignatario.push({
                    text: documento.nome,
                    value: documento.documentoId,
                    descricao: documento.descricao
                })
            }
        },
        async carregarPapeisSignatarios() {
            const listaPapeisSignatarios = await this.$store.dispatch(actionTypes.BUSCAR_PAPEIS_SIGNATARIOS)
            for (const papel of listaPapeisSignatarios) {
                this.papeisSignatario.push({text: papel.nome, value: papel.id})
            }
        },
        construirContrato() {
            const contrato = this.contrato
            contrato.nomeDocOriginal = this.arquivo[0].name
            const arquivo = new FormData()
            this.arquivo.forEach(a => {
                arquivo.append('arquivo', a)
            })
            arquivo.append('contrato', JSON.stringify(contrato))
            return arquivo
        },
        fecharDialogLimitePlanoAtingido() {
            this.mostrarLimitePlanoAtingido = false
        },
        async gerarLoteOnce() {
            this.disableSalvar = true
            await this.gerarLote()
            setTimeout(() => this.disableSalvar = false, 4000);
        },
        async gerarLote() {
            const validadoContrato = await this.validarDadosContrato()
            const validadoSignatario = await this.validarDadosSignatarios()
            if (validadoContrato && validadoSignatario) {
                try {
                    this.setMensagemLoading('Salvando contrato...')
                    this.atualizarDatasISO()
                    const data = this.construirContrato();
                    data.append('signatariosRecorrentes', JSON.stringify(this.signatarioRecorrente))
                    data.append('signatariosIndividuais', JSON.stringify(this.signatarioIndividual))
                    data.append('dadosComplementares', JSON.stringify(this.dadosComplementares))
                    await this.$store.dispatch(actionTypes.CRIAR_CONTRATO_LOTE, data)
                    this.habilitarAnimacaoSucesso()
                    setTimeout(() => {
                        this.$router.push({name: 'meus'})
                    }, 3000)

                } catch (e) {
                    const exceptionType = e.response.data.exception
                    if (exceptionType && exceptionType.search('LimiteDocumentosAtingidoException') !== -1) {
                        this.mostrarLimitePlanoAtingido = true
                    }
                }
            }
        },
        habilitarAnimacaoSucesso() {
            this.mostrarAnimacaoSucesso = true
        },
        inicializarModelContrato() {
            this.contrato.inicioVigencia = moment().format('YYYY-MM-DD')
            this.contrato.fimVigencia = moment().add(5, 'days').format('YYYY-MM-DD')
            this.contrato.inicioAssinatura = moment().format('YYYY-MM-DD')
            this.contrato.fimAssinatura = moment().add(5, 'days').format('YYYY-MM-DD')
            if (!!this.pastaAtual && this.pastaAtual.pastaId) {
                this.contrato.pastaId = this.pastaAtual.pastaId
            }
        },
        pdfs(arquivos) {
            this.arquivo = _.clone(arquivos);
        },
        validarArquivoSelecionado() {
            if (!this.arquivo || this.arquivo.length <= 0) {
                this.mostrarNotificacaoErro('Arquivo não selecionado.')
                return false
            }
            return true
        },
        async validarDadosContrato() {
            if (this.contrato.nome.search("[#%\\[{}\\]|\\\\]") !== -1) {
                this.mostrarNotificacaoErro('Nome do documento possui caracteres inválidos #%[{}]|\\')
                return
            }
            const validado = await this.$refs.novoFormularioContrato.validarFormulario()
            const datasValidas = this.validarDatasInicioFimVigencia()
            const arquivoSelecionado = this.validarArquivoSelecionado()
            const validarContratoRaiz = await this.validarCriarContratoRaiz()
            return validado && datasValidas && arquivoSelecionado && validarContratoRaiz
        },
        async validarDadosSignatarios() {
            const validado = await this.$refs.novoFormularioSignatario.validarFormulario()
            const validadoIndividual = await this.$refs.novoFormularioSignatarioIndividual.validarFormulario()
            const validadoEmail = this.validarEmails()
            return validado && validadoIndividual && validadoEmail
        },
        validarDatasInicioFimVigencia() {
            if (!this.contrato.inicioVigencia) {
                return false
            }
            if (this.contrato.fimVigencia) {
                const fimVigencia = moment(this.contrato.fimVigencia)
                const inicioVigencia = moment(this.contrato.inicioVigencia)
                if (inicioVigencia.isAfter(fimVigencia)) {
                    this.mostrarNotificacaoErro('A data de término da vigência não pode ser anterior ao início da vigência.')
                    return false
                }
            }
            return true
        },
        validarEmails() {
            var validacao = this.signatarioRecorrente.email.filter(email => this.signatarioIndividual.email.includes(email))
            if (validacao.length > 0) {
                this.mostrarNotificacaoErro('Não é possivel inserir o mesmo e-mail nas duas listas')
                return false
            }
            return true
        },
        async validarEtapa() {
            if (this.stepper === 1) {
                const validado = await this.validarDadosContrato()
                if (!validado) {
                    this.stepper = 1
                    return
                }
                this.passo2 = true
                this.stepper = 2
            } else if (this.stepper === 2) {
                const validado = await this.validarDadosSignatarios()
                if (!validado) {
                    this.stepper = 2
                    return
                }
                this.stepper = 3
            }
        },
        async validarCriarContratoRaiz() {
            if (!this.podeCriarContratoRaiz && (!Utils.possuiValor(this.contrato.pastaId))) {
                if (this.ehAdministradorOrganizacao) {
                    this.mostrarNotificacaoErro(`Não é permitido a criação de ${this.$tc('pt_br.documento').toLowerCase()}s na RAIZ, selecione uma pasta ou mude a configuração.`)
                } else {
                    this.mostrarNotificacaoErro(`Não é permitido a criação de ${this.$tc('pt_br.documento').toLowerCase()}s na RAIZ, selecione uma pasta ou contate o Administrador da sua Organização.`)
                }
                return false
            }
            return true
        },
        async voltarEtapa() {
            this.stepper = this.stepper - 1
        },
        voltarParaMeusContratos() {
            if (this.stepper === 1) {
                RouterUtils.voltarParaMeusDocumentos(this.meusContratosBusca.filtros.formalizacao.value)
            } else {
                this.voltarEtapa();
            }
        }
    }
}
</script>

<style lang="stylus">
.co-lote-contrato
    background-color #eeeeee

    .co-steper-botton
        padding-bottom 50px !important

    .v-stepper__content
        padding 0 !important
        padding-bottom 5% !important
        background-color #eee

    .container-lote
        margin 0 auto !important

        .formulario-opcional
            padding 2% 20% !important

        .v-text-field
            font-size 14px
            border-bottom 0 solid #ddd

        .v-label
            font-size 19px

        .hint-checkbox
            font-size 12px
            color #aaa
            margin-bottom 10px

        .v-text-field__details
            display none

    .signatarios-lote
        padding-top 3% !important
        display inline-flex
        max-width none !important

.co-lote-contrato-footer
    z-index 5
    width 100%
    position fixed !important
    background-color rgba(255, 255, 255, 0.7) !important
    padding 20px
    bottom 0
    left 0
    border-top 1px solid #ddd
    text-align center

    .acoes
        border-radius 20px
        font-size 16px


    .voltar
        color #aaaaaa !important
        border 1px solid #aaaaaa
        position absolute !important
        left 20px

</style>