<template>
    <div>
        <v-menu content-class="menu-card-contrato az-menu-lista" left offset-y>
            <template v-slot:activator="{ on }">
                <v-icon v-on="on"> mdi-dots-vertical </v-icon>
            </template>
            <v-list>
                <v-list-item @click="downloadContrato" v-if="contratoFormalizado">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-list-item-title v-on="on">
                                <v-icon class="mr-2" size="18px"> mdi-folder-zip </v-icon>
                                Baixar arquivos do {{ $tc('pt_br.documento') }} (ZIP)
                            </v-list-item-title>
                        </template>
                        <span>Baixará um arquivo zip contendo os arquivos do documento.</span>
                    </v-tooltip>
                </v-list-item>

                <v-divider/>

                <v-list-item
                    @click="downloadContratoAssinado"
                    v-az-auth="'Contrato.Assinado'"
                    v-show="possuiSignatarioAssinado"
                >
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-list-item-title v-on="on">
                                <v-icon class="mr-2" size="14px" style="margin-left: 5px"> mdi-download </v-icon>
                                Baixar {{ $tc('pt_br.documento') }} com Assinatura Criptografada
                            </v-list-item-title>
                        </template>
                        <span>Baixará o {{ $tc('pt_br.documento') }} contendo todas as assinaturas criptografadas internamente,<br/>
                            necessitando de programas como o Adobe Acrobat Reader para visualiza-las. <br/>
                            ATENÇÃO: Este Documento possui validade legal!</span>
                    </v-tooltip>
                </v-list-item>

                <v-list-item @click="downloadContratoImpressao" v-az-auth="'Contrato.Impressao'">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-list-item-title v-on="on">
                                <v-icon class="mr-2" size="14px" style="margin-left: 5px"> mdi-download </v-icon>
                                Baixar {{ $tc('pt_br.documento') }} para Impressão
                            </v-list-item-title>
                        </template>
                        <span>Baixará o {{ $tc('pt_br.documento') }} contendo as assinaturas no corpo, como carregado no visualizador.<br/>
                            ATENÇÃO: Documento apenas para impressão, não possui validade legal!</span>
                    </v-tooltip>
                </v-list-item>

                <v-list-item @click="downloadContratoOriginal" v-az-auth="'Contrato.Original'">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-list-item-title v-on="on">
                                <v-icon class="mr-2" size="14px" style="margin-left: 5px"> mdi-download </v-icon>
                                Baixar {{ $tc('pt_br.documento') }} Original
                            </v-list-item-title>
                        </template>
                        <span>Baixará o {{ $tc('pt_br.documento') }} original enviado a plataforma</span>
                    </v-tooltip>
                </v-list-item>

                <v-divider/>

                <v-list-item v-show="podeRenovar" v-az-auth="'Contrato.Renovar'" @click="renovarContrato">
                    <v-list-item-title>
                        <v-icon class="mr-2" size="18px"> mdi-autorenew </v-icon>
                        {{ getLabelBotaoGerarNovaVersaoOuRenovar() }}
                    </v-list-item-title>
                </v-list-item>

                <v-list-item v-show="podeArquivar" v-az-auth="'Contrato.Arquivar'" @click="confirmarArquivamento">
                    <v-list-item-title>
                        <v-icon class="mr-2" size="18px"> mdi-package-down </v-icon>
                        Arquivar {{ $tc('pt_br.documento') }}
                    </v-list-item-title>
                </v-list-item>

                <v-list-item v-show="podeRestaurar" v-az-auth="'Contrato.Restaurar'" @click="confirmarRestauracao">
                    <v-list-item-title>
                        <v-icon class="mr-2" size="18px"> mdi-package-up </v-icon>
                        Restaurar {{ $tc('pt_br.documento') }}
                    </v-list-item-title>
                </v-list-item>

                <v-list-item v-show="podeExtinguir" v-az-auth="'Contrato.Extinguir'" @click="abrirModalExtincao">
                    <v-list-item-title>
                        <v-icon class="mr-2" size="18px"> mdi-timer-sand-empty </v-icon>
                        Extinguir {{ $tc('pt_br.documento') }}
                    </v-list-item-title>
                </v-list-item>

                <v-list-item v-az-auth="'Contrato.Mover'" @click="abrirModalSelecionarPasta">
                    <v-list-item-title>
                        <v-icon class="mr-2" size="18px"> mdi-folder </v-icon>
                        Mover para Pasta
                    </v-list-item-title>
                </v-list-item>

                <v-divider/>

                <v-list-item
                    v-show="podeCancelar"
                    v-az-auth="'Contrato.Cancelar'"
                    @click="abrirModalMotivoCancelamento"
                >
                    <v-list-item-title>
                        <v-icon class="mr-2" size="18px"> mdi-close </v-icon>
                        Cancelar
                        <span v-if="ehEfcazCLM">Versão</span>
                        <span v-else>{{ $tc('pt_br.documento') }}</span>
                    </v-list-item-title>
                </v-list-item>

                <v-list-item v-show="podeRemover" v-az-auth="'Contrato.Excluir'" @click="confirmarRemocao">
                    <v-list-item-title>
                        <v-icon class="mr-2" size="18px"> mdi-delete </v-icon>
                        Excluir
                        <span v-if="ehEfcazCLM">Versão</span>
                        <span v-else>{{ $tc('pt_br.documento') }}</span>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <clm-dialog v-model="dialogArquivarContrato">
            <clm-ops sentimento="assustado">
                <div slot="titulo">Eita! Deixa eu confirmar uma coisa...</div>
                <div slot="subtitulo">Você quer mesmo arquivar o {{ $tc('pt_br.documento').toLowerCase() }}?</div>
                <div slot="acoes">
                    <div class="principal">
                        <a @click="arquivar">Sim, eu quero arquivar!</a>
                    </div>
                    <div class="secundaria">
                        <a @click="fecharDialogArquivar">Não, obrigado</a>
                    </div>
                </div>
            </clm-ops>
        </clm-dialog>

        <clm-dialog v-model="mostrarConfirmacao">
            <clm-ops sentimento="assustado">
                <div slot="titulo">Eita! Deixa eu confirmar uma coisa...</div>
                <div slot="subtitulo">{{ confirmacao.subtitulo }}</div>
                <div slot="acoes">
                    <div class="principal">
                        <a @click="confirmacao.acaoPrincipal">Sim, claro!</a>
                    </div>
                    <div class="secundaria">
                        <a @click="fecharConfirmacao">Não, obrigado</a>
                    </div>
                </div>
            </clm-ops>
        </clm-dialog>

        <dialog-default-confirmar-acao
            v-if="mostrarLimitePlanoAtingido"
            :dialog="mostrarLimitePlanoAtingido"
            sentimento="assustado"
            titulo="Eita! Você atingiu seu limite de uso"
            :subtitulo=mensagemLimiteDocumentos
            label-botao-principal="Sim, eu quero!"
            @acao-principal="acaoDialogLimitePlanoAtingido"
            @fechar="fecharDialogLimitePlanoAtingido"
        />

        <dialog-mover-elemento
            v-if="dialogMoverContrato"
            :dialog="dialogMoverContrato"
            :elemento="contrato"
            @fechar="fecharDialogMover"
        />

        <modal-motivo-cancelamento
            v-if="modalMotivoCancelamento"
            @confirmarCancelamento="confirmarCancelamento"
            @fecharModalMotivoCancelamento="fecharModalMotivoCancelamento"
        />

        <nova-extincao
            v-if="modalExtincao"
            v-model="extincao"
            @cancelar="cancelarExtincao"
            @clean="limparArquivoExtincao"
            @extinguir="extinguirContrato"
            @upload="preencherReferenciaArquivo"
        />

    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {actionTypes} from '@/commons/constants'
import ClmDialog from '@/commons/components/ClmDialog'
import ClmOps from '@/commons/components/ClmOps'
import controleAcoes from '../controle-acoes'
import ModalMotivoCancelamento from '@/views/contrato/commons/ModalMotivoCancelamento'
import NovaExtincao from './extincao/NovaExtincao'
import PlanoAssinaturaUtils from '@/commons/utils/PlanoAssinaturaUtils'
import _ from 'lodash'
import DialogMoverElemento from '@/views/home/meus-contratos/components/common/DialogMoverElemento'
import DialogDefaultConfirmarAcao from '@/commons/components/dialogs/DialogDefaultConfirmarAcao.vue'

export default {
    name: 'DetalheContratoAcoes',
    components: {
        DialogDefaultConfirmarAcao,
        DialogMoverElemento, ClmDialog, ClmOps, ModalMotivoCancelamento, NovaExtincao
    },
    props: {
        corIcone: {
            default: 'white'
        }
    },
    data() {
        return {
            arquivoExtincao: {},
            confirmacao: {
                subtitulo: '',
                acaoPrincipal: () => {
                    // acao default
                }
            },
            dialogArquivarContrato: false,
            dialogMoverContrato: false,
            extincao: {},
            mensagemModalMoverNotificacao: '',
            motivoCancelamento: '',
            modalExtincao: false,
            modalMotivoCancelamento: false,
            mostrarConfirmacao: false,
            mostrarLimitePlanoAtingido: false,
            mostrarModalMoverNotificacao: false,
            pastaSelecionada: {}
        }
    },
    computed: {
        ...mapState(['contrato', 'minutaAtual', 'signatarios', 'historicoContrato', 'login']),
        ...mapGetters(['ehEfcazCLM', 'ehPlanoFree']),
        mensagemLimiteDocumentos() {
            const textClm = 'Adquira o Plano PRO e continue alavancando seu negócio.'
            let textoBesign = ''

            if (this.ehPlanoFree) {
                textoBesign = 'A quantidade de documentos disponíveis acabou! Adquira um plano recorrente ou um pacote individual para continuar a enviar contratos para assinatura. Clique em “Sim, eu quero” para conhecer nossas opções.'
            } else {
                textoBesign = 'A quantidade de documentos disponíveis em seu plano acabou! Faça um upgrade para um plano com mais documentos ou adquira um pacote individual para continuar a enviar contratos para assinatura. Clique em “Sim, eu quero” para conhecer nossas opções.'
            }

            return `${this.ehEfcazCLM ? textClm : textoBesign} <span style="font-size:20px">&#128640;</span>`
        },
        contratoArquivado() {
            return this.contrato.arquivado
        },
        possuiSignatarioAssinado() {
            const desabilitado = _.find(this.signatarios, (signatario) => signatario.statusSignatario === 'ASSINADO')
            return desabilitado !== undefined
        },
        contratoCancelado() {
            return this.minutaAtual.situacaoFormalizacao === 'CANCELADO'
        },
        contratoFormalizado() {
            return this.minutaAtual.situacaoFormalizacao === 'FORMALIZADO'
        },
        contratoExtinto() {
            return this.contrato.extinto
        },
        ehUltimaVersao() {
            return this.minutaAtual.versao === this.historicoContrato.length
        },
        podeArquivar() {
            return controleAcoes.arquivarContrato(this.contrato)
        },
        podeCancelar() {
            const minutaCancelamento = {
                versao: this.minutaAtual.versao,
                situacaoMinuta: this.minutaAtual.situacaoFormalizacao,
                contratoArquivado: this.contrato.arquivado,
                contratoExtinto: this.contrato.extinto
            }
            return controleAcoes.cancelarMinuta(minutaCancelamento)
        },
        podeExtinguir() {
            return controleAcoes.extinguirContrato(this.minutaAtual, this.contrato)
        },
        podeRemover() {
            const assinaturas = this.signatarios.filter(signatario => signatario.dataAssinatura)
            const contratoExclusao = {
                situacaoMinuta: this.minutaAtual.situacaoFormalizacao,
                totalAssinaturas: assinaturas.length
            }
            return controleAcoes.excluirContrato(contratoExclusao)
        },
        podeRenovar() {
            return this.contratoCancelado && this.ehUltimaVersao && !this.contratoExtinto && !this.contratoArquivado || this.contratoFormalizado && this.ehUltimaVersao && !this.contratoExtinto && !this.contratoArquivado
        },
        podeRestaurar() {
            return controleAcoes.restaurarContrato(this.contrato)
        }
    },
    methods: {
        ...mapActions([
            actionTypes.ARQUIVAR_CONTRATO,
            actionTypes.BUSCAR_CONTRATO,
            actionTypes.BUSCAR_MINUTA_ATUAL,
            actionTypes.BUSCAR_SIGNATARIOS,
            actionTypes.BUSCAR_HISTORICO_CONTRATO,
            actionTypes.CANCELAR_MINUTA,
            actionTypes.REMOVER_CONTRATO,
            actionTypes.RESTAURAR_CONTRATO_ARQUIVADO
        ]),
        acaoDialogLimitePlanoAtingido() {
            this.mostrarLimitePlanoAtingido = false
            this.$router.push({name: 'planos'})
        },
        abrirModalExtincao() {
            this.modalExtincao = true
            this.arquivoExtincao = new File([''], '')
            this.extincao = {}
        },
        abrirModalMotivoCancelamento() {
            this.modalMotivoCancelamento = true
        },
        abrirModalMoverNotificacao() {
            this.mostrarModalMoverNotificacao = true
        },
        abrirModalSelecionarPasta() {
            this.contrato.contratoId = this.contrato.id
            this.dialogMoverContrato = true
        },
        async arquivar() {
            this.setMensagemLoading(`Arquivando ${this.$tc('pt_br.documento').toLowerCase()}...`)
            this.fecharConfirmacao()
            await this.arquivarContrato(this.contrato.id)
            await this.sincronizarDadosContrato()
            this.mostrarNotificacaoSucessoDefault()
        },
        async cancelar() {
            try {
                this.setMensagemLoading(`Cancelando versão do ${this.$tc('pt_br.documento').toLowerCase()}...`)
                this.fecharConfirmacao()
                await this.cancelarMinuta({
                    contratoId: this.contrato.id,
                    minutaId: this.minutaAtual.id,
                    motivoCancelamento: this.motivoCancelamento
                })
                await this.sincronizarDadosContrato()
                this.mostrarNotificacaoSucessoDefault()
            } catch (e) {
                this.fecharConfirmacao()
            }
        },
        cancelarExtincao() {
            this.modalExtincao = false
        },
        construirRequisicaoExtincao() {
            this.extincao.contratoId = this.contrato.id
            const extincao = this.extincao
            const formData = new FormData()
            formData.append('arquivo', this.arquivoExtincao)
            formData.append('extincao', JSON.stringify(extincao))
            return {contratoId: this.contrato.id, extincao: formData}
        },
        confirmarArquivamento() {
            this.confirmacao = {
                subtitulo: `Você quer mesmo arquivar o ${this.$tc('pt_br.documento').toLowerCase()}?`,
                acaoPrincipal: this.arquivar
            }
            this.mostrarConfirmacao = true
        },
        confirmarCancelamento(value) {
            this.motivoCancelamento = value.motivoCancelamento
            this.fecharModalMotivoCancelamento()
            this.confirmacao = {
                subtitulo: `Você quer mesmo cancelar a versão do ${this.$tc('pt_br.documento').toLowerCase()}?`,
                acaoPrincipal: this.cancelar
            }
            this.mostrarConfirmacao = true
        },
        confirmarRemocao() {
            this.confirmacao = {
                subtitulo: `Você quer mesmo excluir a versão do ${this.$tc('pt_br.documento').toLowerCase()}?`,
                acaoPrincipal: this.remover
            }
            this.mostrarConfirmacao = true
        },
        confirmarRestauracao() {
            this.confirmacao = {
                subtitulo: `Você quer mesmo restaurar o ${this.$tc('pt_br.documento').toLowerCase()}?`,
                acaoPrincipal: this.restaurar
            }
            this.mostrarConfirmacao = true
        },
        async downloadContratoAssinado() {
            const arquivoBase64 = await this.$store.dispatch(actionTypes.DOWNLOAD_MINUTA_ASSINADA, {
                contratoId: this.contrato.id,
                minutaId: this.minutaAtual.id
            })
            this.salvarArquivo(this.contrato.nome, arquivoBase64, 'application/pdf')
        },
        async downloadContratoImpressao() {
            const arquivoBase64 = await this.$store.dispatch(actionTypes.DOWNLOAD_MINUTA_IMPRESSAO, {
                contratoId: this.contrato.id,
                minutaId: this.minutaAtual.id
            })
            this.salvarArquivo(this.contrato.nome, arquivoBase64, 'application/pdf')
        },
        async downloadContratoOriginal() {
            const arquivoBase64 = await this.$store.dispatch(actionTypes.DOWNLOAD_MINUTA_ORIGINAL, {
                contratoId: this.contrato.id,
                minutaId: this.minutaAtual.id
            })
            this.salvarArquivo(this.contrato.nome, arquivoBase64, 'application/pdf')
        },
        async downloadContrato() {
            const arquivoBase64 = await this.$store.dispatch(actionTypes.DOWNLOAD_MINUTA_ZIP, {
                contratoId: this.contrato.id,
                minutaId: this.minutaAtual.id
            })
            this.salvarArquivo(this.contrato.nome, arquivoBase64, 'application/zip')
        },
        ehMoverContratoRaizParaPasta(pasta, pastaSelecionada) {
            return pastaSelecionada === null && pasta.pastaId !== undefined
        },
        ehMoverContratoPastaParaPasta(pasta, pastaSelecionada) {
            return pastaSelecionada !== null && pasta.pastaId === undefined
        },
        async extinguirContrato() {
            try {
                this.setMensagemLoading(`Extinguindo ${this.$tc('pt_br.documento').toLowerCase()}...`)
                await this.$store.dispatch(actionTypes.EXTINGUIR_CONTRATO, this.construirRequisicaoExtincao())
                await this.$store.dispatch(actionTypes.BUSCAR_CONTRATO, this.contrato.id)
                this.cancelarExtincao()
                this.mostrarNotificacaoSucessoDefault()
            } catch (e) {
                this.cancelarExtincao()
            }
        },
        fecharConfirmacao() {
            this.mostrarConfirmacao = false
            this.confirmacao = {
                subtitulo: '',
                acaoPrincipal: () => {
                    // acao default
                }
            }
        },
        fecharDialogArquivar() {
            this.dialogArquivarContrato = false
        },
        fecharDialogLimitePlanoAtingido() {
            this.mostrarLimitePlanoAtingido = false
        },
        fecharDialogMover() {
            this.dialogMoverContrato = false
        },
        fecharModalMotivoCancelamento() {
            this.modalMotivoCancelamento = false
        },
        getLabelBotaoGerarNovaVersaoOuRenovar() {
            return controleAcoes._minutaCancelada(this.minutaAtual.situacaoFormalizacao) ? 'Gerar Nova Versão' : 'Renovar/Aditivar'
        },
        limparArquivoExtincao() {
            this.arquivoExtincao = new File([''], '')
        },
        async remover() {
            try {
                this.setMensagemLoading(`Removendo ${this.$tc('pt_br.documento').toLowerCase()}...`)
                this.fecharConfirmacao()
                if (controleAcoes._versaoInicial(this.minutaAtual.versao)) {
                    await this.removerContrato(this.contrato.id)
                    this.mostrarNotificacaoSucessoDefault()
                    this.$router.push({name: 'meus'})
                } else {
                    await this.removerContrato(this.contrato.id)
                    await this.sincronizarDadosContrato()
                    this.mostrarNotificacaoSucessoDefault()
                    this.$emit('buscarDocumento')
                }
            } catch (e) {
                this.fecharConfirmacao()
            }
        },
        async renovarContrato() {
            const podeRenovar = this.podeRenovar
            const organizacaoId = this.$store.state.login.organizacaoPlano.organizacaoId
            if (await PlanoAssinaturaUtils.limiteContratosAtingido(organizacaoId)) {
                this.mostrarLimitePlanoAtingido = true
                return
            }
            if (!podeRenovar) {
                this.mostrarNotificacaoErro(`${this.$tc('pt_br.documento')} deve estar formalizado ou cancelado para ser renovado/aditivado.`)
                return
            }
            this.$router.push({name: 'renovarDocumento', params: {contratoId: this.contrato.id}})
        },
        async restaurar() {
            try {
                this.setMensagemLoading(`Restaurando ${this.$tc('pt_br.documento').toLowerCase()}...`)
                this.fecharConfirmacao()
                await this.restaurarContratoArquivado(this.contrato.id)
                await this.sincronizarDadosContrato()
                this.mostrarNotificacaoSucessoDefault()
            } catch (e) {
                this.fecharConfirmacao()
            }
        },
        async sincronizarDadosContrato() {
            this.setMensagemLoading(`Carregando dados do ${this.$tc('pt_br.documento').toLowerCase()}...`)
            await this.buscarContrato(this.contrato.id)
            this.setMensagemLoading(`Carregando dados do ${this.$tc('pt_br.documento').toLowerCase()}...`)
            await this.buscarMinutaAtual(this.contrato.id)
            this.buscarHistoricoContrato(this.contrato.id)
            await this.buscarSignatarios({contratoId: this.contrato.id, minutaId: this.minutaAtual.id})
        },
        preencherReferenciaArquivo(arquivo) {
            this.arquivoExtincao = arquivo
        },
        selecionarPasta() {
            this.value.pastaId = this.pastaSelecionada.pastaId
            this.fecharDialoglMover()
        }
    }
}
</script>