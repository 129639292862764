<template>
    <drag :transfer-data="elemento" class="co-home-elemento-drag d-flex align-center">
        <slot name="item"/>

        <div slot="image" class="drag-image d-flex align-center">
            <v-icon> {{ icon }} </v-icon>
            <span>{{ nome | azClipText(17) }}</span>
        </div>
    </drag>
</template>

<script>

export default {
    name: "ElementoDrag",
    props: {
        elemento: {
            type: Object,
            required: true
        },
        nome: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
    }
}
</script>

<style lang="stylus">
.co-home-elemento-drag
    width 100%
    height 100%
    flex-direction column
    position relative

    &:hover
        border 1px solid var(--background-300)
        border-radius 5px

    .drag-image
        padding 10px 20px
        background-color var(--background-100)
        border 1px solid #ccc

        span
            color #aaa
            font-size 14px !important
            padding-left 10px
            position relative
            top -2px

</style>