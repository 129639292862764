<template>
    <div class="co-configuracao-lateral">
        <router-view/>
    </div>
</template>

<script>

export default {
    name: 'ConfiguracaoRouter',
    async mounted() {
        this.$store.state.loki.page.title = 'Configurações'
    }
}
</script>

<style lang="stylus">
.co-configuracao-lateral
    height calc(100vh - 96px)
    overflow-y auto

@media (max-width: 720px)
    .co-configuracao-lateral
        height calc(100vh - 60px)
</style>
