<template>
    <div class="co-filtros-components-tags">
        <span>Tags</span>

        <v-autocomplete
            multiple
            chips
            small-chips
            deletable-chips
            :items="tagsDisponiveis"
            :search-input.sync="textoConsulta"
            v-model="selecionado"
            ref="filtroTags"
            item-text="nome"
            item-value="id"
            return-object
            color="#ccc"
            class="filtro-tags"
            placeholder="Buscar"
            @input="onSelecionarTag">
        </v-autocomplete>
    </div>
</template>

<script>
import {actionTypes} from '@/commons/constants'
import {mapState} from 'vuex'

export default {
    name: 'FiltroTags',
    props: {
        value: {
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState(['organizacao', 'meusContratosBusca']),
    },
    watch: {
        'meusContratosBusca.filtros.tags.value'() {
            if (this.meusContratosBusca.filtros.tags.value === null) {
                this.selecionado = []
            }
        }
    },
    async created() {
        await this.buscarTagsOrganizacao()
        await this.recuperarTags()
    },
    data() {
        return {
            selecionado: [],
            textoConsulta: '',
            tagsDisponiveis: []
        }
    },
    methods: {
        async buscarTagsOrganizacao() {
            this.tagsDisponiveis = await this.$store.dispatch(actionTypes.BUSCAR_TAGS_POR_ORGANIZACAO, this.organizacao.organizacaoId)
        },
        criarStringFiltroTags(tags) {
            let filtroTag = ''
            for (const tag of tags) {
                filtroTag += `${tag.tagId},`
            }
            return filtroTag.substr(0, filtroTag.length - 1)
        },
        onSelecionarTag() {
            this.textoConsulta = ''
            this.value.tags.value = this.criarStringFiltroTags(this.selecionado)
        },
        recuperarTags() {
            let tags = this.value.tags.value.split(',')
            this.selecionado = []
            for (const tag of tags) {
                if (tag !== '') {
                    this.selecionado.push(this.tagsDisponiveis.find(t => t.tagId === parseInt(tag)))
                }
            }

        }
    }
}
</script>

<style lang="stylus">
.co-filtros-components-tags
    width 100%
    position relative

    .v-autocomplete:not(.v-input--is-focused).v-select--chips input
        max-height: inherit;


</style>