<template>
    <div class="co-chave-seguranca">
        <span v-if="informativo" class="label">
            Informe a chave de segurança
            <v-tooltip top max-width="500px">
                <template v-slot:activator="{ on }">
                    <v-icon v-if="informativo" v-on="on" size="20" color="#999"> mdi-information </v-icon>
                </template>
                <span>{{ descricaoCampo }}</span>
            </v-tooltip>
        </span>
        <v-text-field
            v-model="value.chaveSeguranca"
            :error-messages="errors.collect('Chave de seguranca')"
            name="Chave de seguranca"
            :label=" informativo ? '' : 'Chave de segurança'"
            placeholder="Informe a chave de segurança"
            persistent-placeholder
            :class="informativo ? 'chave-seguranca-field' : ''"
            :disabled="disabled"
        >
        </v-text-field>

        <div v-if="!informativo" class="hint-checkbox">
            {{ descricaoCampo }}
        </div>
    </div>
</template>

<script>

export default {
    name: 'ChaveSeguranca',
    props: {
        value: {
            required: true
        },
        informativo: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            descricaoCampo: 'Aqui você pode definir uma chave de segurança que só você e o ' +
                this.$tc("pt_br.signatario").toLowerCase() + ' saberão. Isso é uma medida para dar mais segurança ao processo de' +
                ' assinatura, garantindo que outra pessoa não conseguirá assinar no lugar deste ' + this.$tc("pt_br.signatario").toLowerCase() + '.'
        }
    }
}
</script>

<style lang="stylus">
.co-chave-seguranca
    .chave-seguranca-field
        padding-top 0
        margin-top 0

    .label
        color #777777

</style>