<template>
    <v-container grid-list px-10 pt-10 pb-4 class="co-grupos-contatos">

        <configuracao-titulo :titulo="'Grupos de ' + this.$tc('pt_br.signatario', 2)" divider-off>
            <div slot="actions" class="d-flex align-center">
                <v-slide-x-reverse-transition v-if="!ehMobile">
                    <v-text-field
                        transition="slide-x-reverse-transition"
                        v-if="mostrarSearch"
                        class="search-bar"
                        v-model="search"
                        label="Buscar pelo nome"
                        hide-details
                        @blur="limparFiltros"
                        @keyup.enter="atualizarFiltro">
                    </v-text-field>
                </v-slide-x-reverse-transition>

                <v-fade-transition>
                    <v-btn
                        v-if="!mostrarSearch || !ehMobile"
                        class="mr-4"
                        height="32px"
                        width="32px"
                        icon outlined
                        @click="atualizarFiltro"
                        color="primary">
                        <v-icon> mdi-magnify </v-icon>
                    </v-btn>
                </v-fade-transition>

                <div v-az-auth="'GrupoSignatarios.Novo'">
                    <v-btn v-if="!ehMobile" color="primary" dark depressed @click="novoGrupoSignatarios" >
                        <v-icon size="20px" class="mr-2"> mdi-plus-circle </v-icon>
                        Adicionar
                    </v-btn>

                    <v-btn
                        v-else icon
                        color="primary"
                        height="32px"
                        width="32px"
                        @click="novoGrupoSignatarios"
                    >
                        <v-icon size="41px"> mdi-plus-circle </v-icon>
                    </v-btn>
                </div>
            </div>
        </configuracao-titulo>

        <v-row no-gutters v-if="ehMobile" class="justify-space-between align-center my-4">
            <v-slide-x-reverse-transition>
                <v-text-field
                    transition="slide-x-reverse-transition"
                    v-if="mostrarSearch"
                    class="search-bar"
                    v-model="search"
                    label="Buscar pelo nome ou e-mail"
                    hide-details
                    @blur="limparFiltros"
                    @keyup.enter="atualizarFiltro">
                </v-text-field>
            </v-slide-x-reverse-transition>

            <v-fade-transition>
                <v-btn
                    v-if="mostrarSearch"
                    class="ml-2"
                    height="32px"
                    width="32px"
                    icon outlined
                    @click="atualizarFiltro"
                    color="primary">
                    <v-icon> mdi-magnify </v-icon>
                </v-btn>
            </v-fade-transition>
        </v-row>

        <v-row no-gutters class="justify-space-between mx-3 mt-5">
            <grupos-contatos-tabela
                :grupos-signatarios="gruposSignatarios"
                :qtd-linhas-tabela="qtdLinhasSelecionada"
                @edit="editarGrupoSignatarios"
                @excluir="confirmarExcluirGrupoSignatarios"/>
        </v-row>

        <v-row no-gutters class="justify-space-between mt-3 mx-3">
            <v-flex class="footer">
                <v-pagination
                    dense
                    class="paginas justify-center elevation-0"
                    v-model="getPaginacaoAtual().page"
                    :length="getPaginacaoAtual().numPages"
                    :total-visible="7"
                    @input="irParaPagina"/>

                <v-spacer v-if="!ehMobile"/>

                <v-select
                    v-if="!ehMobile"
                    class="linhas-por-pagina"
                    v-model="qtdLinhasSelecionada"
                    :items="quatidades"
                    label="Itens por pagina"
                    hide-details
                    @change="atualizarQtdLinhasPorPagina"/>
            </v-flex>
        </v-row>

        <dialog-novo-grupo-contatos
            v-if="abrirModalNovoGrupo"
            :dialog="abrirModalNovoGrupo"
            :grupo-signatarios="grupoSignatarios"
            :cadastros-signatarios="cadastrosSignatarios"
            :loadingContatos="loadingContatos"
            @fechar="fecharDialogNovoGrupo"
            @salvar="salvarFecharDialogNovoGrupo"
        />

        <dialog-default-confirmar-acao
            v-if="mostrarConfirmacao"
            :dialog="mostrarConfirmacao"
            sentimento="assustado"
            titulo="Eita! Deixa eu confirmar uma coisa..."
            :subtitulo="confirmacao.subtitulo"
            label-botao-principal="Sim, Claro!"
            @acao-principal="confirmacao.acaoPrincipal"
            @fechar="fecharConfirmacao"
        />
    </v-container>
</template>

<script>

import DialogNovoGrupoContatos from './DialogNovoGrupoContatos'
import GruposContatosTabela from './GruposContatosTabela'
import DialogDefaultConfirmarAcao from '@/commons/components/dialogs/DialogDefaultConfirmarAcao'
import {actionTypes, mutationTypes} from '@/commons/constants'
import {mapActions, mapMutations, mapState} from 'vuex'
import _ from 'lodash'
import ConfiguracaoTitulo from '../common/ConfiguracaoTitulo'

export default {
    name: 'GruposContatos',
    components: {
        ConfiguracaoTitulo,
        GruposContatosTabela,
        DialogNovoGrupoContatos,
        DialogDefaultConfirmarAcao
    },
    data() {
        return {
            abrirModalNovoGrupo: false,
            cadastrosSignatarios: [],
            confirmacao: {
                subtitulo: '',
                acaoPrincipal: () => {
                    // funcao default
                }
            },
            documentosSignatario: [],
            filtrado: false,
            filtros: {},
            filtroSelecionado: 'TODOS',
            globalLoadingAtivado: false,
            grupoSignatarios: {},
            gruposSignatarios: [],
            loadingContatos: false,
            mostrarConfirmacao: false,
            mostrarSearch: false,
            papeisSignatario: [],
            quatidades: [5, 10, 15, 25, 50],
            qtdLinhasSelecionada: 10,
            search: ''
        }
    },
    computed: {
        ...mapState(['loki', 'gruposSignatariosBusca']),
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        }
    },
    async mounted() {
        await this.calcularNumeroLinhas()
        await this.resetarPaginacao()
        await this.resetarFiltros()
        await this.buscarPagina()
        await this.carregarCadastrosSignatarios()
    },
    methods: {
        ...mapActions([
            actionTypes.CADASTRO_SIGNATARIOS.BUSCAR_CADASTROS_SIGNATARIOS_FILTRADOS,
            actionTypes.GRUPO_SIGNATARIOS.BUSCAR_GRUPO_SIGNATARIOS,
            actionTypes.GRUPO_SIGNATARIOS.BUSCAR_GRUPOS_SIGNATARIOS_FILTRADOS,
            actionTypes.GRUPO_SIGNATARIOS.INSERIR_GRUPO_SIGNATARIOS,
            actionTypes.GRUPO_SIGNATARIOS.ATUALIZAR_GRUPO_SIGNATARIOS,
            actionTypes.GRUPO_SIGNATARIOS.REMOVER_GRUPO_SIGNATARIOS
        ]),
        ...mapMutations([
            mutationTypes.SET_PAGINACAO_GRUPOS_SIGNATARIOS,
            mutationTypes.SET_FILTROS_GRUPOS_SIGNATARIOS
        ]),
        abrirModalConfirmacao() {
            this.mostrarConfirmacao = true
        },
        async atualizarFiltro() {
            if (!this.mostrarSearch) {
                this.mostrarSearch = true
            } else {
                if (this.search === '') {
                    this.mostrarSearch = false
                    if (!this.filtrado) {
                        return
                    } else {
                        this.filtrado = false
                    }
                } else {
                    this.filtrado = true
                }
                await this.setFiltros({
                    nome: {value: this.search, default: null},
                })
                await this.buscarPagina()
            }
        },
        atualizarListaGruposSignatarios(gruposSignatarios) {
            this.gruposSignatarios = gruposSignatarios
        },
        async atualizarQtdLinhasPorPagina() {
            await this.setPaginacao({
                page: 1,
                rowsPerPage: this.qtdLinhasSelecionada
            })
            await this.buscarPagina()
        },
        async buscarPagina() {
            const paginaGruposSignatarios = await this.$store.dispatch(actionTypes.GRUPO_SIGNATARIOS.BUSCAR_GRUPOS_SIGNATARIOS_FILTRADOS)
            this.setPaginacao({
                numPages: paginaGruposSignatarios.totalPages
            })
            this.atualizarListaGruposSignatarios(paginaGruposSignatarios.content)
        },
        async calcularNumeroLinhas() {
            if (!this.ehMobile) {
            let qtdLinhasIdeal = Math.floor((window.innerHeight - 316) / 40)
            this.qtdLinhasSelecionada = qtdLinhasIdeal
            this.quatidades.push(qtdLinhasIdeal)
                this.quatidades.sort(function (a, b) {return a - b})
            }
        },
        async carregarCadastrosSignatarios() {
            const cadastros = await this.$store.dispatch(actionTypes.CADASTRO_SIGNATARIOS.BUSCAR_MEUS_CADASTROS_SIGNATARIOS)
            for (const cadastro of cadastros) {
                this.cadastrosSignatarios.push({
                    text: cadastro.nome + ' - ' + cadastro.contato,
                    value: cadastro.id,
                })
            }
        },
        confirmarExcluirGrupoSignatarios(grupoSignatarios) {
            this.grupoSignatarios = grupoSignatarios
            this.confirmacao = {
                subtitulo: `Você quer mesmo excluir o grupo de ${this.$tc("pt_br.signatario", 2).toLowerCase()} ${grupoSignatarios.nome}?`,
                acaoPrincipal: this.excluirGrupoSignatarios
            }
            this.abrirModalConfirmacao()
        },
        async editarGrupoSignatarios(grupoSignatarios) {
            this.setMensagemLoading(`Buscando dados do grupo de ${this.$tc("pt_br.signatario", 2).toLowerCase()}...`)
            this.grupoSignatarios = await this.$store.dispatch(actionTypes.GRUPO_SIGNATARIOS.BUSCAR_GRUPO_SIGNATARIOS, {
                grupoSignatariosId: grupoSignatarios.id
            })
            this.abrirModalNovoGrupo = true
        },
        async excluirGrupoSignatarios() {
            this.setMensagemLoading(`Excluindo grupo de ${this.$tc("pt_br.signatario", 2).toLowerCase()}...`)
            await this.$store.dispatch(actionTypes.GRUPO_SIGNATARIOS.REMOVER_GRUPO_SIGNATARIOS, {
                grupoSignatariosId: this.grupoSignatarios.id
            })
            this.grupoSignatarios = null
            await this.buscarPagina()
            this.fecharConfirmacao()
            this.fecharModalGrupoSignatarios()
            this.mostrarNotificacaoSucessoDefault()
        },
        fecharDialogNovoGrupo() {
            this.abrirModalNovoGrupo = false
        },
        fecharConfirmacao() {
            this.confirmacao = {
                subtitulo: '',
                acaoPrincipal: () => {
                    // funcao default
                }
            }
            this.mostrarConfirmacao = false
        },
        fecharModalGrupoSignatarios() {
            this.abrirModalNovoGrupo = false
        },
        getFiltrosAtuais() {
            return _.clone(this.gruposSignatariosBusca.filtros)
        },
        getPaginacaoAtual() {
            return _.clone(this.gruposSignatariosBusca.paginacao)
        },
        irParaPagina(pagina) {
            this.setPaginacao({
                page: pagina
            })
            this.buscarPagina()
        },
        limparFiltros(){
            if(this.filtrado && this.search === ""){
                this.filtrado = false

                this.resetarFiltros()
                this.buscarPagina()
            }
        },
        async novoGrupoSignatarios() {
            this.grupoSignatarios = {
                id: null,
                nome: '',
                signatarios: []
            }
            this.abrirModalNovoGrupo = true
        },
        resetarFiltros() {
            this.setFiltros({
                nome: {value: null, default: null},
            })
        },
        resetarPaginacao() {
            this.setPaginacao({
                page: 1,
                rowsPerPage: this.qtdLinhasSelecionada
            })
        },
        salvarFecharDialogNovoGrupo() {
            this.abrirModalNovoGrupo = false
            this.buscarPagina()
        },
        setFiltros(filtros) {
            const filtrosAtuais = this.getFiltrosAtuais()
            this.setFiltrosGruposSignatarios(Object.assign({}, filtrosAtuais, filtros))
        },
        setPaginacao(paginacao) {
            const paginacaoAtual = this.getPaginacaoAtual()
            this.setPaginacaoGruposSignatarios(Object.assign({}, paginacaoAtual, paginacao))
        }
    }
}
</script>

<style lang="stylus">
.co-grupos-contatos
    background-color var(--background-100) !important
    width 100%

    .titulo
        height 44px

    .title
        color var(--primary)

    .search-bar
        padding-top 0
        margin-top 0
        max-width 350px
        min-width 220px

    .footer
        display flex
        position relative
        align-content space-between

        .paginas
            width 100%
            align-content center

        .linhas-por-pagina
            position absolute
            right 0
            width 100px

@media (max-width: 720px)
    .co-contatos
        .footer
            .linhas-por-pagina
                position unset
</style>

