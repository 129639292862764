var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pt-1",attrs:{"cols":"6","sm":"8"}},[_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g({staticStyle:{"font-size":"16px","color":"#777777"}},on),[_vm._v(_vm._s(_vm.nomePdf))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.pdf.name))])])],1)]),_c('v-col',{staticClass:"grey--text pt-1",attrs:{"cols":"3","sm":"2"}},[_c('span',[_vm._v(_vm._s(_vm.sizePdf))])]),_c('v-col',{staticClass:"grey--text pt-1",attrs:{"cols":"3","sm":"2"}},[_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g({staticStyle:{"cursor":"pointer","margin":"0px 5px"},on:{"click":_vm.visualizar}},on),[_vm._v(" mdi-eye-outline ")])]}}])},[_c('span',[_vm._v("Visualizar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g({staticStyle:{"cursor":"pointer","margin":"0px 5px"},on:{"click":_vm.excluir}},on),[_vm._v(" mdi-delete ")])]}}])},[_c('span',[_vm._v("Deletar")])])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }