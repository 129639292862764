<template>
    <v-text-field
        label="Descrição"
        class="required-field"
        v-model="value.descricao"
        v-validate="'required'"
        :error-messages="errors.collect('descricao')"
        name="descricao"
        placeholder="Informe a descrição"
    />
</template>

<script>
export default {
    name: 'Descricao',
    props: ['value']
}
</script>