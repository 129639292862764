<template>
    <div>
        <v-dialog :value="dialog" width="500" persistent>
            <v-card class="co-dialog-nova-extincao">
                <v-card-title class="headline text-color-300--text">
                    <div> Extinguir {{ $tc("pt_br.documento").toLowerCase() }}</div>
                    <v-spacer/>
                    <v-icon size="18" @click="$emit('cancelar')"> mdi-window-close </v-icon>
                </v-card-title>

                <v-card-text>
                    <v-layout wrap>
                        <v-flex xs12 sm12 md12>
                            <v-textarea
                                label="Justificativa"
                                placeholder="Texto"
                                name="Texto"
                                class="required-field"
                                v-model="value.descricao"
                                v-validate="'required'"
                                :error-messages="errors.collect('Texto')"
                            />
                        </v-flex>
                    </v-layout>
                    <div class="label-arquivo">Arquivo</div>
                    <div class="upload-file-extincao">
                        <div v-if="possuiExtincao">
                            <div class="arquivo">
                                <v-icon size="60" class="icone"> mdi-clipboard-text </v-icon>
                                <span class="texto">{{ nomeArquivoSelecionado }}</span>
                            </div>
                            <div class="opcoes" @click="limparExtincao">
                                <v-icon size="70px" class="icone"> mdi-window-close </v-icon>
                                <span class="texto">Excluir</span>
                            </div>
                        </div>
                        <div v-else>
                            <clm-file-upload
                                ref="clmFileUploader"
                                height="100px"
                                v-show="!arquivoSelecionado"
                                @arquivoSelecionado="setArquivoSelecionado"
                                @error="tratarErroEnvioArquivo">
                                <v-icon size="50px" class="icon" @click.prevent="$refs.clmFileUploader.openFileSelector()">
                                    mdi-cloud-upload
                                </v-icon>
                                <p class="descricao">Arraste seu arquivo aqui ou clique para fazer upload.</p>
                            </clm-file-upload>
                        </div>
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-btn color="background-200" depressed @click="$emit('cancelar')">
                        Cancelar
                    </v-btn>
                    <v-spacer/>
                    <v-btn color="primary" @click="salvar">
                        Confirmar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <dialog-default-confirmar-acao
            v-if="mostrarConfirmacao"
            :dialog="mostrarConfirmacao"
            sentimento="atencao"
            titulo="Eita! Deixa eu confirmar uma coisa..."
            :subtitulo="mensagemContratito"
            label-botao-principal="EXTINGUIR"
            @acao-principal="confirmar"
            @fechar="fecharConfirmacao"
        />

    </div>
</template>

<script>
import {mapState} from 'vuex'
import ClmDialog from '@/commons/components/ClmDialog'
import ClmOps from '@/commons/components/ClmOps'
import ClmFileUpload from '@/views/contrato/commons/ClmFileUpload'
import DialogDefaultConfirmarAcao from '@/commons/components/dialogs/DialogDefaultConfirmarAcao'

export default {
    name: 'DialogNovaExtincao',
    components: {DialogDefaultConfirmarAcao, ClmDialog, ClmOps, ClmFileUpload},
    props: {
        value: {
            type: Object
        },
        dialog: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            arquivoSelecionado: false,
            nomeArquivoSelecionado: '',
            mostrarConfirmacao: false
        }
    },
    computed: {
        ...mapState(['loki', 'minutaAtual']),
        mensagemContratito() {
            return `Você quer mesmo extinguir o ${this.$tc("pt_br.documento").toLowerCase()} ?`
        },
        possuiExtincao() {
            return this.arquivoSelecionado
        }
    },
    methods: {
        abrirModalConfirmacao() {
            this.mostrarConfirmacao = true
        },
        fecharConfirmacao() {
            this.mostrarConfirmacao = false
        },
        confirmar() {
            this.$emit('extinguir')
            this.fecharConfirmacao()
        },
        limparExtincao() {
            this.arquivoSelecionado = false
            this.$emit('clean')
        },
        async salvar() {
            const valido = await this.$validator._base.validateAll()
            if (!valido) {
                return
            }
            this.abrirModalConfirmacao()
        },
        setArquivoSelecionado(arquivo) {
            this.arquivoSelecionado = true
            this.nomeArquivoSelecionado = arquivo.name
            this.$emit('upload', arquivo)
        }
    }
}
</script>

<style lang="stylus">
.co-dialog-nova-extincao

    .data
        margin-left 5px
        color #777

    .label-arquivo
        font-size 12px
        color rgba(0, 0, 0, .54)
        padding-bottom 5px

    .upload-file-extincao
        .arquivo
            display block
            text-align center
            margin: 10px 0
            height 100px
            border 2px dashed #ccc

            .icone
                color #777
                cursor pointer

            .texto
                text-align center
                display block
                color #777

        .opcoes
            display none
            text-align center
            margin: 10px 0
            height 100px
            border 2px dashed #ccc

            .icone
                color #777
                cursor pointer

            .texto
                text-align center
                display block
                color #777

        &:hover
            background-color rgba(0, 0, 0, .4)

            .arquivo
                display none

            .opcoes
                display block

            .icone
                color white

            .texto
                color white
</style>