<template>
    <div class="co-documento-signatario">
        <v-select
            v-model="value.documentoId"
            v-validate="validarCampo()"
            :class="campoRequerido ? 'required-field' : ''"
            :error-messages="errors.collect('Documento')"
            :items="documentos"
            label="Tipo de documento"
            name="Documento"
            placeholder="Selecione"
            persistent-placeholder
            :rules="[(v) => !!v || 'Informe o campo obrigatório']"/>

        <div v-if="descricao" class="hint-checkbox">
            {{ getHintDocumento }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'DocumentoSignatario',
    props: {
        value: {
            required: true
        },
        campoRequerido: {
            type: Boolean,
            default: false
        },
        descricao: {
            type: Boolean
        },
        documentos: {
            required: true,
            type: Array,
            default: () => []
        }
    },
    data: () => ({
        documentosSelecao: []
    }),
    computed: {
        getHintDocumento() {
            if (this.value.documentoId) {
                return this.documentos.find(documento => documento.value === this.value.documentoId).descricao
            } else {
                return `Tipo de documento que o ${this.$tc("pt_br.signatario").toLowerCase()} informará no momento da assinatura`
            }
        },
    },
    methods: {
        montarDocumentosParaSelecao() {
            if (this.documentos) {
                this.documentos.forEach(documento => {
                    this.documentosSelecao.add({
                        text: documento.nome,
                        value: documento.documentoId,
                        descricao: documento.descricao
                    })
                })
            }
        },
        validarCampo() {
            return this.campoRequerido ? 'required' : ''
        }
    }
}
</script>
