<template>
    <div class="co-painel-configuracoes-notificacao-signatario">

        <configuracao-titulo :titulo="this.$tc('pt_br.signatario', 2)"/>

        <v-row no-gutters class="mx-3">
            <v-switch
                color="primary"
                v-model="value.emailAssinarDoc"
                :label="`Permitir envio de E-mails ao assinar documentos.`"
                :disabled="ehPlanoSemEdicaoConfiguracaoNotificacao"
            />
        </v-row>

        <v-row no-gutters class="mx-3">
            <v-switch
                color="primary"
                v-model="value.emailFormalizarDoc"
                :label="`Permitir envio de E-mails ao formalizar documentos.`"
                :disabled="ehPlanoSemEdicaoConfiguracaoNotificacao"
            />
        </v-row>

        <v-row no-gutters class="mx-3">
            <v-switch
                color="primary"
                v-model="value.emailRejeitarDoc"
                :label="`Permitir envio de E-mails ao rejeitar a assinatura dos documentos.`"
                :disabled="ehPlanoSemEdicaoConfiguracaoNotificacao"
            />
        </v-row>

        <v-row no-gutters class="mx-3">
            <v-switch
                color="primary"
                v-model="value.emailCancelarDoc"
                :label="`Permitir envio de E-mails ao cancelar documentos.`"
                :disabled="ehPlanoSemEdicaoConfiguracaoNotificacao"
            />
        </v-row>

        <v-row no-gutters class="mx-3">
            <v-switch
                color="primary"
                v-model="value.emailProximoLimiteAssinaturaDoc"
                :label="`Permitir envio de E-mails de limite de prazo para assinatura próximo a encerrar.`"
                :disabled="ehPlanoSemEdicaoConfiguracaoNotificacao"
            />
        </v-row>
        <v-row no-gutters class="mx-3">
            <v-switch
                color="primary"
                v-model="value.emailLimiteAssinaturaDoc"
                :label="`Permitir envio de E-mails de limite de prazo para assinatura expirado.`"
                :disabled="ehPlanoSemEdicaoConfiguracaoNotificacao"
            />
        </v-row>
    </div>
</template>

<script>

import ConfiguracaoTitulo from '../../common/ConfiguracaoTitulo'

export default {
    name: 'NotificacoesSignatario',
    components: {ConfiguracaoTitulo},
    props: {
        value: {
            required: true
        },
        ehPlanoSemEdicaoConfiguracaoNotificacao: {
            type: Boolean,
            required: true
        }
    }
}
</script>
