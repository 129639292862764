<template>
    <v-col class="co-card-pacote" cols="10" sm="5" md="4" lg="3" xl="2">
        <v-card class="card-principal text-center mx-3">
            <v-card-text class="card-principal-conteudo text-color-default">
                <div class="justify-center titulo title mb-5">{{ produto.titulo }}</div>

                <div v-if="produto.descricao" class="justify-center subtitulo mb-5">
                    <div v-for="descricao in getDescricao" :key="descricao">
                        {{ descricao }}
                    </div>
                </div>

                <div class="justify-center subtitulo mb-5">
                    <strong class="mb-3 headline font-weight-bold">
                        <span v-if="produto.valorPacote > 0.00">{{ formatarReal(produto.valorPacote) }} /mês</span>
                    </strong>
                </div>

                <div v-for="beneficios in produto.beneficios" :key="beneficios.beneficio" class="beneficios text-left">
                    <span>
                        <v-icon size="15px" color="green" v-if="beneficios.destaque === undefined || beneficios.destaque"> mdi-check-circle-outline </v-icon>
                        {{ beneficios.beneficio }}
                    </span>
                </div>
            </v-card-text>

            <v-card-actions>
                <div class="adquirir">
                    <div class="d-flex descricao-adquirir justify-space-around align-center mb-3">
                        <v-btn class="btn-add-remove" color="primary" small :disabled="this.produto.quantidade <= 0 || !ehAdministradorOrganizacao" @click="removeQuantidade">-</v-btn>
                        <input class="quantidade text-cecnter mx-3" v-model="produto.quantidade" :style="getStyleConteudo" min="0" placeholder="0" v-mask="'###'"/>
                        <v-btn class="btn-add-remove" color="primary" small :disabled="this.produto.quantidade >= 999 || !ehAdministradorOrganizacao" @click="addQuantidade">+</v-btn>
                    </div>
                </div>
            </v-card-actions>
        </v-card>
    </v-col>
</template>

<script>
import CurrencyFormatterUtils from '@/commons/utils/CurrencyFormatterUtils'
import {mapGetters} from 'vuex'

export default {
    name: 'CardPacote',
    props: {
        icon: {
            type: String
        },
        produto: {
            type: Object
        },
        quantidadeSaldo: {
            type: Number
        },
        quantidadeTotal: {
            type: Number,
            default: 0
        }
    },
    computed: {
        ...mapGetters(['ehAdministradorOrganizacao']),
        getDescricao() {
            return this.produto.descricao.split(';')
        },
        getStyleConteudo() {
            return this.produto.quantidade > 0 ? 'color: var(--v-primary-lighten1)' : 'color: #9b9b9b'
        },
    },
    watch: {
        'produto.quantidade'() {
            this.produto.quantidade = parseInt(this.produto.quantidade)
        }
    },
    methods: {
        addQuantidade() {
            if (this.produto.quantidade < 999) {
                this.produto.quantidade += 1
            }
        },
        formatarReal(valor) {
            return CurrencyFormatterUtils.formatarBrl(Number(valor))
        },
        removeQuantidade() {
            if (this.produto.quantidade >= 1) {
                this.produto.quantidade -= 1
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
.co-card-pacote
    min-width 320px

    .text-color-default
        color var(--text-color-200)

    .card-principal
        border-radius 5px
        height 600px

        .card-principal-conteudo
            height 90%

            .titulo
                color var(--primary)
                min-height 64px

            .subtitulo
                font-size 17px
                min-height 44px

    .adquirir
        width 100%

        .descricao-adquirir
            font-size 18px
            height 40px
            width 100%
            border-radius 5px
            box-shadow rgba(0,0,0,0.3) 0px 1px 1px 0px

            .btn-add-remove
                font-size 18px

            .quantidade
                width 80px
                text-align center

@media (max-height: 880px)
    .co-card-pacote
        .card-principal
            height auto

            .card-principal-conteudo
                .beneficios
                    display none
</style>
