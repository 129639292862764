<template>
    <div class="co-filtro-ordenacao">
        <v-select
            v-model="selecionado"
            :items="itens"
            dense
            hide-details
            item-text="descricao"
            item-value="valor"
            label="Ordenar por"
            outlined
            @change="filtrar"
        />
    </div>
</template>

<script>

import {actionTypes, mutationTypes} from '@/commons/constants'
import _ from 'lodash'
import {mapState} from 'vuex'

export default {
    name: "FiltroOrdenacao",
    data() {
        return {
            selecionado: {},
            itens: [
                {
                    descricao: 'A-Z',
                    valor: {
                        sortBy: 'nomeMinusculo',
                        descending: false
                    }
                },
                {
                    descricao: 'Z-A',
                    valor: {
                        sortBy: 'nomeMinusculo',
                        descending: true
                    }
                },
                {
                    descricao: 'Mais Recentes',
                    valor: {
                        sortBy: 'dataCriacao',
                        descending: true
                    }
                },
                {
                    descricao: 'Mais Antigos',
                    valor: {
                        sortBy: 'dataCriacao',
                        descending: false
                    }
                },
                {
                    descricao: 'Menor Prazo Renovação',
                    valor: {
                        sortBy: 'diasRestantesVigencia',
                        descending: true
                    }
                },
                {
                    descricao: 'Maior Prazo Renovação',
                    valor: {
                        sortBy: 'diasRestantesVigencia',
                        descending: false
                    }
                },
            ]
        }
    },
    created() {
        const recuperado = this.itens.filter(item => item.valor.sortBy === this.preferenciasUsuario.ordenacao.sortBy && item.valor.descending === this.preferenciasUsuario.ordenacao.descending)
        this.selecionado = recuperado.length > 0 ? recuperado[0] : this.itens[0]
    },
    computed: {
        ...mapState(['paginacaoContratos', 'preferenciasUsuario'])
    },
    methods: {
        atualizarPaginacaoOrdenacao() {
            let paginacaoAtual = _.clone(this.paginacaoContratos)
            this.$store.commit(mutationTypes.SET_PAGINACAO_BUSCA_CONTRATOS, Object.assign({}, paginacaoAtual, this.selecionado))
        },
        filtrar() {
            this.atualizarPaginacaoOrdenacao()
            this.$store.commit(mutationTypes.PREFERENCIAS_USUARIO.SET_ORDENACAO_BUSCA, this.selecionado)
            this.$store.dispatch(actionTypes.USUARIO.ATUALIZAR_PREFERENCIAS_USUARIO, this.preferenciasUsuario)
            this.$store.commit(mutationTypes.ACOES.SET_ACOES_ORDENAR)
        }
    }
}
</script>

<style lang="stylus">
.co-filtro-ordenacao
    max-width 170px
    margin 0
    padding 0

</style>