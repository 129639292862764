<template>
    <v-container grid-list px-10 pt-10 pb-4 class="co-contatos">

        <configuracao-titulo :titulo="this.$tc('pt_br.signatario', 2)" divider-off>
            <div slot="actions" class="d-flex align-center">
                <v-slide-x-reverse-transition v-if="!ehMobile">
                    <v-text-field
                        transition="slide-x-reverse-transition"
                        v-if="mostrarSearch"
                        class="search-bar"
                        v-model="search"
                        label="Buscar pelo nome ou e-mail"
                        hide-details
                        @blur="limparFiltros"
                        @keyup.enter="atualizarFiltro"/>
                </v-slide-x-reverse-transition>

                <v-fade-transition>
                    <v-btn
                        v-if="!mostrarSearch || !ehMobile"
                        class="mr-4"
                        height="32px"
                        width="32px"
                        icon outlined
                        @click="atualizarFiltro"
                        color="primary"
                    >
                        <v-icon> mdi-magnify</v-icon>
                    </v-btn>
                </v-fade-transition>

                <div v-az-auth="'CadastroSignatario.Novo'">
                    <v-btn v-if="!ehMobile" color="primary" dark depressed @click="novoCadastroSignatario">
                        <v-icon size="20px" class="mr-2"> mdi-plus-circle</v-icon>
                        Adicionar
                    </v-btn>

                    <v-btn
                        v-else icon
                        color="primary"
                        height="32px"
                        width="32px"
                        @click="novoCadastroSignatario"
                    >
                        <v-icon size="41px"> mdi-plus-circle</v-icon>
                    </v-btn>
                </div>
            </div>
        </configuracao-titulo>

        <v-row no-gutters v-if="ehMobile" class="justify-space-between align-center my-4">
            <v-slide-x-reverse-transition>
                <v-text-field
                    transition="slide-x-reverse-transition"
                    v-if="mostrarSearch"
                    class="search-bar"
                    v-model="search"
                    label="Buscar pelo nome ou e-mail"
                    hide-details
                    @blur="limparFiltros"
                    @keyup.enter="atualizarFiltro">
                </v-text-field>
            </v-slide-x-reverse-transition>

            <v-fade-transition>
                <v-btn
                    v-if="mostrarSearch"
                    class="ml-2"
                    height="32px"
                    width="32px"
                    icon outlined
                    @click="atualizarFiltro"
                    color="primary">
                    <v-icon dark> mdi-magnify</v-icon>
                </v-btn>
            </v-fade-transition>
        </v-row>

        <v-row no-gutters class="justify-space-between mx-3 mt-5">
            <contatos-tabela
                :cadastros-signatarios="cadastrosSignatarios"
                :papeis-signatario="papeisSignatario"
                :qtd-linhas-tabela="qtdLinhasSelecionada"
                @edit="editarCadastroSignatario"
                @excluir="confirmarExcluirCadastro"
            />
        </v-row>

        <v-row no-gutters class="justify-space-between mt-3 mx-3">
            <v-flex class="footer">
                <v-pagination
                    dense
                    class="paginas justify-center elevation-0"
                    v-model="getPaginacaoAtual().page"
                    :length="getPaginacaoAtual().numPages"
                    :total-visible="7"
                    @input="irParaPagina"/>

                <v-spacer v-if="!ehMobile"/>

                <v-select
                    v-if="!ehMobile"
                    class="linhas-por-pagina"
                    v-model="qtdLinhasSelecionada"
                    :items="quatidades"
                    label="Itens por pagina"
                    hide-details
                    @change="atualizarQtdLinhasPorPagina"/>
            </v-flex>
        </v-row>

        <dialog-novo-contato
            v-if="abrirModalNovoContato"
            :dialog="abrirModalNovoContato"
            :cadastro-signatario="cadastroSignatario"
            :documentos-signatario="documentosSignatario"
            :papeis-signatario="papeisSignatario"
            :grupos-signatarios="gruposSignatarios"
            @fechar="fecharDialogNovoContato"
            @salvar="salvarFecharDialogNovoContato"
        />

        <dialog-default-confirmar-acao
            v-if="mostrarConfirmacao"
            :dialog="mostrarConfirmacao"
            sentimento="assustado"
            titulo="Eita! Deixa eu confirmar uma coisa..."
            :subtitulo="confirmacao.subtitulo"
            label-botao-principal="Sim, Claro!"
            @acao-principal="confirmacao.acaoPrincipal"
            @fechar="fecharConfirmacao"
        />
    </v-container>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import {actionTypes, mutationTypes} from '@/commons/constants'
import ContatosTabela from './ContatosTabela'
import DialogNovoContato from './DialogNovoContato'
import _ from 'lodash'
import DialogDefaultConfirmarAcao from '@/commons/components/dialogs/DialogDefaultConfirmarAcao'
import ConfiguracaoTitulo from '../common/ConfiguracaoTitulo'

export default {
    name: 'Contatos',
    components: {
        ConfiguracaoTitulo,
        DialogDefaultConfirmarAcao,
        DialogNovoContato,
        ContatosTabela
    },
    data() {
        return {
            abrirModalNovoContato: false,
            cadastroSignatario: {},
            cadastrosSignatarios: [],
            confirmacao: {
                subtitulo: '',
                acaoPrincipal: () => {
                    // funcao default
                }
            },
            documentosSignatario: [],
            filtrado: false,
            filtros: {},
            filtroSelecionado: 'TODOS',
            globalLoadingAtivado: false,
            gruposSignatarios: [],
            mostrarConfirmacao: false,
            mostrarSearch: false,
            papeisSignatario: [],
            quatidades: [5, 10, 15, 25, 50],
            qtdLinhasSelecionada: 10,
            search: ''
        }
    },
    computed: {
        ...mapState(['loki', 'cadastrosSignatariosBusca']),
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        }
    },
    async mounted() {
        await this.calcularNumeroLinhas()
        await this.resetarPaginacao()
        await this.resetarFiltros()
        await this.buscarPagina()
        await this.carregarPapeisSignatarios()
        await this.carregarDocumentosSignatario()
        await this.carregarGruposSignatarios()
    },
    methods: {
        ...mapActions([
            actionTypes.CADASTRO_SIGNATARIOS.BUSCAR_CADASTROS_SIGNATARIOS_FILTRADOS,
            actionTypes.CADASTRO_SIGNATARIOS.BUSCAR_CADASTRO_SIGNATARIO,
            actionTypes.CADASTRO_SIGNATARIOS.INSERIR_CADASTRO_SIGNATARIO,
            actionTypes.CADASTRO_SIGNATARIOS.ATUALIZAR_CADASTRO_SIGNATARIO,
            actionTypes.CADASTRO_SIGNATARIOS.REMOVER_CADASTRO_SIGNATARIO,
            actionTypes.GRUPO_SIGNATARIOS.BUSCAR_MEUS_GRUPOS_SIGNATARIOS
        ]),
        ...mapMutations([
            mutationTypes.SET_PAGINACAO_CADASTROS_SIGNATARIOS,
            mutationTypes.SET_FILTROS_CADASTROS_SIGNATARIOS
        ]),
        abrirModalConfirmacao() {
            this.mostrarConfirmacao = true
        },
        async atualizarFiltro() {
            if (!this.mostrarSearch) {
                this.mostrarSearch = true
            } else {
                if (this.search === '') {
                    this.mostrarSearch = false
                    if (!this.filtrado) {
                        return
                    } else {
                        this.filtrado = false
                    }
                } else {
                    this.filtrado = true
                }
                await this.setFiltros({
                    nome: {value: this.search, default: null},
                })
                await this.buscarPagina()
            }
        },
        atualizarListaCadastrosSignatarios(cadastrosSignatarios) {
            this.cadastrosSignatarios = cadastrosSignatarios
        },
        async atualizarQtdLinhasPorPagina() {
            await this.setPaginacao({
                page: 1,
                rowsPerPage: this.qtdLinhasSelecionada
            })
            await this.buscarPagina()
        },
        async buscarPagina() {
            const paginaCadastrosSignatarios = await this.$store.dispatch(actionTypes.CADASTRO_SIGNATARIOS.BUSCAR_CADASTROS_SIGNATARIOS_FILTRADOS)
            this.setPaginacao({
                numPages: paginaCadastrosSignatarios.totalPages
            })
            this.atualizarListaCadastrosSignatarios(paginaCadastrosSignatarios.content)
        },
        async calcularNumeroLinhas() {
            if (!this.ehMobile) {
                let qtdLinhasIdeal = Math.floor((window.innerHeight - 316) / 40)
                this.qtdLinhasSelecionada = qtdLinhasIdeal
                this.quatidades.push(qtdLinhasIdeal)
                this.quatidades.sort(function (a, b) {
                    return a - b
                })
            }

        },
        async carregarDocumentosSignatario() {
            const documentos = await this.$store.dispatch(actionTypes.BUSCAR_DOCUMENTOS_SIGNATARIO)
            for (const documento of documentos) {
                this.documentosSignatario.push({
                    text: documento.nome,
                    value: documento.documentoId,
                    descricao: documento.descricao
                })
            }
        },
        async carregarGruposSignatarios() {
            const grupos = await this.$store.dispatch(actionTypes.GRUPO_SIGNATARIOS.BUSCAR_MEUS_GRUPOS_SIGNATARIOS)
            for (const grupo of grupos) {
                this.gruposSignatarios.push({text: grupo.nome, value: grupo.id})
            }
        },
        async carregarPapeisSignatarios() {
            const listaPapeisSignatarios = await this.$store.dispatch(actionTypes.BUSCAR_PAPEIS_SIGNATARIOS)
            for (const papel of listaPapeisSignatarios) {
                this.papeisSignatario.push({text: papel.nome, value: papel.id})
            }
        },
        confirmarExcluirCadastro(cadastroSignatario) {
            this.cadastroSignatario = cadastroSignatario
            this.confirmacao = {
                subtitulo: `Você quer mesmo excluir o ${this.$tc("pt_br.signatario").toLowerCase()} ${cadastroSignatario.nome}?`,
                acaoPrincipal: this.excluirCadastro
            }
            this.abrirModalConfirmacao()
        },
        async editarCadastroSignatario(cadastroSignatario) {
            this.setMensagemLoading(`Buscando dados do ${this.$tc("pt_br.signatario").toLowerCase()}...`)
            this.cadastroSignatario = await this.$store.dispatch(actionTypes.CADASTRO_SIGNATARIOS.BUSCAR_CADASTRO_SIGNATARIO, {
                cadastroSignatarioId: cadastroSignatario.id
            })
            this.abrirModalNovoContato = true
        },
        async excluirCadastro() {
            this.setMensagemLoading(`Excluindo ${this.$tc("pt_br.signatario").toLowerCase()}...`)
            await this.$store.dispatch(actionTypes.CADASTRO_SIGNATARIOS.REMOVER_CADASTRO_SIGNATARIO, {
                cadastroSignatarioId: this.cadastroSignatario.id
            })
            this.cadastroSignatario = null
            await this.buscarPagina()
            this.fecharConfirmacao()
            this.fecharModalCadastroSignatario()
            this.mostrarNotificacaoSucessoDefault()
        },
        fecharDialogNovoContato() {
            this.abrirModalNovoContato = false
        },
        fecharConfirmacao() {
            this.confirmacao = {
                subtitulo: '',
                acaoPrincipal: () => {
                    // funcao default
                }
            }
            this.mostrarConfirmacao = false
        },
        fecharModalCadastroSignatario() {
            this.abrirModalNovoContato = false
        },
        getFiltrosAtuais() {
            return _.clone(this.cadastrosSignatariosBusca.filtros)
        },
        getPaginacaoAtual() {
            return _.clone(this.cadastrosSignatariosBusca.paginacao)
        },
        irParaPagina(pagina) {
            this.setPaginacao({
                page: pagina
            })
            this.buscarPagina()
        },
        limparFiltros() {
            if(this.filtrado && this.search === ""){
                this.filtrado = false

                this.resetarFiltros()
                this.buscarPagina()
            }
        },
        async novoCadastroSignatario() {
            this.cadastroSignatario = {
                id: null,
                tipoAssinatura: null,
                papelId: null,
                documentoId: null,
                skipFotoWpp: true,
                nome: '',
                email: '',
                ddi: '55',
                telefone: '',
                grupos: []
            }
            this.abrirModalNovoContato = true
        },
        resetarFiltros() {
            this.setFiltros({
                nome: {value: null, default: null},
            })
        },
        resetarPaginacao() {
            this.setPaginacao({
                page: 1,
                rowsPerPage: this.qtdLinhasSelecionada
            })
        },
        salvarFecharDialogNovoContato() {
            this.abrirModalNovoContato = false
            this.buscarPagina()
        },
        setFiltros(filtros) {
            const filtrosAtuais = this.getFiltrosAtuais()
            this.setFiltrosCadastrosSignatarios(Object.assign({}, filtrosAtuais, filtros))
        },
        setPaginacao(paginacao) {
            const paginacaoAtual = this.getPaginacaoAtual()
            this.setPaginacaoCadastrosSignatarios(Object.assign({}, paginacaoAtual, paginacao))
        }
    }
}
</script>

<style lang="stylus">
.co-contatos
    background-color var(--background-100) !important
    width 100%

    .titulo
        height 44px

    .title
        color var(--primary)

    .search-bar
        padding-top 0
        margin-top 0
        max-width 350px
        min-width 220px

    .footer
        display flex
        position relative
        align-content space-between

        .paginas
            width 100%
            align-content center

        .linhas-por-pagina
            position absolute
            right 0
            width 100px

@media (max-width: 720px)
    .co-contatos
        .footer
            .linhas-por-pagina
                position unset
</style>

