<template>
    <div class="co-importar-signatarios-tabela">
        <div class="busca mb-6">
            <div v-az-auth="'CadastroSignatario.Novo'">
                <v-btn color="primary" dark depressed large @click="novoCadastroSignatario">
                    <v-icon size="20px" class="mr-2"> mdi-plus-circle </v-icon>
                    Adicionar
                </v-btn>
            </div>

            <v-spacer/>

            <v-text-field
                class="search-bar"
                v-model="search"
                label="Buscar pelo nome ou e-mail"
                hide-details
                @keyup.enter="atualizarFiltro">
            </v-text-field>

            <v-btn icon outlined @click="atualizarFiltro">
                <v-icon dark>
                    mdi-magnify
                </v-icon>
            </v-btn>
        </div>

        <v-data-table
            v-model="value.selecionados"
            class="tabela-signatarios"
            hide-default-footer
            show-select
            :no-data-text="noDataText"
            :headers="headers"
            :items="cadastrosSignatarios"
            :items-per-page="5"
            checkbox-color="primary"
            @toggle-select-all="value.todosSelecionados = !value.todosSelecionados"
        >
            <template v-slot:header.data-table-select="{ props, on }">
                <v-simple-checkbox
                    :value="props.value || value.todosSelecionados || props.indeterminate"
                    v-on="on"
                    :indeterminate="props.indeterminate"
                    color="primary"
                />
            </template>
            <template v-slot:item.contato="{ item }">
                {{ montarContato(item) }}
            </template>
            <template v-slot:item.papel="{ item }">
                {{ converterPapel(item.papelId) }}
            </template>

        </v-data-table>

        <v-flex class="footer">
            <v-pagination
                dense
                class="paginas justify-center elevation-0"
                v-model="getPaginacaoAtual().page"
                :length="getPaginacaoAtual().numPages"
                :total-visible="7"
                @input="irParaPagina"/>
        </v-flex>
    </div>
</template>

<script>
import _ from 'lodash'
import {actionTypes, mutationTypes} from '@/commons/constants'
import {mapGetters, mapState} from 'vuex'

export default {
    name: 'ImportarSignatariosTabela',
    props: {
        value: {
            type: Object
        },
        papeisSignatario: {
            type: Array
        }
    },
    data() {
        return {
            listaSignatarios: [],
            cadastrosSignatarios: [],
            search: '',
            headers: [
                {
                    text: 'Nome',
                    align: 'left',
                    sortable: false,
                    filterable: true,
                    value: 'nome'
                },
                {
                    text: 'E-mail/Telefone',
                    align: 'left',
                    sortable: false,
                    filterable: true,
                    value: 'contato',
                    width: '35%'
                },
                {
                    text: 'Papel',
                    align: 'left',
                    sortable: false,
                    filterable: true,
                    value: 'papel',
                    width: '15%'
                }
            ]
        }
    },
    async mounted() {
        await this.resetarPaginacao()
        await this.resetarFiltros()
        await this.buscarPagina()
    },
    computed: {
        ...mapState(['cadastrosSignatariosBusca']),
        ...mapGetters(['ehEfcazCLM']),
        noDataText() {
            return `Nenhum ${this.$tc("pt_br.signatario").toLowerCase()} encontrado.`
        }
    },
    methods: {
        atualizarListaCadastrosSignatarios(cadastrosSignatarios) {
            this.cadastrosSignatarios = cadastrosSignatarios
        },
        async atualizarFiltro() {
            await this.setFiltros({
                nome: {value: this.search, default: null},
            })
            await this.buscarPagina()
        },
        async buscarPagina() {
            const paginaCadastrosSignatarios = await this.$store.dispatch(actionTypes.CADASTRO_SIGNATARIOS.BUSCAR_CADASTROS_SIGNATARIOS_FILTRADOS)
            this.setPaginacao({
                numPages: paginaCadastrosSignatarios.totalPages
            })
            this.atualizarListaCadastrosSignatarios(paginaCadastrosSignatarios.content)
        },
        converterPapel(papelId) {
            try {
                return this.papeisSignatario.find(papel => papel.value === papelId).text
            } catch (e) {
                return papelId
            }
        },
        getFiltrosAtuais() {
            return _.clone(this.cadastrosSignatariosBusca.filtros)
        },
        getPaginacaoAtual() {
            return _.clone(this.cadastrosSignatariosBusca.paginacao)
        },
        irParaPagina(pagina) {
            this.setPaginacao({
                page: pagina
            })
            this.buscarPagina()
        },
        montarContato(item) {
            if (item.tipoAssinatura === 'WHATSAPP') {
                let ddd = item.telefone.substring(0, 2);
                let telefone = item.telefone.substring(2);
                return '+' + item.ddi + " (" + ddd + ") " + telefone;
            } else {
                return item.email
            }
        },
        novoCadastroSignatario() {
            this.$emit('novo')
        },
        resetarPaginacao() {
            this.setPaginacao({
                page: 1,
                rowsPerPage: 5
            })
        },
        resetarFiltros() {
            this.setFiltros({
                nome: {value: null, default: null},
            })
        },
        setFiltros(filtros) {
            const filtrosAtuais = this.getFiltrosAtuais()
            this.$store.commit(mutationTypes.SET_FILTROS_CADASTROS_SIGNATARIOS, Object.assign({}, filtrosAtuais, filtros))
        },
        setPaginacao(paginacao) {
            const paginacaoAtual = this.getPaginacaoAtual()
            this.$store.commit(mutationTypes.SET_PAGINACAO_CADASTROS_SIGNATARIOS, Object.assign({}, paginacaoAtual, paginacao))
        }
    }
}
</script>

<style lang="stylus">
.co-importar-signatarios-tabela
    .busca
        justify-content end
        display flex
        align-items center

        .search-bar
            max-width 350px;

    .tabela-signatarios
        min-height 290px

    .v-data-table-header th
        background-color var(--divider-100) !important


</style>