<template>
    <div>
        <form enctype="multipart/form-data" novalidate>
            <div class="co-upload-pdf"
                 style="height: 120px"
                 @click="$refs.inputPdf.openFileSelector()"
                 @drop.prevent="$refs.inputPdf.receberArquivo($event.dataTransfer.items)" @dragover.prevent="">

                <input-pdf ref="inputPdf" @arquivo-selecionado="adicionarPdf" :lista-pdf-inserido="pdfs"/>

                <div style="width: 100%;">
                    <v-icon size="55"> mdi-cloud-upload </v-icon>

                    <div>
                        <p style="font-size: 14px">Arraste seus arquivos aqui ou clique para fazer upload.</p>
                        <p>Máximo de documentos (.pdf): 5</p>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import InputPdf from '@/views/contrato/novo/upload-envelope/components/InputPdf';

export default {
    name: 'UploadPdf',
    components: {InputPdf},
    props: {
        pdfs: Array
    },
    methods: {
        adicionarPdf(files) {
            this.$emit('adicionar-pdf', files)
        }
    }
}
</script>

<style lang="stylus">
.co-upload-pdf
    border 2px dashed #ccc
    margin 20px 0
    padding 30px 10px 30px
    align-content center
    align-items center
    text-align center
    background-color white
    color #7b7b7b
    display flex
    position relative
    cursor pointer

    &:hover
        border-color #939393

    .input-file
        opacity 0
        position absolute
        left 0
        top 0
        width 10px
        height 10px
        z-index 1

    p
        width 100%
        color #777
        font-size 13px
        margin-bottom 0

</style>