<template>
    <div class="co-email-design mb-3">
        <v-row no-gutters class="justify-center align-center mt-1">
            <div class="logotipo d-flex align-center">
                <img class="logo" :src="getLogo" height="42">
            </div>
        </v-row>

        <v-row no-gutters class="align-center mt-1">
            <div class="d-flex justify-center mt-2 title py-4 center"
                 :style="`background-color: ${ getPrimaryColor }; color: ${ getSecondaryColor }`">
                Título do Email
            </div>
        </v-row>

        <v-row no-gutters class="corpo align-center my-4">
            <div class="d-flex justify-center mt-2 subtitle-1 center">
                Corpo do Email
            </div>

            <div class="d-flex justify-center mt-2 mb-3 center">
                <v-btn :color="getPrimaryColor" class="pre-visualizar-botao">
                    <span :style="'color: ' + getSecondaryColor">
                        Botão
                    </span>
                </v-btn>
            </div>
        </v-row>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'EmailDesign',
    props: {
        colors: {
            type: Object,
            required: true
        },
        defaultColors: {
            type: Object,
            required: true
        },
        logo: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState(['loki']),
        getLogo() {
            if (!!this.logo) {
                return this.logo
            } else {
                return this.loki.product.mainLogo
            }
        },
        getPrimaryColor() {
            return this.disabled ? this.defaultColors.primary : this.colors.primary.color
        },
        getSecondaryColor() {
            return this.disabled ? this.defaultColors.secondary : this.colors.secondary.color
        }
    }
}
</script>

<style lang="stylus">
.co-email-design
    width 100%
    border 1px solid #c7c7c7
    border-radius: 5px
    background-color #f8f8f7

    .center
        width 100%

    .corpo
        background-color #ffffff

    .pre-visualizar-botao
        cursor default
</style>
