<template>
    <div>
        <v-row no-gutters>
            <v-col cols="6" sm="8">
                <v-row no-gutters>
                    <v-flex style="font-size: 16px; color: #777777">Anexo</v-flex>
                </v-row>
            </v-col>

            <v-col cols="3" sm="2" class="grey--text">
                <v-flex style="font-size: 16px; color: #777777">Tamanho</v-flex>
            </v-col>

            <v-col cols="3" sm="2" class="grey--text">
                <v-flex style="font-size: 16px; color: #777777">Ações</v-flex>
            </v-col>

        </v-row>
    </div>

</template>

<script>
export default {
    name: 'HeaderListPdf'
}
</script>