<template>
    <div>
        <input id="clmFileSelectorUploadInputPdf" type="file"
               multiple accept="application/pdf" @change="receberArquivo($event.target.files)"/>
        <loading :dialog="dialogLoading"/>
    </div>

</template>

<script>
import Loading from '@/commons/components/dialogs/Loading'

export default {
    name: 'InputPdfNovo',
    components: {Loading},
    props: {
        listaPdfInserido: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            tamanhoMaximoNomeArquivo: 180,
            toBase64: file => new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => resolve(reader.result)
                reader.onerror = error => reject(error)
            }),
            quantidadeMaximaPdf: 5,
            dialogLoading: false
        }
    },
    methods: {
        abrirLoading() {
            this.dialogLoading = true
        },
        calcFileSize(bytes, unit) {
            const k = 1024
            return parseFloat((bytes / Math.pow(k, unit)).toFixed(2))
        },
        ehArquivoComNomePermitido(file) {
            return file.name.length < this.tamanhoMaximoNomeArquivo
        },
        ehExtensaoArquivoValida(nomeArquivo) {
            const extensao = nomeArquivo.substr(nomeArquivo.lastIndexOf('.'), nomeArquivo.length)
            return extensao === '.pdf' || extensao === '.PDF' || this.accept === '*'
        },
        ehTamanhoArquivoPermitido(files) {
            const {maxSize, unit} = this.getMaxSizeConfig()
            const fileSizes = files.map(file => this.calcFileSize(file.size, unit))
            const pdfsInseridoSize = this.listaPdfInserido.map(file => this.calcFileSize(file.size, unit))
            const pdfSize = pdfsInseridoSize.reduce((acumalador, atual) => acumalador += atual, 0)
            const fileSize = fileSizes.reduce((acumalador, atual) => acumalador += atual, 0)
            return !((fileSize + pdfSize) > parseInt(maxSize))
        },
        emitirArquivoSelecionado(file) {
            this.$emit('arquivo-selecionado', file)
        },
        fecharLoading() {
            this.dialogLoading = false
        },
        async getArquivoSelecionado(eventItems) {
            let files = []
            Object.keys(eventItems).forEach((key) => {
                const item = eventItems[key]
                if (item.kind !== undefined && item.kind === 'file') {
                    files.push(item.getAsFile())
                } else {
                    files.push(item)
                }
            })

            for (let index = 0; index < files.length; index++) {
                files[index].base64 = await this.toBase64(files[index])
            }
            return files
        },
        getMaxSizeConfig() {
            const maxSizeFile = this.$store.state.loki.file.maxSize
            const maxSize = maxSizeFile.replace(/([A-Za-z]+)/g, '')
            const unit = ['B', 'KB', 'MB', 'GB', 'TB'].indexOf(maxSizeFile.replace(/\d+/g, '').toUpperCase())
            return {maxSize, unit}
        },
        openFileSelector() {
            document.getElementById('clmFileSelectorUploadInputPdf').click()
        },
        async receberArquivo(eventItems) {
            let files = []

            if (this.validarSelecaoArquivo(eventItems)) {
                this.mostrarNotificacaoErro("Nenhum arquivo selecionado. Por favor, selecione um arquivo.")
                return
            }

            if (this.validarQuantidadeMaximaPdf(eventItems)) {
                this.mostrarNotificacaoErro("Quantidade de pdfs maior que o seu plano suporta. Por favor, selecione menos arquivos.")
                return
            }

            if (this.validarQuantidadePdfs(eventItems)) {
                this.mostrarNotificacaoErro("Quantidade de pdfs maior que o seu plano suporta. Por favor, exclua um pdf para adicionar um novo.")
                return
            }

            this.abrirLoading()
            files = await this.getArquivoSelecionado(eventItems)

            let validarFiles = files.filter(file => !this.ehExtensaoArquivoValida(file.name))
            if (validarFiles.length > 0) {
                this.mostrarNotificacaoErro('Extensão do arquivo inválida. Por favor, faça upload de um arquivo .pdf')
                this.fecharLoading()
                return
            }

            if (!this.ehTamanhoArquivoPermitido(files)) {
                this.mostrarNotificacaoErro(`Tamanho de arquivo não permitido. Arquivo selecionado deve ter no máximo ${this.$store.state.loki.file.maxSize}.`)
                this.fecharLoading()
                return
            }

            validarFiles = files.filter(file => !this.ehArquivoComNomePermitido(file))
            if (validarFiles.length > 0) {
                this.mostrarNotificacaoErro(`Nome do arquivo muito grande. Por favor, renomei o arquivo para conter no máximo ${this.tamanhoMaximoNomeArquivo} caracteres.`)
                this.fecharLoading()
                return
            }

            this.emitirArquivoSelecionado(files)
            this.fecharLoading()
            document.getElementById("clmFileSelectorUploadInputPdf").value = "";
        },
        validarQuantidadeMaximaPdf(eventItems) {
            return eventItems.length > this.quantidadeMaximaPdf
        },
        validarQuantidadePdfs(eventItems) {
            return this.quantidadeMaximaPdf < (this.listaPdfInserido.length + eventItems.length)
        },
        validarSelecaoArquivo(eventItems) {
            return !Object.keys(eventItems).length
        }
    }
}
</script>

<style lang="stylus">
#clmFileSelectorUploadInputPdf
    opacity 0
    position absolute
    left 0
    top 0
    width 10px
    height 10px
    z-index 0
</style>