<template>
    <div class="co-home-botao-novo-contrato">

        <div v-if="!ehMobile || botaoQuadrado" class="pa-4 pb-0">
            <botao-novo
                :acoes="acoes"
                @novo-contrato="novoContrato('email')"
                @novo-lote-contrato="novoLoteContrato"
                @novo-contrato-whats="novoContrato('whatsApp')"
                :label="labelBotaoNovoDocumento"
            />

            <v-btn
                v-if="!ehMobile && !ehOrganizacaoFreeSemDocumentos && !ocultarCriarPasta"
                color="primary"
                class="ml-3"
                @click="abrirDialogNovaPasta"
            >
                <v-icon left> mdi-plus-circle </v-icon>
                Criar Pasta
            </v-btn>
        </div>

        <botao-novo-mobile
            v-else
            :acoes="acoesMobile"
            :eh-mobile="ehMobile"
            @novo-contrato="novoContrato('email')"
            @novo-lote-contrato="novoLoteContrato"
            @novo-contrato-whats="novoContrato('whatsApp')"
            @nova-pasta="abrirDialogNovaPasta"
        />

        <dialog-pasta
            v-az-auth="'Pasta.Novo'"
            v-if="dialogPasta"
            :dialog="dialogPasta"
            :pasta-pai="pastaAtual"
            @fechar="fecharDialogPasta"
        />

        <dialog-default-confirmar-acao
            v-if="dialogdefaultConfirmaAcao"
            :dialog="dialogdefaultConfirmaAcao"
            label-botao-principal="Sim, eu quero!"
            :titulo="tituloDialogDefaultConfirmarAcao"
            :subtitulo="subtituloDialogDefaultConfirmarAcao"
            sentimento="assustado"
            @acao-principal="acaoDialogDefaultConfirmaAcao"
            @fechar="fecharDialogDefaultConfirmarAcao"
        />
    </div>
</template>

<script>
import BotaoNovoMobile from './components/BotaoNovoMobile'
import {mapGetters, mapState} from 'vuex'
import BotaoNovo from './components/BotaoNovo'
import DialogDefaultConfirmarAcao from '@/commons/components/dialogs/DialogDefaultConfirmarAcao'
import {actionTypes} from '@/commons/constants'
import _ from 'lodash'
import DialogPasta from '@/views/common/dialogs/DialogPasta'

export default {
    name: 'BotoesCriar',
    components: {
        DialogPasta,
        DialogDefaultConfirmarAcao,
        BotaoNovo,
        BotaoNovoMobile
    },
    data() {
        return {
            dialogdefaultConfirmaAcao: false,
            acoes: [],
            acoesMobile: [],
            tituloDialogDefaultConfirmarAcao: '',
            subtituloDialogDefaultConfirmarAcao: '',
            tipoDialogDefault: '',
            novoContratoTipoDefault: null,
            dialogPasta: false
        }
    },
    props: {
        botaoQuadrado: {
            type: Boolean,
            default: false
        },
        ocultarCriarPasta: {
            type: Boolean,
            default: false
        }
    },
    created() {
        this.preencherAcoes()
    },
    computed: {
        ...mapState(['loki', 'consumoOrganizacao', 'pastaAtual']),
        ...mapGetters(['ehEfcazCLM', 'ehPlanoDocIlimitado', 'ehPlanoFree', 'ehOrganizacaoSemAssinatura', 'ehPastaRaiz']),
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        },
        labelBotaoNovoDocumento() {
            return `Criar ${this.$tc("pt_br.documento")}`
        },
        limiteContratosAtingido() {
            return this.ehPlanoDocIlimitado ? false : this.saldoUtilizadoDocumento >= this.saldoTotalDocumento
        },
        ehClickContratoEmLote() {
            return this.tituloDialogDefaultConfirmarAcao.includes('funcionalidade')
        },
        ehLimiteProximo() {
            return this.ehPlanoDocIlimitado ? false : this.saldoUtilizadoDocumento === this.saldoTotalDocumento - 1
        },
        ehOrganizacaoFreeSemDocumentos() {
            return !this.ehEfcazCLM && this.ehPlanoFree && this.consumoOrganizacao.documentosUtilizados === 0
        },
        saldoTotalDocumento() {
            return this.consumoOrganizacao.totalDocumentos
        },
        saldoUtilizadoDocumento() {
            return this.consumoOrganizacao.documentosUtilizados
        },
        mapearMensagemTituloDialogDefaultConfirmarAcao() {
            switch (this.tipoDialogDefault) {
                case 'documento_lote':
                    return 'o upgrade de Plano PRO, no botao de novo contrato em Lote'
                case 'documento_limite':
                    return 'o upgrade de Plano PRO, no botao de novo contrato'
                case 'documento_limite_proximo':
                    return 'o upgrade de Plano PRO, na tela de limite de documentos próximo'
                default:
                    return this.tituloDialogDefaultConfirmarAcao
            }
        },
    },
    methods: {
        abrirDialogContratarDocumentoEmLote() {
            const textoClm = 'Adquira o Plano PRO e continue alavancando seu negócio'
            const textoBesign = 'Faça um upgrade para um plano recorrente para poder utilizar essa funcionalidade. Clique em “Sim, eu quero” para conhecer nossas opções'
            
            this.tipoDialogDefault = 'documento_lote'
            this.tituloDialogDefaultConfirmarAcao = 'Poxa, você não tem acesso a funcionalidade de documento em lote'
            this.subtituloDialogDefaultConfirmarAcao = `${this.ehEfcazCLM ? textoClm : textoBesign} <span style="font-size:20px">&#128640;</span>`
            this.dialogdefaultConfirmaAcao = true
        },
        abrirDialogLimiteAtingido() {
            const textoClm = 'Adquira o Plano PRO e continue alavancando seu negócio'
            let textoBesign = this.mensagemDialogLimiteAtingidoBesign();

            this.tipoDialogDefault = 'documento_limite'
            this.tituloDialogDefaultConfirmarAcao = 'Eita! Você atingiu seu limite de uso'
            this.subtituloDialogDefaultConfirmarAcao = `${this.ehEfcazCLM ? textoClm : textoBesign} <span style="font-size:20px">&#128640;</span>`
            this.dialogdefaultConfirmaAcao = true
        },
        abrirDialogLimiteProximo() {
            const textoClm = 'Considere atualizar seu plano para o PRO'
            let textoBesign = ''

            if (this.ehPlanoFree) {
                textoBesign = 'Considere atualizar para um plano! Adquira um plano recorrente ou um pacote individual para continuar a enviar contratos para assinatura. Clique em “Sim, eu quero” para conhecer nossas opções.'
            } else {
                textoBesign = 'Considere atualizar para um plano! Faça um upgrade para um plano com mais documentos ou adquira um pacote individual para continuar a enviar contratos para assinatura. Clique em “Sim, eu quero” para conhecer nossas opções.'
            }

            this.tipoDialogDefault = 'documento_limite_proximo'
            this.tituloDialogDefaultConfirmarAcao = 'Eita! O limite de documentos esta próximo...'
            this.subtituloDialogDefaultConfirmarAcao = this.ehEfcazCLM ? textoClm : textoBesign;
            this.dialogdefaultConfirmaAcao = true
        },
        abrirDialogPasta() {
            this.dialogPasta = true
        },
        async acaoDialogDefaultConfirmaAcao() {
            await this.$store.dispatch(actionTypes.EMAIL.ENVIAR_EMAIL_COMERCIAL, {
                'mensagem': `O usuário ${this.$store.state.login.usuarioOrganizacao.usuarioNome} de e-mail: ${this.$store.state.login.usuarioOrganizacao.usuarioEmail} e telefone: ${this.$store.state.login.usuarioOrganizacao.usuarioTelefone} da organização ${this.$store.state.organizacao.nome} solicitou ${this.mapearMensagemTituloDialogDefaultConfirmarAcao}`
            })

            this.fecharDialogDefaultConfirmarAcao(false)
            this.$router.push({name: 'planos'})
        },
        abrirDialogNovaPasta() {
            this.abrirDialogPasta()
        },
        fecharDialogDefaultConfirmarAcao(continuar = true) {
            this.dialogdefaultConfirmaAcao = false
            if (this.ehLimiteProximo && !this.ehClickContratoEmLote && continuar) {
                this.redirecionarParaNovoContrato(this.novoContratoTipoDefault)
            }
        },
        fecharDialogPasta() {
            this.dialogPasta = false
        },
        mensagemDialogLimiteAtingidoBesign(){
            let textoBesign = ''

            if (this.ehPlanoFree){
                textoBesign = 'A quantidade de documentos disponíveis acabou! Adquira um plano recorrente ou um pacote individual para continuar a enviar contratos para assinatura. Clique em “Sim, eu quero” para conhecer nossas opções.'
            } else {
                textoBesign = 'A quantidade de documentos disponíveis em seu plano acabou! Faça um upgrade para um plano com mais documentos ou adquira um pacote individual para continuar a enviar contratos para assinatura. Clique em “Sim, eu quero” para conhecer nossas opções.'
            }

            return textoBesign;
        },
        novoContrato(tipoDefault) {
            this.novoContratoTipoDefault = tipoDefault
            if (this.ehEfcazCLM || !this.ehPlanoDocIlimitado) {
                if (this.ehLimiteProximo) {
                    this.abrirDialogLimiteProximo()
                    return
                } else if (this.limiteContratosAtingido) {
                    this.abrirDialogLimiteAtingido()
                    return
                }
            }

            this.redirecionarParaNovoContrato(tipoDefault)
        },
        novoLoteContrato() {
            if (!this.ehEfcazCLM && this.ehPlanoFree) {
                this.abrirDialogContratarDocumentoEmLote()
                return
            }
            if (this.limiteContratosAtingido) {
                this.abrirDialogLimiteAtingido()
                return
            }

            this.$router.push({name: 'loteDocumentos'})
        },
        preencherAcoes() {
            this.acoes = [
                // {
                //     nome: 'Assinar por WhatsApp',
                //     icon: 'mdi-whatsapp',
                //     acao: 'novo-contrato-whats',
                //     pro: false
                // },
                {
                    nome: 'Assinar por E-mail',
                    icon: 'mdi-email-outline',
                    acao: 'novo-contrato',
                    pro: false
                },
                {
                    nome: 'Assinar em Lote ',
                    icon: 'mdi-plus-box-multiple',
                    acao: 'novo-lote-contrato',
                    pro: true
                }
            ]
            this.acoesMobile = _.cloneDeep(this.acoes)
            this.acoesMobile.push({nome: 'Pasta', icon: 'mdi-folder', acao: 'nova-pasta', pro: false})
        },
        redirecionarParaNovoContrato(tipoDefault) {
            this.$router.push({name: 'novoDocumento', params: {tipoDefault: tipoDefault}})
        },
    }
}
</script>
