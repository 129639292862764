<template>
    <v-container grid-list px-10 pt-10 pb-4 class="co-integracoes">

        <configuracao-titulo
            titulo="WebHooks"
            subtitulo="Integre rapidamente as soluções da sua empresa com nossa API rest para assinatura eletronica."
            :subtitulo2="'Acesse agora a nossa documentação e automatize seus sistemas' + (this.ehEfcazCLM ? '.' : ' com a BeSign.')"
        />

        <v-row no-gutters class="align-center my-4 mx-3">
            <v-btn color="primary" dark href="https://www.besign.com.br/conheca-nossa-api" target="_blank">
                Acessar Documentação
            </v-btn>

            <v-alert dense text type="info" color="primary lighten-1" class="ml-4 mb-0 justify-space-between">
                <span class="grow" style="font-size: 12px">
                    Hey, ficou com dúvidas ou quer contratar? Conheça nossos planos!
                </span>

                <v-btn class="ml-3" color="primary" outlined x-small @click="conhecerPlanos">
                    CONHECER PLANOS
                </v-btn>
            </v-alert>
        </v-row>

        <v-row no-gutters class="mt-5">
            <div class="title">
                Ação do {{ $tc("pt_br.signatario") }}
            </div>
        </v-row>

        <v-divider/>

        <v-row no-gutters>
            <div class="subtitle-2">
                Assinatura/Rejeição do {{ $tc("pt_br.signatario") }}
            </div>
        </v-row>

        <v-row no-gutters class="justify-space-between align-start my-4 mx-3">
            <v-switch class="mt-0 mr-3 pt-2" v-model="webhooks.webhookAssinatura" label="Ativar"/>

            <v-text-field
                v-model="webhooks.webhookAssinaturaUrl"
                v-validate="validarUrl()"
                class="mr-3"
                :error-messages="errors.collect('Url')"
                label="Url"
                name="Url"
                placeholder="Informe a url"
                outlined
                dense
            />

            <v-btn color="green" class="white--text" @click="salvar" :loading="loading" :disabled="ehSalvarDesabilitado">
                Salvar
            </v-btn>
        </v-row>

        <v-row no-gutters class="my-5 justify-space-between align-center titulo">
            <div class="title">
                Notificações Pendentes de Envio
            </div>

            <v-btn color="green" class="white--text" @click="reenviarNotificacoes" :loading="loadingDados" :disabled="ehListaVazia">
                Reenviar Notificações
            </v-btn>
        </v-row>

        <v-row no-gutters class="justify-space-between mx-3">
            <notificacoes-webhook-pendentes-tabela
                :historico="notificacoesPendentes"
                :qtd-linhas-tabela="qtdLinhasSelecionada"
                :loading="loadingDados"/>
        </v-row>

        <v-row no-gutters class="justify-space-between mt-3 mx-3">
            <v-flex class="footer">
                <v-pagination
                    dense
                    class="paginas justify-center elevation-0"
                    v-model="getPaginacaoAtual().page"
                    :length="getPaginacaoAtual().numPages"
                    :total-visible="7"
                    @input="irParaPagina"/>

                <v-spacer v-if="!ehMobile"/>

                <v-select
                    v-if="!ehMobile"
                    class="linhas-por-pagina"
                    v-model="qtdLinhasSelecionada"
                    :items="quatidades"
                    label="Itens por pagina"
                    hide-details
                    @change="atualizarQtdLinhasPorPagina"/>
            </v-flex>
        </v-row>
    </v-container>
</template>

<script>

import NotificacoesWebhookPendentesTabela from './NotificacoesWebhookPendentesTabela'
import DialogDefaultConfirmarAcao from '@/commons/components/dialogs/DialogDefaultConfirmarAcao'
import {actionTypes, mutationTypes} from '@/commons/constants'
import ConfiguracaoTitulo from '../common/ConfiguracaoTitulo'
import {mapGetters, mapState} from 'vuex'
import _ from 'lodash'

export default {
    name: 'Integracoes',
    components: {
        ConfiguracaoTitulo,
        NotificacoesWebhookPendentesTabela,
        DialogDefaultConfirmarAcao
    },
    data() {
        return {
            loading: false,
            loadingDados: false,
            notificacoesPendentes: [],
            webhooks: {
                webhookAssinatura: false,
                webhookAssinaturaUrl: ''
            },
            filtrado: false,
            mostrarSearch: false,
            quatidades: [5, 10, 15, 25, 50],
            qtdLinhasSelecionada: 10,
        }
    },
    computed: {
        ...mapState(['login', 'webhookPendentesBusca', 'contatoComercial']),
        ...mapGetters(['ehEfcazCLM']),
        ehListaVazia() {
            return !this.notificacoesPendentes || this.notificacoesPendentes && this.notificacoesPendentes.length === 0
        },
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        },
        ehSalvarDesabilitado() {
            const webhook = this.login.organizacaoConfiguracao.webhook
            const ativo = webhook?.webhookAssinatura === this.webhooks.webhookAssinatura
            const url = webhook?.webhookAssinaturaUrl === this.webhooks.webhookAssinaturaUrl
            return ( ativo && url ) || ( !url && !this.validarUrl() )
        }
    },
    async created() {
        await this.buscarConfiguracoes()
        await this.resetarPaginacao()
        await this.buscarPagina()
        await this.setIntervaloAtualizacao()
    },
    methods: {
        atualizarPaginaInicial() {
            if (this.getPaginacaoAtual().page === 0) {
                this.buscarPagina()
            }
        },
        async atualizarQtdLinhasPorPagina() {
            this.setPaginacao({
                page: 1,
                rowsPerPage: this.qtdLinhasSelecionada
            })
            await this.buscarPagina()
        },
        async buscarConfiguracoes() {
            await this.$store.dispatch(actionTypes.WEBHOOK.BUSCAR_ORGANIZACAO_CONFIGURACOES_WEBHOOKS)
            this.webhooks = _.clone(this.login.organizacaoConfiguracao.webhook)
        },
        async buscarPagina() {
            try {
                this.loadingDados = true
                const paginaPendentes = await this.$store.dispatch(actionTypes.WEBHOOK.BUSCAR_WEBHOOKS_PENDENTES_ENVIO)
                this.setPaginacao({
                    numPages: paginaPendentes.totalPages
                })
                this.notificacoesPendentes = paginaPendentes.content
            }
            catch (e) {
                this.mostrarNotificacaoErro("Não foi possível recuperar as notificações")
            } finally {
                this.loadingDados = false
            }
        },
        conhecerPlanos() {
            this.$router.push({name: 'planos'})
        },
        getPaginacaoAtual() {
            return _.clone(this.webhookPendentesBusca.paginacao)
        },
        irParaPagina(pagina) {
            this.setPaginacao({
                page: pagina
            })
            this.buscarPagina()
        },
        resetarPaginacao() {
            this.setPaginacao({
                page: 1,
                rowsPerPage: this.qtdLinhasSelecionada
            })
        },
        async reenviarNotificacoes() {
            try {
                this.loadingDados = true
                await this.$store.dispatch(actionTypes.WEBHOOK.REENVIAR_WEBHOOKS_PENDENTES)
                this.resetarPaginacao()
                await this.buscarPagina()
            } catch (e) {
                this.mostrarNotificacaoErro('Erro ao solicitar reenvio das notificações')
            } finally {
                this.loadingDados = false
            }
        },
        async salvar() {
            try {
                this.loading = true
                await this.$store.dispatch(actionTypes.WEBHOOK.ATUALIZAR_ORGANIZACAO_CONFIGURACOES_WEBHOOKS, this.webhooks)
            } catch (e) {
                this.mostrarNotificacaoErro('Erro ao atualizar configurações')
            } finally {
                this.loading = false
            }
        },
        async setIntervaloAtualizacao() {
            this.processUpdate = window.setInterval(await this.atualizarPaginaInicial, 60 * 1000)
        },
        setPaginacao(paginacao) {
            const paginacaoAtual = this.getPaginacaoAtual()
            this.$store.commit(mutationTypes.WEBHOOK.SET_PAGINACAO_WEBHOOKS, Object.assign({}, paginacaoAtual, paginacao))
        },
        validarUrl() {
            let url = this.webhooks.webhookAssinaturaUrl
            const regex = /^(https?|ftp):\/\/([a-z0-9-]+(\.[a-z0-9-]+)*|([0-9]{1,3}\.){3}[0-9]{1,3}|(\[[a-f0-9:]+\]))(:[0-9]+)?(\/[a-z0-9-.,@?^=%&/~+_*#!-]*)*$/i

            return url === "" || regex.test(url) && url.length < 500
        },
    }
}
</script>

<style lang="stylus">
.co-integracoes
    background-color var(--background-100) !important
    width 100%
    overflow-y auto

    .title
        color var(--primary)

    .titulo
        height 44px

    .subtitle-2
        color var(--text-color-200)

    .footer
        display flex
        position relative
        align-content space-between

        .paginas
            width 100%
            align-content center

        .linhas-por-pagina
            position absolute
            right 0
            width 100px

@media (max-width: 720px)
    .co-contatos
        .footer
            .linhas-por-pagina
                position unset
</style>

