<template>
    <div class="co-notificacoes-webhook-pendentes-tabela">
        <v-data-table
            class="notificacoes-webhook-pendentes-tabela"
            hide-default-footer
            no-data-text="Nenhuma notificação pendente"
            :headers="headers"
            :items-per-page="qtdLinhasTabela"
            :items="historico"
            :loading="loading"
            loading-text="Buscando notificações pendentes..."

        >
            <template v-slot:item.data="{ item }">
                {{ formatHora(item.data) }}
            </template>

            <template v-slot:item.status="{ item }">
                {{ getStatus(item.status) }}
            </template>

            <template v-slot:item.acoes="{ item }">
                <v-layout class="acoes-tabela">
                    <v-tooltip top color="black">
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="primary" size="15px" @click="copiarNotificacao(item.conteudo)"> mdi-content-copy </v-icon>
                        </template>
                        <span>Copiar</span>
                    </v-tooltip>
                </v-layout>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import moment from 'moment-timezone'

export default {
    name: 'NotificacoesWebhookPendentesTabela',
    props: {
        historico: {
            type: Array,
            required: true
        },
        qtdLinhasTabela: {
            type: Number,
            default: 10
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            headers: [
                {
                    text: 'Data',
                    align: 'left',
                    value: 'data',
                    width: '160px',
                    divider: true,
                    sortable: false,
                    filterable: true
                },
                {
                    text: 'Status',
                    align: 'left',
                    value: 'status',
                    width: '160px',
                    divider: true,
                    sortable: false,
                    filterable: false
                },
                {
                    text: 'Conteudo',
                    align: 'left',
                    value: 'conteudo',
                    divider: true,
                    sortable: false,
                    filterable: false
                },
                {
                    text: 'Ações',
                    align: 'center',
                    value: 'acoes',
                    width: '70px',
                    divider: true,
                    sortable: false,
                    filterable: false
                }
            ]
        }
    },
    methods: {
        async copiarNotificacao(conteudo) {
            try {
                let sucess = await this.$copyText(conteudo, this.$refs.content_copy)
                if(sucess) {
                    this.mostrarNotificacaoSucesso('Notificação pendente para sua área de transferência')
                }
            } catch (e) {
                this.mostrarNotificacaoErro('Erro ao copiar link!')
            }
        },
        formatHora(valor) {
            return moment.utc(valor).local().format('DD/MM/YYYY hh:mm:ss')
        },
        getStatus(status) {
            switch (status) {
                case 'NAO_ENVIADA':
                    return 'Erro ao disparar'
                case 'NAO_ENFILEIRADA':
                    return 'Não disparada'
            }
        }
    }
}
</script>

<style lang="stylus">
.co-notificacoes-webhook-pendentes-tabela
    width 100%

    .v-input--selection-controls.v-input .v-label
        font-size 16px

    .notificacoes-webhook-pendentes-tabela
        color var(--text-color-300)

        .ellipsis
            display block
            align-items center
            text-overflow ellipsis
            white-space nowrap
            overflow hidden

        .acoes-tabela
            justify-content space-around

            .botoes
                color var(--text-color-200)

        thead th
            border-bottom thin solid rgba(0,0,0,.12)

        tbody td
            height 40px !important
            font-size 13px !important
            border-bottom thin solid rgba(0,0,0,.12)

@media (max-width: 720px)
    .co-notificacoes-webhook-pendentes-tabela
        .notificacoes-webhook-pendentes-tabela
            .acoes-tabela
                justify-content right

                .botoes-mobile
                    margin-right 15px

            thead
                display none !important

            tbody tr
                border-bottom 1px solid var(--v-primary-lighten1) !important

                td
                    display block !important
                    text-align left !important
                    padding 5px 10px !important
                    max-height unset !important
                    height unset !important
                    justify-items left

</style>