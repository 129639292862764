<template>
    <v-dialog v-model="dialogHistorico" persistent width="600">
        <v-card>
            <v-toolbar
                class="subtitle-1 elevation-0 font-weight-bold"
                color="primary"
                dark
                height="40px">
                Histórico {{ $tc("pt_br.signatario").toLowerCase() }}: {{ signatario.email }}
                <v-spacer></v-spacer>
                <v-icon small @click="$emit('fecharHistorico')"> mdi-window-close </v-icon>
            </v-toolbar>

            <v-layout v-if="validarSMS()" class="ma-1 pb-1 justify-end" row style="border-bottom: solid 1px #ddd;" wrap>
                <div style="width: 80px !important;">
                    <v-checkbox
                        v-model="filtrarEmail"
                        class="ma-0"
                        color="primary"
                        hide-details
                        label="E-mail"
                        style="transform: scale(0.7)"
                    />
                </div>

                <div>
                    <v-checkbox
                        v-model="filtrarSMS"
                        class="ma-0 mr-3"
                        color="primary"
                        hide-details
                        label="SMS"
                        style="transform: scale(0.7)"
                    />
                </div>
            </v-layout>

            <div class="timeline">
                <v-timeline class="ml-5 mt-2 mb-4" dense>
                    <v-timeline-item v-for="(item,i) in dados" :key="i" color="white" fill-dot small>
                        <template slot="icon">
                            <v-icon v-if="item.meioEnvio === 'EMAIL' && validarAssinatura(item)" :color="getCorIcone(item)" small>
                                mdi-email
                            </v-icon>

                            <v-icon v-else-if="item.meioEnvio === 'EMAIL' && validarAberturaEmail() && item.dataAberturaEmail === null" color="#ccc" small>
                                mdi-email-open
                            </v-icon>

                            <v-icon v-else-if="item.meioEnvio === 'EMAIL' && ((validarAberturaEmail() && item.dataAberturaEmail !== null) || !validarAberturaEmail())" color="#ffbe48" small>
                                mdi-email-open
                            </v-icon>

                            <v-icon v-else-if="item.meioEnvio === 'SMS'" color="#ffbe48" small>mdi-cellphone</v-icon>

                            <v-icon v-if="item.meioEnvio === 'WHATSAPP'" color="#ffbe48" small>mdi-whatsapp</v-icon>
                        </template>

                        <div class="item">
                            <v-flex>
                                <span class="subtitle-1 font-weight-medium" style="color: #274158; font-size: 12px">{{ conversaoTipoServico(item.tipoServico) }}</span>
                            </v-flex>

                            <v-flex>
                                <div style="font-size: 10px; color: #999999">Em :
                                    {{ item.dataEnvio | azDate('DD/MM/YYYY HH:mm:ss') }}
                                </div>

                                <div v-if="validarAberturaEmail() && item.dataAberturaEmail !== null"
                                     style="font-size: 10px; color: #999999">Abertura:
                                    {{ item.dataAberturaEmail | azDate('DD/MM/YYYY HH:mm:ss') }}
                                </div>
                            </v-flex>
                        </div>
                    </v-timeline-item>
                </v-timeline>
            </div>
        </v-card>
    </v-dialog>
</template>


<script>
import {mapState} from 'vuex'

export default {
    name: 'Historico',
    props: {
        dialogHistorico: {
            type: Boolean,
            required: true
        },
        signatario: {
            type: Object,
            required: true
        },
        historico: {}
    },
    data() {
        return {
            filtrarEmail: false,
            filtrarSMS: false,
            dados: [],
            dadosOriginais: []
        }
    },
    computed: {
        ...mapState(['login']),
    },
    mounted() {
        this.carregarHistoricoMensagens()
    },
    watch: {
        filtrarEmail() {
            if (this.filtrarEmail === true && this.filtrarSMS === false) {
                this.filtrarPorEmail()
            } else if (this.filtrarEmail === true && this.filtrarSMS === true) {
                this.dados = this.dadosOriginais
            } else if (this.filtrarEmail === false && this.filtrarSMS === true) {
                this.filtrarPorSMS()
            } else {
                this.dados = this.dadosOriginais
            }
        },
        filtrarSMS() {
            if (this.filtrarSMS === true && this.filtrarEmail === false) {
                this.filtrarPorSMS()
            } else if (this.filtrarSMS === true && this.filtrarEmail === true) {
                this.dados = this.dadosOriginais
            } else if (this.filtrarSMS === false && this.filtrarEmail === true) {
                this.filtrarPorEmail()
            } else {
                this.dados = this.dadosOriginais
            }
        }
    },
    methods: {
        carregarHistoricoMensagens() {
            this.dados = this.historico
            this.dadosOriginais = this.historico
        },
        conversaoTipoServico(tipoServico) {
            if (tipoServico === 'ENVIO_CONVITE') {
                return 'Convite enviado'
            } else if (tipoServico === 'REENVIO_CONVITE') {
                return 'Convite reenviado'
            } else if (tipoServico === 'ENVIO_ASSINATURA') {
                return `${this.$tc("pt_br.documento").toLowerCase()}s assinado`
            } else if (tipoServico === 'ENVIO_REJEICAO') {
                return 'Convite rejeitado'
            } else if (tipoServico === 'ENVIO_CHAVE_SEGURANCA') {
                return 'Chave de segurança enviada'
            } else if (tipoServico === 'ENVIO_TOKEN_ASSINATURA') {
                return 'Token de assinatura enviado'
            }
        },
        filtrarPorEmail() {
            this.dados = []
            this.dadosOriginais.forEach(dado => {
                if (dado.meioEnvio === 'EMAIL') {
                    this.dados.push(dado)
                }
            })
        },
        filtrarPorSMS() {
            this.dados = []
            this.dadosOriginais.forEach(dado => {
                if (dado.meioEnvio === 'SMS') {
                    this.dados.push(dado)
                }
            })
        },
        validarSMS() {
            return this.login.funcionalidadesOrganizacao.notificarConviteSMS || this.login.funcionalidadesOrganizacao.enviarTokenAssinaturaSMS
        },
        validarAberturaEmail() {
            return this.login.funcionalidadesOrganizacao.validarAberturaEmail
        },
        validarAssinatura(item) {
            if (item.tipoServico === 'ENVIO_REJEICAO' || item.tipoServico === 'ENVIO_ASSINATURA') {
                return true
            }
            return false
        },
        getCorIcone(item) {
            if (item.tipoServico === 'ENVIO_REJEICAO') {
                return '#f78393'
            } else {
                return '#72cb72'
            }
        }
    }
}
</script>

<style lang="stylus">
.timeline
    height 300px
    width 100%
    overflow-x hidden
    overflow-y auto

    .v-timeline
        padding 0 !important

    .v-timeline-item
        padding-bottom 10px !important

        .v-timeline-item__dot
            box-shadow none !important

            .v-icon
                font-size 20px !important

    .item
        padding 10px 0px

</style>