<template>
    <div>

        <descricao-envelope/>

        <envelope-animacao @exibir-lista-pdf="exbirListaPdf"/>

    </div>
</template>

<script>
import EnvelopeAnimacao from '@/views/contrato/novo/upload-envelope/components/envelope/components/EnvelopeAnimacao'
import DescricaoEnvelope from '@/views/contrato/novo/upload-envelope/components/envelope/components/DescricaoEnvelope'

export default {
    name: 'Envelope',
    components: {DescricaoEnvelope, EnvelopeAnimacao},
    methods: {
        visualizar(pdf) {
            this.$emit("visualizar", pdf)
        },
        excluir(pdf) {
            this.$emit("excluir", pdf)
        },
        exbirListaPdf(files) {
            this.$emit('exibir-lista-pdf', files)
        }
    }
}
</script>