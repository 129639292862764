<template>
    <v-text-field
        label="Quantidade"
        v-validate="'required'"
        v-model="value.quantidade"
        :error-messages="errors.collect('quantidade')"
        class="required-field item-minuta-quantidade"
        name="quantidade"
        placeholder="0"
        type="number"
    />
</template>

<script>
export default {
    name: 'Quantidade',
    props: ['value']
}
</script>

<style lang="stylus">
.item-minuta-quantidade
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button
        -webkit-appearance none
        margin 0
</style>