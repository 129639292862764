import axios from 'axios'
import {actionTypes} from '@/commons/constants'
import {AzSearchUrlBuilder} from '@azinformatica/loki'

export default {
    async [actionTypes.COMPRAS.INSERIR]({state}, compraAdicionais) {
        compraAdicionais.organizacaoId = state.login.organizacaoPlano.organizacaoId
        const {data} = await axios.post(`api/compras/inserir`,  compraAdicionais)
        return data.linkPagamento
    },

    async [actionTypes.COMPRAS.HISTORICO_ADICIONAIS_ORGANIZACAO]({state}, parametros) {
        const organizacaoId = state.login.organizacaoPlano.organizacaoId
        const {filtros, paginacao} = parametros

        const url = AzSearchUrlBuilder.build(`api/compras/adicionais/organizacoes/${organizacaoId}`, filtros, paginacao)
        const {data} = await axios.get(url)

        return data
    },

    async [actionTypes.COMPRAS.BUSCAR_PLANO_ASSINATURA_ADICIONAL]({state}) {
        const organizacaoId = state.login.organizacaoPlano.organizacaoId
        const {data} = await axios.get(`api/compras/plano/assinatura/adicionais/organizacoes/${organizacaoId}`)
        return data
    }
}