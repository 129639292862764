import {hasPermissions} from '@azinformatica/loki'

const rotasRedirecionamento = {
    MEUS_CONTRATOS: 'meus',
    PLANO_EXPIRADO: 'planoExpirado',
    PRIMEIRO_ACESSO: 'primeiroAcesso',
    USUARIO_INATIVO: 'usuarioInativo',
    PLANOS: 'planos'
}

export default (router, store) => {
    router.beforeEach((to, from, next) => {
        if (ehRotaRedirecionamento(to)) {
            continuarNavegacao(next)
        } else if (ehUsuarioInativo(store)) {
            redirecionarParaUsuarioInativo(next)
        } else if (semAutorizacaoParaRota(to, store)) {
            redirecionarParaMeusContratos(next)
        } else if (ehPrimeiroAcessoCLM(store)) {
            redirecionarParaPrimeiroAcessoCLM(next)
        } else if (ehPrimeiroAcesso(to, store)) {
            redirecionarParaPrimeiroAcesso(next)
        } else if (organizacaoComPlanoExpirado(store)) {
            redirecionarParaPlanoExpirado(next)
        } else if (usuarioVisualizador(store) && ehRotaOrigemBarra(from)) {
            redirecionarParaPlanos(next)
        } else {
            continuarNavegacao(next)
        }
    })
}

function continuarNavegacao(next) {
    next()
}

function ehPrimeiroAcesso(to, store) {
    return to.name !== rotasRedirecionamento.MEUS_CONTRATOS && !store.state.loki.product.efcazCLM && !store.state.login.organizacaoPlano.organizacaoExistente
}

function ehPrimeiroAcessoCLM(store) {
    return store.state.loki.product.efcazCLM && !store.state.login.organizacaoPlano.organizacaoExistente
}

function ehRotaOrigemBarra(from) {
    return from.fullPath === '/'
}

function ehRotaRedirecionamento(to) {
    let ehRedirecionamento = false
    for (let rota of Object.keys(rotasRedirecionamento)) {
        if (rotasRedirecionamento[rota] === to.name && to.name !== rotasRedirecionamento.MEUS_CONTRATOS) {
            ehRedirecionamento = true
            break
        }
    }
    return ehRedirecionamento
}

function ehUsuarioInativo(store) {
    return store.state.login.usuarioOrganizacao.usuarioSituacao === 'INATIVO'
}

function organizacaoComPlanoExpirado(store) {
    return !store.state.login.organizacaoPlano.organizacaoAtiva
}

function redirecionarPara(nomeRota, next) {
    next({name: nomeRota})
}

function redirecionarParaMeusContratos(next) {
    redirecionarPara(rotasRedirecionamento.MEUS_CONTRATOS, next)
}

function redirecionarParaPrimeiroAcesso(next) {
    redirecionarPara(rotasRedirecionamento.MEUS_CONTRATOS, next)
}

function redirecionarParaPrimeiroAcessoCLM(next) {
    redirecionarPara(rotasRedirecionamento.PRIMEIRO_ACESSO, next)
}

function redirecionarParaPlanoExpirado(next) {
    redirecionarPara(rotasRedirecionamento.PLANO_EXPIRADO, next)
}

function redirecionarParaPlanos(next) {
    redirecionarPara(rotasRedirecionamento.PLANOS, next)
}

function redirecionarParaUsuarioInativo(next) {
    redirecionarPara(rotasRedirecionamento.USUARIO_INATIVO, next)
}

function usuarioVisualizador(store) {
    return store.state.login.usuarioOrganizacao.perfil === 'Visualizador'
}

function semAutorizacaoParaRota(to, store) {
    const permissoesRequeridas = to.meta && to.meta.authorities ? to.meta.authorities : []
    const permissoesUsuario = store.state.loki.user.authorities
    return !hasPermissions(permissoesUsuario, permissoesRequeridas)
}
