<template>
    <v-toolbar flat dark color="primary" height="50">
        <v-btn icon dark color="text-color-2" @click="voltar">
            <v-icon> mdi-chevron-left </v-icon>
        </v-btn>
        <v-toolbar-title> Meus {{ $tc('pt_br.documento') }}s </v-toolbar-title>
        <v-spacer/>
        <v-toolbar-title> {{ titulo }}</v-toolbar-title>
        <v-spacer/>
        <v-btn v-if="possuiOpcoes" icon dark color="text-color-2" @click="maisOpcoes">
            <v-menu bottom left>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on"> mdi-dots-vertical </v-icon>
                </template>

                <v-list class="co-toobar-itens">
                    <v-list-item
                        v-for="(item, i) in menuItens"
                        :key="i"
                        class="menu-itens"
                        @click="openFunction(item)"
                    >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-btn>
    </v-toolbar>
</template>

<script>
export default {
    name: 'Toobar',
    props: {
        menuItens: {
            type: Array
        },
        possuiOpcoes: {
            type: Boolean,
            default: false
        },
        titulo: {
            type: String,
        }
    },
    methods: {
        maisOpcoes() {
            this.$emit('mais-opcoes')
        },
        openFunction(item) {
            this.$emit(item.event)
        },
        voltar() {
            this.$emit('voltar')
        },
    }
}
</script>

<style lang="stylus">
.co-toobar-itens
    .menu-itens
        cursor pointer

</style>