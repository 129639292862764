<template>
    <v-container class="co-resumo">
        <v-col class="resumo">
            <v-card-text class="resumo-itens">
                <v-row no-gutters pb-3>
                    <div class="primary--text lighten-1 titulo pb-3">RESUMO</div>
                </v-row>

                <card-resumo-item
                    v-if="!!this.planoSelecionado"
                    :produto="this.planoSelecionado"
                    class="pb-4"
                />

                <card-resumo-item
                    v-for="adicional in Object.keys(itensAdicionais)"
                    v-if="itensAdicionais[adicional].quantidade > 0"
                    :key="itensAdicionais[adicional].nome"
                    :produto="itensAdicionais[adicional]"
                    class="pb-4"
                />
            </v-card-text>

            <v-card-actions class="acoes d-block">
                <v-divider/>

                <p class="valor-final primary--text lighten-1">Total: <span
                    class="total">{{ formatReal(valorTotalItens) }} {{ mostrarParcelas }}</span>
                </p>

                <v-btn :disabled="!ehPagamentoSelecionado"
                       block
                       class="confirmar-actions__btn"
                       color="green white--text"
                       depressed
                       @click="confirmar">
                    CONFIRMAR
                </v-btn>
            </v-card-actions>
        </v-col>
    </v-container>
</template>

<script>
import CardResumoItem from './CardResumoItem'

export default {
    name: 'Resumo',
    components: {CardResumoItem},
    props: {
        metodoSelecionado: {
            type: Object,
        },
        itensAdicionais: {
            type: Object,
        },
        planoSelecionado: {
            type: Object,
        },
        valorTotalItens: {
            type: Number
        },
    },
    computed: {
        ehPagamentoSelecionado() {
            return !!this.metodoSelecionado
        },
        mostrarParcelas() {
            return !!this.planoSelecionado && this.planoSelecionado.parcelas > 0 ? `(${this.planoSelecionado.parcelas}x)` : ''
        },
    },
    methods: {
        confirmar() {
            this.$emit('confirmar-compra')
        },
        formatReal(valor) {
            return valor.toLocaleString('pt-br', {style: 'currency', currency: 'BRL', minimumFractionDigits: 2})
        },
    }
}
</script>

<style lang="stylus">
.co-resumo
    background-color var(--background-400) !important
    display flex

    .resumo
        justify-content space-between
        padding 0
        position relative

        .resumo-itens
            overflow-y auto
            height calc(100% - 99px)
            padding-bottom 0

        .valor-final
            text-align center
            margin 10px

            .total
                font-size 20px

        .acoes
            position absolute
            width 100%
            bottom 0
            padding 6px

            &__btn
                height 40px

@media (max-width: 600px)
    .co-resumo
        .resumo
            .resumo-itens
                overflow-y unset
                height unset
                padding-bottom 105px

            .acoes
                position fixed
                background-color var(--background-400)

</style>
