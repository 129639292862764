<template>
    <v-menu open-on-hover max-width="300" top left offset-y content-class="az-menu-lista" class="co-home-pasta-info">
        <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="co-home-contrato-info mr-1" color="secondary-200" size="20"> mdi-information </v-icon>
        </template>

        <informacao-contrato-novo :contrato="contrato"/>
    </v-menu>
</template>

<script>
import InformacaoContratoNovo from './components/InformacaoContratoNovo'

export default {
    name: "ContratoInfo",
    components: {
        InformacaoContratoNovo
    },
    props: {
        contrato: {
            type: Object,
            required: true
        }
    },
    methods: {
        visualizarContrato() {
            this.$router.push({name: 'detalheContrato', params: {id: this.contrato.contratoId}})
        }
    }
}
</script>

<style lang="stylus">
.co-home-contrato-info
    width 100%
    height 100%
    margin-top 1px

</style>