<template>
    <v-form ref="contratoForm" class="co-novo-contrato-form">
        <v-container>
            <v-row class="linha">
                <v-col cols="12">
                    <v-text-field
                        :name="getTranslateLabel()"
                        :label="getTranslateLabel()"
                        :placeholder="getTranslateLabel()"
                        class="required-field"
                        maxlength="255"
                        v-model="value.nome"
                        :rules="[(v) => !!v || 'Informe o campo obrigatório']"
                        :disabled="renovacao"
                        hide-details
                        persistent-placeholder
                    />
                </v-col>
            </v-row>

            <v-row class="linha">
                <v-col cols="12" sm="6">
                    <contrato-tag v-model="value.tags"/>
                </v-col>

                <v-col cols="12" sm="6" v-az-auth="'Pasta.Visualizar'">
                    <v-combobox
                        v-model="pastaSelecionada.pastaNome"
                        item-text="pastaNome"
                        label="Pasta"
                        placeholder="Selecione"
                        :disabled="renovacao"
                        :rules="getPastaRaizRules()"
                        persistent-placeholder
                        clearable
                        hide-details
                        @click="abrirDialogSelecioanrPasta"
                        @click:clear="limparPastaSelecioanda"
                    />
                </v-col>
            </v-row>

            <v-row class="linha">
                <v-col cols="12" sm="4">
                    <v-text-field
                        name-date="Início da vigência"
                        label="Início da vigência"
                        class="required-field"
                        v-model="value.inicioVigencia"
                        type="date"
                        :rules="[(v) => !!v || 'Informe o campo obrigatório']"
                        hide-details
                        persistent-placeholder
                    />
                </v-col>

                <v-col cols="12" sm="4" style="height: 100px !important">
                    <v-text-field
                        name-date="Término da vigência"
                        label="Término da vigência"
                        :class="classesConteudoDataFinal"
                        v-model="value.fimVigencia"
                        type="date"
                        :rules="getTerminoVigenciaRules()"
                        :disabled="desabilitarDataFim"
                        hide-details
                        persistent-placeholder
                    />
                    <v-checkbox
                        @change="limparDataTermino()"
                        v-model="desabilitarDataFim"
                        label="Sem data de término"
                        class="radio-prazo"
                        color="gray"
                    />
                </v-col>

                <v-col xs12 sm="4">
                    <v-text-field
                        name-date="Limite p/ assinaturas"
                        label="Limite p/ assinaturas"
                        class="required-field"
                        v-model="value.fimAssinatura"
                        type="date"
                        :rules="[(v) => !!v || 'Informe o campo obrigatório']"
                        hide-details
                    />
                </v-col>
            </v-row>
        </v-container>

        <dialog-selecionar-pasta
            v-if="dialogSelecionar"
            :dialog="dialogSelecionar"
            :pasta-selecionada="pastaSelecionada"
            @fechar="fecharDialogSelecionarPasta"
            @selecionar="selecionarPasta"
            pode-criar
        />
    </v-form>
</template>

<script>
import {hasPermissions} from '@azinformatica/loki'
import ContratoTag from '@/views/contrato/commons/ContratoTag'
import {mapGetters, mapState} from 'vuex'
import moment from 'moment-timezone'
import DialogSelecionarPasta from '@/views/common/dialogs/DialogSelecionarPasta'

export default {
    name: 'NovoContratoForm',
    components: {DialogSelecionarPasta, ContratoTag},
    inject: ['$validator'],
    props: {
        value: {
            required: true
        },
        renovacao: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            desabilitarDataFim: false,
            dialogSelecionar: false,
            pastaSelecionada: {}
        }
    },
    async mounted() {
        await this.validarPastaAtual()
        this.atualizarPastaAtual()
    },
    computed: {
        ...mapState(['pastaAtual']),
        ...mapGetters(['podeCriarContratoRaiz']),
        classesConteudoDataFinal() {
            return {
                'date-com-check': true,
                'required-field': !this.desabilitarDataFim
            }
        },
        contratoTagClass() {
            return {
                'xs12': !this.possuiPermissaoVisualizarPastas()
            }
        }
    },
    methods: {
        abrirDialogSelecioanrPasta() {
            this.dialogSelecionar = true
        },
        atualizarPastaAtual() {
            if (this.renovacao) {
                this.pastaSelecionada.pastaId = this.value.pastaId
                this.pastaSelecionada.pastaNome = this.value.pastaNome
            } else {
                this.pastaSelecionada.pastaId = this.pastaAtual.pastaId
                this.pastaSelecionada.pastaNome = this.pastaAtual.pastaNome
            }
        },
        fecharDialogSelecionarPasta() {
            this.dialogSelecionar = false
        },
        limparDataTermino() {
            if (this.desabilitarDataFim) {
                this.value.fimVigencia = null
            } else {
                this.value.fimVigencia = moment(this.value.inicioVigencia).add(5, 'days').format('YYYY-MM-DD')
            }
        },
        limparPastaSelecioanda() {
            this.pastaSelecionada = {
                pastaId: null
            }
        },
        possuiPermissaoVisualizarPastas() {
            const userPermissions = this.$store.state.loki.user.authorities
            return hasPermissions(userPermissions, ['Pasta.Visualizar'])
        },
        getPastaRaizRules() {
            if (this.podeCriarContratoRaiz || this.renovacao) {
                return []
            }
            return [(v) => !!v || 'Informe uma Pasta']
        },
        getTranslateLabel() {
            return 'Título do ' + this.$tc("pt_br.documento")
        },
        getTerminoVigenciaRules() {
            if (this.desabilitarDataFim) {
                return []
            }
            return [(v) => !!v || 'Informe o Término da Vigência']
        },
        selecionarPasta() {
            this.value.pastaId = this.pastaSelecionada.pastaId
            if (!this.pastaSelecionada.pastaId) {
                this.limparPastaSelecioanda()
            }
            this.fecharDialogSelecionarPasta()
        },
        validarFormulario() {
            return this.$refs.contratoForm.validate()
        }
    }
}
</script>

<style lang="stylus">
.co-novo-contrato-form
    width 100%
    margin-top 20px

    .linha
        margin-top 0

    .container
        padding-right 0 !important
        padding-left 0 !important

    .titulo
        width: 100% !important
        font-size: 28px
        text-align: center !important
        color: #555
        line-height: 1
        padding: 10px 0 10px 0

    .subtitulo
        width: 100% !important
        font-size: 16px
        text-align: center !important
        color: #adb7bd
        line-height: 1

    .date-com-check
        color #dddddd

        .v-text-field__details
            position absolute
            bottom 0

        .v-input__control
            height 82px

    .radio-prazo
        position: relative
        top: -48px
        margin-top 0 !important
        padding-top 0 !important

        .v-input--selection-controls
            &__input
                margin 0 !important
                width 18px
                padding-top 1px

            &__ripple
                width 20px
                height 20px
                left -10px
                top calc(50% - 17px)

        i
            font-size 14px !important
            color #aaa !important

        label
            font-size 11px !important
            color #aaa !important

        .v-input__slot
            margin-bottom 0 !important

        .v-messages
            display none !important
</style>
