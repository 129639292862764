<template>
    <v-dialog :value="dialog" width="400" persistent>
        <v-card class="co-dialog-repetir-posicionamento">
            <v-card-title class="primary title lighten-1 white--text">
                <div class="background-100--text">Repetir Posicionamento</div>
                <v-spacer/>
                <v-icon color="white" @click="fecharDialog"> mdi-window-close </v-icon>
            </v-card-title>

            <v-card-text class="mt-3" >
                <v-radio-group
                    row name="RepetirPosicionamento"
                    class="ml-3 mr-3"
                    v-model="valueRepetirPosicionamento"
                    v-for="(tipo, index) in tiposRepetirPosicionamento"
                    :key="index">
                    <v-radio
                        name="Nome"
                        class="mb-0"
                        color="primary"
                        height="5px"
                        :label="tipo.nome"
                        :value="tipo.id"/>
                </v-radio-group>
            </v-card-text>

            <v-card-actions class="acoes justify-center">
                <div class="secundaria">
                    <v-btn depressed @click="fecharDialog">CANCELAR</v-btn>
                </div>

                <div>
                    <v-btn color="primary" @click="repetirPosicionamento">REPETIR</v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'DialogRepetirPosicionamento',
    props: {
        dialog: {
            type: Boolean,
            default: false
        },
    },
    data () {
        return {
            tiposRepetirPosicionamento: [
                {id: 'TODAS_AS_PAGINAS', nome: 'Todas as paginas'},
                {id: 'PROXIMA_PAGINA', nome: 'Proxima pagina'},
                {id: 'ULTIMA_PAGINA', nome: 'Ultima pagina'}
            ],
            valueRepetirPosicionamento: 'TODAS_AS_PAGINAS'
        }
    },
    methods: {
        fecharDialog() {
            this.$emit('fechar-dialog')
        },
        repetirPosicionamento() {
            this.$emit('repetir-posicionamento', this.valueRepetirPosicionamento)
        }
    }
}
</script>

<style lang="stylus">
.co-dialog-repetir-posicionamento
    text-align center
    display block
    padding-bottom 15px
    background-color white

    .secundaria
        margin-right 30px

@media (max-width: 600px)
    .co-dialog-repetir-posicionamentom
        .acoes
            flex-direction column-reverse
        .secundaria
            margin 20px 0 0 0
</style>