export default {
    CARTAO_CREDITO: {
        nome: 'Cartão de Crédito',
        index: 0
    },
    BOLETO: {
        nome: 'Boleto Bancário',
        index: 1
    },
    PIX: {
        nome: 'PIX',
        index: 2
    },
}
