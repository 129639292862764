<template>
    <v-dialog :value="value" :max-width="maxWidth" :persistent="persistent">
        <v-card>
            <slot/>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'clm-dialog',
    props: {
        maxWidth: {
            type: String,
            default: '600'
        },
        persistent: {
            type: Boolean,
            default: true
        },
        value: {
            required: true
        }
    }
}
</script>