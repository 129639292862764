<template>
    <v-menu open-on-hover max-width="350" top left offset-y content-class="az-menu-lista" class="co-home-pasta-info">
        <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="co-informacao-card mr-1" color="secondary-200" size="20"> mdi-information </v-icon>
        </template>

        <informacao-pasta-novo :pasta="pasta"/>
    </v-menu>
</template>

<script>
import InformacaoPastaNovo
    from '@/views/home/meus-contratos/components/pastas/components/pasta/components/pasta-info/components/InformacaoPastaNovo'
import Pasta from '@/views/home/meus-contratos/components/pastas/components/pasta/Pasta'

export default {
    name: "PastaInfo",
    components: {
        InformacaoPastaNovo,
        Pasta
    },
    props: {
        pasta: {
            type: Object,
            required: true
        }
    },
    methods: {
        moverContrato(pasta, contrato) {
            return this.$emit('mover-contrato', {pasta, contrato})
        }
    }
}
</script>

<style lang="stylus">
.co-home-pasta-info
    width 100%
    height 100%

</style>