<template>
    <div class="co-envelope">
        <div class="envlope-wrapper">
            <div id="envelope" class="close"
                 @click="$refs.inputPdf.openFileSelector()"
                 @drop.prevent="$refs.inputPdf.receberArquivo($event.dataTransfer.items)" @dragover.prevent="">

                <input-pdf ref="inputPdf" @arquivo-selecionado="abrirEnvelope"/>

                <v-icon style="z-index: 10; color: rgba(255,255,255,0.6); position: absolute" size="70"> mdi-file-upload-outline </v-icon>
                <div class="front flap"></div>
                <div class="front pocket"></div>

                <div class="letter">
                    <div class="words line1"></div>
                    <div class="words line2"></div>
                    <div class="words line3"></div>
                    <div class="words line4"></div>
                </div>
            </div>
        </div>

        <div class="texto-informativo">
            <v-row no-gutters justify="center">Arraste seu(s) arquivo(s) ou clique no envelope para fazer upload.</v-row>
            <v-row no-gutters justify="center" class="texto-informativo-quantidade"> Máximo de documentos (.pdf): <span class="quantidade-maxima-pdf">5</span></v-row>
        </div>

    </div>

</template>

<script>

import InputPdf from '@/views/contrato/novo/upload-envelope/components/InputPdf'

export default {
    name: 'EnvelopeAnimacao',
    components: {InputPdf},
    data() {
        return {
            file: '',
            dialog: false
        }
    },
    methods: {
        abrirEnvelope(files) {
            this.animacaoAbrirEnvelope()
            setTimeout(() => this.exibirListaPdf(files), 1500)
        },
        animacaoAbrirEnvelope() {
            let envelope = document.getElementById("envelope")
            envelope.classList.add('open')
            envelope.classList.remove('close')
        },
        exibirListaPdf(files) {
            this.$emit('exibir-lista-pdf', files)
        }
    }
}
</script>

<style lang="stylus">
.co-envelope

    .texto-informativo-quantidade
        color #aaaaaa
        font-size 14px

    .quantidade-maxima-pdf
        color var(--v-primary-base)

    .texto-informativo
        color #aaaaaa
        font-size 16px
        text-align center

    .envlope-wrapper
        height 200px

    #envelope
        display flex
        justify-content center
        align-items center
        width 280px
        height 180px
        border-bottom-left-radius 6px
        border-bottom-right-radius 6px
        margin-left auto
        margin-right auto
        top 150px
        background-color #00334c
        box-shadow 0 4px 20px rgba(0, 0, 0, 0.2)
        cursor pointer

    .front
        position absolute
        width 0
        height 0
        z-index 3

    .flap
        border-left 140px solid transparent
        border-right 140px solid transparent
        border-bottom 82px solid transparent
        border-top 98px solid var(--v-primary-darken1)
        transform-origin top

    .pocket
        border-left 140px solid var(--v-primary-lighten1)
        border-right 140px solid var(--v-primary-lighten1)
        border-bottom 90px solid var(--v-primary-base)
        border-top 90px solid transparent
        border-bottom-left-radius 6px
        border-bottom-right-radius 6px

    .letter
        position relative
        background-color #fff
        width 90%
        margin-left auto
        margin-right auto
        height 90%
        top 5%
        border-radius 6px
        box-shadow 0 2px 26px rgba(0, 0, 0, .12)

    .letter:after
        content ''
        position absolute
        top 0
        bottom 0
        left 0
        right 0
        background-image linear-gradient(180deg, rgba(255, 255, 255, 0.00) 25%, rgba(215, 227, 239, 0.70) 55%, rgba(215, 227, 239, 1.00) 100%)

    .words
        position absolute
        left 10%
        width 80%
        height 14%
        background-color #eeeff0

    .words.line1
        top 15%
        width 20%
        height 7%

    .words.line2
        top 30%

    .words.line3
        top 50%

    .words.line4
        top 70%

    .open
        opacity 0
        transition opacity 1s 1s

        .flap
            transform rotatex(180deg)
            -webkit-transition transform 0.4s 0.2s ease, z-index 0.6s 0.2s
            -moz-transition transform 0.4s 0.2s ease, z-index 0.6s 0.2s
            -ms-transition transform 0.4s 0.2s ease, z-index 0.6s 0.2s
            -o-transition transform 0.4s 0.2s ease, z-index 0.6s 0.2s
            transition transform 0.4s 0.2s ease, z-index 0.6s 0.2s
            z-index 1

        .letter
            -webkit-transition transform 0.4s 0.8s ease, z-index 0.6s 0.2s
            -moz-transition transform 0.4s 0.8s ease, z-index 0.6s 0.2s
            -ms-transition transform 0.4s 0.8s ease, z-index 0.6s 0.2s
            -o-transition transform 0.4s 0.8s ease, z-index 0.6s 0.2s
            transform translatey(-60px)
            transition transform 0.4s 0.8s ease, z-index 0.6s 0.2s
            z-index 2


    .close
        opacity 1

        .flap
            transform rotatex(0deg)
            -webkit-transition transform 0.4s 0.6s ease, z-index 1s
            -moz-transition transform 0.4s 0.6s ease, z-index 1s
            -ms-transition transform 0.4s 0.6s ease, z-index 1s
            -o-transition transform 0.4s 0.6s ease, z-index 1s
            transition transform 0.4s 0.6s ease, z-index 1s
            z-index 5

        .letter
            transform translatey(0px)
            -webkit-transition transform 0.4s ease, z-index 1s
            -moz-transition transform 0.4s ease, z-index 1s
            -ms-transition transform 0.4s ease, z-index 1s
            -o-transition transform 0.4s ease, z-index 1s
            transition transform 0.4s ease, z-index 1s
            z-index 1
</style>