<template>
    <div class="co-area-trabalho">
        <div class="infos">
            <div class="coluna">
                <div class="label-menu">Versão</div>
                <historico-contrato @mudarVersao="carregarDadosVersao"/>
            </div>
            <div class="coluna" style="margin-right: 0 !important;" v-if="rascunho || emFormalizacao">
                <div class="label-menu ml-1">Vigência</div>
                <div style="display: flex; font-size: 11px; color: #bbb">
                    <v-dialog
                        ref="modalData"
                        width="290px"
                        v-model="modalDataInicial">
                        <template v-slot:activator="{ on }">
                            <div v-on="on" class="data-editavel">
                                {{ minutaAtual.inicioVigencia | azDate }}
                            </div>
                        </template>
                        <div class="titulo-seletor-data">Início da vigência</div>
                        <v-date-picker
                            show-current
                            locale="pt-br"
                            v-model="dataInicioVigencia"
                            scrollable
                            class="az-date"
                            @change="atualizarDataInicioVigencia">
                        </v-date-picker>
                    </v-dialog>

                    <div class="mx-1">a</div>

                    <v-dialog
                        v-model="modalDataFinal"
                        ref="modalData"
                        width="290px"
                        class="dialogDatePicker">
                        <template v-slot:activator="{ on }">
                            <div v-on="on" class="data-editavel">{{ calcularDataFinalVigencia() }}</div>
                        </template>
                        <div class="titulo-seletor-data">Término da vigência</div>
                        <v-date-picker
                            show-current
                            locale="pt-br"
                            :disabled="prazoIndeterminado"
                            v-model="dataFimVigencia"
                            :class="prazoIndeterminado ? 'az-date dialogDatePicker date-picker-desabilitado' : 'az-date dialogDatePicker'"
                            @change="atualizarDataFimVigencia">
                        </v-date-picker>
                        <div class="titulo-seletor-prazo-indeterminado">
                            <v-checkbox
                                v-model="prazoIndeterminado"
                                dense
                                @change="vigenciaIndeterminado"
                                center
                                class="checkbox-prazo"
                                label="Prazo Indeterminado">
                            </v-checkbox>
                        </div>
                    </v-dialog>
                    <v-icon size="12" color="#ccc" style="margin-bottom: 4px; margin-left: 5px"> mdi-pencil </v-icon>
                </div>
            </div>
            <div class="coluna" style="margin-right: 0 !important;" v-else>
                <div class="label-menu ml-1">Vigência</div>
                <div style="display: flex; font-size: 11px; color: #bbb">
                    <div style="margin-left: 5px">
                        {{ minutaAtual.inicioVigencia | azDate }}
                    </div>
                    <div class="mx-1">a</div>
                    <div>{{ calcularDataFinalVigencia() }}</div>
                </div>
            </div>
        </div>
        <div class="abas-area-trabalho">
            <v-icon class="icon-menu mobile-only" @click="abrirMenuMobile"> mdi-menu </v-icon>
            <menu-lateral
                :itens="menu"
                :visivel="menuLateral"
                :menu-default="getMenuDefault()"
                @selecionar="selecionarMenu"
            />
            <div class="conteudo-aba">
                <assinaturas
                    v-if="ehMenuSelecionado('ASSINATURAS')"
                    @alterarDataLimite="alterarDataLimiteAssinatura"
                    @abrirModalMotivoCancelamento="abrirModalMotivoCancelamento()"
                />
                <documento :path-documento="pathDocumento" v-if="mobile && ehMenuSelecionado('DOCUMENTO')"/>
                <posicionamento-dados
                    ref="refPosicionamentoDados"
                    v-if="ehMenuSelecionado('POSICIONAMENTO')"
                    :draggables="draggables"
                    @criar-posicionamento-assinatura="criarPosicionamentoAssinatura"
                    @limpar-draggables="limparDraggables"
                    @salvar-draggables="salvarDraggables"
                />
                <itens v-if="ehMenuSelecionado('ITENS')"/>
                <anexo v-if="ehMenuSelecionado('ANEXOS')" @anexoFechado="atualizarDocumento"/>
                <lista-sancao v-if="ehMenuSelecionado('SANCAO')"/>
            </div>
        </div>

        <modal-motivo-cancelamento
            v-if="modalMotivoCancelamento"
            @confirmarCancelamento="confirmarCancelamento"
            @fecharModalMotivoCancelamento="fecharModalMotivoCancelamento"
        />

        <dialog-default-confirmar-acao
            v-if="mostrarConfirmacao"
            :dialog="mostrarConfirmacao"
            sentimento="assustado"
            :titulo="'Eita! Deixa eu confirmar uma coisa...'"
            :subtitulo="confirmacao.subtitulo"
            label-botao-principal="Sim, claro!"
            @acao-principal="confirmacao.acaoPrincipal"
            @fechar="fecharConfirmacao"
        />
    </div>

</template>

<script>
import {mapActions, mapState} from 'vuex'
import {actionTypes, mutationTypes} from '@/commons/constants'
import Assinaturas from './assinaturas/Assinaturas'
import Documento from './Documento'
import HistoricoContrato from './HistoricoContrato'
import ModalMotivoCancelamento from '@/views/contrato/commons/ModalMotivoCancelamento'
import MenuLateral from './MenuLateral'
import Anexo from './anexos/ListaAnexo'
import ListaSancao from './sancoes/ListaSancao'
import moment from 'moment'
import DateTimeUtils from '@/commons/utils/DateTimeUtils'
import Itens from '../../../visualizar-contrato/barra-lateral/itens/Itens'
import PosicionamentoDados from './posicionamento/PosicionamentoDados'
import DialogDefaultConfirmarAcao from '@/commons/components/dialogs/DialogDefaultConfirmarAcao'

export default {
    name: 'AreaTrabalho',
    props: ['pathDocumento', 'draggables'],
    components: {
        DialogDefaultConfirmarAcao,
        PosicionamentoDados,
        Itens,
        Anexo,
        Assinaturas,
        Documento,
        HistoricoContrato,
        ModalMotivoCancelamento,
        MenuLateral,
        ListaSancao
    },
    data() {
        return {
            confirmacao: {
                subtitulo: '',
                acaoPrincipal: () => {
                    // acao default
                }
            },
            dataInicioVigencia: null,
            dataFimVigencia: null,
            dialog: true,
            menuLateral: true,
            modalMotivoCancelamento: false,
            modalDataInicial: false,
            modalDataFinal: false,
            menuSelecionado: {},
            mostrarConfirmacao: false,
            motivoCancelamento: '',
            prazoIndeterminado: false
        }
    },
    watch: {
        '$store.state.minutaAtual.inicioVigencia'() {
            this.formatarDataInicioVigercia()
        },
        '$store.state.minutaAtual.fimVigencia'() {
            this.formatarDataFimVigercia()
        },
        'menuSelecionado.id'() {
            this.limparDraggables()
        }
    },
    computed: {
        ...mapState(['carregandoDadosContrato', 'contrato', 'minutaAtual', 'signatarios', 'mobile', 'login']),
        arquivado() {
            return this.minutaAtual.contratoArquivado === true
        },
        cancelado() {
            return this.minutaAtual.situacaoFormalizacao === 'CANCELADO'
        },
        emFormalizacao() {
            return this.minutaAtual.situacaoFormalizacao === 'EM_FORMALIZACAO'
        },
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        },
        formalizado() {
            return this.minutaAtual.situacaoFormalizacao === 'FORMALIZADO'
        },
        menu() {
            let menu = [
                {
                    id: 'ASSINATURAS',
                    label: 'Assinaturas',
                    icone: 'mdi-gesture',
                    authorities: 'Contrato.Visualizar'
                },
                {
                    id: 'DOCUMENTO',
                    label: 'Documento',
                    icone: 'mdi-file',
                    mobile: true,
                    authorities: 'Contrato.Visualizar'
                }
            ]

            if (!this.ehMobile) {
                menu.push({
                    id: 'POSICIONAMENTO',
                    label: 'Posicionamento',
                    icone: 'mdi-signature-freehand',
                    authorities: 'Contrato.Posicionamento.Visualizar'
                })
            }
            menu.push({
                id: 'ITENS',
                label: 'Itens',
                icone: 'mdi-dns-outline',
                authorities: 'Contrato.Item.Visualizar'
            })
            menu.push({
                id: 'ANEXOS',
                label: 'Anexos',
                icone: 'mdi-paperclip',
                authorities: 'Contrato.Anexo.Visualizar'
            }, {
                id: 'SANCAO',
                label: 'Sanções',
                icone: 'mdi-alert',
                authorities: 'Contrato.Sancao.Visualizar'
            })
            return menu
        },
        rascunho() {
            return this.minutaAtual.situacaoFormalizacao === 'RASCUNHO'
        },
        verificaSituacaoMinuta() {
            return !this.formalizado && !this.cancelado && !this.arquivado
        }
    },
    methods: {
        ...mapActions([
            actionTypes.ATUALIZAR_CONTRATO,
            actionTypes.ATUALIZAR_MINUTA,
            actionTypes.BUSCAR_CONTRATO,
            actionTypes.BUSCAR_MINUTA,
            actionTypes.BUSCAR_MINUTA_ATUAL,
            actionTypes.BUSCAR_SIGNATARIOS,
            actionTypes.CANCELAR_MINUTA,
            actionTypes.BUSCAR_SIGNATARIOS
        ]),
        abrirMenuMobile() {
            this.menuLateral = !this.menuLateral
        },
        abrirModalMotivoCancelamento() {
            this.modalMotivoCancelamento = true
        },
        async atualizarDataFimVigenciaMinuta(fimVigencia) {
            this.setMensagemLoading(`Atualizando dados do ${this.$tc("pt_br.documento").toLowerCase()}...`)
            await this.atualizarMinuta({
                contratoId: this.contrato.id,
                minutaId: this.minutaAtual.id,
                minuta: {fimVigencia: fimVigencia}
            })
            await this.carregarDadosMinuta(this.minutaAtual)
            this.mostrarNotificacaoSucessoDefault()
        },
        async atualizarDataInicioVigenciaMinuta() {
            this.setMensagemLoading(`Atualizando dados do ${this.$tc("pt_br.documento").toLowerCase()}...`)
            const inicioVigencia = DateTimeUtils.setZeroHorasISO(this.dataInicioVigencia)
            await this.atualizarMinuta({
                contratoId: this.contrato.id,
                minutaId: this.minutaAtual.id,
                minuta: {inicioVigencia: inicioVigencia}
            })
            this.carregarDadosMinuta(this.minutaAtual);
            this.mostrarNotificacaoSucessoDefault()
        },
        async atualizarDataFimVigencia() {
            if (this.validarDatasInicioFimVigencia()) {
                await this.atualizarDataFimVigenciaMinuta(DateTimeUtils.set23HorasISO(this.dataFimVigencia))
            }
            this.formatarDataFimVigercia()
            this.modalDataFinal = false
        },
        async atualizarDataInicioVigencia() {
            if (this.validarDatasInicioFimVigencia()) {
                await this.atualizarDataInicioVigenciaMinuta()
            }
            this.formatarDataInicioVigercia()
            this.modalDataInicial = false
        },
        atualizarDocumento() {
            this.$emit('atualizarDocumento')
        },
        async alterarDataLimiteAssinatura(novoFimAssinatura) {
            this.setMensagemLoading(`Atualizando dados do ${this.$tc("pt_br.documento").toLowerCase()}...`)
            await this.atualizarMinuta({
                contratoId: this.contrato.id,
                minutaId: this.minutaAtual.id,
                minuta: {fimAssinatura: novoFimAssinatura}
            })
            await this.buscarMinutaAtual(this.contrato.id)
            this.mostrarNotificacaoSucessoDefault()
        },
        buscarPosicionamentoDadoSignatariosPorMinuta() {
            this.$refs.refPosicionamentoDados.buscarPosicionamentoDadoSignatariosPorMinuta()
        },
        async cancelar() {
            try {
                this.setMensagemLoading(`Cancelando versão do ${this.$tc("pt_br.documento").toLowerCase()}...`)
                this.fecharConfirmacao()
                await this.cancelarMinuta({
                    contratoId: this.contrato.id,
                    minutaId: this.minutaAtual.id,
                    motivoCancelamento: this.motivoCancelamento
                })
                await this.sincronizarDadosContrato()
                this.mostrarNotificacaoSucessoDefault()
            } catch (e) {
                this.fecharConfirmacao()
            }
        },
        calcularDataFinalVigencia() {
            if (!this.minutaAtual.fimVigencia) {
                this.prazoIndeterminado = true
                return 'Indeterminado'
            }
            return moment(this.minutaAtual.fimVigencia).format('DD/MM/YYYY')
        },
        async carregarDadosVersao(minuta) {
            if (this.ehVersaoSelecionada(minuta)) {
                return
            }
            await this.carregarDadosMinuta(minuta)
            this.$emit("buscarDocumento")
        },
        async carregarDadosMinuta(minuta) {
            this.setMensagemLoading(`Carregando dados do ${this.$tc("pt_br.documento").toLowerCase()}...`)
            await this.buscarMinuta({contratoId: this.contrato.id, minutaId: minuta.id})
        },
        criarPosicionamentoAssinatura(signatario) {
            this.$emit('criar-posicionamento-assinatura', signatario)
        },
        confirmarCancelamento(value) {
            this.motivoCancelamento = value.motivoCancelamento
            this.fecharModalMotivoCancelamento()
            this.confirmacao = {
                subtitulo: `Você quer mesmo cancelar a versão do ${this.$tc("pt_br.documento").toLowerCase()}?`,
                acaoPrincipal: this.cancelar
            }
            this.mostrarConfirmacao = true
        },
        ehMenuSelecionado(menuId) {
            return this.menuSelecionado.id === menuId
        },
        ehVersaoSelecionada(minuta) {
            return this.minutaAtual.versao === minuta.versao
        },
        formatarDataInicioVigercia() {
            this.dataInicioVigencia = moment(this.minutaAtual.inicioVigencia).format('YYYY-MM-DD')
        },
        formatarDataFimVigercia() {
            if (this.minutaAtual.fimVigencia) {
                this.prazoIndeterminado = false
                this.dataFimVigencia = moment(this.minutaAtual.fimVigencia).format('YYYY-MM-DD')
            } else {
                this.prazoIndeterminado = true
            }
        },
        fecharConfirmacao() {
            this.mostrarConfirmacao = false
            this.confirmacao = {
                subtitulo: '',
                acaoPrincipal: () => {
                    // acao default
                }
            }
        },
        fecharModalMotivoCancelamento() {
            this.modalMotivoCancelamento = false
        },
        getMenuDefault() {
            if (window.outerWidth <= 720) {
                this.$store.commit(mutationTypes.SET_MOBILE, true)
            } else {
                this.$store.commit(mutationTypes.SET_MOBILE, false)
            }
            return this.menu[0]
        },
        limparDraggables() {
            this.$emit('limpar-draggables')
        },
        possuiSignatarios() {
            return this.signatarios && this.signatarios.length > 0
        },
        salvarDraggables() {
            this.$emit('salvar-draggables')
        },
        selecionarMenu(menu) {
            this.menuSelecionado = menu
        },
        setarHoraFimVigencia() {
            const fimVigencia = moment(this.dataInicioVigencia).add(5, 'days').format('YYYY-MM-DD')
            this.minutaAtual.fimVigencia = DateTimeUtils.set23HorasISO(fimVigencia)
        },
        async sincronizarDadosContrato() {
            this.setMensagemLoading(`Carregando dados do ${this.$tc("pt_br.documento").toLowerCase()}...`)
            await this.buscarContrato(this.contrato.id)
            this.setMensagemLoading(`Carregando dados do ${this.$tc("pt_br.documento").toLowerCase()}...`)
            await this.buscarMinutaAtual(this.contrato.id)
        },
        validarDatasInicioFimVigencia() {
            if (!this.dataInicioVigencia) {
                return false
            }
            if (this.dataFimVigencia) {
                const fimVigencia = moment(this.dataFimVigencia)
                const inicioVigencia = moment(this.dataInicioVigencia)
                if (inicioVigencia.isAfter(fimVigencia)) {
                    this.mostrarNotificacaoErro('A data de término da vigência não pode ser anterior ao início da vigência.')
                    return false
                }
            }
            return true
        },
        async vigenciaIndeterminado() {
            if (this.prazoIndeterminado) {
                this.dataFimVigencia = null
                this.modalDataFinal = false
                await this.atualizarDataFimVigenciaMinuta(this.dataFimVigencia)
            } else {
                this.setarHoraFimVigencia()
                this.formatarDataFimVigercia()
                const dataFimVigencia = DateTimeUtils.set23HorasISO(this.dataFimVigencia)
                await this.atualizarDataFimVigenciaMinuta(dataFimVigencia)
            }

        }
    }
}
</script>

<style lang="stylus">
.checkbox-prazo
    margin 0 !important
    padding: 15px 10px !important;

    label
        font-size 12px
        color #999 !important

    .v-input__slot
        margin 0 !important

    .v-messages
        display none !important

.dialogDatePicker
    box-shadow none !important

.date-picker-desabilitado
    box-shadow none !important

    .v-date-picker-header
        background-color #cccccc !important

    .v-date-picker-table
        background-color #ddd !important

.titulo-seletor-prazo-indeterminado
    display block
    text-align center
    font-size 16px
    background-color white !important
    font-weight bold
    width: 100%
    padding: 0 10px
    border-top 1px solid #eee !important

.titulo-seletor-data
    display block
    text-align center
    font-size 16px
    background-color var(--v-primary-base) !important
    color white
    font-weight bold
    width: 100%
    padding: 10px 10px

.data-editavel
    cursor pointer
    padding 0 5px
    border-radius 2px

    i
        font-size 12px !important
        padding-bottom 2px
        margin-left 2px
        color #ccc !important
        transition 0 !important

    &:hover
        background-color #ccc
        color white

        i
            color white !important
            transition 0 !important

.pagination-label
    font-size 12px !important
    color #bbb !important

.az-dv-controls
    border-bottom 1px solid #ddd

    i
        font-size 18px !important
        color #bbb !important

.co-area-trabalho
    width 450px
    background-color white
    height calc(100% - 64px)
    position absolute
    left 0

    .icon-menu
        position absolute
        top 12px
        left 12px

    .abas-area-trabalho
        height calc(100% - 50px)
        display flex
        border-right 1px solid #ccc
        box-shadow inset 0 8px 10px -4px #ccc

        .conteudo-aba
            width 100%
            height: 100%

    .infos
        display flex
        justify-content left
        padding 0
        height 50px
        box-shadow 10px 10 xp 10px #000
        padding-left 60px
        position relative
        border-bottom 1px solid #ddd

        .coluna
            display block
            padding-top 8px
            height 50px
            margin-right 30px

            .label-menu
                font-size 12px
                color #777

            span
                font-size 12px
                color #777
                font-weight bold

            p
                font-size 11px
                color #bbb

    .mobile-only
        display none !important

@media (max-width: 720px)
    .co-area-trabalho
        width 100% !important
        border 0 !important
        height calc(100% - 56px) !important

        .menu-lateral
            box-shadow none !important

        .arrow-right
            display none !important

        .mostrar_menu
            width 48px !important
            transition 0.5s

        .esconder_menu
            width 0 !important
            opacity 0 !important
            transition 0.5s

            .icone-ativo
                display none !important

            .icone-inativo
                display none !important

        .mobile-only
            display block !important

        .abas-area-trabalho
            .conteudo-aba
                width 100%
                height 100%
                overflow hidden

</style>