<template>
    <div class="co-email-signatario">
        <v-combobox
            v-model="value.email"
            name="E-mail"
            :label="placeholder"
            chips deletable-chips
            multiple
            append-icon=""
            autocomplete="off"
            :placeholder="placeholder"
            persistent-placeholder
            hide-selected
            v-validate="'required|email'"
            :disabled="desabilitarCampo"
            :filter="filter"
            :search-input.sync="search"
            :error-messages="errors.collect('E-mail')"
            :class="campoRequerido ? 'required-field' : ''"
            :items="signatarios"
            @input="inserirSignatarioSugestao"
            :rules="getEmailRules">

            <template v-slot:item="{ index, item }">
                <span>{{ item.text }}</span>
            </template>
        </v-combobox>

        <div class="hint-checkbox">
            {{ descricaoCampo }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'EmailSignatarioLote',
    props: {
        value: {
            required: true
        },
        campoRequerido: {
            type: Boolean,
            default: false
        },
        desabilitarCampo: {
            type: Boolean
        },
        signatariosSugestao: {
            type: Array
        }
    },
    data() {
        return {
            search: '',
            teste: [],
            signatarios: []
        }
    },
    computed: {
        descricaoCampo() {
            return `Para adicionar mais de um ${this.$tc("pt_br.signatario").toLowerCase()} utilize vírgulas ex: efcaz@gmail.com, efcaz2@gmail.com`
        },
        getEmailRules(value) {
            if (value instanceof Array && value.length == 0) {
                return ['O campo E-mail é obrigatório']
            }
            return []
        },
        placeholder() {
            return `E-mail(s) do(s) ${this.$tc("pt_br.signatario").toLowerCase()}(s)`
        }
    },
    watch: {
        'search': async function (value) {
            if (value != null && value.includes(',')) {
                await this.inserirSignatarioVetor()
                this.$nextTick(() => {
                    this.search = ''
                })
            }
        }
    },
    mounted() {
        this.transformarSigntariosEmail()
    },
    methods: {
        inserirSignatarioSugestao() {
            this.value.email.map((email, index) => {
                if (email.text !== undefined) {
                    this.value.email[index] = email.text
                }
            })
            this.search = ''
        },
        transformarSigntariosEmail() {
            if (this.signatariosSugestao !== undefined) {
                this.signatarios = this.signatariosSugestao.map(signatario => {
                    return {
                        text: signatario.email
                    }
                })
            }
        },
        required(value) {
            if (value instanceof Array && value.length > 0) {
                let emailsInvalidos = value.filter(text => {
                    if (typeof text == String) {
                        return !text.text.match(/\w+@\w+\.\w+/gi)
                    } else {
                        return false
                    }

                })

                return emailsInvalidos.length !== 0 ? 'Required.' : true
            }
            return true
        },
        async inserirSignatarioVetor() {
            await this.$nextTick(() => {
                let emails = []
                emails.push(...this.search.split(','))
                emails = emails.map(email => email.trim())
                emails = emails.filter(email => email.length > 0)
                this.value.email.push(...emails)
                this.$nextTick(() => {
                    this.search = ''
                })
            })
        },
        filter(item, queryText) {
            let regex = new RegExp('^' + queryText, 'gi')
            return item.text.match(regex)
        }
    }
}
</script>

<style lang="stylus">
.co-email-signatario
    .v-select__selections
        max-height: 150px
        overflow-y: auto
        display: inline

    .v-chip__content
        max-width: 250px;
        display: inline !important;
        padding-right: 25px !important;
        padding-top: 5px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

    .v-chip__close
        position: absolute;
        top: 6px;
        right: 15px;

    .v-autocomplete:not(.v-input--is-focused).v-select--chips input
        margin-top 6px
        max-height 25px !important
</style>