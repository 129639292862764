<template>
    <v-flex class="co-cartao-credito-input align-self-center pt-3 pr-2">
        <v-row no-gutters class="input">
            <v-text-field
                class="required-field"
                :error-messages="errors.collect('numCartao')"
                flat
                hide-details
                label="Número do cartão"
                name="numCartao"
                v-mask="'####-####-####-####'"
                v-model="dadosCartao.numeroCartao"
                v-validate="'required'"
            />
        </v-row>
        <v-row no-gutters class="input">
            <v-text-field
                class="required-field"
                :error-messages="errors.collect('nomeTitular')"
                flat
                hide-details
                label="Nome do titular do cartão"
                name="nomeTitular"
                v-model="dadosCartao.nomeCartao"
                v-validate="'required'"
            />
        </v-row>
        <v-row no-gutters class="input">
            <v-text-field
                class="input__validade required-field"
                :error-messages="errors.collect('validadeCartao')"
                flat
                hide-details
                label="Validade"
                name="validadeCartao"
                v-mask="'##/##'"
                v-model="dadosCartao.validadeCartao"
                v-validate="'required'"
            />
            <v-spacer/>
            <v-text-field
                class="required-field input__validade csv"
                :error-messages="errors.collect('csv')"
                flat
                hide-details
                id="csv"
                label="Cód. segurança"
                name="csv"
                v-mask="'###'"
                v-model="dadosCartao.codSegurancaCartao"
                v-validate="'required'"
            />
        </v-row>
        <v-row no-gutters class="input">
            <v-text-field
                class="required-field"
                :error-messages="errors.collect('cpfCnpjTitular')"
                flat
                hide-details
                label="CPF ou CNPJ do titular do cartão"
                name="cpfCnpjTitular"
                v-mask="['###.###.###-##','##.###.###/####-##']"
                v-model="dadosCartao.cpfCnpjCartao"
                v-validate="validarCpfCnpj()"
            />
        </v-row>
    </v-flex>
</template>

<script>
export default {
    name: 'CartaoCreditoInput',
    props: {
        dadosCartao: {
            type: Object,
        }
    },
    methods: {
        validarCpfCnpj() {
            if (!this.dadosCartao.cpfCnpjCartao) {
                return 'required'
            }
            return this.dadosCartao.cpfCnpjCartao.length > 14 ? 'required|cnpj' : 'required|cpf'
        }
    }
}
</script>

<style lang="stylus">
.co-cartao-credito-input
    width 100%

    .input
        font-size 12px
        height 45px

        &__validade
            width 80px

        .v-label
            font-size 11px

    .v-input input
        padding 0

</style>
