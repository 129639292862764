import {actionTypes, mutationTypes} from '@/commons/constants'
import _ from 'lodash'
import {mapMutations, mapState} from 'vuex'

export default {
    computed:{
        ...mapState(['meusContratos', 'meusContratosBusca', 'paginacaoContratos'])
    },
    methods:{
        ...mapMutations([mutationTypes.SET_MEUS_CONTRATOS,
                         mutationTypes.SET_PAGINACAO_BUSCA_CONTRATOS,
                         mutationTypes.SET_TOTAL_CONTRATOS_LISTAGEM]),
        adicionarContratosNaLista(novosContratos) {
            this.prepararNovosContratos(novosContratos)
            const contratos = this.meusContratos.concat(novosContratos)
            this.setMeusContratos(contratos)
        },
        async buscarContratos() {
            this.resetarContratos()
            await this.buscarPaginaContratos()
        },
        async buscarPaginaContratos() {
            this.incrementarPagina()

            const cor = this.meusContratosBusca.filtros.cor.value !== ''
            const tags = this.meusContratosBusca.filtros.tags.value !== ''
            const nome = this.meusContratosBusca.filtros.nome.value !== ''
            const dataCriacaoInicio = this.meusContratosBusca.filtros.dataCriacaoInicio.value !== ''
            const dataCriacaoFim = this.meusContratosBusca.filtros.dataCriacaoFim.value !== ''

            let paginaContratos = null

            if (cor || tags || nome || dataCriacaoInicio || dataCriacaoFim) {
                paginaContratos = await this.$store.dispatch(actionTypes.CONTRATO.BUSCAR_CONTRATOS_FILTRADOS)
            } else {
                paginaContratos = await this.$store.dispatch(actionTypes.CONTRATO.BUSCAR_TODOS_CONTRATOS)
            }
            this.adicionarContratosNaLista(paginaContratos.content)

            if (this.ehUltimaPaginaContratos(paginaContratos)) {
                this.decrementarPagina()
                this.previnirLoopInfinitoContratos()
            }
            return paginaContratos
        },
        construirTags(contrato) {
            if (contrato.tags && contrato.tags.length > 0) {
                contrato.tags = contrato.tags.split('|')
            } else {
                contrato.tags = []
            }
        },
        construirSignatarios(contrato) {
            if (contrato.signatarios && contrato.signatarios.length > 0) {
                try {
                    contrato.signatarios = JSON.parse(contrato.signatarios)
                } catch (e) {
                    contrato.signatarios = [{status: 'falha ao buscar'}]
                }
            } else {
                contrato.signatarios = []
            }
        },
        decrementarPagina() {
            const paginacao = this.getPaginacaoAtual()
            this.setPaginacaoContrato({
                page: paginacao.page - 1
            })
        },
        ehPaginaVazia(pagina) {
            return pagina.content.length === 0
        },
        ehUltimaPaginaContratos(pagina) {
            return pagina.number >= pagina.totalPages - 1
        },
        getPaginacaoAtual() {
            return _.clone(this.paginacaoContratos)
        },
        incrementarPagina() {
            const paginacao = this.getPaginacaoAtual()
            this.setPaginacaoContrato({
                page: paginacao.page + 1
            })
        },
        prepararNovosContratos(novosContratos) {
            novosContratos.forEach(contrato => {
                this.construirTags(contrato)
                this.construirSignatarios(contrato)
            })
        },
        previnirLoopInfinitoContratos() {
            this.meusContratosBusca.ultimaPaginaBusca = true
        },
        setPaginacaoContrato(paginacao) {
            const paginacaoAtual = this.getPaginacaoAtual()
            this.setPaginacaoBuscaContratos(Object.assign({}, paginacaoAtual, paginacao))
        },
        resetarContratos(){
            this.resetarTotalContratos()
            this.resetarContratosContent()
        },
        resetarContratosContent() {
            this.meusContratosBusca.ultimaPaginaBusca = false
            this.setMeusContratos([])
            this.setPaginacaoContrato({
                page: 0
            })
        },
        resetarTotalContratos() {
            this.$store.commit(mutationTypes.SET_TOTAL_CONTRATOS_LISTAGEM, null)
        }
    }
}