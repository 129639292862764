import {actionTypes} from '@/commons/constants'

export default {
    methods: {
        async buscarDadosOrganizacao() {
            const dadosOrganizacao = await this.$store.dispatch(actionTypes.ORGANIZACAO.BUSCAR_DADOS_ORGANIZACAO)
            if(!dadosOrganizacao.cep) {
                dadosOrganizacao.cep = ''
            }
            return dadosOrganizacao
        },

        async atualizarOrganizacao(organizacaoId, organizacaoDTO){
            await this.$store.dispatch(actionTypes.ORGANIZACAO.ATUALIZAR, {organizacaoId: organizacaoId, organizacaoDTO: organizacaoDTO })
            this.mostrarNotificacaoSucessoDefault()
        }
    }
}
