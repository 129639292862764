<template>
    <div class="az-options-picker">
        <v-combobox
            :label="getLabel()"
            :items="options"
            :item-text="displayField"
            ref="combobox"
            placeholder="Selecione"
            v-model="newValue"
            v-if="editing"
            @key.enter="add"
            @input="add"
            hide-details
            persistent-placeholder
        />
        <az-text-view :label="getLabel()" text="" v-else/>
        <div class="itens">
            <v-chip v-for="value in selected" :key="getKey(value)" :close="editing" @click:close="remove(value)" class="ml-1 mt-3">
                <v-tooltip top v-if="displayField">
                    <template v-slot:activator="{ on }">
                        <span v-on="on">{{ value[displayField] | azClipText(15) }}</span>
                    </template>
                    {{value[displayField]}}
                </v-tooltip>

                <span v-else>{{ value }}</span>
            </v-chip>
        </div>
    </div>
</template>

<script>
export default {
    name: 'options-picker',
    props: {
        selected: {
            type: Array,
            required: true
        },
        options: {
            type: Array,
            required: true
        },
        displayField: {
            type: String,
            required: false
        },
        label: {
            type: String,
            required: true
        },
        required: {
            type: Boolean,
            default: false
        },
        editing: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            newValue: null
        }
    },
    methods: {
        add() {
            if (typeof this.newValue === 'object' || (typeof this.newValue === 'string' && this.newValue.trim() !== '')) {
                this.$emit('add', this.newValue)
                this.$nextTick(() => {
                    this.newValue = null
                    this.$refs.combobox.blur()
                })
            }
        },
        getLabel() {
            if (this.required) {
                return `${this.label}*`
            }
            return this.label
        },
        getKey(value) {
            if (this.displayField) {
                return value[this.displayField]
            }
            return value
        },
        remove(value) {
            this.$emit('remove', value)
        }
    }
}
</script>
<style lang="stylus">
.az-options-picker
    .itens
        float left
        margin-top 10px

</style>
