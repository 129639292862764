<template>
    <div class="co-pub-design pb-3">
        <div :style="'background-color: ' + getPrimaryColor"
             class="pre-visualizar-cabecalho d-flex justify-space-between">

            <div class="d-flex ml-2">
                <v-icon :color="getSecondaryColor" size="32px">
                    mdi-file-sign
                </v-icon>

                <div class="ml-2" style="display: grid">
                    <div :style="'color: ' + getSecondaryColor"
                         class="titulo d-flex align-center">
                        TESTE
                    </div>

                    <div :style="'color: ' + getSecondaryColor"
                         class="subtitulo d-flex align-center">
                        Assinar como: Contratante
                    </div>
                </div>
            </div>


            <div class="logotipo d-flex align-center">
                <img :src="getLogo" class="logo" height="64">
            </div>
        </div>

        <div :style="'color: ' + getPrimaryColor" class="d-flex justify-center mt-2 title">
            Texto de destaque
        </div>

        <div class="d-flex justify-center mt-2">
            <v-btn :color="getPrimaryColor" class="pre-visualizar-botao">
                <v-icon :color="getSecondaryColor" class="mr-2" size="15"> mdi-gesture</v-icon>

                <span :style="'color: ' + getSecondaryColor">
                    Assinar
                </span>
            </v-btn>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'PubDesign',
    props: {
        colors: {
            type: Object,
            required: true
        },
        defaultColors: {
            type: Object,
            required: true
        },
        logo: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState(['loki']),
        getLogo() {
            if (!!this.logo) {
                return this.logo
            } else {
                return this.loki.product.mainLogo
            }
        },
        getPrimaryColor() {
            return this.disabled ? this.defaultColors.primary : this.colors.primary.color
        },
        getSecondaryColor() {
            return this.disabled ? this.defaultColors.secondary : this.colors.secondary.color
        }
    }
}
</script>

<style lang="stylus">
.co-pub-design
    width 100%
    border 1px solid #c7c7c7
    border-radius: 5px

    .pre-visualizar-cabecalho
        border-radius 5px 5px 0 0

        .titulo
            font-size 16px
            font-weight bold

        .subtitulo
            font-size 12px

    .pre-visualizar-botao
        cursor default
</style>
