<template>
    <div class="co-novo-documento-signatario-rejeicao-documento">
        <div class="checkbox-rejeicao-documento" @click="value.rejeitarDocumento = !value.rejeitarDocumento">
            <span class="checkbox-texto">
                Rejeição do documento
            </span>
            <icon-info :descricao-campo="descricaoCampo"/>
            <v-spacer/>
            <v-switch
                v-model="value.rejeitarDocumento"
                name="rejeicao"
                @change="value.rejeitarDocumento = !value.rejeitarDocumento"
            />
        </div>
    </div>
</template>

<script>
import IconInfo from '@/views/criar-documento/novo-documento-signatario/components/IconInfo'

export default {
    name: 'RejeicaoDocumentoNovo',
    components: {IconInfo},
    props: {
        value: {
            required: true
        }
    },
    computed: {
        descricaoCampo() {
            return `Permite ao ${this.$tc("pt_br.signatario").toLowerCase()} rejeitar o documento`
        }
    }
}
</script>

<style lang="stylus">
.co-novo-documento-signatario-rejeicao-documento
    width 100%

    .checkbox-rejeicao-documento
        display flex
        align-items center
        cursor pointer

        .checkbox-texto
            font-size 14px
            display flex
</style>