<template>
    <div class="co-pasta-compartilhamento-acoes d-flex justify-center">
        <v-col sm="9">
            <v-autocomplete
                flat
                clearable
                hide-details
                dense
                multiple
                chips
                label="Adicionar Usuário"
                placeholder="Buscar por nome"
                item-value="usuarioId"
                item-text="usuarioNome"
                :items="usuariosSemAcesso"
                v-model="usuariosSemAcessoId"
            ></v-autocomplete>
        </v-col>
        <v-col sm="3" class="d-flex justify-end align-end">
            <v-btn depressed color="primary"
                   :disabled="possuiSelecao"
                   @click="atribuirAcesso">
                Adicionar
            </v-btn>
        </v-col>

    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'PastaCompartilhamentoAcoes',
    data() {
        return {
            usuariosSemAcessoId: []
        }
    },
    props: {
        usuariosSemAcesso: {
            required: true
        }
    },
    computed: {
        ...mapState(['login']),
        possuiSelecao() {
            return this.usuariosSemAcessoId.length === 0
        }
    },
    methods: {
        atribuirAcesso() {
            const usuariosId = this.usuariosSemAcessoId
            this.usuariosSemAcessoId = []
            return this.$emit('atribuirAcesso', usuariosId)
        },
    }
}
</script>

<style lang="stylus">
.co-pasta-compartilhamento-acoes
    width 100%
    border-top 1px solid #eee
    margin-top 10px
    padding-top 10px
    padding-bottom 20px
    display flex
</style>