<template>
    <v-col class="co-cartao-credito-fisico" cols="6">
        <div class="flipper">
            <div class="front">
                <div class="ma-3">
                    <v-row no-gutters>
                        VISA
                    </v-row>

                    <v-row no-gutters>
                        <input
                            readonly
                            class="creditcard__number my-3"
                            v-model="dadosCartao.numeroCartao"
                            placeholder="0000-0000-0000-0000"
                            v-mask="'####-####-####-####'"
                        />
                    </v-row>

                    <v-row no-gutters>
                        <input
                            readonly
                            class="creditcard__text__nome mr-2"
                            v-model="dadosCartao.nomeCartao"
                            placeholder="Nome impresso no cartão"
                        />

                        <input
                            readonly
                            class="creditcard__text__validade"
                            v-model="dadosCartao.validadeCartao"
                            placeholder="mm/aa"
                        />
                    </v-row>
                </div>
            </div>

            <div class="back">
                <v-row no-gutters class="mt-2">
                    <div class="black-bar"/>
                </v-row>

                <div class="pa-3">
                    <v-row no-gutters>
                        <div class="align-self-center">CSV</div>
                        <input
                            readonly
                            class="creditcard__number mx-2"
                            v-model="dadosCartao.codSegurancaCartao"
                            placeholder="000"/>
                    </v-row>
                </div>
            </div>
        </div>
    </v-col>
</template>

<script>
export default {
    name: 'CartaoCreditoFisico',
    props: {
        dadosCartao: {
            type: Object,
        },
    },
    mounted() {
        const inputCsv = document.getElementById('csv')
        const mostrarCartaoVerso = function () {
            document.getElementsByClassName('flipper')[0].style.transform = 'rotateY(180deg)'
        }
        const mostrarCartaoFrente = function () {
            document.getElementsByClassName('flipper')[0].style.transform = ''
        }
        inputCsv.addEventListener('focusin', mostrarCartaoVerso)
        inputCsv.addEventListener('focusout', mostrarCartaoFrente)

    }
}
</script>

<style lang="stylus">
.co-cartao-credito-fisico
    width 190px
    height 121px

    .flipper
        width 100%
        height 100%
        transition transform 0.8s
        transform-style preserve-3d
        border 1px solid #c7c7c7
        border-radius 5px

        .front, .back
            position absolute
            width 100%
            height 100%
            backface-visibility hidden
            background-color #F1F4F7
            border-radius 5px

        .back
            transform rotateY(180deg)

            .black-bar
                background-color black
                height 25px
                width 100%

        .creditcard
            cursor default
            &__text
                color #6F6F6F
                font-size 11px

                &__nome
                    width 70%
                    text-overflow ellipsis

                &__validade
                    width 25%
                    text-overflow ellipsis

            &__number
                color #6F6F6F
                font-size 15px
                width 100% !important


</style>
