<template>
    <div class="co-filtro-botoes-acoes">
        <v-btn color="primary" @click="filtrar" large>
            Aplicar
        </v-btn>
        <v-btn color="text-color-100" text @click="limpar" large class="mt-3">
            Limpar Filtro
        </v-btn>
    </div>
</template>

<script>
import {mutationTypes} from '@/commons/constants'

export default {
    name: 'BotoesAcoes',
    methods: {
        filtrar() {
            this.$emit('filtrar')
        },
        async limpar() {
            await this.$store.commit(mutationTypes.FILTROS.LIMPAR_FILTROS_BUSCA)
            this.$emit('filtrar')
        }
    }
}
</script>

<style lang="stylus">
.co-filtro-botoes-acoes
    display flex
    flex-direction column
    width 95%
    position absolute
    bottom 10px
</style>