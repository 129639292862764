<template>
    <v-dialog value="true" persistent width="850">
        <v-card class="co-visualizar-anexo">
            <v-app-bar flat dark color="primary" class="co-detalhe-contrato-toolbar">
                <span class="headline titulo-anexo">{{ nome }}</span>
                <v-spacer></v-spacer>
                <v-icon @click="$emit('fechar')"> mdi-window-close </v-icon>
            </v-app-bar>
            <embed :src="path" width="850" height="375"
                   type="application/pdf">
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'VisualizarAnexo',
    props: ['anexo', 'contratoId', 'minutaId'],
    data: () => {
        return {
            path: ''
        }
    },
    mounted() {
        this.buscarPath()
    },
    computed: {
        nome() {
            return this.anexo.nome
        }
    },
    methods: {
        buscarPath() {
            this.path = `api/contratos/${this.contratoId}/minutas/${this.minutaId}/anexos/${this.anexo.anexoId}`
        }
    }
}
</script>

<style lang="stylus">
.co-visualizar-anexo
    .titulo-anexo
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
</style>
