<template>
    <v-card class="co-novo-documento-signatario-card-dados-principais pa-5">
        <v-card-text class="pa-0">
            <tipo-assinatura
                :signatario="signatario"
                :exibir-signatario-completo="exibirSignatarioCompleto"
                :tipos-assinatura="tiposAssinatura"
                @validaTipoWhatsapp="validaTipoWhatsapp"
            />

            <div :class="exibirSignatarioCompleto ? '' : 'dado-principal'">
                <email-signatario-novo
                    v-if="signatario.tipoAssinatura !== 'WHATSAPP'"
                    v-model="signatario"
                    :signatarios-sugestoes="signatariosSugestoes"
                    campo-requerido
                    descricao/>

                <telefone-signatario-novo
                    v-if="signatario.tipoAssinatura === 'WHATSAPP'"
                    v-model="signatario"
                    campo-requerido
                    descricao/>

                <template v-if="!exibirSignatarioCompleto">
                    <v-spacer/>

                    <v-btn icon :disabled="podeExcluirSignatario"
                           @click="remover(signatario)">
                        <v-icon size="22"> mdi-delete </v-icon>
                    </v-btn>
                </template>
            </div>

            <v-divider class="mt-3"/>
        </v-card-text>

        <v-card-actions v-show="exibirSignatarioCompleto" class="mt-3 py-0">
            <papel-signatario-novo
                v-model="signatario"
                :papeis="papeisSignatario"
                campo-requerido
                descricao/>

            <v-spacer/>

            <v-btn icon :disabled="podeExcluirSignatario"
                   @click="remover(signatario)">
                <v-icon size="22"> mdi-delete </v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import EmailSignatarioNovo
    from '@/views/criar-documento/novo-documento-signatario/components/dados-principais/components/EmailSignatarioNovo'
import PapelSignatarioNovo
    from '@/views/criar-documento/novo-documento-signatario/components/dados-principais/components/PapelSignatarioNovo'
import TelefoneSignatarioNovo
    from '@/views/criar-documento/novo-documento-signatario/components/dados-principais/components/TelefoneSignatarioNovo'
import TipoAssinatura
    from '@/views/criar-documento/novo-documento-signatario/components/dados-principais/components/TipoAssinatura'

export default {
    name: 'CardDadosPrincipais',
    components: {
        TipoAssinatura,
        EmailSignatarioNovo, PapelSignatarioNovo, TelefoneSignatarioNovo
    },
    props: {
        exibirSignatarioCompleto: {
            type: Boolean,
            default: true
        },
        podeExcluirSignatario: {
            type: Boolean,
            default: false
        },
        signatario: {
            required: true,
            type: Object
        },
        signatariosSugestoes: {
            type: Array,
        },
        papeisSignatario: {
            type: Array
        },
        tiposAssinatura: {
            type: Array
        }
    },
    computed: {
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        }
    },
    methods: {
        remover() {
            this.$emit('remover', this.signatario)
        },
        validaTipoWhatsapp() {
            this.$emit('validaTipoAssinatura')
        }
    }
}
</script>

<style lang="stylus">
.co-novo-documento-signatario-card-dados-principais
    .dado-principal
        display flex

</style>