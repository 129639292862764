<template>
    <div class="co-contrato-tags-novo">
        <v-flex v-if="!ehTagsVazio">
            <span v-for="(tag, index) in tagsSelecionadas()" :key="index" class="mr-2">
                <v-chip text-color="primary"
                        class="text tag-chips"
                        dense>
                    {{ tag | azClipText(qtdCaracteresMax) }}
                </v-chip>
            </span>

            <v-chip v-if="qtdItensNaoSelecionados > 0" text-color="primary" class="text tag-chips" dense>
                +{{ qtdItensNaoSelecionados }}
            </v-chip>
        </v-flex>

        <span v-else class="text">
            Não possui TAGs
        </span>
    </div>
</template>

<script>
export default {
    name: 'ContratoTagsNovo',
    props: {
        value: {
            type: Array,
            require: true
        },
        qtdCaracteresMax: {
            default: 0
        },
        quantidadeTags: {
            type: Function
        }
    },
    computed: {
        qtdItensNaoSelecionados() {
            return this.retornaListaTag.length - this.quantidadeTags()
        },
        ehTagsVazio() {
            return this.retornaListaTag.length === 0
        },
        retornaListaTag(){
            const obj = JSON.parse(this.value[0]);
            return obj.map(t => t.nome);
        }
    },
    methods: {
        tagsSelecionadas() {
            return this.retornaListaTag.slice(0, this.quantidadeTags())
        }
    }
}
</script>

<style lang="stylus">
.co-contrato-tags-novo
    white-space nowrap
    display flex

    .tag-chips
        background-color var(--background-300) !important

    .text
        font-size 12px
        font-weight 100
        color var(--primary)

    .v-chip
        cursor pointer

    .v-chip::before
        background-color var(--background-300)

</style>
