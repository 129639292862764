<template>
    <div>
        <form enctype="multipart/form-data" novalidate>
            <div class="clm-drop-file"
                 :style="{'height': height}"
                 @drop.prevent="receberArquivo($event.dataTransfer.items)"
                 @dragover.prevent="">
                <input id="clmFileSelector" type="file" class="input-file"
                       :name="uploadFieldName"
                       :accept="accept"
                       @change="receberArquivo($event.target.files)"/>
                <div class="center">
                    <slot></slot>
                </div>
            </div>
        </form>
    </div>
</template>

<script>

export default {
    name: 'ClmFileUpload',
    props: {
        accept: {
            type: String,
            default: '*'
        },
        height: {
            type: String,
            default: '200px'
        }
    },
    data() {
        return {
            uploadFieldName: 'file',
            arquivoBase64: '',
            tamanhoMaximoNomeArquivo: 180
        }
    },
    methods: {
        receberArquivo(eventItems) {
            if (this.validarSelecaoArquivo(eventItems)) {
                this.mostrarNotificacaoErro("Nenhum arquivo selecionado. Por favor, selecione um arquivo.")
                return
            }
            const file = this.getArquivoSelecionado(eventItems)
            if (!this.ehExtensaoArquivoValida(file.name)) {
                this.mostrarNotificacaoErro('Extensão do arquivo inválida. Por favor, faça upload de um arquivo .pdf')
                return
            }
            if (!this.ehTamanhoArquivoPermitido(file)) {
                this.mostrarNotificacaoErro(`Tamanho de arquivo não permitido. Arquivo selecionado deve ter no máximo ${this.$store.state.loki.file.maxSize}.`)
                return
            }

            if (!this.ehArquivoComNomePermitido(file)) {
                this.mostrarNotificacaoErro(`Nome do arquivo muito grande. Por favor, renomei o arquivo para conter no máximo ${this.tamanhoMaximoNomeArquivo} caracteres.`)
                return
            }
            this.emitirArquivoSelecionado(file)
        },
        emitirArquivoSelecionado(file) {
            this.$emit('arquivoSelecionado', file)
        },
        validarSelecaoArquivo(eventItems) {
            return !Object.keys(eventItems).length
        },
        getArquivoSelecionado(eventItems) {
            const files = []
            Object.keys(eventItems).forEach((key) => {
                const item = eventItems[key]
                if (item.kind != undefined && item.kind === 'file') {
                    files.push(item.getAsFile())
                } else {
                    files.push(item)
                }
            })
            return files[0]
        },
        openFileSelector() {
            document.getElementById('clmFileSelector').click()
        },
        ehArquivoComNomePermitido(file) {
            return file.name.length < this.tamanhoMaximoNomeArquivo
        },
        ehExtensaoArquivoValida(nomeArquivo) {
            const extensao = nomeArquivo.substr(nomeArquivo.lastIndexOf('.'), nomeArquivo.length)
            return extensao === '.pdf' || extensao === '.PDF' || this.accept === '*'
        },
        ehTamanhoArquivoPermitido(file) {
            const {maxSize, unit} = this.getMaxSizeConfig()
            const fileSize = this.calcFileSize(file.size, unit)
            return !(fileSize > parseInt(maxSize))
        },
        calcFileSize(bytes, unit) {
            const k = 1024
            return parseFloat((bytes / Math.pow(k, unit)).toFixed(2))
        },
        getMaxSizeConfig() {
            const maxSizeFile = this.$store.state.loki.file.maxSize
            const maxSize = maxSizeFile.replace(/([A-Za-z]+)/g, '')
            const unit = ['B', 'KB', 'MB', 'GB', 'TB'].indexOf(maxSizeFile.replace(/\d+/g, '').toUpperCase())
            return {maxSize, unit}
        }
    }
}
</script>

<style lang="stylus">
.clm-drop-file
    border: 2px dashed #ccc
    margin: 10px 0
    padding: 30px 0 20px
    align-content: center
    align-items: center
    text-align: center
    background-color: #eeeeee
    color: #7b7b7b
    display: flex
    position: relative

    &:hover
        border-color: #939393

    .center
        margin: 0 auto
        padding: 0

    .input-file
        opacity: 0
        position: absolute
        left: 0
        top: 0
        width: 10px
        height: 10px
        z-index: 1

    &__big
        padding: 70px 0 60px !important

    p
        width: 100%
        color: #777
        font-size: 13px
        margin-bottom: 0
        margin-top: 15px

    a
        background-color: #d28a2C
        color: white
        padding: 10px 15px 10px 10px
        border-radius: 2px
        font-size: 13px

        &:hover
            background-color: lighten(#d28a2C, 10%)
            border: 1px solid lighten(#d28a2C, 10%) !important

        i
            color: white !important
            position: relative
            top: 2px
            font-size: 18px
            margin-right: 5px
</style>