<template>
    <elemento-drag class="co-home-pasta" :elemento="pasta" :nome="pasta.pastaNome" icon="mdi-folder">
        <div slot="item" class="d-flex home-pasta align-center">
            <drop class="d-flex align-center home-pasta" @drop="moverPorDrop(pasta, ...arguments)">
                <div class="co-home-pasta px-2 pasta d-flex align-center" @click="entrarPasta(pasta)">
                    <v-icon color="secondary-200" size="22"> mdi-folder</v-icon>

                    <div class="nome-pasta ml-2">
                        <span class="ellipsis"> {{ pasta.pastaNome }} </span>

                        <span class="ml-1"> ({{ pasta.quantidadeContratos }})</span>
                    </div>
                </div>
            </drop>

            <div class="acoes">
                <pasta-info :pasta="pasta"/>

                <notificacao
                    v-show="!ehMobile" 
                    :pasta="pasta"
                    ref="refNotificao"
                />

                <mais-opcoes-pastas
                    :pasta="pasta"
                    @editar-pasta="editarPasta"
                    @excluir-pasta="excluirPasta"
                    @mover-pasta="moverPasta"
                    @compartilhar-pasta="compartilharPasta"
                    @notificacao-pasta="notificacaoPasta"
                />
            </div>
        </div>
    </elemento-drag>

</template>

<script>

import ElementoDrag from '@/views/home/meus-contratos/components/common/ElementoDrag'
import Notificacao from '@/views/home/meus-contratos/common/Notificacao'
import MaisOpcoesPastas from './components/MaisOpcoesPastas'
import PastaInfo from './components/pasta-info/PastaInfo'
import {mapState} from 'vuex'

export default {
    name: 'Pasta',
    components: {
        ElementoDrag,
        PastaInfo,
        MaisOpcoesPastas,
        Notificacao
    },
    props: {
        pasta: {
            type: Object
        },
        ehModoLista: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            mostrarModalDesativarNotificacao: false
        }
    },
    computed: {
        ...mapState(['login']),
        ehItemMinuta() {
            return this.login.funcionalidadesOrganizacao.itemMinuta
        },
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        }
    },
    methods: {
        compartilharPasta(pasta) {
            this.$emit('compartilhar-pasta', pasta)
        },
        editarPasta(pasta) {
            this.$emit('editar-pasta', pasta)
        },
        entrarPasta(pasta) {
            this.$emit('entrar-pasta', pasta)
        },
        excluirPasta(pasta) {
            this.$emit('excluir-pasta', pasta)
        },
        moverPorDrop(pastaDestino, elemento) {
            if (!elemento.pastaId || pastaDestino.pastaId !== elemento.pastaId) {
                return this.$emit('mover-drop', {pastaDestino, elemento})
            }
        },
        moverPasta(pasta) {
            this.$emit('mover-pasta', pasta)
        },
        notificacaoPasta() {
            this.$refs.refNotificao.verificarPerfilUsuarioPasta()
        }
    }
}
</script>

<style lang="stylus">
.co-home-pasta
    cursor pointer
    height 46px
    display flex
    position relative
    width 100%

    .home-pasta
        height 100%
        width 100%

    .pasta
        border 1px solid var(--background-300)
        color var(--text-color-300)
        border-radius 5px

    .nome-pasta
        color var(--text-color-300) !important
        display flex
        align-items center
        max-width calc(100% - 100px)

        .ellipsis
            display block
            align-items center
            text-overflow ellipsis
            white-space nowrap
            overflow hidden
            width 100%

    .acoes
        position absolute
        right 5px
        display flex
</style>