import Vue from 'vue'
import Router from 'vue-router'
import criarRouterGuards from './router-guards'
import {mutationTypes} from '@/commons/constants'
import store from '@/commons/store'
import NovoDocumento from '@/views/criar-documento/novo-documento/NovoDocumento'
import NovoDocumentoSignatario from './views/criar-documento/novo-documento-signatario/SignatarioNovoDocumento'
import LoteDocumentos from './views/contrato/novo/lote-documentos/LoteDocumentos'
import DetalheContrato from './views/contrato/detalhe/DetalheContrato'
import RedirecionaPlanoExpirado from './views/configuracao/plano/RedirecionaPlanoExpirado'
import RedirecionaEditarUsuario from './views/configuracao/perfil/RedirecionaEditarUsuario'
import RedirecionarUsuarioInativo from './views/home/configuracoes/usuarios/RedirecionarUsuarioInativo'
import NovaOrganizacao from './views/configuracao/organizacao/NovaOrganizacao'
import Usuarios from './views/home/configuracoes/usuarios/Usuarios'
import ConfiguracoesSeguranca from '@/views/home/configuracoes/seguranca/ConfiguracoesSeguranca'
import ConfiguracoesNotificacoes from '@/views/home/configuracoes/notificacoes/ConfiguracoesNotificacoes'
import MeusContratos from '@/views/home/meus-contratos/MeusContratos'
import Relatorios from '@/views/home/relatorios/Relatorios'
import Novidades from '@/views/home/novidades/Novidades'
import Sugestoes from '@/views/home/sugestoes/Sugestoes'
import Planos from '@/views/home/planos/Planos'
import Contatos from '@/views/home/configuracoes/contatos/Contatos'
import GruposContatos from '@/views/home/configuracoes/grupos-contatos/GruposContatos'
import ConfiguracaoRouter from '@/views/home/configuracoes/ConfiguracaoRouter'
import Compras from '@/views/home/configuracoes/compras/Compras'
import RenovarDocumento from '@/views/criar-documento/renovar-documento/RenovarDocumento'
import Integracoes from '@/views/home/configuracoes/integracoes/Integracoes'
import ConfiguracoesWhiteLabel from '@/views/home/configuracoes/white-label/ConfiguracoesWhiteLabel'
import Organizacao from './views/home/configuracoes/organizacao/Organizacao'

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            redirect: {name: 'meus'}
        },
        {
            path: '/perfil',
            name: 'redirecionarEditarUsuario',
            component: RedirecionaEditarUsuario
        },
        {
            path: '/documentos',
            name: 'contratos',
            component: MeusContratos,
            meta: {
                menu: {
                    title: 'Documentos',
                    icon: 'mdi-folder-outline'
                },
                verificarNovasNotificacoes: true
            },
            children: [
                {
                    path: '/documentos/meus',
                    name: 'meus',
                    component: MeusContratos,
                    meta: {
                        menu: {
                            title: 'Meus Documentos'
                        },
                        verificarNovasNotificacoes: true
                    },
                    beforeEnter(to, from, next) {
                        let filtros = store.state.meusContratosBusca.filtros
                        filtros.formalizacao.value = 'MEUS'
                        store.commit(mutationTypes.FILTROS.SET_FILTROS_BUSCA, filtros)
                        next()
                    }
                },
                {
                    path: '/documentos/assinado',
                    name: 'assinados',
                    component: MeusContratos,
                    meta: {
                        authorities: ['Filtros.Assinados'],
                        menu: {
                            title: 'Assinados'
                        },
                        verificarNovasNotificacoes: true
                    },
                    beforeEnter(to, from, next) {
                        let filtros = store.state.meusContratosBusca.filtros
                        filtros.formalizacao.value = 'ASSINADOS'
                        store.commit(mutationTypes.FILTROS.SET_FILTROS_BUSCA, filtros)
                        next()
                    }
                },
                {
                    path: '/documentos/parcialmente-assinado',
                    name: 'parcialmenteAssinados',
                    component: MeusContratos,
                    meta: {
                        authorities: ['Filtros.Assinados'],
                        menu: {
                            title: 'Parcialmente Assinado'
                        },
                        verificarNovasNotificacoes: true
                    },
                    beforeEnter(to, from, next) {
                        let filtros = store.state.meusContratosBusca.filtros
                        filtros.formalizacao.value = 'PARCIAL_ASSINADOS'
                        store.commit(mutationTypes.FILTROS.SET_FILTROS_BUSCA, filtros)
                        next()
                    }
                },
                {
                    path: '/documentos/formalizados',
                    name: 'formalizados',
                    component: MeusContratos,
                    meta: {
                        menu: {
                            title: 'Formalizados'
                        },
                        verificarNovasNotificacoes: true
                    },
                    beforeEnter(to, from, next) {
                        let filtros = store.state.meusContratosBusca.filtros
                        filtros.formalizacao.value = 'FORMALIZADO'
                        store.commit(mutationTypes.FILTROS.SET_FILTROS_BUSCA, filtros)
                        next()
                    }
                },
                {
                    path: '/documentos/naoFormalizados',
                    name: 'naoFormalizados',
                    component: MeusContratos,
                    meta: {
                        menu: {
                            title: 'Não Formalizados'
                        },
                        verificarNovasNotificacoes: true
                    },
                    beforeEnter(to, from, next) {
                        let filtros = store.state.meusContratosBusca.filtros
                        filtros.formalizacao.value = 'NAO_FORMALIZADO'
                        store.commit(mutationTypes.FILTROS.SET_FILTROS_BUSCA, filtros)
                        next()
                    }
                },
                {
                    path: '/documentos/cancelados',
                    name: 'cancelados',
                    component: MeusContratos,
                    meta: {
                        menu: {
                            title: 'Cancelados'
                        },
                        verificarNovasNotificacoes: true
                    },
                    beforeEnter(to, from, next) {
                        let filtros = store.state.meusContratosBusca.filtros
                        filtros.formalizacao.value = 'CANCELADO'
                        store.commit(mutationTypes.FILTROS.SET_FILTROS_BUSCA, filtros)
                        next()
                    }
                },
                {
                    path: '/documentos/arquivados',
                    name: 'arquivados',
                    component: MeusContratos,
                    meta: {
                        menu: {
                            title: 'Arquivados'
                        },
                        verificarNovasNotificacoes: true
                    },
                    beforeEnter(to, from, next) {
                        let filtros = store.state.meusContratosBusca.filtros
                        filtros.formalizacao.value = 'ARQUIVADOS'
                        store.commit(mutationTypes.FILTROS.SET_FILTROS_BUSCA, filtros)
                        next()
                    }
                },
                {
                    path: '/documentos/extintos',
                    name: 'extintos',
                    component: MeusContratos,
                    meta: {
                        authorities: ['Contrato.Extinguir'],
                        menu: {
                            title: 'Extintos'
                        },
                        verificarNovasNotificacoes: true
                    },
                    beforeEnter(to, from, next) {
                        let filtros = store.state.meusContratosBusca.filtros
                        filtros.formalizacao.value = 'EXTINTOS'
                        store.commit(mutationTypes.FILTROS.SET_FILTROS_BUSCA, filtros)
                        next()
                    }
                },
            ]
        },
        {
            path: '/relatorios',
            name: 'relatorios',
            component: Relatorios,
            meta: {
                menu: {
                    title: 'Relatórios',
                    icon: 'mdi-file-document-outline'
                },
                verificarNovasNotificacoes: true
            }
        },
        {
            path: '/planos',
            name: 'planos',
            component: Planos,
            meta: {
                menu: {
                    title: 'Planos',
                    icon: 'mdi-currency-usd'
                },
                verificarNovasNotificacoes: true
            },
            beforeEnter(to, from, next) {
                if (store.getters.ehEfcazCLM) {
                    return next({path: '/'})
                }

                next()
            }
        },
        {
            path: '/configuracoes',
            name: 'configuracaoRouter',
            component: ConfiguracaoRouter,
            meta: {
                menu: {
                    title: 'Configurações',
                    icon: 'mdi-cog-outline'
                },
                verificarNovasNotificacoes: true
            },
            children: [
                {
                    path: '/configuracoes/contatos',
                    name: 'contatos',
                    component: Contatos,
                    meta: {
                        authorities: ['CadastroSignatario.Editar'],
                        menu: {
                            title: 'Contatos'
                        },
                        verificarNovasNotificacoes: true
                    }
                },
                {
                    path: '/configuracoes/grupos-contatos',
                    name: 'gruposContatos',
                    component: GruposContatos,
                    meta: {
                        authorities: ['GrupoSignatarios.Editar'],
                        menu: {
                            title: 'Grupos de Contatos'
                        },
                        verificarNovasNotificacoes: true
                    }
                },
                {
                    path: '/configuracoes/usuarios',
                    name: 'usuarios',
                    component: Usuarios,
                    meta: {
                        authorities: ['Usuario.Novo', 'Usuario.Editar', 'Usuario.AtivarInativar'],
                        menu: {
                            title: 'Usuários'
                        }
                    }
                },
                {
                    path: '/configuracoes/integracoes',
                    name: 'integracoes',
                    component: Integracoes,
                    meta: {
                        authorities: ['Webhook.Editar'],
                        menu: {
                            title: 'Integrações'
                        },
                        verificarNovasNotificacoes: true
                    }
                },
                {
                    path: '/configuracoes/compras',
                    name: 'compras',
                    component: Compras,
                    meta: {
                        menu: {
                            title: 'Compras'
                        },
                        verificarNovasNotificacoes: true
                    }
                },
                {
                    path: '/configuracoes/seguranca',
                    name: 'seguranca',
                    component: ConfiguracoesSeguranca,
                    meta: {
                        authorities: ['Painel.Geral.Visualizar'],
                        menu: {
                            title: 'Segurança'
                        }
                    }
                },
                {
                    path: '/configuracoes/notificacoes',
                    name: 'notificacoes',
                    component: ConfiguracoesNotificacoes,
                    meta: {
                        authorities: ['Painel.Notificacao.Visualizar'],
                        menu: {
                            title: 'Notificações'
                        }
                    }
                },
                {
                    path: '/configuracoes/white-label',
                    name: 'whiteLabel',
                    component: ConfiguracoesWhiteLabel,
                    meta: {
                        authorities: ['WhiteLabel.Visualizar'],
                        menu: {
                            title: 'White Label'
                        }
                    }
                },
                {
                    path: '/configuracoes/organizacao',
                    name: 'organizacao',
                    component: Organizacao,
                    meta: {
                        authorities: ['Organizacao.Editar'],
                        menu: {
                            title: 'Organização'
                        }
                    }
                },
            ]
        },
        {
            path: '/novidades',
            name: 'novidadeds',
            component: Novidades,
            meta: {
                authorities: ['BeSign.Novidades'],
                menu: {
                    title: 'Novidades',
                    icon: 'mdi-bullhorn-outline'
                },
                verificarNovasNotificacoes: true
            }
        },
        {
            path: '/sugestoes',
            name: 'sugestoes',
            component: Sugestoes,
            meta: {
                authorities: ['BeSign.Sugestoes'],
                menu: {
                    title: 'Sugestões',
                    icon: 'mdi-lightbulb-on-outline'
                },
                verificarNovasNotificacoes: true
            }
        },
        {
            path: '/documentos/novo/:tipoDefault',
            name: 'novoDocumento',
            component: NovoDocumento,
            meta: {
                authorities: ['Contrato.Novo']
            },
        },
        {
            path: '/documentos/novo/signatarios/:id/:tipoDefault',
            name: 'novoDocumentoSignatario',
            component: NovoDocumentoSignatario,
            meta: {
                authorities: ['Contrato.Novo']
            }
        },
        {
            path: '/documentos/:contratoId/renovacao',
            name: 'renovarDocumento',
            component: RenovarDocumento,
            meta: {
                authorities: ['Contrato.Renovar']
            }
        },
        {
            path: '/documentos/lote',
            name: 'loteDocumentos',
            component: LoteDocumentos,
            meta: {
                authorities: ['Contrato.Lote']
            }
        },
        {
            path: '/documentos/:id',
            name: 'detalheContrato',
            component: DetalheContrato,
            meta: {
                authorities: ['Contrato.Visualizar', 'Contrato.Editar']
            }
        },
        {
            path: '/planoExpirado',
            name: 'planoExpirado',
            component: RedirecionaPlanoExpirado
        },
        {
            path: '/usuarioInativo',
            name: 'usuarioInativo',
            component: RedirecionarUsuarioInativo
        },
        {
            path: '/primeiroAcesso',
            name: 'primeiroAcesso',
            component: NovaOrganizacao,
            beforeEnter(to, from, next) {
                if (store.state.login.organizacaoPlano.organizacaoExistente) {
                    return next({path: '/'})
                }
                next()
            }
        }
    ]
})

criarRouterGuards(router, store)

export default router
