<template>
    <div v-if="ehSemFuncionalidade" class="co-configuracoes-exclusivas mt-5">
        <v-row no-gutters class="plano">
            <span class="plano">
                Mude para o Plano PRO e tenha acesso a TODAS as Configurações!!
                <img class="coroa" src="../../../../assets/icons/crown_vector.svg" height="20" width="20" alt="coroa"/>
            </span>
        </v-row>

        <v-row v-if="!ehEfcazCLM" no-gutters class="plano sub-titulo">
            <p>Conheça nossos planos
                <a class="destaque" @click.prevent.stop="abrirLoja">
                    CLICANDO AQUI
                </a>
            </p>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';


export default {
    name: 'ConfiguracoesExclusivas',
    props: {
        ehSemFuncionalidade: {
            type: Boolean,
            default: true,
            required: true
        }
    },
    computed: {
        ...mapGetters(['ehEfcazCLM']),
    },
    methods: {
        abrirLoja() {
            this.$router.push({name: 'planos'})
        }
    }
}
</script>

<style lang="stylus">
.co-configuracoes-exclusivas
    .titulo
        color #777

    .plano
        font-size 18px
        display flex
        justify-content center
        text-align center
        color var(--v-primary-base)

    .sub-titulo
        font-size 14px

    .coroa
        margin-left 5px

    .destaque
        font-weight bold
        color green
        transition all 500ms
        cursor pointer

    .destaque:hover
        background rgba(240, 240, 240, .8)
        color lighten(green, 20%)


</style>
