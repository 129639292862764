<template>
    <div class="co-novo-meus-contratos-barra-busca-modo-exibicao pr-3 d-flex">
        <v-col class="exibir ml-3">
            <div class="texto">Exibir</div>
        </v-col>

        <v-col class="icone">
            <v-tooltip bottom class="no-mobile">
                <template v-slot:activator="{ on }">
                    <v-icon :class="visualizacaoListaStyle"
                            v-on="on"
                            @click="mudarLayoutVisualizacao('LISTA')">
                        mdi-view-list
                    </v-icon>
                </template>
                <span>Exibir em modo lista</span>
            </v-tooltip>
        </v-col>

        <v-col class="icone">
            <v-tooltip bottom class="no-mobile">
                <template v-slot:activator="{ on }">
                    <v-icon :class="visualizacaoCardStyle"
                            v-on="on"
                            @click="mudarLayoutVisualizacao('CARD')">
                        mdi-view-comfy
                    </v-icon>
                </template>
                <span>Exibir em modo grade</span>
            </v-tooltip>
        </v-col>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {actionTypes, mutationTypes} from '@/commons/constants'

export default {
    name: "ModoExibicao",
    computed: {
        ...mapState(['meusContratosBusca', 'preferenciasUsuario']),
        visualizacaoListaStyle() {
            const layout = this.preferenciasUsuario.layoutLista
            return (layout === 'LISTA') ? 'ativo' : 'inativo'
        },
        visualizacaoCardStyle() {
            const layout = this.preferenciasUsuario.layoutLista
            return (layout === 'CARD') ? 'ativo' : 'inativo'
        }
    },
    methods: {
        mudarLayoutVisualizacao(layout) {
            this.$store.commit(mutationTypes.PREFERENCIAS_USUARIO.SET_LAYOUT_LISTA, layout)
            this.$store.dispatch(actionTypes.USUARIO.ATUALIZAR_PREFERENCIAS_USUARIO, this.preferenciasUsuario)
        },
    }
}
</script>

<style lang="stylus">
.co-novo-meus-contratos-barra-busca-modo-exibicao
    height 44px
    position relative
    align-items center

    .col
        padding 0

    .exibir
        display flex
        align-self center
        max-width 50px

        .texto
            color var(--primary)

    .icone
        cursor pointer
        max-width 40px
        max-height 50px

        .inativo
            color var(--secondary-100)
            margin-left 5px
            font-size 30px

        .ativo
            color var(--primary)
            margin-left 5px
            font-size 30px

</style>