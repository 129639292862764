<template>
    <v-flex class="co-dentro-pasta">
        <div class="acoes mb-1">
            <v-breadcrumbs class="ml-5" :items="pastasBreadcrumbs" divider="/">
                <template v-slot:item="props">
                    <v-breadcrumbs-item @click="irParaPasta(props.item.id)">
                        <drop class="d-flex align-center" @drop="moverPorDrop(props.item.id, ...arguments)">
                            <span class="pasta"> {{ props.item.text }} {{ props.item.quantidadeContratos }} </span>
                        </drop>
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>

            <v-tooltip top v-az-auth="'Pasta.Editar'">
                <template v-slot:activator="{ on }">
                    <v-icon class="ml-3" v-on="on" size="22" color="secondary-200" @click="abrirDialogPasta">
                        mdi-pencil
                    </v-icon>
                </template>
                <span>Editar Pasta</span>
            </v-tooltip>

            <v-tooltip top v-if="ehAdministradorOrganizacao" v-az-auth="'Pasta.Compartilhar'">
                <template v-slot:activator="{ on }">
                    <v-icon class="ml-3" v-on="on" size="22" color="secondary-200" @click="abrirDialogCompartilharPasta">
                        mdi-account-multiple
                    </v-icon>
                </template>
                <span>Editar Permissões</span>
            </v-tooltip>

            <notificacao class="ml-3" :pasta="pastaAtual"/>
        </div>

        <v-divider />

        <dialog-pasta
            v-if="dialogPasta"
            :dialog="dialogPasta"
            :pasta-editar="pastaAtual"
            :pasta-pai="getPastaPai()"
            @fechar="fecharDialogPasta"
            editar
        />

        <dialog-pasta-compartilhamento
            v-if="dialogCompartilharPasta"
            :dialog="dialogCompartilharPasta"
            :pasta="pastaAtual"
            @fechar="fecharDialogCompartilharPasta"
        />

        <dialog-mover-elemento
            v-if="dialogMover.abrirDialog"
            :dialog="dialogMover.abrirDialog"
            :elemento="dialogMover.elemento"
            :destino="dialogMover.destino"
            @fechar="fecharDialoglMover"
        />
    </v-flex>
</template>

<script>
import Notificacao from '@/views/home/meus-contratos/common/Notificacao'
import DialogPastaCompartilhamento from './components/dialogs/DialogPastaCompartilhamento'
import DialogMoverElemento from '@/views/home/meus-contratos/components/common/DialogMoverElemento'
import DialogPasta from '@/views/common/dialogs/DialogPasta'
import Utils from '@/commons/utils/Utils'
import {mutationTypes} from '@/commons/constants'
import {mapGetters, mapState} from 'vuex'

export default {
    name: 'HeaderDentroPasta',
    components: {DialogMoverElemento, DialogPastaCompartilhamento, DialogPasta, Notificacao},
    data() {
        return {
            dialogCompartilharPasta: false,
            dialogPasta: false,
            dialogMover: {
                abrirDialog: false,
                elemento: null,
                destino: null
            }
        }
    },
    computed: {
        ...mapState(['pastaAtual', 'pastaBreadcrumbs', 'login']),
        ...mapGetters(['podeCriarContratoRaiz', 'ehAdministradorOrganizacao']),
        pastasBreadcrumbs() {
            const pastasBreadcrumbs = []
            this.pastaBreadcrumbs.forEach(pasta => {
                pastasBreadcrumbs.push({
                    id: pasta.pastaId,
                    text: pasta.pastaNome ? pasta.pastaNome : this.login.organizacaoPlano.organizacaoNome
                })
            })
            return pastasBreadcrumbs
        },
    },
    methods: {
        abrirDialogCompartilharPasta() {
            this.dialogCompartilharPasta = true
        },
        abrirDialogConfirmarMoverPasta(pastaDestino, elemento) {
            this.dialogMover.abrirDialog = true
            this.dialogMover.elemento = elemento
            this.dialogMover.destino = pastaDestino
        },
        abrirDialogPasta() {
            this.dialogPasta = true
        },
        buscarPastaInBreadcrumbs(pastaId) {
            return this.pastaBreadcrumbs.find(item => item.pastaId === pastaId)
        },
        ehContrato(elemento) {
            return Utils.possuiValor(elemento.contratoId)
        },
        ehMesmaPasta(pastaId) {
            return pastaId ? this.pastaAtual.pastaId === pastaId : false
        },
        fecharDialogCompartilharPasta() {
            this.dialogCompartilharPasta = false
        },
        fecharDialoglMover() {
            this.dialogMover.abrirDialog = false
            this.dialogMover.elemento = null
            this.dialogMover.destino = null
        },
        fecharDialogPasta() {
            this.dialogPasta = false
        },
        getPastaPai() {
            return this.pastaBreadcrumbs[this.pastaBreadcrumbs.length - 1]
        },
        irParaPasta(pastaId) {
            this.$store.commit(mutationTypes.PASTAS.VOLTAR_PARA_PASTA, pastaId)
        },
        moverPorDrop(pastaDestinoId, elemento) {
            if (this.ehMesmaPasta(pastaDestinoId)) {
                return
            }
            const pastaDestino = this.buscarPastaInBreadcrumbs(pastaDestinoId)
            if (this.ehContrato(elemento) || pastaDestino.pastaId !== elemento.pastaId) {
                this.abrirDialogConfirmarMoverPasta(pastaDestino, elemento)
            }
        },
        verificarQuantidadeContratosPasta(quantidadeContratos) {
            return quantidadeContratos || 0
        },
    }
}
</script>

<style lang="stylus">
.co-dentro-pasta
    .acoes
        display flex

    .pasta
        font-size 16px

    .v-breadcrumbs
        padding 0 !important
        cursor pointer

        .v-breadcrumbs__item
            color var(--text-color-200) !important
            font-size 13px
</style>