<template>
    <div>
        Extinto
        <v-menu offset-y transition="slide-x-transition" bottom left
                :close-on-content-click=false content-class="az-menu-lista">
            <template v-slot:activator="{ on }">
                <v-btn small icon v-on="on">
                    <v-icon color="white"> mdi-chevron-down</v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-card-text>
                    <v-layout wrap>
                        <v-flex xs12 sm12 md12>
                            <az-text-view
                                label="Justificativa"
                                :text="contrato.descricaoExtincao"/>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                            <az-text-view
                                label="Data da Extinção"
                                :text="contrato.dataExtincao | azDate "/>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                            <az-text-view
                                label="Usuário"
                                :text="contrato.autorExtincaoNome"/>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                            <div class="az-text" v-if="possuiAnexo">
                                <label>Arquivo</label>
                            </div>
                            <a @click="download"> {{ this.contrato.nomeArqOriginalExtincao }} </a>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-menu>
    </div>
</template>
<script>
import {actionTypes} from '@/commons/constants'

export default {
    name: 'VisualizarExtincao',
    props: {
        contrato: {
            required: true
        }
    },
    computed: {
        nomeArquivo() {
            if (this.contrato.arqRespositorioExtincao) {
                return this.contrato.nomeArqOriginalExtincao
            }
            return ''
        },
        possuiAnexo() {
            return this.contrato.arqRespositorioExtincao
        }
    },
    methods: {
        async download() {
            const arquivoBase64 = await this.$store.dispatch(actionTypes.DOWNLOAD_ARQUIVO_EXTINCAO, {contratoId: this.contrato.id})
            this.salvarArquivo(this.contrato.nomeArqOriginalExtincao, arquivoBase64)
        }
    }
}
</script>