export default {
    login: {
        organizacaoPlano: {},
        usuarioOrganizacao: {},
        funcionalidadesOrganizacao: {},
        organizacaoConfiguracao: {},
        usuario: {}
    },
    cadastrosSignatariosBusca: {
        filtros: {
            nome: {
                value: null,
                default: null
            },
        },
        paginacao: {
            page: 0, rowsPerPage: 10, numPages: 0, sortBy: 'nome', descending: false
        }
    },
    carregandoDadosContrato: false,
    contrato: {},
    consumoOrganizacao: {
        organizacaoSemAssinatura: true
    },
    pesquisaFeedback: {
        exigirResposta: false,
        respondido: false
    },
    defaultLoadingMessage: 'Carregando...',
    estados: [],
    fileManagerUri: 'hal/public/arquivos',
    gruposSignatariosBusca: {
        filtros: {
            nome: {
                value: null,
                default: null
            },
        },
        paginacao: {
            page: 0, rowsPerPage: 10, numPages: 0, sortBy: 'nome', descending: false
        }
    },
    historicoContrato: [],
    meusContratos: [],
    meusContratosBusca: {
        filtros: {
            cor: {
                value: '',
                default: ''
            },
            dataCriacaoInicio: {
                value: '',
                default: ''
            },
            dataCriacaoFim: {
                value: '',
                default: ''
            },
            formalizacao: {
                value: '',
                default: 'MEUS'
            },
            nome: {
                value: '',
                default: ''
            },
            pastaId: {
                value: '',
                default: ''
            },
            tags: {
                value: '',
                default: ''
            }
        },
        totalContratosListagem: null,
        ultimaPaginaBusca: false
    },
    minutaAtual: {},
    mobile: false,
    notificacoes: {
        mensagens: [],
        totalNaoLido: 0
    },
    notificacoesBusca: {
        paginacao: {
            page: 0, rowsPerPage: 4, sortBy: 'dataCadastro', descending: false
        }
    },
    organizacao: {},
    paginacaoContratos: {
        page: 1, rowsPerPage: 24, sortBy: 'nomeMinusculo', descending: false
    },
    parametrosSistema: {},
    cookies: {
            filtros: {
                formalizacao: 'MEUS',
            },
        promoCloseCount: 0
    },
    preferenciasUsuario: {
        asideClosed: false,
        layoutLista: 'CARD',
            ordenacao: {
                sortBy: 'nomeMinusculo',
                descending: false
        },
    },
    signatarios: [],
    webhookPendentesBusca: {
        paginacao: {
            page: 0, rowsPerPage: 10, numPages: 0, sortBy: 'id', descending: false
        }
    },
    acaoFiltrar: false,
    acaoOrdenar: false,
    contatoComercial: {
        telefone: '551146734895'
    },
}
