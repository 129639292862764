import axios from 'axios'
import {actionTypes} from '@/commons/constants'

export default {
    async [actionTypes.EMAIL.ENVIAR_EMAIL_COMERCIAL]({state}, email) {
        email.organizacaoId = state.login.organizacaoPlano.organizacaoId
        const {data} = await axios.post(`api/email/comercial`, email)
        return data
    },

}
