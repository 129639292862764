<template>
    <v-container grid-list px-10 pt-10 pb-4 class="co-configuracoes-notificacao">
        <configuracoes-exclusivas :eh-sem-funcionalidade="ehPlanoSemEdicaoConfiguracaoNotificacao"/>

        <notificacoes-usuario
            :eh-plano-sem-edicao-configuracao-notificacao="ehPlanoSemEdicaoConfiguracaoNotificacao"
            v-model="configuracoes.usuario"
        />

        <notificacoes-signatario
            :eh-plano-sem-edicao-configuracao-notificacao="ehPlanoSemEdicaoConfiguracaoNotificacao"
            v-model="configuracoes.signatario"
        />

        <notificacoes-organizacao
            :eh-plano-sem-edicao-configuracao-notificacao="ehPlanoSemEdicaoConfiguracaoNotificacao"
            v-model="configuracoes.organizacao"
        />

        <configuracao-salvar :disabled="ehPlanoSemEdicaoConfiguracaoNotificacao" @salvar="salvar" :loading="loading"/>
    </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import {actionTypes} from '@/commons/constants'
import {hasPermissions} from '@azinformatica/loki'
import NotificacoesOrganizacao from './components/NotificacoesOrganizacao'
import NotificacoesSignatario from './components/NotificacoesSignatario'
import NotificacoesUsuario from './components/NotificacoesUsuario'
import ConfiguracoesExclusivas from '../common/ConfiguracoesExclusivas'
import ConfiguracaoSalvar from '../common/ConfiguracaoSalvar'
import _ from 'lodash'
import store from '@/commons/store'

export default {
    name: 'ConfiguracoesNotificacoes',
    components: {
        ConfiguracaoSalvar,
        NotificacoesUsuario,
        ConfiguracoesExclusivas,
        NotificacoesSignatario,
        NotificacoesOrganizacao
    },
    data() {
        return {
            configuracoes: {
                usuario: {},
                organizacao: {},
                signatario: {}
            },
            loading: false
        }
    },
    async mounted() {
        await this.buscarConfiguracoes()
    },
    computed: {
        ...mapGetters(['ehEfcazCLM', 'ehPlanoFree']),
        ehPlanoSemEdicaoConfiguracaoNotificacao() {
            const userPermissions = this.$store.state.loki.user.authorities
            return !this.ehEfcazCLM && this.ehPlanoFree || !hasPermissions(userPermissions, ['Painel.Notificacao.Editar'])
        }
    },
    methods: {
        async buscarConfiguracoes() {
            this.configuracoes = _.cloneDeep(store.state.login.organizacaoConfiguracao.notificacao)
        },
        async salvar() {
            try {
                this.loading = true
                await this.$store.dispatch(actionTypes.CONFIGURACAO.ATUALIZAR_ORGANIZACAO_CONFIGURACOES_NOTIFICACOES, this.configuracoes)
                this.mostrarNotificacaoSucessoDefault()
            } catch (e) {
                this.mostrarNotificacaoErro("Não foi possível atualizar as configurações da organização!")
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="stylus">
.co-configuracoes-notificacao
    background-color var(--background-100) !important
    width 100%

    .titulo
        height 44px

    .title
        color var(--primary)

    .salvar
        position absolute
        bottom 36px
        left 0
        width 100%
        background-color rgba(249, 249, 249, 0.8)
        backdrop-filter blur(3px)

    .footer
        height 80px

@media (max-width: 720px)
    .co-configuracoes-notificacao
        .salvar
            bottom 0

        .salvar-mobile
            width 80%


</style>
