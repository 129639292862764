<template>
    <div>
        <v-dialog value="true" width="500" persistent>
            <v-card>
                <v-card-text class="co-dialog-alterar-nome">
                    <v-container grid-list-md class="modal-editar-nome">
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-text-field label="Nome" v-model="nomeEdicao" maxlength="255"/>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Autor" disabled v-model="value.autorNome"/>
                            </v-flex>
                            <v-flex xs12>
                                <contrato-tag ref="contratoTag" v-model="tagsEdicao"/>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey lighten-1" text @click="resetarAlteracao">Cancelar</v-btn>
                    <v-btn color="green" text @click="salvar">Confirmar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import ClmDialog from '@/commons/components/ClmDialog'
import ClmOps from '@/commons/components/ClmOps'
import _ from 'lodash'
import ContratoTag from '@/views/contrato/commons/ContratoTag'

export default {
    name: 'dialog-renomear-contratos',
    components: {ContratoTag, ClmDialog, ClmOps},
    props: {
        value: {
            required: true
        },
        nome:{
            type: String,
            required: true
        },
        tags:{
            type: Array,
            required: true
        }
    },
    data() {
        return {
            nomeEdicao: '',
            tagsEdicao: []
        }
    },
    mounted() {
        this.nomeEdicao = _.clone(this.nome)
        this.tagsEdicao = _.clone(this.tags)
    },
    methods: {
        dadosValidos() {
            return this.nomeEdicao && this.nomeEdicao.length > 0
        },
        resetarAlteracao() {
            this.$emit('fechar')
        },
        salvar() {
            if (!this.dadosValidos()) {
                this.mostrarNotificacaoErro('O nome do contrato não pode ser vazio.')
                return
            }
            this.$emit('salvar', this.nomeEdicao, this.tagsEdicao)
            this.resetarAlteracao()
        },
    }
}
</script>

<style lang="stylus">

.co-dialog-alterar-nome
    padding-top 16px !important

    .modal-editar-nome
        background-color white !important
</style>
