<template>
    <div class="co-adquirir-saldo-loading d-flex align-center justify-center">
        <v-progress-circular
            indeterminate
            color="blue-grey"
            :size="200"
            :width="10"
        />
    </div>
</template>

<script>

export default {
    name: 'LojaLoading',
    components: {},
}
</script>

<style lang="stylus">
.co-adquirir-saldo-loading
    background-color black
    opacity 0.3
    position absolute
    top 0
    left 0
    height 100%
    width 100%


</style>