<template>
    <div class="co-rejeicao-documento">
        <div class="checkbox-rejeicao-documento" @click="value.rejeitarDocumento = !value.rejeitarDocumento">
            <input
                v-model="value.rejeitarDocumento"
                name="rejeicao"
                type="checkbox"
                style="cursor: pointer"
            />

            <span class="checkbox-texto">
                Rejeição do documento
            </span>
        </div>

        <div class="hint-checkbox">
            {{ descricaoCampo }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'RejeicaoDocumento',
    props: {
        value: {
            required: true
        }
    },
    computed: {
        descricaoCampo() {
            return `Permite ao ${this.$tc("pt_br.signatario").toLowerCase()} rejeitar o documento`
        }
    }
}
</script>

<style lang="stylus">
.co-rejeicao-documento
    .checkbox-rejeicao-documento
        display flex
        align-items center
        cursor pointer

        .checkbox-texto
            font-size 14px
            display flex
            margin-left 10px
            color #777777
            text-align right
</style>