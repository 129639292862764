<template>
    <v-dialog v-model="dialogPagamentos" persistent :fullscreen="ehMobile" width="700">
        <dados-cobranca
            v-if="dadosCobranca"
            @fechar="fechar"
            @voltar="fechar"
            v-model="dadosOrganizacao"
            @comprar-saldo="abrirMetodoPagamento"
        />

        <metodo-pagamento
           v-else-if="metodosPagamento"
            @fechar="fechar"
            @voltar="fechar"
            :itens-adicionais="itensAdicionais"
            :plano-selecionado="planoSelecionado"
            :valor-total-itens="valorTotalItens"
            :loja-titulo="lojaTitulo"
        />
    </v-dialog>
</template>

<script>
import DadosCobranca from './components/dados-cobranca/DadosCobranca.vue'
import MetodoPagamento from './components/metodo-pagamento/MetodoPagamento'
import {actionTypes} from '@/commons/constants'
import _ from 'lodash'

export default {
    name: 'DialogPagamento',
    components: {
        DadosCobranca,
        MetodoPagamento
    },
    props: {
        itensAdicionais: {
            type: Object,
            required: true
        },
        planoSelecionado: {
            type: Object | null,
            required: true
        },
        dialogPagamentos: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            metodosPagamento: false,
            dadosCobranca: false,
            dadosOrganizacao: {},
        }
    },
    async created() {
        await this.atualizarValoresAdicionais()
        await this.abrirMetodoPagamento()
    },
    computed: {
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        },
        itensToString() {
            let stringItens = ''

            let documento = this.itensAdicionais.documento
            if (documento.quantidade > 0) {
                stringItens += this.itemString(documento.quantidade, documento.quantidadePacote, documento.titulo)
            }

            // let comboDocumentoWhatsapp = this.itensAdicionais.comboDocumentoWhatsapp
            // if (comboDocumentoWhatsapp.quantidade > 0) {
            //     stringItens += this.itemString(comboDocumentoWhatsapp.quantidade, comboDocumentoWhatsapp.quantidadePacote, comboDocumentoWhatsapp.titulo)
            // }

            if (!!this.planoSelecionado) {
                stringItens += this.itemString(1, 0, this.planoSelecionado.titulo)
            }

            return stringItens
        },
        lojaTitulo() {
            return `Adquirir ${this.getTipoCompraSelecionado}`
        },
        getTipoCompraSelecionado() {
            return this.ehPlano ? 'Plano' : 'Pacote'
        },
        ehPlano() {
            return !!this.planoSelecionado
        },
        valorTotalItens() {
            let documento = this.itensAdicionais.documento
            let valorDocumento = documento.valorPacote ? documento.quantidade * documento.valorPacote : 0

            //let comboDocumentoWhatsapp = this.itensAdicionais.comboDocumentoWhatsapp
            //let valorcomboDocumentoWhatsapp = comboDocumentoWhatsapp.valorPacote ? comboDocumentoWhatsapp.quantidade * comboDocumentoWhatsapp.valorPacote : 0

            let avulsos = valorDocumento //+ valorcomboDocumentoWhatsapp

            let planos = this.valorTotalPlanos()

            return this.arredondarDecimal(avulsos + planos)
        }
    },
    methods: {
        async atualizarValoresAdicionais() {
            let compras = await this.$store.dispatch(actionTypes.COMPRAS.BUSCAR_PLANO_ASSINATURA_ADICIONAL)
            _.merge(this.itensAdicionais, compras, this.itensAdicionais)

            //let qtd = this.itensAdicionais.comboDocumentoWhatsapp.quantidadePacote

            //this.itensAdicionais.comboDocumentoWhatsapp.titulo = `Documentos + ${qtd} WhatsApp`
        },
        async abrirMetodoPagamento() {
            const possuiDadosDeCobranca = await this.validarDadosCobranca()

            if (possuiDadosDeCobranca) {
                this.metodosPagamento = true
                this.dadosCobranca = false
            } else {
                this.metodosPagamento = false
                this.dadosCobranca = true
            }
        },
        arredondarDecimal(valor) {
            return Math.round((valor + Number.EPSILON) * 100) / 100
        },
        tratarTituloPlano(titulo) {
            let tituloAux = titulo.toLowerCase().replaceAll('_', ' ')
            return tituloAux.replace(/(^\w{1})|(\s+\w{1})/g, letra => letra.toUpperCase());
        },
        fechar() {
            this.metodosPagamento = false
            this.dadosCobranca = false

            this.$emit('fechar-pagamentos')
        },
        itemString(quantidade, quantidadePacote, titulo) {
            if (quantidadePacote > 1) {
                return quantidade + ' pacote(s) ' + quantidadePacote + " " + titulo + '(s), '
            } else {
                return quantidade + ' ' + titulo + '(s), '
            }
        },
        async validarDadosCobranca() {
            this.dadosOrganizacao = await this.buscarDadosOrganizacao()

            return (this.dadosOrganizacao.cnpjCPF && this.dadosOrganizacao.cnpjCPF.length > 0) &&
                (this.dadosOrganizacao.endereco && this.dadosOrganizacao.endereco.length > 0) &&
                (this.dadosOrganizacao.bairro && this.dadosOrganizacao.bairro.length > 0) &&
                (this.dadosOrganizacao.numero && this.dadosOrganizacao.numero.length > 0) &&
                (this.dadosOrganizacao.cep && this.dadosOrganizacao.cep.length > 0) &&
                this.dadosOrganizacao.municipioId && this.dadosOrganizacao.estadoId
        },
        valorTotalPlanos() {
            let valorPlanos = 0

            if(!!this.planoSelecionado) {
                const ehValorParcelado = this.planoSelecionado.parcelas > 0
                const valorDefinido = ehValorParcelado ? this.planoSelecionado.valorParcela : this.planoSelecionado.valorPacote

                valorPlanos += valorDefinido ? valorDefinido : 0
            }

            return valorPlanos
        },
    }
}
</script>
