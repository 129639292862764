var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{staticClass:"co-dentro-pasta"},[_c('div',{staticClass:"acoes mb-1"},[_c('v-breadcrumbs',{staticClass:"ml-5",attrs:{"items":_vm.pastasBreadcrumbs,"divider":"/"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('v-breadcrumbs-item',{on:{"click":function($event){return _vm.irParaPasta(props.item.id)}}},[_c('drop',{staticClass:"d-flex align-center",on:{"drop":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.moverPorDrop.apply(void 0, [ props.item.id ].concat( argsArray ))}}},[_c('span',{staticClass:"pasta"},[_vm._v(" "+_vm._s(props.item.text)+" "+_vm._s(props.item.quantidadeContratos)+" ")])])],1)]}}])}),_c('v-tooltip',{directives:[{name:"az-auth",rawName:"v-az-auth",value:('Pasta.Editar'),expression:"'Pasta.Editar'"}],attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-3",attrs:{"size":"22","color":"secondary-200"},on:{"click":_vm.abrirDialogPasta}},on),[_vm._v(" mdi-pencil ")])]}}])},[_c('span',[_vm._v("Editar Pasta")])]),(_vm.ehAdministradorOrganizacao)?_c('v-tooltip',{directives:[{name:"az-auth",rawName:"v-az-auth",value:('Pasta.Compartilhar'),expression:"'Pasta.Compartilhar'"}],attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-3",attrs:{"size":"22","color":"secondary-200"},on:{"click":_vm.abrirDialogCompartilharPasta}},on),[_vm._v(" mdi-account-multiple ")])]}}],null,false,2635048693)},[_c('span',[_vm._v("Editar Permissões")])]):_vm._e(),_c('notificacao',{staticClass:"ml-3",attrs:{"pasta":_vm.pastaAtual}})],1),_c('v-divider'),(_vm.dialogPasta)?_c('dialog-pasta',{attrs:{"dialog":_vm.dialogPasta,"pasta-editar":_vm.pastaAtual,"pasta-pai":_vm.getPastaPai(),"editar":""},on:{"fechar":_vm.fecharDialogPasta}}):_vm._e(),(_vm.dialogCompartilharPasta)?_c('dialog-pasta-compartilhamento',{attrs:{"dialog":_vm.dialogCompartilharPasta,"pasta":_vm.pastaAtual},on:{"fechar":_vm.fecharDialogCompartilharPasta}}):_vm._e(),(_vm.dialogMover.abrirDialog)?_c('dialog-mover-elemento',{attrs:{"dialog":_vm.dialogMover.abrirDialog,"elemento":_vm.dialogMover.elemento,"destino":_vm.dialogMover.destino},on:{"fechar":_vm.fecharDialoglMover}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }