<template>
    <v-row class="co-novo-meus-contratos-barra-busca pl-4 py-2">
        <modo-exibicao/>

        <v-spacer v-if="!ehMobile"/>

        <filtro-ordenacao/>

        <campo-pesquisa v-model="filtros"/>

        <filtro-avancado v-model="filtros"/>
    </v-row>
</template>

<script>
import ModoExibicao from './filtros/ModoExibicao'
import CampoPesquisa from './filtros/CampoPesquisa'
import FiltroAvancado from './filtros/FiltroAvancado'
import FiltroOrdenacao from './filtros/FiltroOrdenacao'

export default {
    name: 'BarraBusca',
    components: {FiltroOrdenacao, CampoPesquisa, ModoExibicao, FiltroAvancado},
    computed: {
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        }
    },
    props: {
        filtros: {
            type: Object,
            required: true
        }
    },
}
</script>

<style lang="stylus">
.co-novo-meus-contratos-barra-busca
    width 100%
    justify-content center
    align-items center
    flex none
    margin 0

@media (max-width: 768px) 
    .co-novo-meus-contratos-barra-busca
        padding 16px
        justify-content space-between
        gap 8px

</style>
