<template>
    <div class="co-formulario-signatario mt-5">
        <dados-principais
            :signatarios="signatariosDados"
            :documentos-signatario="documentosSignatario"
            :papeis-signatario="papeisSignatario"
            :tipos-assinatura="tiposAssinatura"
            :possui-ordem-ativa="signatarioOpcao.ordemAssinaturaDefinida"
            @adicionar-signatario="adicionarNovoSignatario"
            @remover-signatario="removerSignatario"
            @validaTipoAssinatura="validaTipoAssinatura"/>

        <v-row no-gutters class="definir-ordem-assinatura d-flex align-center mt-5 px-3">

            <v-tooltip top >
                <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="desativarBotaoDefinirOrdem ? on : ''">
                        <v-switch
                            class="ma-0 pa-0"
                            color="primary"
                            dense
                            hide-details
                            v-model="signatarioOpcao.ordemAssinaturaDefinida"
                            :disabled="desativarBotaoDefinirOrdem"
                        >
                            <template v-slot:label>
                                <span class="ordem-label"> Definir ordem de assinatura </span>
                            </template>
                        </v-switch>
                    </div>
                </template>
                <span>
                    Você deve inserir ao menos 2 {{ $tc("pt_br.signatario", 2).toLowerCase() }} para ativar esta funcionalidade.
                </span>
            </v-tooltip>

            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <span v-on="on" class="definir-ordem-tooltip">
                        <v-icon size="16px" :color="corTooltip"> mdi-information </v-icon>
                    </span>
                </template>
                <span>Você pode definir uma sequência para os {{ $tc("pt_br.signatario", 2).toLowerCase() }}. <br/> O próximo só poderá assinar depois que o anterior tenha assinado.</span>
            </v-tooltip>
        </v-row>

        <opcoes-avancadas
            class="opcoes-avancadas mt-5"
            :signatario-opcao="signatarioOpcao"
            :papeis-siganatario="papeisSignatario"
            :documentos-signatario="documentosSignatario"/>

        <div class="botoes-wrapper mt-xs-8">
            <div class="botoes">
                <div class="d-flex justify-center">
                    <v-btn v-if="!ehMobile" color="primary" large
                        @click="continuar"
                        class="px-5">
                        Continuar
                        <v-icon color="white" size="18"> mdi-arrow-right </v-icon>
                    </v-btn>

                    <v-btn v-else color="primary" large
                        @click="salvarOnce(false)"
                        :disabled="!habilitarSalvar"
                        class="px-5">
                        Enviar para Assinatura
                    </v-btn>
                </div>

                <div class="d-flex justify-center my-2">
                    <v-btn text large plain
                        color="primary"
                        @click="salvarOnce(true)"
                        :disabled="!habilitarSalvar"
                        class="btn-pular px-5">
                        Pular Etapa
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OpcoesAvancadas
    from '@/views/criar-documento/novo-documento-signatario/components/opcoes-avancadas/OpcoesAvancadas'
import DadosPrincipais
    from '@/views/criar-documento/novo-documento-signatario/components/dados-principais/DadosPrincipais'

export default {
    name: 'FormularioSignatario',
    components: {
        DadosPrincipais,
        OpcoesAvancadas,
    },
    props: {
        documentosSignatario: {
            type: Array,
            required: true
        },
        habilitarSalvar: {
            type: Boolean
        },
        papeisSignatario: {
            type: Array,
            required: true
        },
        signatariosDados: {
            type: Array
        },
        signatarioOpcao: {
            type: Object,
            required: true,
        },
        tiposAssinatura: {
            type: Array
        },
    },
    computed: {
        corTooltip() {
            return !this.desativarBotaoDefinirOrdem ? 'primary' : ''
        },
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        },
        desativarBotaoDefinirOrdem() {
            return this.signatariosDados.length < 2
        },
    },
    methods: {
        adicionarNovoSignatario() {
            this.$emit('adicionar-signatario')
        },
        continuar() {
            this.$emit('continuar')
        },
        removerSignatario(signatario) {
            this.$emit('remover-signatario', signatario)
        },
        salvarOnce(pularEtapa) {
            this.$emit('salvar-once', pularEtapa)
        },
        validaTipoAssinatura() {
            this.$emit('valida-tipo-assinatura')
        }
    }
}
</script>

<style lang="stylus">
.co-formulario-signatario
    .definir-ordem-assinatura
        gap 5px

        .ordem-label
            font-size 15px
            color var(--text-color-300)

        .definir-ordem-tooltip
            color var(--secondary-400)
            display flex
            font-size 18px
            margin-right 21px

@media (max-width: 600px)
    .co-formulario-signatario
        height 100%

        .botoes-wrapper
            min-width 100%
            min-height 122px
            margin-top 0
            background-color #FFF

            .botoes
                z-index 100
                position fixed
                bottom 0
</style>