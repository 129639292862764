import axios from 'axios'
import {actionTypes} from '@/commons/constants'

export default {
    async [actionTypes.POSICIONAMENTO_DADO.ATUALIZAR]({state}, posicionamentoDados) {
        const minutaId = state.minutaAtual.id
        return await axios.put(`api/minutas/${minutaId}/posicionamento-dados`, posicionamentoDados)
    },

    async [actionTypes.POSICIONAMENTO_DADO.INSERIR]({state}, posicionamentoDados) {
        const minutaId = state.minutaAtual.id
        return await axios.post(`api/minutas/${minutaId}/posicionamento-dados`, posicionamentoDados)
    },

    async [actionTypes.POSICIONAMENTO_DADO.BUSCAR_POR_MINUTA]({state}) {
        const minutaId = state.minutaAtual.id
        const {data} =  await axios.get(`api/minutas/${minutaId}/posicionamento-dados`)
        return data
    },
}