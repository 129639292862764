<template>
    <div class="co-sentimentos-feeedback">
        <v-row no-gutters class="justify-center mt-5">
            <v-icon class="mr-2" size="48" :disabled="desabilitar" :color="atualizarCor('red', 0)" @click="atualizarNota(0)">
                mdi-emoticon-frown
            </v-icon>

            <v-icon class="mr-2" size="48" :disabled="desabilitar" :color="atualizarCor('orange', 2.5)" @click="atualizarNota(2.5)">
                mdi-emoticon-sad
            </v-icon>

            <v-icon class="mr-2" size="48" :disabled="desabilitar" :color="atualizarCor('yellow', 5)" @click="atualizarNota(5)">
                mdi-emoticon-neutral
            </v-icon>

            <v-icon class="mr-2" size="48" :disabled="desabilitar":color="atualizarCor('lime', 7.5)" @click="atualizarNota(7.5)">
                mdi-emoticon-happy
            </v-icon>

            <v-icon size="48" :disabled="desabilitar" :color="atualizarCor('green', 10)" @click="atualizarNota(10)">
                mdi-emoticon-excited
            </v-icon>
        </v-row>

        <div class="validate mb-3">
            <span v-show="validate">campo Nota obrigatório!</span>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'

export default {
    name: 'SentimentoFeedback',
    components: {},
    props: {
        nota: {
            type: Number,
            required: true
        },
        desabilitar: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dialogFeedback: true,
            validate: false
        }
    },
    created() {

    },
    computed: {
        ...mapGetters(['ehEfcazCLM']),
        ...mapState([]),
        ehAbrirFeedback() {
            return this.dialogFeedback
        },
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        },
    },
    methods: {
        atualizarNota(nota) {
            this.$emit('atualizar-nota', nota)
        },
        atualizarCor(cor, notaCor) {
            if (this.nota !== -1) {
                return this.nota === notaCor ? cor : 'var(--text-color-300)'
            } else {
                return cor
            }
        },
        fechar() {
            this.dialogFeedback = false
        },
        validarNota() {
            if (this.nota < 0) {
                this.validate = true
            }
            return this.nota >= 0
        }
    }
}
</script>

<style lang="stylus">
.co-sentimentos-feeedback

    .titulo
        display flex
        justify-content center
        width 100%

    .texto
        color var(--text-color-300)

    .texto2
        color var(--text-color-200)

    .validate
        height 22px
        display flex
        justify-content center
        font-size: 12px;
        color var(--error-500)

</style>