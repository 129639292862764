import axios from 'axios'
import {actionTypes} from '@/commons/constants'
import {AzSearchUrlBuilder} from '@azinformatica/loki'

export default {
    async [actionTypes.COBRANCAS.HISTORICO_ORGANIZACAO]({state}, parametros) {
        const organizacaoId = state.login.organizacaoPlano.organizacaoId
        const {filtros, paginacao} = parametros

        const url = AzSearchUrlBuilder.build(`api/cobrancas/assinaturas/organizacao/${organizacaoId}`, filtros, paginacao)
        const {data} = await axios.get(url)

        return data
    },
}