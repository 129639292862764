<template>
    <div class="co-painel-configuracoes-notificacao-signatario">
        <configuracao-titulo titulo="Usuários"/>

        <v-row no-gutters class="mx-3">
            <v-switch
                color="primary"
                v-model="value.emailUsuarioAdicional"
                :label="`Permitir envio de E-mails de cadastro aos novos usuários.`"
                :disabled="ehPlanoSemEdicaoConfiguracaoNotificacao"
            />
        </v-row>
    </div>
</template>

<script>

import ConfiguracaoTitulo from '../../common/ConfiguracaoTitulo'

export default {
    name: 'NotificacoesUsuario',
    components: {ConfiguracaoTitulo},
    props: {
        value: {
            required: true
        },
        ehPlanoSemEdicaoConfiguracaoNotificacao: {
            type: Boolean,
            required: true
        }
    }
}
</script>
