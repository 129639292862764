import axios from 'axios'
import {actionTypes} from '@/commons/constants'

export default {
    async [actionTypes.CONFIGURACAO.WHITE_LABEL.BUSCAR_ORGANIZACAO_CONFIGURACOES_WHITE_LABEL](context) {
        const {data} = await axios.get('api/configuracoes/white-label')
        return data
    },

    async [actionTypes.CONFIGURACAO.WHITE_LABEL.ATUALIZAR_ORGANIZACAO_CONFIGURACOES_WHITE_LABEL](context, configuracoes) {
        const {data} = await axios.put('api/configuracoes/white-label', configuracoes)
        return data
    }
}
