<template>
    <div class="clm-assinaturas">
        <div class="area-sigatarios">
            <area-sem-assinaturas
                v-if="areaSemAssinatura"
                style="height: 100%"
                :abrir-dialogs="abrirDialogs"
                @adicionarSignatario="adicionarSignatario"
                @formalizar="confirmarFormalizacao" />

            <div v-else>
                <div class="titulo-assinatura" style="height: 50px">
                    <div>
                        <div style="padding-top: 8px;">{{ $tc("pt_br.signatario", 2) }} ({{
                                quantidadeSignatarios
                            }})
                        </div>
                        <div style="padding-bottom: 5px;">
                            <limite-assinatura
                                :valor="minutaAtual.fimAssinatura"
                                @salvar="alterarDataLimiteAssinatura" />
                        </div>
                    </div>

                    <div class="co-aviso-limite-assinatura-expirado">
                        <aviso-limite-assinatura-expirado v-if="verificarAssinaturas"/>
                    </div>
                    <div v-if="podeInserirSignatario" class="d-flex" v-az-auth="'Contrato.Signatario.Novo'">
                        <importar-signatarios-menu :abrir-dialogs="abrirDialogs" class="pr-2" v-if="!ehMobile">
                            <template v-slot:botao-importar="{ menu: menu, tooltip: tooltip }">
                                <div class="botao-importar"
                                     v-on="{ ...tooltip, ...menu }">
                                    <v-icon color="primary" size="24px" style="position:relative; left: 1px">
                                        mdi-account-multiple-plus
                                    </v-icon>
                                </div>
                            </template>
                        </importar-signatarios-menu>

                        <div v-az-auth="'Contrato.Signatario.Novo'" class="abas-novo"
                             style="color: #72cb72; cursor:pointer"
                             @click="adicionarSignatarios">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" color="primary" size="24px" style="position:relative; left: 1px">
                                        mdi-plus-circle
                                    </v-icon>
                                </template>

                                <span>Adicionar {{ $tc("pt_br.signatario", 2) }}</span>
                            </v-tooltip>
                        </div>
                    </div>
                </div>

                <adicionar-signatario
                    v-if="adicionandoSignatario"
                    :documentos-signatario="documentosSignatario"
                    :modo-edicao="modoEdicao"
                    :novo-signatario="signatario"
                    :papeis-siganatario="papeisSignatario"
                    :signatarios-sugestoes="signatariosSugestoes"
                    :minutaAtual="minutaAtual"
                    @atualizar="atualizarSignatario"
                    @cancelar="cancelar"
                    @salvar="inserirSignatario"/>

                <div v-if="!podeAdicionar && !possuiSignatarios()" class="co-contrato-sem-assinantes mt-4">
                    <v-icon color="#ccc" size="50px"> mdi-gesture</v-icon>
                    <span>Este contrato não possui nenhum {{ $tc("pt_br.signatario").toLowerCase() }}.</span>
                </div>

                <div :style="adicionandoSignatario ? 'height: calc(100% - 205px);' : 'height: calc(100% - 50px)'"
                     style="overflow-y: auto; overflow-x: hidden">
                    <div style="position: relative">
                        <lista-signatarios
                            v-if="possuiSignatarios() && !adicionandoSignatario"
                            @atualizarChaveSeguranca="atualizarChaveSegurancaSignatario"
                            @atualizarTelefoneSignatario="atualizarTelefoneSignatario"
                            @editarSignatario="editarSignatario"
                            @salvar="inserirSignatario" />
                    </div>
                </div>
            </div>
        </div>

        <div v-if="possuiSignatarios() && !adicionandoSignatario">
            <div class="acoes">
                <botao-principal
                    v-if="exibirBotaoFormalizar"
                    :existeAssinaturaRejeitada="possuiAssinaturaRejeitada"
                    :existeSignatarioAguardandoAssinatura="possuiSignatariosSemAssinar"
                    :possuiSignatarios="possuiSignatarios()"
                    :possuiSignatariosAguardadoEnvio="possuiSignatariosAguardadoEnvio"
                    :signatariosPendentesEnvioConvite="signatariosPendentesEnvioConvite()"
                    :loading="loading"
                    @cancelar="abrirModalMotivoCancelamento()"
                    @convidar="convidar()"
                    @formalizar="confirmarFormalizacao()" />
            </div>
        </div>
        <az-confirm
            v-if="dialogConfirmarImportarSignatarios"
            :max-width=400
            class="adicionar-signatarios-com-importacao"
            @onConfirm="adicionarSignatariosComImportacao"
            @onDecline="adicionarSignatarios">
            Importar {{ $tc("pt_br.signatario", 2).toLowerCase() }} da versão anterior?
        </az-confirm>

        <dialog-info-signatario-whatsapp-assinando
            :mostrarDialog="dialogSignatarioWhatsAppAssinando.mostrarOutros || dialogSignatarioWhatsAppAssinando.mostrarPriorizar"
            :signatarios-impedidos="getSignatariosImpedidosWhatsApp"
            :priorizar="dialogSignatarioWhatsAppAssinando.mostrarPriorizar"
            @fechar="fecharDialogSignatarioWhatsAppAssinando"
            @alterar-priorizar="atualizarMinuta" />

        <importar-signatarios-dialogs
            salvar-backend
            :papeis-signatario="papeisSignatario"
            :abrir-dialogs="abrirDialogs"
            :documentos-signatario="documentosSignatario"
            @adicionar-signatarios="atualizarListagemSignatarios" />

        <dialog-default-confirmar-acao
            v-if="dialogFormalizarContrato"
            :dialog="dialogFormalizarContrato"
            sentimento="feliz"
            :titulo="'O que é Formalizar?'"
            :subtitulo="formalizarSubtitulo"
            label-botao-principal="SIM, Quero formalizar!"
            @acao-principal="formalizar"
            @fechar="fecharDialogFormalizar" />
    </div>
</template>

<script>
import _ from 'lodash'
import {mapGetters, mapState} from 'vuex'
import controleAcoes from '../../../controle-acoes'
import moment from 'moment'
import actionTypes from '@/commons/constants/action-types'
import AreaSemAssinaturas from './AreaSemAssinatura'
import AdicionarSignatario
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/AdicionarSignatario'
import AvisoLimiteAssinaturaExpirado from './AvisoLimiteAssinaturaExpirado'
import BotaoPrincipal from '../BotaoPrincipal'
import ListaSignatarios from './ListaSignatarios'
import LimiteAssinatura from './LimiteAssinatura'
import DialogInfoSignatarioWhatsappAssinando
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/dialogs/DialogInfoSignatarioWhatsappAsinando'
import ImportarSignatariosDialogs
    from '@/views/criar-documento/novo-documento-signatario/components/dados-principais/components/importarSignatarios/ImportarSignatariosDialogs'
import ImportarSignatariosMenu
    from '@/views/criar-documento/novo-documento-signatario/components/dados-principais/components/importarSignatarios/ImportarSignatariosMenu'
import DialogDefaultConfirmarAcao from '@/commons/components/dialogs/DialogDefaultConfirmarAcao'

export default {
    name: 'Assinaturas',
    components: {
        DialogDefaultConfirmarAcao,
        ImportarSignatariosDialogs,
        ImportarSignatariosMenu,
        AdicionarSignatario,
        AreaSemAssinaturas,
        AvisoLimiteAssinaturaExpirado,
        BotaoPrincipal,
        ListaSignatarios,
        LimiteAssinatura,
        DialogInfoSignatarioWhatsappAssinando
    },
    data() {
        return {
            abrirDialogs: {
                dialogImportarSignatarios: false,
                dialogImportarGrupos: false
            },
            adicionandoSignatario: false,
            definirOrdem: false,
            documentosSignatario: [],
            dialogFormalizarContrato: false,
            dialogConfirmarImportarSignatarios: false,
            loading: false,
            modoEdicao: false,
            novosSignatarios: [],
            signatariosSugestoes: [],
            signatario: {
                chaveSeguranca: '',
                email: [],
                papelId: '',
                documentoId: '',
                ddi: '55',
                telefone: '',
                tipoAssinatura: '',
                enviarConviteSMS: false,
                enviarTokenSMS: false,
                assinaturaManuscrita: true,
                representanteLegal: true,
                mensagem: '',
                rejeitarDocumento: true
            },
            papeisSignatario: [],
            processUpdate: undefined,
            signatariosImportados: [],
            dialogSignatarioWhatsAppAssinando: {
                mostrarPriorizar: false,
                mostrarOutros: false
            },
            signatariosImpedidos: {
                daOrganizacao: [],
                outros: []
            },
        }
    },
    computed: {
        ...mapState(['contrato', 'minutaAtual', 'loki', 'signatarios']),
        ...mapGetters(['podeFormalizarContratoAuto']),
        arquivado() {
            return this.minutaAtual.contratoArquivado === true
        },
        areaSemAssinatura() {
            return !this.possuiSignatarios() && !this.adicionandoSignatario && this.podeAdicionar
        },
        ehMinutaCancelada() {
            return this.minutaAtual.situacaoFormalizacao === 'CANCELADO'
        },
        ehMinutaFormalizada() {
            return this.minutaAtual.situacaoFormalizacao === 'FORMALIZADO'
        },
        ehMinutaEmFormalizacao() {
            return this.minutaAtual.situacaoFormalizacao === 'EM_FORMALIZACAO'
        },
        ehMinutaEmRascunho() {
            return this.minutaAtual.situacaoFormalizacao === 'RASCUNHO'
        },
        ehMinutaEmFormalizacaoOuRascunho() {
            return this.ehMinutaEmFormalizacao || this.ehMinutaEmRascunho
        },
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        },
        ehSignatariosImpedidosDaOrganizacao() {
            return this.signatariosImpedidos.daOrganizacao && this.signatariosImpedidos.daOrganizacao.length > 0
        },
        ehSignatariosImpedidosOutros() {
            return this.signatariosImpedidos.outros && this.signatariosImpedidos.outros.length > 0
        },
        exibirBotaoFormalizar() {
            return this.possuiSignatarios() && this.verificaSituacaoMinuta
        },
        formalizarSubtitulo() {
            return `Formalizando o ${this.$tc("pt_br.documento").toLowerCase()} você o bloqueia para qualquer alteração, ou seja, ` +
                `não será mais possível incluir ${this.$tc("pt_br.signatario", 2).toLowerCase()} ou alterar datas de vigência. ` +
                `Essa etapa é opcional, entretanto, depois de formalizado não será possível desfazer.`
        },
        getSignatariosImpedidosWhatsApp() {
            return this.dialogSignatarioWhatsAppAssinando.mostrarPriorizar ? this.signatariosImpedidos.daOrganizacao : this.signatariosImpedidos.outros
        },
        podeAdicionar() {
            return controleAcoes.adicionarRemoverSignatarios(this.minutaAtual)
        },
        podeInserirSignatario() {
            return !this.adicionandoSignatario && this.podeAdicionar && this.possuiSignatarios() && !this.possuiAssinaturaRejeitada
        },
        possuiAssinaturaRejeitada() {
            const assinaturasRejeitadas = this.signatarios.filter(signatario => signatario.statusSignatario === "REJEITADO")
            return assinaturasRejeitadas.length > 0
        },
        possuiSignatariosAguardadoEnvio() {
            const signatariosAgudandoEnvio = this.signatarios.filter(signatario => signatario.statusSignatario === "NAO_CONVIDADO")
            return signatariosAgudandoEnvio.length > 0
        },
        possuiSignatariosSemAssinar() {
            const assinaturas = this.signatarios.filter(signatario => signatario.statusSignatario === "CONVIDADO")
            return assinaturas.length > 0
        },
        verificaSituacaoMinuta() {
            return !this.ehMinutaFormalizada && !this.ehMinutaCancelada && !this.arquivado
        },
        verificarAssinaturas() {
            const possuiAssinaturasPendentes = this.possuiSignatariosSemAssinar || this.possuiSignatariosAguardadoEnvio
            return possuiAssinaturasPendentes && this.verificarDataAssinaturas && !this.ehMinutaCancelada
        },
        verificarDataAssinaturas() {
            const fimAssinatura = moment(this.minutaAtual.fimAssinatura).endOf('day')
            const dataAtual = moment(new Date())

            return dataAtual.isAfter(fimAssinatura)
        },
        quantidadeSignatarios() {
            return this.signatarios.length
        }
    },
    created() {
        this.setIntervaloAtualizacaoSignatarios()
    },
    beforeDestroy() {
        this.cancelAutoUpdate()
    },
    watch: {
        async 'contrato'() {
            if (this.ehMinutaFormalizada || this.ehMinutaEmFormalizacao) {
                await this.atualizarListagemSignatarios()
            }
        },
        async 'minutaAtual'() {
            await this.atualizarListagemSignatarios()
        },
        async 'signatarios'() {
            this.possuiSignatarios()
            this.signatariosPendentesEnvioConvite()
        }
    },
    async mounted() {
        if (this.minutaAtual && this.minutaAtual.id && this.contrato.id) {
            await this.atualizarListagemSignatarios()
            this.signatariosPendentesEnvioConvite()
        }
        await this.buscarConsumoOrganizacao()
        await this.buscarSignatariosSugestoes()
    },
    methods: {
        async setIntervaloAtualizacaoSignatarios() {
            this.processUpdate = window.setInterval(await this.refreshSignatarios, 10000)
        },

        async atualizar(signatario) {
            this.formatarTelefone(signatario)
            this.validarCamposVazios(signatario)
            await this.$store.dispatch(actionTypes.ATUALIZAR_SIGNATARIO, {
                contratoId: this.contrato.id,
                minutaId: this.minutaAtual.id,
                signatario: signatario
            })
        },
        async atualizarSignatario(signatario) {
            await this.atualizar(signatario)
            this.adicionandoSignatario = false
            await this.atualizarListagemSignatarios()
        },
        async atualizarTelefoneSignatario(signatario) {
            const atualizarSignatarioInput = await this.formatarSignatarioParaAtualizar(signatario)
            await this.atualizar(atualizarSignatarioInput)
            await this.atualizarListagemSignatarios()
        },
        async buscarConsumoOrganizacao() {
            await this.$store.dispatch(actionTypes.COMUN.BUSCAR_CONSUMO_ORGANIZACAO)
        },
        async buscarSignatariosSugestoes() {
            this.signatariosSugestoes = await this.$store.dispatch(actionTypes.SIGNATARIO.BUSCAR_SIGNATARIOS_SUGESTOES)
        },
        async atualizarChaveSegurancaSignatario(signatario) {
            const atualizarSignatarioInput = await this.formatarSignatarioParaAtualizar(signatario)
            await this.atualizar(atualizarSignatarioInput)
            await this.atualizarListagemSignatarios()
        },
        abrirModalMotivoCancelamento() {
            this.$emit('abrirModalMotivoCancelamento')
        },
        async adicionarSignatarios() {
            this.limparSignatariosImportados()
            await this.carregarPapeisSignatarios()
            await this.carregarDocumentosSignatario()
            this.construirSignatarioVazio()
            this.modoEdicao = false
            this.dialogConfirmarImportarSignatarios = false
            this.adicionandoSignatario = true
        },
        async adicionarSignatariosComImportacao() {
            this.setMensagemLoading(`Importando ${this.$tc("pt_br.signatario", 2).toLowerCase()}...`)
            await this.carregarPapeisSignatarios()
            await this.carregarDocumentosSignatario()
            await this.carregarSignatariosImportados()
            await this.inserirSignatariosImportados()
            this.dialogConfirmarImportarSignatarios = false
            this.adicionandoSignatario = false
            this.limparSignatariosImportados()
            await this.atualizarListagemSignatarios()
        },
        alterarDataLimiteAssinatura(novoFimAssinatura) {
            this.$emit('alterarDataLimite', novoFimAssinatura)
        },
        async atualizarMinuta() {
            await this.$store.dispatch(actionTypes.BUSCAR_MINUTA_ATUAL, this.contrato.id)
        },
        async adicionarSignatario() {
            this.modoEdicao = false
            await this.carregarDocumentosSignatario()
            await this.carregarPapeisSignatarios()
            this.construirSignatarioVazio()
            await this.importarSignatariosMinutaAnterior()
            if (this.signatariosImportados && this.signatariosImportados.length > 0) {
                this.dialogConfirmarImportarSignatarios = true
            } else {
                this.adicionandoSignatario = true
            }
        },
        async atualizarListagemSignatarios() {
            this.setMensagemLoading(`Atualizando ${this.$tc("pt_br.signatario", 2).toLowerCase()}...`)
            await this.buscarSignatarios()
        },
        async buscarSignatarios() {
            await this.$store.dispatch(actionTypes.BUSCAR_SIGNATARIOS, {
                contratoId: this.contrato.id,
                minutaId: this.minutaAtual.id
            })

            const algumSignatarioPendenteAssinatura = this.verificarPossuiSignatarioNaoAssinou()
            const organizacaoPermiteFormalizarAuto = this.podeFormalizarContratoAuto
            const statusInicialDaMinuta = this.minutaAtual.situacaoFormalizacao
            const statusMinutaPooling = this.signatarios[0]?.statusMinuta

            if (this.signatarios.length > 0 && !algumSignatarioPendenteAssinatura && organizacaoPermiteFormalizarAuto) {
                if (statusMinutaPooling !== statusInicialDaMinuta) {
                    await this.atualizarMinuta()
                }
            }
        },
        cancelar() {
            this.adicionandoSignatario = false
        },
        cancelAutoUpdate() {
            if (this.processUpdate) {
                window.clearInterval(this.processUpdate)
            }
        },
        async carregarPapeisSignatarios() {
            const listaPapeisSignatarios = await this.$store.dispatch(actionTypes.BUSCAR_PAPEIS_SIGNATARIOS)
            for (const papel of listaPapeisSignatarios) {
                this.papeisSignatario.push({text: papel.nome, value: papel.id})
            }
        },
        async carregarDocumentosSignatario() {
            const documentos = await this.$store.dispatch(actionTypes.BUSCAR_DOCUMENTOS_SIGNATARIO)
            for (const documento of documentos) {
                this.documentosSignatario.push({
                    text: documento.nome,
                    value: documento.documentoId,
                    descricao: documento.descricao
                })
            }
        },
        carregarSignatariosImportados() {
            this.signatariosImportados.map(signatario => this.novosSignatarios.push({
                email: signatario.email,
                papelId: this.identificarPapelSignatario(signatario.papel),
                ddi: signatario.ddi,
                skipFotoWpp: signatario.skipFotoWpp,
                telefone: signatario.telefone === undefined ? null : signatario.telefone,
                chaveSeguranca: signatario.chaveSeguranca,
                enviarConviteSMS: signatario.enviarConviteSMS,
                representanteLegal: signatario.representanteLegal,
                enviarTokenSMS: signatario.enviarTokenSMS,
                assinaturaManuscrita: signatario.assinaturaManuscrita,
                disponibilidadeAssinatura: signatario.disponibilidadeAssinatura,
                documentoId: this.identificarDocumento(signatario.documento),
                tipoAssinatura: signatario.tipoAssinatura,
                mensagem: signatario.mensagem,
                rejeitarDocumento: signatario.rejeitarDocumento
            }))
        },
        confirmarFormalizacao() {
            this.dialogFormalizarContrato = true
        },
        construirSignatarioVazio() {
            this.signatario = {
                chaveSeguranca: '',
                documentoId: 1,
                disponibilidadeAssinatura: 'REMOTA',
                assinaturaManuscrita: true,
                ddi: '55',
                skipFotoWpp: true,
                email: [],
                enviarTokenSMS: false,
                enviarConviteSMS: false,
                papelId: 16,
                representanteLegal: true,
                telefone: '',
                tipoAssinatura: '',
                mensagem: '',
                rejeitarDocumento: true
            }
        },
        async convidar() {
            this.loading = true
            try {
                const signatariosConvidados = await this.$store.dispatch(actionTypes.CONVIDAR_SIGNATARIOS,{
                    contratoId: this.contrato.id,
                    minutaId: this.minutaAtual.id
                })
                if (!signatariosConvidados.data.sucesso) {
                    this.signatariosImpedidos.daOrganizacao = signatariosConvidados.data.signatariosImpedidosOrganizacao
                    this.signatariosImpedidos.outros = signatariosConvidados.data.signatariosImpedidosOutros

                    if (this.ehSignatariosImpedidosDaOrganizacao) {
                        this.dialogSignatarioWhatsAppAssinando.mostrarPriorizar = true
                    }
                    if (this.ehSignatariosImpedidosOutros) {
                        this.dialogSignatarioWhatsAppAssinando.mostrarOutros = true
                    }
                } else {
                    this.mostrarNotificacaoSucesso(`Documento enviado aos ${this.$tc("pt_br.signatario", 2).toLowerCase()}`)
                    await this.atualizarListagemSignatarios()
                    await this.atualizarMinuta()
                }
            } finally {
                this.loading = false
            }
        },
        async editarSignatario(signatario) {
            await this.carregarPapeisSignatarios()
            await this.carregarDocumentosSignatario()
            this.preencherSignatarioAtualizar(signatario)
            this.modoEdicao = true
            this.adicionandoSignatario = true
        },
        fecharDialogFormalizar() {
            this.dialogFormalizarContrato = false
        },
        fecharDialogSignatarioWhatsAppAssinando() {
            if (this.dialogSignatarioWhatsAppAssinando.mostrarPriorizar) {
                this.dialogSignatarioWhatsAppAssinando.mostrarPriorizar = false
            } else {
                this.dialogSignatarioWhatsAppAssinando.mostrarOutros = false
                this.signatariosImpedidos = {
                    daOrganizacao: [],
                    outros: []
                }
            }
            this.buscarSignatarios()
        },
        async formalizar() {
            this.loading = true
            try {
                this.fecharDialogFormalizar()
                this.setMensagemLoading(`Formalizando ${this.$tc("pt_br.documento").toLowerCase()}...`)

                await this.$store.dispatch(actionTypes.FORMALIZAR_CONTRATO, {
                    contratoId: this.contrato.id,
                    minutaId: this.minutaAtual.id
                })
                await this.atualizarMinuta()
                this.mostrarNotificacaoSucessoDefault()
            } catch (e) {
                this.mostrarNotificacaoErro('Não foi possível formalizar')
            } finally {
                this.loading = false
            }
        },
        formatarTelefone(signatario) {
            if (signatario.telefone !== null && signatario.telefone.length > 0)
                signatario.telefone = signatario.telefone.replace(/[^a-zA-Z0-9 ]/g, "").replace(/\s/g, "")
        },
        async formatarSignatarioParaAtualizar(signatario) {
            await this.carregarPapeisSignatarios()
            await this.carregarDocumentosSignatario()
            const atualizarSignatarioInput = {
                signatarioId: signatario.id,
                papelId: this.identificarPapelSignatario(signatario.papel),
                documentoId: this.identificarDocumento(signatario.documento),
                email: signatario.email,
                chaveSeguranca: signatario.chaveSeguranca,
                ddi: signatario.ddi,
                skipFotoWpp: signatario.skipFotoWpp,
                telefone: signatario.telefone,
                enviarConviteSMS: signatario.enviarConviteSMS,
                enviarTokenSMS: signatario.enviarTokenSMS,
                statusSignatario: signatario.statusSignatario,
                disponibilidadeAssinatura: signatario.disponibilidadeAssinatura,
                assinaturaManuscrita: signatario.assinaturaManuscrita,
                tipoAssinatura: signatario.tipoAssinatura,
                representanteLegal: signatario.representanteLegal,
                mensagem: signatario.mensagem,
                rejeitarDocumento: signatario.rejeitarDocumento
            }
            return atualizarSignatarioInput
        },
        identificarPapelSignatario(papelSignatario) {
            const papelId = _.find(this.papeisSignatario, (signatario) => {
                return signatario.text === papelSignatario
            })
            return papelId.value
        },
        identificarDocumento(documento) {
            const documentoId = _.find(this.documentosSignatario, (documentoSignatario) => {
                return documentoSignatario.text === documento
            })
            return documentoId.value
        },
        async inserirSignatario(signatario) {
            await this.inserir(signatario)
            this.adicionandoSignatario = false
            await this.atualizarListagemSignatarios()
        },
        async inserir(signatario) {
            this.validarCamposVazios(signatario)

            if (signatario.email.length > 1) {
                signatario.telefone = ''
                signatario.enviarTokenSMS = false
                signatario.enviarConviteSMS = false
                await this.inserirLoteSignatario(signatario)
            } else {
                await this.inserirUnicoSignatario(signatario)
            }
        },
        async inserirSignatariosImportados() {
            for (const signatario of this.novosSignatarios) {
                this.validarCamposVazios(signatario)
                await this.inserirUnicoSignatario(signatario)
            }
        },
        async inserirLoteSignatario(signatario) {
            await this.$store.dispatch(actionTypes.SIGNATARIO.INSERIR_SIGNATARIO_LOTE, {
                contratoId: this.contrato.id,
                minutaId: this.minutaAtual.id,
                signatario: signatario
            })
        },
        async inserirUnicoSignatario(signatario) {
            signatario.email = signatario.email.toString()
            await this.$store.dispatch(actionTypes.SIGNATARIO.INSERIR_SIGNATARIO, {
                minutaId: this.minutaAtual.id,
                signatario: signatario
            })
        },
        async importarSignatariosMinutaAnterior() {
            const versao = this.minutaAtual.versao - 1
            this.signatariosImportados = await this.$store.dispatch(actionTypes.BUSCAR_SIGNATARIOS_POR_MINUTA_VERSAO, {
                contratoId: this.contrato.id,
                minutaId: this.minutaAtual.id,
                versao
            })
        },
        limparSignatariosImportados() {
            this.signatariosImportados = []
            this.novosSignatarios = []
        },
        possuiSignatarios() {
            return this.signatarios && this.signatarios.length > 0
        },
        preencherSignatarioAtualizar(signatario) {
            this.signatario = {
                signatarioId: signatario.id,
                nome: signatario.nome,
                email: [signatario.email],
                papelId: this.identificarPapelSignatario(signatario.papel),
                documentoId: this.identificarDocumento(signatario.documento),
                ddi: signatario.ddi,
                skipFotoWpp: signatario.skipFotoWpp,
                telefone: signatario.telefone === null ? '' : signatario.telefone.replace(/[^a-zA-Z0-9 ]/g, "").replace(/\s/g, ""),
                chaveSeguranca: signatario.chaveSeguranca === null ? '' : signatario.chaveSeguranca,
                enviarConviteSMS: signatario.enviarConviteSMS,
                enviarTokenSMS: signatario.enviarTokenSMS,
                tipoAssinatura: signatario.tipoAssinatura,
                statusSignatario: signatario.statusSignatario,
                disponibilidadeAssinatura: signatario.disponibilidadeAssinatura,
                assinaturaManuscrita: signatario.assinaturaManuscrita,
                representanteLegal: signatario.representanteLegal,
                mensagem: signatario.mensagem,
                rejeitarDocumento: signatario.rejeitarDocumento
            }
        },
        async refreshSignatarios() {
            if (this.contrato.id !== undefined && this.minutaAtual.id !== undefined && this.ehMinutaEmFormalizacaoOuRascunho) {
                this.desabilitarLoadingGlobal()
                await this.buscarSignatarios()
                this.habilitarLoadingGlobal()
            }
        },
        signatariosPendentesEnvioConvite() {
            return this.signatarios.filter(signatario => signatario.statusSignatario === 'NAO_CONVIDADO' && signatario.disponibilidadeAssinatura !== 'PRESENCIAL').length
        },
        verificarPossuiSignatarioNaoAssinou(){
            return this.signatarios.some(signatario => signatario.statusSignatario !== 'ASSINADO')
        },
        validarCamposVazios(signatario) {
            if (signatario.ddi === '55' && signatario.telefone !== null && signatario.telefone.length === 10)
                signatario.telefone = signatario.telefone.substr(0, 2) + '9' + signatario.telefone.substr(2, signatario.telefone.length)

            if (signatario.mensagem === null || signatario.mensagem.length === 0)
                signatario.mensagem = null

            signatario.telefone = (signatario.telefone == null || signatario.telefone.length === 0) ? null : signatario.telefone
        }
    }
}
</script>

<style lang="stylus">
.co-contrato-sem-assinantes
    display block
    text-align center

    span
        display block
        margin 10px 30px
        color #777

.clm-assinaturas
    height 100%

    .area-sigatarios
        height: 100%
        overflow-y auto

        .titulo
            align-items center
            color #777
            font-size 14px
            text-transform uppercase
            padding 0 20px
            border-bottom 1px solid #ddd
            vertical-align center
            justify-content space-between
            padding-top 5px !important
            height 35px
            display block
            margin-top 10px
            padding-bottom 45px

    .titulo-assinatura
        display flex
        align-items center
        color #777
        font-size 14px
        text-transform uppercase
        padding 0 20px
        border-bottom 1px solid #ddd
        vertical-align center
        justify-content space-between

    .subtitulo-assinatura
        display flex
        align-items center
        color #777
        font-size 12px
        border-bottom 1px solid #ddd
        padding 0 20px
        height 36px
        vertical-align center

    .area-com-assinaturas
        height calc(100% - 201px)
        overflow-y auto
        overflow-x hidden

        .icone-adicionar
            display block
            text-align center


    .area-sem-assinaturas

        overflow-y auto
        display block
        text-align center
        color #777

        a
            color #66BB6A

    .acoes
        position relative

.abas-novo
    display flex !important
    align-items center !important
    color green
    font-size 12px
    font-weight bold
</style>
