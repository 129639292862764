<template>
    <v-flex class="co-upload-envelope">

        <envelope v-if="!possuiPdf" @exibir-lista-pdf="adicionarPdfs"/>

        <lista-pdf v-else :pdfs="pdfs" @adicionar-pdf="adicionarPdfs" @remover-pdf="removerPdf"/>

    </v-flex>
</template>

<script>
import ListaPdf from '@/views/contrato/novo/upload-envelope/components/ListaPdf/ListaPdf'
import Envelope from '@/views/contrato/novo/upload-envelope/components/envelope/Envelope'

export default {
    name: 'UploadEnvelope',
    components: {Envelope, ListaPdf},
    data() {
        return {
            pdfs: []
        }
    },
    computed: {
        possuiPdf() {
            return this.pdfs.length > 0
        }
    },
    methods: {
        adicionarPdfs(files) {
            this.pdfs.push(...files)
            this.$emit('pdfs', this.pdfs)
        },
        removerPdf(pdf) {
            const index = this.pdfs.indexOf(pdf)
            if (index > -1) {
                this.pdfs.splice(index, 1)
            }
            this.$emit('pdfs', this.pdfs)
        }
    }
}
</script>