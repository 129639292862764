<template>
    <div>
        <div class="definir-ordem" :style="desabilitarDefinirOrdemAssinatura ? 'cursor: not-allowed;' : ''">
            <v-checkbox
                v-if="mostrarDefinirOdemAssinaturas"
                hide-details
                label="Definir ordem de assinaturas"
                :disabled="desabilitarDefinirOrdemAssinatura"
                @change="definirOrdemAssinatura"
                v-model="ordenarAssinaturas"
                :color="corCheckbox"
            />

            <v-tooltip top v-if="mostrarDefinirOdemAssinaturas">
                <template v-slot:activator="{ on }">
                    <span v-on="on" class="definir-ordem-tooltip">
                        <v-icon size="16px" :color="corTooltip"> mdi-information </v-icon>
                    </span>
                </template>

                <span v-if="!desabilitarDefinirOrdemAssinatura">Você pode definir uma sequência para os {{ $tc("pt_br.signatario", 2).toLowerCase() }}. <br/> O próximo só poderá assinar depois que o anterior tenha assinado.</span>
                <span v-else-if="possuiSignatarioRepetido">Você só pode desabilitar a ordem de assinatura caso não possua {{ $tc("pt_br.signatario", 2).toLowerCase() }} repetidos no {{ $tc("pt_br.documento").toLowerCase() }}.</span>
                <span v-else>Só é possível definir uma ordem de assinatura caso ainda não tenha enviado convites para {{ $tc("pt_br.signatario", 2).toLowerCase() }} nesse {{ $tc("pt_br.documento").toLowerCase() }}.</span>
            </v-tooltip>
        </div>
        <v-divider></v-divider>
        <div :style="tamanhoListagem" v-resize="tamanhoListaResize">
            <draggable
                :list="signatarios"
                :sort="ordenarAssinaturas"
                draggable=".pode-mover"
                @change="redefinirOrdemSignatarios">
                <signatario v-for="(signatario, index) in signatarios"
                            :key="signatario.id"
                            :class="getClassMoverSignatario(signatario)"
                            :signatario="signatario"
                            :presencial-pode-assinar="presencialPodeAssinar(index)"
                            @adicionarChaveSeguranca="abrirClmOpsChaveSeguranca"
                            @editar="editarSignatario"
                            @atualizarTelefoneSignatario="atualizarTelefoneSignatario"
                            @atualizarChaveSeguranca="atualizarChaveSeguranca"
                            @enviarChaveSegurancaPorSMS="enviarChaveSegurancaPorSMS"
                            @convidarPorEmail="convidarPorEmail"
                            @convidarPorSMS="convidarPorSMS"
                            @convidarPorWhatsApp="convidarPorWhatsApp"
                            @convidarPresencial="convidarPresencial(signatario, index)"
                            @dadosSignatario="abrirClmOpsDadosSignatario"
                            @historico="possuiHistorico"
                            @remover="confirmarRemocaoSignatario"
                            @reenviarConviteEmail="reenviarConviteEmail"
                            @salvar="inserirSignatario"
                            @salvarTelefoneSignatario="atualizarTelefoneSignatario"
                />
            </draggable>

            <clm-dialog v-model="dialogRemoverSignatario" class="dialog-excluir">
                <clm-ops sentimento="assustado">
                    <div slot="titulo">Eita! Deixa eu confirmar uma coisa...</div>
                    <div slot="subtitulo">Você quer mesmo excluir o {{ $tc("pt_br.signatario").toLowerCase() }}?</div>
                    <div slot="acoes">
                        <div class="principal">
                            <a @click="remover">Sim, eu quero excluir!</a>
                        </div>

                        <div class="secundaria">
                            <a @click="fecharDialogRemocao">Não, obrigado</a>
                        </div>
                    </div>
                </clm-ops>
            </clm-dialog>

            <clm-dialog v-model="dialogConviteReenviado" class="dialog-convite-enviado">
                <clm-ops sentimento="feliz">
                    <div slot="titulo">Tudo certo...</div>

                    <div v-if="!enviarPorEmailSMS" slot="subtitulo">O convite foi enviado para
                        {{ signatarioReenvioConvite.email }}
                    </div>

                    <div v-else slot="subtitulo">O convite foi enviado para
                        {{ signatarioReenvioConvite.email }} e para o número {{ signatarioReenvioConvite.telefone }}
                    </div>

                    <div slot="acoes">
                        <div class="secundaria">
                            <a @click="fecharDialogConviteReenvidado">Fechar</a>
                        </div>
                    </div>
                </clm-ops>
            </clm-dialog>

            <clm-ops-chave-seguranca
                :clm-ops-chave-seguranca="clmOpsChaveSeguranca"
                :signatario="signatarioEdicao"
                @fecharClmOpsChaveSeguranca="fecharClmOpsChaveSeguranca"
                @atualizarChaveSeguranca="atualizarChaveSeguranca"
            />

            <clm-ops-dados-signatario
                :assinatura-manuscrita-base64="assinaturaManuscritaBase64"
                :clm-ops-dados-signatario="clmOpsDadosSignatario"
                :signatario="signatario"
                @atualizarChaveSeguranca="editarSignatario"
                @fecharClmOpsDadosSignatario="fecharClmOpsDadosSignatario"
            />

            <historico
                v-if="dialogHistorico"
                :dialog-historico="dialogHistorico"
                :signatario="signatario"
                :historico="historico"
                @fecharHistorico="fecharDialogHistorico"
            />

            <clm-ops-nao-possui-historico
                v-if="naoPossuiHistorico"
                :nao-possui-historico="naoPossuiHistorico"
                @fecharClmOpsNaoPossuiHistorico="fecharClmOpsNaoPossuiHistorico"
            />
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {actionTypes} from '@/commons/constants'
import _ from 'lodash'
import ClmDialog from '@/commons/components/ClmDialog'
import ClmOps from '@/commons/components/ClmOps'
import ClmOpsChaveSeguranca from './ClmOpsChaveSeguranca'
import ClmOpsDadosSignatario from './ClmOpsDadosSignatario'
import Signatario from './Signatario'
import convidarSignatarioPorWhatsApp from '../../../convidar-signatario-por-whatsApp'
import Historico from '@/views/contrato/detalhe/area-trabalho/assinaturas/Historico'
import ClmOpsNaoPossuiHistorico from '@/views/contrato/detalhe/area-trabalho/assinaturas/ClmOpsNaoPossuiHistorico'

export default {
    name: 'ListaSignatarios',
    components: {
        ClmDialog,
        ClmOps,
        ClmOpsChaveSeguranca,
        ClmOpsDadosSignatario,
        Signatario,
        Historico,
        ClmOpsNaoPossuiHistorico
    },
    data() {
        return {
            assinaturaManuscritaBase64: '',
            clmOpsChaveSeguranca: false,
            clmOpsDadosSignatario: false,
            dialogHistorico: false,
            convitePresencial: '',
            enviarPorEmailSMS: false,
            dialogConviteReenviado: false,
            dialogRemoverSignatario: false,
            naoPossuiHistorico: false,
            ordenarAssinaturas: this.$store.state.minutaAtual.ordemAssinaturas,
            signatario: {},
            signatarioEdicao: {},
            signatarioRemocao: {},
            signatarioReenvioConvite: {},
            historico: [],
            tamanhoListaSignatarios: ''
        }
    },
    computed: {
        ...mapState(['signatarios', 'minutaAtual', 'contrato', 'login']),
        contratoId() {
            return this.contrato.id
        },
        corTooltip(){
            return this.desabilitarDefinirOrdemAssinatura ? 'primary' : ''
        },
        corCheckbox(){
            return this.desabilitarDefinirOrdemAssinatura ? 'secondary-400' : 'primary'
        },
        desabilitarDefinirOrdemAssinatura() {
            return this.minutaAtual.envioConvitesIniciados || this.possuiSignatarioRepetido
        },
        ehMinutaEmRascunho() {
            return this.minutaAtual.situacaoFormalizacao === 'RASCUNHO'
        },
        ehMinutaEmFormalizacao() {
            return this.minutaAtual.situacaoFormalizacao === 'EM_FORMALIZACAO'
        },
        ehMinutaEmFormalizacaoOuRascunho() {
            return this.ehMinutaEmFormalizacao || this.ehMinutaEmRascunho
        },
        possuiSignatarioConvidado() {
            const desabilitado = _.find(this.signatarios, (signatario) => signatario.statusSignatario === 'CONVIDADO')
            return desabilitado !== undefined
        },
        possuiSignatarioAssinado() {
            const desabilitado = _.find(this.signatarios, (signatario) => signatario.statusSignatario === 'ASSINADO')
            return desabilitado !== undefined
        },
        possuiSignatarioRejeitado() {
            const desabilitado = _.find(this.signatarios, (signatario) => signatario.statusSignatario === 'REJEITADO')
            return desabilitado !== undefined
        },
        possuiSignatarioRepetido() {
            const emails = this.signatarios.map(signatario => signatario.email).filter(valores => valores)
            const telefones = this.signatarios.map(signatario => signatario.telefone).filter(valores => valores)

            const signatariosSemRepeticaoEmail = new Set(emails)
            const signatariosSemRepeticaoTelefone = new Set(telefones)

            return emails.length !== signatariosSemRepeticaoEmail.size || telefones.length !== signatariosSemRepeticaoTelefone.size
        },
        possuiSignatarioAguardandoConvite() {
            const desabilitado = _.find(this.signatarios, (signatario) => signatario.statusSignatario === 'NAO_CONVIDADO')
            return desabilitado !== undefined
        },
        minutaCanceladaOuFormalizada() {
            return this.minutaAtual.situacaoFormalizacao === 'FORMALIZADO' || this.minutaAtual.situacaoFormalizacao === 'CANCELADO'
        },
        mostrarDefinirOdemAssinaturas() {
            return !this.minutaCanceladaOuFormalizada
        },
        minutaId() {
            return this.minutaAtual.id
        },
        tamanhoListagem() {
            return this.tamanhoListaSignatarios
        }
    },
    methods: {
        ...mapActions([
            actionTypes.BUSCAR_SIGNATARIOS,
            actionTypes.BUSCAR_ORGANIZACAO,
            actionTypes.BUSCAR_MINUTA_ATUAL,
            actionTypes.BUSCAR_HISTORICO_MENSAGENS,
            actionTypes.CONVIDAR_SIGNATARIO_POR_EMAIL,
            actionTypes.CONVIDAR_SIGNATARIO_POR_EMAIL_SMS,
            actionTypes.CONVIDAR_SIGNATARIO_POR_SMS,
            actionTypes.CONVIDAR_SIGNATARIO_PRESENCIAL,
            actionTypes.REENVIAR_CONVITE_SIGNATARIO,
            actionTypes.REMOVER_SIGNATARIO,
            actionTypes.REDEFINIR_ORDEM_SIGNATARIOS,
        ]),
        abrirDialogHistorico() {
            this.dialogHistorico = true
        },
        abrirClmOpsChaveSeguranca(signatario) {
            this.fecharDialogRemocao()
            this.fecharClmOpsDadosSignatario()
            this.fecharDialogConviteReenvidado()
            this.signatarioEdicao = signatario
            this.clmOpsChaveSeguranca = true
        },
        abrirClmOpsDadosSignatario(signatario) {
            this.fecharDialogRemocao()
            this.fecharClmOpsChaveSeguranca()
            this.fecharDialogConviteReenvidado()
            this.signatario = signatario
            this.clmOpsDadosSignatario = true
            if (this.signatario.assinaturaManuscrita) {
                this.converterAssinaturaManuscritaBase64()
            } else {
                this.assinaturaManuscritaBase64 = ''
            }
        },
        abrirClmOpsNaoPossuiHistorico() {
            this.naoPossuiHistorico = true
        },
        async atualizarListagemSignatarios() {
            this.setMensagemLoading(`Atualizando listagem de ${this.$tc("pt_br.signatario", 2).toLowerCase()}...`)
            await this.buscarSignatarios()
        },
        editarSignatario(signatario) {
            this.$emit('editarSignatario', signatario)
        },
        atualizarChaveSeguranca(signatario) {
            this.$emit('atualizarChaveSeguranca', signatario)
        },
        atualizarTelefoneSignatario(signatario) {
            this.$emit('atualizarTelefoneSignatario', signatario)
        },
        async buscarSignatarios() {
            await this.$store.dispatch(actionTypes.BUSCAR_SIGNATARIOS, {
                contratoId: this.contratoId,
                minutaId: this.minutaId
            })
        },
        confirmarRemocaoSignatario(signatario) {
            this.fecharClmOpsDadosSignatario()
            this.fecharClmOpsChaveSeguranca()
            this.fecharDialogConviteReenvidado()
            this.dialogRemoverSignatario = true
            this.signatarioRemocao = signatario
        },
        async convidarPorSMS(signatario) {
            await this.convidarSignatarioPorSMS(
                {
                    contratoId: this.contratoId,
                    minutaId: this.minutaId,
                    signatarioId: signatario.id
                }
            )
            await this.$store.dispatch(actionTypes.COMUN.BUSCAR_CONSUMO_ORGANIZACAO)
            this.mostrarNotificacaoSucessoDefault()
        },
        async convidarPorEmail(signatario) {
            this.signatarioReenvioConvite = signatario
            try {
                this.setMensagemLoading('Enviando convite...')
                await this.convidarSignatarioPorEmail({
                    contratoId: this.contratoId,
                    minutaId: this.minutaId,
                    signatarioId: this.signatarioReenvioConvite.id
                })
                await this.atualizarListagemSignatarios()
                this.enviarPorEmailSMS = false
                this.mostrarDialogConviteReenvidado()
            } catch (e) {
                await this.atualizarListagemSignatarios()
            }
        },
        convidarPorWhatsApp(signatario) {
            convidarSignatarioPorWhatsApp.enviarWhatsApp(signatario, this.contrato.nome, this.login.organizacaoPlano.organizacaoNome)
        },
        async converterAssinaturaManuscritaBase64() {
            const assinaturaManuscrita = await this.visualizarAssinaturaManuscrita()
            this.assinaturaManuscritaBase64 = 'data:image/png;base64,' + assinaturaManuscrita
        },
        async convidarPresencial(signatario, index) {
            if (this.presencialPodeAssinar(index)) {
                this.convitePresencial = await this.convidarSignatarioPresencial({
                    contratoId: this.contratoId,
                    minutaId: this.minutaId,
                    signatarioId: signatario.id
                })
                window.open(this.convitePresencial, '_blank')
            }
        },
        async definirOrdemAssinatura() {
            if (this.ordenarAssinaturas) {
                const payload = this.montarPayloadRedefinirOrdemSignatarios()
                await this.$store.dispatch(actionTypes.DEFINIR_ORDEM_ASSINATURA, {
                    contratoId: this.contratoId,
                    minutaId: this.minutaId,
                    ordemSignatarios: payload
                })
                this.$store.state.minutaAtual.ordemAssinaturas = true
                await this.buscarSignatarios()
            } else {
                await this.$store.dispatch(actionTypes.REMOVER_ORDEM_ASSINATURA, {
                    contratoId: this.contratoId,
                    minutaId: this.minutaId
                })
                this.$store.state.minutaAtual.ordemAssinaturas = false
                await this.buscarSignatarios()
            }
        },
        ehSignatarioNaoConvidado(signatario) {
            return signatario.statusSignatario === 'NAO_CONVIDADO'
        },
        async enviarChaveSegurancaPorSMS(signatario) {
            this.setMensagemLoading('Enviando SMS com chave de segurança...')
            await this.$store.dispatch(actionTypes.ENVIAR_CHAVE_SEGURANCA_POR_SMS, {
                contratoId: this.contratoId,
                minutaId: this.minutaId,
                signatarioId: signatario.id
            })
            this.mostrarNotificacaoSucessoDefault()
            await this.buscarOrganizacao()
        },
        fecharDialogRemocao() {
            this.dialogRemoverSignatario = false
        },
        fecharDialogHistorico() {
            this.dialogHistorico = false
        },
        fecharDialogConviteReenvidado() {
            this.dialogConviteReenviado = false
            this.enviarPorEmailSMS = false
            this.signatarioReenvioConvite = {}
        },
        fecharClmOpsChaveSeguranca() {
            this.clmOpsChaveSeguranca = false
        },
        fecharClmOpsDadosSignatario() {
            this.clmOpsDadosSignatario = false
        },
        fecharClmOpsNaoPossuiHistorico() {
            this.naoPossuiHistorico = false
        },
        getClassMoverSignatario(signatario) {
            return this.ehSignatarioNaoConvidado(signatario) && this.ehMinutaEmFormalizacaoOuRascunho && this.minutaAtual.ordemAssinaturas ? 'pode-mover' : 'nao-pode-mover'
        },
        inserirSignatario(novoSignatario) {
            this.$emit('salvar', novoSignatario)
        },
        mostrarDialogConviteReenvidado() {
            this.dialogConviteReenviado = true
        },
        montarPayloadRedefinirOrdemSignatarios() {
            let payload = []
            this.signatarios.forEach((signatario, index) => {
                payload.push({signatarioId: signatario.id, ordemEnvio: index})
            })
            return payload
        },
        async possuiHistorico(signatario) {
            this.signatario = signatario
            await this.signatarioComHistorico()
            if (this.historico.length !== 0) {
                this.abrirDialogHistorico()
            } else {
                this.abrirClmOpsNaoPossuiHistorico()
            }
        },
        presencialPodeAssinar(index) {
            return !this.ordenarAssinaturas || this.verificarSeEhProximoAssinar(index)
        },
        async remover() {
            try {
                this.setMensagemLoading(`Removendo ${this.$tc("pt_br.signatario").toLowerCase()}...`)
                await this.removerSignatario({
                    contratoId: this.contratoId,
                    minutaId: this.minutaId,
                    signatarioId: this.signatarioRemocao.id
                })
                this.fecharDialogRemocao()
                await this.atualizarListagemSignatarios()
                if (this.minutaAtual.ordemAssinaturas && this.possuiSignatarioAguardandoConvite) {
                    await this.redefinirOrdemSignatarios()
                }
                await this.buscarMinutaAtual(this.contratoId)
                this.mostrarNotificacaoSucessoDefault()
            } catch (e) {
                await this.atualizarListagemSignatarios()
            }
        },
        async redefinirOrdem(payload) {
            await this.$store.dispatch(actionTypes.REDEFINIR_ORDEM_SIGNATARIOS, {
                contratoId: this.contratoId,
                minutaId: this.minutaId,
                ordemSignatarios: payload
            })
        },
        async redefinirOrdemSignatarios() {
            const payload = this.montarPayloadRedefinirOrdemSignatarios()
            await this.redefinirOrdem(payload)
            await this.buscarSignatarios()
        },
        async reenviarConviteEmail(signatario) {
            this.signatarioReenvioConvite = signatario
            try {
                this.setMensagemLoading('Reenviando convite...')
                await this.reenviarConviteSignatario({
                    contratoId: this.contratoId,
                    minutaId: this.minutaId,
                    signatario: this.signatarioReenvioConvite
                })
                await this.atualizarListagemSignatarios()
                this.enviarPorEmailSMS = false
                this.mostrarNotificacaoSucesso('Convite reenviado!')
            } catch (e) {
                await this.atualizarListagemSignatarios()
            }
        },
        async signatarioComHistorico() {
            this.historico = await this.$store.dispatch(actionTypes.BUSCAR_HISTORICO_MENSAGENS, {
                contratoId: this.contratoId,
                minutaId: this.minutaId,
                signatarioId: this.signatario.id
            })
        },
        tamanhoListaResize() {
            const element = document.getElementsByClassName('area-sigatarios')[0]
            const altura = element.clientHeight - 145 + 'px'
            this.tamanhoListaSignatarios = `width: 100%;position: relative; height: ${altura}; overflow-y: auto;`
        },
        verificarSeEhProximoAssinar(index) {
            if (index === 0) {
                return true
            } else {
                let anterior = this.signatarios[index - 1]
                if (anterior.statusSignatario === 'ASSINADO' || anterior.statusSignatario === 'REJEITADO') {
                    return true
                }
            }
            return false
        },
        async visualizarAssinaturaManuscrita() {
            return await this.$store.dispatch(actionTypes.VISUALIZAR_ASSINATURA_MANUSCRITA, {
                contratoId: this.contrato.id,
                minutaId: this.minutaAtual.id,
                signatarioId: this.signatario.id
            })
        }
    }
}
</script>
<style lang="stylus">
.pode-mover
    &:hover
        cursor move
        opacity 0.9

.nao-pode-mover
    &:hover
        cursor default
        opacity 0.9

.definir-ordem
    display flex
    justify-content space-between

    .v-input--selection-controls
        margin 5px 0 5px 20px !important
        padding 0 !important

        .v-input--selection-controls__input
            width 17px !important

            .v-icon
                font-size 18px !important

    .v-label
        font-size 12px !important
        color var(--secondary-400) !important

.definir-ordem-tooltip
    color var(--secondary-400) !important
    display flex
    font-size 18px !important
    margin-right 21px

</style>