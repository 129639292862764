import {actionTypes, mutationTypes} from '@/commons/constants'
import {mapState} from 'vuex'
import _ from 'lodash'

export default {
    computed: {
        ...mapState([
            'meusContratosBusca',
            'pastaRaiz',
            'pastaAtual',
            'subPastasAtual',
            'pastasParaAtualizar'
        ])
    },
    methods: {
        async adicionarPastaNovaSemPastaPai(pastaNovaDTO, pastaPaiId) {
            const pastaNova = await this.$store.dispatch(actionTypes.PASTAS.INSERIR_PASTA, pastaNovaDTO)
            this.$store.commit(mutationTypes.PASTAS.ADD_PASTAS_PARA_ATUALIZAR, pastaPaiId)
            return pastaNova
        },
        async adicionarPastaNova(pastaNovaDTO, pastaPai) {
            const pastaNova = await this.$store.dispatch(actionTypes.PASTAS.INSERIR_PASTA, pastaNovaDTO)
            if (pastaPai.ultimaPaginaBusca) {
                pastaPai.pastas.push(pastaNova)
                this.ordenarPastas(pastaPai)
                pastaPai.totalPastas += 1
                if (this.pastaAtual.pastaId === pastaPai.pastaId) {
                let pastas = _.clone(this.subPastasAtual)
                this.$store.commit(mutationTypes.PASTAS.SET_PASTA_ATUAL_CONTENT, pastas)
                }
            } else {
                this.resetarPasta(this.pastaAtual)
            }
            return pastaNova
        },
        adicionarPastasNaLista(novasPastas) {
            const pastas = this.pastaAtual.pastas.concat(novasPastas)
            this.$store.commit(mutationTypes.PASTAS.SET_PASTA_ATUAL_CONTENT, pastas)
        },
        async atualizarPasta(pastaNew, pasta, pastaPai) {
            pastaNew = await this.$store.dispatch(actionTypes.PASTAS.ATUALIZAR_PASTA, pastaNew)
            if (pastaPai.pastaId === pastaNew.pastaPaiId) {
                pasta.pastaNome = pastaNew.pastaNome
            }
            this.ordenarPastas(pastaPai)
        },
        async buscarPastas() {
            let paginacao = this.incrementarPaginaPastaAtual()
            paginacao = Object.assign({}, this.pastaRaiz.paginacao, paginacao)

            const cor = this.meusContratosBusca.filtros.cor.value !== ''
            const tags = this.meusContratosBusca.filtros.tags.value !== ''
            const nome = this.meusContratosBusca.filtros.nome.value !== ''
            const dataCriacaoInicio = this.meusContratosBusca.filtros.dataCriacaoInicio.value !== ''
            const dataCriacaoFim = this.meusContratosBusca.filtros.dataCriacaoFim.value !== ''

            let paginaPastas = null
            if (cor || tags || nome || dataCriacaoInicio || dataCriacaoFim) {
                paginaPastas = await this.$store.dispatch(actionTypes.PASTAS.BUSCAR_PASTAS_FILTRADAS, paginacao)
            } else {
                paginaPastas = await this.$store.dispatch(actionTypes.PASTAS.BUSCAR_PASTAS_PAGINADAS, paginacao)
            }
            if (this.ehUltimaPaginaPastas(paginaPastas)) {
                this.previnirLoopInfinitoPastas()
            }
            this.adicionarPastasNaLista(paginaPastas.content)
            return paginaPastas
        },
        ehUltimaPaginaPastas(pagina) {
            return pagina.number >= pagina.totalPages - 1
        },
        async entrarNaPasta(pasta) {
            await this.preparPasta(pasta)
            await this.$store.commit(mutationTypes.PASTAS.ENTRAR_NA_PASTA, pasta)
        },
        excluirPasta(pastaPai, pastaId) {
            const indexP = pastaPai.pastas.findIndex(p => p.pastaId === pastaId)
            if (indexP !== -1) {
                const pasta = _.clone(pastaPai.pastas[indexP])
                pastaPai.pastas.splice(indexP, 1);
                pastaPai.totalPastas -= 1
                return pasta
            }
            return null
        },
        getPastaPaginacaoAtual() {
            let paginacaoAtual = this.pastaAtual.paginacao
            if (paginacaoAtual == null) {
                paginacaoAtual = {
                    page: 0
                }
            }
            return paginacaoAtual
        },
        incrementarPaginaPastaAtual() {
            let paginacao = this.getPastaPaginacaoAtual()
            paginacao.page += 1
            return paginacao
        },
        async moverEntrePastasSemPastaPai(pastaPaiOld, pastaId, pastaPaiId) {
            const pasta = this.excluirPasta(pastaPaiOld, pastaId)
            if (!!pasta) {
               this.$store.commit(mutationTypes.PASTAS.ADD_PASTAS_PARA_ATUALIZAR, pastaPaiId)
            } else {
                this.mostrarNotificacaoErro('Falha ao mover, atualize a pagina')
            }
        },
        async moverEntrePastas(pastaPaiOld, pastaPaiNew, pastaId) {
            const pasta = this.excluirPasta(pastaPaiOld, pastaId)
            if (pasta) {
                if (pastaPaiNew.pastas && pastaPaiNew.pastas.length === pastaPaiNew.totalPastas) {
                    pastaPaiNew.totalPastas += 1
                    pastaPaiNew.pastas.push(pasta)
                    this.ordenarPastas(pastaPaiNew)
                } else {
                    this.resetarPasta(pastaPaiNew)
                }
            }
            else {
                this.mostrarNotificacaoErro('Falha ao mover, atualize a pagina')
            }
        },
        ordenarPastas(pasta) {
            pasta.pastas.sort((a, b) => a.pastaNome.localeCompare(b.pastaNome))
        },
        preparPasta(pasta) {
            if (!pasta.pastas) {
                pasta.paginacao = {}
                this.resetarPasta(pasta)
            }
        },
        previnirLoopInfinitoPastas() {
            this.pastaAtual.ultimaPaginaBusca = true
        },
        resetarPasta(pasta) {
            const paginacao = {
                page: 0
            }
            if (pasta.paginacao) {
                pasta.paginacao = Object.assign({}, pasta.paginacao, paginacao)
            } else {
                pasta.paginacao = paginacao
            }
            pasta.pastas = []
            pasta.totalPastas = null
            pasta.ultimaPaginaBusca = false
            pasta.buscaFiltrada = false
        },
        async validarPastaAtual() {
            if (! !!this.pastaAtual) {
                await this.$store.commit(mutationTypes.PASTAS.ENTRAR_NA_PASTA, this.pastaRaiz)
            }
        }
    }
}
