import axios from 'axios'
import {actionTypes, mutationTypes} from '@/commons/constants'
import _ from 'lodash'

export default {
    async [actionTypes.CONFIGURACAO.ACEITAR_TERMO_USO_SIGNATARIOS_PAGANTES]({state}) {
        const organizacaoId = state.login.organizacaoPlano.organizacaoId
        await axios.put(`api/configuracoes/organizacoes/${organizacaoId}/aceitar-termo-signatario-pagante`)
    },

    async [actionTypes.CONFIGURACAO.BUSCAR_ORGANIZACAO_CONFIGURACOES]({commit}) {
        const {data} = await axios.get('api/configuracoes-painel')
        commit(mutationTypes.LOGIN.SET_ORGANIZACAO_CONFIGURACAO, data)
    },

    async [actionTypes.CONFIGURACAO.ATUALIZAR_ORGANIZACAO_CONFIGURACOES_GERAIS]({state, commit}, configuracoes) {
        const {data} = await axios.put('api/configuracoes-painel/gerais', configuracoes)
        commit(mutationTypes.LOGIN.SET_ORGANIZACAO_CONFIGURACAO_GERAL, _.merge(state.login.organizacaoConfiguracao.geral, configuracoes))
        return data
    },

    async [actionTypes.CONFIGURACAO.ATUALIZAR_ORGANIZACAO_CONFIGURACOES_NOTIFICACOES]({state, commit}, configuracoes) {
        const {data} = await axios.put('api/configuracoes-painel/notificacoes', configuracoes)
        commit(mutationTypes.LOGIN.SET_ORGANIZACAO_CONFIGURACAO_NOTIFICACAO, _.merge(state.login.organizacaoConfiguracao.notificacao, configuracoes))
        return data
    },

    async [actionTypes.CONFIGURACAO.CRIAR_TOKEN_API_V2]({state}, {usuarioId}) {
        const organizacaoId = state.login.organizacaoPlano.organizacaoId
        const {data} = await axios.put(`api/configuracoes-usuarios/organizacoes/${organizacaoId}/usuarios/${usuarioId}`)
        return data
    },
}