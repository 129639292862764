<template>
    <v-card-text class="co-dialog-cancelar-plano">
        <div class="text-center">
            <div class="subtitulo">
                Não é possível realizar o cancelamento automático pela plataforma, pois você
                possui um plano anual contratado, entre em contato através do chat de suporte, e-mail <a href="mailto:contato@besign.com.br">contato@besign.com.br</a>
                ou através do telefone <a href="phoneto:+551140042081">(11) 4004-2081</a>.
            </div>
        </div>
    </v-card-text>
</template>
 
<script>
 
export default {
    name: 'MensagemPlanoAnual',
}
</script>