<template>
    <div class="co-dados-organizacao-form" :class="ehBgTelaNovaOrganizacao">
        <v-container class="d-flex container" :class="ehBgTelaNovaOrganizacao">
            <span class="titulo-secao font-weight-bold pa-3">Dados</span>
            <v-row no-gutters>
                <v-col cols="12" md="6">
                    <az-combo-enum
                        class="required-field mx-3"
                        v-validate="'required'"
                        :error-messages="errors.collect('Tipo')"
                        v-model="organizacaoValue.tipoPessoa"
                        :enum-object="pessoaTipo"
                        is-required
                        label="Tipo"
                        name="Tipo"/>
                </v-col>

                <v-col cols="12" md="6">
                    <v-text-field
                        class="required-field mx-3"
                        v-if="pessoaFisica"
                        v-model="organizacaoValue.cnpjCPF"
                        v-validate="'required|cpf'"
                        :error-messages="errors.collect('Documento')"
                        label="CPF"
                        v-mask="['###.###.###-##']"
                        name="Documento"
                        placeholder="000.000.000-00"
                        persistent-placeholder/>

                    <v-text-field
                        class="required-field mx-3"
                        v-if="pessoaJuridica"
                        v-model="organizacaoValue.cnpjCPF"
                        v-validate="'required|cnpj'"
                        :error-messages="errors.collect('Documento')"
                        label="CNPJ"
                        v-mask="['##.###.###/####-##']"
                        persistent-placeholder
                        name="Documento"
                        placeholder="00.000.000/0000-00"/>
                </v-col>

                <v-col cols="12" md="6">
                    <v-text-field
                        class="required-field mx-3"
                        v-model="organizacaoValue.nome"
                        v-validate="'required|max:255'"
                        :error-messages="errors.collect('Nome')"
                        label="Nome"
                        name="Nome"
                        placeholder="ex. Nome Empresa"
                        persistent-placeholder/>
                </v-col>

                <v-col cols="12" md="6">
                    <v-text-field
                        class="required-field mx-3"
                        v-model="organizacaoValue.email"
                        v-validate="'required|email'"
                        :error-messages="errors.collect('E-mail')"
                        label="E-mail"
                        name="E-mail"
                        placeholder="ex. contato@email.com.br"
                        persistent-placeholder/>
                </v-col>

                <v-col cols="12" md="6">
                    <v-text-field
                        class="required-field mx-3"
                        v-validate="'required'"
                        :error-messages="errors.collect('Celular')"
                        v-model="organizacaoValue.telefone"
                        v-mask="['(##) #####-####','(##) ####-####']"
                        label="Celular / WhatsApp"
                        name="Celular"
                        placeholder="(00) 00000-0000"
                        persistent-placeholder/>
                </v-col>
            </v-row>

            <span class="titulo-secao font-weight-bold pa-3">Endereço</span>
            <v-row no-gutters>
                <v-col cols="12" md="3">
                    <v-text-field
                        class="required-field mx-3"
                        v-validate="'required|min:8'"
                        :error-messages="errors.collect('CEP')"
                        @keyup.native.enter="buscarCEP"
                        @blur="buscarCEP"
                        v-model="organizacaoValue.cep"
                        label="CEP"
                        name="CEP"
                        v-mask="['#####-###']"
                        persistent-placeholder
                        placeholder="00000-000"/>
                </v-col>

                <v-col cols="12" md="6">
                    <v-text-field
                        class="required-field mx-3"
                        v-validate="'required'"
                        :error-messages="errors.collect('Rua')"
                        v-model="organizacaoValue.endereco"
                        label="Endereço"
                        name="Endereço"
                        placeholder="Ex. R. 25 de Dezembro"
                        persistent-placeholder/>
                </v-col>

                <v-col cols="12" md="3">
                    <v-text-field
                        class="required-field mx-3"
                        v-validate="'required'"
                        :error-messages="errors.collect('Número')"
                        v-model="organizacaoValue.numero"
                        label="Número"
                        name="Número"
                        v-mask="'##########'"
                        placeholder="Ex. 2071"
                        persistent-placeholder/>
                </v-col>

                <v-col cols="12" md="6">
                    <v-text-field
                        class="required-field mx-3"
                        v-validate="'required'"
                        :error-messages="errors.collect('Bairro')"
                        v-model="organizacaoValue.bairro"
                        label="Bairro"
                        name="Bairro"
                        placeholder="Ex. Nossa Senhora de Fátima"
                        persistent-placeholder/>
                </v-col>

                <v-col cols="12" md="6">
                    <v-text-field
                        class="mx-3"
                        :error-messages="errors.collect('Complemento')"
                        v-model="organizacaoValue.complemento"
                        label="Complemento"
                        name="Complemento"
                        placeholder="Ex. Bloco 1 Apto 2 "
                        persistent-placeholder/>
                </v-col>

                <v-col cols="12" md="6">
                    <v-autocomplete
                        class="required-field mx-3"
                        v-validate="'required'"
                        :error-messages="errors.collect('Estado')"
                        v-model="organizacaoValue.estadoId"
                        :items="estados"
                        item-text="nome"
                        item-value="id"
                        label="Estado"
                        name="Estado"
                        autocomplete="off"
                        placeholder="Selecione"
                        persistent-placeholder/>
                </v-col>

                <v-col cols="12" md="6">
                    <v-autocomplete
                        class="required-field mx-3"
                        v-validate="'required'"
                        :error-messages="errors.collect('Cidade')"
                        v-model="organizacaoValue.municipioId"
                        :items="municipios"
                        item-text="nome"
                        item-value="id"
                        label="Cidade"
                        name="Cidade"
                        autocomplete="off"
                        placeholder="Selecione"
                        persistent-placeholder/>
                </v-col>
            </v-row>

            <v-layout row wrap>
                <v-col cols="12">
                    <v-checkbox
                        v-model="organizacaoValue.emailMarketing"
                        color="primary"
                        hide-details
                        name="Emails Marketing"
                        class="mx-3 mt-0"
                        label="Aceito receber notícias e promoções da Besign"/>
                </v-col>

                <v-col cols="12">
                    <v-checkbox
                        v-model="checkTermo"
                        :v-validate="validarTermoUso()"
                        :error-messages="errors.collect('Termo de Uso')"
                        :color="ehPrimeiroAcesso ? 'primary' : 'gray'"
                        hide-details
                        :readonly="!ehPrimeiroAcesso"
                        name="Termo de Uso"
                        class="mx-3 mt-0">
                            <template v-slot:label>
                                <div>
                                    Eu li e aceito os
                                </div>

                                <a href="https://www.efcaz.com.br/termos-de-uso-e-adesao" @click.stop class="pl-1"
                                target="_blank"> Termos de Uso
                                </a>
                            </template>
                    </v-checkbox>
                </v-col>
            </v-layout>

            <v-layout>
                <v-flex class="text-sm-right mb-5 mr-3">
                    <v-btn v-az-auth="'Organizacao.Editar'"
                        :disabled="disabled"
                        class="botao-salvar"
                        color="primary"
                        depressed
                        @click="salvar">
                            Salvar
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import {actionTypes, tipoPessoa} from '@/commons/constants'
import {mapState} from 'vuex'

export default {
    name: 'DadosOrganizacao',
    props: {
        organizacaoValue: {
            required: true
        },
        disabled: {
            type: Boolean
        },
    },
    data() {
        return {
            municipios: [],
            primeiraVezEditando: true,
            checkTermo: false,
            pessoaTipo: tipoPessoa,
        }
    },
    computed: {
        ...mapState(['estados']),
        ehBgTelaNovaOrganizacao() {
            return this.ehPrimeiroAcesso ? 'bg-nova-organizacao' : ''
        },
        ehPrimeiroAcesso() {
            return !this.$store.state.login.organizacaoPlano.organizacaoExistente
        },
        pessoaFisica() {
            return this.organizacaoValue.tipoPessoa === 'FISICA'
        },
        pessoaJuridica() {
            return this.organizacaoValue.tipoPessoa === 'JURIDICA'
        }
    },
    async mounted() {
        await this.buscarEstados()
        await this.carregarMunicipios()

        if (!this.ehPrimeiroAcesso) {
            this.checkTermo = true
        }
    },
    watch: {
        'organizacaoValue.tipoPessoa'() {
            if (this.ehPrimeiroAcesso) {
                this.organizacaoValue.nome = null
                this.organizacaoValue.cnpjCPF = null
                this.organizacaoValue.email = null
                this.organizacaoValue.telefone = null
            }
        },
        async 'organizacaoValue.estadoId'() {            
            await this.carregarMunicipios()
        },
    },
    methods: {
        async buscarCEP() {
            if (!this.organizacaoValue.cep || this.organizacaoValue.cep.length < 8) {
                return
            }

            this.habilitarLoadingGlobal()

            const endereco = await this.$store.dispatch(actionTypes.ORGANIZACAO.BUSCAR_CEP, this.organizacaoValue.cep)

            if (endereco.erro) {
                this.mostrarNotificacaoErro('CEP informado é inválido')
                this.organizacaoValue.cep = ''

                this.desabilitarLoadingGlobal()
                return
            }

            this.organizacaoValue.endereco = endereco.logradouro
            this.organizacaoValue.bairro = endereco.bairro

            const estado = this.estados.find(estado => estado.sigla === endereco.uf)
            this.organizacaoValue.estadoId = estado.id

            await this.carregarMunicipios()

            const municipio = this.municipios.find(cidade => cidade.nome === endereco.localidade)
            this.organizacaoValue.municipioId = municipio.id
            
            this.desabilitarLoadingGlobal()
        },
        async buscarEstados() {
            if (this.estados && this.estados.length > 0) {
                return
            }

            this.setMensagemLoading('Buscando Estados')

            await this.$store.dispatch(actionTypes.BUSCAR_ESTADOS)
        },
        async buscarMunicipios() {
            this.setMensagemLoading('Buscando Cidades')

            this.municipios = await this.$store.dispatch(actionTypes.BUSCAR_CIDADES, this.organizacaoValue.estadoId)
        },
        async carregarMunicipios() {
            if (this.organizacaoValue.estadoId) {
                await this.buscarMunicipios()
            }
        },
        async salvar() {
            const todosOsCamposValidos = await this.$validator._base.validateAll()

            if (!todosOsCamposValidos) {
                return
            }

            if (this.organizacaoValue.estadoId && !this.organizacaoValue.municipioId) {
                this.mostrarNotificacaoErro('Selecione a cidade.')
                return
            }

            if (this.organizacaoValue.telefone.length < 14) {
                this.mostrarNotificacaoErro('Telefone com formato invalido.')
                return
            }

            this.$emit('salvar')
        },
        validarTermoUso() {
            return this.ehPrimeiroAcesso ? 'required' : ''
        }
    }
}
</script>

<style lang="stylus" scoped>
.co-dados-organizacao-form
    width 100%
    background-color #FFFF

    &.bg-nova-organizacao
        background-color #EEEE
    
    .titulo-secao
        color var(--text-color-300)
        font-size 18px

    .container
        flex-direction column
        background-color #FFFF

        &.bg-nova-organizacao
            background-color #EEEE

    .botao-salvar
        padding 22px

@media (max-width: 720px)
    .co-dados-organizacao-form
        .botao-salvar
            width 100%
            margin-top 30px
</style>
