<template>
    <div class="co-novo-documento-signatario-papel-signatario">
        <label class="label">Papel</label>
        <icon-info class="px-1" :descricao-campo="descricaoCampo"/>
        <v-select
            class="papel-selecionar"
            v-model="value.papelId"
            v-validate="validarCampo()"
            :class="campoRequerido ? 'required-field' : ''"
            :error-messages="errors.collect('Papel assinatura')"
            :items="papeis"
            filled
            attach
            name="Papel assinatura"
            placeholder="Selecione"
            persistent-placeholder
            hide-details
            full-width
            :menu-props="{ minWidth: '210' }"
            :rules="[(v) => !!v || 'Informe o campo obrigatório']">
        </v-select>
    </div>
</template>

<script>
import IconInfo from '@/views/criar-documento/novo-documento-signatario/components/IconInfo'

export default {
    name: 'PapelSignatarioNovo',
    components: {IconInfo},
    props: {
        value: {
            required: true
        },
        campoRequerido: {
            type: Boolean,
            default: false
        },
        descricao: {
            type: Boolean
        },
        papeis: {
            required: true,
            type: Array,
            default: () => []
        }
    },
    computed: {
        descricaoCampo() {
            return `Função que o ${this.$tc("pt_br.signatario").toLowerCase()} desempenhará no momento da assinatura`
        }
    },
    methods: {
        validarCampo() {
            return this.campoRequerido ? 'required' : ''
        }
    }
}
</script>

<style lang="stylus">
.co-novo-documento-signatario-papel-signatario
    display flex

    .label
        color var(--text-color-300)

    .papel-selecionar
        font-size 12px
        max-width 60%

        .v-select__slot
            height 20px

    .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot
        min-height 20px

    .v-text-field.v-text-field--enclosed .v-text-field__details, .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot
        padding 4px

    .v-text-field--enclosed .v-input__append-inner
        margin 0px
        padding 0
</style>