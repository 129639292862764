<template>
    <div class="co-tipo-assinatura-eletronica">
        <div class="tipo-assinatura">

            <div class="tipo-assinatura-click" @click="tipoAssinaturaProximo">
                <div :style="getStyleTitle" class="assinatura-eletronica-title">Eletrônica (E-mail)</div>
            </div>

            <v-spacer></v-spacer>
            <v-icon v-if="false" class="icon-info" size="14" @click="abrirDialogInfo()"> mdi-information </v-icon>
        </div>

        <div :style="getStyleHint" class="assinatura-eletronica-hint">
            {{ descricao }}
        </div>

        <contratar-servico v-if="!eletronicaHabilitado" @abrir-dialog-contratar-servico="abrirDialogContratarServico"/>

        <label-adquirir-saldo v-else/>

    </div>
</template>

<script>
import ContratarServico
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/ContratarServico'
import LabelAdquirirSaldo
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/LabelAdquirirSaldo'

export default {
    name: 'TipoAssinaturaWhatsapp',
    components: {LabelAdquirirSaldo, ContratarServico},
    props: ['value', 'eletronicaHabilitado'],
    data() {
        return {
            dialogContratarServico: false
        }
    },
    computed: {
        descricao() {
            return `O ${this.$tc("pt_br.signatario").toLowerCase()} informará seus dados, e no passo seguinte receberá um token para concluir o processo de assinatura`
        },
        getStyleHint() {
            return this.eletronicaHabilitado ? 'color: #aaa;' : 'color: rgba(170,170,170,0.5);'
        },
        getStyleTitle() {
            return this.eletronicaHabilitado ? 'color: var(--v-primary-base)' : 'color: rgba(119,119,119,0.4)'
        },
    },
    methods: {
        abrirDialogContratarServico() {
            this.$emit('abrir-dialog-contratar-servico')
        },
        abrirDialogInfo() {
            this.$emit('abrir-dialog-info', 'Eletrônica')
        },
        tipoAssinaturaProximo() {
            if (this.eletronicaHabilitado) {
                this.value.tipoAssinatura = 'ELETRONICA'
                this.$emit('tipo-assinatura-proximo')
            } else {
                this.abrirDialogContratarServico()
            }

        }
    }
}
</script>

<style lang="stylus">
.co-tipo-assinatura-eletronica
    .tipo-assinatura
        display flex

        .tipo-assinatura-click
            display flex
            cursor pointer

            .assinatura-eletronica-title
                font-weight bold

            .assinatura-eletronica-title:hover
                text-decoration-line underline

        .icon-info
            color #ccc

    .assinatura-eletronica-hint
        font-size 12px
        text-align justify
        margin-bottom 8px
</style>

