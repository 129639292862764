<template>
    <v-dialog v-model="dialog" persistent width="800">
        <v-card class="co-dialog-visualizar-pdf">

            <v-toolbar flat dark color="primary" class="co-detalhe-contrato-toolbar">
                <span class="headline titulo">{{ nome }}</span>

                <v-spacer></v-spacer>

                <v-icon @click="fechar"> mdi-window-close </v-icon>
            </v-toolbar>

            <iframe :src="pdf.base64" height="700" width="100%"></iframe>

        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'DialogVisualizarPdf',
    props: ['pdf', 'dialog'],
    data: () => {
        return {
            path: ''
        }
    },
    computed: {
        nome() {
            return this.pdf.name
        }
    },
    methods: {
        fechar() {
            this.$emit('fechar')
        }
    }
}
</script>

<style lang="stylus">
.co-dialog-visualizar-pdf
    .titulo
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
</style>
