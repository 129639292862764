<template>
    <div>
        <v-dialog v-model="dialog" width="600" persistent scrollable>
            <v-card class="co-dialog-selecionar-elemento" max-height="500px" min-height="300px">
                <v-card-title>
                    <div class="title mb-3">Selecione a pasta de destino</div>
                    <v-spacer/>
                    <v-icon size="18" @click="fecharDialogs"> mdi-window-close</v-icon>
                </v-card-title>

                <v-card-subtitle>
                    <v-text-field
                        v-model="search"
                        label="Buscar pasta por nome"
                        flat
                        hide-details
                        clearable
                        clear-icon="mdi-close-circle-outline"
                    ></v-text-field>
                </v-card-subtitle>

                <v-card-text>
                    <v-treeview
                        class="treeview"
                        :active.sync="pastasSelecionadas"
                        :filter="filter"
                        :search="search"
                        :load-children="buscarChildens"
                        :items="pastas"
                        item-disabled="disabled"
                        item-key="pastaId"
                        item-text="pastaNome"
                        hoverable
                        return-object
                        activatable
                        color="primary"
                        transition
                        style="cursor: pointer"
                        open-all
                    >
                        <template v-slot:label="{ item, open }">
                            <div :class="ehElementoNaMesmaPasta(item) ? 'pasta-atual' : ''">
                                <span>{{ item.pastaNome }} </span>
                                <span v-if="ehElementoNaMesmaPasta(item)">(Atual)</span>
                            </div>
                        </template>
                    </v-treeview>
                </v-card-text>

                <v-card-actions>
                    <v-btn depressed color="background-200" @click="fecharDialogs">Cancelar</v-btn>

                    <v-spacer/>

                    <v-btn
                        v-if="podeCriar"
                        color="primary"
                        outlined
                        v-az-auth="'Pasta.Novo'"
                        :disabled="!ehPastaSelecionada(pastasSelecionadas[0])"
                        @click="abrirDialogNovaPasta"
                    >
                        Nova Pasta
                    </v-btn>

                    <v-btn
                        color="primary"
                        :disabled="!ehPastaSelecionada(pastasSelecionadas[0])"
                        @click="selecionar(pastasSelecionadas[0])"
                    >
                        SELECIONAR
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <dialog-pasta
            v-az-auth="'Pasta.Novo'"
            v-if="dialogPasta"
            :dialog="dialogPasta"
            :pasta-pai="null"
            :pasta-pai-id="this.pastasSelecionadas[0].pastaId"
            @fechar="fecharDialogPasta"
            @pasta-nova="selecionar"/>
    </div>
</template>

<script>
import OptionsPicker from '@/commons/components/OptionsPicker'
import {mapGetters, mapState} from 'vuex'
import {actionTypes} from '@/commons/constants'
import DialogPasta from '@/views/common/dialogs/DialogPasta'
import Utils from '@/commons/utils/Utils'

export default {
    name: 'DialogSelecionarPasta',
    components: {DialogPasta, OptionsPicker},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        ehSubPastaLiberada: {
            type: Function,
            default: () => true
        },
        pastaSelecionada: {
            type: Object,
        },
        podeCriar: {
            type: Boolean,
            default: false
        },
        verificarPastaSelecionada: {
            type: Function,
            default: () => true
        }
    },
    data() {
        return {
            dialogPasta: false,
            caseSensitive: false,
            pastasSelecionadas: [],
            search: null,
            pastas: [],
        }
    },
    async created() {
        this.prepararSelecionarPasta()
    },
    computed: {
        ...mapState(['organizacao', 'login']),
        ...mapGetters(['podeCriarContratoRaiz', 'ehAdministradorOrganizacao']),
        filter() {
            return this.caseSensitive
                ? (item, search, textKey) => item[textKey].indexOf(search) > -1
                : undefined
        },
    },
    methods: {
        abrirDialogNovaPasta() {
            this.dialogPasta = true
        },
        async buscarChildens(pastaAberta) {
            const pastasAcesso = await this.buscarSubPastas(pastaAberta.pastaId)
            if (pastasAcesso.length > 0) {
                pastasAcesso.forEach(pasta => {
                    if (this.ehSubPastaLiberada(pasta)) {
                        pastaAberta.children.push({
                            ...pasta,
                            children: []
                        })
                    }
                })
            } else {
                pastaAberta.children = null
            }
        },
        async buscarSubPastas(pastaId) {
            if (pastaId === null) {
                pastaId = 0
            }
            return await this.$store.dispatch(actionTypes.PASTAS.BUSCAR_PASTAS_ACESSO_USUARIO, pastaId)
        },
        ehElementoNaMesmaPasta(pasta) {
            return this.pastaSelecionada.pastaId === pasta.pastaId
        },
        ehPastaSelecionada(pasta) {
            return !!pasta
        },
        fecharDialogs() {
            this.$emit('fechar')
        },
        fecharDialogPasta() {
            this.dialogPasta = false
        },
        prepararSelecionarPasta() {
            this.pastas.push({
                pastaNome: this.login.organizacaoPlano.organizacaoNome,
                pastaId: null,
                children: []
            })
            this.pastasSelecionadas.push(this.pastas[0])
        },
        selecionar(pasta) {
            if (this.validarPastaSelecionada(pasta)) {
                this.pastaSelecionada.pastaId = pasta.pastaId
                this.pastaSelecionada.pastaNome = pasta.pastaNome
                this.$emit('selecionar')
            }
        },
        validarPastaSelecionada(pasta) {
            let pastaSelecionada = this.verificarPastaSelecionada(pasta)
            let podeMover = this.verificarMoverPastaRaiz(pasta)
            return pastaSelecionada && podeMover
        },
        verificarMoverPastaRaiz(pasta) {
            if (!this.podeCriarContratoRaiz && !!pasta && !Utils.possuiValor(pasta.pastaId)) {
                if (this.ehAdministradorOrganizacao) {
                    this.mostrarNotificacaoErro(`Não é permitido mover ${this.$tc('pt_br.documento').toLowerCase()}s para a RAIZ, selecione uma pasta ou mude a configuração.`)
                } else {
                    this.mostrarNotificacaoErro(`Não é permitido mover ${this.$tc('pt_br.documento').toLowerCase()}s para a RAIZ, selecione uma pasta ou contate o Administrador da sua Organização.`)
                }
                return false
            }
            return true
        }
    }
}
</script>

<style lang="stylus">
.co-dialog-selecionar-elemento
    .pasta-ativa
        color var(--v-secondary-lighten1) !important

    .pasta-atual
        color #ccc !important

        &:hover
            background-color transparent !important

    .treeview
        background-color var(--background-150) !important

</style>