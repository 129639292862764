<template>
    <div class="co-item-anexo">
        <div style="width: 100%; display: flex">
            <div class="dados">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <div class="nome" v-on="on">{{ anexo.nome | azClipText(27) }}</div>
                    </template>
                    <span>{{ anexo.nome }}</span>
                </v-tooltip>
                <div class="data-upload">{{ anexo.dataUpload | azDate }}</div>
            </div>
        </div>

        <div class="acoes">
            <v-menu bottom right transition="scale-transition" content-class="az-menu-lista">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" dark icon>
                        <v-icon color="#aaa" size="24px"> mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item class="no-mobile" v-if="ehAnexoTipoPdf"
                                 @click="$emit('visualizar', anexo)"
                                 v-az-auth="'Contrato.Anexo.Visualizar'">
                        <v-list-item-title>Visualizar</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-for="(acao, index) in acoes" :key="index"
                                 @click="$emit(acao.evento, anexo)"
                                 v-az-auth="acao.authorities">
                        <v-list-item-title>{{ acao.nome }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Anexo',
    props: {
        anexo: {
            required: true
        },
        ehSemFuncionalidade: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            acoes: [
                {
                    nome: 'Download arquivo',
                    evento: 'download',
                    authorities: 'Contrato.Anexo.Baixar'
                }
            ]
        }
    },
    computed: {
        ehAnexoTipoPdf() {
            return this.anexo.nomeArqOriginal.toUpperCase().includes('.PDF')
        }
    },
    created() {
        if (!this.ehSemFuncionalidade) {
            this.acoes.push({
                nome: 'Excluir',
                evento: 'remover',
                authorities: 'Contrato.Anexo.Excluir'
            })
        }
    }
}
</script>

<style lang="stylus">
.co-item-anexo
    display flex
    padding 10px 0 10px 20px
    justify-content space-between
    border-bottom 1px solid #ccc

    .acoes
        width: 50px
        display flex

        .v-btn
            margin 0

    .dados
        display block
        text-align left

        .nome
            color #777
            font-size 13px

        .data-upload
            color #bbb
            font-size 11px
</style>