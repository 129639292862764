<template>
    <div class="co-lista-itens">
        <div class="itens" v-for="item in itens" v-bind:key="item.id">
            <item :descricao="item.descricao"
                  :quantidade="item.quantidade"
                  :valor-unitario="item.valor"
                  :valor-total="item.valorTotal"
                  :eh-sem-funcionalidade="ehSemFuncionalidade"
                  @excluir="remover(item)"
                  @editar="editar(item)"
            />
        </div>
    </div>
</template>

<script>
import Item from './Item'

export default {

    name: 'ListaItens',
    components: {Item},
    props: {
        ehSemFuncionalidade: {
            type: Boolean,
            default: false
        },
        itens: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    methods: {
        remover(item) {
            this.$emit('excluir', item)
        },
        editar(item) {
            this.$emit('editar', item)
        }
    }
}
</script>

<style lang="stylus">
.co-lista-itens
    height calc(100% - 50px)
    overflow-y auto
    overflow-x hidden

    .itens
        padding 10px 20px
        display flex
        border-bottom 1px solid #cccccc
        justify-content space-between
</style>