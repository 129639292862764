import { render, staticRenderFns } from "./FormularioTiposAssinaturas.vue?vue&type=template&id=6ba86aea"
import script from "./FormularioTiposAssinaturas.vue?vue&type=script&lang=js"
export * from "./FormularioTiposAssinaturas.vue?vue&type=script&lang=js"
import style0 from "./FormularioTiposAssinaturas.vue?vue&type=style&index=0&id=6ba86aea&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports