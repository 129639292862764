import moment from 'moment'

export default class DateTimeUtils {
    static setZeroHorasISO(date) {
        const dateTime = moment(date)
        dateTime.hours(0)
        dateTime.minutes(0)
        dateTime.seconds(0)
        dateTime.milliseconds(0)
        return dateTime.toISOString()
    }

    static set23HorasISO(date) {
        const dateTime = moment(date)
        dateTime.hours(23)
        dateTime.minutes(59)
        dateTime.seconds(59)
        dateTime.milliseconds(999)
        return dateTime.toISOString()
    }

    static converterDataHoraLocal(data) {
        const opcoes = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        }

        return new Intl.DateTimeFormat('pt-BR', opcoes).format(data)
    }

    static converterDataLocal(data) {
        const opcoes = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        };

        return new Intl.DateTimeFormat('pt-BR', opcoes).format(data)
    }
}