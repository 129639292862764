<template>
    <drag :transfer-data="contrato" class="co-home-contrato-info card-drag d-flex align-center">
        <slot name="contratoItem"/>

        <div slot="image" class="drag-image d-flex align-center">
            <v-icon> mdi-file-document </v-icon>
            <span>{{ contrato.nome | azClipText(17) }}</span>
        </div>
    </drag>
</template>

<script>

export default {
    name: "ContratoDrag",
    props: {
        contrato: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="stylus">
.co-home-contrato-info
    .card-drag
        width 100%
        height 100%
        flex-direction column
        position relative

        &:hover
            border 1px solid var(--background-300)
            border-radius 5px

        .drag-image
            padding 10px 20px
            background-color var(--background-100)
            border 1px solid #ccc

            span
                color #aaa
                font-size 14px !important
                padding-left 10px
                position relative
                top -2px

</style>