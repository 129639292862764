<template>
    <div class="co-item-sancao">
        <div style="width: 100%; display: flex">
            <div class="dados">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <div class="nome" v-on="on">{{ sancao.tipo | azClipText(27) }}</div>
                    </template>
                    <span>{{ sancao.tipo }}</span>
                </v-tooltip>
                <div class="data">{{ sancao.dataUpload| azDate }}</div>
            </div>
        </div>

        <div class="acoes">
            <v-menu bottom right transition="scale-transition" content-class="az-menu-lista">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" dark icon>
                        <v-icon size="24px" color="#aaa"> mdi-dots-vertical </v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="(acao, index) in acoes" :key="index" @click="$emit(acao.evento, sancao)"
                                 v-az-auth="acao.authorities">
                        <v-list-item-title>{{ acao.nome }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Sancao',
    props: {
        ehSemFuncionalidade: {
            type: Boolean,
            default: false
        },
        sancao: {
            required: true
        }
    },
    data() {
        return {
            acoes: [
                {
                    nome: 'Visualizar',
                    evento: 'visualizar',
                    authorities: 'Contrato.Sancao.Visualizar'
                }
            ]
        }
    },
    created() {
        if (!this.ehSemFuncionalidade) {
            this.acoes.push({
                nome: 'Excluir',
                evento: 'remover',
                authorities: 'Contrato.Sancao.Excluir'
            })
        }
    }
}
</script>


<style lang="stylus">
.co-item-sancao
    display flex
    padding 10px 0 10px 20px
    justify-content space-between
    border-bottom 1px solid #ccc

    .acoes
        width: 50px
        display flex

        .v-btn
            margin 0

    .dados
        display block
        text-align left

        .nome
            color #777
            font-size 13px

        .data
            color #bbb
            font-size 11px
</style>