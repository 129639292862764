import axios from 'axios'
import {actionTypes, mutationTypes} from '@/commons/constants'
import {AzSearchUrlBuilder} from '@azinformatica/loki'

export default {
    async [actionTypes.CONTRATO.BUSCAR_TODOS_CONTRATOS]({state, commit}) {
        const {filtros} = state.meusContratosBusca
        const paginacao = state.paginacaoContratos
        const organizacaoId = state.organizacao.organizacaoId

        filtros.pastaId.value = state.pastaAtual.pastaId && !filtros.nome.value ? state.pastaAtual.pastaId : null

        const url = AzSearchUrlBuilder.build(`api/contratos/buscar/organizacao/${organizacaoId}`, filtros, paginacao)
        const {data} = await axios.get(url)
        commit(mutationTypes.SET_TOTAL_CONTRATOS_LISTAGEM, data.totalElements)
        return data
    },

    async [actionTypes.CONTRATO.BUSCAR_CONTRATOS_FILTRADOS]({state, commit}) {
        const {filtros} = state.meusContratosBusca
        const paginacao = state.paginacaoContratos
        const organizacaoId = state.organizacao.organizacaoId

        filtros.pastaId.value = state.pastaAtual.pastaId ? state.pastaAtual.pastaId : null

        const url = AzSearchUrlBuilder.build(`api/contratos/buscar/organizacao/${organizacaoId}/filtros`, filtros, paginacao)
        const {data} = await axios.get(url)
        commit(mutationTypes.SET_TOTAL_CONTRATOS_LISTAGEM, data.totalElements)
        return data
    },
}