<template>
    <div class="co-ops">
        <clm-ops-imagem :sentimento="sentimento"/>
        <div class="titulo">
            <slot name="titulo"/>
        </div>
        <div class="subtitulo">
            <slot name="subtitulo"/>
        </div>
        <div class="acoes">
            <slot name="acoes"/>
        </div>
    </div>
</template>

<script>
import ClmOpsImagem from '@/commons/components/ClmOpsImagem'

export default {
    name: 'clm-ops',
    components: {ClmOpsImagem},
    props: {
        sentimento: {
            type: String,
            validator: val => ['feliz', 'triste', 'assustado', 'valido', 'atencao', 'erro'].includes(val),
            required: true
        }
    }
}
</script>

<style lang="stylus">
.co-ops
    text-align center
    display block
    padding 20px

    .titulo
        font-size 18px
        font-weight bold
        color #777
        margin-top 10px
        margin-bottom 10px

    .subtitulo
        font-size 14px
        color #777
        margin-right 10%
        margin-left 10%

        .destaque
            color orange
            font-weight bold
            text-decoration none

    .acoes
        margin-top 10px

        .principal
            margin-top 30px

            a
                background-color orange
                border-radius 20px
                font-size 16px
                text-transform uppercase
                color white
                font-weight bold
                transition 0.1s
                padding 7px 40px

                &:hover
                    background-color lighten(orange, 40%)

        .secundaria
            margin-top 10px

            a
                font-size 13px
                color #777
                transition 0.2s

                &:hover
                    color darken(#777, 40%)

@media (max-width: 720px)
    .co-ops .acoes .principal a
        font-size 14px
        padding 7px 20px
</style>