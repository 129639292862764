<template>
    <div class="co-anexo">
        <funcionalidade-exclusiva :eh-sem-funcionalidade="ehSemFuncionalidade"/>

        <v-divider/>

        <div class="abas">
            <div class="titulo">Anexos</div>

            <a class="abas-novo" @click="abrirModalNovoAnexo" v-az-auth="'Contrato.Anexo.Novo'"
               v-if="!contrato.extinto && !ehSemFuncionalidade">
                <v-icon color="green" size="20px"> mdi-plus-circle </v-icon>
            </a>
        </div>

        <anexo
            v-for="anexo in anexos"
            :key="anexo.anexoId"
            :anexo="anexo"
            :eh-sem-funcionalidade="ehSemFuncionalidade"
            @remover="confirmarRemocaoAnexo"
            @download="download"
            @visualizar="visualizar"
        />

        <novo-anexo
            v-if="inserindoAnexo"
            v-model="anexo"
            @cancelar="fecharModalNovoAnexo"
            @inserir="inserirAnexo"
            @upload="preencherReferenciaArquivo"
            @clean="limparArquivo"
        />

        <clm-dialog v-model="dialogRemoverAnexo">
            <clm-ops sentimento="assustado">
                <div slot="titulo">Eita! Deixa eu confirmar uma coisa...</div>
                <div slot="subtitulo">Você quer mesmo excluir o anexo?</div>
                <div slot="acoes">
                    <div class="principal">
                        <a @click="remover">Sim, eu quero excluir!</a>
                    </div>
                    <div class="secundaria">
                        <a @click="fecharDialogRemocao">Não, obrigado</a>
                    </div>
                </div>
            </clm-ops>
        </clm-dialog>

        <visualizar-anexo
            :anexo="anexoVisualizar"
            :contrato-id="contrato.id"
            :minuta-id="minutaAtual.id"
            v-if="dialogVisualizarAnexo"
            @fechar="fecharVisualizarAnexo"/>
    </div>
</template>

<script>
import Anexo from './Anexo'
import NovoAnexo from './NovoAnexo'
import VisualizarAnexo from './VisualizarAnexo'
import {mapState} from 'vuex'
import {actionTypes} from '@/commons/constants'
import ClmDialog from '@/commons/components/ClmDialog'
import ClmOps from '@/commons/components/ClmOps'
import FuncionalidadeExclusiva from '../../../../visualizar-contrato/barra-lateral/components/FuncionanlidadeExclusiva'

export default {
    components: {
        FuncionalidadeExclusiva,
        ClmOps,
        ClmDialog,
        Anexo,
        NovoAnexo,
        VisualizarAnexo
    },
    name: 'ListaAnexo',
    data() {
        return {
            anexo: {},
            anexos: [],
            arquivoAnexo: {},
            inserindoAnexo: false,
            anexoRemocao: {},
            anexoVisualizar: {},
            dialogRemoverAnexo: false,
            dialogVisualizarAnexo: false
        }
    },
    computed: {
        ...mapState(['login', 'contrato', 'minutaAtual']),
        ehSemFuncionalidade() {
            return !this.login.funcionalidadesOrganizacao.anexo
        }
    },
    watch: {
        async 'minutaAtual'() {
            if (this.minutaAtual.situacaoProcessamento !== 'EM_PROCESSAMENTO') {
                await this.atualizarListagemAnexos()
            }
        }
    },
    async mounted() {
        if (this.minutaAtual && this.minutaAtual.id) {
            await this.atualizarListagemAnexos()
        }
    },
    methods: {
        abrirModalNovoAnexo() {
            this.anexo = {
                nome: '',
                nomeArqOriginal: '',
                minutaId: this.$store.state.minutaAtual.id
            }
            this.inserindoAnexo = true
        },
        async atualizarListagemAnexos() {
            this.setMensagemLoading('Carregando anexos...')
            this.anexos = await this.$store.dispatch(actionTypes.BUSCAR_ANEXOS, {
                contratoId: this.contrato.id,
                minutaId: this.minutaAtual.id
            })
        },
        async baixarArquivoAnexo(anexo) {
            return await this.$store.dispatch(actionTypes.DOWNLOAD_ARQUIVO_ANEXO, {
                contratoId: this.contrato.id,
                minutaId: this.minutaAtual.id,
                anexoId: anexo.anexoId
            })
        },
        confirmarRemocaoAnexo(anexo) {
            this.dialogRemoverAnexo = true
            this.anexoRemocao = anexo
        },
        async download(anexo) {
            const arquivoBase64 = await this.baixarArquivoAnexo(anexo)
            const filename = this.extractFilename(anexo.nomeArqOriginal)
            this.salvarArquivo(filename, arquivoBase64, anexo.tipoArquivo)
        },
        fecharDialogRemocao() {
            this.dialogRemoverAnexo = false
        },
        fecharModalNovoAnexo() {
            this.inserindoAnexo = false
        },
        preencherReferenciaArquivo(arquivo) {
            this.arquivoAnexo = arquivo
        },
        construirRequisicao() {
            const anexo = this.anexo
            const formData = new FormData()
            formData.append('arquivo', this.arquivoAnexo)
            formData.append('anexo', JSON.stringify(anexo))
            return {contratoId: this.contrato.id, minutaId: this.minutaAtual.id, anexo: formData}
        },
        async inserirAnexo() {
            try {
                this.setMensagemLoading('Salvando anexo...')
                await this.$store.dispatch(actionTypes.INSERIR_ANEXO, this.construirRequisicao())
                this.fecharModalNovoAnexo()
                await this.atualizarListagemAnexos()
                this.mostrarNotificacaoSucessoDefault()
            } catch (e) {
                await this.atualizarListagemAnexos()
            }
        },
        limparArquivo() {
            this.arquivoAnexo = {}
        },
        async remover() {
            try {
                this.setMensagemLoading('Removendo anexo...')
                await this.$store.dispatch(actionTypes.REMOVER_ANEXO, {
                    contratoId: this.contrato.id,
                    minutaId: this.minutaAtual.id,
                    anexoId: this.anexoRemocao.anexoId
                })
                this.fecharDialogRemocao()
                await this.atualizarListagemAnexos()
                this.mostrarNotificacaoSucessoDefault()
            } catch (e) {
                await this.atualizarListagemAnexos()
            }
        },
        async visualizar(anexo) {
            this.anexoVisualizar.nome = anexo.nome
            this.anexoVisualizar.anexoId = anexo.anexoId
            this.anexoVisualizar.arquivo = await this.baixarArquivoAnexo(anexo)
            this.dialogVisualizarAnexo = true
        },
        fecharVisualizarAnexo() {
            this.dialogVisualizarAnexo = false
            this.$emit('anexoFechado')
        }
    }
}
</script>

<style lang="stylus">
.co-anexo
    display block
    text-align center
    overflow-x hidden
    overflow-y auto
    height: 100%

    &__acoes
        background-color green
        color white !important
        border-radius 20px
        font-size 15px

        i
            margin-right 5px

    .abas
        display flex
        justify-content space-between
        height 50px
        padding 0 20px
        vertical-align center
        border-bottom 1px solid #ccc

        .abas-novo
            display flex !important
            align-items center !important
            color green
            font-size 12px
            font-weight bold

        .titulo
            display flex
            align-items center
            color #777
            font-size 14px
            text-transform uppercase

@media (max-width: 720px)
    .co-anexo
        height calc(100% - 90px)

</style>