<template>
    <v-menu bottom right transition="scale-transition">
        <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="#aaa" size="24px"> mdi-dots-vertical </v-icon>
        </template>
        <v-list>
            <v-list-item v-for="(item, i) in acoesItens" :key="i" v-az-auth="item.authorities"
                         @click="$emit(item.acao)">
                <v-list-item-title> {{ item.nome }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: 'AcoesItem',
    props: {
        acoesItens: {
            type: Array,
            required: true
        }
    }
}
</script>