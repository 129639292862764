export default {
    DECREMENTAR_PAGINA_NOTIFICACOES_BUSCA: 'decrementarPaginaNotificacoesBusca',
    INCREMENTAR_PAGINA_NOTIFICACOES_BUSCA: 'incrementarPaginaNotificacoesBusca',
    COMUN: {
        SET_CONSUMO_ORGANIZACAO: 'setConsumoOrganizacao'
    },
    LOKI: {
        DISABLE_GLOBAL_LOADING: 'disableGlobalLoading',
        ENABLE_GLOBAL_LOADING: 'enableGlobalLoading',
        SET_GLOBAL_LOADING: 'setGlobalLoading',
        SET_LOADING_MESSAGE: 'setLoadingMessage',
        SET_NOTIFICATION: 'setNotification',
        SHOW_ALERT: 'showAlert'
    },
    LOGIN: {
        SET_ORGANIZACAO_PLANO: 'setOrganizacaoPlano',
        SET_USUARIO_LOGADO: 'setUsuarioLogado',
        SET_VINCULO_USUARIO_ORGANIZACAO: 'setVinculoUsuarioOrganizacao',
        SET_FUNCIONALIDADES_ORGANIZACAO: 'setFuncionalidadesOrganizacao',
        SET_ORGANIZACAO_CONFIGURACAO: 'setOrganizacaoConfiguracao',
        SET_ORGANIZACAO_CONFIGURACAO_GERAL: 'setOrganizacaoConfiguracaoGeral',
        SET_ORGANIZACAO_CONFIGURACAO_NOTIFICACAO: 'setOrganizacaoConfiguracaoNotificacao',
        SET_ORGANIZACAO_CONFIGURACAO_WEBHOOK: 'setOrganizacaoConfiguracaoWebhook'
    },
    ACOES: {
        SET_ACOES_FILTRAR: 'setAcoesFiltrar',
        SET_ACOES_ORDENAR: 'setAcoesOrdenar'
    },
    FEEDBACK: {
        SET_PESQUISA_FEEDBACK: 'setPesquisaFeedback'
    },
    FILTROS: {
        LIMPAR_FILTROS_BUSCA: 'limparFiltrosBusca',
        SET_FILTROS_BUSCA: 'setFiltrosBusca',
        RECUPERAR_FILTROS_BUSCA_COOKIES: 'recuperarFiltrosBuscaCookies'
    },
    PASTAS: {
        ADD_PASTAS_PARA_ATUALIZAR: 'addPastasParaAtualizar',
        ENTRAR_NA_PASTA: 'entrarNaPasta',
        REMOVE_PASTAS_PARA_ATUALIZAR: 'removePastasParaAtualizar',
        SET_PASTA_ATUAL_CONTENT: 'setPastaAtualContent',
        SET_PASTA_ATUAL_TOTAL_ELEMENTS: 'setPastaAtualTotalElements',
        SET_PASTA_ATUAL_FLAG_FILTRAGEM: 'setPastaAtualFlagFiltragem',
        VOLTAR_PARA_PASTA: 'voltarParaPasta',
    },
    PREFERENCIAS_USUARIO: {
        SET_ASIDE_CLOSE: 'setAsideClose',
        SET_LAYOUT_LISTA: 'setLayoutLista',
        SET_ORDENACAO_BUSCA: 'setOrdenacaoBusca',
        SET_PREFERENCIAS: 'setPreferencias'
    },
    WEBHOOK: {
        SET_PAGINACAO_WEBHOOKS: 'setPaginacaoWebhooks'
    },
    RESET_NOTIFICACOES: 'resetNotificacoes',
    SET_API_BUSCA_MEUS_CONTRATOS: 'setApiBuscaMeusContratos',
    SET_CARREGANDO_DADOS_CONTRATO: 'setCarregandoDadosContrato',
    SET_CONTRATO: 'setContrato',
    SET_ESTADOS: 'setEstados',
    SET_HISTORICO_CONTRATO: 'setHistoricoContrato',
    SET_MOBILE: 'setMobile',
    SET_MENU_AVATAR: 'setMenuAvatar',
    SET_MEUS_CONTRATOS: 'setMeusContratos',
    SET_MINUTA_ATUAL: 'setMinutaAtual',
    SET_NOTIFICACOES_MENSAGENS: 'setNotificacoesMensagens',
    SET_NOTIFICACOES: 'setNotificacoes',
    SET_PAGINACAO_BUSCA_CONTRATOS: 'setPaginacaoBuscaContratos',
    SET_PAGINACAO_BUSCA_NOTIFICACOES: 'setPaginacaoBuscaNotificacoes',
    SET_PARAMETROS_SISTEMA: 'setParametrosSistema',
    SET_PASTAS: 'setPastas',
    SET_PERFIS: 'setPerfis',
    SET_COOKIES: 'setCookies',
    SET_PRODUTO: 'setProduto',
    SET_ORGANIZACAO: 'setOrganizacao',
    SET_SIGNATARIOS: 'setSignatarios',
    SET_TOTAL_CONTRATOS_LISTAGEM: 'setTotalContratosListagem',
    SET_PAGINACAO_CADASTROS_SIGNATARIOS: 'setPaginacaoCadastrosSignatarios',
    SET_FILTROS_CADASTROS_SIGNATARIOS: 'setFiltrosCadastrosSignatarios',
    SET_PAGINACAO_GRUPOS_SIGNATARIOS: 'setPaginacaoGruposSignatarios',
    SET_FILTROS_GRUPOS_SIGNATARIOS: 'setFiltrosGruposSignatarios'
}
