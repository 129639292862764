import filtrosMutations from '@/commons/store/mutations/filtrosMutations'
import pastasMutations from './pastasMutations'
import preferenciasMutations from './preferenciasMutations'
import mutations from './mutations'

export default {
    ...filtrosMutations,
    ...pastasMutations,
    ...preferenciasMutations,
    ...mutations
}