<template>
    <az-template-default
        ref="template"
        show-notification
        @closeNotifications="verificarNovasNotificacoes"
        @openNotifications="marcarNotificacoesLidas"
        @paginateNotifications="buscarPaginaNotificacoes"
        @readNotifications="marcarNotificacoesLidas"
        @refreshNotifications="verificarNovasNotificacoes"
        @removeNotification="removerNotificacaoPorId"
        @visitNotification="visualizarContrato"
    >
        <div class="co-app-conteudo-principal">
            <router-view/>
            <dialog-feedback v-if="!ehEfcazCLM && ehOrganizacaoComFeedbackPendente"/>
            <banner-promocao
                v-if="!ehEfcazCLM && ehPlanoFree && ehMostrarDialogPlanos"
                @fechar="fecharDialogPlanos"
                :dialog="ehMostrarDialogPlanos"
                :personalizado="false" />
        </div>

        <template v-if="!asideClosed && !ehEfcazCLM && ehPlanoFree" v-slot:bannerAside>
            <img class="banner mb-8" src="@/assets/banner_esquenta.png" @click="banerClick()">
        </template>

    </az-template-default>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import {actionTypes, mutationTypes} from '@/commons/constants'
import iconBesign from '@/assets/icons/iconBesign.svg'
import DialogFeedback from '@/commons/components/dialogs/dialogFeedback/DialogFeedback'
import BannerPromocao from '@/commons/components/BannerPromocao'
import CookiesUtils from '@/commons/utils/CookiesUtils'
import moment from 'moment-timezone'

export default {
    components: {
        DialogFeedback,
        BannerPromocao
    },
    watch: {
        'meusContratosBusca.filtros.nome.value'() {
            if (this.meusContratosBusca.filtros.nome.value === null) {
                this.$refs.template.searchText = ''
            }
        },
        '$store.state.loki.asideClosed'() {
            this.$store.commit(mutationTypes.PREFERENCIAS_USUARIO.SET_ASIDE_CLOSE, this.$store.state.loki.asideClosed)
            this.$store.dispatch(actionTypes.USUARIO.ATUALIZAR_PREFERENCIAS_USUARIO, this.preferenciasUsuario)
        },
    },
    data() {
        return {
            ehMostrarDialogPlanos: false
        }
    },
    computed: {
        ...mapState(['loki', 'notificacoes', 'meusContratosBusca', 'consumoOrganizacao', 'preferenciasUsuario', 'pesquisaFeedback', 'login', 'cookies']),
        ...mapGetters(['ehEfcazCLM', 'ehPlanoFree', 'existeContratos']),
        asideClosed() {
            return this.$store.state.loki.asideClosed
        },
        ehRegraFeedbackPlanoFree() {
            return this.ehPlanoFree && this.pesquisaFeedback.exigirResposta
        },
        ehRegraDiariaDialogPlanos() {
            if (! !!this.login.usuarioOrganizacao.ultimoAcesso) {
                return true
            }

            const data = new Date(this.login.usuarioOrganizacao.ultimoAcesso);
            const dataAtual = new Date();

            return moment(data).isBefore(dataAtual, 'day') && !moment(data).isSame(dataAtual, 'day');
        },
        ehResetarPromoCloseCount() {
            if (!!this.login.usuarioOrganizacao.ultimoAcesso) {
                const data = new Date(this.login.usuarioOrganizacao.ultimoAcesso);
                const dataCorte = new Date('2024-11-4');

                return moment(data).isBefore(dataCorte, 'day') && !moment(data).isSame(dataCorte, 'day');
            }
        },
        ehOrganizacaoComFeedbackPendente() {
            if (this.pesquisaFeedback.dataIgnorado) {
                if (this.pesquisaFeedback.qtdIgnorado < 3) {
                    let dataProxFeedback = new Date(this.pesquisaFeedback.dataIgnorado)
                    dataProxFeedback.setDate(dataProxFeedback.getDate() + 10)
                    return !this.pesquisaFeedback.respondido && dataProxFeedback <= new Date()
                }
            } else if (this.pesquisaFeedback.respondido || this.ehRegraFeedbackPlanoFree) {
                return !this.pesquisaFeedback.respondido
            } else {
                return !this.pesquisaFeedback.respondido && this.existeContratos
            }
        },
        ehOrganizacaoCriada() {
            return this.login.organizacaoPlano.organizacaoExistente
        },
        saldoTotalDocumento() {
            return this.consumoOrganizacao.totalDocumentos
        },
        saldoUtilizadoDocumento() {
            return this.consumoOrganizacao.documentosUtilizados
        }
    },
    created() {
        this.$store.state.loki.notificationConfig.refreshTimeout = 5 * 60 * 1000

        this.$store.commit('SET_FILES_CONFIG', {
            api: this.$store.state.fileManagerUri,
            maxSize: this.$store.state.parametrosSistema['spring.servlet.multipart.max-file-size']
        })
    },
    async mounted() {
        this.resolverDependencias()
        await this.verificarNovasNotificacoes()
        this.modalNovosPlanos()

        if (this.ehEfcazCLM) {
            this.ocultarMenuPlanos()
        } else {
            await this.notificarAdminSobreClickPlanos()
        }
    },
    methods: {
        ...mapActions([
            actionTypes.BUSCAR_NOTIFICACOES,
            actionTypes.LER_NOTIFICACOES,
            actionTypes.REMOVER_NOTIFICACAO
        ]),
        ...mapMutations([
            mutationTypes.DECREMENTAR_PAGINA_NOTIFICACOES_BUSCA,
            mutationTypes.INCREMENTAR_PAGINA_NOTIFICACOES_BUSCA,
            mutationTypes.LOKI.SET_NOTIFICATION,
            mutationTypes.SET_NOTIFICACOES,
            mutationTypes.RESET_NOTIFICACOES,
            mutationTypes.SET_NOTIFICACOES_MENSAGENS,
            mutationTypes.SET_PAGINACAO_BUSCA_NOTIFICACOES
        ]),
        alterarParaBesign() {
            this.$vuetify.theme.themes.light.primary = '#604a99'
            document.documentElement.style.setProperty('--primary', '#604a99')
            document.title = "BeSign | Assinatura Eletrônica com poucos cliques"

            var link = document.querySelector("link[rel~='icon']")
            if (!link) {
                link = document.createElement('link')
                link.rel = 'icon'
                document.getElementsByTagName('head')[0].appendChild(link)
            }
            link.href = iconBesign;
        },
        async atualizarNotificacoesNaStore(notificacoes) {
            this.setNotificacoes(notificacoes)
            this.setNotification(this.formatarNotificacoes(this.notificacoes))
        },
        atualizarPaginacaoNotificacoes(notificacoes) {
            if (this.ehPaginaVazia(notificacoes)) {
                this.decrementarPaginaNotificacoesBusca()
            }
        },
        async banerClick() {
            await this.$router.push({name: 'planos'})
        },
        async buscarPaginaNotificacoes() {
            this.incrementarPaginaNotificacoesBusca()
            const notificacoes = await this.buscarNotificacoes()
            this.atualizarPaginacaoNotificacoes(notificacoes)
            this.atualizarNotificacoesNaStore(notificacoes)
            await this.marcarNotificacoesLidas()
        },
        ehPaginaVazia(pagina) {
            return pagina.content.length === 0
        },
        encontrarIdNotificacoesNaoLidas() {
            const notificacoesNaoLidas = this.notificacoes.mensagens.filter(notificacao => !notificacao.lido)
            const notificacoesIdList = []
            notificacoesNaoLidas.forEach(notificacao => notificacoesIdList.push(notificacao.id))
            return notificacoesIdList
        },
        exibirVerMaisNotificacoes(totalElementos, paginaAtual, elementosPorPagina) {
            return (totalElementos / elementosPorPagina) > paginaAtual
        },
        fecharDialogPlanos() {
            this.ehMostrarDialogPlanos = false
        },
        formatarNotificacoes(notificacoes) {
            const messages = []
            notificacoes.mensagens.forEach(notificacao => {
                messages.push({
                    id: notificacao.id,
                    text: notificacao.mensagem,
                    read: notificacao.lido,
                    when: notificacao.dataCadastro,
                    contratoId: notificacao.contratoId
                })
            })
            const unread = notificacoes.totalNaoLido
            const viewMore = this.exibirVerMaisNotificacoes(notificacoes.totalElementos,
                this.$store.state.notificacoesBusca.paginacao.page,
                this.$store.state.notificacoesBusca.paginacao.rowsPerPage)
            return {messages, unread, viewMore}
        },
        async marcarNotificacoesLidas() {
            const notificacoesIdList = this.encontrarIdNotificacoesNaoLidas()
            if (!notificacoesIdList.length) {
                return
            }
            await this.lerNotificacoes(notificacoesIdList)
        },
        modalNovosPlanos() {
            const UM_MINUTO_MILISSEGUNDOS = 60000

            if (this.ehResetarPromoCloseCount) {
                this.cookies.promoCloseCount = 0
                CookiesUtils.atualizarCookies(this.cookies)
            }

            if (this.ehOrganizacaoCriada && this.ehRegraDiariaDialogPlanos && this.cookies.promoCloseCount < 3) {
                setTimeout(() => {
                    this.ehMostrarDialogPlanos = true
                }, UM_MINUTO_MILISSEGUNDOS)
            }
        },
        ocultarMenuPlanos() {
            const menuPlanosBotao = document.querySelector(".menu .az-menu .v-list a[href='#/planos']")

            if (!!menuPlanosBotao) {
                menuPlanosBotao.style.display = 'none'
            }
        },
        async notificarAdminSobreClickPlanos() {
            const pegarBotaoPlanos = document.querySelector(".menu .az-menu .v-list a[href='#/planos']")

            pegarBotaoPlanos.addEventListener("click", async () => {
                await this.$store.dispatch(actionTypes.EMAIL.ENVIAR_EMAIL_COMERCIAL, {
                    'mensagem': `Lead ${this.$store.state.loki.user.fullName}, do email: ${this.$store.state.login.organizacaoPlano.organizacaoEmail}, do telefone: ${this.$store.state.login.organizacaoPlano.organizacaoTelefone ?? 'N/A'}, da organização: ${this.$store.state.login.organizacaoPlano.organizacaoNome}, atualmente no plano '${this.$store.getters.nomePlano}' clicou no Menu 'Planos'`,
                    'assunto': `Lead ${this.$store.state.loki.user.fullName} clicou em Planos - Menu Lateral`,
                    'tituloEmail': `Lead ${this.$store.state.loki.user.fullName} clicou em Planos - Menu Lateral`
                })
            })
        },
        resolverDependencias() {
            this.$store.state.loki.asideClosed = this.$store.state.preferenciasUsuario.asideClosed

            this.$store.state.loki.file.maxSize = '20Mb'

            if (!this.$store.state.loki.product.efcazCLM) {
                this.alterarParaBesign()
            } else {
                document.title = "Efcaz-CLM é a maior plataforma online para Gestão de Contratos"
            }
        },
        async removerNotificacaoPorId(notificacao) {
            await this.removerNotificacao(notificacao.id)
            this.removerNotificacaoLocal(notificacao.id)

        },
        removerNotificacaoLocal(notificacaoId) {
            const notificacoes = this.notificacoes.mensagens
            const index = notificacoes.findIndex(notificacao => notificacao.id === notificacaoId)
            notificacoes.splice(index, 1)
            this.setNotificacoesMensagens(notificacoes)
            this.setNotification(this.formatarNotificacoes(this.notificacoes))
        },
        rotaPermiteVerificarNovasNotificacoes() {
            return this.$route.meta.verificarNovasNotificacoes
        },
        async verificarNovasNotificacoes() {
            if (!this.rotaPermiteVerificarNovasNotificacoes()) {
                return
            }
            this.setPaginacaoBuscaNotificacoes({
                page: 1,
                rowsPerPage: 4,
                sortBy: 'dataCadastro',
                descending: true
            })
            const notificacoes = await this.buscarNotificacoes()
            this.resetNotificacoes()
            this.setNotificacoes(notificacoes)
            this.setNotification(this.formatarNotificacoes(this.notificacoes))
        },
        visualizarContrato(notificacao) {
            if (notificacao.contratoId) {
                this.$router.push({name: 'detalheContrato', params: {id: notificacao.contratoId}})
            }
        }
    }
}
</script>

<style lang="stylus">
.v-application--is-ltr .v-text-field .v-label
    color var(--text-color-300)

.az-template-default
    .main
        width 80%

    .az-logo a
        height auto

    .az-about
        background-color white

    .mobile_menu
        display none

    .az-aside
        margin-bottom 25px !important

        .v-navigation-drawer__content
            background-color var(--background-200) !important

    .az-menu
        .active-menu
            background-color transparent !important

        .active-menu:after
            display none

        .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled)
            color var(--primary) !important

        .v-list a:hover, .v-list-item:hover
            background-color var(--background-200) !important

        .v-list .v-list-item--active, .active-menu .v-list-item__title, .v-list .v-list-item .v-icon,
        .v-list-item:hover:not(.v-list-item--active):not(.v-list-item--disabled)
            color var(--primary) !important

    .az-submenu
        background-color rgba(0, 0, 0, 0.015)

        .active-menu
            background-color transparent !important

        .v-list-item__title
            color var(--primary) !important

        .v-list a:hover, .v-list-item:hover
            background-color var(--background-200) !important

    .az-aside
        .text-hide-menu
            color var(--text-color-300) !important

        .arrow-opened i, .arrow-closed i
            color var(--text-color-100) !important

    .toolbar
        -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2) !important
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2) !important
        background-color: #fff !important

    .az-avatar__username
    .notification-icon
    .az-avatar__picture i
        color var(--secondary-200) !important

.co-app-conteudo-principal
    height 100%
    background-color white

body
    font-size 14px

    .az-menu-lista
        .v-list-item
            height 35px
            padding 0 10px

            &:hover
                background-color #ddd
                color #999

            .v-list-item__title
                font-size 13px
                color #777

                i
                    color #999


.az-pdf-document-viewer
    .v-progress-linear
        margin 0 !important

.action
    text-align: center
    padding: 30px 0
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    .show
        color: white
        border-radius: 20px
        transition 0s
        width 210px !important
        margin 0 !important
        font-size 16px
        text-transform capitalize

    .hide
        color: white
        font-weight bold
        transition 0s
        width 36px
        height 36px
        margin 0 !important

        i
            font-size 24px !important

.menu-new-list
    padding 10px 0 !important

    .item
        display block
        font-size 14px
        padding 10px 15px
        cursor pointer

        &:hover
            background-color #e5e5e5

        i
            padding-right 10px
            font-size 18px

    .v-autocomplete__content
        .v-list__tile__action
            display none !important

::placeholder
    color #ccc !important

.required-field label:after
    content " *"
    color var(--v-error-base)

.az-logo a
    height 100%

.az-text
    label
        font-size 12px
        color rgba(0, 0, 0, 0.54)

    p
        color #555

.az-avatar
    .plan
        color yellow
        font-weight bold
        font-size 12px !important

html
    overflow auto !important

.v-menu__content
    .v-list
        padding 0

        &__tile
            height 40px

.v-footer
    padding 0

.v-main
    padding-bottom 0 !important

.banner
    width 170px
    margin-left 15px
    margin-right 15px
    cursor pointer

@media (max-width: 720px)
    #_hj_feedback_container
        display none !important

    .v-footer
        display none !important
</style>
