import axios from 'axios'
import {AzSearchUrlBuilder} from '@azinformatica/loki'
import {actionTypes, mutationTypes} from '@/commons/constants'

export default {

    async [actionTypes.COMUN.BUSCAR_CONSUMO_ORGANIZACAO]({commit, state}) {
        const organizacaoId = state.login.organizacaoPlano.organizacaoId
        const {data} = await axios.get(`api/configuracoes/organizacoes/${organizacaoId}/consumo`)
        commit(mutationTypes.COMUN.SET_CONSUMO_ORGANIZACAO, data)
    },

    async [actionTypes.ARQUIVAR_CONTRATO](context, contratoId) {
        return await axios.put(`api/contratos/${contratoId}/arquivamento`)
    },

    async [actionTypes.ATIVAR_NOTIFICACAO_CONTRATO](context, {organizacaoId, contratoId, notificacaoContrato}) {
        await axios.put(`api/notificacoes/organizacao/${organizacaoId}/contrato/${contratoId}/ativar`, notificacaoContrato)
    },

    async [actionTypes.ATIVAR_NOTIFICACAO_PASTA](context, {organizacaoId, pastaId, pastaUsuarioAcesso}) {
        await axios.put(`api/notificacoes/organizacao/${organizacaoId}/pasta/${pastaId}/ativar`, pastaUsuarioAcesso)
    },

    async [actionTypes.ATRIBUIR_DOMINIO_PERFIL_NOVO_USUARIO](context, dominioPerfilDTO) {
        const {data} = await axios.post(`hal/dominioPerfil/criarDominioEDominioPerfil`, dominioPerfilDTO)
        return data
    },

    async [actionTypes.ATIVAR_USUARIO_ORGANIZACAO](context, {organizacaoId, usuarioId}) {
        return await axios.put(`api/configuracoes/organizacoes/${organizacaoId}/usuarios/${usuarioId}/ativar`)
    },

    async [actionTypes.ATUALIZAR_CONTRATO](context, {contratoId, contrato}) {
        return await axios.put(`api/contratos/${contratoId}`, contrato)
    },

    async [actionTypes.ATUALIZAR_MINUTA](context, {contratoId, minutaId, minuta}) {
        return await axios.put(`api/contratos/${contratoId}/minutas/${minutaId}`, minuta)
    },

    async [actionTypes.ATUALIZAR_TAGS_CONTRATO](context, {contratoId, contrato}) {
        return await axios.put(`api/contratos/${contratoId}/atualizacaotags`, contrato)
    },

    async [actionTypes.BUSCAR_ANEXOS](context, {contratoId, minutaId}) {
        const {data} = await axios.get(`api/contratos/${contratoId}/minutas/${minutaId}/anexos`)
        return data
    },

    async [actionTypes.BUSCAR_CONTRATO]({commit}, contratoId) {
        const {data} = await axios.get(`api/contratos/${contratoId}`)
        commit(mutationTypes.SET_CONTRATO, data)
    },

    async [actionTypes.BUSCAR_CIDADES](context, estadoId) {
        const {data} = await axios.get(`hal/public/localizacoes/estados/${estadoId}/municipios`)
        return data
    },

    async [actionTypes.BUSCAR_ESTADOS]({commit}) {
        const {data} = await axios.get('hal/public/localizacoes/estados')
        commit(mutationTypes.SET_ESTADOS, data)
    },

    async [actionTypes.BUSCAR_PLANOS]() {
        const {data} = await axios.get(`api/configuracoes/planos`)
        return data
    },

    async [actionTypes.BUSCAR_HISTORICO_CONTRATO]({commit}, contratoId) {
        const {data} = await axios.get(`api/contratos/${contratoId}/minutas`)
        commit(mutationTypes.SET_HISTORICO_CONTRATO, data)
    },

    async [actionTypes.BUSCAR_HISTORICO_MENSAGENS](context, {contratoId, minutaId, signatarioId}) {
        const {data} = await axios.get(`api/contratos/${contratoId}/minutas/${minutaId}/signatarios/${signatarioId}/historico-mensagens`)
        return data
    },

    async [actionTypes.BUSCAR_LINK_EDITAR_USUARIO]({state}) {
        const payload = {
            uri: window.location.origin + window.location.pathname,
            produto: state.loki.product.name
        }
        const {data} = await axios.post('hal/editarUsuario', payload)
        return data
    },

    async [actionTypes.BUSCAR_MINUTA]({commit}, {contratoId, minutaId}) {
        const {data} = await axios.get(`api/contratos/${contratoId}/minutas/${minutaId}`)
        commit(mutationTypes.SET_MINUTA_ATUAL, data)
    },

    async [actionTypes.BUSCAR_MINUTA_ATUAL]({commit}, contratoId) {
        const {data} = await axios.get(`api/contratos/${contratoId}/minutas/atual`)
        commit(mutationTypes.SET_MINUTA_ATUAL, data)
    },

    async [actionTypes.BUSCAR_NOTIFICACOES]({state}) {
        const {paginacao} = state.notificacoesBusca
        const url = AzSearchUrlBuilder.build('api/notificacoes', {}, paginacao)
        const {data} = await axios.get(url)
        return data
    },

    async [actionTypes.BUSCAR_DOCUMENTOS_SIGNATARIO]() {
        const {data} = await axios.get('api/contratos/documentosSignatario')
        return data
    },

    async [actionTypes.BUSCAR_PAPEIS_SIGNATARIOS]() {
        const {data} = await axios.get('api/contratos/papeisSignatarios')
        return data
    },

    async [actionTypes.BUSCAR_PERFIS]() {
        const {data} = await axios.get(`api/usuarios/perfil`)
        return data
    },

    async [actionTypes.BUSCAR_ORGANIZACAO]({state, commit}) {
        try {
            const organizacaoId = state.login.organizacaoPlano.organizacaoId
            const {data} = await axios.get(`api/configuracoes/organizacoes/${organizacaoId}`)
            commit(mutationTypes.SET_ORGANIZACAO, data)
        } catch (e) {
            commit(mutationTypes.SET_ORGANIZACAO, null)
        }
    },

    async [actionTypes.BUSCAR_SANCOES](context, {contratoId, minutaId}) {
        const {data} = await axios.get(`api/contratos/${contratoId}/minutas/${minutaId}/sancoes`)
        return data
    },

    async [actionTypes.BUSCAR_SIGNATARIOS]({commit}, {contratoId, minutaId}) {
        const {data} = await axios.get(`api/contratos/${contratoId}/minutas/${minutaId}/signatarios`)
        commit(mutationTypes.SET_SIGNATARIOS, data)
    },

    async [actionTypes.BUSCAR_SIGNATARIOS_POR_MINUTA_VERSAO](context, {contratoId, versao}) {
        const {data} = await axios.get(`api/contratos/${contratoId}/minutas/versao/${versao}/signatarios`)
        return data
    },

    async [actionTypes.BUSCAR_TAGS_POR_ORGANIZACAO](context, organizacaoId) {
        const {data} = await axios.get(`api/configuracoes/organizacoes/${organizacaoId}/tags`)
        return data
    },

    async [actionTypes.BUSCAR_USUARIOS_NOTIFICACAO_PASTA](context, {organizacaoId, pastaId}) {
        const {data} = await axios.get(`api/notificacoes/organizacao/${organizacaoId}/pasta/${pastaId}/usuarios`)
        return data
    },

    async [actionTypes.BUSCAR_USUARIO_POR_ID](context, usuarioId) {
        const {data} = await axios.get(`hal/usuarios/${usuarioId}`)
        return data
    },

    async [actionTypes.BUSCAR_USUARIOS_NOTIFICACAO_CONTRATO](context, {organizacaoId, contratoId}) {
        const {data} = await axios.get(`api/notificacoes/organizacao/${organizacaoId}/contrato/${contratoId}/usuarios`)
        return data
    },

    async [actionTypes.BUSCAR_DADOS_USUARIO]({state}) {
        const usuarioId = state.loki.user.id
        const {data} = await axios.get(`hal/usuarios/${usuarioId}`)
        return data
    },

    async [actionTypes.BUSCAR_USUARIOS_POR_ORGANIZACAO](context, {organizacaoId, filtros}) {
        const url = AzSearchUrlBuilder.build(`api/configuracoes/organizacoes/${organizacaoId}/usuarios`, filtros, {})
        const {data} = await axios.get(url)
        return data
    },

    async [actionTypes.CANCELAR_MINUTA](context, {contratoId, minutaId, motivoCancelamento}) {
        return await axios.put(`api/contratos/${contratoId}/minutas/${minutaId}/cancelamento`, {motivoCancelamento})
    },

    async [actionTypes.CRIAR_CONTRATO](context, contrato) {
        return await axios.post('api/contratos', contrato)
    },

    async [actionTypes.CRIAR_CONTRATO_LOTE](context, contrato) {
        return await axios.post('api/contratos/lote', contrato)
    },

    async [actionTypes.INSERIR_ANEXO](context, {contratoId, minutaId, anexo}) {
        return await axios.post(`api/contratos/${contratoId}/minutas/${minutaId}/anexos`, anexo)
    },

    async [actionTypes.EXTINGUIR_CONTRATO](context, {contratoId, extincao}) {
        return await axios.post(`api/contratos/${contratoId}/extincao`, extincao)
    },

    async [actionTypes.INSERIR_ORGANIZACAO](context, organizacaoDTO) {
        return await axios.post('api/organizacoes', organizacaoDTO)
    },

    async [actionTypes.INSERIR_SANCAO](context, {contratoId, minutaId, sancao}) {
        return await axios.post(`api/contratos/${contratoId}/minutas/${minutaId}/sancoes`, sancao)
    },

    async [actionTypes.CONVIDAR_SIGNATARIOS](context, {contratoId, minutaId}) {
        return await axios.put(`api/contratos/${contratoId}/minutas/${minutaId}/signatarios/convidar`)
    },

    async [actionTypes.CONVIDAR_SIGNATARIO_POR_EMAIL](context, {contratoId, minutaId, signatarioId}) {
        return await axios.put(`api/contratos/${contratoId}/minutas/${minutaId}/signatarios/${signatarioId}/convidar-por-email`)
    },

    async [actionTypes.CONVIDAR_SIGNATARIO_POR_EMAIL_SMS](context, {contratoId, minutaId, signatarioId}) {
        return await axios.put(`api/contratos/${contratoId}/minutas/${minutaId}/signatarios/${signatarioId}/convidar-por-email-sms`)
    },

    async [actionTypes.CONVIDAR_SIGNATARIO_POR_SMS](context, {contratoId, minutaId, signatarioId}) {
        return await axios.put(`api/contratos/${contratoId}/minutas/${minutaId}/signatarios/${signatarioId}/convidar-por-sms`)
    },

    async [actionTypes.CONVIDAR_SIGNATARIO_PRESENCIAL](context, {contratoId, minutaId, signatarioId}) {
        const {data} = await axios.get(`api/contratos/${contratoId}/minutas/${minutaId}/signatarios/${signatarioId}/convidar-presencial`)
        return data
    },

    async [actionTypes.CONVIDAR_USUARIO_ORGANIZACAO](context, usuario) {
        const {data} = await axios.post(`hal/public/usuarios`, usuario)
        return data
    },

    async [actionTypes.DEFINIR_ORDEM_ASSINATURA](context, {contratoId, minutaId, ordemSignatarios}) {
        await axios.post(`api/contratos/${contratoId}/minutas/${minutaId}/definir-ordem`, ordemSignatarios)
    },

    async [actionTypes.DESATIVAR_NOTIFICACAO_CONTRATO](context, {organizacaoId, contratoId, notificacaoContrato}) {
        return await axios.put(`api/notificacoes/organizacao/${organizacaoId}/contrato/${contratoId}/desativar`, notificacaoContrato)
    },

    async [actionTypes.DESATIVAR_NOTIFICACAO_PASTA](context, {organizacaoId, pastaId, pastaUsuarioAcesso}) {
        await axios.put(`api/notificacoes/organizacao/${organizacaoId}/pasta/${pastaId}/desativar`, pastaUsuarioAcesso)
    },

    async [actionTypes.DOWNLOAD_ARQUIVO_ANEXO](context, {contratoId, minutaId, anexoId}) {
        const {data} = await axios.get(`api/contratos/${contratoId}/minutas/${minutaId}/anexos/${anexoId}/download`)
        return data
    },

    async [actionTypes.DOWNLOAD_ARQUIVO_SANCAO](context, {contratoId, minutaId, sancaoId}) {
        const {data} = await axios.get(`api/contratos/${contratoId}/minutas/${minutaId}/sancoes/${sancaoId}/download`)
        return data
    },

    async [actionTypes.DOWNLOAD_ARQUIVO_EXTINCAO](context, {contratoId}) {
        const {data} = await axios.get(`api/contratos/${contratoId}/extincao/download`)
        return data
    },

    async [actionTypes.DOWNLOAD_MINUTA_ASSINADA](context, {contratoId, minutaId}) {
        const {data} = await axios.get(`api/contratos/${contratoId}/minutas/${minutaId}/assinada/base64`)
        return data
    },

    async [actionTypes.DOWNLOAD_MINUTA_IMPRESSAO](context, {contratoId, minutaId}) {
        const {data} = await axios.get(`api/contratos/${contratoId}/minutas/${minutaId}/impressao/base64`)
        return data
    },

    async [actionTypes.DOWNLOAD_MINUTA_ZIP](context, {contratoId, minutaId}) {
        const {data} = await axios.get(`api/contratos/${contratoId}/minutas/${minutaId}/download/zip`)
        return data
    },

    async [actionTypes.DOWNLOAD_MINUTA_ORIGINAL](context, {contratoId, minutaId}) {
        const {data} = await axios.get(`api/contratos/${contratoId}/minutas/${minutaId}/original/base64`)
        return data
    },

    async [actionTypes.ATUALIZAR_SIGNATARIO](context, {contratoId, minutaId, signatario}) {
        const signatarioId = signatario.signatarioId
        return await axios.put(`api/contratos/${contratoId}/minutas/${minutaId}/signatarios/${signatarioId}`, signatario)
    },

    async [actionTypes.ENVIAR_CHAVE_SEGURANCA_POR_SMS](context, {contratoId, minutaId, signatarioId}) {
        return await axios.get(`api/contratos/${contratoId}/minutas/${minutaId}/signatarios/${signatarioId}/enviar-chave-seguranca-sms`)
    },

    async [actionTypes.INATIVAR_USUARIO_ORGANIZACAO](context, {organizacaoId, usuarioId}) {
        return await axios.put(`api/configuracoes/organizacoes/${organizacaoId}/usuarios/${usuarioId}/inativar`)
    },

    async [actionTypes.LER_NOTIFICACOES](context, notificacaoIdList) {
        return await axios.put(`api/notificacoes/lido`, notificacaoIdList)
    },

    async [actionTypes.MOVER_CONTRATO_PASTA](context, {contratoId, contratoPasta}) {
        const {data} = await axios.put(`api/contratos/${contratoId}/pasta`, contratoPasta)
        return data
    },

    async [actionTypes.FORMALIZAR_CONTRATO](context, {contratoId, minutaId}) {
        return await axios.put(`api/contratos/${contratoId}/minutas/${minutaId}/publicacao`)
    },

    async [actionTypes.REMOVER_CONTRATO](context, contratoId) {
        return await axios.delete(`api/contratos/${contratoId}`)
    },

    async [actionTypes.REDEFINIR_ORDEM_SIGNATARIOS](context, {contratoId, minutaId, ordemSignatarios}) {
        await axios.put(`api/contratos/${contratoId}/minutas/${minutaId}/redefinir-ordem`, ordemSignatarios)
    },

    async [actionTypes.REENVIAR_CONVITE_SIGNATARIO](context, {contratoId, minutaId, signatario}) {
        const signatarioId = signatario.id
        return await axios.post(`api/contratos/${contratoId}/minutas/${minutaId}/signatarios/${signatarioId}/convite`, signatario)
    },

    async [actionTypes.REMOVER_ORDEM_ASSINATURA](context, {contratoId, minutaId}) {
        await axios.delete(`api/contratos/${contratoId}/minutas/${minutaId}/remover-ordem`)
    },

    async [actionTypes.REMOVER_NOTIFICACAO](context, notificacaoId) {
        return await axios.delete(`api/notificacoes/${notificacaoId}`)
    },

    async [actionTypes.REMOVER_SIGNATARIO](context, {contratoId, minutaId, signatarioId}) {
        return await axios.delete(`api/contratos/${contratoId}/minutas/${minutaId}/signatarios/${signatarioId}`)
    },

    async [actionTypes.REMOVER_ANEXO](context, {contratoId, minutaId, anexoId}) {
        return await axios.delete(`api/contratos/${contratoId}/minutas/${minutaId}/anexos/${anexoId}`)
    },

    async [actionTypes.REMOVER_SANCAO](context, {contratoId, minutaId, sancaoId}) {
        return await axios.delete(`api/contratos/${contratoId}/minutas/${minutaId}/sancoes/${sancaoId}`)
    },

    async [actionTypes.RENOVAR_CONTRATO](context, {contratoId, renovacao}) {
        return await axios.post(`api/contratos/${contratoId}/renovacao`, renovacao)
    },

    async [actionTypes.RESTAURAR_CONTRATO_ARQUIVADO](context, contratoId) {
        return await axios.put(`api/contratos/${contratoId}/desarquivamento`)
    },

    async [actionTypes.VINCULAR_USUARIO_COM_ORGANIZACAO]({state}, usuarioId) {
        const organizacaoId = state.login.organizacaoPlano.organizacaoId
        return await axios.post(`api/configuracoes/organizacoes/${organizacaoId}/usuarios/${usuarioId}`)
    },

    async [actionTypes.VISUALIZAR_ASSINATURA_MANUSCRITA](context, {contratoId, minutaId, signatarioId}) {
        const {data} = await axios.get(`api/contratos/${contratoId}/minutas/${minutaId}/signatarios/${signatarioId}/visualizar-manuscrita`)
        return data
    },

}
