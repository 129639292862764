import {mutationTypes} from '@/commons/constants'
import Vue from 'vue'

export default {
    [mutationTypes.PASTAS.ADD_PASTAS_PARA_ATUALIZAR](state, pastaId) {
        state.pastasParaAtualizar.push(pastaId)
    },

    [mutationTypes.PASTAS.ENTRAR_NA_PASTA](state, pasta) {
        Vue.set(state, 'pastaAtual', pasta)
        Vue.set(state, 'subPastasAtual', pasta.pastas)
        state.pastaBreadcrumbs.push(pasta)
    },

    [mutationTypes.PASTAS.REMOVE_PASTAS_PARA_ATUALIZAR](state, pastaId) {
        let indice = state.pastasParaAtualizar.indexOf(pastaId);
        if (indice !== -1) {
            state.pastasParaAtualizar.splice(indice, 1);
        }
    },

    [mutationTypes.PASTAS.SET_PASTA_ATUAL_CONTENT](state, pastas) {
        Vue.set(state.pastaAtual, 'pastas', pastas)
        Vue.set(state, 'subPastasAtual', pastas)
    },

    [mutationTypes.PASTAS.SET_PASTA_ATUAL_TOTAL_ELEMENTS](state, total) {
        Vue.set(state.pastaAtual, 'totalPastas', total)
    },

    [mutationTypes.PASTAS.SET_PASTA_ATUAL_FLAG_FILTRAGEM](state, flag) {
        Vue.set(state.pastaAtual, 'buscaFiltrada', flag)
    },

    [mutationTypes.PASTAS.VOLTAR_PARA_PASTA](state, pastaId) {
        let pastaPai = state.pastaAtual
        while (pastaPai !== null) {
            pastaPai = state.pastaBreadcrumbs.pop()
            if (pastaPai.pastaId === pastaId) {
                state.pastaBreadcrumbs.push(pastaPai)
                Vue.set(state, 'pastaAtual', pastaPai)
                Vue.set(state, 'subPastasAtual', pastaPai.pastas)
                pastaPai = null
            }
        }
    },

}
