<template>
    <v-card color="co-card-posicionamento-dados mt-5 pa-5">
        <v-card-text class="pa-0">
            <v-row no-gutters>
                <v-icon color="secondary-200" size="20"> mdi-email </v-icon>
                <span class="pl-2">{{ dadosSignatario.contato }}</span>
            </v-row>
            <v-row no-gutters>
                <span class="py-1 px-2 my-2 signatario-papel">{{ dadosSignatario.papel }}</span>
            </v-row>
            <v-divider/>
        </v-card-text>
        <v-card-actions class="pa-0">
            <v-row class="tipos-posicionamento mt-2">
                <div v-if="ehVisivel(opcaoPosicionamentoDado.value)"
                     v-for="(opcaoPosicionamentoDado, index) in opcoesPosicionamentoDados" :key="index"
                    class="mr-2"
                >
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" color="primary" outlined small class="mt-3"
                                   @click="criarPosicionamentoAssinatura(opcaoPosicionamentoDado)"
                                   :disabled="ehEditavel(opcaoPosicionamentoDado.value)">

                                <v-icon left color="primary">{{ opcaoPosicionamentoDado.icon }}</v-icon>
                                {{ opcaoPosicionamentoDado.tipo }}
                            </v-btn>
                        </template>
                        <span> Para posicionar, clique em {{ opcaoPosicionamentoDado.tipo }} e depois no documento.
                            <br/>
                            Caso deseje alterar o posicionamento segure {{ opcaoPosicionamentoDado.tipo }} para arrastar até o ponto desejado.
                        </span>
                    </v-tooltip>
                </div>
            </v-row>

        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'CardPosicionamentoDados',
    props: {
        dadosSignatario: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            opcoesPosicionamentoDados: [
                {
                    tipo: 'Assinatura', value: 'RUBRICA', icon: 'mdi-gesture'
                },
                {
                    tipo: 'Rubrica', value: 'DADO_EXTRA', icon: 'mdi-pencil'
                },
                {
                    tipo: 'Documento', value: 'CPF', icon: 'mdi-badge-account-horizontal'
                },
                {
                    tipo: 'Email', value: 'EMAIL', icon: 'mdi-email'
                },
                {
                    tipo: 'Nome', value: 'NOME_SIGNATARIO', icon: 'mdi-account'
                },
                {
                    tipo: 'Data Nascimento', value: 'DATA_NASCIMENTO', icon: 'mdi-calendar-month'
                }
            ]
        }
    },
    computed: {
        ehEletronica() {
            return this.dadosSignatario.tipoAssinatura === 'ELETRONICA'
        },
        ehWhatsapp() {
            return this.dadosSignatario.tipoAssinatura === 'WHATSAPP'
        },
        ehDigital() {
            return this.dadosSignatario.tipoAssinatura === 'DIGITAL'
        }
    },
    methods: {
        criarPosicionamentoAssinatura(opcao) {
            this.$emit('criar-posicionamento-assinatura', {...this.dadosSignatario, tipoPosicionamento: opcao})
        },
        ehVisivel(opcaoPosicionamentoDadoValue) {
            return this.ehEletronica || (this.ehWhatsapp && !this.ehEditavelWhatsapp(opcaoPosicionamentoDadoValue)) || (this.ehDigital && !this.ehEditavelDigital(opcaoPosicionamentoDadoValue))
        },
        ehEditavel(opcaoPosicionamentoDadoValue) {
            return !this.dadosSignatario.editavel || this.ehEditavelWhatsapp(opcaoPosicionamentoDadoValue) || this.ehEditavelDigital(opcaoPosicionamentoDadoValue)
        },
        ehEditavelWhatsapp(opcaoPosicionamentoDadoValue) {
            return this.ehWhatsapp && !(opcaoPosicionamentoDadoValue === 'DATA_NASCIMENTO' || opcaoPosicionamentoDadoValue === 'CPF'
                || opcaoPosicionamentoDadoValue === 'NOME_SIGNATARIO')
        },
        ehEditavelDigital(opcaoPosicionamentoDadoValue) {
            return this.ehDigital && (opcaoPosicionamentoDadoValue === 'DATA_NASCIMENTO')
        }
    }
}
</script>

<style lang="stylus">
.co-card-posicionamento-dados
    width 100%

    .signatario-papel
        background var(--background-300)
        font-size 11px
        border-radius 5px
</style>