<template>
    <v-card class="co-metodo-pagamento">
        <v-card-title class="primary titulo lighten-1 white--text">
            {{ lojaTitulo }} <span v-if="ehMetodoSelecionado" class="pagamento"> &nbsp;» {{ metodoSelecionado.nome }}</span>
            <v-spacer/>
            <v-icon color="white" @click="fechar"> mdi-window-close </v-icon>
        </v-card-title>

        <v-row v-if="!finalizarPagamento" class="metodo-pagamento_corpo" no-gutters>
            <v-col cols="12" sm="8">
                <pagamento
                    :dados-cartao="dadosCartao"
                    :eh-compra-plano="ehCompraPlano"
                    :plano="planoSelecionado"
                    @voltar="voltar"
                    @atualizar-metodo-selecionado="atualizarMetodoSelecionado"/>
            </v-col>

            <v-col class="ma-0 pa-0" cols="12" sm="4">
                <resumo
                    :itens-adicionais="itensAdicionais"
                    :plano-selecionado="planoSelecionado"
                    :metodo-selecionado="metodoSelecionado"
                    :valor-total-itens="valorTotalItens"
                    @confirmar-compra="confirmarCompra"/>
            </v-col>
        </v-row>

        <v-container v-else>
            <finalizar-pagamento
                @fechar="fechar"
                :metodo-selecionado="metodoSelecionado"
                :link-pagamento="linkPagamento"/>
        </v-container>

        <loja-loading v-if="saldoLoading"/>
    </v-card>
</template>

<script>
import LojaLoading from '../LojaLoading'
import Pagamento from './pagamento/Pagamento'
import Resumo from './resumo/Resumo'
import FinalizarPagamento from './finalizar-compra/FinalizarPagamento'
import CurrencyFormatterUtils from '@/commons/utils/CurrencyFormatterUtils';
import {actionTypes, metodoPagamentoEnum} from '@/commons/constants'

export default {
    name: 'MetodoPagamento',
    components: {
        LojaLoading,
        FinalizarPagamento,
        Pagamento,
        Resumo
    },
    props: {
        itensAdicionais: {
            type: Object,
        },
        planoSelecionado: {
            type: Object,
        },
        lojaTitulo: {
            type: String,
        },
        valorTotalItens: {
            type: Number,
        }
    },
    data() {
        return {
            dadosCartao: {
                codSegurancaCartao: '',
                cpfCnpjCartao: '',
                numeroCartao: '',
                nomeCartao: '',
                validadeCartao: '',
                titulo: '',
            },
            finalizarPagamento: false,
            metodoSelecionado: null,
            saldoLoading: false,
        }
    },
    computed: {
        ehMetodoSelecionado() {
            return this.metodoSelecionado !== null
        },
        ehMetodoSelecionadoCartao() {
            return this.metodoSelecionado === 0
        },
        ehCompraPlano() {
            return !!this.planoSelecionado
        },
    },
    methods: {
        atualizarMetodoSelecionado(metodoSelecionado) {
            this.metodoSelecionado = metodoSelecionado
        },
        async confirmarCompra() {
            try {
                this.saldoLoading = true

                const avulsos = []
                let plano = null

                if (this.itensAdicionais.documento.quantidade > 0) {
                    avulsos.push({
                        'tipoAdicionalCompra': this.itensAdicionais.documento.nome,
                        'quantidade': this.itensAdicionais.documento.quantidade,
                        'valorPacote': this.itensAdicionais.documento.valorPacote,
                        'quantidadePacote': this.itensAdicionais.documento.quantidadePacote
                    })
                }

                // if (this.itensAdicionais.comboDocumentoWhatsapp.quantidade > 0) {
                //     avulsos.push({
                //         'tipoAdicionalCompra': this.itensAdicionais.comboDocumentoWhatsapp.nome,
                //         'quantidade': this.itensAdicionais.comboDocumentoWhatsapp.quantidade,
                //         'valorPacote': this.itensAdicionais.comboDocumentoWhatsapp.valorPacote,
                //         'quantidadePacote': this.itensAdicionais.comboDocumentoWhatsapp.quantidadePacote
                //     })
                // }

                if (!!this.planoSelecionado) {
                    plano = {
                        'tipoAdicionalCompra': this.planoSelecionado.nome,
                        'quantidade': 1,
                        'valor': this.planoSelecionado.valorPacote,
                    }
                }

                const compra = {
                    'avulsos': avulsos,
                    'formaPagamento': Object.keys(metodoPagamentoEnum)[this.metodoSelecionado.index],
                    'parcelas': this.planoSelecionado?.parcelas || 0,
                    'plano': plano
                }

                if (this.ehMetodoSelecionadoCartao) {
                    const valido = await this.$validator._base.validateAll()

                    if (!valido) {
                        this.mostrarNotificacaoErro('Preencha os campos')
                        return
                    }

                    let mesCartao = parseInt(this.dadosCartao.validadeCartao.slice(0, 2))

                    if (mesCartao > 12 || mesCartao < 1) {
                        this.mostrarNotificacaoErro('Data de validade do cartão inválida!')
                        return
                    }

                    let anoAtual = this.anoAtual()
                    let anoCartao = parseInt(this.dadosCartao.validadeCartao.slice(3, 5))

                    if (anoCartao < anoAtual) {
                        this.mostrarNotificacaoErro('Cartão encontra-se vencido!' + anoCartao)
                        return
                    }
                }

                this.linkPagamento = await this.$store.dispatch(actionTypes.COMPRAS.INSERIR, compra)
                this.finalizarPagamento = this.linkPagamento !== null && this.linkPagamento !== undefined

                this.saldoLoading = false

                await this.enviarNotificacaoComercial(compra)
            } catch (e) {
                const exceptionType = e.response?.data.exception
                if (exceptionType && exceptionType.search('ErroCriarClienteFourBillingException') !== -1) {
                    if (e.response.data.message.includes("telefone")) {
                        this.mostrarNotificacaoErro('Erro ao criar cobrança, telefone inválido.')
                    } else {
                        this.mostrarNotificacaoErro('Erro ao criar cobrança')
                    }

                    console.error(e.response.data.message)
                } else {
                    this.mostrarNotificacaoErro('Erro ao criar cobrança')
                }
            } finally {
                this.saldoLoading = false
            }
        },
        anoAtual() {
            let ano = new Date().getFullYear()
            ano = ano.toString().slice(2, 4)
            return parseInt(ano)
        },
        async enviarNotificacaoComercial(compraAdicionais) {
            let emailInformacoes;

            if (compraAdicionais.avulsos.length > 0 && !this.planoSelecionado) {
                emailInformacoes = this.montarInformacoesCompraAvulsoEmail(compraAdicionais)
            } else {
                emailInformacoes = this.montarInformacoesCompraPlanoEmail(compraAdicionais)
            }

            await this.$store.dispatch(actionTypes.EMAIL.ENVIAR_EMAIL_COMERCIAL, {
                'mensagem': emailInformacoes.mensagem,
                'assunto': emailInformacoes.assunto,
                'tituloEmail': emailInformacoes.assunto,
            })
        },
        montarInformacoesCompraAvulsoEmail(compraAdicionais) {
            const avulsosCompradosSemRepetir = new Set(compraAdicionais.avulsos.map(item => item.tipoAdicionalCompra))
            const confirmouCompraTexto = avulsosCompradosSemRepetir.size > 1 ? `dos pacotes` : `do pacote`
            const nomesPacotesAvulsosComprados = Array.from(avulsosCompradosSemRepetir).toString().replaceAll(",", ", ")
            let resumoCompra = ''

            compraAdicionais.avulsos.forEach(itensResumo => {
                resumoCompra += `<br/> Qtd: ${itensResumo.quantidade} - Nome: ${itensResumo.tipoAdicionalCompra} - Valor: ${this.formatarValor(itensResumo.valorPacote, itensResumo.quantidade)}`
            })

            return {
                mensagem: `Lead ${this.$store.state.loki.user.fullName}, do email: ${this.$store.state.login.organizacaoPlano.organizacaoEmail}, do telefone: ${this.$store.state.login.organizacaoPlano.organizacaoTelefone ?? 'N/A'}, da organização: ${this.$store.state.login.organizacaoPlano.organizacaoNome}, atualmente no plano '${this.$store.getters.nomePlano}' confirmou a compra ${confirmouCompraTexto} ${nomesPacotesAvulsosComprados}. Necessário revisar no Asaas se foi gerado o pagamento ${confirmouCompraTexto} sem recorrência. <br/> <br/> ${resumoCompra}`,
                assunto: `Lead ${this.$store.state.loki.user.fullName} Confirmou a compra - ${confirmouCompraTexto} ${nomesPacotesAvulsosComprados}`
            }
        },
        montarInformacoesCompraPlanoEmail(compraAdicionais) {
            const nomePlano = compraAdicionais.plano.tipoAdicionalCompra
            let resumoCompra = ''

            const ehCompraPlanoParcelado = compraAdicionais.parcelas > 0
            const valorPlano = ehCompraPlanoParcelado ? this.planoSelecionado.valorParcela : compraAdicionais.plano.valor

            resumoCompra += `<br/> Qtd: ${compraAdicionais.plano.quantidade} - Nome: ${nomePlano} - Valor: ${this.formatarValor(valorPlano, compraAdicionais.plano.quantidade)} ${ehCompraPlanoParcelado ? `(${compraAdicionais.parcelas}X)` : ''}`

            return {
                mensagem: `Lead ${this.$store.state.loki.user.fullName}, do email: ${this.$store.state.login.organizacaoPlano.organizacaoEmail}, do telefone: ${this.$store.state.login.organizacaoPlano.organizacaoTelefone ?? 'N/A'}, da organização: ${this.$store.state.login.organizacaoPlano.organizacaoNome}, atualmente no plano '${this.$store.getters.nomePlano}' confirmou a compra do plano ${nomePlano}. Necessário revisar no Asaas se foi gerado o pagamento do plano. <br/> <br/> ${resumoCompra}`,
                assunto: `Lead ${this.$store.state.loki.user.fullName} Confirmou a compra - do plano ${nomePlano}`
            }
        },
        fechar() {
            this.$emit('fechar')
        },
        formatarValor(valor, multiplicador) {
            return CurrencyFormatterUtils.formatarBrl(Number(valor) * multiplicador)
        },
        voltar() {
            this.$emit('voltar')
        }
    }
}
</script>

<style lang="stylus">
.co-metodo-pagamento
    .titulo
        font-size 18px
        font-weight bold

        .pagamento
            font-size 16px

    .metodo-pagamento_corpo
        overflow-y auto
        min-height 494px
        height auto

@media (max-height: 600px)
    .co-metodo-pagamento
        .metodo-pagamento_corpo
            min-height calc(90vh - 58px)

</style>
