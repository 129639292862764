<template>
    <v-form class="co-formulario-opcional-varios-email">
        <rejeicao-documento
            :value="novoSignatario"
        />

        <chave-seguranca
            class="mt-10"
            v-if="!assinaturaPresencial"
            :value="novoSignatario"
        />

        <mensagem-email-signatario
            class="mt-10"
            v-if="!assinaturaPresencial"
            :value="novoSignatario"
        />

    </v-form>
</template>

<script>
import ChaveSeguranca from '../campos-formulario/ChaveSeguranca'
import MensagemEmailSignatario
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/MensagemEmailSignatario'
import RejeicaoDocumento
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/RejeicaoDocumento'

export default {
    name: 'FormularioOpcionalVariosEmail',
    components: {
        ChaveSeguranca,
        MensagemEmailSignatario,
        RejeicaoDocumento
    },
    props: {
        novoSignatario: {
            type: Object,
            required: true
        }
    },
    computed: {
        assinaturaPresencial() {
            return this.novoSignatario.disponibilidadeAssinatura === 'PRESENCIAL'
        }
    }
}
</script>