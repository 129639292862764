<template>
    <v-col class="co-card-plano justify-center" cols="10" lg="2" md="3" sm="4" xs="1">
        <div class="d-flex justify-center melhor-opcao"/>

        <v-card class="card-principal text-center mx-2">
            <div class="melhor-opcao">
                <div class="py-2"><strong>MELHOR OPÇÃO</strong></div>
                <v-divider/>
            </div>

            <v-card-text class="card-principal-conteudo text-color-default">
                <div class="justify-center titulo mb-5">{{ plano.titulo.toUpperCase() }}</div>

                <div v-if="plano.descricao" class="subtitulo mb-3 descricao">
                    <div v-for="descricao in getDescricao" :key="descricao">
                        {{ descricao }}
                    </div>
                </div>

                <div class="text-left beneficios">
                    <div v-for="beneficios in plano.beneficios" :key="beneficios.beneficio">
                        <span>
                            <v-icon v-if="beneficios.destaque === undefined || beneficios.destaque" color="green" size="15px">
                                mdi-check-circle-outline
                            </v-icon>

                            {{ beneficios.beneficio }}
                        </span>
                    </div>
                </div>
            </v-card-text>

            <v-card-actions>
                <div class="adquirir">
                    <div class="d-flex align-center justify-center">
                        <v-btn class="botao" color="primary" :disabled="!ehAdministradorOrganizacao || ehPlanoPersonalizado" block width="150" @click="falarComEspecialista">
                            Fale com vendas
                        </v-btn>
                    </div>
                </div>
            </v-card-actions>
        </v-card>

        <div v-if="!ehPlanoEmCancelamento && ehAdministradorOrganizacao" class="d-flex justify-center cancelar-plano">
            <span v-if="ehPlanoPersonalizado" class="my-3 text-color-default btn-cancelar" @click="cancelarPlano">
                CANCELAR PLANO
            </span>
        </div>
    </v-col>
</template>

<script>
import {mapState, mapGetters} from 'vuex'

export default {
    name: 'CardPlanoPersonalizado',
    props: {
        plano: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState(['contatoComercial']),
        ...mapGetters(['ehPlanoEmCancelamento', 'ehAdministradorOrganizacao', 'ehPlanoPersonalizado']),
        getDescricao() {
            return this.plano.descricao.split(';')
        }
    },
    methods: {
        cancelarPlano() {
            this.$emit('cancelar-plano')
        },
        falarComEspecialista() {
            window.open(`https://api.whatsapp.com/send?phone=${this.contatoComercial.telefone}&text=Ol%C3%A1%2C%20estou%20acessando%20a%20Besign%20e%20gostaria%20de%20saber%20mais%20sobre%20os%20planos%20de%20API.`, '_blank')
        }
    }
}
</script>

<style lang="stylus" scoped>
.co-card-plano
    min-width 290px

    .text-color-default
        color var(--text-color-200)

    .melhor-opcao
        height 37px

    .card-principal
        display flex
        flex-direction column
        justify-content space-between
        border-radius 5px

        .melhor-opcao
            background-color var(--v-primary-base)
            color white

        .card-principal-conteudo
            .titulo
                font-weight bold
                font-size 17px
                color var(--primary)

            .subtitulo
                font-size 15px

            .promocao
                font-size 14px
                height 44px

            .descricao
                min-height 133px

            .beneficios
                font-size 13px
                height 230px

            .meu-plano
                color var(--primary) !important

    .adquirir
        width 100%

    .cancelar-plano
        min-height 45px
        
        .btn-cancelar
            cursor pointer

@media (min-width: 1840px)
    .co-card-plano
        .card-principal
            .card-principal-conteudo
                .beneficios
                    height 220px

@media (max-height: 660px)
    .co-card-plano
        .card-principal
            .card-principal-conteudo
                .beneficios
                    display none
</style>
