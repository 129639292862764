<template>
    <v-dialog :value="dialog" max-width="500px" persistent scrollable>
        <v-card class="co-novo-grupo-contato" :disabled="loadingContatos || loading">
            <v-card-title class="title primary--text mb-6">
                <span v-if="ehNovoGrupoSignatarios">Novo Grupo</span>
                <span v-else>Editar Grupo</span>
                <v-spacer/>
                <v-icon color="primary" @click="fechar"> mdi-window-close </v-icon>
            </v-card-title>

            <v-card-text clas="campos mx-3">
                <nome-grupo
                    v-model="grupoSignatarios"
                    title-case
                    campo-requerido
                    data-vv-scope="novoGrupo"/>

                <lista-signatarios-selecionaveis
                    v-model="grupoSignatarios"
                    :cadastros="cadastrosSignatarios"
                    data-vv-scope="novoGrupo"/>
            </v-card-text>

            <v-divider/>
            <v-card-actions class="mx-3 my-2">
                <v-btn class="cancelar" depressed outlined @click="fechar">
                    CANCELAR
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green white--text" depressed @click="salvar" :loading="loadingContatos || loading">
                    SALVAR
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ListaSignatariosSelecionaveis from './components/ListaSignatariosSelecionaveis'
import NomeGrupo from './components/NomeGrupo'
import {actionTypes} from '@/commons/constants'
import DocumentoSignatario
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/DocumentoSignatario'

export default {
    name: 'DialogNovoGrupoContatos',
    components: {
        DocumentoSignatario,
        NomeGrupo,
        ListaSignatariosSelecionaveis,
    },
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
        grupoSignatarios: {
            type: Object,
        },
        cadastrosSignatarios: {
            type: Array,
        },
        loadingContatos: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {
        ehNovoGrupoSignatarios() {
            return this.grupoSignatarios.id === null
        }
    },
    methods: {
        fechar() {
            this.$emit('fechar')
        },
        async salvar() {
            const valido = await this.$validator.validateAll("novoGrupo")

            if (!valido) {
                return
            }

            if (this.ehNovoGrupoSignatarios) {
                await this.salvarGrupoSignatarios()
            } else {
                await this.salvarEdicao()
            }
        },
        async salvarGrupoSignatarios() {
            try {
                this.loading = true
                this.setMensagemLoading(`Salvando grupo de ${this.$tc("pt_br.signatario", 2).toLowerCase()}...`)

                let grupoNovo = await this.$store.dispatch(actionTypes.GRUPO_SIGNATARIOS.INSERIR_GRUPO_SIGNATARIOS, {
                    novoGrupoSignatarios: {
                        id: this.grupoSignatarios.id,
                        nome: this.grupoSignatarios.nome,
                        cadastrosSignatariosIds: this.grupoSignatarios.signatarios
                    }
                })

                if (grupoNovo.sucesso) {
                    this.mostrarNotificacaoSucessoDefault()
                    this.$emit('salvar')
                } else {
                    this.mostrarNotificacaoErro(this.tratarMensagemErro(grupoNovo.mensagem))
                }

                this.loading = false
            } catch (e) {
                this.loading = false
            }
        },
        async salvarEdicao() {
            try {
                this.loading = true
                this.setMensagemLoading('Salvando alteração...')

                let grupoAtualizado = await this.$store.dispatch(actionTypes.GRUPO_SIGNATARIOS.ATUALIZAR_GRUPO_SIGNATARIOS, {
                    atualizarGrupoSignatarios: {
                        id: this.grupoSignatarios.id,
                        nome: this.grupoSignatarios.nome,
                        cadastrosSignatariosIds: this.grupoSignatarios.signatarios
                    }
                })
                
                if (grupoAtualizado.sucesso) {
                    this.mostrarNotificacaoSucessoDefault()
                    this.$emit('salvar')
                } else {
                    this.mostrarNotificacaoErro(this.tratarMensagemErro(grupoAtualizado.mensagem))
                }
                this.loading = false
            } catch (e) {
                this.loading = false
            }
        },
        tratarMensagemErro(mensagem) {
            return mensagem.slice(2, -2)
        }
    }
}
</script>

<style lang="stylus">
.co-novo-grupo-contato
    .campos
        overflow-y auto

    .cancelar
        color var(--text-color-200)

</style>
