<template>
    <div class="co-novo-documento-signatario-mensagem-email-signatario">
        <span>
            Mensagem
        </span>

        <icon-info :descricao-campo="descricaoCampo"/>

        <v-textarea
            v-model="value.mensagem"
            name="mensagem"
            class="pt-0 mt-0"
            :placeholder="placeholder"
            persistent-placeholder
            hide-details
            maxlength="250"
            type="text"
            :disabled="disabled"
            auto-grow
            rows="2"
        >
        </v-textarea>

        <div class="quantidade-caracteres">
            {{ value.mensagem !== null ? value.mensagem.length : '0' }}/250
        </div>
    </div>
</template>

<script>

import IconInfo from '@/views/criar-documento/novo-documento-signatario/components/IconInfo'

export default {
    name: 'MensagemEmailSignatarioNovo',
    components: {IconInfo},
    props: {
        value: {
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            tamanhoTexto: [v => v.length <= 250 || 'Quantidade Máxima 250 caracteres']
        }
    },
    computed: {
        descricaoCampo() {
            return `Aqui você pode definir uma mensagem para o ${this.$tc("pt_br.signatario").toLowerCase()}, a mensagem será enviada no email de convite de assinatura.`
        },
        placeholder() {
            return `Informe uma mensagem para o ${this.$tc("pt_br.signatario").toLowerCase()}`
        }
    }

}
</script>

<style lang="stylus">
.co-novo-documento-signatario-mensagem-email-signatario
    position relative
    width 100%

    .mensagem-email-field
        padding-top 0
        margin-top 0

    .label
        color var(--text-color-300)

    .quantidade-caracteres
        display flex
        justify-content flex-end
        font-size 11px
        color var(--text-color-100)
</style>