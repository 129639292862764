<template>
    <div class="sem-recorrencia-wrapper d-flex">
        <v-row no-gutters class="co-sem-recorrencia justify-center align-end">
            <card-pacote
                class="my-3"
                :produto="itensAdicionais.documento"
                :quantidade-saldo="saldoUtilizadoDocumento"
                :quantidade-total="saldoTotalDocumento"
            />

            <!-- <card-pacote
                class="my-3"
                :produto="itensAdicionais.comboDocumentoWhatsapp"
            /> -->
        </v-row>

        <div class="adquirir-avulsos d-flex align-center justify-center">
            <div class="d-flex align-center justify-center">
                <v-btn :disabled="!selecionouAlgumPacote || !ehAdministradorOrganizacao" class="botao-comprar" color="primary" block @click="abrirPagamentos">
                    COMPRAR
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import CardPacote from './components/CardPacote'
import {actionTypes} from '@/commons/constants'
import CurrencyFormatterUtils from '../../../../../commons/utils/CurrencyFormatterUtils'

export default {
    name: 'SemRecorrencia',
    components: {
        CardPacote
    },
    props: {
        itensAdicionais: {
            type: Object,
        },
    },
    data() {
        return {
            dialogDowngrade: false,
            algumAdicionalSelecionado: false
        }
    },
    computed: {
        ...mapState(['consumoOrganizacao']),
        ...mapGetters(['ehAdministradorOrganizacao']),
        saldoTotalDocumento() {
            return this.consumoOrganizacao.totalDocumentos
        },
        saldoUtilizadoDocumento() {
            return this.consumoOrganizacao.documentosUtilizados
        },
        selecionouAlgumPacote() {
            return Object.keys(this.itensAdicionais).some(adicional => this.itensAdicionais[adicional].quantidade > 0)
        }
    },
    methods: {
        async abrirPagamentos() {
            this.$emit('abrir-pagamentos', null)

            await this.enviarNotificacaoComercial()
        },
        async enviarNotificacaoComercial() {
            const adicionaisSelecionados = []

            if (this.itensAdicionais.documento.quantidade > 0) {
                adicionaisSelecionados.push(this.itensAdicionais.documento)
            }

            // if (this.itensAdicionais.comboDocumentoWhatsapp.quantidade > 0) {
            //     adicionaisSelecionados.push(this.itensAdicionais.comboDocumentoWhatsapp)
            // }

            await this.$store.dispatch(actionTypes.EMAIL.ENVIAR_EMAIL_COMERCIAL, {
                'mensagem': this.montarNotificacaoCompraAdmin(adicionaisSelecionados),
                'assunto': `Lead ${this.$store.state.loki.user.fullName} adicionou quantidades no carrinho em sem recorrência`,
                'tituloEmail': `Lead ${this.$store.state.loki.user.fullName} adicionou quantidades no carrinho em sem recorrência`
            })
        },

        formatarValor(valor, multiplicador) {
            return CurrencyFormatterUtils.formatarBrl(Number(valor) * multiplicador)
        },
        montarNotificacaoCompraAdmin(compraAdicionais) {
            const itensCompradosSemRepetir = new Set(compraAdicionais.map(item => item.nome))
            const nomesPlanosOuAdicionaisComprados = Array.from(itensCompradosSemRepetir).toString().replaceAll(",", ", ")

            let resumoCompra = ''

            compraAdicionais.forEach(itensResumo => {
                resumoCompra += `Qtd: ${itensResumo.quantidade} - Nome: ${itensResumo.nome} - Valor: ${this.formatarValor(itensResumo.valorPacote, itensResumo.quantidade)} <br/>`
            })

            return `Lead ${this.$store.state.loki.user.fullName}, do email: ${this.$store.state.login.organizacaoPlano.organizacaoEmail}, do telefone: ${this.$store.state.login.organizacaoPlano.organizacaoTelefone ?? 'N/A'}, da organização: ${this.$store.state.login.organizacaoPlano.organizacaoNome}, atualmente no plano '${this.$store.getters.nomePlano}' adicionou quantidades de ${nomesPlanosOuAdicionaisComprados} sem recorrência. <br/> <br/> ${resumoCompra}`
        },
    }
}
</script>

<style lang="stylus" scoped>
.sem-recorrencia-wrapper
    flex-direction column

    .adquirir-avulsos
        width 100%
        padding-bottom 20px

        .botao-comprar
            width 300px
            max-width 70%

</style>
