var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"co-importar-signatarios-tabela"},[_c('div',{staticClass:"busca mb-6"},[_c('div',{directives:[{name:"az-auth",rawName:"v-az-auth",value:('CadastroSignatario.Novo'),expression:"'CadastroSignatario.Novo'"}]},[_c('v-btn',{attrs:{"color":"primary","dark":"","depressed":"","large":""},on:{"click":_vm.novoCadastroSignatario}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"20px"}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Adicionar ")],1)],1),_c('v-spacer'),_c('v-text-field',{staticClass:"search-bar",attrs:{"label":"Buscar pelo nome ou e-mail","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.atualizarFiltro.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"icon":"","outlined":""},on:{"click":_vm.atualizarFiltro}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-magnify ")])],1)],1),_c('v-data-table',{staticClass:"tabela-signatarios",attrs:{"hide-default-footer":"","show-select":"","no-data-text":_vm.noDataText,"headers":_vm.headers,"items":_vm.cadastrosSignatarios,"items-per-page":5,"checkbox-color":"primary"},on:{"toggle-select-all":function($event){_vm.value.todosSelecionados = !_vm.value.todosSelecionados}},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-simple-checkbox',_vm._g({attrs:{"value":props.value || _vm.value.todosSelecionados || props.indeterminate,"indeterminate":props.indeterminate,"color":"primary"}},on))]}},{key:"item.contato",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.montarContato(item))+" ")]}},{key:"item.papel",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.converterPapel(item.papelId))+" ")]}}]),model:{value:(_vm.value.selecionados),callback:function ($$v) {_vm.$set(_vm.value, "selecionados", $$v)},expression:"value.selecionados"}}),_c('v-flex',{staticClass:"footer"},[_c('v-pagination',{staticClass:"paginas justify-center elevation-0",attrs:{"dense":"","length":_vm.getPaginacaoAtual().numPages,"total-visible":7},on:{"input":_vm.irParaPagina},model:{value:(_vm.getPaginacaoAtual().page),callback:function ($$v) {_vm.$set(_vm.getPaginacaoAtual(), "page", $$v)},expression:"getPaginacaoAtual().page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }