<template>
    <v-dialog v-model="dialog" persistent width="400">
        <v-card>

            <v-toolbar flat dark color="primary" class="co-detalhe-contrato-toolbar">
                <span class="headline titulo-anexo">Países</span>
                <v-spacer></v-spacer>
                <v-icon @click="fechar" color="white"> mdi-window-close </v-icon>
            </v-toolbar>

            <v-layout class="justify-center">
                <v-flex class="sm9 xs10">
                    <v-text-field
                        label=""
                        v-model="search"
                        placeholder="Nome do país"
                        prepend-inner-icon="mdi-magnify"
                    ></v-text-field>
                </v-flex>
            </v-layout>
            <v-flex class="text-xs-center" style="overflow-y: auto">

                <v-list style="height: 350px">
                    <template v-for="(item, index) in listaPaisesSearch">
                        <v-list-item
                            :key="item.title"
                            @click="adicionar(item)">
                            <v-list-item-content>
                                <v-list-item-title style="color: #777777">{{ item.nome }}</v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-list-item-action-text style="color: #777777">{{ item.fone }}
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>

                        <v-divider/>
                    </template>
                </v-list>

            </v-flex>
        </v-card>
    </v-dialog>

</template>

<script>
import paises from '@/commons/utils/ddi.json'

export default {
    props: ['dialog'],
    name: 'DialogPais',
    data() {
        return {
            search: '',
            listaPaises: paises
        }
    },
    computed: {
        listaPaisesSearch() {
            return this.listaPaises.filter(lista => {
                return (lista.nome.toUpperCase().includes(this.search.toUpperCase()) || lista.fone.toString().includes(this.search))
            })
        }
    },
    methods: {
        adicionar(item) {
            this.$emit('adicionar-ddi', item)
            this.fechar()
        },
        fechar() {
            this.search = ''
            this.$emit('fechar')
        }
    }
}
</script>