<template>
    <v-dialog persistent v-model="dialog" width="300">
        <v-card>
            <div class="co-loading">
                <div class="texto">{{ texto }}</div>
                <div class="loading-clm"></div>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'Loading',
    components: {},
    props: {
        dialog: {
            type: Boolean
        },
        texto: {
            type: String,
            default: 'Carregando ...'
        }
    }
}
</script>

<style lang="stylus">
.co-loading
    width 100%
    display flex
    flex-direction column
    justify-content center
    align-items center
    background-color transparent
    text-align start
    padding-bottom 20px

    .texto
        padding 10px 20px
        color #777
        font-weight 700
        font-size 14px
        text-align start
        width 100%

    .loading-clm
        position relative
        width 260px
        height 10px
        background-color rgb(155, 150, 150)
        overflow hidden
        border-radius 2px

    .loading-clm:before
        content ''
        position absolute
        left 0
        right 0
        height 100%
        width 100px
        border-radius 10px
        background-color var(--v-primary-base)
        animation-name loading
        animation-duration 2s
        animation-timing-function ease-in-out
        animation-iteration-count infinite

    @keyframes loading
        0%
            transform translateX(-100px)

        100%
            transform translateX(280px)

</style>