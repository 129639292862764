<template>
    <div v-az-auth="'Contrato.Publicar'" class="acao-formalizar">
        <v-tooltip top v-if="existeAssinaturaRejeitada">
            <template v-slot:activator="{ on }">
                <div v-on="on" style="width: 100%">
                    <v-btn v-az-auth="'Contrato.Publicar'"
                           block disabled
                           large
                           style="margin: 0 !important; height: 60px">
                        FORMALIZAR {{ $tc("pt_br.documento").toUpperCase() }}
                    </v-btn>
                </div>
            </template>
            <span>É necessário que todos {{ $tc("pt_br.signatario", 2).toLowerCase() }} assinem o contrato para formalizar</span>
        </v-tooltip>

        <v-tooltip v-else-if="ehSignatarioPagante && possuiSignatarios && possuiSignatariosAguardadoEnvio"
                   :disabled="possuiRepresentanteLegal" top>
            <template v-slot:activator="{ on }">
                <div v-on="on" style="width: 100%">
                    <v-btn
                        v-az-auth="'Contrato.Signatario.Convidar'"
                        :dark="possuiRepresentanteLegal && habilitarBotaoConvidar"
                        :disabled="!(possuiRepresentanteLegal && habilitarBotaoConvidar)" 
                        block
                        color="green lighten-2"
                        depressed large
                        style="margin: 0 !important; height: 60px"
                        @click="enviarConvites"
                        :loading="loading">
                        ENVIAR CONVITES
                    </v-btn>
                </div>
            </template>
            <span>Para enviar os convites é necessário possuir 1 representante legal</span>
        </v-tooltip>

        <v-btn v-else-if="possuiSignatarios && possuiSignatariosAguardadoEnvio"
               v-az-auth="'Contrato.Signatario.Convidar'" 
               :dark="habilitarBotaoConvidar"
               :disabled="!habilitarBotaoConvidar"
               block color="green lighten-2"
               depressed
               large
               style="margin: 0 !important; height: 60px"
               @click="enviarConvites"
               :loading="loading">
            ENVIAR CONVITES
        </v-btn>

        <v-tooltip top v-else-if="existeSignatarioAguardandoAssinatura">
            <template v-slot:activator="{ on }">
                <div v-on="on" style="width: 100%">
                    <v-btn v-az-auth="'Contrato.Publicar'"
                           block depressed
                           disabled large
                           style="margin: 0 !important; height: 60px">
                        FORMALIZAR {{ $tc("pt_br.documento").toUpperCase() }}
                    </v-btn>
                </div>
            </template>
            <span>É necessário que todos {{ $tc("pt_br.signatario", 2).toLowerCase() }} assinem o contrato para formalizar</span>
        </v-tooltip>

        <v-tooltip v-else-if="ehSignatarioPagante && !possuiRepresentanteLegal" top>
            <template v-slot:activator="{ on }">
                <div v-on="on" style="width: 100%">
                    <v-btn v-az-auth="'Contrato.Publicar'"
                           block depressed
                           disabled
                           large
                           style="margin: 0 !important; height: 60px" 
                           @click="formalizarDocumento"
                           :loading="loading">
                        FORMALIZAR {{ $tc("pt_br.documento").toUpperCase() }}
                    </v-btn>
                </div>
            </template>
            <span>Para formalizar este contrato é necessário possuir 1 representante legal</span>
        </v-tooltip>

        <v-btn v-else v-az-auth="'Contrato.Publicar'"
               block
               color="green lighten-2" dark
               depressed large
               style="margin: 0 !important; height: 60px"
               @click="formalizarDocumento"
               :loading="loading">
            FORMALIZAR {{ $tc("pt_br.documento").toUpperCase() }}
        </v-btn>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import _ from 'lodash'

export default {
    name: 'BotaoPrincipal',
    props: [
        'existeAssinaturaRejeitada',
        'existeSignatarioAguardandoAssinatura',
        'possuiSignatariosAguardadoEnvio',
        'possuiSignatarios',
        'signatariosPendentesEnvioConvite',
        'loading'
    ],
    computed: {
        ...mapState(['minutaAtual', 'signatarios']),
        ...mapGetters(['ehSignatarioPagante', 'possuiRepresentanteLegal']),
        habilitarBotaoConvidar() {
            const possuiOrdemEConvitesNaoEnviados = this.possuiOrdemAssinatura && !this.minutaAtual.envioConvitesIniciados
            const naoPossuiOrdemEPossuiSignatariosPendentes = !this.possuiOrdemAssinatura && Boolean(this.signatariosPendentesEnvioConvite)

            const possuiOrdemEConvitesInterrompidos = this.possuiOrdemAssinatura && this.minutaAtual.envioConvitesIniciados && !this.possuiSignatarioConvidado

            return naoPossuiOrdemEPossuiSignatariosPendentes || possuiOrdemEConvitesNaoEnviados || possuiOrdemEConvitesInterrompidos
        },
        possuiSignatarioConvidado() {
            return this.signatarios.some(signatario => signatario.statusSignatario === 'CONVIDADO')
        },
        possuiOrdemAssinatura() {
            return this.minutaAtual.ordemAssinaturas
        },
        possuiSignatarioWhatsAppNaoConvidado() {
            return this.signatarios.some(signatario => signatario.statusSignatario === 'NAO_CONVIDADO' && signatario.tipoAssinatura === 'WHATSAPP')
        },
    },
    methods: {
        enviarConvites() {
            if (this.possuiSignatarioWhatsAppNaoConvidado) {
                this.mostrarNotificacaoErro(`Existem ${this.$tc("pt_br.signatario", 2).toLowerCase()} com o tipo de assinatura WhatsApp. A funcionalidade foi descontinuada, altere para e-mail.`)
            } else {
                this.$emit('convidar')
            }
        },
        formalizarDocumento() {
            this.$emit('formalizar')
        }
    }
}
</script>

<style lang="stylus">
.acao-formalizar
    display flex
    justify-content center
    border-top 1px solid #ccc
    position absolute
    bottom 0
    width 100%

@media (max-width: 720px)
    .acao-formalizar
        justify-content left !important
        width 100%

</style>