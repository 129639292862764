import axios from 'axios'
import {actionTypes} from '@/commons/constants'
import {AzSearchUrlBuilder} from '@azinformatica/loki'

export default {
    async [actionTypes.CADASTRO_SIGNATARIOS.BUSCAR_CADASTRO_SIGNATARIO](context, {cadastroSignatarioId}) {
        const {data} = await axios.get(`api/cadastros-signatarios/${cadastroSignatarioId}`)
        return data
    },

    async [actionTypes.CADASTRO_SIGNATARIOS.BUSCAR_CADASTROS_SIGNATARIOS_FILTRADOS]({state}) {
        const {filtros, paginacao} = state.cadastrosSignatariosBusca
        const url = AzSearchUrlBuilder.build(`api/cadastros-signatarios/paginada`, filtros, paginacao)
        const {data} = await axios.get(url)
        return data
    },

    async [actionTypes.CADASTRO_SIGNATARIOS.BUSCAR_MEUS_CADASTROS_SIGNATARIOS]() {
        const url = AzSearchUrlBuilder.build(`api/cadastros-signatarios`)
        const {data} = await axios.get(url)
        return data
    },

    async [actionTypes.CADASTRO_SIGNATARIOS.INSERIR_CADASTRO_SIGNATARIO](context, {cadastroSignatario}) {
        const {data} = await axios.post(`api/cadastros-signatarios`, cadastroSignatario)
        return data
    },

    async [actionTypes.CADASTRO_SIGNATARIOS.ATUALIZAR_CADASTRO_SIGNATARIO](context, {cadastroSignatario}) {
        const {data} = await axios.put(`api/cadastros-signatarios`, cadastroSignatario)
        return data
    },

    async [actionTypes.CADASTRO_SIGNATARIOS.REMOVER_CADASTRO_SIGNATARIO](context, {cadastroSignatarioId}) {
        const {data} = await axios.delete(`api/cadastros-signatarios/${cadastroSignatarioId}`)
        return data
    },

    async [actionTypes.CADASTRO_SIGNATARIOS.IMPORTAR_CADASTROS_SIGNATARIOS]({state}, {cadastrosSelecionados}) {
        const minutaId = state.minutaAtual.id
        const {data} = await axios.post(`api/cadastros-signatarios/importar/minuta/${minutaId}`, {cadastrosSelecionados})
        return data
    },
}