<template>
    <div class="co-papel-signatario">
        <v-select
            v-model="value.papelId"
            v-validate="validarCampo()"
            :class="campoRequerido ? 'required-field' : ''"
            :error-messages="errors.collect('Papel assinatura')"
            :items="papeis"
            label="Papel"
            name="Papel assinatura"
            placeholder="Selecione"
            persistent-placeholder
            :rules="[(v) => !!v || 'Informe o campo obrigatório']">
        </v-select>

        <div v-if="descricao" class="hint-checkbox">
            {{ descricaoCampo }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'PapelSignatario',
    props: {
        value: {
            required: true
        },
        campoRequerido: {
            type: Boolean,
            default: false
        },
        descricao: {
            type: Boolean
        },
        papeis: {
            required: true,
            type: Array,
            default: () => []
        }
    },
    data: () => ({
        papeisSelecao: []
    }),
    computed: {
        descricaoCampo() {
            return `Função que o ${this.$tc("pt_br.signatario").toLowerCase()} desempenhará no momento da assinatura`
        }
    },
    methods: {
        montarPapeisParaSelecao() {
            if (this.papeis) {
                this.papeis.forEach(papel => {
                    this.papeisSelecao.push({
                        text: papel.nome,
                        value: papel.papelId
                    })
                })
            }
        },
        validarCampo() {
            return this.campoRequerido ? 'required' : ''
        }
    }
}
</script>