<template>
    <div class="co-organizacao-pagina px-10 pt-10 pb-4">
        <h1 class="titulo-pagina pa-3 text-h6 font-weight-bold">
            Organização
        </h1>
        <h3 class="subtitulo-pagina px-3 font-weight-medium subtitle-2">
            Os dados da organização serão utilizados para a emissão das Notas Fiscais de pagamento. 
            Caso não estejam sendo enviados com os dados inseridos nessa tela contate o nosso suporte.
        </h3>

        <dados-organizacao 
            :organizacaoValue="dadosOrganizacao"
            @salvar="atualizarDadosOrganizacao"
        />
    </div>
</template>

<script>
import DadosOrganizacao from '../../../common/DadosOrganizacao'
import actionTypes from '@/commons/constants/action-types'

export default {
    components: {
        DadosOrganizacao
    },
    data() {
        return {
            dadosOrganizacao: {}
        }
    },
    async mounted() {
        this.dadosOrganizacao = await this.buscarDadosOrganizacao()        
    },
    methods: {
        async atualizarDadosOrganizacao() {
            await this.$store.dispatch(actionTypes.ORGANIZACAO.ATUALIZAR, {organizacaoId: this.$store.state.organizacao.organizacaoId, organizacaoDTO: this.dadosOrganizacao})

            this.mostrarNotificacaoSucesso('Dados atualizados com sucesso.')

            this.dadosOrganizacao = await this.buscarDadosOrganizacao()
        },
        async buscarDadosOrganizacao() {
            return await this.$store.dispatch(actionTypes.ORGANIZACAO.BUSCAR_DADOS_ORGANIZACAO)
        }
    }
}
</script>

<style lang="stylus">
.co-organizacao-pagina
    .container
        margin 0

    .titulo-pagina
    .subtitulo-pagina
        color var(--primary)
</style>