<template>
    <v-btn
    v-if="ehMostrarScroll"
    @click="scroll"
    class="botao-scroll"
    color="primary"
    width="50"
    height="50"
    depressed
    rounded
    fixed
    >
        <v-icon :class="ehArrowSubir ? 'girar-arrow' : ''" color="white" size="25">
            mdi-arrow-down
        </v-icon>
    </v-btn>
</template>

<script>
export default {
    name: 'BotaoScrollDocumento',
    props: {
        paginacao: {
            type: Object,
            required: true
        }
    },
    computed: {
        ehArrowSubir() {
            return this.paginacao.ehUltimaPagina
        },
        ehMostrarScroll() {
            return this.paginacao.totalPaginas >= 3
        },
    },
    methods: {
        scroll() {
            const pdfViewer = document.querySelector('#az-pdf-viewer')

            pdfViewer?.scrollTo({ top: this.ehArrowSubir ? 0 : pdfViewer?.scrollHeight, behavior: 'smooth' })
        },
    }
}
</script>

<style lang="stylus" scoped>
.botao-scroll
    bottom 130px
    right 10px
    min-width unset !important

    &:hover
        transform scale(1.05)

    .girar-arrow
        transform rotate(180deg)
</style>