<template>
    <v-dialog :value="dialog" max-width="500px" persistent scrollable>
        <v-card class="co-novo-contato" :disabled="loading">
            <v-card-title class="title primary--text mb-6">
                <span v-if="ehNovoCadastro">Novo Cadastro</span>
                <span v-else>Editar Cadastro</span>
                <v-spacer/>
                <v-icon color="primary" @click="fechar"> mdi-window-close </v-icon>
            </v-card-title>

            <v-card-text clas="campos mx-3">
                <nome-signatario
                    v-model="cadastroSignatario"
                    title-case
                    campo-requerido
                    data-vv-scope="novoContato"/>

                <tipo-assinatura-formulario
                    v-model="cadastroSignatario"
                    :tipos-assinatura="tiposAssinatura"
                    campo-requerido
                    data-vv-scope="novoContato"/>

                <email-signatario
                    v-model="cadastroSignatario"
                    :campo-requerido="!ehAssinaturaWhatsapp"
                    data-vv-scope="novoContato"/>

                <telefone-signatario-novo
                    v-model="cadastroSignatario"
                    :campo-requerido="ehAssinaturaWhatsapp"
                    data-vv-scope="novoContato"/>

                <documento-signatario
                    v-model="cadastroSignatario"
                    :documentos="documentosSignatario"
                    campo-requerido
                    data-vv-scope="novoContato"/>

                <papel-signatario
                    v-model="cadastroSignatario"
                    :papeis="papeisSignatario"
                    campo-requerido
                    data-vv-scope="novoContato"/>

                <lista-grupos-selecionaveis
                    v-model="cadastroSignatario"
                    :grupos="gruposSignatarios"
                    data-vv-scope="novoContato"/>
            </v-card-text>

            <v-divider/>
            <v-card-actions class="mx-3 my-2">
                <v-btn class="cancelar" depressed outlined @click="fechar">
                    CANCELAR
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green white--text" depressed @click="salvar" :loading="loading">
                    SALVAR
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {actionTypes} from '@/commons/constants'
import {mapActions, mapState} from 'vuex'
import NomeSignatario
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/NomeSignatario'
import EmailSignatario
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/EmailSignatario'
import DocumentoSignatario
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/DocumentoSignatario'
import PapelSignatario
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/PapelSignatario'
import ListaGruposSelecionaveis from './components/ListaGruposSelecionaveis'
import TipoAssinaturaFormulario
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/adicionar-signatario/components/campos-formulario/TipoAssinatura'
import TelefoneSignatarioNovo
    from '@/views/criar-documento/novo-documento-signatario/components/dados-principais/components/TelefoneSignatarioNovo'

export default {
    name: 'DialogNovoContato',
    components: {
        TipoAssinaturaFormulario,
        ListaGruposSelecionaveis,
        NomeSignatario,
        EmailSignatario,
        TelefoneSignatarioNovo,
        DocumentoSignatario,
        PapelSignatario
    },
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
        cadastroSignatario: {
            type: Object,
        },
        documentosSignatario: {
            type: Array,
        },
        gruposSignatarios: {
            type: Array,
        },
        papeisSignatario: {
            type: Array,
        },
    },
    data() {
        return {
            tiposAssinatura: [
                {id: 'ELETRONICA', nome: 'Assinatura Eletrônica'},
                {id: 'DIGITAL', nome: 'Assinatura Digital'},
                //{id: 'WHATSAPP', nome: 'Assinatura Whatsapp'}
            ],
            loading: false,
        }
    },
    computed: {
        ...mapState(['login']),
        assinaturaDigital() {
            return this.login.funcionalidadesOrganizacao.assinaturaDigital
        },
        ehNovoCadastro() {
            return this.cadastroSignatario.id === null
        },
        ehAssinaturaWhatsapp() {
            return this.cadastroSignatario.tipoAssinatura === 'WHATSAPP'
        },
        ehEmailVazio() {
            return this.cadastroSignatario.email === undefined || this.cadastroSignatario.email === null || this.cadastroSignatario.email === ''
        },
        ehTelefoneVazio() {
            return this.cadastroSignatario.telefone === undefined || this.cadastroSignatario.telefone === null || this.cadastroSignatario.telefone === ''
        }
    },
    created() {
        this.possuiAssinaturaDigital()
    },
    methods: {
        ...mapActions([
            actionTypes.BUSCAR_PAPEIS_SIGNATARIOS
        ]),
        fechar() {
            this.$emit('fechar')
        },
        possuiAssinaturaDigital() {
            if (!this.assinaturaDigital) {
                this.tiposAssinatura.splice(1, 1)
            }
        },
        async salvar() {
            const valido = await this.$validator.validateAll("novoContato")
            if (!valido) {
                return
            }
            if (this.ehEmailVazio && this.ehTelefoneVazio) {
                this.mostrarNotificacaoErro('Preencha o Email e/ou o Telefone para salvar')
                return
            }
            if (this.ehNovoCadastro) {
                await this.salvarCadastro()
            } else {
                await this.salvarEdicao()
            }
        },
        async salvarCadastro() {
            try {
                this.loading = true
                this.setMensagemLoading(`Salvando ${this.$tc("pt_br.signatario").toLowerCase()}...`)
                let cadastroNovo = await this.$store.dispatch(actionTypes.CADASTRO_SIGNATARIOS.INSERIR_CADASTRO_SIGNATARIO, {
                    cadastroSignatario: this.cadastroSignatario
                })
                if (cadastroNovo.sucesso) {
                    this.mostrarNotificacaoSucessoDefault()
                    this.$emit('salvar')
                } else {
                    this.mostrarNotificacaoErro(this.tratarMensagemErro(cadastroNovo.mensagem))
                }
                this.loading = false
            } catch (e) {
                this.loading = false
            }
        },
        async salvarEdicao() {
            try {
                this.loading = true
                this.setMensagemLoading('Salvando alteração...')
                let cadastroAtualizado = await this.$store.dispatch(actionTypes.CADASTRO_SIGNATARIOS.ATUALIZAR_CADASTRO_SIGNATARIO, {
                    cadastroSignatario: this.cadastroSignatario
                })
                if (cadastroAtualizado.sucesso) {
                    this.mostrarNotificacaoSucessoDefault()
                    this.$emit('salvar')
                } else {
                    this.mostrarNotificacaoErro(this.tratarMensagemErro(cadastroAtualizado.mensagem))
                }
                this.loading = false
            } catch (e) {
                this.loading = false
            }
        },
        tratarMensagemErro(mensagem) {
            return mensagem.slice(2, -2)
        }
    }
}
</script>

<style lang="stylus">
.co-novo-contato
    .campos
        overflow-y auto

    .cancelar
        color var(--text-color-200)

</style>
