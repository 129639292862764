<template>
    <div class="co-cabecalho-itens">
        <div class="titulo">
            <div style="padding-top: 5px">
                Itens ({{ quantidade }})
            </div>
            <div style="font-size: 12px; color: #aaaaaa">
                Total do contrato: {{ valorTotal }}
            </div>
        </div>
        <template v-if="!cadastrandoItens && !ehSemFuncionalidade">
            <a v-az-auth="'Contrato.Item.Novo'" class="novo" @click="cadastrarItem">
                <v-icon color="green" size="20px"> mdi-plus-circle </v-icon>
            </a>
        </template>
    </div>
</template>

<script>
export default {
    name: 'CabecalhoItens',
    props: {
        cadastrandoItens: {
            type: Boolean,
            required: true
        },
        ehSemFuncionalidade: {
            type: Boolean,
            default: false
        },
        quantidade: {
            required: true
        },
        valorTotal: {
            required: true
        }
    },
    methods: {
        cadastrarItem() {
            this.$emit('cadastrar')
        }
    }
}
</script>

<style lang="stylus">
.co-cabecalho-itens
    display flex
    justify-content space-between
    height 50px
    padding 0 20px
    vertical-align center
    border-bottom 1px solid #ccc

    .novo
        display flex !important
        align-items center !important
        color green
        font-size 12px
        font-weight bold

    .titulo
        display flex
        flex-direction column
        text-align initial
        color #777
        font-size 14px
        text-transform uppercase

</style>