<template>
    <div class="co-importar-signatarios-menu">
        <v-menu bottom offset-y transition="slide-x-transition">
            <template v-slot:activator="{ on: menu }">
                <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip }">
                        <slot name="botao-importar" v-bind:menu="menu" v-bind:tooltip="tooltip">
                            <template>
                                <v-btn color="primary" v-on="{ ...tooltip, ...menu }">
                                    <v-icon class="mr-2">mdi-magnify</v-icon>
                                    Buscar da Lista de {{ $tc("pt_br.signatario", 2) }}
                                </v-btn>
                            </template>
                        </slot>
                    </template>

                    <span>Importar {{ $tc("pt_br.signatario", 2) }} ou Grupos de {{ $tc("pt_br.signatario", 2) }}</span>
                </v-tooltip>
            </template>

            <v-list>
                <v-list-item link @click="abrirDialogImportarGrupos">
                    <v-list-item-title class="menu-itens">
                        Grupos de {{ $tc("pt_br.signatario", 2) }}
                    </v-list-item-title>
                </v-list-item>

                <v-list-item link @click="abrirDialogImportarSignatarios">
                    <v-list-item-title class="menu-itens"> {{ $tc("pt_br.signatario", 2) }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>

import DialogImportarSignatarios from './dialogs/DialogImportarSignatarios'
import DialogImportarGrupos from './dialogs/DialogImportarGrupos'

export default {
    name: 'ImportarSignatariosMenu',
    components: {
        DialogImportarGrupos,
        DialogImportarSignatarios,
    },
    props: {
        abrirDialogs: {
            type: Object,
            require: true,
            default() {
                return {
                    dialogImportarSignatarios: false,
                    dialogImportarGrupos: false
                }
            }
        }
    },
    methods: {
        abrirDialogImportarGrupos() {
            this.abrirDialogs.dialogImportarGrupos = true
        },
        abrirDialogImportarSignatarios() {
            this.abrirDialogs.dialogImportarSignatarios = true
        }
    }
}
</script>


<style lang="stylus">
.co-importar-signatarios-menu
    .botao-importar
        cursor pointer

    .menu-itens
        i
            color #999
</style>

