<template>
    <v-container class="co-pagamento">
        <v-col class="pagamento">
            <v-card-text class="conteudo">
                <v-row no-gutters>
                    <div class="primary--text lighten-1 titulo">PAGAMENTO</div>
                </v-row>

                <v-radio-group v-model="metodoSelecionado" class="radio-group" hide-details>
                    <card-metodo-pagamento
                        v-for="metodo in metodosPagamentoAceitos"
                        :key="metodo.index"
                        :dados-cartao="dadosCartao"
                        :metodo="metodo"
                        :metodo-selecionado="metodoSelecionado"
                        class="mb-3"
                    ></card-metodo-pagamento>
                </v-radio-group>
            </v-card-text>

            <v-card-actions class="acoes d-block">
                <v-divider/>

                <v-row class="px-2 py-3" no-gutters>
                    <v-btn class="voltar"
                           depressed
                           @click="voltar">Voltar
                    </v-btn>

                    <v-spacer/>

                    <v-img
                        :src="imagens.siteSeguro"
                        contain
                        height="40px"
                        max-width="100px"
                    />
                </v-row>
            </v-card-actions>
        </v-col>
    </v-container>
</template>

<script>

import SiteSeguro from '@/assets/icons/site-seguro.png'
import CardMetodoPagamento from './components/CardMetodoPagamento'
import {metodoPagamentoEnum} from '@/commons/constants'

export default {
    name: 'Pagamento',
    components: {CardMetodoPagamento},
    props: {
        dadosCartao: {
            type: Object,
        },
        ehCompraPlano: {
            type: Boolean,
            default: false
        },
        plano: {
            type: Object | undefined
        }
    },
    data() {
        return {
            imagens: {
                siteSeguro: SiteSeguro
            },
            metodoSelecionado: null,
            total: 0.00,
        }
    },
    computed: {
        metodosPagamentoAceitos() {
            if (this.ehCompraPlano) {
                const ehPlanoAnual = this.plano.nome.includes('_ANUAL')
                const naoEhParcelado = this.plano.parcelas === 0

                if (ehPlanoAnual && naoEhParcelado) {
                    return metodoPagamentoEnum
                }

                return [metodoPagamentoEnum.CARTAO_CREDITO]
            } else {
                return metodoPagamentoEnum
            }
        },
    },
    watch: {
        'metodoSelecionado'() {
            this.$emit('atualizar-metodo-selecionado', this.metodoSelecionado)
        }
    },
    methods: {
        fechar() {
            this.$emit('fechar')
        },
        formatReal(valor) {
            return valor.toLocaleString('pt-br', {style: 'currency', currency: 'BRL', minimumFractionDigits: 2})
        },
        voltar() {
            this.$emit('voltar')
        }
    }
}
</script>

<style lang="stylus">
.co-pagamento
    display flex
    max-height 550px

    .v-input__control
        width 100% !important

    .radio-group
        width 100%

    .pagamento
        background-color white
        align-items stretch
        justify-content space-between
        padding 0
        position relative

        .conteudo
            overflow-y auto
            height calc(100% - 82px)
            padding-bottom 0

        .acoes
            position absolute
            width 100%
            bottom 0

    .certificado
        color #C7C7C7

    .voltar
        background: #eeeeee
        color #787878
        height 40px

@media (max-width: 600px)
    .co-pagamento
        .pagamento
            .conteudo
                overflow-y unset
                height unset
                padding-bottom 105px

</style>
