<template>
    <v-app-bar flat dark color="primary" class="co-detalhe-contrato-caminho">
      <span class="ml-1 no-mobile titulo-com-acao espacamento ma-0" v-if="nome"
            @click="abrirModalEditar">{{ nome | azClipText(70) }}</span>
        <span class="mr-2 mobile espacamento ma-0" v-if="nome">{{ nome | azClipText(20) }}</span>

        <v-tooltip left color="black" class="pasta">
            <template v-slot:activator="{ on }">
                <v-icon size="20"
                        class="icone-visualizar ml-4"
                        v-on="on"
                        @click="abrirModalParaMoverContrato">
                    mdi-folder-move
                </v-icon>
            </template>
            <span>Mover</span>
        </v-tooltip>

        <notificacao
            :mostrar-modal-desativar-notificacao="mostrarModalDesativarNotificacao"
            :contrato="true"
            :eh-administrador-organizacao="ehAdministradorOrganizacao()"
            :eh-contrato-arquivado="ehContratoArquivado()"
            :eh-contrato-extinto="ehContratoExtinto()"
            :eh-detalhe-do-contrato="ehDetalheDoContrato()"
            :eh-minuta-cancelada="ehMinutaCancelada()"
            :pode-notificar="contrato.notificar"
            :usuarios-notificacao-contrato="usuariosNotificacaoContrato"
            @desativarNotificacao="desativarNotificacao"
            @fecharModalDesativarNotificacao="fecharModalDesativarNotificacao()"
            @verificarPerfilUsuario="verificarPerfilUsuario"
            class="ml-1"
        />
        <modal-notificacao
            v-model="usuariosNotificacaoContrato"
            v-if="modalNotificacao"
            :notificacao-contrato=true
            @ativarNotificacao="ativarNotificacao"
            @desativarNotificacao="desativarNotificacao"
            @desativarTodasNotificacoes="desativarTodasNotificacoes"
            @fecharModalNotificacao="fecharModalNotificacao"
        />
    </v-app-bar>

</template>
<script>
import {hasPermissions} from '@azinformatica/loki'
import Notificacao from '../commons/Notificacao'
import _ from 'lodash'
import ModalNotificacao from '@/views/contrato/commons/ModalNotificacao'
import {actionTypes} from '@/commons/constants'
import {mapState} from 'vuex'

export default {
    name: 'DetalheContratoCaminho',
    components: {Notificacao, ModalNotificacao},
    data() {
        return {
            modalNotificacao: false,
            mostrarModalDesativarNotificacao: false,
            usuariosNotificacaoContrato: []
        }
    },
    computed: {
        ...mapState(['contrato']),
    },
    watch: {
        'contrato'() {
            this.buscarUsuariosNotificacaoContrato()
        }
    },
    props: ['caminhoContrato', 'nome', 'minutaAtual'],
    methods: {
        abrirModalEditar() {
            this.$emit('abrirModalEditar')
        },
        abrirModalDesativarNotificacao() {
            this.mostrarModalDesativarNotificacao = true
        },
        abrirModalNotificacao() {
            this.modalNotificacao = true
        },
        abrirModalParaMoverContrato() {
            const userPermissions = this.$store.state.loki.user.authorities
            if (hasPermissions(userPermissions, ['Contrato.Mover'])) {
                this.$emit('abrirModalParaMoverContrato')
            }
        },
        async ativarNotificacao(usuario) {
            await this.ativarNotificacaoContrato(usuario.usuarioId)
            await this.buscarUsuariosNotificacaoContrato()
            this.atualizarNotificacaoContratoState([usuario], true)
            this.mostrarNotificacaoSucessoDefault()
        },
        async ativarNotificacaoContrato(usuariosId) {
            await this.$store.dispatch(actionTypes.ATIVAR_NOTIFICACAO_CONTRATO, {
                organizacaoId: this.contrato.organizacaoId,
                contratoId: this.contrato.id,
                notificacaoContrato: {
                    contratoId: this.contrato.id,
                    usuariosId: [usuariosId]
                }
            })
        },
        atualizarNotificacaoContratoState(usuariosNotificacao, notificar) {
            const usuarioLogadoId = this.$store.state.loki.user.id
            if (_.find(usuariosNotificacao, (usuario) => usuario.usuarioId === usuarioLogadoId)) {
                this.contrato.notificar = notificar
            }
        },
        async buscarUsuariosNotificacaoContrato() {
            this.usuariosNotificacaoContrato = await this.$store.dispatch(actionTypes.BUSCAR_USUARIOS_NOTIFICACAO_CONTRATO, {
                organizacaoId: this.$store.state.login.organizacaoPlano.organizacaoId,
                contratoId: this.contrato.id
            })
        },
        ehAdministradorOrganizacao() {
            const usuarioLogadoId = this.$store.state.loki.user.id
            const administradoresId = this.$store.state.login.organizacaoPlano.administradoresId
            return _.find(administradoresId, (administradorId) => administradorId === usuarioLogadoId) !== undefined
        },
        ehContratoArquivado() {
            return this.contrato.arquivado
        },
        ehContratoExtinto() {
            return this.contrato.extinto
        },
        ehDetalheDoContrato() {
            return this.contrato.id !== undefined
        },
        ehMinutaCancelada() {
            return this.minutaAtual.situacaoFormalizacao === 'CANCELADO'
        },
        async desativarNotificacao(usuario) {
            await this.desativarNotificacaoContrato([usuario.usuarioId])
            await this.buscarUsuariosNotificacaoContrato()
            this.atualizarNotificacaoContratoState([usuario], false)
            this.fecharModalDesativarNotificacao()
            this.mostrarNotificacaoSucessoDefault()
        },
        async desativarNotificacaoContrato(usuarioId) {
            await this.$store.dispatch(actionTypes.DESATIVAR_NOTIFICACAO_CONTRATO, {
                organizacaoId: this.$store.state.login.organizacaoPlano.organizacaoId,
                contratoId: this.contrato.id,
                notificacaoContrato: {
                    contratoId: this.contrato.id,
                    usuariosId: usuarioId
                }
            })
        },
        async desativarTodasNotificacoes(usuarios) {
            const usuariosId = this.getUsuariosId(usuarios)
            await this.desativarNotificacaoContrato(usuariosId)
            await this.buscarUsuariosNotificacaoContrato()
            this.atualizarNotificacaoContratoState(usuarios, false)
            this.mostrarNotificacaoSucessoDefault()
        },
        fecharModalDesativarNotificacao() {
            this.mostrarModalDesativarNotificacao = false
        },
        fecharModalNotificacao() {
            this.modalNotificacao = false
        },
        getUsuariosId(usuarios) {
            let usuariosId = []

            usuarios.forEach(usuario => {
                if (usuario.ativo) {
                    usuariosId.push(usuario.usuarioId)
                }
            })

            return usuariosId
        },
        verificarPerfilUsuario(usuarios) {
            if (this.ehAdministradorOrganizacao()) {
                return this.abrirModalNotificacao()
            }
            if (this.contrato.notificar && !this.ehMinutaCancelada()) {
                return this.abrirModalDesativarNotificacao()
            }
            return this.ativarNotificacao(usuarios)
        },

    }
}
</script>

<style lang="stylus">
.co-detalhe-contrato-caminho
    .v-toolbar__content
        height auto !important
        padding 0 !important
        font-weight bold
        font-size 18px

    .espacamento
        margin 5px
        padding 0 !important

    .pasta
        padding-left 10px
        padding-right 10px

    .titulo-com-acao
        cursor pointer
        padding 0 5px

        &:hover
            background-color rgba(255, 255, 255, 0.2) !important
            border-radius 2px !important
</style>