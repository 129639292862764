<template>
    <v-dialog v-model="dialog" width="700" persistent scrollable>
        <v-card class="text-center">
            <v-card-title class="justify-center">
                <span>Solicitar cancelamento plano atual</span>
            </v-card-title>
            <v-divider class="mx-6 mb-3"/>

            <mensagem-plano-recorrente 
                ref="form"
                v-if="!ehPlanoAnual"
                :loading="loading"
                :motivo="motivo"
                :motivosCancelamento="motivosCancelamento"
            />

            <mensagem-plano-anual 
                v-else 
                @fechar="fechar"
            />

            <v-card-actions :class="posicaoBotoesAcao" class="acoes">
                <div class="secundaria">
                    <v-btn color="primary" @click="fechar">Sair sem cancelar</v-btn>
                </div>

                <div v-if="!ehPlanoAnual" class="principal">
                    <v-btn depressed :loading="loading" @click="solicitarCancelamento">Solicitar Cancelamento</v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex'
import {actionTypes} from '@/commons/constants'
import MensagemPlanoRecorrente from './dialog-cancelamento/components/mensagem-plano-recorrente/MensagemPlanoRecorrente';
import MensagemPlanoAnual from './dialog-cancelamento/components/mensagem-plano-anual/MensagemPlanoAnual';

export default {
    name: 'DialogCancelarPlano',
    props: {
        dialog: {
            type: Boolean
        }
    },
    components: {
        MensagemPlanoRecorrente,
        MensagemPlanoAnual,
    },
    data() {
        return {
            motivo: {
                cancelamento: "Outros (Descreva)",
                outros: '',
            },
            motivosCancelamento: [
                "Irei recontratar (ex. troca de plano, boleto, cartão ou nota fiscal)",
                "A plataforma não atendeu ao que estava buscando",
                "Já contratei ou vou contratar outro plataforma",
                "O valor da plataforma é muito caro",
                "A plataforma apresenta erros/mal funcionamento",
                "Estou enviando poucos documentos",
                "A plataforma é muito difícil de usar",
                "Outros (Descreva)"
            ],
            loading: false
        }
    },
    computed: {
        ...mapGetters(['ehPlanoAnual']),
        posicaoBotoesAcao() {
            return this.ehPlanoAnual ? 'justify-center' : 'justify-space-between'
        }
    },
    methods: {
        fechar() {
            this.$emit('fechar')
        },
        async solicitarCancelamento() {
            try {
                const ehFormularioValido = await this.validarFormulario()

                if (!ehFormularioValido) return

                this.loading = true

                const cancelamentoOutput = await this.$store.dispatch(actionTypes.PLANOS.SOLICITAR_CANCELAMENTO_PLANO, {
                    motivoCancelamento: this.motivo.cancelamento,
                    motivoOutros: this.motivo.outros
                })

                
                this.loading = false
                this.$emit('solicitacao-realizada', cancelamentoOutput)
            } catch (e) {
                this.loading = false
                this.mostrarNotificacaoErro('Erro ao solicitar cancelamento')
            }
        },
        async validarFormulario() {
            return await this.$refs.form.validarFormulario()
        }
    }
}
</script>

<style lang="stylus">
.co-dialog-cancelar-plano
    .subtitulo
        font-size 16px
</style>