export default {
    pastaRaiz: {
        pastaId: null,
        paginacao: {
            page: 0,
            rowsPerPage: 20
        },
        pastas: [],
        totalPastas: null,
        buscaFiltrada: false,
        ultimaPaginaBusca: false,
        quantidadeContratos: null
    },
    pastaAtual: null,
    subPastasAtual: null,
    pastaBreadcrumbs: [],
    pastasParaAtualizar: [],
}
