<template>
    <v-dialog v-model="dialog" persistent width="500">
        <v-card class="clm-ops-contratar-servico py-2">
            <v-icon class="btn-fechar" small @click="fechar"> mdi-window-close </v-icon>
            <v-flex class="text-xs-center">
                <img src="../../assets/ops/feliz.gif">
                <div class="texto">Opa, vejo aqui que gostaria contratar um novo serviço.
                </div>
                <div class="texto">Que tal resolvermos isso, entre em contato com o nosso suporte
                    <a style="color: orange" @click.prevent="abrirChatSuporte">AQUI</a>.
                </div>
            </v-flex>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'DialogContratarServico',
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
    },
    computed: {
        ...mapState(['contatoComercial'])
    },
    methods: {
        fechar() {
            this.$emit('fechar')
        },
        abrirChatSuporte() {
            this.$emit('fechar')
            window.open(`https://api.whatsapp.com/send?phone=${this.contatoComercial.telefone}`)
        },
    }
}
</script>

<style lang="stylus">
.clm-ops-contratar-servico
    .texto
        color #aaaaaa
        margin 10px 40px

    .btn-fechar
        position absolute
        z-index 1
        right 10px
        top 10px

</style>