<template>
    <div class="co-formulario-tipos-assinaturas">
        <tipo-assinatura-digital
            v-if="mostrarAssinaturaDigital"
            v-model="novoSignatario"
            :digital-habilitado="assinaturaDigital"
            @tipo-assinatura-proximo="tipoAssinaturaProximo"
            @abrir-dialog-contratar-servico="abrirDialogContratarServico"
            @abrir-dialog-info="abrirDialogInfo"
        />

        <tipo-assinatura-eletronica
            v-model="novoSignatario"
            :eletronica-habilitado="assinaturaEletronica"
            @abrir-dialog-contratar-servico="abrirDialogContratarServico"
            @tipo-assinatura-proximo="tipoAssinaturaProximo"
            @abrir-dialog-info="abrirDialogInfo"
        />

<!--        <tipo-assinatura-whatsapp-->
<!--            v-model="novoSignatario"-->
<!--            :whatsapp-habilitado="assinaturaWhatsapp"-->
<!--            @tipo-assinatura-proximo="tipoAssinaturaProximo"-->
<!--            @abrir-dialog-info="abrirDialogInfo"-->
<!--            @abrir-dialog-contratar-servico="abrirDialogContratarServico"-->
<!--        />-->

        <dialog-contratar-servico
            :dialog="dialogContratarServico"
            @fechar="fecharDialogContratarServico"
        />

        <dialog-info-tipo-assinatura
            :dialog="dialogInfoTipoAssinatura"
            :tipo-assinatura="informacoesTipoAssinatura"
            @fechar="fecharDialogInfo"
        />
    </div>
</template>

<script>
import {mapState} from 'vuex'
import DialogContratarServico from '../../../../../../../../commons/components/DialogContratarServico'
import DialogInfoTipoAssinatura from '../../../../../dialogs/DialogInfoTipoAssinatura'
import TipoAssinaturaDigital from '../campos-formulario/TipoAssinaturaDigital'
import TipoAssinaturaEletronica from '../campos-formulario/TipoAssinaturaEletronica'
import TipoAssinaturaWhatsapp from '../campos-formulario/TipoAssinaturaWhatsapp'

export default {
    name: 'FormularioTiposAssinaturas',
    props: {
        mostrarAssinaturaDigital: {
            type: Boolean,
            required: true
        },
        novoSignatario: {
            required: true
        }
    },
    components: {
        DialogContratarServico,
        DialogInfoTipoAssinatura,
        TipoAssinaturaDigital,
        TipoAssinaturaEletronica,
        TipoAssinaturaWhatsapp
    },
    data() {
        return {
            dialogContratarServico: false,
            dialogInfoTipoAssinatura: false,
            informacoesTipoAssinatura: {}
        }
    },
    computed: {
        ...mapState(['login']),
        assinaturaDigital() {
            return this.login.funcionalidadesOrganizacao.assinaturaDigital
        },
        assinaturaEletronica() {
            return this.login.funcionalidadesOrganizacao.assinaturaEletronicaEmail
        },
        assinaturaWhatsapp() {
            return this.login.funcionalidadesOrganizacao.assinaturaEletronicaWhatsapp
        }
    },
    methods: {
        abrirDialogContratarServico() {
            this.dialogContratarServico = true
        },
        abrirDialogInfo(value) {
            this.informacoesTipoAssinatura.nome = value
            this.dialogInfoTipoAssinatura = true
        },
        fecharDialogContratarServico() {
            this.dialogContratarServico = false
        },
        fecharDialogInfo() {
            this.dialogInfoTipoAssinatura = false
        },
        tipoAssinaturaProximo() {
            this.$emit('tipo-assinatura-proximo')
        }
    }
}
</script>

<style lang="stylus">
.co-formulario-tipos-assinaturas
    .selecionar-assinatura
        margin-bottom 20px;
        color #777777
</style>
