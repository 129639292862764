<template>
    <div>
        <v-dialog value="true" persistent fullscreen hide-overlay scrollable>
            <v-card>
                <v-card-title class="pa-0">
                    <toobar @voltar="voltarParaMeusDocumentos" :titulo="getLabelToolbarTitle()"/>
                </v-card-title>

                <v-card-text class="pa-0">
                    <v-container fluid class="co-renovar-documento">
                        <v-row no-gutters :style="getHeight">
                            <v-col class="formulario-documento pa-0" cols="12" sm="3">
                                <formulario-documento
                                    ref="formularioDocumentoNovo"
                                    class="ma-3 formulario"
                                    :documento="documento"
                                    renovacao
                                />

                                <div v-if="!ehMobile" class="d-flex justify-end pa-4 btn-continuar">
                                    <v-btn color="primary"
                                           @click="continuarOnce"
                                           :disabled="disableContinuar">
                                        {{ getLabelBotaoGerarNovaVersaoOuRenovar() }}
                                        <v-icon right> mdi-arrow-right</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>

                            <v-col :style="getStyleBackground" cols="12" sm="9">
                                <div>
                                    <upload :pdfs="pdfs" @adicionar-pdf="adicionarPdfs"/>
                                    <lista-upload class="mb-6" :pdfs="pdfs" @remover-pdf="removerPdf"/>
                                    <v-btn v-if="ehMobile"
                                           color="primary"
                                           class="btn-continuar btn-mobile"
                                           @click="continuarOnce"
                                           :disabled="disableContinuar">
                                        {{ getLabelBotaoGerarNovaVersaoOuRenovar() }}
                                        <v-icon right> mdi-arrow-right</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <dialog-default-confirmar-acao
            v-if="dialogLimitePlanoAtingido"
            :dialog="dialogLimitePlanoAtingido"
            sentimento="assustado"
            titulo="Eita! Você atingiu seu limite de uso"
            :subtitulo=mensagemLimiteDocumentos
            label-botao-principal="Sim, eu quero!"
            @acao-principal="acaoDialogLimitePlanoAtingido"
            @fechar="fecharDialogLimitePlanoAtingido"
        />
    </div>
</template>

<script>
import Toobar from '../components/toobar/Toobar'
import Upload from '../novo-documento/components/documento/campos-upload/Upload'
import ListaUpload from '../novo-documento/components/documento/campos-upload/listaUpload/ListaUpload'
import FormularioDocumento from '../novo-documento/components/documento/campos-formulario/FormularioDocumento'
import DialogDefaultConfirmarAcao from '@/commons/components/dialogs/DialogDefaultConfirmarAcao'
import DateTimeUtils from '@/commons/utils/DateTimeUtils'
import controleAcoes from '@/views/contrato/controle-acoes'
import {mapGetters, mapState} from 'vuex'
import {actionTypes} from '@/commons/constants'
import moment from 'moment-timezone'
import _ from 'lodash'
import RouterUtils from '@/commons/utils/RouterUtils'

export default {
    name: "RenovarDocumento",
    components: {DialogDefaultConfirmarAcao, FormularioDocumento, ListaUpload, Upload, Toobar},
    data() {
        return {
            documento: {
                contratoId: null,
                nome: '',
                nomeDocOriginal: '',
                inicioVigencia: '',
                fimVigencia: '',
                inicioAssinatura: '',
                fimAssinatura: '',
                tags: [],
                pastaId: null
            },
            disableContinuar: false,
            pdfs: [],
            dialogLimitePlanoAtingido: false,
        }
    },
    async mounted() {
        this.documento.contratoId = this.$route.params.contratoId
        await this.buscarContratoPorId()
        await this.buscarVersaoMaisRecenteMinuta()
        this.inicializarModeloContrato()
    },
    watch: {
        'contrato.contratoTags'(val) {
            this.documento.tags = _.clone(val)
        }
    },
    computed: {
        ...mapState(['login', 'organizacao', 'contrato', 'pastaAtual', 'minutaAtual']),
        ...mapGetters(['podeCriarContratoRaiz', 'ehAdministradorOrganizacao', 'nomePlanoContratado', 'ehEfcazCLM']),
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        },
        getHeight() {
            return this.ehMobile ? ' height: auto' : ' height: 100%'
        },
        getStyleBackground() {
            return this.ehMobile ? 'background: var(--background-200)' : 'background: white'
        },
        mensagemLimiteDocumentos(){
            const textClm = 'Adquira o Plano PRO e continue alavancando seu negócio.'
            let textoBesign = ''

            if (this.ehPlanoFree){
                textoBesign = 'A quantidade de documentos disponíveis acabou! Adquira um plano recorrente ou um pacote individual para continuar a enviar contratos para assinatura. Clique em “Sim, eu quero” para conhecer nossas opções.'
            } else {
                textoBesign = 'A quantidade de documentos disponíveis em seu plano acabou! Faça um upgrade para um plano com mais documentos ou adquira um pacote individual para continuar a enviar contratos para assinatura. Clique em “Sim, eu quero” para conhecer nossas opções.'
            }

            return `${this.ehEfcazCLM ? textClm : textoBesign} <span style="font-size:20px">&#128640;</span>`
        }
    },
    methods: {
        async acaoDialogLimitePlanoAtingido() {
            window.open("https://www.asaas.com/c/416172638803", '_blank')
            await this.$store.dispatch(actionTypes.EMAIL.ENVIAR_EMAIL_COMERCIAL, {
                'mensagem': `O usuário ${this.login.usuarioOrganizacao.usuarioNome}` +
                            `de e-mail: ${this.login.usuarioOrganizacao.usuarioEmail}` +
                            `e telefone: ${this.login.usuarioOrganizacao.usuarioTelefone}` +
                            `da organização: ${this.organizacao.nome}` +
                            `atingiu o limite do Plano: ${this.nomePlanoContratado}`
            })
            this.fecharDialogLimitePlanoAtingido()
        },
        adicionarPdfs(files) {
            this.pdfs.push(...files)
        },
        atualizarDatasISO(documento) {
            documento.inicioVigencia = DateTimeUtils.setZeroHorasISO(documento.inicioVigencia)
            documento.fimVigencia = DateTimeUtils.set23HorasISO(documento.fimVigencia)
            documento.inicioAssinatura = DateTimeUtils.setZeroHorasISO(documento.inicioAssinatura)
            documento.fimAssinatura = DateTimeUtils.set23HorasISO(documento.fimAssinatura)
        },
        async buscarContratoPorId() {
            this.setMensagemLoading(`Carregando dados do ${this.$tc("pt_br.documento").toLowerCase()}`)
            await this.$store.dispatch(actionTypes.BUSCAR_CONTRATO, this.documento.contratoId)
        },
        async buscarVersaoMaisRecenteMinuta() {
            this.setMensagemLoading('Carregando dados da minuta')
            await this.$store.dispatch(actionTypes.BUSCAR_MINUTA_ATUAL, this.documento.contratoId)
        },
        calcularInicioRenovacao(criacao) {
            if (this.minutaAtual.fimVigencia) {
                return moment(this.minutaAtual.fimVigencia)
                    .add(1, 'days')
                    .format('YYYY-MM-DD')
            }
            return criacao
        },
        calcularFimRenovacao() {
            if (this.minutaAtual.fimVigencia) {
                const inicioVigencia = moment(this.minutaAtual.inicioVigencia)
                const fimVigencia = moment(this.minutaAtual.fimVigencia)
                const periodoVigencia = Math.abs(inicioVigencia.diff(fimVigencia, 'days'))
                return moment(this.minutaAtual.fimVigencia)
                    .add(periodoVigencia, 'days')
                    .format('YYYY-MM-DD')
            }
            return null
        },
        construirRequisicao() {
            const documento = _.clone(this.documento)
            this.atualizarDatasISO(documento)
            documento.nomeDocOriginal = this.pdfs[0].name
            const arquivo = new FormData()
            this.pdfs.forEach(a => {
                arquivo.append('arquivo', a)
            })
            arquivo.append('renovacao', JSON.stringify(documento))
            return {contratoId: this.documento.contratoId, renovacao: arquivo}
        },
        async continuarOnce() {
            this.disableContinuar = true
            await this.renovarContrato()
        },
        fecharDialogLimitePlanoAtingido() {
            this.dialogLimitePlanoAtingido = false
        },
        getLabelToolbarTitle() {
            return controleAcoes._minutaCancelada(this.minutaAtual.situacaoFormalizacao) ? 'Gerar nova versão do ' : 'Renovar/Aditivar '
        },
        getLabelBotaoGerarNovaVersaoOuRenovar() {
            return controleAcoes._minutaCancelada(this.minutaAtual.situacaoFormalizacao) ? 'GERAR NOVA VERSÃO' : 'RENOVAR/ADITIVAR'
        },
        inicializarModeloContrato() {
            let criacao = moment().format('YYYY-MM-DD')
            let vencimento = moment().add(7, 'days').format('YYYY-MM-DD')

            this.documento.nome = this.contrato.nome
            this.documento.inicioVigencia = this.calcularInicioRenovacao(criacao)
            this.documento.fimVigencia = this.calcularFimRenovacao()
            this.documento.inicioAssinatura = criacao
            this.documento.fimAssinatura = vencimento
            this.documento.pastaId = this.contrato.pastaId
        },
        async importarItensMinuta() {
            const antigaMinutaId = this.minutaAtual.id
            await this.buscarVersaoMaisRecenteMinuta()
            await this.$store.dispatch(actionTypes.ITEM.IMPORTAR_ITENS_MINUTA, antigaMinutaId)
        },
        removerPdf(pdf) {
            const index = this.pdfs.indexOf(pdf)
            if (index > -1) {
                this.pdfs.splice(index, 1)
            }
        },
        async renovarContrato() {
            const dadosValidos = await this.validarDadosFormulario()
            if (!dadosValidos) {
                this.disableContinuar = false
                return
            }
            try {
                this.setMensagemLoading(this.setMensagemCarregamento())
                await this.$store.dispatch(actionTypes.RENOVAR_CONTRATO, this.construirRequisicao())
                this.mostrarNotificacaoSucessoDefault()
                await this.importarItensMinuta()
                await this.$router.push({name: 'detalheContrato', params: {id: this.documento.contratoId}})
            } catch (e) {
                this.disableContinuar = false
                const exceptionType = e.response.data.exception
                if (exceptionType && exceptionType.search('LimiteDocumentosAtingidoException') !== -1) {
                    this.dialogLimitePlanoAtingido = true
                }
                await this.resetDatas()
            }
        },
        resetDatas() {
            this.documento.inicioVigencia = moment(this.documento.inicioVigencia).format('YYYY-MM-DD')
            this.documento.fimVigencia = moment(this.documento.fimVigencia).format('YYYY-MM-DD')
            this.documento.inicioAssinatura = moment(this.documento.inicioAssinatura).format('YYYY-MM-DD')
            this.documento.fimAssinatura = moment(this.documento.fimAssinatura).format('YYYY-MM-DD')
        },
        setMensagemCarregamento() {
            return controleAcoes._minutaCancelada(this.minutaAtual.situacaoFormalizacao) ? `Gerando nova versão do ${this.$tc("pt_br.documento").toLowerCase()}...` : `Renovando ${this.$tc("pt_br.documento").toLowerCase()}...`
        },
        validarArquivoSelecionado() {
            if (!this.pdfs || this.pdfs.length <= 0) {
                this.mostrarNotificacaoErro('Arquivo(s) não selecionado(s).')
                return false
            }
            return true
        },
        async validarDadosFormulario() {
            const validado = await this.$refs.formularioDocumentoNovo.validarFormulario()
            const datasValidas = this.validarDatasInicioFimVigencia()
            const arquivoSelecionado = this.validarArquivoSelecionado()
            if (!datasValidas) {
                this.$refs.formularioDocumentoNovo.abrirOpcoesAvancadas()
            }
            return validado && datasValidas && arquivoSelecionado
        },
        validarDatasInicioFimVigencia() {
            if (!this.documento.inicioVigencia) {
                return false
            }
            if (this.documento.fimVigencia) {
                const fimVigencia = moment(this.documento.fimVigencia)
                const inicioVigencia = moment(this.documento.inicioVigencia)
                if (inicioVigencia.isAfter(fimVigencia)) {
                    this.mostrarNotificacaoErro('A data de término da vigência não pode ser anterior ao início da vigência.')
                    return false
                }
            }
            return true
        },
        voltarParaMeusDocumentos() {
            RouterUtils.voltarParaMeusDocumentos(this.meusContratosBusca.filtros.formalizacao.value)
        },
    }
}
</script>

<style lang="stylus">
.co-renovar-documento
    background var(--background-200) !important

    .col-12, .col-sm-3
        padding 0

    .btn-continuar
        width 100%
        bottom 0
        z-index 999
        background var(--background-200) !important

    .btn-mobile
        position absolute
        height 60px !important

    .formulario-documento
        position relative
        height auto
        background-color var(--background-200)

        .formulario
            overflow auto
            height calc(100vh - 118px)

@media (max-width: 600px)
    .co-renovar-documento
        .formulario-documento
            .formulario
                height auto
</style>
