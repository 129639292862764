<template>
    <v-card class="co-endereco-compra">
        <v-card-title class="primary lighten-1 white--text">
            Confirme seus dados
            <v-spacer/>
            <v-icon color="white" @click="fechar"> mdi-window-close</v-icon>
        </v-card-title>

        <v-card-text>
            <div class="mt-5 d-flex justify-center align-center">
                <span class="titulo">Preencha os dados de cobrança para prosseguir com a compra</span>
            </div>

            <v-layout v-if="semDadosPessoais" class="endereco" row wrap>
                <v-flex xs12>
                    <span class="titulo my-2">Dados Pessoais:</span>
                </v-flex>

                <v-flex sm3 xs12>
                    <az-combo-enum
                        v-model="value.tipoPessoa"
                        v-validate="'required'"
                        :enum-object="pessoaTipo"
                        :error-messages="errors.collect('Tipo')"
                        class="required-field mx-3"
                        is-required
                        label="Tipo"
                        name="Tipo"/>
                </v-flex>

                <v-flex sm9 xs12>
                    <v-text-field
                        v-if="pessoaFisica"
                        v-model="value.cnpjCPF"
                        v-mask="['###.###.###-##']"
                        v-validate="'required|cpf'"
                        :error-messages="errors.collect('Documento')"
                        class="required-field mx-3"
                        label="CPF"
                        name="Documento"
                        persistent-placeholder
                        placeholder="000.000.000-00"/>

                    <v-text-field
                        v-if="pessoaJuridica"
                        v-model="value.cnpjCPF"
                        v-mask="['##.###.###/####-##']"
                        v-validate="'required|cnpj'"
                        :error-messages="errors.collect('Documento')"
                        class="required-field  mx-3"
                        label="CNPJ"
                        name="Documento"
                        persistent-placeholder
                        placeholder="00.000.000/0000-00"/>
                </v-flex>
            </v-layout>

            <v-layout row wrap class="endereco">
                <v-flex v-if="semDadosPessoais" xs12>
                    <span class="titulo my-2">Endereço:</span>
                </v-flex>

                <v-flex sm3 xs12>
                    <v-text-field
                        class="required-field mx-3"
                        v-validate="'required|min:8'"
                        :error-messages="errors.collect('CEP')"
                        @keyup.native.enter="buscarCEP"
                        @blur="buscarCEP"
                        v-model="value.cep"
                        label="CEP"
                        name="CEP"
                        v-mask="['#####-###']"
                        persistent-placeholder
                        placeholder="00000-000"/>
                </v-flex>

                <v-flex sm6 xs12>
                    <v-text-field
                        class="required-field mx-3"
                        v-validate="'required'"
                        :error-messages="errors.collect('Rua')"
                        v-model="value.endereco"
                        label="Endereço"
                        name="Endereço"
                        placeholder="Ex. R. 25 de Dezembro"
                        persistent-placeholder/>
                </v-flex>

                <v-flex sm3 xs12>
                    <v-text-field
                        v-model="value.numero"
                        v-mask="'##########'"
                        v-validate="'required'"
                        :error-messages="errors.collect('Número')"
                        class="required-field mx-3"
                        label="Número"
                        name="Número"
                        persistent-placeholder
                        placeholder="Ex. 2071"/>
                </v-flex>

                <v-flex sm6 xs12>
                    <v-text-field
                        class="required-field mx-3"
                        v-validate="'required'"
                        :error-messages="errors.collect('Bairro')"
                        v-model="value.bairro"
                        label="Bairro"
                        name="Bairro"
                        placeholder="Ex. Nossa Senhora de Fátima"
                        persistent-placeholder/>
                </v-flex>

                <v-flex sm6 xs12>
                    <v-text-field
                        v-model="value.complemento"
                        :error-messages="errors.collect('Complemento')"
                        class="mx-3"
                        label="Complemento"
                        name="Complemento"
                        persistent-placeholder
                        placeholder="Ex. Bloco 1 Apto 2 "/>
                </v-flex>

                <v-flex sm6 xs12>
                    <v-autocomplete
                        class="required-field mx-3"
                        v-validate="'required'"
                        :error-messages="errors.collect('Estado')"
                        v-model="value.estadoId"
                        :items="estados"
                        item-text="nome"
                        item-value="id"
                        label="Estado"
                        name="Estado"
                        autocomplete="off"
                        placeholder="Selecione"
                        persistent-placeholder/>
                </v-flex>

                <v-flex sm6 xs12>
                    <v-autocomplete
                        class="required-field mx-3"
                        v-validate="'required'"
                        :error-messages="errors.collect('Cidade')"
                        v-model="value.municipioId"
                        :items="municipios"
                        item-text="nome"
                        item-value="id"
                        label="Cidade"
                        name="Cidade"
                        autocomplete="off"
                        placeholder="Selecione"
                        persistent-placeholder/>
                </v-flex>
            </v-layout>
        </v-card-text>

        <v-divider/>

        <v-card-actions>
            <v-row no-gutters class="px-2 py-3">
                <v-btn class="cancelar"
                       depressed
                       @click="voltar">Voltar
                </v-btn>

                <v-spacer/>

                <v-btn
                    @click="salvarDados"
                    color="green white--text"
                    depressed>CONTINUAR
                </v-btn>
            </v-row>
        </v-card-actions>
        <loja-loading v-if="loading"/>
    </v-card>
</template>

<script>
import LojaLoading from '../LojaLoading'
import {actionTypes, tipoPessoa} from '@/commons/constants'
import {mapState} from 'vuex'

export default {
    name: "DadosCobranca",
    components: {LojaLoading},
    data() {
        return {
            loading: false,
            municipios: [],
            pessoaTipo: tipoPessoa,
            semDadosPessoais: false,
        }
    },
    props: {
        value: {
            required: true
        }
    },
    computed: {
        ...mapState(['estados', 'loki']),
        pessoaFisica() {
            return this.value.tipoPessoa === 'FISICA'
        },
        pessoaJuridica() {
            return this.value.tipoPessoa === 'JURIDICA'
        }
    },
    async mounted() {
        this.verificarOrganizacaoSemDadosPessoais()
        await this.buscarEstados()
        await this.carregarMunicipios()
    },
    watch: {
        async 'value.estadoId'() {
            this.value.municipioId = null
            await this.carregarMunicipios()
        }
    },
    methods: {
        fechar() {
            this.$emit('fechar')
        },
        voltar() {
            this.$emit('voltar')
        },
        async buscarMunicipios() {
            this.setMensagemLoading('Buscando Cidades')
            this.municipios = await this.$store.dispatch(actionTypes.BUSCAR_CIDADES, this.value.estadoId)
        },
        async buscarEstados() {
            if (this.estados && this.estados.length > 0) {
                return
            }
            this.setMensagemLoading('Buscando Estados')
            await this.$store.dispatch(actionTypes.BUSCAR_ESTADOS)
        },
        async carregarMunicipios() {
            if (this.value.estadoId) {
                await this.buscarMunicipios()
            }
        },
        async consultarViaCep() {
            try {
                const endereco = await this.$store.dispatch(actionTypes.ORGANIZACAO.BUSCAR_CEP, this.value.cep)
                if (endereco.erro) {
                    this.mostrarNotificacaoErro('CEP informado é inválido')
                    this.value.cep = ''
                    this.loading = false
                    return
                }
                return endereco
            } catch (e) {
                this.mostrarNotificacaoErro('Erro ao buscar CEP automaticamente')
                throw e
            }
        },
        async buscarCEP() {
            if (!this.value.cep || this.value.cep.length < 8) {
                return
            }
            try {
                this.loading = true
                const endereco = await this.consultarViaCep()
                this.value.endereco = endereco.logradouro
                this.value.bairro = endereco.bairro
                const estado = this.estados.find(estado => {
                    return estado.sigla === endereco.uf
                })
                if (estado) {
                    this.value.estadoId = estado.id
                    await this.carregarMunicipios()
                    const municipio = this.municipios.find(cidade => {
                        return cidade.nome === endereco.localidade
                    })
                    if (municipio) {
                        this.value.municipioId = municipio.id
                    }
                }
                this.loading = false
            } catch (e) {
                this.loading = false
            }
        },
        async salvarDados() {
            const valido = await this.$validator._base.validateAll()
            if (!valido) {
                return
            }
            await this.atualizarOrganizacao(this.$store.state.organizacao.organizacaoId, this.value)
            this.$emit('comprar-saldo')
        },
        verificarOrganizacaoSemDadosPessoais() {
            if (!this.value.cnpjCPF || this.value.cnpjCPF.length === 0) {
                this.semDadosPessoais = true
            }
        }
    }
}
</script>

<style lang="stylus">
.co-endereco-compra
    .titulo
        color var(--v-primary-base)
        font-size 16px
        font-weight normal
        margin 5px

    .endereco
        padding 5px
        margin-top 10px
</style>